<div class="left-content">
    <div class="app-menu icon-btn" (click)="toggleMenuDisplay()">
        <ez-icon [name]="'bars'"></ez-icon>
    </div>
    <div class="contract-info">
        <div class="logo-contract" *ngIf="contract && !contractOnChange">
            <object type="image/png" [data]="contract | StaticContractLogoUri" typemustmatch>
                <img [src]="undefined | StaticContractLogoUri" />
            </object>
        </div>
        <div class="block-info">
            <ez-select class="contracts" *ngIf="contract != null && contracts?.length > 1; else oneContract"
                [nullable]="false" [autocomplete]="true" [value]="contract" (valueChange)="contractChange($event)">
                <ez-option *ngFor="let client of contracts" [value]="client">
                    <div class="contracts-opt">
                        <object type="image/png" [data]="client | StaticContractLogoUri" typemustmatch>
                            <img [src]="undefined | StaticContractLogoUri" />
                        </object>
                        <span>
                            {{client.shortName}}
                        </span>
                        <ez-icon class="cursor-pointer" (click)="openContractInNewWindow(client, $event)" name="external-link-alt"></ez-icon>
                    </div>
                </ez-option>
            </ez-select>
            <ng-template #oneContract>
                <span class="one-label">{{contract?.shortName}}</span>
            </ng-template>
        </div>
        <div class="block-info">
            <ez-select *ngIf="shippers?.length > 1" [nullable]="false" [autocomplete]="true" [value]="shipper"
                (valueChange)="shipperChange($event)">
                <ez-option *ngFor="let ship of shippers" [value]="ship">
                    <span>
                        {{ship.shortName}}
                    </span>
                </ez-option>
            </ez-select>
        </div>
    </div>
</div>
<div class="right-content">
    <div class="filters" [class.blink-me]="isDateChanges">
        <ez-loader *ngIf="shipperOnLoad"></ez-loader>
        <div class="filter-dates" ez-overlay-origin #originDates="EzOverlayOrigin"
            [ezTooltip]="'general.delivery-date' | translate">
            <ez-icon [name]="'calendar-day'"></ez-icon>
            <span>
                <i class="fas fa-chevron-left previous-day" (click)="onChangeDate($event, directionChangeDate.previous)"></i>
                <date [value]="date" [format]="{date:'medium'}" [onlyDate]="true"></date>
                <i class="fas fa-chevron-right next-day" (click)="onChangeDate($event, directionChangeDate.next)"></i>
            </span>
        </div>
        <ng-container *ngIf="commodities?.length > 0">
            <div class="separator"></div>
            <div class="filter-commo">
                <ng-container *ngFor="let commo of commodities">
                    <ng-container [ngSwitch]="commo">
                        <power-icon *ngSwitchCase="commoTypes.Power" [ezTooltip]=" 'enum.material.'+commo | translate"
                            [class.selected]="powerSelected" (click)="commodityChange(commo)">
                        </power-icon>
                        <gas-icon *ngSwitchCase="commoTypes.Gas" [ezTooltip]=" 'enum.material.'+commo | translate"
                            [class.selected]="gasSelected" (click)="commodityChange(commo)">
                        </gas-icon>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <div class="separator"></div>
        <div class="filter-countries" [class.has-selection]="!hasCountriesSelection || !hasAreasSelection"
            [ezTooltip]="'Countries and Areas filters'">
            <ez-icon [name]="'globe-europe'" ez-overlay-origin #originCountries="EzOverlayOrigin"></ez-icon>
        </div>
        <div class="separator" [hidden]="isMultiShipper || !canSeeAutoTrading && !canSeeAuction"></div>
        <div class="filter-market" [hidden]="isMultiShipper || !canSeeAutoTrading && !canSeeAuction"
            [class.has-selection]="exchangesSelected?.length != exchanges?.length || hasMarketSelection"
            ez-overlay-origin #originMarkets="EzOverlayOrigin">
            <ez-icon [name]="'chart-bar'"></ez-icon>
        </div>
    </div>
    <div class="events icon-btn" [routerPage]="appPages.EventsReport" [routerOutlet]="'popup'"
        [disabled]="!hasRightEvents" [class.disabled]="!hasRightEvents"
        [ezTooltip]="eventSvcEnable ? (!hasRightEvents ? undefined : (countEvents+ ' alerts not read')) : 'Alerts service down'">
        <span class="badge animated" *ngIf="countEvents != null" [class.rubberBand]="countEventsChange"
            (animationend)="eventBadgeTransitionEnd()">{{countEvents > 99 ? '99' : countEvents}} </span>
        <ez-icon [name]=" eventSvcEnable ? 'bell' : 'fa-bell-slash'"></ez-icon>
    </div>
    <div class="user-menu icon-btn" (click)=" toggleDisplayUserMenu($event)">
        <div class="user-icon">
            <span>{{userIcon}}</span>
        </div>
    </div>
</div>

<ez-popover [(open)]="displayPopoverDate" [origin]="originDates" [actionType]="'click'" [alignH]="'right'"
    [alignInnerH]="true" class="popover-dates">
    <div class="dates-container">
        <div class="quick-dates">
            <ez-button *ngFor="let date of quickDates | enumStringToIterable"
                [severity]="date['value'] === quickDate ? 'primary' : undefined"
                (onClick)="quickDateChange(date['value'])">
                {{ date['value'] }}
            </ez-button>
        </div>
        <ez-date-calendar [value]="date" (valueChange)="dateChanges($event)">
        </ez-date-calendar>
    </div>
</ez-popover>

<ez-popover [origin]="originCountries" [actionType]="'click'" [alignH]="'right'" [alignInnerH]="true"
    class="popover-countries">
    <ez-tabs [(value)]="filterTab">
        <ez-tab-section [value]="filterTabs.Countries">
            <ez-tab-title>
                <span class="title">
                    Countries
                </span>
                <ez-checkbox [disabled]="filterTab !== filterTabs.Countries" [indeterminate]="true"
                    [checked]="hasCountriesSelection" (checkedChange)="toggleSelectionCountries($event)"
                    [ezTooltip]="(hasCountriesSelection ? 'general.unselect-all' : 'general.select-all') | translate">
                </ez-checkbox>
            </ez-tab-title>
            <ez-content>
                <ez-image-selector [multiselect]="true" [value]="countriesSelected"
                    (valueChange)="countriesChange($event)">
                    <ez-image *ngFor="let data of countries" [url]="data.country | StaticCountryUri:64" [value]="data"
                        [name]="data.country.name">
                        <div class="icon-commo">
                            <power-icon [ngStyle]="{opacity: data.hasPower ? 1 : 0}" [class.disabled]="!powerSelected">
                            </power-icon>
                            <gas-icon [ngStyle]="{opacity:data.hasGas? 1 : 0}" [class.disabled]="!gasSelected">
                            </gas-icon>
                        </div>
                    </ez-image>
                </ez-image-selector>
            </ez-content>
        </ez-tab-section>
        <ez-tab-section [value]="filterTabs.Areas">
            <ez-tab-title>
                <span class="title">
                    Areas
                </span>
                <ez-checkbox [disabled]="filterTab !== filterTabs.Areas" [indeterminate]="true"
                    [checked]="hasAreasSelection" (checkedChange)="toggleSelectionAreas($event)"
                    [ezTooltip]="(hasAreasSelection ? 'general.unselect-all' : 'general.select-all') | translate">
                </ez-checkbox>
            </ez-tab-title>
            <ez-content>
                <div class="filter-block" *ngIf="areasPower?.length > 0">
                    <div class="block-title">
                        <span>Power</span>
                        <ez-checkbox [indeterminate]="true" [checked]="hasAreasPowerSelection"
                            (checkedChange)="toggleSelectionPowerAreas($event)"
                            [ezTooltip]="(hasAreasPowerSelection ? 'general.unselect-all' : 'general.select-all') | translate">
                        </ez-checkbox>
                    </div>
                    <ez-image-selector [multiselect]="true" [value]="areasPowerSelected"
                        (valueChange)="areasPowerChange($event)">
                        <ez-image *ngFor="let area of areasPower" [url]="area | StaticGridUri:64" [value]="area"
                            [name]="area.name">
                            <div class="icon-country">
                                <img [src]="area.country | StaticCountryUri:32" />
                            </div>
                            <div class="icon-commo">
                                <power-icon [class.disabled]="!powerSelected"></power-icon>
                            </div>
                        </ez-image>
                    </ez-image-selector>
                </div>
                <div class="filter-block" *ngIf="areasGas?.length > 0">
                    <div class="block-title">
                        <span>Gas</span>
                        <ez-checkbox [indeterminate]="true" [checked]="hasAreasGasSelection"
                            (checkedChange)="toggleSelectionGasAreas($event)"
                            [ezTooltip]="(hasAreasGasSelection ? 'general.unselect-all' : 'general.select-all') | translate">
                        </ez-checkbox>
                    </div>
                    <ez-image-selector [multiselect]="true" [value]="areasGasSelected"
                        (valueChange)="areasGasChange($event)">
                        <ez-image *ngFor="let area of areasGas" [url]="area | StaticGridUri:64" [value]="area"
                            [name]="area.name">
                            <div class="icon-country">
                                <img [src]="area.country | StaticCountryUri:32" />
                            </div>
                            <div class="icon-commo">
                                <gas-icon [class.disabled]="!gasSelected"></gas-icon>
                            </div>
                        </ez-image>
                    </ez-image-selector>
                </div>
            </ez-content>
        </ez-tab-section>
    </ez-tabs>
</ez-popover>

<ez-popover [origin]="originMarkets" [actionType]="'click'" [alignH]="'right'" [alignInnerH]="true"
    class="popover-market">
    <div class="market-header">
        <span class="title">
            Exchanges
        </span>
        <ez-checkbox [indeterminate]="true" [checked]="hasExchangeSelection"
            (checkedChange)="toggleSelectionExchanges($event)"
            [ezTooltip]="(hasExchangeSelection ? 'general.unselect-all' : 'general.select-all') | translate">
        </ez-checkbox>
    </div>
    <ez-content>
        <div class="filter-block" *ngIf="exchanges?.length > 0">
            <div class="block-title">
                <span>Market</span>
                <ez-checkbox [indeterminate]="true" [checked]="hasExchangeSelection"
                    (checkedChange)="toggleSelectionExchanges($event)"
                    [ezTooltip]="(hasExchangeSelection ? 'general.unselect-all' : 'general.select-all') | translate">
                </ez-checkbox>
            </div>
            <ez-image-selector [multiselect]="true" [value]="exchangesSelected" (valueChange)="exchangesChange($event)">
                <ez-image *ngFor="let exchange of exchanges"
                    [url]="(exchange?.auction?.token || exchange?.autotrading?.token) | StaticExchangeUri:32"
                    [name]="'enum.exchanges.'+ exchange.label | translate" [value]="exchange">
                </ez-image>
            </ez-image-selector>
        </div>
        <div class="filter-block" *ngIf="accounts?.length || users?.length">
            <div class="block-title">
                <span>Account</span>
                <ez-checkbox [indeterminate]="true" [checked]="hasAccountsSelection"
                    (checkedChange)="toggleSelectionAccounts($event)"
                    [ezTooltip]="(hasAccountsSelection ? 'general.unselect-all' : 'general.select-all') | translate">
                </ez-checkbox>
            </div>
            <div class="block-account">
                <div class="account-selector">
                    <ez-title>
                        <ez-icon [name]="'chart-bar'"></ez-icon>
                        Continuous
                    </ez-title>
                    <div class="account" [class.active]="user.selected" *ngFor="let user of uniqueUsers"
                        (click)="usersChange(user)">
                        <div class="img-exchange">
                            <img [src]="user?.exchange | StaticExchangeUri:32" />
                        </div>
                        <span [ezTooltip]="user.name" [tooltipDelayShow]="800">{{user.name}}</span>
                    </div>
                </div>
                <div class="account-selector">
                    <ez-title>
                        <ez-icon [name]="'gavel'"></ez-icon>
                        Auction
                    </ez-title>
                    <div class="account" [class.active]="account.selected" *ngFor="let account of accounts"
                        (click)="accountsChange(account)">
                        <div class="img-exchange">
                            <img [src]="account?.exchange | StaticExchangeUri:32" />
                        </div>
                        <span [ezTooltip]="account.name" [tooltipDelayShow]="800">{{account.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </ez-content>
</ez-popover>


<div [hidden]="!displayUserMenu" class="usermenu-container">
    <div #userMenuInfo>
        <div class="user-info">
            <div class="user-icon">
                <span>{{userIcon}}</span>
            </div>
            <div class="user-name">
                {{userName}}
            </div>
        </div>
        <div class="culture-info">
            <div class="culture" ez-overlay-origin #originCulture="EzOverlayOrigin">
                <span>{{ 'culture.use' | translate:{culture: 'culture.countries.'+cultureUsed | translate} }}</span>
                <ez-icon [name]="'question-circle'"></ez-icon>
            </div>
            <ez-popover *ngIf="displayUserMenu" [origin]="originCulture" [actionType]="'over'"
                [positionAlign]="PosPopover" [class]="'popover-culture-info'">
                <div class="title">
                    {{'culture.separator.title' | translate}}
                </div>
                <ez-ul>
                    <ez-li>{{'culture.separator.decimal' | translate:{separator:cultureDecimalSeparator,
                        name:('culture.separator.name.'+translateSeparator(cultureDecimalSeparator) | translate )} }}</ez-li>
                    <ez-li>{{'culture.separator.thousand' | translate:{separator:cultureThousandSeparator,
                        name:('culture.separator.name.'+translateSeparator(cultureThousandSeparator) | translate )} }}</ez-li>
                    <ez-li>{{'culture.separator.time' | translate:{separator:cultureTimeSeparator,
                        name:('culture.separator.name.'+translateSeparator(cultureTimeSeparator) | translate )} }}</ez-li>
                </ez-ul>
                <div class="title">
                    {{'culture.format.title' | translate}}
                </div>
                <ez-ul>
                    <ez-li>{{'culture.format.date' | translate:{format:cultureDateFormat} }}</ez-li>
                    <ez-li>{{'culture.format.time' | translate:{format:cultureTimeFormat} }}</ez-li>
                </ez-ul>
            </ez-popover>
        </div>
    </div>
    <ez-ul>
        <ez-li (click)="onChangePwd()">
            <ez-icon [name]="'key'"></ez-icon>
            <span>Change password</span>
        </ez-li>
        <ez-li (click)="prefModal.open = true">
            <ez-icon [name]="'user-cog'"></ez-icon>
            <span>Preferences</span>
        </ez-li>
        <ez-li (click)="toggleDisplayHelp()">
            <ez-icon [name]="'question-circle'"></ez-icon>
            <span>{{ hasHelpPanel ? 'Hide help': 'Display help'}}</span>
        </ez-li>
        <ez-li (click)="toggleContactSupport()">
            <ez-icon [name]="'phone'"></ez-icon>
            <span>Contact support</span>
        </ez-li>
        <ez-li [routerPage]="appPages.Logout" class="logout">
            <ez-icon [name]="'sign-out-alt'"></ez-icon>
            <span>Logout</span>
        </ez-li>
    </ez-ul>
</div>
<ez-modal #prefModal>
    <ez-header>User Preference</ez-header>
    <preference-page></preference-page>
</ez-modal>
