<ez-table [data]="gradientItems" [disabled]="disabled">
    <ez-row-header>
        <th colspan="2" class="gd-times">
            <ng-content select="ez-label"></ng-content>
        </th>
        <th class="gd-value">
            <ng-content select=".ez-factor"></ng-content>
        </th>
        <th class="gd-actions"></th>
    </ez-row-header>
    <ez-column property="from">
        <td *ezTbCell="let item" class="gd-time" [ngClass]="{'has-error': item.onError}">
            <span class="from-value">{{item.from}}</span>
        </td>
    </ez-column>
    <ez-column property="to">
        <td *ezTbCell="let item; index as i" class="gd-time" [ngClass]="{'has-error': item.onError}">
            <span class="ez-icon-infiny"
                *ngIf="i == gradientItems.length - 1 && !lastRowEditable && item.to >= infiniteMaxValue;"></span>
            <span class="from-value"
                *ngIf="i == gradientItems.length - 1 && !lastRowEditable && item.to < infiniteMaxValue">{{item.to}}</span>
            <ez-input-number *ngIf="i !== gradientItems.length - 1 || lastRowEditable" [disabled]="disabled"
                [value]="item.to" (valueChange)="onToChange($event, i, item)"  >
            </ez-input-number>
        </td>
    </ez-column>
    <ez-column property="value">
        <td *ezTbCell="let item; index as i" class="gd-value" [ngClass]="{'has-error': item.onError}">
            <ez-input-number [disabled]="disabled" [value]="item.value" (valueChange)="onFactorChange($event, i)"
                [min]="minValue">
                <ez-suffix>{{ item.unit?.name }}</ez-suffix>
            </ez-input-number>
        </td>
    </ez-column>
    <ez-column property="delete">
        <td *ezTbCell="let item; index as i" class="gd-actions" [ngClass]="{'has-error': item.onError}">
            <ez-button *ngIf="i != gradientItems.length - 1; else add" [icon]="'minus'" (onClick)="onDeleteClick(i)"
                [disabled]="(gradientItems && gradientItems.length == 1 && !gradientItems.from && !gradientItems.to) || disabled"
                [small]="true">
            </ez-button>
            <ng-template #add>
                <ez-button [disabled]="disabled || !addEnabled" [icon]="'plus'" (onClick)="onAddClick()" [small]="true">
                </ez-button>
            </ng-template>
        </td>
    </ez-column>
</ez-table>
