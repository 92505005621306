// @ts-nocheck
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CoreFormModule } from '@forms/core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { ScenarioEditComponent } from './scenario-edit.component';

@NgModule({
    imports: [
        CoreFormModule,
        TranslateModule
    ],
    declarations: [
        ScenarioEditComponent
    ],
    exports: [
        ScenarioEditComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ScenarioFormModule {

}
