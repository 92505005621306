import { Component, HostBinding, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "ez-locker",
    templateUrl: "./locker.component.html",
    styleUrls: ["./locker.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class EzLockerComponent {
    @HostBinding('class.displayed') displayed: boolean = false;

    @Input()
    public get activated(): boolean {
        return this.displayed;
    }
    public set activated(value: boolean) {
        this.displayed = value;
    }

    constructor() {
    }
}
