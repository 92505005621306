import { AfterViewInit, Component, OnInit } from '@angular/core';
import { REDIRECT_ACTION_KEY, RedirectActions } from 'src/app/modules/redirection/models/redicrect-actions';
import { UserVersionStore } from '../models/user-version-store';
import { CacheService, KeyCache } from '@services/cache.service';
import { OauthService } from '@eznergy/webapi';
import { Logger } from '@core';

@Component({
    selector: 'redirect-cmp',
    template: ''
})
export class RedirectComponent implements OnInit, AfterViewInit {

    constructor(
        private _cachingService: CacheService,
        private readonly _oauthService: OauthService,
        private _logger: Logger
    ) {}

    public ngOnInit(): void {
        this.deleteItemUiOnLocalStorage();
    }

    private deleteItemUiOnLocalStorage(): void {
        const store = this._cachingService.get<UserVersionStore | undefined>(KeyCache.UserVersion, UserVersionStore);
        if (store) {
            this._cachingService.remove(KeyCache.UserVersion);
        }
    }

    public async ngAfterViewInit(): Promise<void> {
        await this._onLoadAsync();
    }

    public async _onLoadAsync(): Promise<void> {
        const { location, history } = window;

        const queryParams = new URLSearchParams(location.search);
        const action = queryParams.get(REDIRECT_ACTION_KEY);

        // if this is not a redirect
        if (!action) {
            return;
        }

        try {
            // remove redirect from url
            const redirectIndex = location.href.indexOf('?');
            const rootUrl = location.href.substring(0, redirectIndex);
            history.replaceState(null, '', rootUrl);

            switch (action) {
                case RedirectActions.LogIn:
                case RedirectActions.LogOut: {
                    // logOut the current user
                    await this._oauthService.logout().toPromise();
                    break;
                }
            }

        } catch (err) {
            this._logger.warn("Redirection error caused a user logout");
            await this._oauthService.logout().toPromise();
        }
    }
}
