// @ts-nocheck
import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from "@angular/forms";
import { coerceBoolean } from '@eznergy/core';
import { DateRange, DateTime, Timespan } from "@eztypes/generic";
import { IPhysicalValue, ITimeGradientItem } from "@eztypes/webapi";
import Decimal from 'decimal.js-light';
import * as _ from 'lodash';

export function isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
}

// @dynamic
export class EzValidators extends Validators {

    static minTimespan(min: Timespan): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
                return null;
            }
            const value = control.value as Timespan;
            let minText: string = '';
            let actualText: string = '';
            if (min.totalDays && min.totalDays === new Decimal(min.totalDays).toDecimalPlaces(0).toNumber()) {
                minText = min.totalDays + ' d';
                actualText = control.value.totalDays + ' d';
            } else if (min.totalHours && min.totalHours === new Decimal(min.totalHours).toDecimalPlaces(0).toNumber()) {
                minText = min.totalHours + ' h';
                actualText = control.value.totalHours + ' h';
            } else if (min.totalMinutes && min.totalMinutes === new Decimal(min.totalMinutes).toDecimalPlaces(0).toNumber()) {
                minText = min.totalMinutes + ' min';
                actualText = control.value.totalMinutes + ' min';
            } else if (min.totalSeconds && min.totalSeconds === new Decimal(min.totalSeconds).toDecimalPlaces(0).toNumber()) {
                minText = min.totalSeconds + ' s';
                actualText = control.value.totalSeconds + ' s';
            } else {
                minText = min.totalMilliseconds + ' ms';
                actualText = control.value.totalMilliseconds + ' ms';
            }
            return !value || value.totalMilliseconds < min.totalMilliseconds ? { 'min': { 'min': minText, 'actual': actualText } } : null;
        };
    }

    static maxTimespan(max: Timespan): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
                return null;
            }
            const value = control.value as Timespan;
            let maxText: string = '';
            let actualText: string = '';
            if (max.totalDays && max.totalDays === new Decimal(max.totalDays).toDecimalPlaces(0).toNumber()) {
                maxText = max.totalDays + ' d';
                actualText = control.value.totalDays + ' d';
            } else if (max.totalHours && max.totalHours === new Decimal(max.totalHours).toDecimalPlaces(0).toNumber()) {
                maxText = max.totalHours + ' h';
                actualText = control.value.totalHours + ' h';
            } else if (max.totalMinutes && max.totalMinutes === new Decimal(max.totalMinutes).toDecimalPlaces(0).toNumber()) {
                maxText = max.totalMinutes + ' min';
                actualText = control.value.totalMinutes + ' min';
            } else if (max.totalSeconds && max.totalSeconds === new Decimal(max.totalSeconds).toDecimalPlaces(0).toNumber()) {
                maxText = max.totalSeconds + ' s';
                actualText = control.value.totalSeconds + ' s';
            } else {
                maxText = max.totalMilliseconds + ' ms';
                actualText = control.value.totalMilliseconds + ' ms';
            }

            return !value || value.totalMilliseconds > max.totalMilliseconds ? { 'max': { 'max': maxText, 'actual': actualText } } : null;
        };
    }

    static minPhysicalValue(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
                return null;
            }
            const value = control.value as IPhysicalValue;
            return value == null || value.value < min ? { 'min': { 'min': min, 'actual': control.value.value } } : null;
        };
    }

    static maxPhysicalValue(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
                return null;
            }
            const value = control.value as IPhysicalValue;
            return value == null || value.value > max ? { 'max': { 'max': max, 'actual': control.value.value } } : null;
        };
    }

    static minGradientValueValue(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
                return null;
            }
            const value = control.value as ITimeGradientItem[];
            const item: ITimeGradientItem = _.find(value, (v) => v.offset < min);
            return value && item ? { 'min': { 'min': min, 'actual': item.offset } } : null;
        };
    }

    static maxGradientValueValue(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
                return null;
            }
            const value = control.value as ITimeGradientItem[];
            const item: ITimeGradientItem = _.find(value, (v) => v.offset > max);
            return value && item ? { 'max': { 'max': max, 'actual': item.offset } } : null;
        };
    }
}

export const EZ_MIN_PH_VAL_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EzMinPhValValidator),
    multi: true
};

@Directive({
    selector: 'ez-physical-value[min][formControlName], ez-physical-value[min][formControl], ez-physical-value[min][ngModel]',
    providers: [EZ_MIN_PH_VAL_VALIDATOR]
})
export class EzMinPhValValidator implements Validator {

    private _validator: ValidatorFn;
    private _onChange: () => void;

    @Input()
    set min(value: number) {
        if (this._min !== value) {
            this._min = value;
            this._createValidator();
            if (this._onChange) this._onChange();
        }
    }
    private _min: number;

    validate(control: AbstractControl): ValidationErrors | null {
        return this._min == null ? null : this._validator(control);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    private _createValidator(): void {
        this._validator = EzValidators.minPhysicalValue(this._min);
    }
}


export const EZ_MAX_PH_VAL_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EzMaxPhValValidator),
    multi: true
};

@Directive({
    selector: 'ez-physical-value[max][formControlName],ez-physical-value[max][formControl], ez-physical-value[max][ngModel]',
    providers: [EZ_MAX_PH_VAL_VALIDATOR]
})
export class EzMaxPhValValidator implements Validator {

    private _validator: ValidatorFn;
    private _onChange: () => void;

    @Input()
    set max(value: number) {
        if (this._max !== value) {
            this._max = value;
            this._createValidator();
            if (this._onChange) this._onChange();
        }
    }
    private _max: number;

    validate(control: AbstractControl): ValidationErrors | null {
        return this._max == null ? null : this._validator(control);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    private _createValidator(): void {
        this._validator = EzValidators.maxPhysicalValue(this._max);
    }
}

export const EZ_MIN_TIMESPAN_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EzMinTimespanValidator),
    multi: true
};

@Directive({
    selector: 'ez-timespan[min][formControlName], ez-timespan[min][formControl], ez-timespan[min][ngModel]',
    providers: [EZ_MIN_TIMESPAN_VALIDATOR]
})
export class EzMinTimespanValidator implements Validator {

    private _validator: ValidatorFn;
    private _onChange: () => void;

    @Input()
    set min(value: Timespan) {
        if (this._min !== value) {
            this._min = value;
            this._createValidator();
            if (this._onChange) this._onChange();
        }
    }
    private _min: Timespan;

    validate(control: AbstractControl): ValidationErrors | null {
        return this._min == null ? null : this._validator(control);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    private _createValidator(): void {
        this._validator = EzValidators.minTimespan(this._min);
    }
}

export const EZ_MIN_GRADIENTVALUE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EzMinGradientValueValidator),
    multi: true
};

@Directive({
    selector: 'ez-gradient-value[min][formControlName], ez-gradient-value[min][formControl], ez-gradient-value[min][ngModel]',
    providers: [EZ_MIN_GRADIENTVALUE_VALIDATOR]
})
export class EzMinGradientValueValidator implements Validator {

    private _validator: ValidatorFn;
    private _onChange: () => void;

    @Input()
    set min(value: number) {
        if (this._min !== value) {
            this._min = value;
            this._createValidator();
            if (this._onChange) this._onChange();
        }
    }
    private _min: number;

    validate(control: AbstractControl): ValidationErrors | null {
        return this._min == null ? null : this._validator(control);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    private _createValidator(): void {
        this._validator = EzValidators.minGradientValueValue(this._min);
    }
}

export const EZ_MAX_GRADIENTVALUE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EzMaxGradientValueValidator),
    multi: true
};

@Directive({
    selector: 'ez-gradient-value[max][formControlName], ez-gradient-value[max][formControl], ez-gradient-value[max][ngModel]',
    providers: [EZ_MAX_GRADIENTVALUE_VALIDATOR]
})
export class EzMaxGradientValueValidator implements Validator {

    private _validator: ValidatorFn;
    private _onChange: () => void;

    @Input()
    set max(value: number) {
        if (this._max !== value) {
            this._max = value;
            this._createValidator();
            if (this._onChange) this._onChange();
        }
    }
    private _max: number;

    validate(control: AbstractControl): ValidationErrors | null {
        return this._max == null ? null : this._validator(control);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    private _createValidator(): void {
        this._validator = EzValidators.maxGradientValueValue(this._max);
    }
}

export const EZ_MAX_TIMESPAN_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EzMaxTimespanValidator),
    multi: true
};

@Directive({
    selector: 'ez-timespan[max][formControlName],ez-timespan[max][formControl], ez-timespan[max][ngModel]',
    providers: [EZ_MAX_TIMESPAN_VALIDATOR]
})
export class EzMaxTimespanValidator implements Validator {

    private _validator: ValidatorFn;
    private _onChange: () => void;

    @Input()
    set max(value: Timespan) {
        if (this._max !== value) {
            this._max = value;
            this._createValidator();
            if (this._onChange) this._onChange();
        }
    }
    private _max: Timespan;

    validate(control: AbstractControl): ValidationErrors | null {
        return this._max == null ? null : this._validator(control);
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    private _createValidator(): void {
        this._validator = EzValidators.maxTimespan(this._max);
    }
}

@Directive({
    selector: "[ez-minDate][ngModel],[ez-minDate][formControl], [ez-minDate][formControlName]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: EzDateValidatorMin, multi: true }
    ]
})
export class EzDateValidatorMin implements Validator {
    private _onChange: () => void;

    @Input("ez-minDate")
    set min(value: DateTime) {
        if (this._min !== value) {
            this._min = value;
            if (this._onChange) {
                this._onChange();
            }
        }
    }
    private _min: DateTime;

    @Input("ez-minDateEqual")
    set minDateEqual(value: boolean) {
        const newValue = coerceBoolean(value);
        if (this._minDateEqual !== newValue) {
            this._minDateEqual = newValue;
            if (this._onChange) {
                this._onChange();
            }
        }
    }
    private _minDateEqual: boolean = false;

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    validate(control: AbstractControl) {
        if (control.value == undefined || this._min == undefined) {
            return null;
        }
        let value: DateTime;
        if (control.value instanceof DateTime) {
            value = control.value
        } else if (control.value instanceof DateRange) {
            value = control.value.from
        }

        if (this._minDateEqual) {
            if (value.isAfterOrEqual(this._min)) {
                return null;
            }
        } else {
            if (value.isAfter(this._min)) {
                return null;
            }
        }

        return {
            "minDate": true
        };
    }
}


@Directive({
    selector: "[ez-maxDate][ngModel],[ez-maxDate][formControl],[ez-maxDate][formControlName]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: EzDateValidatorMax, multi: true }
    ]
})
export class EzDateValidatorMax implements Validator {
    private _onChange: () => void;

    @Input("ez-maxDate")
    set max(value: DateTime) {
        if (this._max !== value) {
            this._max = value;
            if (this._onChange) {
                this._onChange();
            }
        }
    }
    private _max: DateTime;

    @Input("ez-maxDateEqual")
    set maxDateEqual(value: boolean) {
        const newValue = coerceBoolean(value);
        if (this._maxDateEqual !== newValue) {
            this._maxDateEqual = newValue;
            if (this._onChange) {
                this._onChange();
            }
        }
    }
    private _maxDateEqual: boolean = false;

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    validate(control: AbstractControl) {
        if (control.value == undefined || this._max == undefined) {
            return null;
        }

        let value: DateTime;
        if (control.value instanceof DateTime) {
            value = control.value
        } else if (control.value instanceof DateRange) {
            value = control.value.from
        }

        if (this._maxDateEqual) {
            if (value.isBeforeOrEqual(this._max)) {
                return null;
            }
        } else {
            if (value.isBeforeOrEqual(this._max)) {
                return null;
            }
        }

        return {
            "maxDate": true
        };
    }
}


