<ez-form (onSubmit)="submitForm()" (onCancel)="onCancel()">
    <ez-form-field>
        <ez-input-text [required]="true" [(ngModel)]="selectedName" [placeholder]="'globalTrad.name' | translate">
        </ez-input-text>
    </ez-form-field>
    <ez-form-field [hidden]="forceCurveType">
        <ez-select [required]="true" [placeholder]="'timeseries.global.curveType' | translate"
            [(ngModel)]="selectedType" (valueChange)="onSelectedTypeChanged($event)">
            <ez-select-option *ngFor="let type of types" [value]="type">{{ type.value }}</ez-select-option>
        </ez-select>
    </ez-form-field>
    <ez-form-field [hidden]="!showUnits">
        <ez-select [disabled]="selectedType?.value !== 'DECIMAL'" [required]="true" [(ngModel)]="selectedUnit"
            [placeholder]="'timeseries.global.unit' | translate">
            <ez-select-option *ngFor="let unit of units" [value]="unit">{{ unit.name }}</ez-select-option>
        </ez-select>
    </ez-form-field>
    <ez-footer>
        <ez-button [label]="'globalTrad.cancel' | translate" ez-form-cancel></ez-button>
        <ez-button [label]="'globalTrad.validate' | translate" ez-form-submit></ez-button>
    </ez-footer>
</ez-form>