import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IGrid } from '@eztypes/webapi';
import { BaseCardPopoverComponent } from '../base-card.component';

@Component({
    selector: 'organisation-card-popover',
    template: '<ez-popover #popover><organisation-card [value]="value" [light]="light"></organisation-card></ez-popover>',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganisationCardPopover extends BaseCardPopoverComponent<IGrid> {
}
