// @ts-nocheck
import { isSame, sortBy } from "@eznergy/core";
import { ICounterpart, IDeal, IGrid, IGridPoint, MaterialCode } from "@eztypes/webapi";

export interface ISelectionCounterpart {
    readonly sortId: number;
    readonly counterpart: ICounterpart;
    readonly deals: IDeal[];
}

export class SelectionCounterpart implements ISelectionCounterpart {
    readonly counterpart: ICounterpart;
    sortId: number;
    deals: IDeal[] = [];

    constructor(sortId: number, counterpart: ICounterpart) {
        this.sortId = sortId;
        this.counterpart = counterpart;
    }
}

export interface ISelectionGridPoint {
    readonly sortId: number;
    readonly gp: IGridPoint;
    readonly gps: IGridPoint[];
    readonly counterparts: ISelectionCounterpart[];
}

export class SelectionGridPoint implements ISelectionGridPoint {
    sortId: number;
    readonly gp: IGridPoint;
    readonly gps: IGridPoint[];
    counterparts: SelectionCounterpart[] = [];

    constructor(sortId: number, gp: IGridPoint, gps: IGridPoint[]) {
        this.sortId = sortId;
        this.gp = gp;
        this.gps = gps;
    }

    getCp(cp: ICounterpart): SelectionCounterpart {
        return this.counterparts.find((a) => isSame(a.counterpart, cp));
    }

    addCp(cp: ICounterpart, sortId: number): SelectionCounterpart {
        let local = this.getCp(cp);
        if (local == null) {
            local = new SelectionCounterpart(sortId, cp);
            this.counterparts.push(local);
        } else {
            local.sortId = sortId;
        }
        return local;
    }

    removeCp(cp: ICounterpart): void {
        const index = this.findCpIndex(cp);
        if (index !== -1) {
            this.counterparts.splice(index, 1);
        }
    }

    hasCp(cp: ICounterpart): boolean {
        return this.findCpIndex(cp) !== -1;
    }

    findCpIndex(cp: ICounterpart): number {
        return this.counterparts.findIndex((g) => isSame(g.counterpart, cp));
    }
}

export interface ISelectionGrid {
    readonly grid: IGrid;
    readonly gridPoints: SelectionGridPoint[];
    readonly isEmpty: boolean;
}

export class SelectionGrid implements ISelectionGrid {
    readonly grid: IGrid;
    readonly gridPoints: SelectionGridPoint[] = [];

    get isEmpty(): boolean {
        return this.gridPoints.length === 0;
    }

    constructor(grid: IGrid) {
        this.grid = grid;
    }

    getSelectionGp(gp: IGridPoint): SelectionGridPoint {
        return this.gridPoints.find((g) => isSame(g.gp, gp));
    }

    addGp(gp: IGridPoint, sortId: number, gps: IGridPoint[]): SelectionGridPoint {
        let local = this.getSelectionGp(gp);
        if (local == null) {
            local = new SelectionGridPoint(sortId, gp, gps);
            this.gridPoints.push(local);
        } else {
            local.sortId = sortId;
        }
        return local;
    }

    removeGp(gp: IGridPoint): void {
        const index = this.findGpIndex(gp);
        if (index !== -1) {
            this.gridPoints.splice(index, 1);
        }
    }

    hasGp(gp: IGridPoint): boolean {
        return this.findGpIndex(gp) !== -1;
    }

    findGpIndex(gp: IGridPoint): number {
        return this.gridPoints.findIndex((g) => isSame(g.gp, gp));
    }
}

export interface ISelectionData {
    readonly grids: ISelectionGrid[];
}

export class SelectionData implements ISelectionData {
    get grids(): SelectionGrid[] {
        return this._grids;
    }
    private _grids: SelectionGrid[] = [];

    constructor() {
    }

    clear(): void {
        this._grids = [];
    }

    getGridSelection(grid: IGrid): SelectionGrid {
        return this._grids.find((g) => isSame(grid, g.grid));
    }

    addGrid(grid: IGrid): SelectionGrid {
        let local = this.getGridSelection(grid);
        if (local == null) {
            local = new SelectionGrid(grid);
            this._grids.push(local);
            this._sort();
        }
        return local;
    }

    removeGrid(grid: IGrid): void {
        const index = this.findGridIndex(grid);
        if (index !== -1) {
            this._grids.splice(index, 1);
            this._sort();
        }
    }

    hasGrid(grid: IGrid): boolean {
        return this.findGridIndex(grid) !== -1;
    }

    findGridIndex(grid: IGrid): number {
        return this._grids.findIndex((g) => isSame(grid, g.grid));
    }

    private _sort(): void {
        this._grids = sortBy(this._grids, (a) => `${a.grid.material.code === MaterialCode.Power ? '1' : '2'}_${a.grid.name}`);
    }
}
