import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IDateRange } from "@eztypes/generic";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageFilesPreferenceInfo {
    static load(preferences: string | PageFilesPreferenceInfo): PageFilesPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageFilesPreferenceInfo>(preferences, PageFilesPreferenceInfo) as PageFilesPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    formatIds: number[] | undefined;
    processPeriod: IDateRange | undefined;
    widthDate: boolean | undefined;
    maxItems: number | undefined;
}

@UserPreferenceClassDecorator
export class PageFilesPreferencePreference implements IPreferencePage {
    static load(preferences: string | PageFilesPreferenceInfo): PageFilesPreferencePreference {
        if (!preferences) {
            return new PageFilesPreferencePreference();
        }

        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageFilesPreferenceInfo.load(preferences);
                return new PageFilesPreferencePreference(
                    v1.formatIds,
                    v1.processPeriod,
                    v1.widthDate,
                    v1.maxItems
                );
            default:
                return new PageFilesPreferencePreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    formatIds: number[] | undefined;
    processPeriod: IDateRange | undefined;
    widthDate: boolean | undefined;
    maxItems: number | undefined;

    constructor(
        formatIds?: number[] | undefined,
        processPeriod?: IDateRange | undefined,
        widthDate?: boolean | undefined,
        maxItems?: number | undefined
    ) {
        this.formatIds = formatIds;
        this.processPeriod = processPeriod;
        this.widthDate = widthDate;
        this.maxItems = maxItems;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byDate) {
            this.widthDate = undefined;
            this.processPeriod = undefined;
        }
        if (options.all) {
            this.maxItems = undefined;
            this.formatIds = undefined;
        }
    }
}

