<ez-input-search [placeholder]="ph" class="input-search" (valueChange)="onFilterChange($event)">
</ez-input-search>
<ul class="ts-groups">
    <li *ngFor="let item of dataView">
        <ez-checkbox [label]="item.label.path" [ngModel]="item.checked" [indeterminate]="true"
            (checkedChange)="onCheckboxChange($event, item)">
        </ez-checkbox>
    </li>
</ul>
<div class="footer-btn" *ngIf="!autoApply">
    <ez-button [small]="true" [label]="'general.cancel' | translate" (onClick)="undoChanges()"></ez-button>
    <ez-button [small]="true" [label]="'general.apply' | translate" (onClick)="submit()"
        [severity]="hasChange ? 'warning':'primary'">
    </ez-button>
</div>
