// @ts-nocheck
// Libs
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Logger } from '@core';
import { BasePagePopup } from '@core/pages';
import { ApiService } from "@eznergy/webapi";
import { ISession, UserRight } from "@eztypes/webapi";
import { AtParametersForm, TabForm } from '../forms/parameters/parameters.component';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from "@services/application.service";
import { AppPages, RouterService } from "@services/router.service";

@Component({
    selector: "session-parameters-page",
    templateUrl: "./session-parameters.component.html",
    styleUrls: ["./session-parameters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionParametersPage extends BasePagePopup implements OnDestroy, OnInit {

    @ViewChild(AtParametersForm, { static: true }) form: AtParametersForm;

    get session(): ISession {
        return this._session;
    }
    private _session: ISession;
    private _sessionId: number;

    private _hasSessionReadRight: boolean;

    tabForm: TabForm;

    get urlPage(): string {
        if (!this._srvRouter || !this._sessionId)
            return undefined;
        return this._srvRouter.getUrl(this._page, { params: { id: this._sessionId.toString() } });
    }
    get urlPopup(): string {
        if (!this._srvRouter || !this._sessionId)
            return undefined;
        return this._srvRouter.getUrlPopup(this._page, { id: this._sessionId.toString() });
    }

    private _page: AppPages;

    constructor(private _api: ApiService,
        private _appSvc: ApplicationService,
        private _cdr: ChangeDetectorRef,
        private _route: ActivatedRoute,
        private _srvRouter: RouterService,
        private _srvTranslate: TranslateService,
        logger: Logger) {
        super(logger, 'session-parameters');
        this.titlePopup = "Session Parameters";
    }

    ngOnInit(): void {
        let subRights = this._appSvc.contractChange.subscribe(() => {
            if (this._hasSessionReadRight !== this._appSvc.hasRight(UserRight.SessionRead)) {
                this._hasSessionReadRight = this._appSvc.hasRight(UserRight.SessionRead);
                this._getSession();
            }
        });
        let sub = this._route.paramMap.subscribe((param) => {
            if (param.has("id")) {
                let id = parseInt(param.get('id'));
                if (Number.isNaN(id)) {
                    this._srvRouter.closeOutletPopup();
                }
                if (this._sessionId != id) {
                    this._sessionId = id;
                    this._getSession();
                }
                if (param.has("tab")) {
                    let tab = param.get('tab');
                    switch (tab) {
                        case "exchange":
                            this.tabForm = "Products & Contracts";
                            this._page = AppPages.SessionParamExchange;
                            break;
                        case "price":
                            this.tabForm = "Prices";
                            this._page = AppPages.SessionParamPrice;
                            break;
                        case "volume":
                            this.tabForm = "Volume";
                            this._page = AppPages.SessionParamVolume;
                            break;
                        case "strategy":
                            this.tabForm = "Strategy";
                            this._page = AppPages.SessionParamStrategy;
                            break;
                        case "termination":
                            this.tabForm = "Early termination";
                            this._page = AppPages.SessionParamTermination;
                            break;
                    }
                    this._cdr.markForCheck();
                } else {
                    if (this._route.outlet === "popup")
                        this._srvRouter.navigateToPopup(AppPages.SessionParamExchange, { id: this._sessionId.toString() });
                }
                this._srvRouter.setAppTitle(this.titlePopup);
            } else {
                this._srvRouter.closeOutletPopup();
            }
        });
        this._subscriptions.push("checkRights", subRights);
        this._subscriptions.push("paramMap", sub);
    }

    canDeactivate() {
        if (this.form) {
            return this.form.canClosedForm();
        }
        return true;
    }

    private _getSession(): void {
        this._subscriptions.clearSub("session");
        if (this._hasSessionReadRight && this._sessionId != null) {
            this._loaderPage.push("getSession");
            let sub = this._api.autotrading.sessions.get(this._appSvc.contract.id, this._sessionId).subscribe((obj) => {
                if (obj) {
                    this._session = obj;
                    if (this._session) {
                        let msg = "autotrading.session.popups.title.parameters";
                        let sub = this._srvTranslate.get(msg).subscribe((trans: string) => {
                            this.titlePopup = trans + ' ' + this._session.name;
                            this._srvRouter.setAppTitle(this.titlePopup);
                        });
                        this._subscriptions.push("title", sub);
                    }
                    this._loaderPage.remove("getSession");
                    this._cdr.markForCheck();
                }
            }, () => {
                this._loaderPage.remove("getSession");
            });
            this._subscriptions.push("session", sub);
        } else {
            this._session = undefined;
            this._cdr.detectChanges();
        }
    }
}
