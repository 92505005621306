// @ts-nocheck
import { DateTime } from '@eztypes/generic';
import { IEtsMarketResultPortfolio, IEtsMarketResultRowGlobal, IEtsPortfolio } from "@eztypes/webapi";
import { Decimal } from 'decimal.js-light';
import { EtsTotal } from "./etsTotal.model";

export class PortfolioMarket implements IEtsMarketResultPortfolio {
    portfolio: IEtsPortfolio;
    total: EtsTotal[];
    linear: EtsTotal[];
    block: EtsTotal[];
}

export class EtsGlobal implements IEtsMarketResultRowGlobal {
    periodStart: DateTime;
    periodEnd: DateTime;
    price: number;
    volume: number;
}

export class EtsSummary {
    date: DateTime;
    globalVolume: string;
    clientVolume: string;
    globalPrice: string;
    portfoliosNet: string[];
}

export class EtsSummaryTotal {
    label: string;
    globalVolume: number;
    clientVolume: number;
    globalPrice: number;
    totalPortfoliosNet: number[];
}

export class EtsPortfolioTotal {
    label: string;
    price: number;
    linear: number;
    block: number;
    label2: string;
    data: Decimal | string[];
}

export class EtsBlockTotal {
    label: string;
    volume: number;
}

export class EtsPortfolioResult {
    date: DateTime;
    globalPrice: string;
    linearVolume: string;
    blockVolume: string;
    portfolioBlocks: string[];
}

export class EtsBlockOrderHeader {
    name: string;
    etsID: number;
    comment: string;
}
