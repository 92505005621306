import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";
import { PageAdminPreferenceInfoPreference } from "./admin-preferences.model";
import { PageCalculatedCurvePreference } from "./calculated-curve-preferences.model";
import { PageTsEditPreference } from "./ts-edit-preferences.model";

export interface IContractPagesPreferences {
    tsAdmin: PageAdminPreferenceInfoPreference;
    tsCalculated: PageCalculatedCurvePreference;
    tsEdit: PageTsEditPreference;
}

class ContractPagesPreferenceInfo {
    static load(preferences: string | ContractPagesPreferenceInfo): ContractPagesPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<ContractPagesPreferenceInfo>(preferences, ContractPagesPreferenceInfo) as ContractPagesPreferenceInfo;
        return preferences;
    }
    readonly version: number = -1;

    //#region TS
    readonly tsAdmin: string = "";
    readonly tsCalculated: string = "";
    readonly tsEdit: string = "";
    //#endregion TS
}

@UserPreferenceClassDecorator
export class ContractPagesPreference implements IPreferencePage {

    static load(preferences: string | ContractPagesPreferenceInfo | undefined): ContractPagesPreference {
        if (preferences == null) {
            return new ContractPagesPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;

        switch (version) {
            case 1:
                const v1 = ContractPagesPreferenceInfo.load(preferences);
                const contractPagePreferences: IContractPagesPreferences = {
                    tsEdit: PageTsEditPreference.load(v1.tsEdit),
                    tsCalculated: PageCalculatedCurvePreference.load(v1.tsCalculated),
                    tsAdmin: PageAdminPreferenceInfoPreference.load(v1.tsAdmin),
                };
                return new ContractPagesPreference(contractPagePreferences);
            default:
                return new ContractPagesPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;

    //#region TS
    @UPPropertySave
    readonly tsAdmin: PageAdminPreferenceInfoPreference;
    @UPPropertySave
    readonly tsCalculated: PageCalculatedCurvePreference;
    @UPPropertySave
    readonly tsEdit: PageTsEditPreference;
    //#endregion TS


    constructor(contractPagePreferences?: IContractPagesPreferences) {
        if (contractPagePreferences) {
            this.tsEdit = contractPagePreferences.tsEdit;
            this.tsCalculated = contractPagePreferences.tsCalculated;
            this.tsAdmin = contractPagePreferences.tsAdmin;
        } else {
            this.tsEdit = new PageTsEditPreference();
            this.tsCalculated = new PageCalculatedCurvePreference();
            this.tsAdmin = new PageAdminPreferenceInfoPreference();
        }
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        this.tsAdmin.reset(options);
        this.tsCalculated.reset(options);
        this.tsEdit.reset(options);
    }
}

