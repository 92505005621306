import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageAuctionOverviewPreferenceInfo {
    static load(preferences: string): PageAuctionOverviewPreferenceInfo {
        return deserialize<PageAuctionOverviewPreferenceInfo>(preferences, PageAuctionOverviewPreferenceInfo) as PageAuctionOverviewPreferenceInfo;
    }

    readonly version: number = 1;
    refreshTimer: number | undefined;
}

@UserPreferenceClassDecorator
export class PageAuctionOverviewPreference implements IPreferencePage {
    static load(preferences: string): PageAuctionOverviewPreference {
        if (!preferences) {
            return new PageAuctionOverviewPreference();
        }

        const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
        switch (prefInfo.version) {
            case 1:
                const v1 = PageAuctionOverviewPreferenceInfo.load(preferences);
                return new PageAuctionOverviewPreference(v1.refreshTimer);
            default:
                return new PageAuctionOverviewPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    refreshTimer: number | undefined;

    constructor(refreshTimer: number | undefined = undefined) {
        this.refreshTimer = refreshTimer;
    }

    reset(_options: OptionsResetPreferencePage = {}): void {}
}

