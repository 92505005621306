// @ts-nocheck
// Lib
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 * Pipe wich format the curve type of nomination
 */
@Pipe({ name: "formatNominationCurveType" })
export class FormatNominationCurveType implements PipeTransform {

    private _nominationEntity = "NOMINATION_ENTITY";
    private _nominationCPart = "NOMINATION_CPART";
    private _settledAdjSysop = "SETTLED_ADJ_SYSOP";
    private _settledSysop = "SETTLED_SYSOP";
    private _prodocEntity = "PRODOC_ENTITY";
    private _prodconSysop = "PROCON_SYSOP";
    private _allocated = "ALLOCATED";
    private _tsoAccepted = "ACCEPTED_SYSOP";

    constructor(private _translateService: TranslateService) {
    }

    /**
    * Format the value of the curve type
    * Public method
    * @param value Origin value curve type
    * @returns {string} nomination curve type formated
    */
    private _getNominationCurveType(value: string): string {
        switch (value) {
            case this._nominationEntity:
                return this._translateService.instant("nominations.messages.nominationEntity");
            case this._nominationCPart:
                return this._translateService.instant("nominations.messages.nominationCPart");
            case this._settledAdjSysop:
                return this._translateService.instant("nominations.messages.curveAdjacent");
            case this._settledSysop:
                return this._translateService.instant("nominations.messages.curveEntity");
            case this._prodocEntity:
                return this._translateService.instant("nominations.messages.nominationEntity");
            case this._prodconSysop:
                return this._translateService.instant("nominations.messages.curveEntity");
            case this._allocated:
                return this._translateService.instant("nominations.messages.curveEntity");
            case this._tsoAccepted:
                return this._translateService.instant("nominations.messages.tsoAccepted");
            default:
                return "";
        }
    }

    /**
     * Transform pipe format the value of the curve type
     * Pipe method
     * @param value Origin value from api
     * @returns {string} nomination curve type formated
     */
    transform(value: string): string {
        return this._getNominationCurveType(value);
    }

    /**
     * Transform pipe format the value of the curve type
     * Public method
     * @param value Origin value from api
     * @returns {string} nomination curve type formated
     */
    getNominationCurveType(value: string): string {
        return this._getNominationCurveType(value);
    }
}
