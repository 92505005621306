import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EzTextInput } from './textInput/text-input.component';
import { EzToggleComponent } from './toggle/toggle.component';
import { EzIconModule } from '@eznergy/components';

@NgModule({
    imports: [CommonModule, EzIconModule, FormsModule],
    exports: [
        EzTextInput, EzToggleComponent
    ],
    declarations: [
        EzTextInput, EzToggleComponent
    ],

})
export class EzInputModule {
}
