<ez-form (onSubmit)="formSubmit()">
    <ez-form-field>
        <ez-label>{{ 'autotrading.session.create.informations.name' | translate }}</ez-label>
        <ez-input-text [ngModel]="value.name" [required]="true" [maxlength]="150"
            (valueChange)="checkNameValidity($event)" [minlength]="(errorName ? 150 : 1)"></ez-input-text>
        <ez-message-error validator="minlength" [message]="'autotrading.session.create.errors.nameExist' | translate">
        </ez-message-error>
    </ez-form-field>

    <ez-form-field>
        <ez-label>{{ 'autotrading.session.create.informations.commodity' | translate }}</ez-label>
        <div class="commodities">
            <div *ngFor="let commodity of commodities" class="commo" (click)="value.material = commodity"
                [class.selected]="commodity === value.material">
                <commodity-icon [value]="commodity.code"></commodity-icon>
                <span>{{'autotrading.commodities.' + commodity.code?.toLowerCase() | translate}}</span>
            </div>
        </div>
        <!-- <ez-image-selector [(ngModel)]="value.material" [required]="true">
            <ez-image *ngFor="let commodity of commodities" [url]="commodity.logo" [value]="commodity"
                [name]="">
            </ez-image>
        </ez-image-selector> -->
    </ez-form-field>

    <ez-form-field>
        <ez-label>{{ 'autotrading.session.create.informations.algo' | translate }}</ez-label>
        <ez-select [(ngModel)]="value.type" [required]="true">
            <ez-select-option *ngFor="let item of enabledSessionTypes" [value]="item">
                {{'autotrading.algos.'+ item | translate}}
            </ez-select-option>
        </ez-select>
    </ez-form-field>

    <ez-form-field>
        <ez-label>{{ 'autotrading.session.create.informations.shippers' | translate }}</ez-label>
        <ez-select [(ngModel)]="value.shipper" [required]="true">
            <ez-select-option *ngFor="let item of shippers" [value]="item">
                {{ item.shortName }}</ez-select-option>
        </ez-select>
    </ez-form-field>

    <ez-footer *ngIf="!hideFooter">
        <ez-button [label]="'general.cancel' | translate" ez-form-cancel [disabled]="disabled"></ez-button>
        <ez-button [label]="'general.next' | translate" [severity]="'primary'" ez-form-submit [disabled]="disabled">
        </ez-button>
    </ez-footer>
</ez-form>
