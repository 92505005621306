<ez-form (onSubmit)="formSubmit()" [autofocus]="false">
    <ez-form-group class="products-options">
        <ez-label>{{'autotrading.session.parameters.productsContracts.products.label' | translate}}</ez-label>
        <ez-form-field>
            <ez-select [required]="true" [(ngModel)]="selectedProduct" [disabled]="isUpdateMode">
                <ez-select-option *ngFor="let product of products" [value]="product">
                    {{'autotrading.market.products.'+ product | translate}}
                </ez-select-option>
            </ez-select>
        </ez-form-field>
    </ez-form-group>
    <ez-form-group class="contract-options">
        <ez-label>{{'autotrading.session.parameters.productsContracts.contracts.label' | translate}}</ez-label>
        <ez-form-field>
            <ez-radio-group [align]="'full'" [(ngModel)]="selectedRadio" [disabled]="disabled">
                <ez-radio *ngIf="sessionType === sessionTypes.Generation" [value]="0"
                    [label]="'autotrading.session.parameters.productsContracts.contracts.pminmax' | translate">
                </ez-radio>
                <ez-radio [label]="'autotrading.session.parameters.productsContracts.contracts.all' | translate"
                    [value]="2">
                </ez-radio>
                <ez-radio [label]="'autotrading.session.parameters.productsContracts.contracts.coming' | translate"
                    [value]="1">
                    <ez-form-field>
                        <ez-input-number [(ngModel)]="nearNextContract" [disabled]="selectedRadio !== 1 || disabled"
                            [required]="true" [min]="1"
                            [placeholder]="'autotrading.session.parameters.productsContracts.contracts.comingInput' | translate">
                        </ez-input-number>
                    </ez-form-field>
                </ez-radio>
                <ez-radio [label]="'Only selected contracts'" [value]="3">
                    <ez-form-field>
                        <timestep-selector [(ngModel)]="selectedContract" [productType]="internalParameters.product"
                            [disabled]="selectedRadio != 3 || disabled" [required]="true">
                        </timestep-selector>
                    </ez-form-field>
                </ez-radio>
            </ez-radio-group>
        </ez-form-field>
    </ez-form-group>
    <ez-form-group class="offsets-container">
        <ez-label>{{'autotrading.session.parameters.productsContracts.offsets.label' | translate}}</ez-label>
        <ez-form-field>
            <ez-label>{{'autotrading.session.parameters.productsContracts.offsets.opening' | translate}}</ez-label>
            <ez-timespan [(ngModel)]="openingOffset" [min]="closingOffset" [required]="true">
            </ez-timespan>
        </ez-form-field>
        <ez-form-field>
            <ez-label>{{'autotrading.session.parameters.productsContracts.offsets.closing' | translate}}</ez-label>
            <ez-timespan [(ngModel)]="closingOffset" [max]="openingOffset" [required]="true">
            </ez-timespan>
        </ez-form-field>
    </ez-form-group>
    <ez-form-group>
        <ez-label>{{ 'autotrading.session.parameters.automation.periodTrade' | translate }}</ez-label>

        <div class="date-group">
            <ez-card class="period period-add" (click)="openFormAddTradedDay()"
                [ngClass]="{'mode-form':hasOpenFormTradedDay, 'disabled': disabled}">
                <ez-icon [name]="'plus-circle'"></ez-icon>
                <one-day-period-form [value]="dateAddSelected" (valueChange)="onAddDateRange($event)"
                    (onCancel)="hasOpenFormTradedDay = false">
                </one-day-period-form>
            </ez-card>
            <ez-card class="period" *ngFor="let period of datesTradesDeliveryPeriod" [type]="'small'">
                <div class="period-header">
                    <span>{{period.date | ezdate:{format:{date:'medium'} } }}</span>
                    <ez-button [icon]="'trash'" (onClick)="deletePeriod(period)" [small]="true"></ez-button>
                </div>
                <ul [ngClass]="{'no-btn':period.dates.length <= 1}">
                    <li *ngFor="let dateRange of period.dates" [ngClass]="{'add': dateRange === lastRangeAdd}">
                        <span>{{dateRange.from | ezdate:{format:{time:'short'} } }} -
                            {{dateRange.to | ezdate:{format:{time:'short'} } }}</span>
                        <ez-button [icon]="'times'" (onClick)="deleteRange(dateRange)" [small]="true"></ez-button>
                    </li>
                </ul>
            </ez-card>
        </div>
    </ez-form-group>
    <ez-footer *ngIf="!hideFooter">
        <ez-button [label]="'general.reset' | translate" ez-form-cancel></ez-button>
        <ez-button [label]="'general.save' | translate" [severity]="'primary'" ez-form-submit></ez-button>
    </ez-footer>
</ez-form>
