import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import {
    ModeDisplayDetails
} from '../../../modules/auction/order/components/auction-portfolio-list/auction-portfolio-list.component';
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

export class AuctionConfiguration {
    productId: number | undefined;
    exchangePortfolioId: number | undefined;
    internalPortfolioId: string | undefined;
    orderVersion: number | "working" | "online" | undefined;
    displayType: ModeDisplayDetails | undefined;
}

class PageAuctionOrdersPreferenceInfo {
    static load(preferences: string | PageAuctionOrdersPreferenceInfo): PageAuctionOrdersPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageAuctionOrdersPreferenceInfo>(preferences, PageAuctionOrdersPreferenceInfo) as PageAuctionOrdersPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    config: AuctionConfiguration | undefined;
}

@UserPreferenceClassDecorator
export class PageAuctionOrdersPreference implements IPreferencePage {
    static load(preferences: string | PageAuctionOrdersPreferenceInfo): PageAuctionOrdersPreference {
        if (!preferences) {
            return new PageAuctionOrdersPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageAuctionOrdersPreferenceInfo.load(preferences);
                return new PageAuctionOrdersPreference(v1.config);
            default:
                return new PageAuctionOrdersPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    config: AuctionConfiguration | undefined;

    constructor(config?: AuctionConfiguration | undefined) {
        this.config = config;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byGrid) {
            this.config = undefined;
        }
    }
}

