import { SentState } from '@models/views/auction/sent-state.enum';
import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IDateRange } from "@eztypes/generic";
import { AuctionBatchType } from "@eztypes/webapi";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageAuctionSchedulesPreferenceInfo {
    static load(preferences: string | PageAuctionSchedulesPreferenceInfo): PageAuctionSchedulesPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageAuctionSchedulesPreferenceInfo>(preferences, PageAuctionSchedulesPreferenceInfo) as PageAuctionSchedulesPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    productId: number | undefined;
    portfolioIds: number[] | undefined;
    batchTypes: AuctionBatchType[] | undefined;
    state: SentState | undefined;
    dates: IDateRange | undefined;
}

@UserPreferenceClassDecorator
export class PageAuctionSchedulesPreference implements IPreferencePage {
    static load(preferences: string | PageAuctionSchedulesPreferenceInfo): PageAuctionSchedulesPreference {
        if (!preferences) {
            return new PageAuctionSchedulesPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else {
            version = preferences.version;
        }
        switch (version) {
            case 1:
                const v1 = PageAuctionSchedulesPreferenceInfo.load(preferences);
                return new PageAuctionSchedulesPreference(
                    v1.productId,
                    v1.portfolioIds,
                    v1.batchTypes,
                    v1.state,
                    v1.dates
                );
            default:
                return new PageAuctionSchedulesPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    productId: number | undefined;
    portfolioIds: number[] | undefined;
    batchTypes: AuctionBatchType[] | undefined;
    state: SentState | undefined;
    dates: IDateRange | undefined;

    constructor(
        productId?: number | undefined,
        portfolioIds?: number[] | undefined,
        batchTypes?: AuctionBatchType[] | undefined,
        state?: SentState | undefined,
        dates?: IDateRange | undefined
    ) {
        this.productId = productId;
        this.portfolioIds = portfolioIds;
        this.batchTypes = batchTypes;
        this.state = state;
        this.dates = dates;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byDate) {
            this.dates = undefined;
        }
        if (options.all) {
            this.productId = undefined;
            this.portfolioIds = undefined;
            this.batchTypes = undefined;
            this.state = undefined;
        }
    }
}

