import { NgModule } from "@angular/core";
import { EzComponentsModule } from "@eznergy/components";
import { AdminComponentsModule } from "./admin/admin.module";
import { CoreComponentsModule } from "./core/core.module";
import { DataComponentsModule } from "./data/data.module";
import { NavigationComponentsModule } from "./navigation/navigation.module";
import { OverlayComponentsModule } from "./overlay/overlay.component";
import { SharedModule } from '../modules/shared/shared.module';

@NgModule({
    imports: [
        AdminComponentsModule,
        DataComponentsModule,
        NavigationComponentsModule,
        OverlayComponentsModule,
        CoreComponentsModule,
        EzComponentsModule,
        SharedModule
    ],
    exports: [
        AdminComponentsModule,
        DataComponentsModule,
        NavigationComponentsModule,
        OverlayComponentsModule,
        CoreComponentsModule,
        EzComponentsModule,
    ]
})
export class AppComponentsModule {
}
