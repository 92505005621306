// @ts-nocheck
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { EzComponentsModule } from "@eznergy/components";
import { TranslateModule } from "@ngx-translate/core";
import { AppPipesModule } from "@pipes/pipes.module";
import { EzContextMenu, EzContextMenuItem } from "./contextMenu/context-menu.component";

@NgModule({
    imports: [
        CommonModule,
        EzComponentsModule,
        TranslateModule,
        AppPipesModule
    ],
    declarations: [
        EzContextMenuItem,
        EzContextMenu
    ],
    exports: [
        EzContextMenuItem,
        EzContextMenu
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NavigationComponentsModule {

}
