<ez-form>
    <ez-form-group>
        <ez-label>
            <ez-icon [name]="'language'"></ez-icon>
            <span>Language</span>
        </ez-label>
        <ez-image-selector [value]="language" (valueChange)="changeLanguage($event)">
            <ez-image [value]="'fr'" [name]="'French'" [url]="'fr' | StaticCountryUri:32"></ez-image>
            <ez-image [value]="'en'" [name]="'English'" [url]="'gb' | StaticCountryUri:32"></ez-image>
        </ez-image-selector>
    </ez-form-group>
    <ez-form-group>
        <ez-label>
            <ez-icon [name]="'globe'"></ez-icon>
            <span>Time zone alternative</span>
        </ez-label>
        <div>
            <ez-checkbox [checked]="tzActivate" [label]="'Activate'" (checkedChange)="tzActivateChange($event)">
            </ez-checkbox>
        </div>
        <div>
            <tz-selector [value]="timezone" (valueChange)="changeTimeZone($event)" [disabled]="!tzActivate">
            </tz-selector>
        </div>
    </ez-form-group>
    <ez-form-group>
        <ez-label>
            <ez-icon [name]="'clock'"></ez-icon>
            <span>Hour cycle</span>
        </ez-label>
        <ez-button-group [value]="hourCycle" (valueChange)="changeHourCycle($event)">
            <ez-button [value]="'local'">Local</ez-button>
            <ez-button [value]="'h12'">12 Hours</ez-button>
            <ez-button [value]="'h24'">24 Hours</ez-button>
        </ez-button-group>
    </ez-form-group>
    <ez-form-group>
        <ez-button (click)="confirmReset.open = true">Reset your preferences</ez-button>
    </ez-form-group>
</ez-form>

<ez-modal #confirmReset>
    <ez-header>Reset preferences</ez-header>

    <div>
        <p>Your application will be set to its default, do you want to proceed?</p>
        <ng-container *ngIf="asking">
            <ez-button (click)="resetPreferences()">Yes</ez-button>
            <ez-button (click)="confirmReset.open = false">No</ez-button>
        </ng-container>
        <ng-container *ngIf="!asking">
            <p>Hard reset in {{ countdown | async }}.</p>
        </ng-container>
    </div>
</ez-modal>
