// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation, ElementRef, Attribute, Optional, HostBinding, Renderer2 } from "@angular/core";
import { fromEvent } from "rxjs";
import { Subscription } from 'rxjs';
import { auditTime } from "rxjs/operators";

export type PositionPanel = 'left' | 'right';

@Component({
    selector: "screen-panel",
    templateUrl: './screen-panel.component.html',
    styleUrls: ["./screen-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.panel-left]': 'position === "left"',
        '[class.panel-right]': 'position === "right"',
        '[class.resizable]': 'resizable'
    }
})
export class ScreenPanel {

    @Input()
    get position(): PositionPanel {
        return this._position;
    }
    set position(value: PositionPanel) {
        this._position = value;
        this._cdr.markForCheck();
    }
    private _position: PositionPanel = 'left';

    @Input()
    get resizable(): boolean {
        return this._resizable;
    }
    set resizable(value: boolean) {
        const newValue = value || false;
        if (newValue !== this.resizable) {
            this._resizable = newValue;
            this._cdr.markForCheck();
        }
    }
    private _resizable: boolean = false;

    @HostBinding("class.hide")
    @Input()
    get hide(): boolean {
        return this._hide;
    }
    set hide(value: boolean) {
        this._hide = value;
        this._cdr.markForCheck();
    }
    private _hide: boolean = false;

    private readonly _classNameOnMove = 'on-move';

    constructor(private readonly _cdr: ChangeDetectorRef,
        private readonly _el: ElementRef<HTMLElement>,
        @Attribute('width') @Optional() private readonly _attWidth: string,
        private readonly _renderer: Renderer2) { }

    ngOnInit(): void {
        if (this._attWidth) {
            this._el.nativeElement.style.width = this._attWidth;
        }
    }

    ngOnDestroy(): void {
        this._cleanResizeEvent();
    }

    private _positionStart: number;
    private _positionCurrent: number;
    private _width: number;
    private _subMouseMove: Subscription;
    private _subMouseUp: Subscription;
    resize(event: MouseEvent): void {
        this._cleanResizeEvent();
        this._positionStart = event.x;
        this._width = this._el.nativeElement.offsetWidth;
        this._renderer.addClass(this._el.nativeElement, this._classNameOnMove);
        this._subMouseMove = fromEvent(document, "mousemove").pipe(auditTime(10)).subscribe((event: MouseEvent) => {
            this._positionCurrent = event.x;
            let pos: number;
            switch (this.position) {
                case 'left':
                    pos = this._positionCurrent - this._positionStart;
                    break;
                case 'right':
                    pos = this._positionStart - this._positionCurrent;
                    break;
            }
            this._el.nativeElement.style.width = (this._width + pos) + 'px';
        });
        this._subMouseUp = fromEvent(document, "mouseup").subscribe(() => {
            this._cleanResizeEvent();
            this._cdr.detectChanges();
        });
    }

    resizeMin(): void {
        this._el.nativeElement.style.width = "";
    }

    private _cleanResizeEvent(): void {
        this._renderer.removeClass(this._el.nativeElement, this._classNameOnMove);
        if (this._subMouseMove) {
            this._subMouseMove.unsubscribe();
            this._subMouseMove = undefined;
        }
        if (this._subMouseUp) {
            this._subMouseUp.unsubscribe();
            this._subMouseUp = undefined;
        }
    }

}
