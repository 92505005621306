import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { HourCycle, I18nService } from '@eznergy/components';
import { ITimezoneInfo, TimezoneInfosList } from "@eztypes/generic";
import { ApplicationService } from "@services/application.service";
import { CultureService } from "@services/culture.service";
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
    selector: 'preference-page',
    templateUrl: './preferences.page.html',
    styleUrls: ['./preferences.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PreferencesPage {

    get language(): string | null {
        return this._cultureSvc.currentLanguage;
    }

    get tzActivate(): boolean {
        return this._tzActivate;
    }
    private _tzActivate: boolean = false;

    get timezone(): ITimezoneInfo | undefined {
        return this._timezone;
    }
    private _timezone: ITimezoneInfo | undefined;

    get hourCycle(): HourCycle | undefined {
        return this._hourCycle;
    }
    private _hourCycle: HourCycle | undefined;

    public asking = true;
    public countdown = timer(0, 1000).pipe(map((count) => 3 - count));

    constructor(
        private readonly _appSvc: ApplicationService,
        private readonly _cultureSvc: CultureService,
        private readonly _i18nSvc: I18nService
    ) {}

    ngOnInit(): void {
        this._timezone = TimezoneInfosList.find((a) => a.iana == this._appSvc.preferences?.timezone);
        this._tzActivate = this._appSvc.preferences?.tzActivate ?? false;
        this._hourCycle = this._appSvc.preferences?.hourCycle ?? 'local';
    }

    changeLanguage(language: string): void {
        if (this._cultureSvc.currentLanguage !== language) {
            switch (language) {
                case 'fr':
                    this._cultureSvc.currentLanguage = 'fr';
                    break;
                default:
                    this._cultureSvc.currentLanguage = 'en';
                    break;
            }
        }
    }

    changeHourCycle(hourCycle: HourCycle): void {
        this._hourCycle = hourCycle;
        this._i18nSvc.setHourCycle(this._hourCycle);
        // @ts-ignore
        this._appSvc.preferences.hourCycle = this.hourCycle;
    }

    tzActivateChange(activate: boolean): void {
        this._tzActivate = activate;
        if (this._appSvc.preferences) {
            this._appSvc.preferences.tzActivate = this._tzActivate;
        }
        this._appSvc.refreshTimeZone();
    }

    changeTimeZone(timezone: ITimezoneInfo): void {
        this._timezone = timezone;
        if (this._appSvc.preferences) {
            this._appSvc.preferences.timezone = this._timezone.iana;
        }
        this._appSvc.refreshTimeZone();
    }

    resetPreferences() {
        this._appSvc.hardResetPreferences();
        this.asking = false;
        setTimeout(() =>
            window.location.href = window.location.origin
        , 3000);
    }
}
