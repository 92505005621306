// @ts-nocheck
import { Pipe, PipeTransform } from "@angular/core";
import { ITimespan } from "@eztypes/generic";
@Pipe({
    name: 'timeFormatting'
})
export class TimeFormattingPipe implements PipeTransform {
    transform(data: ITimespan, format: string) {
        let timeFormatted = data.format(format);
        return timeFormatted;
    }
}
