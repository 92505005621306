import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ScheduleExecutionStatus } from '@eztypes/webapi';

@Pipe({ name: "formatAcknowledged" })
export class FormatAcknowledgedPipe implements PipeTransform {

    constructor(private _translateService: TranslateService) {
    }

    /**
     * Format value of acknowledged
     * @param value  Origin value from api
     * @param status  Optional schedule status value from api
     * @returns {string} acknowledged formated
     */
    transform(value: boolean, status?: ScheduleExecutionStatus): string {
        if (value) {
            if (status !== ScheduleExecutionStatus.Error) {
                return this._translateService.instant("nominations.acknowledged.sent");
            }
            else {
                return this._translateService.instant("nominations.acknowledged.failed");
            }
        } else {
            return this._translateService.instant("nominations.acknowledged.pending");
        }
    }
}
