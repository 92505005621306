// @ts-nocheck
import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { OauthService } from '@eznergy/webapi';
import { ApplicationService } from '@services/application.service';
import { AppPages, RouterService } from '@services/router.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-page-log-out',
    templateUrl: './page-log-out.component.html',
    styleUrls: ['./page-log-out.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PageLogOutComponent implements OnDestroy {

    private _subs: Subscription = new Subscription();

    constructor(private _appSvc: ApplicationService, private _routerSvc: RouterService, private readonly _oauthService: OauthService) {
        this._subs = this._oauthService.logout().subscribe(
            () => {
                this._afterLogout();
            },
            (err) => {
                this._afterLogout();
            }
        )
    }

    private _afterLogout(): void {
        this._appSvc.setContract(undefined, undefined);
        this._appSvc.setShipper(undefined);
        this._appSvc.setUser();
    }

    ngOnDestroy(): void {
        this._subs.unsubscribe();
    }

}
