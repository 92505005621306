// @ts-nocheck
import { Injectable } from "@angular/core";
import { SwUpdate, VersionEvent, VersionReadyEvent } from "@angular/service-worker";
import { Logger } from '@core/logger';
import { DateTime, Timespan } from "@eztypes/generic";
import { environment } from "../../environments/environment";
import { interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { CacheService } from './cache.service';
import { AppPages, RouterService } from './router.service';

export interface IUpdateData {
    readonly alert: boolean;
    readonly forced: boolean;
    readonly timeForce: number;
    readonly text: string;
    readonly version: string;
}
class UpdateData implements IUpdateData {
    readonly alert: boolean;
    readonly forced: boolean;
    readonly timeForce: number;
    readonly version: string;

    get text(): string {
        return this._getText();
    }
    private _textUpdate: string;

    private _dateForcedRefresh: DateTime;

    constructor(alert: boolean, forced: boolean, text?: string, timeForce: number = 15, version?: string) {
        this.alert = alert;
        this.forced = forced;
        this.timeForce = timeForce;
        this._dateForcedRefresh = DateTime.now().addMinutes(this.timeForce);
        this._textUpdate = text || "New version available - Please click the reload button";
        this.version = version;
    }

    private _getText(): string {
        if (this.forced) {
            const now = DateTime.now();
            const time = Math.abs(Math.floor(now.diff(this._dateForcedRefresh).totalSeconds));
            return `${this._textUpdate} (this application will be reloaded in ${time > 0 ? time : 0}s)`;
        }
        return this._textUpdate;
    }
}
@Injectable({ providedIn: 'root' })
export class SWService {

    get alertNewVersion(): Observable<IUpdateData> {
        return this._subjectAlertNewVersion.asObservable();
    }
    private readonly _subjectAlertNewVersion: Subject<IUpdateData> = new Subject<IUpdateData>();

    private _subForced: Subscription;
    private _subTimer: Subscription;

    constructor(private readonly _updates: SwUpdate, private readonly _logger: Logger, private readonly _cacheSvc: CacheService, private readonly _routerSvc: RouterService) {
        if (!navigator.onLine) {
            this._routerSvc.navigateTo(AppPages.Offline);
        }
        if (this._updates.isEnabled) {
            this._subTimer = interval(Timespan.fromMinutes(1).totalMilliseconds).subscribe(() => {
                this._updates.checkForUpdate().catch((error) => {
                    this._logger.warn("Error in check update service worker", error);
                });
            });
            this._checkIntervalUpdates();
        }
        const appVersionHash = environment.version;
        if (appVersionHash) {
            this._logger.info(`App build version ${appVersionHash}`);
        } else {
            this._logger.info("App build version unknown");
        }
    }

    destroy(): void {
        this._subjectAlertNewVersion.complete();

        this._clearSubForced();
        if (this._subTimer != null) {
            this._subTimer.unsubscribe();
            this._subTimer = undefined;
        }
    }

    private _checkIntervalUpdates(): void {
        if (this._updates.isEnabled) {
            this._updates.unrecoverable.subscribe((event) => {
                this._logger.warn("Service worker unrecoverable", new Error(event.reason));
                const updateData = new UpdateData(true, false, "New version available - Please click the reload button");
                this._subjectAlertNewVersion.next(updateData);
            });
            this._updates.versionUpdates.subscribe((event: VersionEvent) => {
                if (event.type === "VERSION_READY") {
                    const availableData = event.latestVersion.appData as UpdateData;
                    if (this._logger.isDebugEnabled()) {
                        this._logger.debug({ message: "sw new version available", data: event });
                    }
                    if (availableData) {
                        const updateData = new UpdateData(availableData.alert, availableData.forced, availableData.text, availableData.timeForce, availableData.version);
                        if (updateData.alert) {
                            this._subjectAlertNewVersion.next(updateData);
                        }
                        if (updateData.version) {
                            this._logger.info(`Next app build version ${updateData.version}`);
                        }
                        if (updateData.forced) {
                            this._clearSubForced();
                            this._subForced = timer(Timespan.fromMinutes(updateData.timeForce).totalMilliseconds).subscribe(() => {
                                this.activateNewVersion();
                            });
                        }
                    }

                }
            });
        }
    }

    activateNewVersion(): void {
        this._clearSubForced();
        this._updates.activateUpdate().then(() => {
            document.location.reload();
        });
    }

    private _clearSubForced(): void {
        if (this._subForced != null) {
            this._subForced.unsubscribe();
            this._subForced = undefined;
        }
    }
}
