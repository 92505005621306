// @ts-nocheck
import { ErrorHandler } from "@angular/core";
import { Logger } from '@core/logger';

export class EzHandleError implements ErrorHandler {

    constructor(private _logger: Logger) { }

    handleError(error: any) {
        if (this._logger) {
            if (typeof (error) === "string") {
                this._logger.fatal(error);
            } else {
                this._logger.fatal(undefined, error);
            }
        } else {
            throw error;
        }
    }
}
