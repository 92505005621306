// @ts-nocheck

export enum DashboardChartDisplay {
    Right,
    Bottom,
    None
}

export enum DashboardChartType {
    Line = 'line',
    Bar = 'bar',
    Area = 'area',
}
