<ez-form (onSubmit)="formSubmit()" [disabled]="disabled" [autofocus]="false">
    <ez-form-group *ngIf="sessionType === sessionTypes.Flex">
        <ez-label>Flex</ez-label>
        <ez-form-field>
            <ez-label>Pmax</ez-label>
            <ez-select-ts [type]="CurveType.Decimal" [(ngModel)]="pMax" [required]="true"
                [unitFamily]="powerUnitsFamily">
            </ez-select-ts>
        </ez-form-field>
        <ez-form-field>
            <ez-label>Pmin</ez-label>
            <ez-select-ts [type]="CurveType.Decimal" [(ngModel)]="pMin" [disabled]="pMinMax.checked"
                [unitFamily]="powerUnitsFamily" [required]="true">
            </ez-select-ts>
        </ez-form-field>
        <ez-form-field>
            <ez-label>Pmin = - Pmax</ez-label>
            <ez-checkbox #pMinMax [label]="'Pmin = - Pmax'" [(checked)]="pminEqualPmax"></ez-checkbox>
        </ez-form-field>
    </ez-form-group>
    <ez-form-group class="options">
        <ez-label>Initial schedule</ez-label>
        <ez-radio-group [(ngModel)]="selectedInitialVolume" [disabled]="disabled">
            <ez-radio [label]="'Calculated position'" [value]="selectedInitialVolumes.Calculated">
            </ez-radio>
            <ez-radio [label]="'Time serie values'" [value]="selectedInitialVolumes.TimeSerie">
                <ez-form-field>
                    <ez-label>Time-serie</ez-label>
                    <ez-select-ts [(ngModel)]="initialSchedule" [type]="CurveType.Decimal"
                        [disabled]="selectedInitialVolume == selectedInitialVolumes.Calculated || disabled" [required]="true"
                        [unitFamily]="powerUnitsFamily">
                    </ez-select-ts>
                </ez-form-field>
            </ez-radio>
        </ez-radio-group>
        <ez-form-field>
            <ez-label>Portfolio</ez-label>
            <ez-select [placeholder]="'Select a portfolio'" [(ngModel)]="portfolio" [disabled]="!portfolios || disabled">
                <ez-select-option *ngFor="let portfolio of portfolios" [value]="portfolio">{{ portfolio.name }}
                </ez-select-option>
            </ez-select>
        </ez-form-field>
    </ez-form-group>

    <ez-form-group>
        <ez-label>
            <ez-form-field>
                <ez-checkbox [(ngModel)]="flexPovVolume.gradientActivated" [disabled]="disabled">Time gradient</ez-checkbox>
            </ez-form-field>
        </ez-label>
        <ez-form-field>
            <ez-label>Gradients</ez-label>
            <ez-gradient-value [(ngModel)]="gradients" [disabled]="!flexPovVolume.gradientActivated || disabled"
                [required]="flexPovVolume.gradientActivated" [min]="0" [max]="100">
                <ez-label>{{ 'autotrading.session.parameters.volumes.gradient.label' | translate }}</ez-label>
                <span class='ez-factor'>{{ 'autotrading.session.parameters.volumes.gradient.factor' | translate }}</span>
            </ez-gradient-value>
        </ez-form-field>
    </ez-form-group>

    <ez-form-group>
        <ez-label>
            <ez-form-field>
                <ez-checkbox [(ngModel)]="flexPovVolume.hardLimits.activated" [disabled]="disabled">
                    {{'autotrading.session.parameters.volumes.hardLimits.label' | translate}}</ez-checkbox>
            </ez-form-field>
        </ez-label>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.volumes.hardLimits.max' | translate }}</ez-label>
            <ez-select-ts [type]="CurveType.Decimal" [unitFamily]="powerUnitsFamily" [required]="true"
                [(ngModel)]="flexPovVolume.hardLimits.max" [disabled]="!flexPovVolume.hardLimits.activated || disabled">
            </ez-select-ts>
        </ez-form-field>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.volumes.hardLimits.min' | translate }}</ez-label>
            <ez-select-ts [type]="CurveType.Decimal" [unitFamily]="powerUnitsFamily" [required]="true"
                [(ngModel)]="flexPovVolume.hardLimits.min" [disabled]="!flexPovVolume.hardLimits.activated || disabled">
            </ez-select-ts>
        </ez-form-field>
    </ez-form-group>

    <!-- manual volume limits -->
    <ez-form-group>
        <ez-label>
            <ez-form-field>
                <ez-checkbox [(ngModel)]="flexPovVolume.manualLimits.activated" [disabled]="disabled">
                    {{'autotrading.session.parameters.volumes.manualLimits.label' | translate}}
                </ez-checkbox>
            </ez-form-field>
        </ez-label>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.volumes.manualLimits.max' | translate }}
            </ez-label>
            <ez-select-ts [type]="CurveType.Decimal" [unitFamily]="powerUnitsFamily"
                [required]="flexPovVolume.manualLimits.activated" [(ngModel)]="flexPovVolume.manualLimits.max"
                [disabled]="!flexPovVolume.manualLimits.activated || disabled">
            </ez-select-ts>
        </ez-form-field>
    </ez-form-group>

    <ez-footer *ngIf="!hideFooter">
        <ez-button [label]="'general.reset' | translate" ez-form-cancel></ez-button>
        <ez-button [label]="'general.save' | translate" [severity]="'primary'" ez-form-submit></ez-button>
    </ez-footer>
</ez-form>
