// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { EarlyTerminationRules, IEarlyTerminationRules, IUnit, LogoutBehaviour, TimeSeriesType, SessionType, TimeSeriesUpdateDelay } from "@eztypes/webapi";
import { FormBase } from "src/app/pages/form/core/form-base";
import * as _ from 'lodash';
import { Timespan, TimeUnit } from '@eztypes/generic';

@Component({
    selector: 'at-early-termination-form',
    templateUrl: './early-termination.component.html',
    styleUrls: ['./early-termination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AtEarlyTerminationForm extends FormBase {

    @Output() valueChange: EventEmitter<EarlyTerminationRules> = new EventEmitter<EarlyTerminationRules>();

    timeUnits: IUnit[] = [];
    currencyUnits: IUnit[] = [];
    energyUnits: IUnit[] = [];
    energyPriceUnits: IUnit[] = [];
    powerUnits: IUnit[] = [];

    private readonly _currencyFamilyId = 9;
    private readonly _energyPriceFamilyId = 1;
    private readonly _energyFamilyId = 11;
    private readonly _timeFamilyId = 27;
    private readonly _powerFamilyId = 21;

    @Input()
    get units() {
        return this.unitsArray;
    }
    set units(value: IUnit[]) {
        if (value) {
            this.unitsArray = value;
            this._dispatchUnits(this.unitsArray);
            this._cdr.detectChanges();
        }
    }
    unitsArray: IUnit[] = [];

    @Input()
    get value(): IEarlyTerminationRules {
        return this._value;
    }
    set value(value: IEarlyTerminationRules) {
        if (this.value !== value) {
            this.viewUpdate = value != undefined;
            this._value = value;
            this._reset();
            this._cdr.markForCheck();
        }
    }
    private _value: IEarlyTerminationRules;
    tempValue: IEarlyTerminationRules = new EarlyTerminationRules(null, null, null, null, null, null, null, null, null, LogoutBehaviour.Cancel);

    @Input()
    set sessionType(type: SessionType) {
        if (this._sessiontType !== type) {
            this._sessiontType = type;
            this._reset();
            this._cdr.markForCheck();
        }
    }
    private _sessiontType: SessionType = SessionType.Position;

    minDelay = new Timespan(30, TimeUnit.Seconds);
    defaultTimeUnit = TimeUnit.Seconds;

    constructor(private _cdr: ChangeDetectorRef) {
        super();
        this._reset();
    }

    formSubmit() {
        this._value = _.cloneDeep(this.tempValue);
        this._reset();
        this.valueChange.emit(this._value);
        this.onSubmit.emit();
    }

    private _reset(): void {
        if (this._value == null) {
            this.tempValue = new EarlyTerminationRules(null, null, null, null, null, null, null, null, null, LogoutBehaviour.Cancel);
            this.tempValue.timeSeriesUpdateDelays = [];
            this.tempValue.timeSeriesUpdateDelays.push(new TimeSeriesUpdateDelay(false, false, TimeSeriesType.InitialPosition));
            if (this.sessionType === SessionType.Flex) {
                this.tempValue.timeSeriesUpdateDelays.push(new TimeSeriesUpdateDelay(false, false, TimeSeriesType.FlexPmax));
                this.tempValue.timeSeriesUpdateDelays.push(new TimeSeriesUpdateDelay(false, false, TimeSeriesType.FlexPmin));
            }
            this.tempValue.timeSeriesUpdateDelays.push(new TimeSeriesUpdateDelay(false, false, TimeSeriesType.AbsoluteBuyLimit));
            this.tempValue.timeSeriesUpdateDelays.push(new TimeSeriesUpdateDelay(false, false, TimeSeriesType.AbsoluteSellLimit));
        } else {
            this.tempValue = _.cloneDeep(this._value);
        }
    }

    private _dispatchUnits(value: IUnit[]) {
        this.currencyUnits = [];
        this.powerUnits = [];
        this.energyPriceUnits = [];
        this.energyUnits = [];
        this.timeUnits = [];
        let length = value.length;
        for (let i = 0; i < length; i++) {
            switch (value[i].family.id) {
                case this._currencyFamilyId:
                    this.currencyUnits.push(value[i]);
                    break;
                case this._powerFamilyId:
                    this.powerUnits.push(value[i]);
                    break;
                case this._energyPriceFamilyId:
                    this.energyPriceUnits.push(value[i]);
                    break;
                case this._timeFamilyId:
                    this.timeUnits.push(value[i]);
                    break;
                case this._energyFamilyId:
                    this.energyUnits.push(value[i]);
                    break;
            }
        }
    }
}
