import { Component, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ICurveBase, CurveType } from '@eztypes/webapi';
import * as _ez from "@eznergy/core";
import { EzNotificationService } from '@eznergy/toaster';
import { BaseCardComponent } from '../base-card.component';

@Component({
    selector: "timeserie-card",
    templateUrl: "./timeserie.card.html",
    styleUrls: ["./timeserie.card.scss", "../cards.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TimeserieCard extends BaseCardComponent<ICurveBase> {

    readonly tsTypes = CurveType;

    constructor(cdr: ChangeDetectorRef, private _notifSvc: EzNotificationService) {
        super(cdr);
    }

    copyIdToClipboard(): void {
        _ez.writeToClipboard(this.value.id.toString());
        this._notifSvc.success("Timeserie Id was copied to the clipboard");
    }
}
