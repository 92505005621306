import { IUserPreferenceClassDecorator, UPPropertySave } from "@core/decorators";
import { deserialize, serialize } from "@eznergy/core";
import { Observable } from "rxjs";
import { AppPreferences, AppPreferencesInfo } from "./app-preferences.model";
import { IVersionContainer, VersionContainer } from "./core.model";
import { WrapperPreferences } from "@eztypes/webapi";
import { DateTime } from '@eztypes/generic';

export class AppPreferencesInfoContainer implements IVersionContainer {
    static load(object: string | WrapperPreferences): AppPreferencesInfo {
        if (typeof object === "string")
            return deserialize<AppPreferencesInfo>(object, AppPreferencesInfo) as AppPreferencesInfo;
        return object.preferences as unknown as AppPreferencesInfo;
    }

    readonly version: number = 1;
    readonly preferences: string | AppPreferencesInfo | undefined;
    readonly lastSavedDate: DateTime | undefined;
}

export class AppPreferencesContainer implements IVersionContainer {

    static load(object: string | WrapperPreferences): AppPreferencesContainer {
        if (object == null) {
            return new AppPreferencesContainer();
        }
        let version: number;
        if (typeof object == "string") {
            const prefInfo = deserialize<VersionContainer>(object, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else {
            version = object.version;
        }
        const prefInfo = AppPreferencesInfoContainer.load(object);
        switch (version) {
            case 1:
                return new AppPreferencesContainer(AppPreferences.load(prefInfo));
            default:
                return new AppPreferencesContainer();
        }
    }

    @UPPropertySave
    readonly preferences: AppPreferences;
    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    lastSavedDate: DateTime | undefined;

    get changes(): Observable<void> {
        return (this.preferences as any as IUserPreferenceClassDecorator).changes;
    }

    constructor(appPreferences: AppPreferences = new AppPreferences()) {
        this.preferences = appPreferences;
    }

    stringifyOld(): string {
        return serialize({
            "version": this.version,
            "preferences": (this.preferences as any as IUserPreferenceClassDecorator).stringify()
        });
    }

    createWrapperPreferences(): WrapperPreferences {
        return {
            version: this.version,
            preferences: (this.preferences as any as IUserPreferenceClassDecorator).formatPreferencesObject(),
        } as WrapperPreferences;
    }
}
