// @ts-nocheck
import { Injectable } from "@angular/core";
import { I18nService, IFormatDate, typeFormatDate } from "@eznergy/components";
import { DateRange, DateTime, TimeUnit, TimeZone } from "@eztypes/generic";
import { ICalendar } from "@eztypes/webapi";
import { IFormatDate as FormatDate } from "@eztypes/generic";
import { ApplicationService } from "./application.service";
@Injectable({ providedIn: 'root' })
export class DateService {

    constructor(private readonly _appSvc: ApplicationService, private readonly _i18nSvc: I18nService) { }

    format(value: DateTime | DateRange, opts: { format?: typeFormatDate | IFormatDate | FormatDate, timezone?: TimeZone }): string {
        const tz: TimeZone = this._appSvc.preferences.tzActivate ? this._appSvc.timezone : opts.timezone;
        return this._i18nSvc.formatDate(value, { format: opts.format, timezone: tz });
    }

    setDateByCalendar(date: DateTime, calendar: ICalendar, options?: { startOfDay?: boolean, endOfDay?: boolean, useTza?: boolean }): DateTime {
        const tz: TimeZone = options?.useTza && this._appSvc.preferences.tzActivate ? this._appSvc.timezone : TimeZone.create(calendar.ianaName);
        date = date.setTimezone(tz);
        if (options?.startOfDay)
            date = date.startOf(TimeUnit.Days);
        if (options?.endOfDay)
            date = date.endOf(TimeUnit.Days);
        if (options?.endOfDay || options?.startOfDay)
            date = date.addHours(calendar.offset, true);
        return date;
    }

    /**
     *  @deprecated
     */
    dateDiff(date1: any, date2: any): TimeSpan {
        let diff = new TimeSpan();
        let tmp = date2 - date1;

        tmp = Math.floor(tmp / 1000);           // Number of second beetween two dates
        diff.seconds = tmp % 60;                    // Format seconds

        tmp = Math.floor((tmp - diff.seconds) / 60);    // Number of minuts
        diff.minutes = tmp % 60;                        // Format minuts

        tmp = Math.floor((tmp - diff.minutes) / 60);    // Number of hours
        diff.hours = tmp % 24;                       // Format hours

        tmp = Math.floor((tmp - diff.hours) / 24);
        diff.days = tmp;

        return diff;
    }
}

export class TimeSpan {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}
