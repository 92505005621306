// @ts-nocheck
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Direction } from '../transport-direction/transport-direction.icon';

@Component({
    selector: 'transport-gaz-entry-icon',
    templateUrl: './transport-gaz-entry.icon.html',
    styleUrls: ['./transport-gaz-entry.icon.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportGazEntryIcon {
    Direction = Direction;

    constructor() { }
}
