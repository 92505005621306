import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { CurveType } from "@eztypes/webapi";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageAdminPreferenceInfo {
    static load(preferences: string | PageAdminPreferenceInfo): PageAdminPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageAdminPreferenceInfo>(preferences, PageAdminPreferenceInfo) as PageAdminPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    curveTypes: CurveType[] | undefined;
    isComputed: boolean | undefined;
    isLocked: boolean | undefined;
    search: string | undefined;
    groupId: number | undefined;
}

@UserPreferenceClassDecorator
export class PageAdminPreferenceInfoPreference implements IPreferencePage {
    static load(preferences: string | PageAdminPreferenceInfo): PageAdminPreferenceInfoPreference {
        if (!preferences) {
            return new PageAdminPreferenceInfoPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageAdminPreferenceInfo.load(preferences);
                return new PageAdminPreferenceInfoPreference(
                    v1.curveTypes,
                    v1.isComputed,
                    v1.isLocked,
                    v1.search,
                    v1.groupId
                );
            default:
                return new PageAdminPreferenceInfoPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    curveTypes: CurveType[] | undefined;
    isComputed: boolean | undefined;
    isLocked: boolean | undefined;
    search: string | undefined;
    groupId: number | undefined;

    constructor(
        curveTypes?: CurveType[] | undefined,
        isComputed?: boolean | undefined,
        isLocked?: boolean | undefined,
        search?: string | undefined,
        groupId?: number | undefined
    ) {
        this.curveTypes = curveTypes;
        this.isComputed = isComputed;
        this.isLocked = isLocked;
        this.search = search;
        this.groupId = groupId;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.curveTypes = undefined;
            this.isComputed = undefined;
            this.isLocked = undefined;
            this.search = undefined;
            this.groupId = undefined;
        }
    }
}

