// @ts-nocheck

import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "first-content",
    template: '<ng-content select="menu-page"></ng-content><div class="content-page"><ng-content></ng-content></div>',
    styleUrls: ["./first-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,

})
export class FirstContent {

    constructor() { }

}
