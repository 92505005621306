<div class="title">
    {{value?.name}}
</div>
<div class="row">
    <span class="label">{{'general.id' | translate}} : </span>
    <div class="value">
        <div class="value-clipboard" (click)="copyIdToClipboard()" [ezTooltip]="'general.clipboard-copy' | translate">
            <span>{{value?.id}}</span>
            <ez-icon name="copy" [regular]="true"></ez-icon>
        </div>
    </div>
    <ez-icon name="square-root-alt" *ngIf="value?.isComputed" [ezTooltip]="'timeseries.global.calculated' | translate"
        class="icon-warning">
    </ez-icon>
    <ez-icon name="lock" *ngIf="value?.isLocked" [ezTooltip]="'timeseries.global.locked' | translate"
        class="icon-error"></ez-icon>
</div>
<div class="row">
    <span class="label">{{'general.type' | translate }} : </span>
    <span class="value">
        {{'timeseries.types.'+ value?.type | translate}}
    </span>
</div>
<div class="row" *ngIf="value?.unit">
    <span class="label">{{'general.unit' | translate }} : </span>
    <span class="value">
        {{ value.unit.name}}
    </span>
</div>
<div class="row">
    <span class="label">{{'timeseries.global.lastUpdate' | translate}} : </span>
    <span class="value">
        <date [value]="value?.lastUpdate"></date>
    </span>
</div>
<div class="row">
    <span class="label">{{'timeseries.global.groups' | translate}} : </span>
    <ez-tag *ngFor="let group of value?.groups" [mode]="'small'">{{group.name}}</ez-tag>
</div>
