import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IDateRange } from "@eztypes/generic";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageBalancingMessagesPreferenceInfo {
    static load(preferences: string | PageBalancingMessagesPreferenceInfo): PageBalancingMessagesPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageBalancingMessagesPreferenceInfo>(preferences, PageBalancingMessagesPreferenceInfo) as PageBalancingMessagesPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    maxItems: number | undefined;
}

@UserPreferenceClassDecorator
export class PageAssetMessagesPreference implements IPreferencePage {
    static load(preferences: string | PageBalancingMessagesPreferenceInfo): PageAssetMessagesPreference {
        if (!preferences) {
            return new PageAssetMessagesPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageBalancingMessagesPreferenceInfo.load(preferences);
                return new PageAssetMessagesPreference(v1.maxItems);
            default:
                return new PageAssetMessagesPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    maxItems: number | undefined;

    deliveryDates: IDateRange | undefined;
    withDate: boolean | undefined;

    constructor(maxItems: number = 100) {
        this.maxItems = maxItems;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byDate) {
            this.deliveryDates = undefined;
            this.withDate = undefined;
        }
        if (options.all) {
            this.maxItems = undefined;
        }
    }
}

