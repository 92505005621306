// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ICountry, IGrid, ISession, Session } from "@eztypes/webapi";
import { FormBase } from "src/app/pages/form/core/form-base";
import * as _ from "lodash";
import * as _ez from "@eznergy/core";

export class CountryGrids {
    country: ICountry;
    grids: IGrid[];

    constructor(country, grids) {
        this.country = country;
        this.grids = grids;
    }
}

@Component({
    selector: 'at-country-market-area-form',
    templateUrl: './country-market-area.component.html',
    styleUrls: ['./country-market-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AtCountryMarketAreaForm extends FormBase {

    @Output() valueChange: EventEmitter<ISession> = new EventEmitter<ISession>();

    @Input()
    get value(): Session {
        return this._session;
    }
    set value(session: Session) {
        if (this.value != session) {
            this._session = session;
            this._updateCountrySelected();
            this._cdr.markForCheck();
        }
    }
    private _session: Session;

    @Input()
    get countries(): CountryGrids[] {
        return this._countries;
    }
    set countries(value: CountryGrids[]) {
        if (this.countries !== value) {
            this._countries = value;
            this._updateCountrySelected();
            this._cdr.markForCheck();
        }
    }
    private _countries: CountryGrids[];

    gridList: IGrid[] = [];

    constructor(private _cdr: ChangeDetectorRef) {
        super();
    }

    get countrySelected(): CountryGrids {
        return this._countrySelected;
    }
    set countrySelected(country: CountryGrids) {
        this._countrySelected = country;
        if (!this._countrySelected) {
            this.gridList = [];
        } else {
            this.gridList = _.clone(country.grids);
        }
    }
    private _countrySelected: CountryGrids;

    private _updateCountrySelected(): void {
        if (this._countries && this._countries.length && this._session && this._session.balancingGrid && this._session.balancingGrid.country) {
            const country = _.find(this._countries, (c) => _ez.isSame(c.country, this._session.balancingGrid.country));
            this.countrySelected = country;
        }
    }

    formSubmit() {
        this.valueChange.emit(this._session);
        this.onSubmit.emit();
    }

}
