// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subscriptions } from "@core";
import { IconSize } from "@enums/icon-size.enum";
import { IconType } from "@enums/icon-type.enum";
import * as _ez from "@eznergy/core";
import { EzNotificationService } from "@eznergy/toaster";
import { ApiService } from "@eznergy/webapi";
import { IAvailableConfiguration, IAvailableMarket, IExchangeUser, IGrid, IMaterial, IOrganisation, ISession, Session, SessionNameStatus, SessionType, UserRight } from "@eztypes/webapi";
import { FormBase } from '@forms/core/form-base';
import { Exchange } from '../../../models/exchange.model';
import { ApplicationService } from '@services/application.service';
import { StaticService } from "@services/static.service";
import * as _ from "lodash";
import { Subscription } from 'rxjs';
import { AtCountryMarketAreaForm, CountryGrids } from './country-market-area/country-market-area.component';
import { AtNemosForm } from './nemos/nemos.component';
import { AtSessionForm } from './session/session.component';

enum FormPage {
    Session = 1,
    Nemos = 2,
    Country = 3,
}

@Component({
    selector: 'at-session-create-form',
    templateUrl: './session-create.component.html',
    styleUrls: ['./session-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.etape-1]": "selectedForm === 1",
        "[class.etape-2]": "selectedForm === 2",
        "[class.etape-3]": "selectedForm === 3",
    }
})
export class AtSessionCreateForm implements OnDestroy {

    @Output() onCreateSession: EventEmitter<ISession> = new EventEmitter<ISession>();

    @Input()
    get markets(): IAvailableMarket[] {
        return this._markets;
    }
    set markets(value: IAvailableMarket[]) {
        if (this.markets !== value) {
            this._markets = value;
            this.populateWithMarkets();
            this._cdr.markForCheck();
        }
    }
    private _markets: IAvailableMarket[];

    @Input()
    get shippers(): IOrganisation[] {
        return this._shippers;
    }
    set shippers(value: IOrganisation[]) {
        if (this.shippers !== value) {
            this._shippers = value;
            this._cdr.markForCheck();
        }
    }
    private _shippers: IOrganisation[];

    @Input()
    get session(): ISession {
        return this._session;
    }
    set session(value: ISession) {
        if (value !== this.session) {
            this._session = value;
            this._duplicateSession();
            this._cdr.markForCheck();
        }
    }
    private _session: ISession;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        if (this._disabled !== value) {
            this._disabled = value;
            this._cdr.detectChanges();
        }
    }
    private _disabled: boolean = false;

    @ViewChild(AtSessionForm, { static: true }) sessionForm: AtSessionForm;
    @ViewChild(AtNemosForm, { static: true }) nemosForm: AtNemosForm;
    @ViewChild(AtCountryMarketAreaForm, { static: true }) countryMarketForm: AtCountryMarketAreaForm;

    get form(): FormBase {
        if (this.sessionForm && this.selectedForm === 1)
            return this.sessionForm;
        else if (this.nemosForm && this.selectedForm === 2)
            return this.nemosForm;
        else if (this.countryMarketForm && this.selectedForm === 3)
            return this.countryMarketForm;
    }

    get selectedForm(): FormPage {
        return this._selectedForm;
    }
    set selectedForm(value: FormPage) {
        this._selectedForm = value;
        setTimeout(() => {
            this.form.focus();
        }, 200);
    }
    private _selectedForm: FormPage = FormPage.Session;

    formpages = FormPage;
    enabledForms: FormPage[] = [FormPage.Session];
    errorsForm: FormPage[] = [];

    get sessionItem(): ISession {
        return this._sessionItem;
    }
    set sessionItem(value: ISession) {
        this._sessionItem = value || new Session(undefined, undefined, undefined, undefined, undefined, undefined, undefined, SessionType.Position, undefined, undefined);
        this._updateDataBySelection();
    }
    private _sessionItem: Session;

    exchangeList: Exchange[] = [];
    commodities: IMaterial[] = [];
    countries: CountryGrids[] = [];

    private _subs: Subscriptions = new Subscriptions;
    isLoading: boolean = false;

    constructor(
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private _cdr: ChangeDetectorRef,
        private _staticService: StaticService,
        private _srvNotification: EzNotificationService
    ) {
        this._sessionItem = new Session(undefined, undefined, undefined, undefined, undefined, undefined, undefined, SessionType.Position, undefined, undefined);
        this._sessionItem.clientId = this._appSvc.contract.id;
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    private _duplicateSession(): void {
        this._autoRename(this._session.name);
    }

    private _subValidityName: Subscription;
    private _autoRename(name: string, id?: number): void {
        this.isLoading = true;
        if (name.length > 135) {
            name = name.substring(0, 135);
        }
        let rename = name.replace(/\(duplicate(?:|\s\d*)\)/, "").trim() + (id ? " (duplicate " + id + ")" : " (duplicate)");
        if (this._subValidityName) {
            this._subValidityName.unsubscribe();
            this._subValidityName = undefined;
        }
        this._subValidityName = this._api.autotrading.sessions.checkValidatyName(this._appSvc.contract.id, rename).subscribe((status: SessionNameStatus) => {
            if (status === SessionNameStatus.Ok) {
                this.sessionItem = _.clone(this._session);
                this.sessionItem.name = rename;
                this.isLoading = false;
                this._cdr.detectChanges();
            } else {
                id != undefined ? (++id) : (id = 2);
                this._autoRename(name, id);
            }
        }, () => { }, () => {
            this._subValidityName = undefined;
        });
    }

    private _marketByMaterial: { [id: number]: Exchange[] };
    private _countryByMarketUser: { [id: string]: CountryGrids[] };
    private populateWithMarkets(): void {

        this._marketByMaterial = {};
        this._countryByMarketUser = {};

        let configs = _.flatMap(this._markets, (market: IAvailableMarket) => {
            return market.users;
        });
        let grids: IGrid[] = _.uniqBy(_.flatMap(configs, (user: IAvailableConfiguration) => {
            return user.areas;
        }), 'id');

        let materials: IMaterial[] = _.uniqBy(_.flatMap(grids, (area: IGrid) => {
            return area.material;
        }), 'id');

        // let countriesGrid: CountryGrids[] = [];
        // let countries: ICountry[] = _.orderBy(_.uniqBy(_.flatMap(grids, (area: IGrid) => {
        //     let country = _.cloneDeep(area.country);
        //     return country;
        // }), 'id'), ["name"], ["asc"]);

        // _.forEach(countries, (country: ICountry) => {
        //     country.logo.icon = this._staticService.getUriIcon(IconType.Country, IconSize.Size_32, country.logo.icon.replace(".png", ""));
        //     countriesGrid.push(new CountryGrids(country, []));
        // });

        _.forEach(materials, (material: IMaterial) => {
            let markets = _.filter(this._markets, { users: [{ areas: [{ material: { id: material.id } }] }] }) as IAvailableMarket[];
            let exchange: Exchange[] = _.map(markets, (market: IAvailableMarket) => {
                let logo: string = this._staticService.getUriIcon(IconType.Exchange, IconSize.Size_64, <string>market.exchange);
                let users: IExchangeUser[] = _.flatMap(market.users, (user: IAvailableConfiguration) => {
                    return user.user;
                });

                _.forEach(users, (user: IExchangeUser) => {
                    let cts: CountryGrids[] = [];
                    let conf = _.find(market.users, (u: IAvailableConfiguration) => {
                        return u.user.id === user.id;
                    });
                    if (conf && conf.areas) {
                        let gds = _.orderBy(conf.areas, ["name"], ["asc"]);
                        _.forEach(gds, (g: IGrid) => {
                            let gClone = _.cloneDeep(g);
                            if (gClone.gridLogo) {
                                gClone.gridLogo.icon = this._staticService.getUriIcon(IconType.Grid, IconSize.Size_32, gClone.gridLogo.icon.replace(".png", ""));
                            }
                            let c = _.find(cts, (c: CountryGrids) => {
                                return c.country.id === gClone.country.id;
                            });
                            if (c == undefined) {
                                let country = _.clone(gClone.country);
                                country.logo.icon = this._staticService.getUriIcon(IconType.Country, IconSize.Size_32, country.logo.icon.replace(".png", ""));
                                c = new CountryGrids(country, []);
                                cts.push(c);
                            }
                            c.grids.push(gClone);
                        });
                    }

                    cts = _.orderBy(_.filter(cts, (c: CountryGrids) => {
                        return c.grids.length > 0;
                    }), (c: CountryGrids) => {
                        return c.country.name;
                    });

                    this._countryByMarketUser[`${user.id}-${user.name}`] = cts;

                });
                return new Exchange(market.exchange, logo, users);
            });
            this._marketByMaterial[material.id] = exchange;

        });

        this.commodities = _.cloneDeep(materials);
        this._updateDataBySelection();
    }

    private _updateDataBySelection(): void {
        if (this._marketByMaterial && this._countryByMarketUser) {
            if (this._sessionItem.material) {
                this.exchangeList = _.cloneDeep(this._marketByMaterial[this._sessionItem.material.id]);
                let exchangeSelected = _.find(this.exchangeList, (value: Exchange) => {
                    return _ez.isSame(value.token, this._sessionItem.exchange);
                });
                if (!exchangeSelected) {
                    this._sessionItem.exchange = undefined;
                    this._sessionItem.exchangeUser = undefined;
                    this._sessionItem = _.clone(this._sessionItem);
                } else if (!_.some(exchangeSelected.exchangeUsers, (value: IExchangeUser) => {
                    return _ez.isSame(value, this._sessionItem.exchangeUser);
                })) {
                    this._sessionItem.exchangeUser = undefined;
                    this._sessionItem = _.clone(this._sessionItem);
                }
                this._addPage(FormPage.Nemos);
            } else {
                this.exchangeList = [];
                this._sessionItem.exchange = undefined;
                this._sessionItem.exchangeUser = undefined;
                this._sessionItem = _.clone(this._sessionItem);
                this._removePage(FormPage.Nemos);
            }

            if (this._sessionItem.exchangeUser) {
                this.countries = _.cloneDeep(this._countryByMarketUser[`${this._sessionItem.exchangeUser.id}-${this._sessionItem.exchangeUser.name}`]);
                let grids = _.flatMap(this.countries, (value) => { return value.grids; });
                if (this._sessionItem.balancingGrid && !_.some(grids, (value: IGrid) => {
                    return _ez.isSame(value, this._sessionItem.balancingGrid);
                })) {
                    this._sessionItem.balancingGrid = undefined;
                    this._sessionItem = _.clone(this._sessionItem);
                }
                this._addPage(FormPage.Country);
            } else {
                this.countries = [];
                this._sessionItem.balancingGrid = undefined;
                this._sessionItem = _.clone(this._sessionItem);
                this._removePage(FormPage.Country);
            }
        }
    }

    onPreviousPage(): void {
        if (this._selectedForm > 1)
            this._selectedForm--;
    }

    resetForm(): void {
        if (this.form) {
            this.form.cancel();
        }
    }

    private _sendSubmit: boolean = false;
    submitForm(): void {
        this._sendSubmit = this.selectedForm === FormPage.Country;
        if (this.form) {
            this.form.submit();
        }
    }

    onFormSubmit(page?: FormPage): void {
        if (page && this.selectedForm + 1 === page) {
            this._setPage(page);
        } else if (this._sendSubmit && this.enabledForms.length === 3 && this.selectedForm === FormPage.Country) {
            this._submitSession();
        }
    }

    onError(page: FormPage): void {
        switch (page) {
            case FormPage.Session:
                this._removePage(FormPage.Nemos);
            case FormPage.Nemos:
                this._removePage(FormPage.Country);
            default:
                break;
        }
    }

    changePage(page: FormPage): void {
        this._sendSubmit = false;
        if (page !== this.selectedForm && this.form) {
            let oldForm = this.form;
            this.selectedForm = page;
            oldForm.submit();
        }
    }

    private _submitSession(): void {
        if (!this._appSvc.hasRight(UserRight.SessionCreate)) return;
        this._subs.clearSub('sessionCreate');
        this.isLoading = true;
        if (this._session) {
            let sub: Subscription = this._api.autotrading.sessions.duplicate(this._appSvc.contract.id, this._session, this.sessionItem).subscribe(
                (resp) => {
                    this._srvNotification.success("Session created successfully");
                    this.onCreateSession.emit(resp);
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                }
            );
            this._subs.push('sessionCreate', sub);
        } else {
            let sub: Subscription = this._api.autotrading.sessions.create(this._appSvc.contract.id, this.sessionItem).subscribe(
                (resp) => {
                    this._srvNotification.success("Session created successfully");
                    this.onCreateSession.emit(resp);
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
            this._subs.push('sessionCreate', sub);
        }
    }

    private _addPage(page: FormPage): void {
        if (!this.enabledForms.includes(page))
            this.enabledForms.push(page);
    }

    private _removePage(page: FormPage): void {
        if (this.enabledForms.includes(page)) {
            _.remove(this.enabledForms, (value) => { return _ez.isSame(value, page); });
            if (this.selectedForm === page) {
                this._setPage();
            }
        }
    }

    private _setPage(page?: FormPage): void {
        if (page && this.enabledForms.includes(page)) {
            this.selectedForm = page;
        } else {
            this.selectedForm = _.last(this.enabledForms);
        }
    }
}
