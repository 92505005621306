<ez-table [data]="portfolios" *ngIf="portfolios && portfolios.length && portfolioTotal && portfolioTotal.length"
    autofit="false">
    <ez-row-header>
        <th class="border-left last-fixed">{{'balancing.ets.ol.column.date' | translate}}</th>
        <th class="border-right">{{'balancing.ets.ol.column.etsPrice' | translate}}
            ({{'balancing.ets.ol.column.unitEMW' |
            translate}})</th>

        <th class="col-separate"></th>

        <th class="border-left" [ezTooltip]="''">{{'balancing.ets.ol.column.linearVolume' | translate}}
            ({{'balancing.ets.ol.column.unitMW' | translate}})</th>
        <th class="border-right">{{'balancing.ets.ol.column.blockVolume' | translate}}
            ({{'balancing.ets.ol.column.unitMW' | translate}})</th>

        <th *ngIf="display" class="col-separate"></th>

        <th *ngIf="display" class="border-left last-fixed placeholder"></th>

        <th *ngFor="let block of portfolioBlockOrders"
            ezTooltip="{{'balancing.ets.ol.column.comment' | translate}}: {{block.comment}}{{'\n'}}{{'balancing.ets.ol.column.etsId' | translate}}: {{block.etsID}}">
            {{block.name}} ({{'balancing.ets.ol.column.unitMW' | translate}})
            <ez-icon-font name="question-circle" [regular]="true"></ez-icon-font>
        </th>
    </ez-row-header>

    <ez-column property="date" [fixed]='true'>
        <th *ezTbCell="let item" class="border-left last-fixed"><span>{{item.date| ezdate}}</span></th>
    </ez-column>

    <ez-column property="globalPrice">
        <td *ezTbCell="let item" class="border-right"><span>{{item.globalPrice}}</span></td>
    </ez-column>

    <ez-column property="separator1">
        <td class="col-separate" *ezTbCell="let item"></td>
    </ez-column>

    <ez-column property="linearVolume">
        <td *ezTbCell="let item" class="border-left"><span>{{item.linearVolume}}</span></td>
    </ez-column>

    <ez-column property="blockVolume">
        <td *ezTbCell="let item" class="border-right"><span>{{item.blockVolume}}</span></td>
    </ez-column>

    <ez-column *ngIf="display" property="separator2">
        <td class="col-separate" *ezTbCell="let item"></td>
    </ez-column>

    <ez-column *ngIf="display" property="placeholder">
        <td class="border-left last-fixed placeholder" *ezTbCell="let item"></td>
    </ez-column>

    <ez-column property="portfolioBlocks" *ngFor="let col of portfolios[0].portfolioBlocks; index as i;"
        [indexCell]="i">
        <td *ezTbCell="let item">{{item}}</td>
    </ez-column>

    <ez-row-footer *ngFor="let total of portfolioTotal; index as i;last as last" [ngClass]="{'last-row': last}">
        <th class="border-left last-fixed">
            <strong>{{total.label}}</strong>
        </th>
        <th class="border-right"><span>{{total.price}} {{'balancing.ets.ol.column.unitEMW' | translate}}</span></th>
        <th class="col-separate"></th>
        <th class="border-left"><span>{{total.linear}} {{'balancing.ets.ol.column.unitMWH' | translate}}</span></th>
        <th class="border-right"><span>{{total.block}} {{'balancing.ets.ol.column.unitMWH' | translate}}</span></th>
        <th *ngIf="display" class="col-separate"></th>
        <th *ngIf="display" class="border-left last-fixed" [ngClass]="{'icon-right': i == 1}"
            ezTooltip="{{ i == 1 ? ('balancing.ets.ol.column.wapDetail' | translate) : undefined}}">
            <strong>{{total.label2}}</strong>
            <ez-icon-font *ngIf="i == 1" name="question-circle" [regular]="true"></ez-icon-font>
        </th>
        <th *ngFor="let data of portfolioTotal[i].data"><span>{{data}}</span></th>
    </ez-row-footer>
</ez-table>
