// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscriptions } from '@core';
import { BlockQuote, EzInputTextComponent, EzMarkdownComponent, Heading, ItemList, MdElement } from '@eznergy/components';
import { EzNotificationService } from "@eznergy/toaster";
import { ApiService } from '@eznergy/webapi';
import { INumberRange, NumberRange } from '@eztypes/generic';
import { HelpEntry, IHelpEntry } from '@eztypes/webapi';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '@services/application.service';
import * as _ from "lodash";
import { LineTypeStart } from './models/models';
import { ExternalLink, FormatType, LinkType, ListType, SubmitType, ViewType } from './toolbar/editor-toolbar.component';

@Component({
    selector: 'ez-markdown-editor',
    templateUrl: './markdown-editor.component.html',
    styleUrls: ['./markdown-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class MarkdownEditorComponent implements OnInit {

    @ViewChild(EzInputTextComponent, { static: true }) readonly input: EzInputTextComponent;
    @ViewChild(EzMarkdownComponent, { static: true }) readonly reader: EzMarkdownComponent;

    @Output() readonly save: EventEmitter<void> = new EventEmitter();
    @Output() readonly cancel: EventEmitter<void> = new EventEmitter();

    @Input()
    get value(): IHelpEntry { return this._value; }
    set value(value: IHelpEntry) {
        if (value !== this.value) {
            this._value = value;
            this._textAreaContent = this._textAreaContentCache = this._value ? this._value.content : '';
            this._cdr.markForCheck();
        }
    }
    private _value: IHelpEntry;

    get textAreaContent(): string {
        return this._textAreaContent;
    }
    private _textAreaContent: string = '';
    private _textAreaContentCache: string = '';

    @Input()
    get baseUrl(): string {
        return this._baseUrl;
    }
    set baseUrl(value: string) {
        if (value !== this.baseUrl) {
            this._baseUrl = value;
            this._cdr.markForCheck();
        }
    }
    private _baseUrl: string;

    @HostListener('focusin', ['$event'])
    focusin(event: FocusEvent) {
        if (this.input) {
            this.input.focus();
            this._refreshToolbar();
        }
    }

    @HostListener('focusout', ['$event'])
    focusout(event: FocusEvent) {
        if (event.relatedTarget && (event.relatedTarget === this._el.nativeElement || this._el.nativeElement.contains(event.relatedTarget))) {
            this.input.focus();
            this._cdr.detectChanges();
            return;
        }
        this._resetToolBar();
    }

    @HostBinding("attr.tabindex")
    readonly tabindex: number = 0;

    private _successTranslate: string;

    private _subs: Subscriptions = new Subscriptions();

    @HostBinding("class.has-change")
    get hasContentChanged(): boolean {
        return this._hasContentChanged;
    }
    private _hasContentChanged: boolean;

    tbHeading: boolean;
    tbFormat: FormatType[];
    tbQuote: boolean;
    tbListType: ListType;

    loader: boolean;
    cursorPosition: INumberRange = new NumberRange(0, 0);

    constructor(
        private _el: ElementRef,
        private _cdr: ChangeDetectorRef,
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private _srvNotification: EzNotificationService,
        private _srvTranslate: TranslateService
    ) { }

    ngOnInit() {
        const msg = "aside.help.saved";
        const trSub = this._srvTranslate.get(msg).subscribe((translate) => {
            this._successTranslate = translate;
        });
        this._subs.push("successMsg", trSub);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    // #region Events
    // toolbar
    onExternalLinkChange(link: ExternalLink): void {
        let label: string, md: string;
        switch (link.type) {
            case LinkType.ImageFile:
                label = link.label ? link.label : '';
                this._getTempImage((<File[]>link.value)[0], label);
                break;
            case LinkType.ImageLink:
                label = link.label ? link.label : '';
                md = `![${label}](${link.value} ` + (label ? `"${label}"` : '') + `)`;
                this._insertContent(md, this.cursorPosition.min);
                break;
            case LinkType.Link:
                label = link.label ? link.label : '';
                md = (label ? `[${label}]` : ``) + `(${link.value})`;
                this._insertContent(md, this.cursorPosition.min);
                break;
            default:
                break;
        }
    }

    onFormatChange(value: FormatType[]): void {
        const hasBold: boolean = _.includes(value, FormatType.Bold);
        let indexMove: number = 0;
        if (hasBold !== this._hasBold) {
            if (this._hasBold) {
                const boldMd = _.find(this._analyseSelected, (el: MdElement) => { return el.type === "bold"; });
                if (boldMd) {
                    this._removeContent(boldMd.position.min - 2 + indexMove, boldMd.position.min + indexMove);
                    indexMove -= 2;
                    this._removeContent(boldMd.position.max + indexMove, boldMd.position.max + 2 + indexMove);
                    indexMove -= 2;
                }
            } else {
                indexMove += this._insertFormat("**");
            }
        }
        const hasItalic: boolean = _.includes(value, FormatType.Italic);
        if (hasItalic !== this._hasItalic) {
            if (this._hasItalic) {
                const italicMd = _.find(this._analyseSelected, (el: MdElement) => { return el.type === "italic"; });
                if (italicMd) {
                    this._removeContent(italicMd.position.min - 1 + indexMove, italicMd.position.min + indexMove);
                    indexMove -= 1;
                    this._removeContent(italicMd.position.max + indexMove, italicMd.position.max + 1 + indexMove);
                    indexMove -= 1;
                }
            } else {
                indexMove += this._insertFormat("*");
            }
        }
    }

    onQuoteChange(value: boolean): void {
        let indexMove: number = 0;
        if (value) {
            _.forEach(this._linesSelected, (pos: INumberRange) => {
                this._insertContent("> ", pos.min + indexMove);
                indexMove += 2;
            });
        } else {
            _.forEach(this._analyseSelected, (el: MdElement) => {
                if (el.type === "blockquote" && el instanceof BlockQuote) {
                    _.forEach(el.positionQuote, (value: INumberRange) => {
                        this._removeContent(value.min + indexMove, value.max + indexMove);
                        indexMove -= value.max - value.min;
                    });
                }
            });
        }
    }

    onListTypeChange(value: ListType): void {
        let indexMove: number = 0;
        let allItems: ItemList[] = [];
        _.forEach(this._linesSelected, (pos: INumberRange) => {
            let items = _.filter(this._analyseSelected, (el: MdElement) => {
                return el.type === "itemlist" && el.position != undefined && pos.min === el.positionWithChildren.min && pos.max <= el.positionWithChildren.max;
            }) as ItemList[];

            if (items.length > 0 && value != undefined) {
                let lists: MdElement[] = [];
                _.forEach(items, (item: ItemList) => {
                    let parent = item.parent;
                    while (parent.type !== "ulist" && parent.type !== "olist") {
                        parent = parent.parent;
                    }
                    if (!_.some(lists, (list: MdElement) => { return list.id === parent.id; })) {
                        lists.push(parent);
                    }
                });
                items = [];
                _.forEach(lists, (list: MdElement) => {
                    const result = _.filter(this._analyseValue, (item: MdElement) => {
                        return item.parent != undefined && item.parent.id === list.id && (item.type === "itemlist" || item.hasChildren);
                    });
                    const notItems = _.filter(result, (item: MdElement) => {
                        return item.type !== "itemlist";
                    });
                    const newItems = _.filter(result, (item: ItemList) => {
                        return item.type === "itemlist";
                    }) as ItemList[];
                    items = _.concat(items, newItems);

                    _.forEach(notItems, (item: MdElement) => {
                        let children = item;
                        do {
                            children = _.find(this._analyseValue, (item: MdElement) => {
                                return item.parent != undefined && item.parent.id === children.id;
                            });
                        } while (children != undefined && children.type !== "itemlist");
                        if (children != undefined) {
                            items.push(children as ItemList);
                        }
                    });
                });
            }
            allItems = _.concat(allItems, items);
        });
        allItems = _.uniqBy(allItems, (item: ItemList) => {
            return item.id;
        });
        if (allItems.length > 0) {
            _.forEach(allItems, (item: ItemList) => {
                if (value == undefined) {
                    indexMove += this._removeContent(item.positionBullet.min + indexMove, item.positionBullet.max + indexMove);
                } else if (value === ListType.Ul) {
                    const start: number = this._textAreaContent.indexOf(item.bullet, item.positionBullet.min + indexMove);
                    indexMove += this._replaceContent("-", start, start + item.bullet.length);
                } else if (value === ListType.Ol) {
                    const start: number = this._textAreaContent.indexOf(item.bullet, item.positionBullet.min + indexMove);
                    const text = item.index.toString() + ".";
                    indexMove += this._replaceContent(text, start, start + item.bullet.length);
                }
            });
        } else if (value != undefined) {
            const text: string = value === ListType.Ul ? "- " : "1. ";
            _.forEach(this._linesSelected, (pos: INumberRange, index: number) => {
                indexMove += this._insertContent(text.replace("1", (index + 1).toString()), pos.min + indexMove);
            });
        }
    }

    onHeadingChange(): void {
        let indexMove: number = 0;
        _.forEach(this._linesSelected, (pos: INumberRange) => {
            const mds = _.filter(this._analyseSelected, (el: MdElement) => {
                return el.position != undefined && pos.min <= el.positionWithChildren.min && pos.max >= el.positionWithChildren.max;
            });
            const list: ItemList = _.find(mds, (el: MdElement) => {
                return el.type === "itemlist";
            }) as ItemList;
            const quote: BlockQuote = _.find(mds, (el: MdElement) => {
                return el.type === "blockquote";
            }) as BlockQuote;
            const heading: Heading = _.find(mds, (el: MdElement) => {
                return el.type === "heading";
            }) as Heading;
            let posStart = pos.min;
            if (list) {
                if (list.positionBullet.max > posStart) {
                    posStart = list.positionBullet.max;
                }
            }
            if (quote) {
                const posQuote = _.find(quote.positionQuote, (pQuote: INumberRange) => {
                    return pQuote.min >= pos.min && pQuote.max <= pos.max;
                });
                if (posQuote && posQuote.max > posStart) {
                    posStart = posQuote.max;
                }
            }
            let size = 1;
            if (heading) {
                size = heading.size + 1;
                if (size > 6) {
                    this._removeContent(heading.positionHeading.min + indexMove, heading.positionHeading.max + indexMove);
                    indexMove -= heading.positionHeading.max - heading.positionHeading.min;
                } else {
                    if (heading.underline) {
                        this._removeContent(heading.positionHeading.min + indexMove, heading.positionHeading.max + indexMove);
                        indexMove -= heading.positionHeading.max - heading.positionHeading.min;
                        let textInsert: string = "";
                        for (let i = 0; i < size; i++) {
                            textInsert += "#";
                        }
                        textInsert += " ";
                        this._insertContent(textInsert, posStart + indexMove);
                        indexMove += textInsert.length;
                    } else {
                        this._insertContent("#", heading.positionHeading.min + indexMove);
                        indexMove += 1;
                    }
                }
            } else {
                this._insertContent("# ", posStart + indexMove);
                indexMove += 2;
            }
        });
    }

    onViewTypeChange(value: ViewType): void {

    }

    onSubmitChange(value: SubmitType): void {
        if (value === "save") {
            this._saveContentChanges();
        } else {
            this.cancel.emit();
        }
    }

    // user
    onInputChange(value: string): void {
        this._textAreaContent = value;
        this._checkHasContentChanged();
    }

    onSelectionChange(value: INumberRange): void {
        // BUG : not triggered when mouseup inside selection
        this.cursorPosition = value;
        this._analyse();
        this._checkHasContentChanged();
    }

    private _analyseValue: MdElement[];
    analyseEnd(value: MdElement[]): void {
        this._analyseValue = value;
        this._analyse();
    }

    onTaKeyUp(event: KeyboardEvent): void {
        switch (event.code) {
            case "Enter":
                if (event.ctrlKey) return;
                this._checkNewLine();
                break;
            case "Tab":

                break;
        }
    }
    //#endregion Events

    //#region API
    private _getTempImage(file: File, label: string): void {
        this.loader = true;
        const uploadData = new FormData();
        uploadData.append('file', file, file.name);
        this._api.admin.uploadImage(this._appSvc.contract.id, uploadData).subscribe(
            (res) => {
                let imgPattern = `![${label}](${res} ` + (label ? `"${label}"` : '') + `)`;
                this._insertContent(imgPattern, this.cursorPosition.min);
                this.loader = false;
                this._cdr.detectChanges();
            },
            () => {
                this.loader = false;
                this._cdr.detectChanges();
            }
        );
    }

    private _saveContentChanges(): void {
        this.loader = true;
        let helpEntry = new HelpEntry(this._value.pageName, this._textAreaContent);
        if (this._subs.has('saveContent')) this._subs.clearSub('saveContent');
        let sub = this._api.admin.updateHelp(this._appSvc.contract.id, helpEntry).subscribe(
            () => {
                this._textAreaContentCache = this._textAreaContent;
                this._checkHasContentChanged();
                this.save.emit();
                this._srvNotification.success(this._successTranslate);
                this.loader = false;
                this._cdr.detectChanges();
            },
            () => {
                this.loader = false;
                this._cdr.detectChanges();
            }
        );
        this._subs.push("saveContent", sub);
    }
    //#endregion API

    //#region private
    private _typeStartSelection: LineTypeStart;
    private _analyseSelected: { [id: number]: MdElement };
    private _hasBold: boolean;
    private _hasItalic: boolean;
    private _analyse(): void {
        this._hasBold = false;
        this._hasItalic = false;
        this._typeStartSelection = undefined;
        this._analyseSelected = {};
        this._linesSelected = [];
        if (this.cursorPosition != undefined && this._analyseValue != undefined) {
            this._calculIndexLinesSelected();
            const analyseLines = _.filter(this._analyseValue, (mdEle: MdElement) => {
                return mdEle.position != undefined && (mdEle.position.min <= this.cursorPosition.min && mdEle.position.max >= this.cursorPosition.min
                    || mdEle.position.min <= this.cursorPosition.max && mdEle.position.max >= this.cursorPosition.max
                    || mdEle.position.min >= this.cursorPosition.min && mdEle.position.max <= this.cursorPosition.max);
            });



            let ifRemoveQuote: boolean = false;
            let ifRemoveUlist: boolean = false;
            let ifRemoveOlist: boolean = false;

            _.forEach(analyseLines, (mdEle: MdElement) => {
                let typeStart: LineTypeStart;
                const isInner: boolean = mdEle.position != undefined && mdEle.position.min <= this.cursorPosition.min && mdEle.position.max >= this.cursorPosition.max;
                switch (mdEle.type) {
                    case "heading":
                        typeStart |= LineTypeStart.Heading;
                        break;
                    case "olist":
                        typeStart |= LineTypeStart.OlList;
                        break;
                    case "ulist":
                        typeStart |= LineTypeStart.UlList;
                        break;
                    case "blockquote":
                        typeStart |= LineTypeStart.Quote;
                        break;
                    case "bold":
                        if (isInner) {
                            this._hasBold = true;
                        }
                        break;
                    case "italic":
                        if (isInner) {
                            this._hasItalic = true;
                        }
                        break;
                }
                this._analyseSelected[mdEle.id] = mdEle;
                let isFirstList: boolean = true;
                let parent: MdElement = mdEle.parent;
                while (parent != undefined) {
                    switch (parent.type) {
                        case "olist":
                            if (isFirstList) {
                                typeStart |= LineTypeStart.OlList;
                                isFirstList = false;
                            }
                            break;
                        case "ulist":
                            if (isFirstList) {
                                typeStart |= LineTypeStart.UlList;
                                isFirstList = false;
                            }
                            break;
                        case "blockquote":
                            typeStart |= LineTypeStart.Quote;
                            break;
                    }
                    this._analyseSelected[parent.id] = parent;
                    parent = parent.parent;
                }

                if (this._typeStartSelection != undefined && (this._typeStartSelection & LineTypeStart.Quote) !== (typeStart & LineTypeStart.Quote)) {
                    ifRemoveQuote = true;
                }
                if (this._typeStartSelection != undefined && (this._typeStartSelection & LineTypeStart.OlList) !== (typeStart & LineTypeStart.OlList)) {
                    ifRemoveOlist = true;
                }
                if (this._typeStartSelection != undefined && (this._typeStartSelection & LineTypeStart.UlList) !== (typeStart & LineTypeStart.UlList)) {
                    ifRemoveUlist = true;
                }
                this._typeStartSelection |= typeStart;
            });

            if (this._typeStartSelection & LineTypeStart.OlList & LineTypeStart.UlList) {
                this._typeStartSelection &= ~LineTypeStart.OlList;
                this._typeStartSelection &= ~LineTypeStart.UlList;
            }
            if (ifRemoveQuote) {
                this._typeStartSelection &= ~LineTypeStart.Quote;
            }
            if (ifRemoveOlist) {
                this._typeStartSelection &= ~LineTypeStart.OlList;
            }
            if (ifRemoveUlist) {
                this._typeStartSelection &= ~LineTypeStart.UlList;
            }
            this._refreshToolbar();
        }
    }

    private _linesSelected: INumberRange[];
    private _calculIndexLinesSelected(): void {
        let lineStart: number = this._textAreaContent.lastIndexOf("\n", this.cursorPosition.min - 1);
        if (lineStart < 0) {
            lineStart = 0;
        } else {
            lineStart += 1;
        }
        let lineEnd: number = this._textAreaContent.indexOf("\n", this.cursorPosition.max);
        if (lineEnd < 0) {
            lineEnd = this._textAreaContent.length;
        }

        const texts = this._textAreaContent.substring(lineStart, lineEnd);
        let istart: number = 0;
        let index: number;
        do {
            index = texts.indexOf("\n", istart);
            if (index !== -1) {
                this._linesSelected.push(new NumberRange(lineStart + istart, lineStart + index));
                istart = index + 1;
            }
        } while (index !== -1);
        this._linesSelected.push(new NumberRange(lineStart + istart, lineStart + texts.length));
    }

    private _refreshToolbar(): void {
        this.tbFormat = [];
        if (this._hasBold)
            this.tbFormat.push(FormatType.Bold);
        if (this._hasItalic)
            this.tbFormat.push(FormatType.Italic);
        this.tbHeading = (this._typeStartSelection & LineTypeStart.Heading) ? true : false;
        this.tbQuote = (this._typeStartSelection & (LineTypeStart.Quote | LineTypeStart.QuoteIn)) ? true : false;
        this.tbListType = (this._typeStartSelection & LineTypeStart.OlList && !(this._typeStartSelection & LineTypeStart.UlList)) ? ListType.Ol : (this._typeStartSelection & LineTypeStart.UlList && !(this._typeStartSelection & LineTypeStart.OlList)) ? ListType.Ul : undefined;
    }

    private _resetToolBar(): void {
        this.tbFormat = [];
        this.tbListType = undefined;
        this.tbHeading = this.tbQuote = false;
    }

    private _checkHasContentChanged(): void {
        let changed = this._textAreaContent != this._textAreaContentCache;
        if (this.hasContentChanged !== changed) {
            this._hasContentChanged = changed;
        }
    }

    private _insertContent(content: string, startIndex: number): number {
        this._textAreaContent = this._textAreaContent.substring(0, startIndex) + content + this._textAreaContent.substring(startIndex);
        const length = content.length;
        if (this.cursorPosition) {
            if (this.cursorPosition.min >= startIndex) {
                this.cursorPosition = new NumberRange(this.cursorPosition.min + length, this.cursorPosition.max + length);
            } else if (this.cursorPosition.max > startIndex) {
                this.cursorPosition = new NumberRange(this.cursorPosition.min, this.cursorPosition.max + length);
            }
        }
        return length;
    }

    private _replaceContent(content: string, startIndex: number, endIndex: number): number {
        this._textAreaContent = this._textAreaContent.substring(0, startIndex) + content + this._textAreaContent.substring(endIndex);
        const length = content.length - (endIndex - startIndex);
        if (this.cursorPosition) {
            if (this.cursorPosition.min > startIndex) {
                this.cursorPosition = new NumberRange(this.cursorPosition.min + length, this.cursorPosition.max + length);
            } else if (this.cursorPosition.max > startIndex) {
                this.cursorPosition = new NumberRange(this.cursorPosition.min, this.cursorPosition.max + length);
            }
        }
        return length;
    }

    private _removeContent(startIndex: number, endIndex: number): number {
        this._textAreaContent = this._textAreaContent.substring(0, startIndex) + this._textAreaContent.substring(endIndex);
        const length = startIndex - endIndex;
        if (this.cursorPosition) {
            if (this.cursorPosition.min > startIndex) {
                this.cursorPosition = new NumberRange(this.cursorPosition.min + length, this.cursorPosition.max + length);
            } else if (this.cursorPosition.max > startIndex) {
                this.cursorPosition = new NumberRange(this.cursorPosition.min, this.cursorPosition.max + length);
            }
        }
        return length;
    }

    private _insertFormat(format: string): number {
        let indexMove: number = 0;
        let min: number = this.cursorPosition.min - 1;
        if (min < 0) {
            min = 0;
        }
        let max: number = this.cursorPosition.max + 1;
        if (max > (this._textAreaContent.length - 1)) {
            max = this._textAreaContent.length - 1;
        }
        const text = this._textAreaContent.substring(min, max);
        const originalText = text.substring(1, text.length - 1);
        const addStart: number = originalText.length - originalText.trimLeft().length;
        let insertText = format;
        if (addStart > 0 && /[^\s\*]/.test(text[0])) {
            insertText = " " + insertText;
        }
        this.cursorPosition.min += addStart;
        this._insertContent(insertText, this.cursorPosition.min);
        indexMove += insertText.length;

        const removeEnd: number = originalText.length - originalText.trimRight().length;
        insertText = format;
        if (removeEnd > 0 && /[^\s\*]/.test(text[text.length - 1])) {
            insertText = insertText + " ";
        }
        this.cursorPosition.max -= removeEnd;
        this._insertContent(insertText, this.cursorPosition.max);
        indexMove += insertText.length;
        return indexMove;
    }

    private _checkNewLine(): void {
        let lineEnd: number = this._textAreaContent.lastIndexOf("\n", this.cursorPosition.min - 1);
        if (lineEnd < 0) {
            lineEnd = 0;
        }
        let lineStart = this._textAreaContent.lastIndexOf("\n", lineEnd);
        if (lineStart < 0) {
            lineStart = 0;
        }
        const analyseLines = _.filter(this._analyseValue, (mdEle: MdElement) => {
            return mdEle.type !== "bold" && mdEle.type !== "italic" && mdEle.position != undefined && (mdEle.position.min <= lineStart && mdEle.position.max >= lineStart
                || mdEle.position.min <= lineEnd && mdEle.position.max >= lineEnd
                || mdEle.position.min >= lineStart && mdEle.position.max <= lineEnd);
        });

        _.forEach(analyseLines, (mdEle: MdElement) => {
            let parent = mdEle;
            while (parent !== undefined && parent.type !== "itemlist") {
                parent = parent.parent;
            }
            if (parent) {
                const pos = parent.position || parent.positionWithChildren;
                if (pos != undefined) {
                    if (parent.type === "itemlist") {
                        const itemList: ItemList = parent as ItemList;
                        if (itemList.positionBullet.max === (pos.max - (itemList.loose ? 1 : 0))) {
                            this._removeContent(itemList.positionBullet.min, itemList.positionBullet.max);
                        } else {
                            let text = this._textAreaContent.substring(itemList.positionBullet.min, itemList.positionBullet.max);
                            text = text.replace(itemList.index.toString(), (itemList.index + 1).toString());
                            this._insertContent(text, this.cursorPosition.min);
                        }
                    }
                }
            }
        });
    }

    //#endregion private
}
