<ez-form (onSubmit)="formSubmit()">
    <ez-form-field>
        <ez-label>{{ 'autotrading.session.create.countryArea.countries' | translate }}</ez-label>
        <ez-image-selector [(ngModel)]="countrySelected" [required]="true" class="img-countries">
            <ez-image *ngFor="let country of countries" [url]="country.country.logo?.icon" [value]="country"
                [name]="country.country.name"></ez-image>
        </ez-image-selector>
    </ez-form-field>
    <ez-form-field>
        <ez-label>{{ 'autotrading.session.create.countryArea.market' | translate }}</ez-label>
        <ez-image-selector [(ngModel)]="value.balancingGrid" [required]="true" class="img-areas"
            [disabled]="!gridList || gridList.length === 0">
            <ez-image *ngFor="let grid of gridList" [url]="grid.gridLogo?.icon" [value]="grid" [name]="grid.name">
            </ez-image>
        </ez-image-selector>
    </ez-form-field>
    <ez-footer *ngIf="!hideFooter">
        <ez-button [label]="'general.cancel' | translate" ez-form-cancel [disabled]="disabled"></ez-button>
        <ez-button [label]="'general.save' | translate" [severity]="'primary'" ez-form-submit [disabled]="disabled">
        </ez-button>
    </ez-footer>
</ez-form>
