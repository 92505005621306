// @ts-nocheck
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService, IDateTo, IFormatDate, typeFormatDate } from '@eznergy/components';
import { DateRange, DateTime, TimeZone } from '@eztypes/generic';
import { ICalendar } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';

export interface IOptionsPipe {
    format?: typeFormatDate | IFormatDate;
    toDate?: IDateTo;
    calendar?: ICalendar;
}


@Pipe({
    name: 'appdate',
    pure: false
})
export class TimezonePipe implements PipeTransform {

    private get _hasTZA(): boolean {
        return this._appSvc.preferences.tzActivate;
    }

    constructor(private readonly _i18nSvc: I18nService, private readonly _appSvc: ApplicationService) {
    }

    transform(value: DateTime | string | number | Date, options?: IOptionsPipe): any {
        let date: DateTime | DateRange;
        if (value == null) {
            return "";
        }
        if (value instanceof DateTime) {
            date = value;
        } else {
            date = new DateTime(value);
        }
        if (!date.isValid) {
            return "";
        }

        let dateTo: DateTime;
        if (options?.toDate != null) {
            if (options?.toDate?.date instanceof DateTime) {
                dateTo = options.toDate.date;
            } else {
                dateTo = new DateTime(options.toDate.date);
            }
            date = new DateRange(date, dateTo);
        }
        let tz: TimeZone;
        if (this._hasTZA) {
            tz = this._appSvc.timezone;
        } else if (options.calendar != null) {
            tz = TimeZone.create(options.calendar.ianaName);
        }


        return this._i18nSvc.formatDate(date, { format: options.format, timezone: tz });
    }
}
