<menu-page>
    <ez-button [icon]="'sync-alt'" [big]="true" (onClick)="onRefresh()"
        [ezTooltip]="'balancing.ets.ol.button.refresh.pover' | translate"></ez-button>
    <div class="balance-options">
        <table class="opt-balance">
            <tr>
                <td>
                    Position
                </td>
                <td>
                    <ez-input-number [min]="0" [max]="100" [(value)]="valPercent" [disabled]="btnDisable">
                        <ez-suffix>%</ez-suffix>
                    </ez-input-number>
                </td>
                <td rowspan="2">
                    <ez-button [label]="'balancing.ets.ol.button.balanced.label' | translate"
                        [ezTooltip]="'balancing.ets.ol.button.balanced.pover' | translate"
                        (onClick)="onBalancePosition()" [disabled]="!dataTable || btnDisable">
                    </ez-button>
                </td>
            </tr>
            <tr>
                <td>
                    <ez-checkbox [(checked)]="amountCapChecked" [disabled]="btnDisable" [label]="'Cap'">
                    </ez-checkbox>
                </td>
                <td>
                    <ez-input-number [min]="0" [(value)]="amountCap" [disabled]="!amountCapChecked">
                        <ez-suffix>MW</ez-suffix>
                    </ez-input-number>
                </td>
            </tr>
        </table>
    </div>
    <ez-select [value]="versionSelected" (valueChange)="onChangeVersion($event)" [nullable]="false"
        [disabled]="!dataTable || isNew">
        <ez-select-option *ngFor="let version of versions" [value]="version">
            {{'balancing.ets.ol.version' | translate}} {{version}}
        </ez-select-option>
    </ez-select>

    <ez-button [big]="true" [label]="'balancing.ets.ol.button.revert.label' | translate" (onClick)="onRevertVersion()"
        [disabled]="isNew || !hasDataChanged || btnDisable"></ez-button>
    <ez-button [big]="true" [label]="'balancing.ets.ol.button.clearData.label' | translate" (onClick)="onClearData()"
        [disabled]="!hasData || btnDisable"></ez-button>

    <div class="menu-options-right">
        <ez-button [big]="true" [severity]="'warning'" class="deleteButton"
            [disabled]="!hasRightDelete || (hasDataChanged && hasData) || !isActiveVersion || isNew || btnDisable"
            [label]="'balancing.ets.ol.button.delete.label' | translate" (onClick)="openConfirmDelete = true"
            [ezTooltip]="'balancing.ets.ol.button.delete.pover' | translate">
        </ez-button>
        <ez-button [big]="true" [label]="'balancing.ets.ol.button.submit.label' | translate"
            (onClick)="openConfirmSend = true"
            [disabled]="!hasRightCreate || !hasData || (isActiveVersion && !hasDataChanged) || hasError || btnDisable"
            [severity]="'primary'">
        </ez-button>
    </div>

</menu-page>

<div class="ets-ol-content">
    <ez-loader *ngIf="loader.onLoading"></ez-loader>
    <ets-order-linear-table #olEdit [area]="area" [flatPositions]="flatPositions" [orderLinear]="orderLinear"
        [portfolio]="portfolio" [isNew]="isNew" [marketData]="marketDataResult" (onChange)="onTableChange($event)"
        (onBeforeLoading)="onBeforeTableLoad()" (onAfterLoading)="onAfterTableLoad()" [btnDisable]="btnDisable">
    </ets-order-linear-table>
</div>

<ez-modal [(open)]="openConfirmSend" (onClose)="onSubmitOrder($event)">
    <ez-header>
        <span> {{'balancing.ets.ol.confirmSend.title' | translate}}</span>
    </ez-header>
    <ez-content>
        <div>{{'balancing.ets.ol.confirmSend.text' | translate }}</div>
    </ez-content>
    <ez-footer>
        <ez-button [label]="'general.cancel' | translate" ez-modal-close></ez-button>
        <ez-button [label]="'general.confirm' | translate" [ez-modal-close]="true" [severity]="'primary'">
        </ez-button>
    </ez-footer>
</ez-modal>

<ez-modal [(open)]="openConfirmDelete" (onClose)="onDeleteOrder($event)">
    <ez-header>
        <span> {{'balancing.ets.ol.confirmDelete.title' | translate}}</span>
    </ez-header>
    <ez-content>
        <div>{{'balancing.ets.ol.confirmSend.text' | translate }}</div>
    </ez-content>
    <ez-footer>
        <ez-button [label]="'general.cancel' | translate" ez-modal-close></ez-button>
        <ez-button [label]="'general.confirm' | translate" [ez-modal-close]="true" [severity]="'primary'">
        </ez-button>
    </ez-footer>
</ez-modal>