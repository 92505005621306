import { Location } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, ParamMap, PRIMARY_OUTLET } from '@angular/router';
import { MarkdownEditorComponent } from '@components/admin/help/markdown-editor.component';
import { BasePage } from '@core';
import { AppUser } from '@core/app-user.model';
import { Logger } from '@core/logger';
import { IDictionary } from '@eztypes/generic';
import { HelpEntry } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import { AppPages, RouterService } from '@services/router.service';
import { StaticService } from '@services/static.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'ez-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class HelpComponent extends BasePage implements OnInit {

    @ViewChild('_editor') editor: MarkdownEditorComponent | undefined;

    baseUrl: string;
    currentUser: AppUser | undefined;
    isOnEdition: boolean = false;
    hasToRefresh: boolean | undefined;


    get helpEntry(): HelpEntry {
        return this._helpEntry;
    }
    private _helpEntry: HelpEntry = new HelpEntry("general", '');

    openConfirm: boolean | undefined;

    private readonly _rootPath: string;
    private _path: string | undefined;
    private _subjectAlertChange: Subject<boolean> | undefined;
    private _fileDir: string | undefined;

    constructor(
        private _route: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        logger: Logger,
        private _staticService: StaticService,
        private _appSvc: ApplicationService,
        private _srvRouter: RouterService,
        private _http: HttpClient,
        private _handler: HttpBackend,
        private _location: Location
    ) {
        super(logger);
        this._rootPath = this._staticService.baseUriHelp;
        this.baseUrl = this._staticService.baseUriCdn;
        let sub = this._appSvc.userChange.subscribe(() => {
            this.currentUser = this._appSvc.user;
        });
        this._subscriptions.push("userChange", sub);
        this._http = new HttpClient(this._handler);
    }

    ngOnInit() {
        let sub = this._srvRouter.dataParamsChange.subscribe((data: IDictionary<Data> | undefined) => {
            const dictDataPrimary: Data | undefined = data?.get(PRIMARY_OUTLET);
            if (dictDataPrimary != null) {
                const fileDir: string = dictDataPrimary['helpDirectory'];
                if (fileDir !== this._fileDir && this.isOnEdition && this.editor?.hasContentChanged) {
                    this._canCloseHelp().then((bool) => {
                        if (bool) {
                            this.setHelpFilePath(fileDir);
                        } else {
                            this._location.back();
                        }
                    });
                } else {
                    this.setHelpFilePath(fileDir);
                }
            }
        });
        this._subscriptions.push("data", sub);

        sub = this._route.paramMap.subscribe((value: ParamMap) => {
            this.isOnEdition = value.has("mode") && value.get("mode") === "edit";
            this._cdr.detectChanges();
        });
        this._subscriptions.push("paramMap", sub);
    }

    public canDeactivate() {
        if (this.isOnEdition) {
            return this._canCloseHelp();
        }
        return true;
    }

    private _canCloseHelp(): Promise<boolean> {
        if (this.editor && this.editor.hasContentChanged) {
            this.openConfirm = true;
            this._cdr.detectChanges();
            if (!this._subjectAlertChange) {
                this._subjectAlertChange = new Subject<boolean>();
            }
            //@ts-ignore
            return this._subjectAlertChange.toPromise();
        }
        return Promise.resolve(true);
    }

    onConfirmCancel(value: boolean): void {
        if (this._subjectAlertChange) {
            this._subjectAlertChange.next(value === true);
            this._subjectAlertChange.complete();
            this._subjectAlertChange = undefined;
        }
        this._cdr.detectChanges();
    }

    private setHelpFilePath(fileDir: string): void {
        this._fileDir = fileDir || "general/";
        let path: string = this._rootPath + this._fileDir + "help.md";
        if (this._logger.isDebugEnabled()) this._logger.debug({ message: "Loading help file : ", data: path });
        if (path !== this._path) {
            this._path = path;
            this._handlePathChange();
        }
    }

    private _handlePathChange() {
        if (this._path) {
            this._loaderPage.push('handlePathChange');
            this._subscriptions.clearSub('handlePathChange');
            let t = Date.now();
            let sub = this._http.get(this._path + "?t=" + t, { responseType: 'text' }).subscribe(
                (res) => {
                    res = res.replace(/\r\n/g, '\n');
                    if (this._fileDir === this.helpEntry.pageName && res !== this.helpEntry.content || this._fileDir !== this.helpEntry.pageName)
                        this.hasToRefresh = false;
                    this._helpEntry = new HelpEntry();
                    this._helpEntry.pageName = !!this._fileDir ? this._fileDir : '';
                    this._helpEntry.content = res;
                    this._loaderPage.remove('handlePathChange');
                    this._cdr.detectChanges();
                },
                () => {
                    this._loaderPage.remove('handlePathChange');
                    this._cdr.detectChanges();
                }
            );
            this._subscriptions.push('handlePathChange', sub);
        }
    }

    onUpdateClick(): void {
        this._handlePathChange();
    }

    onEditHelpClick(): void {
        this._srvRouter.navigateToAside(AppPages.HelpEdit);
    }

    onSaveMarkdown(): void {
        this.hasToRefresh = true;
    }

    onCancelMarkdown(): void {
        this._srvRouter.navigateToAside(AppPages.Help);
    }
}
