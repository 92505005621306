import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageDealsPreferenceInfo {
    static load(preferences: string | PageDealsPreferenceInfo): PageDealsPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageDealsPreferenceInfo>(preferences, PageDealsPreferenceInfo) as PageDealsPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    refreshTimer: number | undefined;
}

@UserPreferenceClassDecorator
export class PageDealsPreference implements IPreferencePage {
    static load(preferences: string | PageDealsPreferenceInfo): PageDealsPreference {
        if (!preferences) {
            return new PageDealsPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageDealsPreferenceInfo.load(preferences);
                return new PageDealsPreference(v1.refreshTimer);
            default:
                return new PageDealsPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    refreshTimer: number | undefined;

    constructor(refreshTimer: number | undefined = undefined) {
        this.refreshTimer = refreshTimer;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.refreshTimer = undefined;
        }
    }
}

