// @ts-nocheck
import { Reflect } from "core-js";

/* tslint:disable */
export const queryParamMetadataKey = "queryParamProperty";

export type FormatQueryParams = "date";

export interface IQueryParamMetaData<T> {
    key: string;
    property?: string;
    isArray: boolean;
    type: { new(): T };
    format?: FormatQueryParams;
}

export class QueryParamMetaData<T> implements IQueryParamMetaData<T> {
    key: string;
    property?: string;
    isArray: boolean;
    type: { new(): T };
    format: FormatQueryParams;

    static getMetaData<T>(target: any, propertyKey: string): IQueryParamMetaData<T> {
        let queryParam: IQueryParamMetaData<T> = Reflect.getMetadata(queryParamMetadataKey, target, propertyKey);
        if (!queryParam) return undefined;
        if (queryParam.type === undefined) {
            queryParam.type = Reflect.getMetadata("design:type", target, propertyKey);
        }
        return queryParam;
    }
    constructor(key: string, property?: string, isArray: boolean = true, type?: (new () => T), format?: FormatQueryParams) {
        this.key = key;
        this.property = property;
        this.type = type;
        this.isArray = isArray;
        this.format = format;
    }
}

export const dataParamMetadataKey = "dataParamProperty";
export type DefaultType = 'all' | 'empty' | 'first' | 'value' | 'property';

export interface IDataParamMetaData<T> {
    key: string;
    defaultType: DefaultType;
    noCheck?: boolean;
    type?: { new(): T };
    queryKey?: string;
    defaultTypeProperty?: string;
}

export class DataParamMetaData<T> implements IDataParamMetaData<T> {
    key: string;
    defaultType: DefaultType;
    noCheck?: boolean;
    type?: { new(): T };
    queryKey?: string;
    defaultTypeProperty?: string;

    static getMetaData<T>(target: any, propertyKey: string): IDataParamMetaData<T> {
        let queryParam: IDataParamMetaData<T> = Reflect.getMetadata(dataParamMetadataKey, target, propertyKey);
        if (!queryParam) return undefined;
        if (queryParam.type === undefined) {
            queryParam.type = Reflect.getMetadata("design:type", target, propertyKey);
        }
        return queryParam;
    }

    constructor(key: string, defaultType: DefaultType = 'all', noCheck: boolean = false, type?: (new () => T), queryKey?: string, defaultTypeProperty?: string) {
        this.key = key;
        this.defaultType = defaultType
        this.type = type;
        this.noCheck = noCheck;
        this.queryKey = queryKey;
        this.defaultTypeProperty = defaultTypeProperty;
    }
}
