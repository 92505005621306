import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppCoreModule } from "@core/core.module";
import { AppFormsModule } from "@forms/forms.module";
import { TranslateModule } from "@ngx-translate/core";
import { EtsMarketResultPortfolioComponent } from "./ets-market-result/ets-market-result-portfolio/ets-market-result-portfolio.component";
import { EtsMarketResultSummaryComponent } from "./ets-market-result/ets-market-result-summary/ets-market-result-summary.component";
import { EtsMarketResultComponent } from "./ets-market-result/ets-market-result.component";
import { EtsPageComponent } from "./ets.component";
import { EtsOrderLinearTableComponent } from "./orderLinear/ets-order-linear-table.component";
import { EtsOrderLinearComponent } from "./orderLinear/ets-order-linear.component";
import { ParametersEts } from "./params/parameters-ets.component";
import { SharedModule } from '../../../modules/shared/shared.module';

@NgModule({
    imports: [
        AppCoreModule,
        AppFormsModule,
        TranslateModule,
        SharedModule
    ],
    declarations: [
        EtsMarketResultPortfolioComponent,
        EtsMarketResultSummaryComponent,
        EtsMarketResultComponent,
        EtsOrderLinearTableComponent,
        EtsOrderLinearComponent,
        ParametersEts,
        EtsPageComponent
    ],
    exports: [
        EtsMarketResultPortfolioComponent,
        EtsMarketResultSummaryComponent,
        EtsMarketResultComponent,
        EtsOrderLinearTableComponent,
        EtsOrderLinearComponent,
        ParametersEts,
        EtsPageComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EtsPageModule {

}
