import { DynamicPriceType, IFormPriceFlexPos } from './price-flexpos.models';
import {
    BestMarketFilter,
    FlexPricesParameters,
    IDynamicPrice,
    IFlexPricesParameters,
    IPositionPricesParameters,
    Limits,
    PositionPricesParameters,
    RatioType,
    SimpleType,
} from '@eztypes/webapi';

export class PricesFlexposMapper {
    public toFormGroup(values: IFlexPricesParameters | IPositionPricesParameters): IFormPriceFlexPos {
        if (!values) {
            return this.defaultValues();
        }

        return {
            spread: 'minimumBuySellSpread' in values ? values.minimumBuySellSpread : null,
            hardLimitsActivated: values.hardLimits.activated,
            hardLimit: {
                hardLimitsMin: values.hardLimits.min,
                hardLimitsMax: values.hardLimits.max,
            },
            priceLimit: {
                absoluteBuy: values.absoluteBuyTimeSerie,
                absoluteSell: values.absoluteSellTimeSerie,
                timeGradient : {
                    isTimeGradient: values.timeGradientActivated,
                    timeGradientValue: values.timeGradientActivated ? values.timeGradient : []
                },
                volumeGradient: {
                    isVolumeGradient: values.volumeGradientActivated,
                    volumeGradientValue: values.volumeGradientActivated ? values.volumeGradient : []
                }
            },
            isDynamicPrice: values.dynamicPrice &&
                (values.dynamicPrice.isManualStrategy || values.dynamicPrice.isSimpleStrategy || values.dynamicPrice.isRatioStrategy) ? true : false,
            dynamicPriceLimit: {
                dynamicPriceRadio: this.getDynamicPriceType(values.dynamicPrice),
                valueRange: {
                    valueRangeBuyBid: values.dynamicPrice?.ratioType === RatioType.BidAsk && Number.isFinite(values.dynamicPrice.buyRatio) ? values.dynamicPrice.buyRatio : 0,
                    valueRangeSellBid: values.dynamicPrice?.ratioType === RatioType.BidAsk && Number.isFinite(values.dynamicPrice.sellRatio) ? values.dynamicPrice.sellRatio : 100,
                },
                valueRangeVW: {
                    valueRangeVWBuyBid: values.dynamicPrice?.ratioType == RatioType.VolWeightedSpread && Number.isFinite(values.dynamicPrice.buyRatio) ? values.dynamicPrice.buyRatio : 0,
                    valueRangeVWSellBid: values.dynamicPrice?.ratioType == RatioType.VolWeightedSpread && Number.isFinite(values.dynamicPrice.sellRatio) ? values.dynamicPrice.sellRatio : 100,
                },
                manualLimit: {
                    marketMakingPolicies: values.dynamicPrice?.marketMakingPolicies ?? [],
                    isBestMarketFilter: values.bestMarketFilter.activated,
                    bestMarketFilter: {
                        minVolumeTimeserie: values.bestMarketFilter.activated ? values.bestMarketFilter?.minVolumeTimeSerie : null,
                        maxPriceDeltaTimeserie: values.bestMarketFilter.activated ? values.bestMarketFilter?.maxPriceDeltaTimeSerie : null
                    }
                },
            },
            isManualModeLimits: values.manualLimits.activated,
            manualModeLimits: {
                manualLimitsMin: values.manualLimits.activated ? values.manualLimits.min : null,
                manualLimitsMax: values.manualLimits.activated ? values.manualLimits.max : null
            }
        };
    }

    public toDTO(values: IFormPriceFlexPos, defaultValues: IFlexPricesParameters | IPositionPricesParameters): IFlexPricesParameters | IPositionPricesParameters {
        if (values.spread) {
            return new FlexPricesParameters(
                values.priceLimit.absoluteBuy ?? undefined,
                values.priceLimit.absoluteSell ?? undefined,
                values.priceLimit.timeGradient.isTimeGradient,
                values.priceLimit.timeGradient.isTimeGradient ? values.priceLimit.timeGradient.timeGradientValue : [],
                values.priceLimit.volumeGradient.isVolumeGradient,
                values.priceLimit.volumeGradient.isVolumeGradient ? values.priceLimit.volumeGradient.volumeGradientValue : [],
                undefined,
                undefined,
                this.getDynamicPrice(values),
                // @ts-ignore
                values.spread,
                undefined,
                new Limits(
                    values.hardLimitsActivated,
                    values.hardLimitsActivated ? (values.hardLimit.hardLimitsMax ?? undefined) : defaultValues.hardLimits.max,
                    values.hardLimitsActivated ? (values.hardLimit.hardLimitsMin ?? undefined) : defaultValues.hardLimits.min
                ),
                new Limits(
                    values.isManualModeLimits,
                    values.isManualModeLimits ? (values.manualModeLimits?.manualLimitsMax ?? undefined) : defaultValues.manualLimits.max,
                    values.isManualModeLimits ? (values.manualModeLimits?.manualLimitsMin ?? undefined) : defaultValues.manualLimits.min
                ),
                new BestMarketFilter(
                    values.dynamicPriceLimit.manualLimit.isBestMarketFilter,
                    values.dynamicPriceLimit.manualLimit.isBestMarketFilter ?
                        (values.dynamicPriceLimit.manualLimit.bestMarketFilter.minVolumeTimeserie ?? undefined) :
                        defaultValues.bestMarketFilter.minVolumeTimeSerie,
                    values.dynamicPriceLimit.manualLimit.isBestMarketFilter ?
                        (values.dynamicPriceLimit.manualLimit.bestMarketFilter.maxPriceDeltaTimeserie ?? undefined) :
                        defaultValues.bestMarketFilter.maxPriceDeltaTimeSerie
                )
            );
        } else {
            return new PositionPricesParameters(
                values.priceLimit.absoluteBuy ?? undefined,
                values.priceLimit.absoluteSell ?? undefined,
                values.priceLimit.timeGradient.isTimeGradient,
                values.priceLimit.timeGradient.isTimeGradient ? values.priceLimit.timeGradient.timeGradientValue : [],
                values.priceLimit.volumeGradient.isVolumeGradient,
                values.priceLimit.volumeGradient.isVolumeGradient ? values.priceLimit.volumeGradient.volumeGradientValue : [],
                undefined,
                undefined,
                this.getDynamicPrice(values),
                undefined,
                new Limits(
                    values.hardLimitsActivated,
                    values.hardLimitsActivated ? (values.hardLimit.hardLimitsMax ?? undefined) : defaultValues.hardLimits.max,
                    values.hardLimitsActivated ? (values.hardLimit.hardLimitsMin ?? undefined) : defaultValues.hardLimits.min
                ),
                new Limits(
                    values.isManualModeLimits,
                    values.isManualModeLimits ? (values.manualModeLimits?.manualLimitsMax ?? undefined) : defaultValues.manualLimits.max,
                    values.isManualModeLimits ? (values.manualModeLimits?.manualLimitsMin ?? undefined) : defaultValues.manualLimits.min
                ),
                new BestMarketFilter(
                    values.dynamicPriceLimit.manualLimit.isBestMarketFilter,
                    values.dynamicPriceLimit.manualLimit.isBestMarketFilter ?
                        (values.dynamicPriceLimit.manualLimit.bestMarketFilter.minVolumeTimeserie ?? undefined) :
                        defaultValues.bestMarketFilter.minVolumeTimeSerie,
                    values.dynamicPriceLimit.manualLimit.isBestMarketFilter ?
                        (values.dynamicPriceLimit.manualLimit.bestMarketFilter.maxPriceDeltaTimeserie ?? undefined) :
                        defaultValues.bestMarketFilter.maxPriceDeltaTimeSerie
                )
            );
        }
    }

    private getDynamicPriceType(dynamicPrice: IDynamicPrice): DynamicPriceType {
        if (!dynamicPrice) {
            return DynamicPriceType.isBidAsk;
        }

        if (dynamicPrice.isManualStrategy)
            return DynamicPriceType.isManualStrategy;
        if (dynamicPrice.isRatioStrategy) {
            if (dynamicPrice.ratioType == RatioType.BidAsk)
                return DynamicPriceType.isBidAsk;
            if (dynamicPrice.ratioType == RatioType.VolWeightedSpread)
               return DynamicPriceType.isVolWeigthedSpread;
        }
        if (dynamicPrice.isSimpleStrategy) {
            if (dynamicPrice.simpleType == SimpleType.Last)
                return DynamicPriceType.isLast;
            if (dynamicPrice.simpleType == SimpleType.VolumeWeightedAvgPrice)
                return DynamicPriceType.isVolumeWeightedAvgPrice;
        }
        return DynamicPriceType.isBidAsk;
    }

    private getDynamicPrice(values: IFormPriceFlexPos): IDynamicPrice | undefined {
        if (!values.isDynamicPrice) {
            return undefined;
        }

        const returnedValue: IDynamicPrice = {
            isManualStrategy: false,
            isRatioStrategy: false,
            isSimpleStrategy: false,
            ratioType: RatioType.BidAsk,
            buyRatio: 0,
            sellRatio: 0,
            simpleType: SimpleType.Last,
            // @ts-ignore
            marketMakingPolicies: null
        }

        switch (values.dynamicPriceLimit.dynamicPriceRadio) {
            case DynamicPriceType.isBidAsk:
                returnedValue.isRatioStrategy = true;
                returnedValue.ratioType = RatioType.BidAsk;
                returnedValue.buyRatio =
                    values.dynamicPriceLimit.valueRange.valueRangeBuyBid; // this._valueRangeBuySellBid.min;
                returnedValue.sellRatio =
                    values.dynamicPriceLimit.valueRange.valueRangeSellBid; // this._valueRangeBuySellBid.max;
                break;
            case DynamicPriceType.isLast:
                returnedValue.isSimpleStrategy = true;
                returnedValue.simpleType = SimpleType.Last;
                break;
            case DynamicPriceType.isManualStrategy:
                returnedValue.isManualStrategy = true;
                returnedValue.marketMakingPolicies = values.dynamicPriceLimit.manualLimit.marketMakingPolicies
                break;
            case DynamicPriceType.isVolumeWeightedAvgPrice:
                returnedValue.isSimpleStrategy = true;
                returnedValue.simpleType = SimpleType.VolumeWeightedAvgPrice;
                break;
            case DynamicPriceType.isVolWeigthedSpread:
                returnedValue.isRatioStrategy = true;
                returnedValue.ratioType = RatioType.VolWeightedSpread;
                returnedValue.buyRatio =
                    values.dynamicPriceLimit.valueRangeVW.valueRangeVWBuyBid; // this._valueRangeVWBid.min;
                returnedValue.sellRatio =
                    values.dynamicPriceLimit.valueRangeVW.valueRangeVWSellBid; // this._valueRangeVWBid.max;
                break;
        }

        return returnedValue;
    }

    private defaultValues(): IFormPriceFlexPos {
        return {
            spread: null,
            hardLimitsActivated: false,
            hardLimit: {
                hardLimitsMin: null,
                hardLimitsMax: null,
            },
            priceLimit: {
                absoluteBuy: null,
                absoluteSell: null,
                timeGradient : {
                    isTimeGradient: false,
                    timeGradientValue: []
                },
                volumeGradient: {
                    isVolumeGradient: false,
                    volumeGradientValue: []
                }
            },
            isDynamicPrice: false,
            dynamicPriceLimit: {
                dynamicPriceRadio: DynamicPriceType.isBidAsk,
                valueRange: {
                    valueRangeBuyBid: 0,
                    valueRangeSellBid: 100,
                },
                valueRangeVW: {
                    valueRangeVWBuyBid: 0,
                    valueRangeVWSellBid: 100,
                },
                manualLimit: {
                    marketMakingPolicies: [],
                    isBestMarketFilter: false,
                    bestMarketFilter: {
                        minVolumeTimeserie: null,
                        maxPriceDeltaTimeserie: null
                    }
                },
            },
            isManualModeLimits: false,
            manualModeLimits: {
                manualLimitsMin: null,
                manualLimitsMax: null
            }
        };
    }
}