// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { BasePagePopup, MultiLoader } from "@core";
import { Logger } from '@core/logger';
import { EzAccordionSectionComponent, EzForm } from "@eznergy/components";
import * as _ez from "@eznergy/core";
import { ApiService, AutoTradingHub, IPaging } from "@eznergy/webapi";
import { DateTime, TimeZone } from '@eztypes/generic';
import { ContractAreaState, IAtOrder, IContractAreaState, IContractMetrics, ILog, ILogMetadata, IPagedWrapper, IRun, ISession, ITrade, OrderState, PagedWrapper, RunState, UserRight } from "@eztypes/webapi";
import { HubConnectionState } from '@microsoft/signalr';
import { SessionDisplayType } from '../../models/enums';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from "@services/application.service";
import { AppPages, RouterService } from "@services/router.service";
import * as _ from "lodash";
import { interval, Subscription } from 'rxjs';
import { SectionAutoScroll } from './section-auto-scroll';
import { AtOrderForm } from '../forms/order/create-order.component';
import { PageEvent } from '@angular/material/paginator';

enum Tab {
    Orders = 0,
    Trades = 1,
    Logs = 2,
}

export type RunNumber = number | 'current';

@Component({
    selector: "at-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AtReports extends BasePagePopup implements OnDestroy {

    @ViewChild('form') form: EzForm;
    @ViewChild(AtOrderForm) orderForm?: AtOrderForm;

    //#region Variables & accessors
    runs: IRun[];
    public runPageSize = 100;
    public currentPage = 0;
    public runTotalCount = 0;

    isRunning: boolean;
    isRunningManual: boolean;
    tabs = Tab;
    typeDisplaySelected: SessionDisplayType;

    currentRun: IRun;

    displayType = SessionDisplayType;

    get activeTab(): Tab {
        return this._activeTab;
    }
    set activeTab(value: Tab) {
        this._activeTab = value;
        this._updateData();
        this._cdr.detectChanges();
    }
    private _activeTab: Tab;

    runTrades: Map<RunNumber, IPagedWrapper<ITrade>>;
    runLogs: Map<RunNumber, IPagedWrapper<ILog>>;

    orders: IAtOrder[];
    logs: ILog[];
    trades: ITrade[];

    currentLogsPaged: IPagedWrapper<ILog>;
    currentTradesPaged: IPagedWrapper<ITrade>;

    private _sessionId: number;
    hasLostConnection: boolean;
    hasSessionReadRight: boolean = false;
    hasSessionUpdateRight: boolean = false;
    private _subRights: Subscription;

    contracts: IContractAreaState[];
    selectedContract: IContractAreaState;
    contractMetrics: IContractMetrics[];
    selectedContractMetrics: IContractMetrics;

    privateOrderSelected: IAtOrder;
    hasOpenModalOrder: boolean = false;
    session: ISession;

    // private _runNumber: string;
    private _mode: string;

    outlet: string;

    loader: MultiLoader = new MultiLoader();

    private _sectionAutoScroll: SectionAutoScroll = new SectionAutoScroll();

    private _sizeCurrentLogs: number = 50;
    private _startCurrentLogs: number = 0;
    private _sizeCurrentTrades: number = 50;
    private _startCurrentTrades: number = 0;

    //#endregion Variables

    get urlPage(): string {
        if (!this._srvRouter || !this._sessionId)
            return undefined;
        return this._srvRouter.getUrl(this._page, { params: { id: this._sessionId.toString() } });
    }
    get urlPopup(): string {
        if (!this._srvRouter || !this._sessionId)
            return undefined;
        return this._srvRouter.getUrlPopup(this._page, { id: this._sessionId.toString() });
    }
    private _page: AppPages;

    get sizeTableAccordion(): number {
        return this._sizeTableAccordion;
    }


    private _sizeTableAccordion: number = 0;

    private _hub: AutoTradingHub;

    readonly tz: TimeZone = TimeZone.create('Europe/Paris');

    //#region init
    constructor(
        private _appSvc: ApplicationService,
        private _cdr: ChangeDetectorRef,
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _srvRouter: RouterService,
        private _srvTranslate: TranslateService,
        private _elRef: ElementRef<HTMLElement>,
        logger: Logger
    ) {
        super(logger, 'session-reports');
        this.titlePopup = "Session Reports";
        this.outlet = this._route.outlet;
    }

    ngOnInit() {
        this._subRights = this._appSvc.contractChange.subscribe(() => {
            if (this.hasSessionReadRight !== this._appSvc.hasRight(UserRight.SessionRead)) {
                this.hasSessionReadRight = this._appSvc.hasRight(UserRight.SessionRead);
                this._initData();
            }
            if (this.hasSessionUpdateRight !== this._appSvc.hasRight(UserRight.SessionUpdate)) {
                this.hasSessionUpdateRight = this._appSvc.hasRight(UserRight.SessionUpdate);
            }
        });
        const sub = this._route.paramMap.subscribe((param: ParamMap) => {
            let initData: boolean = false;
            let changeMode: boolean = false;
            if (param.has("id")) {
                let id = parseInt(param.get('id'));
                if (this._sessionId !== id) {
                    this._sessionId = id;
                    initData = true;
                }
                if (param.has('mode')) {
                    let mode = param.get('mode');
                    if (mode !== this._mode) {
                        this._mode = mode;
                        if (this._mode === "live" && this.isRunning === false) {
                            this._srvRouter.navigateToPopup(AppPages.SessionReportPast, { id: this._sessionId.toString() });
                        }
                        switch (this._mode) {
                            case 'live':
                                this.typeDisplaySelected = SessionDisplayType.Live;
                                this._activeTab = Tab.Orders;
                                this._page = AppPages.SessionReportLive;
                                break;
                            default:
                                this.typeDisplaySelected = SessionDisplayType.Session;
                                this._activeTab = Tab.Trades;
                                this._page = AppPages.SessionReportPast;
                                break;
                        }
                        changeMode = true;
                    }
                }
            } else if (this._sessionId) {
                this._sessionId = undefined;
                initData = true;
            }

            if (initData) {
                this._initData();
            } else if (changeMode) {
                this._updateData();
                this._cdr.markForCheck();
            }
            this._srvRouter.setAppTitle(this.titlePopup);
        });
        this._subs.push("paramMap", sub);
        this._hub = this._api.autotrading.hub(this._appSvc.contract.id);
        const subState = this._hub.stateChanges.subscribe((state) => {
            const hasLostConnection = state === HubConnectionState.Reconnecting;
            if (this.hasLostConnection !== hasLostConnection) {
                this.hasLostConnection = hasLostConnection;
                this._cdr.detectChanges();
            }
        });
        this._subscriptions.push("hubState", subState);
        const subSize = interval(1000).pipe(_ez.whenPageVisible()).subscribe(() => {
            this._refreshSizeTable();
        });
        this._subs.push("refreshSize", subSize);
    }

    ngAfterContentInit(): void {
        super.ngAfterContentInit();
        this._refreshSizeTable();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this._sectionAutoScroll.destroy();
        this._subRights.unsubscribe();
        this._cleanData();
    }
    // #endregion

    private _refreshSizeTable(): void {
        if (this._elRef != null && this._elRef.nativeElement != null) {
            const height = this._elRef.nativeElement.offsetHeight - 180;
            let newSize: number = 0;
            if (height > 300) {
                newSize = height;
            }
            if (this._sizeTableAccordion !== newSize) {
                this._sizeTableAccordion = newSize;
                this._cdr.detectChanges();
            }
        }
    }

    private _initData(): void {
        this.session = undefined;
        this.isRunning = undefined;
        if (this.hasSessionReadRight) {
            this._getSession();
        } else {
            this._cleanData();
        }
    }

    private _cleanData(): void {
        if (this._subs) this._subs.clearAll();
        this.runs = undefined;
        this.runLogs = undefined;
        this.runTrades = undefined;
        this.logs = undefined;
        this.orders = undefined;
        this.trades = undefined;
        this._cdr.markForCheck();
    }

    onSelectedOrder(order: IAtOrder): void {
        if (!this.hasSessionUpdateRight || !order) return;
        this.selectedContract = undefined;
        this.selectedContract = undefined;
        this._cdr.detectChanges();

        this.privateOrderSelected = order;
        this.selectedContract = new ContractAreaState(order.contract, undefined, new DateTime(), order.contract.deliveryStart);
        this.contracts = [this.selectedContract];

        this.selectedContractMetrics = this.contractMetrics.find(cm => cm.contract.name === cm.contract.name);

        this.hasOpenModalOrder = true;
        this._cdr.detectChanges();
    }

    onOrderFormClose(): void {
        this.privateOrderSelected = undefined;
        this.hasOpenModalOrder = false;
        this.orderForm?.formCancel();
    }

    private _getSession() {
        this._subs.clearSub("session");
        if (this._sessionId) {
            let sub = this._api.autotrading.sessions.get(this._appSvc.contract.id, this._sessionId).subscribe((obj) => {
                this.session = obj;
                this._getContractMetrics();
                this._streamRunMetrics();
                this._setTitle(obj);
            }, () => {
                this._setTitle(undefined);
            });
            this._subs.push("session", sub);
        } else {
            this._setTitle(undefined);
        }
    }

    private _getContractMetrics() {
        this._subs.clearSub("contractmetrics");

        if (!this._sessionId) {
            return;
        }

        let sub = this._api.autotrading.markets.metrics(this._appSvc.contract.id, this._sessionId).subscribe((obj) => {
            this.contractMetrics = obj;

            if (!this.selectedContract) {
                return;
            }

            this.selectedContractMetrics = this.contractMetrics.find(cm => cm.contract.name === this.selectedContract.contract.name);
            this._cdr.detectChanges();
        });

        this._subs.push("contractmetrics", sub);
    }

    private _streamRunMetrics(): void {
        this._subs.clearSub("runMetrics");
        if (this.session != null) {
            let lastState: RunState;
            const sub = this._hub.runMetrics(this.session).subscribe((result) => {
                if (result.data != null && (lastState == null || lastState !== result.data.runState)) {
                    lastState = result.data.runState;
                    let isRunning: boolean = false;
                    this.isRunningManual = false;
                    switch (lastState) {
                        case RunState.Manual:
                            this.isRunningManual = true;
                            isRunning = true;
                            break;
                        case RunState.Live:
                        case RunState.Simulation:
                        case RunState.Paused:
                        case RunState.AutomaticPaused:
                            isRunning = true;
                            break;
                        default:
                            this.onOrderFormClose();
                            if (this._mode === "live") {
                                this._srvRouter.navigateToPopup(AppPages.SessionReportPast, { id: this.session.id.toString() });
                            }
                            break;
                    }
                    if (this.isRunning == null || (isRunning !== this.isRunning)) {
                        this.getRuns();
                    }
                    this.isRunning = isRunning;
                    this._cdr.detectChanges();
                }
            });
            this._subs.push("runMetrics", sub);
        }
    }
    //#endregion Websocket

    private _refreshCurrentLogStream(withRefreshTotal: boolean = true): void {
        const data = _.slice(this.logs, 0, this._sizeCurrentLogs);
        const totalCount = this.currentLogsPaged != null && this.currentLogsPaged.totalCount > this.logs.length ? this.currentLogsPaged.totalCount : this.logs.length;
        this.currentLogsPaged = new PagedWrapper<ILog>(totalCount, 0, this._sizeCurrentLogs, false, data);
        if (withRefreshTotal) {
            this.loader.push('refreshcurrentlogs');
            this._api.autotrading.run.getLogs(this._appSvc.contract.id, this._sessionId, { start: 0, count: 1, direction: "desc" }, "current").subscribe((page) => {
                this.currentLogsPaged = new PagedWrapper<ILog>(page.totalCount, this.currentLogsPaged.start, this._sizeCurrentLogs, this.currentLogsPaged.isAbs, this.currentLogsPaged.data);
                this.loader.remove('refreshcurrentlogs');
                this._cdr.detectChanges();
            }, (error) => {
                this._logger.error("error get top current logs", error);
                this.loader.remove('refreshcurrentlogs');
                this._cdr.detectChanges();
            });
        }
    }

    private _refreshCurrentTradeStream(withRefreshTotal: boolean = true): void {
        const data = _.slice(this.trades, 0, this._sizeCurrentTrades);
        const totalCount = this.currentTradesPaged != null && this.currentTradesPaged.totalCount > this.trades.length ? this.currentTradesPaged.totalCount : this.trades.length;
        this.currentTradesPaged = new PagedWrapper<ITrade>(totalCount, 0, this._sizeCurrentTrades, false, data);
        if (withRefreshTotal) {
            this.loader.push('refreshcurrenttrades');
            this._api.autotrading.markets.privateTrades(this._appSvc.contract.id, this._sessionId, { start: 0, count: 1, direction: "desc" }, "current").subscribe((page) => {
                this.currentTradesPaged = new PagedWrapper<ITrade>(page.totalCount, this.currentTradesPaged.start, this._sizeCurrentTrades, this.currentTradesPaged.isAbs, this.currentTradesPaged.data);
                this.loader.remove('refreshcurrenttrades');
                this._cdr.detectChanges();
            }, (error) => {
                this._logger.error("error get top current trades", error);
                this.loader.remove('refreshcurrenttrades');
                this._cdr.detectChanges();
            });
        }
    }

    public getRuns(pagingEvent: PageEvent = { pageIndex: this.currentPage, pageSize: this.runPageSize }) {
        this.currentPage = pagingEvent.pageIndex;
        this.runPageSize = pagingEvent.pageSize;
        this._subs.clearSub('allRuns');
        if (this._sessionId) {
            this._loaderPage.push("getRuns");
            const paging: IPaging = { start: pagingEvent?.pageSize * pagingEvent?.pageIndex, count: pagingEvent?.pageSize, direction: 'desc' };
            let sub = this._api.autotrading.run.getPaginated(this._appSvc.contract.id, this._sessionId, paging).subscribe(
                (pagedRuns: IPagedWrapper<IRun>) => {
                    // remove last run if still running
                    this.runTotalCount = pagedRuns.totalCount;
                    let runs = _.orderBy(pagedRuns.data, 'runNumber', 'desc');
                    this.currentRun = _.maxBy(runs, (r) => r.runNumber);
                    if (this.currentRun && this.currentRun === pagedRuns.totalCount && !this.currentRun.effectiveEndDate)
                        runs = _.reject(runs, (r) => r.runNumber === this.currentRun.runNumber);
                    this.runs = runs;

                    this.runLogs = new Map<RunNumber, IPagedWrapper<ILog>>();
                    this.runTrades = new Map<RunNumber, IPagedWrapper<ITrade>>();
                    this._updateData();
                    this._loaderPage.remove("getRuns");
                    this._cdr.detectChanges();
                },
                () => {
                    this._loaderPage.remove("getRuns");
                    this._cdr.detectChanges();
                }
            );
            this._subs.push('allRuns', sub);
        }
    }

    private _getMarketTrades(runNumber: RunNumber, paging: IPaging = { start: 0, count: 50, direction: "desc" }) {
        if (this._sessionId) {
            this._subs.clearSub("trades" + runNumber);
            this.loader.push('trades');
            let sub = this._api.autotrading.markets.privateTrades(this._appSvc.contract.id, this._sessionId, paging, runNumber).subscribe(
                (obj: IPagedWrapper<ITrade>) => {
                    if (runNumber === 'current') {
                        this.currentTradesPaged = obj;
                        if (paging.isAbsoluteIndex === true) {
                            this.currentTradesPaged.data.splice(0, 1);
                        }
                        if (paging.direction === "asc") {
                            if (paging.start === 0) {
                                const startDelete = obj.totalCount % this._sizeCurrentTrades;
                                if (startDelete !== 0) {
                                    this.currentTradesPaged.data.splice(startDelete);
                                }
                            }
                            (this.currentTradesPaged as any).data = _.orderBy(this.currentTradesPaged.data, (trade) => trade.index, "desc");
                        }
                    } else {
                        this.runTrades.set(runNumber, obj);
                    }
                    this.loader.remove('trades');
                    this._cdr.detectChanges();
                    this._sectionAutoScroll.clearSection();
                },
                (err) => {
                    this.loader.remove('trades');
                    this._logger.error("error get market trades", err);
                    this._cdr.detectChanges();
                });
            this._subs.push("trades" + runNumber, sub);
        }
    }

    private _getLogs(runNumber: RunNumber, paging: IPaging = { start: 0, count: 50, direction: "desc" }) {
        if (this._sessionId) {
            this._subs.clearSub('logs' + runNumber);
            this.loader.push('logs');
            let sub = this._api.autotrading.run.getLogs(this._appSvc.contract.id, this._sessionId, paging, runNumber).subscribe(
                (obj: IPagedWrapper<ILog>) => {
                    _.forEach(obj.data, (log: ILog) => {
                        log.metadata = log.metadata ? _.orderBy(log.metadata, (meta: ILogMetadata) => meta.key) : log.metadata;
                    });
                    if (runNumber === "current") {
                        this.currentLogsPaged = obj;
                        if (paging.isAbsoluteIndex === true) {
                            this.currentLogsPaged.data.splice(0, 1);
                        }
                        if (paging.direction === "asc") {
                            if (paging.start === 0) {
                                const startDelete = obj.totalCount % this._sizeCurrentLogs;
                                if (startDelete !== 0) {
                                    this.currentLogsPaged.data.splice(startDelete);
                                }
                            }
                            (this.currentLogsPaged as any).data = _.orderBy(this.currentLogsPaged.data, (log) => log.id, "desc");
                        }
                    } else {
                        this.runLogs.set(runNumber, obj);
                    }
                    this.loader.remove('logs');
                    this._cdr.detectChanges();
                    this._sectionAutoScroll.clearSection();
                },
                (error) => {
                    this.loader.remove('logs');
                    this._logger.error("error get logs", error);
                    this._cdr.detectChanges();
                });
            this._subs.push('logs' + runNumber, sub);
        }
    }


    //#endregion API

    //#region private methods
    loadTrades(runNumber: number, section?: EzAccordionSectionComponent): void {
        if (section) {
            this._sectionAutoScroll.setSection(section);
        }
        let data = this.runTrades.get(runNumber);
        if (!data) {
            this._getMarketTrades(runNumber);
        } else {
            this._sectionAutoScroll.clearSection(200);
        }
    }

    loadLogs(runNumber: number, section?: EzAccordionSectionComponent): void {
        if (section) {
            this._sectionAutoScroll.setSection(section);
        }
        let data = this.runLogs.get(runNumber);
        if (!data) {
            this._getLogs(runNumber);
        } else {
            this._sectionAutoScroll.clearSection(200);
        }
    }

    private _updateData(): void {
        if (this.typeDisplaySelected !== SessionDisplayType.Live && this._activeTab === Tab.Orders) {
            this._activeTab = Tab.Trades;
        }
        this._subs.clearSub("updateData");
        if (this.session) {
            let sub: Subscription;
            switch (this._activeTab) {
                case this.tabs.Orders:
                    if (this.typeDisplaySelected === SessionDisplayType.Live) {
                        this._loaderPage.push("watch-order");
                        sub = this._hub.orders(this.session).subscribe((result) => {
                            this.orders = result.data.filter((a) => a.state !== OrderState.Deleted);
                            if (this.privateOrderSelected) {
                                let selectedOrder = _.find(this.orders, (o) => o.id === this.privateOrderSelected.id);
                                if (selectedOrder) this.privateOrderSelected = selectedOrder;
                            }
                            this._loaderPage.remove("watch-order");
                            this._cdr.detectChanges();
                        }, (error) => {
                            this._logger.error("Orders stream hub autotrading " + this.session.id, error);
                        }, () => {
                            this._logger.debug("Orders stream hub autotrading complete " + this.session.id);
                        });
                    }
                    break;
                case this.tabs.Trades:
                    switch (this.typeDisplaySelected) {
                        case SessionDisplayType.Session:
                            let first = _.first(this.runs);
                            if (first && !this.runTrades.get(first.runNumber))
                                this._getMarketTrades(first.runNumber);
                            break;
                        case SessionDisplayType.Live:
                            sub = this._hub.trades(this.session).subscribe((result) => {
                                if (result.data != null) {
                                    const withRefresh: boolean = this.trades == null || this.trades.length === 0;
                                    this.trades = _.orderBy(result.data, (trade) => trade.index, "desc");
                                    this.trades = _.slice(this.trades, 0, this._sizeCurrentTrades * 2);
                                    if (this.currentTradesPaged == null || this.currentTradesPaged.start === 0) {
                                        this._refreshCurrentTradeStream(withRefresh);
                                    }
                                    this._cdr.detectChanges();
                                }
                            }, (error) => {
                                this._logger.error("Trades stream hub autotrading " + this.session.id, error);
                            }, () => {
                                this._logger.debug("Trades stream hub autotrading complete " + this.session.id);
                            });
                            break;
                    }
                    break;
                case this.tabs.Logs:
                    switch (this.typeDisplaySelected) {
                        case SessionDisplayType.Session:
                            let first = _.first(this.runs);
                            if (first && !this.runLogs.get(first.runNumber))
                                this._getLogs(first.runNumber);
                            break;
                        case SessionDisplayType.Live:
                            sub = this._hub.logs(this.session).subscribe((result) => {
                                if (result != null) {
                                    const withRrefresh: boolean = this.logs == null || this.logs.length === 0;
                                    this.logs = _.orderBy(result.data, (log) => log.id, "desc");
                                    this.logs = _.slice(this.logs, 0, this._sizeCurrentLogs * 2);
                                    if (this._startCurrentLogs === 0) {
                                        this._refreshCurrentLogStream(withRrefresh);
                                    }
                                    this._cdr.detectChanges();
                                }
                            }, (error) => {
                                this._logger.error("Log stream hub autotrading " + this.session.id, error);
                            }, () => {
                                this._logger.debug("Log stream hub autotrading complete " + this.session.id);
                            });
                            break;
                    }
                    break;
            }
            if (sub != null) {
                this._subs.push("updateData", sub);
            }
        }
    }

    private _setTitle(session: ISession) {
        if (session) {
            this._subs.clearSub("title");
            let msg = "autotrading.session.popups.title.ordersandtrade";
            let sub = this._srvTranslate.get(msg).subscribe((trans: string) => {
                this.titlePopup = trans + ' ' + session.name;
                this._srvRouter.setAppTitle(this.titlePopup);
            });
            this._subs.push("title", sub);
        } else {
            this._srvRouter.setAppTitle('');
        }
    }
    //#endregion
}
