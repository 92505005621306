<ez-form (onSubmit)="formSubmit()">
    <ez-form-field>
        <ez-label>{{'general.exchange' | translate}}</ez-label>
        <ez-image-selector [multiselect]="false" [ngModel]="selectedExchange" [required]="true"
            [disabled]="schedule != null" (valueChange)="selectedExchangesChange($event)">
            <ez-image *ngFor="let exchange of exchanges" [url]="exchange.token | StaticExchangeUri:32"
                [name]="exchange.name" [value]="exchange">
            </ez-image>
        </ez-image-selector>
    </ez-form-field>
    <ez-form-field>
        <ez-label>{{'pages.auction-schedules.component.forms.base.product' | translate}}</ez-label>
        <ez-select [ngModel]="selectedProduct" (valueChange)="selectedProductChange($event)" [nullable]="true"
            [required]="true" [disabled]="schedule != null || !selectedExchange">
            <ez-select-option *ngFor="let product of products;" [value]="product">
                {{ product.name }}
            </ez-select-option>
        </ez-select>
    </ez-form-field>
    <ez-form-field>
        <ez-label>{{'pages.auction-schedules.component.forms.base.portfolio' | translate}}</ez-label>
        <ez-select [ngModel]="selectedPortfolio" [nullable]="true" (valueChange)="selectedPortfolioChange($event)"
            [required]="true" [disabled]="schedule != null || !selectedExchange" [multiple]="false" ez-overlay-origin
            #originLoader="EzOverlayOrigin">
            <ez-loader *ngIf="portfolioLoading" [origin]="originLoader"></ez-loader>
            <ez-select-option *ngFor="let portfolio of portfolios;" [value]="portfolio">
                {{ portfolio.name }}
            </ez-select-option>
        </ez-select>
    </ez-form-field>

    <ez-form-field>
        <ez-label>{{'pages.auction-schedules.component.forms.base.batchType' | translate}}</ez-label>
        <ez-button-group [singleSelection]="false" [value]="selectedBatchTypes"
            (valueChange)="selectedBatchTypesChange($event)" [required]="true">
            <ez-button [value]="batchTypes.Linear">
                {{ 'pages.auction-schedules.component.forms.base.linearBatchType' | translate }}
            </ez-button>
            <ez-button [value]="batchTypes.Block">
                {{ 'pages.auction-schedules.component.forms.base.blockBatchType' | translate }}
            </ez-button>
        </ez-button-group>
    </ez-form-field>

</ez-form>
