<div class="container">
    <div class="header">
        <ez-button-group [singleSelection]="false" [value]="filterAcknow" (valueChange)="filterAcknowChange($event)"
            [required]="false">
            <ez-button [label]="'events.read' | translate" [value]="acknowFilters.Read"></ez-button>
            <ez-button [label]="'events.unread' | translate" [value]="acknowFilters.Unread"></ez-button>
        </ez-button-group>
        <ez-button-group [singleSelection]="false" [value]="filterType" (valueChange)="filterTypeChange($event)"
            *ngIf="displayFilterBtn" [required]="false">
            <ez-button [label]="'enum.appEventType.'+eventTypes.Auction | translate" [icon]="'gavel'"
                [value]="eventTypes.Auction" *ngIf="hasReadAuction">
            </ez-button>
            <ez-button [label]="'enum.appEventType.'+eventTypes.AutoTrading | translate" [icon]="'chart-bar'"
                [value]="eventTypes.AutoTrading" *ngIf="hasReadAt">
            </ez-button>
            <ez-button [label]="'enum.appEventType.'+eventTypes.ComInterface | translate" [icon]="'arrows-alt-v'"
                [value]="eventTypes.ComInterface" *ngIf="hasReadCi"></ez-button>
        </ez-button-group>
    </div>
    <ez-table [data]="events" [external]="true" [onLoading]="_loaderPage.onLoading">
        <ez-column property="type" *ngIf="displayFilterBtn">
            <th *ezTbHeader>{{ 'general.type' | translate }}</th>
            <td *ezTbCell="let item">
                <ng-container [ngSwitch]="item.type">
                    <ez-icon [name]="'arrows-alt-v'" *ngSwitchCase="eventTypes.ComInterface"></ez-icon>
                    <ez-icon [name]="'chart-bar'" *ngSwitchCase="eventTypes.AutoTrading"></ez-icon>
                    <ez-icon [name]="'gavel'" *ngSwitchCase="eventTypes.Auction"></ez-icon>
                </ng-container>
            </td>
        </ez-column>
        <ez-column property="date">
            <th *ezTbHeader>{{ 'general.date' | translate }}</th>
            <td *ezTbCell="let item" class="td-date">
                {{item.date | ezdate}}
            </td>
        </ez-column>
        <ez-column property="characteristic">
            <th *ezTbHeader>{{ 'events.event' | translate }}</th>
            <td *ezTbCell="let item">
                <div class="event">
                    <div class="text">
                        <ng-container [ngSwitch]="item.type">
                            <div class="type-ci" *ngSwitchCase="eventTypes.ComInterface">
                                <span *ngIf="item.event.characteristic.comInterface != null">{{ 'events.process' |
                                    translate }}:
                                    {{item.event.characteristic.comInterface.name }}</span>
                                <span *ngIf="item.event.characteristic.file != null"> - {{ 'events.file' | translate }}:
                                    {{item.event.characteristic.file.name}}</span>
                            </div>
                            <div class="type-at" *ngSwitchCase="eventTypes.AutoTrading">
                                <span *ngIf="item.event.characteristic.session != null">{{ 'events.session' | translate
                                    }}:
                                    {{item.event.characteristic.session.name }}</span>
                                <span *ngIf="item.event.characteristic.run != null"> - {{ 'events.run' | translate }}:
                                    {{item.event.characteristic.run.runNumber}}</span>
                            </div>
                        </ng-container>
                        <div class="event-info">
                            {{item.event.freeText}}
                        </div>
                    </div>
                    <div class="btn-details" [ezTooltip]="'events.open-details' | translate" *ngIf="item.hasDetails">
                        <ez-icon [name]="item.detailsOpened ? 'caret-up' :'caret-down'" *ngIf="!item.onLoadDetails">
                        </ez-icon>
                        <ez-icon [name]="'circle-notch fa-spin'" *ngIf="item.onLoadDetails"></ez-icon>
                    </div>
                </div>
                <div class="detail" *ngIf="item.detailsOpened && item.hasDetails">
                    <div class="acknow-details" *ngIf="item.hasAckno" [ezTooltip]="'events.read-it' | translate"
                        [tooltipAlign]="'left'">
                        <ez-icon [name]="'envelope-open'"></ez-icon>
                        <span class="ackno-date">
                            {{item.event.acknowledge.date | ezdate}}
                        </span>
                    </div>
                    <ul *ngIf="item.detailsLoaded" class="list-details">
                        <li *ngFor="let detail of item.details">- {{detail.value}}</li>
                    </ul>

                </div>
            </td>
        </ez-column>
        <ez-column property="acknowledge">
            <th *ezTbHeader>
                <ez-button [icon]="'envelope-open'" [ezTooltip]="'events.mark-all-read' | translate"
                    (onClick)="acknowlegdeAll()" [disabled]="!hasUpdateAt && !hasUpdateCi && !hasUpdateAuction">
                </ez-button>
            </th>
            <td *ezTbCell="let item">
                <ez-loader *ngIf="item.onUpdated; else noUpdated"></ez-loader>
                <ng-template #noUpdated>
                    <ng-container *ngIf="item.hasAckno; else notAckno">
                        <ez-button [icon]="'envelope-open'" [ezTooltip]="'events.mark-unread' | translate"
                            (onClick)="unacknowledge($event,item)"
                            [disabled]="(!hasUpdateAt && item.type === eventTypes.AutoTrading) || (!hasUpdateCi && item.type === eventTypes.ComInterface) || (!hasUpdateAuction && item.type === eventTypes.Auction) ">
                        </ez-button>
                    </ng-container>
                    <ng-template #notAckno>
                        <ez-button [icon]="'envelope'" [severity]="'primary'"
                            [ezTooltip]="'events.mark-read' | translate" (onClick)="acknowlegde($event,item)"
                            [disabled]="(!hasUpdateAt && item.type === eventTypes.AutoTrading) || (!hasUpdateCi && item.type === eventTypes.ComInterface) || (!hasUpdateAuction && item.type === eventTypes.Auction) ">
                        </ez-button>
                    </ng-template>
                </ng-template>
            </td>
        </ez-column>
        <ez-paginator (pageChange)="onPageChange()" [sizeOptions]="[50]" [length]="lengthElements">
        </ez-paginator>
        <ez-row *ezTbRow="let item" (click)="toggleDetail(item)" [ngClass]="{'ackno': item.hasAckno}"></ez-row>
    </ez-table>
</div>
