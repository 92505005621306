import { DashboardChartDisplay, DashboardChartType } from './chart-type';
import { IColumnMetaData } from './grid-options';
import { DashboardTableType } from './table-type';
import { ViewLevel } from './view-level';
import { IViewToolPanelColumnData } from '../../tool-panel/actions/actions.toolpanel';
import { IPositionPreset } from '@core/preferences/pages/position-filters-preferences.model';
import { TimeZone } from '@eztypes/generic';

export interface IDashboardFormatOptions {
    readonly chartMode: DashboardChartDisplay;
    readonly tableMode: DashboardTableType;
    readonly tableType: DashboardTableType;

    readonly chartType: DashboardChartType;
    readonly decimalPlace: number;

    readonly timezone: TimeZone;
    readonly viewLevel?: ViewLevel | undefined;
    readonly columns?: IColumnMetaData[] | undefined;
    readonly columnsData?: IViewToolPanelColumnData[] | undefined;
    readonly hiddenDataKeys?: string[] | undefined;

    readonly showTotals: {
        enabled: boolean;
        visible: boolean;
    } | undefined;

    readonly showSubtotals: {
        enabled: boolean;
        visible: boolean;
    } | undefined;
}

export function validateDashboardFormatOptions(
    options: Partial<IDashboardFormatOptions>
): options is IDashboardFormatOptions {
    return options.tableType != null && options.chartType != null && options.chartMode != null && options.decimalPlace != null && options.timezone != null;
}

export function initDashboardFormatOptionsFromPreset(options: IDashboardFormatOptions, preset: IPositionPreset): IDashboardFormatOptions {
    const initoptionsFromPreset: any = {};
    const keysOfProps: (keyof IDashboardFormatOptions)[] = Object.keys(options) as (keyof IDashboardFormatOptions)[];
    keysOfProps.forEach((key: keyof IDashboardFormatOptions) => {
        if (key === 'timezone')
            initoptionsFromPreset[key] = TimeZone.create(preset[key].iana);
        else
            initoptionsFromPreset[key] = preset[key] ?? options[key];
    });
    return initoptionsFromPreset as IDashboardFormatOptions;
}