// @ts-nocheck
import { Pipe } from '@angular/core';
import * as _ from "lodash";

/**
 * @param  {'enumStringToIterable'}} {name [description]
 * @return {[type]}                 [description]
 */
@Pipe({
    name: 'enumStringToIterable',
})
export class EnumStringToIterablePipe<T> {

    constructor() { }

    transform(dict: Object, orderBy?: boolean): Array<{ T }> {
        let a = [];
        let keys = [];
        for (let key in dict) {
            if (keys && dict.hasOwnProperty(key) && keys.indexOf(key.toLowerCase()) == -1) {
                keys.push(key.toLowerCase());
                a.push({ key: key, value: dict[key] });
            }
        }
        if (orderBy !== undefined && orderBy === true) {
            a = _.orderBy(a, ["key"], ["asc"]);
        }
        return a;
    }
}
