// @ts-nocheck
// Core
import { DecimalPipe } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { isSame } from "@eznergy/core";
// Models
import {
    CurveType,
    Exchange,
    FlexStrategy,
    GenerationStrategy,
    IPhysicalValue,
    IThrottlingLimitRule,
    IUnit,
    IUnitFamily,
    IVolumeGradientItem,
    OrderDirection,
    OrderRestriction,
    OrderType,
    PhysicalValue,
    PositionStrategy,
    SessionType,
    ThrottlingLimit,
} from "@eztypes/webapi";
import { IDoublePhysicalValuesGradientItem } from "@eztypes/webapi/core/gradient-item-value";
// Components
import { FormBase } from "@forms/core/form-base";
import { TranslateService } from "@ngx-translate/core";
import Decimal from "decimal.js-light";
import * as _ from "lodash";

@Component({
    selector: "at-strategy-form",
    templateUrl: "./strategy.component.html",
    styleUrls: ["./strategy.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AtStrategyForm extends FormBase {
    @Output() valueChange: EventEmitter<
        FlexStrategy | PositionStrategy | GenerationStrategy
    > = new EventEmitter();

    orderDirection = OrderDirection;
    orderRestriction = OrderRestriction;
    type = SessionType;
    orderType = OrderType;
    ponderationApply: boolean = false;
    isConfirmModal: boolean;

    CurveType = CurveType;
    longInitiationProjection: number;
    shortInitiationProjection: number;
    longInitiationProjectionTranslate: string;
    shortInitiationProjectionTranslate: string;
    shortAggressionProjection: number;
    shortAggressionProjectionTranslation: string;
    longAggressionProjection: number;
    longAggressionProjectionTranslate: string;

    @Input()
    set value(value: FlexStrategy | PositionStrategy | GenerationStrategy) {
        if (!isSame(this.value, value)) {
            this._value = value;
            this._resetInternalValues();
            this._cdr.markForCheck();
        }
    }
    get value(): FlexStrategy | PositionStrategy | GenerationStrategy {
        return this._value;
    }
    private _value: FlexStrategy | PositionStrategy | GenerationStrategy;
    internalValue: FlexStrategy | PositionStrategy | GenerationStrategy;

    get internalValueFlex(): FlexStrategy {
        return this.internalValue instanceof FlexStrategy
            ? this.internalValue
            : undefined;
    }

    @Input()
    set sessionType(type: SessionType) {
        if (this.internalSessiontType !== type) {
            this.internalSessiontType = type;
            this._resetInternalValues();
            this._cdr.markForCheck();
        }
    }

    get sessionType() : SessionType {
        return this.internalSessiontType;
    }

    internalSessiontType: SessionType = SessionType.Position;

    @Input()
    get units(): IUnit[] {
        return this._units;
    }
    set units(value: IUnit[]) {
        if (this.units !== value) {
            this._units = value;
            this.unitMW = _.find(this._units, (u) => u.name === "MW");
            if (this.minOrderSize == null && this.unitMW != null) {
                this.minOrderSize = new PhysicalValue(this.unitMW, 0.1);

                this._resetInternalValues();
            }
            this._cdr.markForCheck();
        }
    }
    private _units: IUnit[];
    minOrderSize: PhysicalValue;
    unitMW: IUnit;

    @Input()
    get energyPriceUnitsFamily(): IUnitFamily {
        return this._energyPriceUnitsFamily;
    }
    set energyPriceUnitsFamily(value: IUnitFamily) {
        if (this.energyPriceUnitsFamily !== value) {
            this._energyPriceUnitsFamily = value;
            this._cdr.markForCheck();
        }
    }
    private _energyPriceUnitsFamily: IUnitFamily;

    @Input()
    get powerUnitsFamily(): IUnitFamily {
        return this._powerUnitsFamily;
    }
    set powerUnitsFamily(value: IUnitFamily) {
        this._powerUnitsFamily = value;
        this._cdr.markForCheck();
    }
    private _powerUnitsFamily: IUnitFamily;

    @Input()
    set defaultPriceValue(value: IPhysicalValue) {
        this._defaultPriceValue = value;
        this.defaultPriceValueOtr = _.clone(value);
        this.defaultPriceValueOmt = _.clone(value);

        if (value) {
            this.defaultPriceValueOtr.value = 0.1;
            this.defaultPriceValueOmt.value = 0.1;
        }
        this._cdr.markForCheck();
    }
    get defaultPriceValue(): IPhysicalValue {
        return this._defaultPriceValue;
    }
    private _defaultPriceValue: IPhysicalValue;

    defaultPriceValueOtr: IPhysicalValue;
    defaultPriceValueOmt: IPhysicalValue;

    @Input()
    set defaultVolumeValue(value: IPhysicalValue) {
        this._defaultVolumeValue = value;
        this.defaultVolumeValueOtr = _.clone(value);
        this.defaultVolumeValueOmt = _.clone(value);

        if (value) {
            this.defaultVolumeValueOtr.value = 0.1;
            this.defaultVolumeValueOmt.value = 0.1;
        }
        this._cdr.markForCheck();
    }
    get defaultVolumeValue(): IPhysicalValue {
        return this._defaultVolumeValue;
    }
    private _defaultVolumeValue: IPhysicalValue;
    defaultVolumeValueOtr: IPhysicalValue;
    defaultVolumeValueOmt: IPhysicalValue;


    get enableOmt(): boolean {
        return this._enableOmt;
    }
    private _enableOmt: boolean = true;

    public enableThrottling: boolean = false;

    @Input()
    get throttlingLimit(): ThrottlingLimit {
        return this._throttlingLimit;
    }
    set throttlingLimit(value: ThrottlingLimit) {
        if (this._throttlingLimit === value) {
            return;
        }

        this._throttlingLimit = value;
        this._cdr.markForCheck();
    }
    private _throttlingLimit: ThrottlingLimit;

    @Input() 
    get exchangeName(): Exchange {
        return this._exchangeName;
    }
    set exchangeName(value: Exchange) {
        this._exchangeName = value;
        this._enableCustomStrategy(this._exchangeName);
        this._cdr.detectChanges();
    }
    private _exchangeName: Exchange | undefined;


    defaultMaxValueOtr: number = Number.MAX_SAFE_INTEGER;
    defaultMaxValueOmt: number = Number.MAX_SAFE_INTEGER;

    get displayedQuantityValue(): number {
        return this.internalValue?.displayedQuantity?.value;
    }
    set displayedQuantityValue(value: number) {
        if (this.internalValue.displayedQuantity == null) {
            this.internalValue.displayedQuantity = new PhysicalValue(
                this.unitMW
            );
        }
        this.internalValue.displayedQuantity.value = value;
    }

    defaultLongOmtOrderModificationGradientsOffset2: IPhysicalValue =
        new PhysicalValue(
            {
                name: "s",
                description: undefined,
                family: undefined,
                id: undefined,
            },
            900
        );
    defaultLongOmtOrderModificationGradientsOffset1: IPhysicalValue =
        new PhysicalValue(
            {
                name: "",
                description: undefined,
                family: undefined,
                id: undefined,
            },
            90000
        );
    defaultShortOmtOrderModificationGradientsOffset2: IPhysicalValue =
        new PhysicalValue(
            {
                name: "s",
                description: undefined,
                family: undefined,
                id: undefined,
            },
            1
        );
    defaultShortOmtOrderModificationGradientsOffset1: IPhysicalValue =
        new PhysicalValue(
            {
                name: "",
                description: undefined,
                family: undefined,
                id: undefined,
            },
            100
        );
    defaultMaxVolumeValueOMT: number = Number.MAX_SAFE_INTEGER;

    get omtShortPriceGradients(): IVolumeGradientItem[] {
        return this.internalValue?.omtShortRulesParameters?.priceGradients;
    }
    set omtShortPriceGradients(value: IVolumeGradientItem[]) {
        this.internalValue.omtShortRulesParameters.priceGradients = value;
    }

    get omtShortOrderModificationGradients(): IDoublePhysicalValuesGradientItem[] {
        return this.internalValue?.omtShortRulesParameters
            ?.orderModificationGradients;
    }
    set omtShortOrderModificationGradients(
        value: IDoublePhysicalValuesGradientItem[]
    ) {
        this.internalValue.omtShortRulesParameters.orderModificationGradients =
            value;
    }

    get omtShortVolumeGradients(): IVolumeGradientItem[] {
        return this.internalValue?.omtShortRulesParameters?.volumeGradients;
    }
    set omtShortVolumeGradients(value: IVolumeGradientItem[]) {
        this.internalValue.omtShortRulesParameters.volumeGradients = value;
    }
    get omtLongPriceGradients(): IVolumeGradientItem[] {
        return this.internalValue?.omtLongRulesParameters?.priceGradients;
    }
    set omtLongPriceGradients(value: IVolumeGradientItem[]) {
        this.internalValue.omtLongRulesParameters.priceGradients = value;
    }

    get omtLongVolumeGradients(): IVolumeGradientItem[] {
        return this.internalValue?.omtLongRulesParameters?.volumeGradients;
    }
    set omtLongVolumeGradients(value: IVolumeGradientItem[]) {
        this.internalValue.omtLongRulesParameters.volumeGradients = value;
    }

    get omtLongOrderModificationGradients(): IDoublePhysicalValuesGradientItem[] {
        return this.internalValue?.omtLongRulesParameters
            ?.orderModificationGradients;
    }
    set omtLongOrderModificationGradients(
        value: IDoublePhysicalValuesGradientItem[]
    ) {
        this.internalValue.omtLongRulesParameters.orderModificationGradients =
            value;
    }

    public exchangeEnum = Exchange;

    constructor(
        private readonly _numberPipe: DecimalPipe,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _serviceTranslate: TranslateService
    ) {
        super();
    }

    formSubmit() {
        if (this.internalValue.type == OrderType.Regular)
            this.internalValue.displayedQuantity = null;
        this._value = _.cloneDeep(this.internalValue);

        this._resetInternalValues();

        this.valueChange.emit(this._value);
        this.onSubmit.emit();
    }

    cloneShortParamsToLong(): void {
        this.internalValue.omtLongRulesParameters.initiatorLimit =
            this.internalValue.omtShortRulesParameters.initiatorLimit;
        this.internalValue.omtLongRulesParameters.aggressorLimit =
            this.internalValue.omtShortRulesParameters.aggressorLimit;
        this.omtLongOrderModificationGradients = _.cloneDeep(
            this.omtShortOrderModificationGradients
        );
        this.omtLongPriceGradients = _.cloneDeep(this.omtShortPriceGradients);
        this.omtLongVolumeGradients = _.cloneDeep(this.omtShortVolumeGradients);
    }

    formCancel(): void {
        this._resetInternalValues();
    }

    private _resetInternalValues() {
        if(this._value ) {
            this.viewUpdate = true;
            this.internalValue = _.cloneDeep(this._value);
            return;
        }

        switch(this.sessionType) {
            case SessionType.Flex : {
                this.internalValue = new FlexStrategy();
                break;
            }
            case SessionType.Position :
            default : {
                this.internalValue = new PositionStrategy();
                break;
            }
        }        
    }

    public getLimitDescription(
        value: number,
        throttlingLimit: IThrottlingLimitRule
    ): string {
        value ??= 0;
        const limit1 = throttlingLimit?.limitL1;

        const omtActions = Math.floor((limit1 / 100) * value);
        let output = `${this._serviceTranslate.instant(
            "autotrading.session.parameters.strategies.strategy.omt.l1LimitDescription",
            {
                value: this._numberPipe.transform(omtActions, "1.0-1"),
                l1: this._numberPipe.transform(limit1, "1.0-1"),
            }
        )}`;

        const limit2 = throttlingLimit?.limitL2;

        if (value <= 100) {
            return output;
        }

        const l2Percentage = new Decimal(value)
            .times(limit1)
            .dividedBy(limit2)
            .toDecimalPlaces(0)
            .toNumber();

        return `${output} ${this._serviceTranslate.instant(
            "autotrading.session.parameters.strategies.strategy.omt.l2LimitDescription",
            {
                value: this._numberPipe.transform(l2Percentage, "1.0-1"),
                l2: this._numberPipe.transform(limit2, "1.0-1"),
            }
        )}`;
    }

    private _enableCustomStrategy(exchange: Exchange): void {
        this._enableOmt = exchange == Exchange.EpexSpot;
        this.enableThrottling = exchange == Exchange.NordPool;
    }
}
