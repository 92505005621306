import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EzComponentsModule } from '@eznergy/components';
import { TranslateModule } from '@ngx-translate/core';
import { AppPipesModule } from '@pipes/pipes.module';
import { CommodityIcon } from './commodity/commodity.icon';
import { GasIcon } from './gas/gas.icon';
import { AcknowledgedStateIcon } from './nominations/acknowledged-state/acknowledged-state.icon';
import { ExpectedFinalValidationStateIcon } from './nominations/expected-final-validation-state/expected-final-validation-state.icon';
import { FinancialOvershootStateIcon } from './nominations/financial-overshoot-state/financial-overshoot-state.icon';
import { FinancialRejectedStateIcon } from './nominations/financial-rejected-state/financial-rejected-state.icon';
import { InvalidStateIcon } from './nominations/invalid-state/invalid-state.icon';
import { MissmatchStateIcon } from './nominations/missmatch-state/missmatch-state.icon';
import { NoValueStateIcon } from './nominations/no-value-state/no-value-state.icon';
import { NominationStateIcon } from './nominations/nomination-state/nomination-state.icon';
import { SentWarningIcon } from './nominations/sent-warning/sent-warning.icon';
import { NominationTechnicalStateIcon } from './nominations/technical-state/technical-state.icon';
import { ToSendStateIcon } from './nominations/to-send-state/to-send-state.icon';
import { UnknowStateIcon } from './nominations/unknow-state/unknow-state.icon';
import { PowerIcon } from './power/power.icon';
import { TransportPowerEntryIcon } from './transport/transport-power-entry/transport-power-entry.icon';
import { TransportGazEntryIcon } from './transport/transport-gaz-entry/transport-gaz-entry.icon';
import { TransportGazExitIcon } from './transport/transport-gaz-exit/transport-gaz-exit.icon';
import { TransportPowerExitIcon } from './transport/transport-power-exit/transport-power-exit.icon';
import { TransportDirectionComponent as TransportDirectionIcon } from './transport/transport-direction/transport-direction.icon';
import { AllStateIcon } from './nominations/all-state/all-state.icon';
import { PriceDirectionIconComponent } from './auction/price-direction-icon/price-direction.icon';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        AppPipesModule,
        EzComponentsModule,
        CommonModule,
        TranslateModule,
        MatIconModule
    ],
    declarations: [
        CommodityIcon,
        GasIcon,
        PowerIcon,
        InvalidStateIcon,
        AcknowledgedStateIcon,
        SentWarningIcon,
        ToSendStateIcon,
        FinancialOvershootStateIcon,
        FinancialRejectedStateIcon,
        MissmatchStateIcon,
        NoValueStateIcon,
        ExpectedFinalValidationStateIcon,
        UnknowStateIcon,
        NominationTechnicalStateIcon,
        NominationStateIcon,
        TransportGazEntryIcon,
        TransportPowerEntryIcon,
        TransportGazExitIcon,
        TransportPowerExitIcon,
        TransportDirectionIcon,
        AllStateIcon,
        PriceDirectionIconComponent
    ],
    exports: [
        CommodityIcon,
        GasIcon,
        PowerIcon,
        InvalidStateIcon,
        AcknowledgedStateIcon,
        SentWarningIcon,
        ToSendStateIcon,
        FinancialOvershootStateIcon,
        FinancialRejectedStateIcon,
        MissmatchStateIcon,
        NoValueStateIcon,
        ExpectedFinalValidationStateIcon,
        UnknowStateIcon,
        NominationTechnicalStateIcon,
        NominationStateIcon,
        TransportGazEntryIcon,
        TransportPowerEntryIcon,
        TransportGazExitIcon,
        TransportPowerExitIcon,
        TransportDirectionIcon,
        AllStateIcon,
        PriceDirectionIconComponent
    ]
})
export class IconsModule { }
