import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

interface IAlertModal<T> {
    content?: string,
    component?: T
} 

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
        CommonModule
    ],
})

export class AlertModalComponent<T> {

    constructor(@Inject(MAT_DIALOG_DATA) public data: IAlertModal<T>) { }

}