// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Subscriptions } from '@core';
import { Logger } from '@core/logger';
import { ApiService } from '@eznergy/webapi';
import { DateRange, DateTime, DayUnit, IDateRange, TimeUnit, TimeZone } from '@eztypes/generic';
import { ICommunicationInterface, IGate, IGrid, INominationSchedule, IProductionSchedule, ScheduleImmediateByDay, ScheduleImmediateByPeriod, ScheduleProperties } from "@eztypes/webapi";
import { ApplicationService } from "@services/application.service";
import * as _ from "lodash";
import { Observable } from 'rxjs';
// Constant
import { DefaultValues } from '../../../../../../constants/constant.default-values';
// Enum
import { DateRangeType } from '@enums/date-range-type.enum';
// Model
import { scheduleType } from '../create-schedules.component';

@Component({
    selector: "ez-immediate-schedules",
    templateUrl: "./immediate-schedules.component.html",
    styleUrls: ["./immediate-schedules.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None

})
export class ImmediateSchedulesComponent {
    @Output() onCreateSchedule: EventEmitter<INominationSchedule[] | IProductionSchedule[]> = new EventEmitter<INominationSchedule[] | IProductionSchedule[]>();

    @Input()
    get grid(): IGrid {
        return this._grid;
    }
    set grid(value: IGrid) {
        if (this.grid !== value) {
            this._grid = value;
            this._initForm();
            this._cdr.markForCheck();
        }
    }
    private _grid: IGrid;

    @Input()
    get gates(): IGate[] {
        return this._gates;
    }
    set gates(value: IGate[]) {
        if (this.gates !== value) {
            this._gates = value;
            this._cdr.markForCheck();
        }
    }
    private _gates: IGate[];

    @Input()
    get selectedGate(): IGate {
        return this._selectedGate;
    }
    set selectedGate(value: IGate) {
        if (this.selectedGate !== value) {
            this._selectedGate = value;
            this._cdr.markForCheck();
        }
    }
    private _selectedGate: IGate;

    @Input()
    get interfaces(): ICommunicationInterface[] {
        return this._interfaces;
    }
    set interfaces(value: ICommunicationInterface[]) {
        if (this.interfaces !== value) {
            this._interfaces = value;
            this._cdr.markForCheck();
        }
    }
    private _interfaces: ICommunicationInterface[];

    @Input()
    get selectedInterfaces(): ICommunicationInterface[] {
        return this._selectedInterfaces;
    }
    set selectedInterfaces(value: ICommunicationInterface[]) {
        if (this.selectedInterfaces !== value) {
            this._selectedInterfaces = value;
            this._cdr.markForCheck();
        }
    }
    private _selectedInterfaces: ICommunicationInterface[];

    @Input()
    get type(): scheduleType {
        return this._type;
    }
    set type(type: scheduleType) {
        if (this.type !== type) {
            this._type = type;
            this._cdr.markForCheck();
        }
    }
    private _type: scheduleType;

    readonly dateRangeType = DateRangeType;

    get dayBefore(): string {
        return this._dayBefore?.toDateLocaleString();
    }
    private _dayBefore: DateTime;

    get intraday(): string {
        return this._intraday?.toDateLocaleString();
    }
    private _intraday: DateTime;

    get dayAhead(): string {
        return this._dayAhead?.toDateLocaleString();
    }
    private _dayAhead: DateTime;

    periodDate: IDateRange;
    customDate: DateTime;

    activateVersion: boolean;
    version: number = 1;
    readonly minVersion: number = DefaultValues.minVersion;
    readonly maxVersion: number = DefaultValues.maxVersion;
    selectedRadio: DateRangeType = DateRangeType.Intraday;
    private readonly _subs: Subscriptions = new Subscriptions();

    public isLoading: boolean = false;

    get timezone(): TimeZone {
        return this._timezone;
    }
    private _timezone: TimeZone;

    constructor(private readonly _cdr: ChangeDetectorRef,
        private readonly _api: ApiService, private readonly _appSvc: ApplicationService,
        private readonly _logger: Logger) {
        this.version = DefaultValues.minVersion;
    }

    customDateChange(date: DateTime): void {
        this.customDate = date.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true);
    }

    periodDateChange(period: IDateRange): void {
        this.periodDate = new DateRange(period.from.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true), period.to.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true));
    }

    private _initForm(): void {
        if (this.grid?.calendar != null) {
            this._timezone = TimeZone.create(this.grid.calendar.ianaName);
            this._intraday = new DateTime(undefined, this.grid.calendar.ianaName).startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true);
            this._dayBefore = this._intraday.addDays(-1);
            this._dayAhead = this._intraday.addDays(1);
            const nextMonday = this._intraday.nextDay(DayUnit.Monday, this._intraday.dayOfWeek === DayUnit.Monday ? 1 : undefined);
            const followingSunday = nextMonday.nextDay(DayUnit.Sunday);
            this.customDate = this._intraday.addDays(2);
            this.periodDate = new DateRange(nextMonday.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true), followingSunday.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true));
            this.selectedRadio = DateRangeType.Intraday;
            this.version = 1;
            this.activateVersion = false;
        }
    }

    ngOnDestroy(): void {
        if (this._subs)
            this._subs.clearAll();
    }

    private _isPeriodValid(): boolean {
        if ((this.selectedRadio === DateRangeType.Period && this.periodDate != null && this.periodDate.from != null && this.periodDate.to != null) ||
            (this.selectedRadio !== DateRangeType.Period)) {
            return true;
        }
        return false;
    }

    private _isSpecificDayValid(): boolean {
        if ((this.selectedRadio === DateRangeType.SpecificDay && this.customDate) || (this.selectedRadio !== DateRangeType.SpecificDay)) {
            return true;
        }
        return false;
    }

    private _isInterfacesValid(): boolean {
        if ((this._selectedInterfaces) && (this._selectedInterfaces.length > 0)) {
            return true;
        }
        return false;
    }

    private _getSelectedDay(): DateTime {
        switch (this.selectedRadio) {
            case DateRangeType.DayBefore:
                return this._dayBefore;
            case DateRangeType.Intraday:
                return this._intraday;
            case DateRangeType.DayAhead:
                return this._dayAhead;
            case DateRangeType.SpecificDay:
                return this.customDate;
            case DateRangeType.Period:
                return null;
        }
    }

    private _internalValidForm(): boolean {
        return this._isSpecificDayValid() && this._isPeriodValid() && this._isInterfacesValid();
    }

    onValidateForm() {
        if (this._internalValidForm()) {
            let properties: ScheduleProperties = null;
            let path: string;
            if (this._type) {
                path = this._type + "ScheduleImmediateBy";
            } else {
                return;
            }
            switch (this.selectedRadio) {
                case DateRangeType.DayBefore:
                case DateRangeType.Intraday:
                case DateRangeType.DayAhead:
                case DateRangeType.SpecificDay: {
                    let propertiesImmediateByDate = new ScheduleImmediateByDay();
                    propertiesImmediateByDate.date = this._getSelectedDay();
                    propertiesImmediateByDate.version = this.activateVersion === true ? this.version : null;
                    propertiesImmediateByDate.gateId = this._selectedGate ? this._selectedGate.id : null;
                    propertiesImmediateByDate.interfaceIds = _.flatMap(this._selectedInterfaces, (inter: ICommunicationInterface) => { return inter.id; });
                    propertiesImmediateByDate.calendarId = this.grid.calendar.id;
                    properties = propertiesImmediateByDate;
                    path += "Day";
                }
                    break;
                case DateRangeType.Period: {
                    let propertiesImmediateByPeriod = new ScheduleImmediateByPeriod();
                    propertiesImmediateByPeriod.startDate = this.periodDate.from;
                    propertiesImmediateByPeriod.endDate = this.periodDate.to;
                    propertiesImmediateByPeriod.version = this.activateVersion === true ? this.version : null;
                    propertiesImmediateByPeriod.gateId = this._selectedGate ? this._selectedGate.id : null;
                    propertiesImmediateByPeriod.interfaceIds = _.flatMap(this._selectedInterfaces, (inter: ICommunicationInterface) => { return inter.id; });
                    propertiesImmediateByPeriod.calendarId = this.grid.calendar.id;
                    properties = propertiesImmediateByPeriod;
                    path += "Period";
                }
                    break;
            }
            this._onCreateSchedule(path, properties);
        }
    }

    private _onCreateSchedule(path: string, properties: ScheduleProperties): void {
        this.isLoading = true;
        if (path) {
            let obs: Observable<INominationSchedule[] | IProductionSchedule[]> = null;
            switch (path) {
                case 'nominationsScheduleImmediateByDay':
                    if (properties instanceof ScheduleImmediateByDay)
                        obs = this._api.nominations.schedules.createImmediateDay(this._appSvc.contract.id, properties);
                    break;
                case 'nominationsScheduleImmediateByPeriod':
                    if (properties instanceof ScheduleImmediateByPeriod)
                        obs = this._api.nominations.schedules.createImmediatePeriod(this._appSvc.contract.id, properties);
                    break;
                case 'productionsScheduleImmediateByDay':
                    if (properties instanceof ScheduleImmediateByDay)
                        obs = this._api.productions.schedules.createImmediateDay(this._appSvc.contract.id, properties);
                    break;
                case 'productionsScheduleImmediateByPeriod':
                    if (properties instanceof ScheduleImmediateByPeriod)
                        obs = this._api.productions.schedules.createImmediatePeriod(this._appSvc.contract.id, properties);
                    break;
                default:
                    return;
            }
            this._subs.clearSub("CreateScheduleImmediate");
            let sub = obs.subscribe(
                (resp: INominationSchedule[] | IProductionSchedule[]) => {
                    this.isLoading = false;
                    this.onCreateSchedule.emit(resp);
                },
                (error) => {
                    this.isLoading = false;
                    this._logger.error({ message: 'error call ' + path, data: this._appSvc.contract.id }, error);
                },
                () => {
                    this.isLoading = false;
                    this._cdr.detectChanges();
                }
            );
            this._subs.push("CreateScheduleImmediate", sub);
        }
    }
}
