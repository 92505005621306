<ez-form (onSubmit)="onSubmitForm()" (onCancel)="onCancelForm()" [autofocus]="false">
    <ez-button-group [(value)]="modeRun">
        <ez-button [value]="modeRunEnum.Live" class="btn-live" [disabled]="hasReadOnlyAccess">
            {{ 'autotrading.runMode.' + modeRunEnum.Live | translate }}
        </ez-button>
        <ez-button [value]="modeRunEnum.Simu" class="btn-simu">
            {{ 'autotrading.runMode.' + modeRunEnum.Simu | translate }}
        </ez-button>
    </ez-button-group>
    <ez-form-field class="timers">
        <ez-datepicker [placeholder]="'autotrading.session.schedule.startDate' | translate"
            [ngModel]="tempConfig?.startDate" [minDate]="currentDate" (valueChange)="onStartDateSelected($event)"
            [withTime]="true" [required]="true">
        </ez-datepicker>
        <span class="timer">{{ tempConfig?.startDate | eztimer:{withPast: false, timer: true} }}</span>
    </ez-form-field>
    <ez-form-field class="timers">
        <ez-datepicker [placeholder]="'autotrading.session.schedule.endDate' | translate"
            [ngModel]="tempConfig?.endDate" [minDate]="tempConfig?.startDate" [maxDate]="maxEndDate"
            [ez-maxDate]="maxEndDate" (valueChange)="onEndDateSelected($event)" [withTime]="true" [required]="true">
        </ez-datepicker>
        <span class="timer">{{ tempConfig?.endDate | eztimer:{withPast: false, timer: true} }}</span>
        <ez-message-error [validator]="'maxDate'"
            [message]="'autotrading.session.schedule.errorDurationRun' | translate">
        </ez-message-error>
    </ez-form-field>

    <div id="default-values">
        <button mat-flat-button color="primary" (click)="setEndDate('hour')">1 {{ 'general.timeunit.hour' | translate }}</button>
        <button mat-flat-button color="primary" (click)="setEndDate('day')">1 {{ 'general.timeunit.day' | translate }}</button>
        <button mat-flat-button color="primary" (click)="setEndDate('month')">1 {{ 'general.timeunit.month' | translate }}</button>
    </div>

    <ez-footer *ngIf="!hideFooter">
        <ez-button [label]="'general.cancel' | translate" ez-form-cancel></ez-button>
        <ez-button [label]="'general.start' | translate" [severity]="'primary'" ez-form-submit></ez-button>
    </ez-footer>
</ez-form>
