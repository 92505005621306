// @ts-nocheck
import { EventEmitter, Input, Output, ViewChild, Directive } from "@angular/core";
import { EzForm } from '@eznergy/components';
import { MultiLoader } from '@core';
import { Subscriptions } from '@core';
import { auditTime } from "rxjs/operators";

@Directive({ selector: 'base-form' })
export class FormBase {

    @Output() readonly onSubmit: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly onChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() readonly onError: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(EzForm, { static: true }) readonly form: EzForm;

    @Input()
    get hideFooter(): boolean {
        return this._hideFooter;
    }
    set hideFooter(value: boolean) {
        this._hideFooter = value;
    }
    private _hideFooter: boolean = false;

    @Input()
    get readonly(): boolean {
        return this._readonly;
    }
    set readonly(value: boolean) {
        this._readonly = value;
        if (this.form)
            this.form.readOnly = this._readonly;
    }
    private _readonly: boolean;

    @Input()
    set disabled(value: boolean) {
        if (value !== this.disabled) {
            this._disabled = value;
            if (this.form)
                this.form.disabled = this._disabled;
        }
    }
    get disabled(): boolean {
        return this._disabled;
    }
    private _disabled: boolean;

    get formHasLoading(): boolean {
        return this._loaderForm.onLoading;
    }
    _loaderForm: MultiLoader = new MultiLoader();
    _subscriptions: Subscriptions = new Subscriptions();

    set viewUpdate(value: boolean) {
        if (this._viewUpdate !== value) {
            this._viewUpdate = value;
            if (this.form)
                this.form.viewUpdate = this._viewUpdate;
        }
    }
    private _viewUpdate: boolean;

    constructor() {
        this._loaderForm.push("init-form");
    }

    ngAfterContentInit(): void {
        if (this.form) {
            if (this._viewUpdate == undefined) {
                this._viewUpdate = false;
            }
            this.form.viewUpdate = this._viewUpdate;

            this.form.onChange.pipe(auditTime(50)).subscribe((hasChange: boolean) => {
                this.onChange.emit(hasChange);
            }, (error) => {
                this.onChange.error(error);
            }, () => {
                this.onChange.complete();
            });

            this.form.onCancel.pipe(auditTime(50)).subscribe(() => {
                this.onCancel.emit();
            }, (error) => {
                this.onCancel.error(error);
            }, () => {
                this.onCancel.complete();
            });

            this.form.onError.subscribe(() => {
                this.onError.emit();
            }, (error) => {
                this.onError.error(error);
            }, () => {
                this.onError.complete();
            });

            this.form.disabled = this._disabled;
        }
        this._loaderForm.remove("init-form");
    }

    ngOnDestroy(): void {
        if (this._subscriptions) {
            this._subscriptions.clearAll();
        }
    }

    focus(): void {
        if (this.form) {
            this.form.focus();
        }
    }

    submit(): boolean {
        if (this.form && this.form.submit) {
            return this.form.submit();
        } else {
            return false;
        }
    }

    cancel(): void {
        if (this.form && this.form.cancel) {
            this.form.cancel();
        }
    }

    reset(): void {
        if (this.form && this.form.reset) {
            this.form.reset();
        }
    }

    markAsDirty(): void {
        if (this.form?._fields) {
            this.form._fields.forEach((field) => {
                field.ngControl.control.markAllAsTouched();
            });
        }
    }

    hasChanged(): boolean {
        if (this.form && this.form.submit) {
            return this.form.hasChanged();
        }
        return undefined;
    }
}
