import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-form-group',
  templateUrl: './custom-form-group.component.html',
  styleUrls: ['./custom-form-group.component.scss']
})
export class CustomFormGroupComponent {

}
