import { NgModule } from '@angular/core';
import { CoreFormModule } from './core/core.module';
import { PositionFormModule } from './position/position.module';
import { ScenarioFormModule } from './scenarios/scenario.module';

@NgModule({
    imports: [
        PositionFormModule,
        ScenarioFormModule,
        CoreFormModule
    ],
    exports: [
        PositionFormModule,
        ScenarioFormModule,
        CoreFormModule
    ]
})
export class AppFormsModule {

}
