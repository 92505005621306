import {
    Attribute,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Optional,
    Output,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { PRIMARY_OUTLET } from '@angular/router';
import { AppDisplayGridType, AppQueryParamsKey } from '@core/application.model';
import { Subscriptions } from '@core/subscriptions';
import { coerceBoolean, containsSame, isSame, orderBy, sortBy } from '@eznergy/core';
import { ApiService } from '@eznergy/webapi';
import { GridTypeCode, ICountry, IGrid, MaterialCode, GridPointCategories } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import { DataService } from '@services/data.service';
import { GridPointService, TreeGridPointWithCp } from '@services/gridpoints.service';
import { AppPages, RouterService } from '@services/router.service';
import { combineLatest, fromEvent, merge, Observable, Subscription } from 'rxjs';
import { auditTime, finalize, map } from 'rxjs/operators';
import { ISelectionCounterpart, ISelectionData, SelectionData } from './model';

interface IGridView {
    grid: IGrid;
    gridpoints: GridPointView | undefined;
    autofocus: boolean;
    selected: boolean;
    opened: boolean;
}

interface ICountryView {
    country: ICountry;
    grids: IGridView[];
    hasSelection: boolean | undefined;
    opened: boolean;
}

export class GridPointView extends TreeGridPointWithCp {
    opened: boolean = false;
    selected: boolean = false;
    // @ts-ignore
    children: GridPointView[];
    cpSelection: ISelectionCounterpart[] = [];
}

enum GridTypeCodeExchange {
    codeAuction = "AUCTION",
    codeContinuous = "CONTINUOUS"
}

type GridTypeGrid = GridTypeCode | GridTypeCodeExchange;

@Component({
    selector:
        'grids-controller, grids-controller-balancing, grids-controller-nomination, grids-controller-asset, grids-controller-auction, grids-controller-continuous',
    templateUrl: './grids.controller.html',
    styleUrls: ['./grids.controller.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class GridsController {
    @Output() readonly gridsChange: EventEmitter<IGrid[]> = new EventEmitter();
    @Output() readonly gridChange: EventEmitter<IGrid> = new EventEmitter();
    @Output() readonly selectionChange: EventEmitter<ISelectionData> = new EventEmitter();

    readonly materialCode = MaterialCode;
    readonly displayTypes = AppDisplayGridType;
    readonly gridTypes = GridTypeCode;

    @Input()
    get filterGrids(): IGrid[] {
        return this._filterGrids;
    }
    set filterGrids(value: IGrid[]) {
        if (this.filterGrids !== value) {
            this._filterGrids = value;
            this._prepareGrids();
            this._cdr.markForCheck();
        }
    }
    private _filterGrids: IGrid[] = [];

    @Input()
    set appPage(value: AppPages) {
        if (this._appPage !== value) {
            this._appPage = value;
        }
    }
    private _appPage!: AppPages;

    @HostBinding('class.list-only')
    get withGP(): boolean {
        return this._withGP;
    }
    private _withGP: boolean = false;

    get withGPData(): boolean {
        return this._withGPData;
    }
    private _withGPData: boolean = false;

    get data(): ICountryView[] | null {
        return this._data;
    }
    private _data: ICountryView[] | null = null;

    get grids(): IGridView[] | null {
        return this._gridviews;
    }
    private _gridviews: IGridView[] | null = null;
    private _gridsCache: IGrid[] | null = null;

    get typeDisplay(): AppDisplayGridType {
        return this._typeDisplay;
    }
    private _typeDisplay: AppDisplayGridType = AppDisplayGridType.Normal;

    get sizeIcon(): number {
        return this._sizeIcon;
    }
    private _sizeIcon: number = 32;

    get sizeSelection() {
        return this._sizeSelection;
    }
    private _sizeSelection: number = 0;

    get hasSelection(): boolean {
        return this._hasSelection;
    }
    private _hasSelection: boolean = false;

    // @HostBinding("class.hide")
    get hidden(): boolean {
        return !this._displayed;
    }
    private _displayed: boolean = false;

    @HostBinding('class.multi-sel')
    readonly hasMultiple: boolean = false;

    @HostBinding('class.on-move')
    get onMove(): boolean {
        return this._onMove;
    }
    private _onMove: boolean = false;

    readonly hideResize: boolean;

    private _subMouseMove: Subscription | undefined;
    private _subMouseUp: Subscription | undefined;
    private readonly _maxWidth: string = '40%';
    private _minWidth: string = '230px';
    private _width: string = '230px';
    private _queryGridValue: number[] | undefined;
    private _queryGridPointValue: number[] | undefined;
    private _queryDealValue: { [id: number]: number[] } | undefined;
    private _queryCpValue: { [id: number]: number[] } | undefined;
    private readonly _subs: Subscriptions = new Subscriptions();
    readonly type: GridTypeGrid | undefined;
    private _hasFiltersGrids: boolean;
    private readonly _selection: SelectionData = new SelectionData();

    constructor(
        private readonly _appSvc: ApplicationService,
        private readonly _el: ElementRef<HTMLElement>,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _routerSvc: RouterService,
        private readonly _renderer: Renderer2,
        private readonly _gpSvc: GridPointService,
        private readonly _api: ApiService,
        private readonly _dataSvc: DataService,
        @Optional() @Attribute('multiple') multiple: string,
        @Optional() @Attribute('withGP') withGP: string,
        @Optional() @Attribute('withGPData') withGPData: string,
        @Optional() @Attribute('hasFilterGrids') hasFilterGrids: string,
        @Optional() @Attribute('hideResize') hideResize: string
    ) {
        const tagName = this._el.nativeElement.tagName.toLocaleLowerCase();
        this.hasMultiple = multiple != null ? coerceBoolean(multiple, true) : false;
        this._hasFiltersGrids = hasFilterGrids != null ? coerceBoolean(hasFilterGrids, true) : false;
        this._withGP = withGP != null ? coerceBoolean(withGP, true) : false;
        this._withGPData = withGPData != null ? coerceBoolean(withGPData, true) : false;
        this.hideResize = hideResize != null ? coerceBoolean(hideResize, true) : false;
        switch (tagName) {
            case 'grids-controller-balancing':
                this.type = GridTypeCode.codeBalancing;
                break;
            case 'grids-controller-nomination':
                this.type = GridTypeCode.codeNomination;
                break;
            case 'grids-controller-asset':
                this.type = GridTypeCode.codeProdNomination;
                break;
            case 'grids-controller-auction':
                this.type = GridTypeCodeExchange.codeAuction;
                break;
            case 'grids-controller-continuous':
                this.type = GridTypeCodeExchange.codeContinuous;
                break;
            default:
                this.type = undefined;
                break;
        }
        if (!this.withGPData) {
            this._routerSvc.setQueryParams(AppQueryParamsKey.Deals, undefined, { replaceUrl: true });
            this._routerSvc.setQueryParams(AppQueryParamsKey.Counterparts, undefined, { replaceUrl: true });
        }
    }

    ngOnInit(): void {
        this.typeDisplayTypeChange(this._appSvc.preferences?.gridDisplayType, this._appSvc.preferences?.gridDisplaySize);
        const subHidden = this._appSvc.hasDisplayGridCtlChange.subscribe((value) => {
            this._displayed = value;
            if (this._displayed) {
                this._renderer.removeClass(this._el.nativeElement, 'hide');
            } else {
                this._renderer.addClass(this._el.nativeElement, 'hide');
            }
            this._cdr.detectChanges();
        });
        this._subs.push('has-displayed', subHidden);
        const subShipper = this._appSvc.shipperChange.subscribe((shipper) => {
            if (shipper != null) {
                this._setQueryValues();
                this._prepareQueryValueSelection();
            } else {
                this.selectionChange.emit(undefined);
                this.gridsChange.emit(undefined);
                this.gridChange.emit(undefined);
            }
        });
        this._subs.push('shipper-change', subShipper);
        let gridChanges: Observable<IGrid[] | undefined>;
        switch (this.type) {
            case GridTypeCode.codeBalancing:
                gridChanges = this._appSvc.gridsBalancing;
                break;
            case GridTypeCodeExchange.codeAuction:
                gridChanges = this._appSvc.gridsAuction;
                break;
            case GridTypeCodeExchange.codeContinuous:
                gridChanges = this._appSvc.gridsContinuous;
                break;
            case GridTypeCode.codeNomination:
                gridChanges = this._appSvc.gridsNomination;
                break;
            case GridTypeCode.codeProdNomination:
                gridChanges = this._appSvc.gridsAssetNomination;
                break;
            default:
                gridChanges = this._appSvc.grids;
                break;
        }
        const subQuery = this._routerSvc.queryParamsChange.subscribe((query) => {
            if (query != null) {
                this._queryGridValue = undefined;
                this._queryGridPointValue = undefined;
                const q = query.get(PRIMARY_OUTLET);
                let hasChange: boolean = false;
                if (q[AppQueryParamsKey.Grids] != null && this._getQueryGridValue() !== q[AppQueryParamsKey.Grids]) {
                    const ids: string[] = q[AppQueryParamsKey.Grids].split(',');
                    this._queryGridValue = ids.map((id) => parseInt(id));
                    hasChange = true;
                }
                if (
                    q[AppQueryParamsKey.GridPoints] != null &&
                    this._getQueryGridPointValue() !== q[AppQueryParamsKey.GridPoints]
                ) {
                    const ids: string[] = q[AppQueryParamsKey.GridPoints].split(',');
                    this._queryGridPointValue = ids.map((id) => parseInt(id));
                    hasChange = true;
                    const queryValues = this._getQueryValues();
                    if (
                        this.withGPData &&
                        q[AppQueryParamsKey.Deals] != null &&
                        queryValues?.deals !== q[AppQueryParamsKey.Deals]
                    ) {
                        const delaIds: string[] = q[AppQueryParamsKey.Deals].split(',');
                        this._queryDealValue = {};
                        delaIds.forEach((id) => {
                            if (id != '') {
                                const data = id.split('__');
                                const gpId = parseInt(data[0]!);
                                this._queryDealValue![gpId] = data[1]!.split('_').map((a) => parseInt(a));
                            }
                        });
                        hasChange = true;
                    }
                    if (
                        this.withGPData &&
                        q[AppQueryParamsKey.Counterparts] != null &&
                        queryValues?.cps !== q[AppQueryParamsKey.Counterparts]
                    ) {
                        const cpIds: string[] = q[AppQueryParamsKey.Counterparts].split(',');
                        this._queryCpValue = {};
                        cpIds.forEach((id) => {
                            if (id != '') {
                                const data = id.split('__');
                                const gpId = parseInt(data[0]!);
                                this._queryCpValue![gpId] = data[1]!.split('_').map((a) => parseInt(a));
                            }
                        });
                        hasChange = true;
                    }
                }

                if (hasChange) {
                    this._prepareQueryValueSelection();
                }
            }
        });
        this._subs.push('query-change', subQuery);

        const subGridChange = combineLatest([gridChanges, this._appSvc.shipperChange]).subscribe(([grids]) => {
            const gridsCache =
                grids == null
                    ? grids
                    : orderBy(
                          grids,
                          (g) =>
                              (g.material.code === MaterialCode.Power || g.material.code === MaterialCode.PowerReserve
                                  ? 'a'
                                  : 'w') +
                              '_' +
                              g.name
                      );
            if (this._gridsCache != null && this.hasMultiple && gridsCache != null) {
                const newGrids = gridsCache.filter((a) => !containsSame(this._gridsCache!, a));
                const grids = newGrids.map((g) => g.id);
                if (this._appSvc.shipperPreferences) {
                    switch (this.type) {
                        case GridTypeCode.codeBalancing:
                            switch (this._appPage) {
                                case AppPages.Deals:
                                    this._appSvc.shipperPreferences.grids.balancingsDeal = [
                                        ...this._appSvc.shipperPreferences.grids.balancingsDeal,
                                        ...grids,
                                    ];
                                    break;
                                case AppPages.AuctionOrders:
                                case AppPages.AuctionSchedules:
                                    this._appSvc.shipperPreferences.grids.balancingsAuction = [
                                        ...this._appSvc.shipperPreferences.grids.balancingsAuction,
                                        ...grids,
                                    ];
                                    break;
                                case AppPages.AuctionOverview:
                                    this._appSvc.shipperPreferences.grids.auctionsOverview = [
                                        ...this._appSvc.shipperPreferences.grids.auctionsOverview,
                                        ...grids,
                                    ];
                                    break;
                                case AppPages.Session:
                                    this._appSvc.shipperPreferences.grids.balancingsContinuous = [
                                        ...this._appSvc.shipperPreferences.grids.balancingContinuous,
                                        ...grids,
                                    ];
                                    break;
                                default:
                                    this._appSvc.shipperPreferences.grids.balancings = [
                                        ...this._appSvc.shipperPreferences.grids.balancings,
                                        ...grids,
                                    ];
                            }
                            break;
                        case GridTypeCode.codeNomination:
                            switch (this._appPage) {
                                case AppPages.NomMessages:
                                case AppPages.NomRealTime:
                                    this._appSvc.shipperPreferences.grids.nominationsRealTimeMessages = [
                                        ...this._appSvc.shipperPreferences.grids.nominationRealTimeMessages,
                                        ...grids,
                                    ];
                                    break;
                                case AppPages.NomOverview:
                                    this._appSvc.shipperPreferences.grids.nominationsOverview = [
                                        ...this._appSvc.shipperPreferences.grids.nominationsOverview,
                                        ...grids,
                                    ];
                                    break;
                                case AppPages.NomBatch:
                                    this._appSvc.shipperPreferences.grids.nominationsBatch = [
                                        ...this._appSvc.shipperPreferences.grids.nominationsOverview,
                                        ...grids,
                                    ];
                                    break;
                                case AppPages.NomConfiguration:
                                    this._appSvc.shipperPreferences.grids.nominationsConf = [
                                        ...this._appSvc.shipperPreferences.grids.nominationsConf,
                                        ...grids,
                                    ];
                                    break;
                            }
                            break;
                        case GridTypeCode.codeProdNomination:
                            this._appSvc.shipperPreferences.grids.assets = [
                                ...this._appSvc.shipperPreferences.grids.assets,
                                ...grids,
                            ];
                            break;
                    }
                }
            }
            this._gridsCache = gridsCache ?? null;

            if (this._appSvc.shipperPreferences != null) {
                this._setQueryValues();
            }
            this._prepareGrids();
            this._cdr.detectChanges();
        });
        this._subs.push('grid-change', subGridChange);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    typeDisplayTypeChange(type: AppDisplayGridType | undefined, width?: string): void {
        if (!type) {
            return;
        }
        const prefixClass = 't-';
        if (this._typeDisplay !== AppDisplayGridType.Normal) {
            this._renderer.removeClass(this._el.nativeElement, prefixClass + this._typeDisplay);
        }
        this._typeDisplay = type;
        if (this._appSvc.preferences) {
            this._appSvc.preferences.gridDisplayType = this._typeDisplay;
        }
        if (this.withGP && this._typeDisplay === AppDisplayGridType.Tiles) {
            this._typeDisplay = AppDisplayGridType.Normal;
        }
        switch (this._typeDisplay) {
            case AppDisplayGridType.Mini:
                this._sizeIcon = 16;
                this._minWidth = '210px';
                break;
            case AppDisplayGridType.Normal:
                this._sizeIcon = 32;
                this._minWidth = '230px';
                break;
            case AppDisplayGridType.Tiles:
                this._sizeIcon = 64;
                this._minWidth = '178px';
                break;
        }
        if (width == null) {
            this._width = this._minWidth;
            if (this._appSvc.preferences) {
                this._appSvc.preferences.gridDisplaySize = this._width;
            }
        } else {
            this._width = width;
        }
        this._autoResize();
        if (this._typeDisplay !== AppDisplayGridType.Normal) {
            this._renderer.addClass(this._el.nativeElement, prefixClass + this._typeDisplay);
        }
    }

    toggleDisplayCountry(cv: ICountryView): void {
        cv.opened = !cv.opened;
        if (!cv.opened) {
            cv.grids.forEach((a) => {
                this.toggleDisplayGrid(a, false);
            });
        }
    }

    toggleDisplayGrid(gv: IGridView, display?: boolean, event?: MouseEvent): void {
        if (event != null) {
            event.stopPropagation();
        }
        gv.opened = display != null ? display : !gv.opened;
        if (!gv.opened) {
            gv.gridpoints?.children.forEach((a) => {
                this.toggleDisplayGridPoint(a, false);
            });
        }
    }

    toggleDisplayGridPoint(gpv: GridPointView, display?: boolean, event?: MouseEvent): void {
        if (event != null) {
            event.stopPropagation();
        }
        gpv.opened = display != null ? display : !gpv.opened;
        if (!gpv.opened) {
            gpv.children.forEach((a) => {
                this.toggleDisplayGridPoint(a, false);
            });
        }
    }

    toggleAllGridsSelection(all: boolean): void {
        if (this.hasMultiple && this._gridviews) {
            this._toggleGridSelection(this._gridviews, !all || this.hasSelection ? 'remove' : 'add');
            this._propGridsChange();
        }
    }

    toggleCountrySelection(country: ICountryView, all: boolean): void {
        if (this.hasMultiple) {
            this._toggleGridSelection(country.grids, !all || country.hasSelection == null ? 'remove' : 'add');
            this._propGridsChange();
        }
    }

    toggleGridSelected(gv: IGridView): void {
        this._toggleGridSelection([gv]);
        this._propGridsChange();
    }

    toggleGridPointSelected(gpv: GridPointView, gv: IGridView, type?: 'add' | 'remove'): void {
        this._recursiveToggleGridPoint(gpv, gv, type);
        this._refreshStateSelectedGp(gv);
        this._propGridsChange();
    }

    counterpartSelectionChange(gv: IGridView, gpv: GridPointView, counterparts: ISelectionCounterpart[]): void {
        if (this._counterpartsSelectionChange(gv, gpv, counterparts)) {
            this._propGridsChange();
        }
    }

    countryTrackBy(_index: number, country: ICountryView): number {
        return country.country.id;
    }

    gridTrackBy(_index: number, grid: IGridView): number {
        return grid.grid.id;
    }

    gridPointTrackBy(_index: number, gpv: GridPointView): number {
        return gpv.gridPoint.id;
    }

    resize(event: MouseEvent, position: 'left' | 'right'): void {
        this._cleanResizeEvent();
        const positionStart = event.x;
        const currentWidth = this._el.nativeElement.offsetWidth;
        this._onMove = true;
        this._subMouseMove = fromEvent<MouseEvent>(document, 'mousemove')
            .pipe(auditTime(10))
            .subscribe((event: MouseEvent) => {
                const positionCurrent = event.x;
                let pos: number;
                switch (position) {
                    case 'right':
                        pos = positionCurrent - positionStart;
                        break;
                    case 'left':
                        pos = positionStart - positionCurrent;
                        break;
                }
                const size = currentWidth + pos + 'px';
                this._el.nativeElement.style.width = `max(min(${this._maxWidth},${size}), ${this._minWidth})`;
                this._el.nativeElement.style.minWidth = `max(min(${this._maxWidth},${size}), ${this._minWidth})`;
            });
        this._subMouseUp = fromEvent(document, 'mouseup').subscribe(() => {
            if (this._appSvc.preferences) {
                this._appSvc.preferences.gridDisplaySize = this._el.nativeElement.style.width;
            }
            this._cleanResizeEvent();
            this._cdr.detectChanges();
        });
    }

    resizeDefault(): void {
        this._autoResize();
        // @ts-ignore
        this._appSvc.preferences.gridDisplaySize = undefined;
    }
    private _autoResize(): void {
        this._el.nativeElement.style.width = this._width;
        this._el.nativeElement.style.minWidth =
            this._width != null ? `max(min(${this._maxWidth},${this._width}), ${this._minWidth})` : this._minWidth;
    }

    private _cleanResizeEvent(): void {
        this._onMove = false;
        if (this._subMouseMove) {
            this._subMouseMove.unsubscribe();
            this._subMouseMove = undefined;
        }
        if (this._subMouseUp) {
            this._subMouseUp.unsubscribe();
            this._subMouseUp = undefined;
        }
    }

    private _setQueryValues(): void {
        if (!(this._queryGridValue && this._queryGridValue.length > 0)) {
            let queryGridValue: number[] = [];
            if (this._appSvc.shipperPreferences) {
                switch (this.type) {
                    case GridTypeCode.codeBalancing:
                        switch (this._appPage) {
                            case AppPages.Deals:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.balancingsDeal
                                    : [this._appSvc.shipperPreferences.grids.balancingDeal];
                                break;
                            default:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.balancings
                                    : [this._appSvc.shipperPreferences.grids.balancing];
                        }
                        break;
                    case GridTypeCode.codeNomination:
                        switch (this._appPage) {
                            case AppPages.NomMessages:
                            case AppPages.NomRealTime:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.nominationsRealTimeMessages
                                    : [this._appSvc.shipperPreferences.grids.nominationRealTimeMessages];
                                break;
                            case AppPages.NomOverview:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.nominationsOverview
                                    : [this._appSvc.shipperPreferences.grids.nominationOverview];
                                break;
                            case AppPages.NomBatch:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.nominationsBatch
                                    : [this._appSvc.shipperPreferences.grids.nominationBatch];
                                break;
                            case AppPages.NomConfiguration:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.nominationsConf
                                    : [this._appSvc.shipperPreferences.grids.nominationConf];
                                break;
                        }
                        break;
                    case GridTypeCode.codeMarket:
                        queryGridValue = this.hasMultiple
                            ? this._appSvc.shipperPreferences.grids.markets
                            : [this._appSvc.shipperPreferences.grids.market];
                        break;
                    case GridTypeCode.codeProdNomination:
                        queryGridValue = this.hasMultiple
                            ? this._appSvc.shipperPreferences.grids.assets
                            : [this._appSvc.shipperPreferences.grids.asset];
                        break;
                    case GridTypeCodeExchange.codeContinuous:
                        queryGridValue = this.hasMultiple
                        ? this._appSvc.shipperPreferences.grids.balancingsContinuous
                        : [this._appSvc.shipperPreferences.grids.balancingContinuous];
                    break;
                    case GridTypeCodeExchange.codeAuction:
                        switch (this._appPage) {
                            case AppPages.AuctionOrders:
                            case AppPages.AuctionSchedules:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.balancingsAuction
                                    : [this._appSvc.shipperPreferences.grids.balancingAuction];
                                break;
                            case AppPages.AuctionOverview:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.auctionsOverview
                                    : [this._appSvc.shipperPreferences.grids.auctionOverview];
                                break;
                            default:
                                queryGridValue = this.hasMultiple
                                    ? this._appSvc.shipperPreferences.grids.balancings
                                    : [this._appSvc.shipperPreferences.grids.balancing];
                                break;
                        }
                }
            }
            this._queryGridValue = queryGridValue;
            if (this.withGP) {
                this._queryGridPointValue = this._appSvc.shipperPreferences?.grids.gridpoints;
            }
        }
    }

    private _toggleGridSelection(gvs: IGridView[], type?: 'add' | 'remove'): void {
        gvs.forEach((gv) => {
            switch (type) {
                case 'add':
                    gv.selected = true;

                    break;
                case 'remove':
                    gv.selected = false;
                    break;
                default:
                    gv.selected = !gv.selected;
                    break;
            }
            if (gv.selected) {
                if (!this.hasMultiple) {
                    gv.autofocus = true;
                }
                if (!this.hasMultiple) {
                    const grids = this._selection.grids.map((a) => a.grid);
                    const gvsSel = this.grids?.filter((a) => containsSame(grids, a.grid));
                    if (gvsSel) {
                        this._toggleGridSelection(gvsSel, 'remove');
                    }
                }
                this._selection.addGrid(gv.grid);
                gv.gridpoints?.children.forEach((a) => {
                    this._recursiveToggleGridPoint(a, gv, 'add');
                });
            } else {
                gv.autofocus = false;
                gv.gridpoints?.children.forEach((a) => {
                    this._recursiveToggleGridPoint(a, gv, 'remove');
                });
                this._selection.removeGrid(gv.grid);
            }
        });
    }

    private _recursiveToggleGridPoint(gpv: GridPointView, gv: IGridView, type?: 'add' | 'remove'): void {
        if (gpv.hasCps || gpv.hasCpsInChild) {
            this._toggleGridPointSelection(gpv, gv, type);
            const childType = gpv.selected ? 'add' : 'remove';
            gpv.children?.forEach((child) => {
                this._recursiveToggleGridPoint(child, gv, childType);
            });
        }
    }

    private _toggleGridPointSelection(gpv: GridPointView, gv: IGridView, type?: 'add' | 'remove'): void {
        switch (type) {
            case 'add':
                gpv.selected = true;
                break;
            case 'remove':
                gpv.selected = false;
                break;
            default:
                gpv.selected = !gpv.selected;
                break;
        }
        let selGrid = this._selection.getGridSelection(gv.grid);
        if (gpv.selected) {
            if (selGrid == null) {
                this._toggleGridSelection([gv], 'add');
                selGrid = this._selection.getGridSelection(gv.grid);
            }
            const selGp = selGrid.addGp(gpv.gridPoint, gpv.sortId, this._gpSvc.getFlatChildNotVirtual(gpv));
            if (gpv.hasCps && gpv.gridPoint.category === GridPointCategories.VTP) {
                gpv.cps.forEach((a, i) => {
                    selGp.addCp(a, i);
                });
                this._counterpartsSelectionChange(gv, gpv, selGp.counterparts);
            }
        } else if (selGrid != null) {
            if (gpv.hasCps) {
                this._counterpartsSelectionChange(gv, gpv, []);
            }
            selGrid.removeGp(gpv.gridPoint);
        }
    }

    private _counterpartsSelectionChange(
        gv: IGridView,
        gpv: GridPointView,
        counterparts: ISelectionCounterpart[]
    ): boolean {
        if (counterparts.length > 0 && !gpv.selected) {
            this._toggleGridPointSelection(gpv, gv, 'add');
            this._refreshStateSelectedGp(gv);
        }
        const gridSelection = this._selection.getGridSelection(gv.grid);
        if (gridSelection) {
            const selGp = gridSelection.getSelectionGp(gpv.gridPoint);
            if (selGp != null) {
                selGp.counterparts = counterparts;
                gpv.cpSelection = selGp.counterparts;
                return true;
            }
        }
        return false;
    }

    private _refreshStateSelectedGp(gv: IGridView): void {
        if (gv?.gridpoints != null) {
            const recursiveFunc: (gp: GridPointView) => void = (gp: GridPointView) => {
                gp.children?.forEach((a) => {
                    recursiveFunc(a);
                });
                if (!gp.selected) {
                    const selected = gp.children.some((a) => a.selected);
                    if (selected && !isSame(gp, gv.gridpoints)) {
                        this._toggleGridPointSelection(gp, gv, 'add');
                    }
                }
            };

            gv.gridpoints?.children.forEach((a) => {
                recursiveFunc(a);
            });
        }
    }

    private async _prepareGrids(): Promise<void> {
        const key = 'prepare-grids';
        this._subs.clearSub(key);
        this._selection.clear();
        this._gridviews = null;
        this._data = null;
        if (this._gridsCache == null) {
            return;
        }

        if (this.withGP) {
            const grids: IGrid[] = [];
            for (const g of this._gridsCache) {
                if (!this._hasFiltersGrids || containsSame(this.filterGrids, g)) {
                    grids.push(g);
                }
            }
            this._gridviews = [];
            grids.forEach((g) => {
                const gps = g.gridpoints.map(
                    (a) =>
                    new GridPointView(
                        a,
                        this._dataSvc.counterparties.filter((b) => isSame(b.gridPoint, a))
                    )
                );
                const gpv = this._gpSvc.createTree(gps);
                this._gridviews?.push({ grid: g, gridpoints: gpv[0], selected: false, opened: false, autofocus: false });
            });
        } else {
            this._gridviews = [];
            for (const g of this._gridsCache) {
                if (!this._hasFiltersGrids || containsSame(this.filterGrids, g)) {
                    this._gridviews.push({
                        grid: g,
                        gridpoints: undefined,
                        selected: false,
                        opened: false,
                        autofocus: false,
                    });
                }
            }
        }

        this._processGridView();
    }

    private _processGridView(): void {
        if (this._gridviews == null || this._gridviews.length === 0) {
            this._data = [];
            this._propGridsChange();
            return;
        }
        const countries: ICountry[] = [];
        const map = new Map();
        this._gridviews.forEach((g) => {
            let country = countries.find((c) => isSame(c, g.grid.country));
            if (country == null) {
                country = g.grid.country;
                countries.push(country);
                map.set(country, []);
            }
            map.get(country).push(g);
        });
        const countryGrids: ICountryView[] = [];
        sortBy(countries, (c) => c.name).forEach((c) => {
            countryGrids.push({
                country: c,
                grids: map.get(c),
                hasSelection: this.hasMultiple,
                opened: true,
            });
        });
        this._data = countryGrids;
        if (this.hasMultiple && !this.withGP) {
            this._toggleGridSelection(this._gridviews, 'add');
        } else {
            this._toggleGridSelection(this._gridviews, 'remove');
        }
        this._prepareQueryValueSelection();
    }

    private _prepareQueryValueSelection(): void {
        // don't do anything if there are no gridviews or data
        if (!this.data || !this._gridviews) {
            return;
        }

        // reset grids values if there is no query value
        if (!this._queryGridValue) {
            this._propGridsChange();
            this._queryGridValue = undefined;
            this._queryGridPointValue = undefined;
            return;
        }

        // collect observables
        const obs: Observable<void>[] = [];

        // recursively mark gridsPoints as active
        const selectGridPoint = ( gridView: IGridView, gridPointView: GridPointView ) => {
            gridPointView.children.forEach((child) => {
                selectGridPoint(gridView, child);
            });

            const type =
                this._queryGridPointValue != null &&
                this._queryGridPointValue.some(
                    (queryGridPointValue) => queryGridPointValue === gridPointView.gridPoint.id
                )
                    ? 'add'
                    : 'remove';

            // toggle gridpoint
            this._toggleGridPointSelection(gridPointView, gridView, type);

            // check for false as undefined | null !== false
            if (!gridPointView.selected) {
                return;
            }

            gridPointView.opened = true;

            // check if counterparties are being queried
            if (!this._queryCpValue) {
                return;
            }

            const cpValues = this._queryCpValue[gridPointView.gridPoint.id];

            // check if any counterparties are being queried for this gridpoint
            if (!cpValues) {
                return;
            }

            const selectedGrids = this._selection.getGridSelection(gridView.grid);
            const selectedGridPoints = selectedGrids?.getSelectionGp(gridPointView.gridPoint);

            if (!gridPointView.hasCps || !selectedGridPoints) {
                return;
            }

            gridPointView.cps.forEach((gridPointView, index) => {
                if (cpValues.includes(gridPointView.entity.id)) selectedGridPoints.addCp(gridPointView, index);
            });

            this._counterpartsSelectionChange(gridView, gridPointView, selectedGridPoints.counterparts);

            const dealsValue = this._queryDealValue && this._queryDealValue[gridPointView.gridPoint.id];
            if (!dealsValue) {
                return;
            }

            const sub = this._api.balancings.deals
                // @ts-ignore
                .getAll(this._appSvc.contract?.id, {
                    grids: [gridView.grid],
                    gridPoints: [gridPointView.gridPoint],
                    technical: true,
                    count: 1000,
                })
                .pipe(
                    map((deals) => {
                        deals.forEach((deal) => {
                            if (dealsValue.includes(deal.id)) {
                                const counterparty = gridPointView.cps.find((counterparty) =>
                                    isSame(deal.counterpart, counterparty.entity)
                                );
                                // @ts-ignore
                                selectedGridPoints.getCp(counterparty)?.deals.push(deal);
                            }
                        });
                    })
                );
            obs.push(sub);
        };

        // mark the grids as active
        this._gridviews.forEach((gridView) => {
            const type = this._queryGridValue?.some((queryGridValue) => queryGridValue === gridView.grid.id)
                ? 'add'
                : 'remove';

            // toggle grid
            this._toggleGridSelection([gridView], type);
            if (type === 'add' && !this.hasMultiple) {
                gridView.autofocus = true;
            }

            // continue if you need gridpoints
            if (!this.withGP || !this._queryGridPointValue?.length) {
                return;
            }

            gridView.gridpoints?.children.forEach((child) => {
                selectGridPoint(gridView, child);
            });

            this._refreshStateSelectedGp(gridView);
            if (gridView.gridpoints?.selected !== false) {
                gridView.opened = true;
            }
        });

        if (!obs.length) {
            this._propGridsChange();
            this._queryGridValue = undefined;
            this._queryGridPointValue = undefined;
            return;
        }

        merge(...obs)
            .pipe(
                finalize(() => {
                    this._propGridsChange();
                    this._queryGridValue = undefined;
                    this._queryGridPointValue = undefined;
                })
            )
            .subscribe(() => {});
    }

    private _propGridsChange(): void {

        this._routerSvc.setQueryParams(AppQueryParamsKey.Grids, this._getQueryGridValue(), {
            replaceUrl: this._queryGridValue != undefined,
        });
        if (this._withGP) {
            this._routerSvc.setQueryParams(AppQueryParamsKey.GridPoints, this._getQueryGridPointValue(), {
                replaceUrl: this._queryGridPointValue != undefined,
            });
            if (this.withGPData) {
                const queryValues = this._getQueryValues();
                this._routerSvc.setQueryParams(AppQueryParamsKey.Deals, queryValues?.deals, {
                    replaceUrl: this._queryDealValue != undefined,
                });
                this._routerSvc.setQueryParams(AppQueryParamsKey.Counterparts, queryValues?.cps, {
                    replaceUrl: this._queryCpValue != undefined,
                });
            }
        }
        this._sizeSelection = this._selection.grids.length;
        this._hasSelection =
            this._sizeSelection === 0 ? false : this._sizeSelection === this._gridviews?.length;

        const gridSelection = this._selection.grids.map((a) => a.grid);
        const grids = gridSelection.map((g) => g.id);
        if (this._appSvc.shipperPreferences) {
            switch (this.type) {
                case GridTypeCode.codeBalancing:
                    switch (this._appPage) {
                        case AppPages.Deals:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.balancingsDeal = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.balancingDeal = grids[0];
                            }
                            break;
                        default:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.balancings = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.balancing = grids[0];
                            }
                            break;
                    }
                    break;
                case GridTypeCodeExchange.codeAuction:
                    switch (this._appPage) {
                        case AppPages.AuctionOrders:
                        case AppPages.AuctionSchedules:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.balancingsAuction = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.balancingAuction = grids[0];
                            }
                            break;
                        case AppPages.Session:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.balancingsContinuous = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.balancingContinuous = grids[0];
                            }
                            break;
                        case AppPages.AuctionOverview:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.auctionsOverview = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.auctionOverview = grids[0];
                            }
                            break;
                    }
                    break;
                case GridTypeCode.codeNomination:
                    switch (this._appPage) {
                        case AppPages.NomMessages:
                        case AppPages.NomRealTime:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.nominationsRealTimeMessages = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.nominationRealTimeMessages = grids[0];
                            }
                            break;
                        case AppPages.NomOverview:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.nominationsOverview = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.nominationOverview = grids[0];
                            }
                            break;
                        case AppPages.NomBatch:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.nominationsBatch = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.nominationBatch = grids[0];
                            }
                            break;
                        case AppPages.NomConfiguration:
                            if (this.hasMultiple) {
                                this._appSvc.shipperPreferences.grids.nominationsConf = grids;
                            } else {
                                // @ts-ignore
                                this._appSvc.shipperPreferences.grids.nominationConf = grids[0];
                            }
                            break;
                    }
                    break;
                case GridTypeCode.codeMarket:
                    if (this.hasMultiple) {
                        this._appSvc.shipperPreferences.grids.markets = grids;
                    } else {
                        // @ts-ignore
                        this._appSvc.shipperPreferences.grids.market = grids[0];
                    }
                    break;
                case GridTypeCode.codeProdNomination:
                    if (this.hasMultiple) {
                        this._appSvc.shipperPreferences.grids.assets = grids;
                    } else {
                        // @ts-ignore
                        this._appSvc.shipperPreferences.grids.asset = grids[0];
                    }
                    break;
            }
        }
        if (this.withGP) {
            const ids: number[] = [];
            this._selection.grids.forEach((a) => {
                ids.push(...a.gridPoints.map((gp) => gp.gp.id));
            });
            if (this._appSvc.shipperPreferences) {
                this._appSvc.shipperPreferences.grids.gridpoints = ids;
            }
        }


        if (this._sizeSelection === 0) {
            if (this._data != null) {
                this._data.forEach((d) => {
                    d.hasSelection = false;
                });
            }

            this.gridsChange.emit(undefined);
            this.gridChange.emit(undefined);
            this.selectionChange.emit(this._selection);
        } else {
            this._data?.forEach((d) => {
                const countSel = d.grids.filter((a) => a.selected).length;
                d.hasSelection = countSel === d.grids.length ? true : countSel === 0 ? false : undefined;
            });
            this.gridsChange.emit(gridSelection);
            this.gridChange.emit(gridSelection[0]);
            this.selectionChange.emit(this._selection);
        }
    }

    private _getQueryGridValue(): string | undefined {
        if (this._gridviews == null) {
            return undefined;
        }
        const ids: number[] = this._selection.grids.map((a) => a.grid.id);
        return sortBy(ids, (a) => a).join(',');
    }

    private _getQueryGridPointValue(): string | undefined {
        if (this._gridviews == null) {
            return undefined;
        }
        const ids: number[] = [];
        this._selection.grids.forEach((a) => {
            ids.push(...a.gridPoints.map((gp) => gp.gp.id));
        });
        return sortBy(ids, (a) => a).join(',');
    }

    private _getQueryValues(): { cps: string; deals: string } | undefined {
        if (this._gridviews == null) {
            return undefined;
        }
        const cpIds: string[] = [];
        const dealIds: string[] = [];
        this._selection.grids.forEach((a) => {
            a.gridPoints.forEach((gp) => {
                if (gp.counterparts != null && gp.counterparts.length > 0) {
                    cpIds.push(`${gp.gp.id}__${gp.counterparts.map((cp) => cp.counterpart.entity.id).join('_')}`);
                    gp.counterparts.forEach((cp) => {
                        if (cp.deals?.length > 0) {
                            dealIds.push(`${gp.gp.id}__${cp.deals.map((deal) => deal.id).join('_')}`);
                        }
                    });
                }
            });
        });
        return { cps: sortBy(cpIds, (a) => a).join(','), deals: sortBy(dealIds, (a) => a).join(',') };
    }

}
