<ez-loader *ngIf="onValidate"></ez-loader>
<ez-form>
    <form-schedule-base #formBase [portfolios]="portfolios" [exchangeProducts]="exchangeProducts" [shipper]="shipper"
        [schedule]="value" [deliveryArea]="deliveryArea" [(value)]="scheduleBaseValue"
        (valueChange)="formBaseValueChanged($event)">
    </form-schedule-base>

    <ez-label>{{'pages.auction-schedules.component.forms.forecasts.period-date-label' | translate }}</ez-label>
    <ez-form-field>
        <ez-datepicker class="period-date" [isRange]="true" [timezone]="timezone" [required]="true"
            [disabled]="!porfolioSelected || value"
            [placeholder]="'pages.auction-schedules.component.forms.forecasts.period' | translate"
            [ngModel]="periodDate" (valueChange)="periodDateChange($event)">
        </ez-datepicker>
    </ez-form-field>

    <ez-form-field>
        <ez-label>{{'pages.auction-schedules.component.forms.forecasts.mode-label' | translate }}</ez-label>

        <ez-radio-group [required]="true" [(ngModel)]="selectedRadio" class="radio-group" [align]="'vertical'"
            [disabled]="!porfolioSelected && !value">

            <ez-radio [label]="'pages.auction-schedules.component.forms.forecasts.sendDayAhead' | translate"
                [value]="Mode.DayBefore" [disabled]="value != null">
                <ez-form-field class="time-field">
                    <ez-timepicker [(ngModel)]="dayBefore" [displayTz]="true" [timezone]="timezone"
                        [disabled]="selectedRadio !== Mode.DayBefore">
                    </ez-timepicker>
                </ez-form-field>
            </ez-radio>

            <ez-radio [label]="'pages.auction-schedules.component.forms.forecasts.sendDay' | translate"
                [value]="Mode.Day" [disabled]="value != null">
                <ez-form-field class="time-field">
                    <ez-timepicker [(ngModel)]="intraday" [displayTz]="true" [disabled]="selectedRadio !== Mode.Day"
                        [timezone]="timezone">
                    </ez-timepicker>
                </ez-form-field>
            </ez-radio>

            <ez-radio [label]="'pages.auction-schedules.component.forms.forecasts.from' | translate"
                [value]="Mode.Period" class="schedule-radio" [disabled]="value != null">

                <span class="label-fromday" [class.disabled]="selectedRadio !== Mode.Period">
                    {{"pages.auction-schedules.component.forms.forecasts.fromDay" | translate}}
                </span>

                <ez-form-field class="input-field">
                    <ez-input-number [required]="true" [(ngModel)]="sendPeriodFrom" [min]="0"
                        (valueChange)="onSenPeriodFromChange($event)" [disabled]="selectedRadio !== Mode.Period">
                    </ez-input-number>
                </ez-form-field>

                <span class="label-today" [class.disabled]="selectedRadio !== Mode.Period">
                    {{"pages.auction-schedules.component.forms.forecasts.toDay" | translate}}
                </span>

                <ez-form-field class="input-field">
                    <ez-input-number [min]="0" [required]="true" [(ngModel)]="sendPeriodTo" [max]="sendPeriodFrom"
                        (valueChange)="onSenPeriodFromChange($event)" [disabled]="selectedRadio !== Mode.Period">
                    </ez-input-number>
                </ez-form-field>

                <span [class.disabled]="selectedRadio !== Mode.Period">
                    &#64;
                </span>

                <ez-form-field class="time-field">
                    <ez-timepicker [(ngModel)]="sendPeriodTime" [displayTz]="true" [timezone]="timezone"
                        [disabled]="selectedRadio !== Mode.Period">
                    </ez-timepicker>
                </ez-form-field>
            </ez-radio>

            <ez-radio [label]="'pages.auction-schedules.component.forms.forecasts.from' | translate" [value]="Mode.On"
                class="datePicker">
                <ez-form-field class="date-field">
                    <ez-datepicker [(ngModel)]="sendOnDate" [disabled]="selectedRadio !== Mode.On" [withTime]="true"
                        [timezone]="timezone">
                    </ez-datepicker>
                </ez-form-field>
            </ez-radio>
        </ez-radio-group>
    </ez-form-field>
</ez-form>
