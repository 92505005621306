<ez-button-group class="button-group" [singleSelection]="false" [value]="format"
    (valueChange)="onExecCommandFormat($event)" [required]="false">
    <ez-button [icon]="'bold'" [value]="formats.Bold" [disabled]="disabled"></ez-button>
    <ez-button [icon]="'italic'" [value]="formats.Italic" [disabled]="disabled"></ez-button>
</ez-button-group>

<ez-button-group class="button-group" [value]="heading" (valueChange)="onExecCommandHeading()">
    <ez-button [icon]="'heading'" [value]="true" [disabled]="disabled">
    </ez-button>
</ez-button-group>

<ez-button-group class="button-group" [value]="externalLink.type" (valueChange)="onExecCommandLink($event)">
    <ez-button [icon]="'link'" [value]="links.Link" [disabled]="disabled"></ez-button>
    <ez-button [icon]="'image'" [value]="links.ImageLink" [disabled]="disabled">
        <i class="fa fa-link img"></i>
    </ez-button>
    <ez-button [icon]="'image'" [value]="links.ImageFile" [disabled]="disabled"></ez-button>
</ez-button-group>

<ez-button-group class="button-group" [value]="listType" (valueChange)="onExecCommandList($event)" [required]="false">
    <ez-button [icon]="'list-ul'" [value]="listTypes.Ul" [disabled]="disabled"></ez-button>
    <ez-button [icon]="'list-ol'" [value]="listTypes.Ol" [disabled]="disabled"></ez-button>
</ez-button-group>

<ez-button-group class="button-group">
    <ez-button [icon]="'quote-right'" [severity]="quote ? 'primary' : ''" (onClick)="onExecCommandQuote()"
        [disabled]="disabled">
    </ez-button>
</ez-button-group>

<!-- <ez-button-group class="button-group"  [value]="viewType"
    (valueChange)="onExecCommandView($event)">
    <ez-button [icon]="'columns'" [value]="viewTypes.Splitted" [disabled]="disabled"></ez-button>
    <ez-button [icon]="'eye'" [value]="viewTypes.Preview" [disabled]="disabled"></ez-button>
</ez-button-group> -->

<div class="left-container">
    <ez-button-group class="button-group action" [value]='submitType' (valueChange)="onExecCommandSubmit($event)">
        <ez-button [icon]="'times'" [value]="submitTypes.Cancel" [disabled]="disabled" class="btn-cancel"></ez-button>
        <ez-button [icon]="'save'" [value]="submitTypes.Save" [disabled]="disabled" class="btn-save"></ez-button>
    </ez-button-group>
</div>

<ez-modal [(open)]="isLinkModalOpen" class="modal-help-toolbar" (onClose)="onCancelLink(true)">
    <ez-header>
        {{'aside.help.toolbar.modals.link.title' | translate:{type: linkType } }}
    </ez-header>
    <ez-form #linkForm (onSubmit)="onValidateLink()" (onCancel)="onCancelLink()">
        <ez-form-field>
            <ez-input-text [(ngModel)]="externalLink.label" [placeholder]="'label'">
            </ez-input-text>
        </ez-form-field>
        <ez-form-field>
            <ez-input-text [(ngModel)]="externalLink.value" [placeholder]="'url'" type='url' [required]='true'>
            </ez-input-text>
        </ez-form-field>
        <ez-footer>
            <ez-button [label]="'general.cancel' | translate" ez-form-cancel></ez-button>
            <ez-button [label]="'general.confirm' | translate" [severity]="'primary'" ez-form-submit></ez-button>
        </ez-footer>
    </ez-form>
</ez-modal>

<ez-modal [(open)]="isImageFileModalOpen" class="modal-help-toolbar" (onClose)="onCancelLink(true)">
    <ez-header>
        {{'aside.help.toolbar.modals.file.title' | translate}}
    </ez-header>
    <ez-form #imageForm (onSubmit)="onValidateLink()" (onCancel)="onCancelLink()">
        <ez-form-field>
            <ez-input-text [(ngModel)]="externalLink.label" [placeholder]="'label'">
            </ez-input-text>
        </ez-form-field>
        <ez-form-field>
            <ez-input-file [(ngModel)]="externalLink.value" [required]='true' [accept]="['.jpg','.jpeg','.png','.gif']"
                [placeholder]="'aside.help.toolbar.modals.file.placeholder' | translate">
            </ez-input-file>
        </ez-form-field>
        <ez-footer>
            <ez-button [label]="'general.cancel' | translate" ez-form-cancel></ez-button>
            <ez-button [label]="'general.confirm' | translate" [severity]="'primary'" ez-form-submit></ez-button>
        </ez-footer>
    </ez-form>
</ez-modal>
