// @ts-nocheck
import {
    Injectable,
    NgZone
} from "@angular/core";



@Injectable({ providedIn: 'root' })
export class PopupService {

    constructor(private _ngZone: NgZone) {

    }

    createPopup(url: string, name: string, options: PopupOptions): IPopup {
        let uri = window.location.origin + window.location.pathname + "#" + url;
        return new Popup(this._ngZone, uri, name, options);
    }

}

export class PopupOptions {
    location: boolean;
    width: number;
    height: number;
    menubar: boolean;
    toolbar: boolean;
    status: boolean;

    constructor(width?: number, height?: number, location: boolean = false, menubar: boolean = false, toolbar: boolean = false, status: boolean = false) {
        this.width = width;
        this.height = height;
        this.location = location;
        this.menubar = menubar;
        this.toolbar = toolbar;
        this.status = status;
    }

    toString(): string {
        let local = '';
        local += this.width ? 'width=' + this.width + ',' : '';
        local += this.height ? 'height=' + this.height + ',' : '';
        local += this.location == false ? 'location=no,' : '';
        local += this.menubar == false ? 'menubar=no,' : '';
        local += this.toolbar == false ? 'toolbar=no,' : '';
        local += this.status == false ? 'status=no,' : '';
        if (local.length > 1)
            local = local.substring(0, local.length - 1);
        return local;
    }
}

export interface IPopup {
    open(): void;
    close(): void;
    readonly closed: boolean;
}

class Popup implements IPopup {

    private _window: Window;

    readonly name: string;
    readonly url: string;
    readonly options: PopupOptions;
    get closed(): boolean {
        return !this._window || this._window.closed;
    }


    constructor(private _ngZone: NgZone, url: string, name: string, options: PopupOptions) {
        this.url = url;
        this.name = name;
        this.options = options;
    }

    open(): void {
        if (!this._window || this._window.closed) {
            this._ngZone.runOutsideAngular(() => {
                this._window = window.open(this.url, this.name, this.options.toString());
            });
        } else {
            this._window.focus();
        }
    }

    close(): void {
        if (this._window) {
            this._ngZone.runOutsideAngular(() => {
                this._window.close();
                this._window = undefined;
            });
        }
    }
}
