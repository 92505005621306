import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { EzComponentsModule } from "@eznergy/components";
import { TranslateModule } from "@ngx-translate/core";
import { AppPipesModule } from "@pipes/pipes.module";
import { AgChartsAngularModule } from "ag-charts-angular";
import { AgGridModule } from "ag-grid-angular";
import 'ag-grid-enterprise';
import { EzTreeGridComponent } from "./tree-grid/tree-grid.component";
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        EzComponentsModule,
        TranslateModule,
        AppPipesModule,
        AgGridModule,
        AgChartsAngularModule,
        SharedModule
    ],
    declarations: [
        EzTreeGridComponent
    ],
    exports: [
        EzTreeGridComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DataComponentsModule {

}
