<ez-loader *ngIf="onValidate"></ez-loader>
<ez-form>
    <form-schedule-base #formBase [portfolios]="portfolios" [exchangeProducts]="exchangeProducts" [shipper]="shipper"
        [deliveryArea]="deliveryArea" [(value)]="scheduleBaseValue" (valueChange)="formBaseValueChanged($event)">
    </form-schedule-base>

    <ez-form-field>
        <ez-radio-group [required]="true" [(ngModel)]="selectedRadio" class="radio-group"
            [disabled]="!porfolioSelected">
            <ez-radio [label]="'pages.auction-schedules.component.forms.immediate.intraday' | translate"
                [value]="dateRangeType.Intraday">
                <span class="date-selection" [class.disabled]="selectedRadio !== dateRangeType.Intraday">{{intraday |
                    ezdate:{format:{date:"medium"} } }}</span>
            </ez-radio>
            <ez-radio [label]="'pages.auction-schedules.component.forms.immediate.dayAhead' | translate"
                [value]="dateRangeType.DayAhead">
                <span class="date-selection" [class.disabled]="selectedRadio !== dateRangeType.DayAhead">{{dayAhead |
                    ezdate:{format:{date:"medium"} } }}</span>
            </ez-radio>
            <ez-radio [label]="'pages.auction-schedules.component.forms.immediate.period' | translate"
                [value]="dateRangeType.Period">
                <div class="date-selection">
                    <ez-datepicker [value]="periodDate" [isRange]="true"
                        [disabled]="selectedRadio !== dateRangeType.Period" (valueChange)="periodDateChange($event)">
                    </ez-datepicker>
                </div>
            </ez-radio>
        </ez-radio-group>
    </ez-form-field>
</ez-form>
