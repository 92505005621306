// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiService } from '@eznergy/webapi';
import { CurveBase, DecimalCurve, ICurveBase, IUnit, StringCurve } from '@eztypes/webapi';
import { ApplicationService } from "@services/application.service";
import { DataService } from "@services/data.service";
import { StatusForm } from '@enums/status-form.enum';
// Enum
import { UnitFamiliesUmScore } from '@enums/unit-families-umscore.enum';
import { EventForm } from '@models/views/events/event-form';
import { DataTypeObject } from '@models/views/generic/data-type-object';

@Component({
    selector: "ez-add-curve",
    templateUrl: "./add-curve.component.html",
    styleUrls: ["./add-curve.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddCurveComponent implements OnInit {

    private _showUnitsDefaultState = false;

    // todo switch back to string for curve types, instead of temporary DataTypeObjects.
    // Find a way for the combobox to dinamycally handle complex object arrays and type object arrays
    public readonly types: Array<DataTypeObject> = [];
    public curve: ICurveBase;
    public selectedName: string = undefined;

    selectedUnit: IUnit = undefined;
    showUnits: boolean = false;
    units: IUnit[] = undefined;
    forceCurveType: boolean = false;

    @Output() onCurveCreateResponse: EventEmitter<EventForm<ICurveBase>> = new EventEmitter<EventForm<ICurveBase>>();

    @Input() curveTypeToForce: string;
    @Input() getFocus: Boolean;

    @Input()
    get unitFamilyFilter(): UnitFamiliesUmScore {
        return this._unitFamilyFilter;
    }
    set unitFamilyFilter(item: UnitFamiliesUmScore) {
        if (item !== this._unitFamilyFilter) {
            this._unitFamilyFilter = item;
        }
        this._refreshUnits();
        this._cdr.detectChanges();
    }
    private _unitFamilyFilter: UnitFamiliesUmScore = undefined;

    @Input()
    get unitsToUse(): IUnit[] {
        return this.units;
    }
    set unitsToUse(item: IUnit[]) {
        if (item !== this.units) {
            this.units = item;
        }
        this._refreshUnits();
        this._cdr.detectChanges();
    }

    get selectedType(): DataTypeObject {
        return this._selectedType;
    }
    set selectedType(item: DataTypeObject) {
        this._selectedType = item;
        this._cdr.detectChanges();
    }
    private _selectedType: DataTypeObject = undefined;

    constructor(
        private _cdr: ChangeDetectorRef,
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private readonly _dataSvc: DataService) {
    }

    // Init event
    ngOnInit() {
        this.types.push(new DataTypeObject("DECIMAL"));
        this.types.push(new DataTypeObject("STRING"));
        this._handleForcedCurveType(this.curveTypeToForce);
        this._resetCurve();
    }

    // Let check if the curve type
    private _handleForcedCurveType(type: string): void {
        switch (type) {
            case "DECIMAL":
                this.forceCurveType = true;
                this.selectedType = this.types.find((o) => o.value === type);
                this.showUnits = true;
                this._showUnitsDefaultState = true;
                break;
            case "STRING":
                this.forceCurveType = false;
                this.selectedType = this.types.find((o) => o.value === type);
                this.showUnits = false;
                this._showUnitsDefaultState = false;
                return;
        }
        this._refreshUnits();
        this._cdr.detectChanges();
    }

    // Used to refresh the units list
    private _refreshUnits() {
        if (!this.units || this.units.length < 1) {
            this.units = this._unitFamilyFilter != null ? this._dataSvc.units?.filter((a) => a.family.score === this._unitFamilyFilter) : this._dataSvc.units;
        }
    }

    // Used to clear the parameters related to the curve
    private _resetCurve() {
        this.curve = new CurveBase();
        this.selectedName = null;
        this.selectedType = this.curveTypeToForce ? this.types.find((o) => o.value === this.curveTypeToForce) : null;
        this.selectedUnit = null;
        this.showUnits = this._showUnitsDefaultState;
        this._cdr.detectChanges();
    }

    // Form validation event
    submitForm() {
        switch (this.selectedType.value) {
            case "STRING":
                // Send response with a valid status and the created string curve (if the service responds) or send an error
                let scurve = new StringCurve();
                scurve.name = this.selectedName;
                scurve.type = this.selectedType.value;
                scurve.unit = null;
                this._api.timeseries.stringCurves.create(this._appSvc.contract.id, scurve).subscribe(
                    (curve) => this._emitResponseEvent(StatusForm.Valid, curve)
                );
                break;
            case "DECIMAL":
                // Send response with a valid status and the created decimal curve (if the service responds) or send an error
                if (!this.selectedUnit)
                    break;
                let dcurve = new DecimalCurve();
                dcurve.name = this.selectedName;
                dcurve.type = this.selectedType.value;
                dcurve.unit = this.selectedUnit;
                this._api.timeseries.decimalCurves.create(this._appSvc.contract.id, dcurve).subscribe(
                    (curve) => this._emitResponseEvent(StatusForm.Valid, curve)
                );
                break;
            default:
                // Send response with an error status
                this._emitResponseEvent(StatusForm.Error, undefined);
                break;
        }
    }

    // Form cancellation event
    onCancel() {
        // Send response with a cancel status
        this._emitResponseEvent(StatusForm.Cancel, undefined);
    }

    // Used to emit the "OnCurveAdded" event
    private _emitResponseEvent(status: StatusForm, curve: ICurveBase): void {
        if (status != undefined) {
            // Creation of the event form to send as a response
            let eventForm = new EventForm<ICurveBase>();
            eventForm.status = status;

            // Update the event form data according to the status
            switch (status) {
                case StatusForm.Cancel:
                case StatusForm.Invalid:
                case StatusForm.Error:
                case StatusForm.Unknow:
                    eventForm.data = undefined;
                    break;
                case StatusForm.Valid:
                case StatusForm.Sent:
                    eventForm.data = curve;
                    if (!curve) {
                        // If the status is good but the curve is undefined, there has been an error
                        eventForm.status = StatusForm.Error;
                    }
                    break;
            }

            // Send the event form and reset values
            this.onCurveCreateResponse.emit(eventForm);

            // Reset
            this._resetCurve();
        }
    }

    // Event on user selecting a new type of curve from the dropdown
    onSelectedTypeChanged(event: DataTypeObject): void {
        if (event && this.types.includes(event)) {
            this.showUnits = event.value == "DECIMAL";
        } else {
            this.showUnits = false;
        }
    }
}
