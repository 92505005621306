// @ts-nocheck
import { Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Optional, Output, ViewEncapsulation } from "@angular/core";
import { PRIMARY_OUTLET } from "@angular/router";
import { AppQueryParamsKey, DayAlignType } from "@core/application.model";
import { Subscriptions } from "@core/subscriptions";
import { DateRange, DateTime, IDateRange } from "@eztypes/generic";
import { ApplicationService } from "@services/application.service";
import { RouterService } from "@services/router.service";

@Component({
    selector: 'dates-controller',
    templateUrl: './dates.controller.html',
    styleUrls: ['./dates.controller.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DatesController {

    @Output() readonly datesChange: EventEmitter<IDateRange> = new EventEmitter();

    readonly dayPosTypes = DayAlignType;
    get alignDate(): DayAlignType {
        return this._alignDate;
    }
    private _alignDate: DayAlignType;

    get days(): number[] {
        return this._days;
    }
    private _days: number[] = [1, 2, 3, 4, 5, 6, 7];

    get maxDays(): number {
        return this._maxDays;
    }
    private _maxDays: number = 7;

    get selectedDates(): IDateRange {
        return this._selectedDates;
    }
    private _selectedDates: DateRange;
    get selectedDays(): number {
        return this._selectedDays;
    }
    private _selectedDays: number;
    private _date: DateTime;
    private readonly _subs: Subscriptions = new Subscriptions();

    constructor(private readonly _appSvc: ApplicationService,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _routerSvc: RouterService) {
        this._alignDate = this._appSvc.preferences.alignDate;
        this._selectedDays = this._appSvc.preferences.rangeDays;
    }

    ngOnInit(): void {
        const subDates = this._appSvc.dateChange.subscribe((date) => {
            this._date = date;
            if (this._date != null) {
                this._propSelectedChange();
                this._cdr.detectChanges();
            }
        });
        this._subs.push("dates-changes", subDates);
        const subQuery = this._routerSvc.queryParamsChange.subscribe((query) => {
            if (query != null) {
                const q = query.get(PRIMARY_OUTLET);
                const rdString = q[AppQueryParamsKey.RangeDays];
                if (rdString != null && !isNaN(rdString)) {
                    const rd = parseInt(rdString);
                    if (rd !== this.selectedDays) {
                        this.selectedDaysChange(rd);
                    }
                }
                const align = q[AppQueryParamsKey.AlignDays];
                if (align != null && align !== this.alignDate) {
                    this.changeAlignDate(align);
                }
            }
        });
        this._subs.push("query-change", subQuery);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    selectedDaysChange(days: number): void {
        this._selectedDays = days;
        this._appSvc.preferences.rangeDays = this._selectedDays;
        this._propSelectedChange();
    }

    changeAlignDate(align: DayAlignType): void {
        this._alignDate = align;
        this._appSvc.preferences.alignDate = this.alignDate;
        this._propSelectedChange();
    }

    private _propSelectedChange(): void {
        this._routerSvc.setQueryParams(AppQueryParamsKey.RangeDays, this._selectedDays);
        this._routerSvc.setQueryParams(AppQueryParamsKey.AlignDays, this.alignDate);
        this._selectedDates = this._appSvc.getDateRange(this._selectedDays, { position: this.alignDate });
        this.datesChange.emit(this._selectedDates);
    }
}
