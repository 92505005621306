// @ts-nocheck
import { Injectable } from '@angular/core';
import { IDeal, IPortfolio } from '@eztypes/webapi';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataUpdateService {

    get dealsChange(): Observable<IDeal> {
        return this._dealSubject.asObservable();
    }
    private readonly _dealSubject: Subject<IDeal> = new Subject<IDeal>();

    constructor() { }

    feedDeal(deal: IDeal): void {
        this._dealSubject.next(deal);
    }

}
