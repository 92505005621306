// @ts-nocheck
import { Injectable } from "@angular/core";
import { AuctionExchange, Exchange, IContract, ICountry, IGrid } from "@eztypes/webapi";
import { IconSize } from "../enums/icon-size.enum";
import { IconType } from "../enums/icon-type.enum";
import { AppConfig } from "../services/app-config.service";

@Injectable()
export class StaticService {

    private readonly _folderNameIconCommodity = "icon_commodity";
    private readonly _folderNameIconContract = "icon_contract";
    private readonly _folderNameIconCounrty = "icon_country";
    private readonly _folderNameIconGrid = "icon_grid";
    private readonly _folderNameIconExchange = "icon_exchange";
    private readonly _folderNameIconTime = "icon_time";

    get baseUriCdn(): string {
        if (!this._baseUriCdn) {
            if (this._config) {
                this._baseUriCdn = this._config.cdnUri;
                if (!this._baseUriCdn.endsWith("/")) {
                    this._baseUriCdn += "/";
                }
            }
        }
        return this._baseUriCdn;
    }
    private _baseUriCdn: string;

    get baseUriImage(): string {
        if (this.baseUriCdn) {
            return this.baseUriCdn + "img/";
        }
        return undefined;
    }

    get baseUriSound(): string {
        if (this.baseUriCdn) {
            return this.baseUriCdn + "sounds/";
        }
        return undefined;
    }

    get baseUriHelp(): string {
        if (this.baseUriCdn) {
            return this.baseUriCdn + "help/";
        }
        return undefined;
    }

    constructor(private _config: AppConfig) {
    }

    getUriIcon(iconType: IconType, size: IconSize, name: string) {

        switch (iconType) {
            case IconType.Grid:
                return this.gridUri(name, size);
            case IconType.Country:
                return this.countryUri(name, size);
            case IconType.Exchange:
                return this.exchangeUri(name, size);
        }

    }

    logoUri(size: IconSize = IconSize.Size_128): string {
        return this.baseUriImage + "logo/" + size.toString() + "/logo.png";
    }

    logoIUri(size: IconSize = IconSize.Size_128): string {
        return this.baseUriImage + "logo/" + size.toString() + "/logo-i.png";
    }

    contractLogoUri(contract: IContract, size: IconSize = IconSize.Size_32): string {
        const name = (contract != null ? contract?.contractGuid : "default").toLocaleLowerCase();
        return `${this.baseUriImage}${this._folderNameIconContract}/${size.toString()}/${name}.png`;
    }

    materialUri(name: string, size: IconSize = IconSize.Size_64): string {
        let n = (name && name.endsWith('.png') ? name : name + ".png").toLocaleLowerCase();
        return this.baseUriImage + this._folderNameIconCommodity + "/" + size.toString() + "/" + n;
    }

    countryUri(country: string | ICountry, size: IconSize = IconSize.Size_64): string {
        const name = (typeof (country) === "string" ? country : country?.icon).toLocaleLowerCase();
        const n = name && name.endsWith('.png') ? name : name + ".png";
        return this.baseUriImage + this._folderNameIconCounrty + "/" + size.toString() + "/" + n;
    }

    exchangeUri(name: string, size: IconSize = IconSize.Size_64): string {
        let n: string = name;
        switch (name) {
            case Exchange.EpexSpot:
            case AuctionExchange.ETS:
                n = Exchange.EpexSpot + ".png";
                break;
            case AuctionExchange.NPAU:
            case AuctionExchange.NPDA:
            case Exchange.NordPool:
                n = Exchange.NordPool + ".png";
                break;
            case Exchange.BspSouthPool:
                n = Exchange.BspSouthPool + ".png";
                break;
            default:
                n = name + ".png";

        }
        return this.baseUriImage + this._folderNameIconExchange + "/" + size.toString() + "/" + n;
    }

    gridUri(grid: string | IGrid, size: IconSize = IconSize.Size_64): string {
        const name = (typeof (grid) === "string" ? grid : grid?.gridLogo?.icon).toLocaleLowerCase();
        const n = name && name.endsWith('.png') ? name : name + ".png";
        return this.baseUriImage + this._folderNameIconGrid + "/" + size.toString() + "/" + n;
    }

    soundUri(name: string, ext: string = ".wav"): string {
        return this.baseUriSound + name.toLocaleLowerCase() + ext.toLocaleLowerCase();
    }

    timeUri(name: string, size: IconSize = IconSize.Size_27): string {
        const n = (name && name.endsWith('.png') ? name : name + ".png").toLocaleLowerCase();
        return this.baseUriImage + this._folderNameIconTime + "/" + size.toString() + "/" + n;
    }
}
