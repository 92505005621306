// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import * as _ez from "@eznergy/core";
import { IEtsArea, IEtsClient, IEtsMarketResult, IGrid } from "@eztypes/webapi";
import { EtsGlobal, EtsSummary, EtsSummaryTotal, PortfolioMarket } from '@models/api/ets/etsMarketResult.model';
import { EtsTotal } from '@models/api/ets/etsTotal.model';
import { TranslateService } from '@ngx-translate/core';
import { EtsService } from "@services/ets.service";
import { Decimal } from 'decimal.js-light';
import { saveAs } from 'file-saver';
import * as _ from "lodash";

@Component({
    selector: 'ets-market-result-summary',
    templateUrl: 'ets-market-result-summary.component.html',
    styleUrls: ['ets-market-result-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class EtsMarketResultSummaryComponent {

    @Input()
    get marketData(): IEtsMarketResult {
        return this._marketData;
    }
    set marketData(value: IEtsMarketResult) {
        if (!_.isEqual(this._marketData, value)) {
            this._marketData = value;
            if (this._marketData && this._marketData.portfolios && this._marketData.globals) {
                this._portfolios = _.orderBy(this._marketData.portfolios, ['portfolio.name'], ['asc']);
                this._globals = _.orderBy(this._marketData.globals, ['periodStart'], ['asc']);
            }
            this.prepareData();
            this._detect.detectChanges();
        }
    }
    private _marketData: IEtsMarketResult;

    private _globals: EtsGlobal[];
    private _portfolios: PortfolioMarket[];

    @Input()
    get profile(): IEtsClient {
        return this._profile;
    }
    set profile(value: IEtsClient) {
        this._profile = value;
    }
    private _profile: IEtsClient;

    @Input()
    get grid(): IGrid {
        return this._grid;
    }
    set grid(value: IGrid) {
        this._grid = value;
    }
    private _grid: IGrid;

    @Input()
    get area(): IEtsArea {
        return this._area;
    }
    set area(value: IEtsArea) {
        this._area = value;
    }
    private _area: IEtsArea;

    private _totals: EtsTotal[];
    summary: EtsSummary[];
    summaryTotal: EtsSummaryTotal[] = [];
    portfoliosNames: string[] = [];

    ngOnDestroy() {
        this._clearOlDisplay();
    }

    constructor(private _srvTrad: TranslateService, private _detect: ChangeDetectorRef, private _etsSvc: EtsService) { }

    private _clearOlDisplay() {
        this._portfolios = [];
        this._totals = [];
        this.summary = undefined;
        this.summaryTotal = [];
    }

    getCol() {
        return this._portfolios.length;
    }

    generateCsv() {
        let profileName = this._profile.shipper.shortName.replace(' ', '-');
        let areaGrid = this._grid.name.replace(' ', '-');
        let area = this._area.name.replace(' ', '-');
        let content = this.marketData.csv;
        let blob = new Blob([content], { type: "text/csv" });
        saveAs(blob, "market-results-" + profileName + "-" + areaGrid + "-" + area + "-" + this.marketData.deliveryDate.toDateLocaleString() + ".csv");
    }

    prepareData() {
        this.summary = undefined;

        if (this._marketData) {
            let summaryTotal = [];

            this._totals = this._marketData.total.total;
            for (let i = 0; i < 3; i++) {
                let item = new EtsSummaryTotal;
                item.totalPortfoliosNet = [];
                summaryTotal.push(item);
            }

            let summary = [];
            let NbOftimeStep = 0;
            let peakNbOftimeStep = 0;
            let offPeakNbOftimeStep = 0;

            let portfoliosNames = [];

            _.sortBy(this._globals, 'periodStart');
            _.sortBy(this._totals, 'periodStart');

            this._labelTranslatation(summaryTotal);
            this._buildGlobalData(summary, summaryTotal, NbOftimeStep, peakNbOftimeStep, offPeakNbOftimeStep);
            this._buildPortfoliosData(summary, summaryTotal, portfoliosNames);

            this.summaryTotal = summaryTotal;
            this.portfoliosNames = portfoliosNames;
            this.summary = summary;
        }
    }

    private _labelTranslatation(summaryTotal) {
        let totalLabel, peakLabel, offPeaklLabel, offerLabel;
        this._srvTrad.get('balancing.ets.ol.column').subscribe((labels) => {
            totalLabel = labels.etsTotal;
            peakLabel = labels.peak;
            offPeaklLabel = labels.offPeak;
            offerLabel = labels.offer;
        });

        summaryTotal[0].label = totalLabel;
        summaryTotal[1].label = peakLabel;
        summaryTotal[2].label = offPeaklLabel;
    }

    private _buildGlobalData(summary, summaryTotal, NbOftimeStep, peakNbOftimeStep, offPeakNbOftimeStep) {

        let globalVolume = new Decimal(0);
        let clientVolume = new Decimal(0);
        let globalPrice = new Decimal(_.sumBy(this._globals, 'price'));

        let peakGlobalVolume = new Decimal(0);
        let peakTotalclientVolume = new Decimal(0);
        let peakGlobalPrice = new Decimal(0);

        let offPeakGlobalVolume = new Decimal(0);
        let offPeakTotalclientVolume = new Decimal(0);
        let offPeakGlobalPrice = new Decimal(0);

        _.forEach(this._globals, (global, index) => {
            NbOftimeStep = NbOftimeStep + 1;
            let summaryItem = new EtsSummary;

            summaryItem.date = global.periodStart;
            summaryItem.globalPrice = new Decimal(global.price).toDecimalPlaces(2).toNumber().toLocaleString();
            summaryItem.globalVolume = new Decimal(global.volume).toDecimalPlaces(2).toNumber().toLocaleString();
            summaryItem.portfoliosNet = [];

            globalVolume = this._etsSvc.calcVwA(global.periodEnd, global.periodStart, global.volume, globalVolume);

            let hour = summaryItem.date.hours;

            if (this._totals[index] && this._totals[index].periodStart && _ez.isSame(this._totals[index].periodStart, summaryItem.date)) {

                summaryItem.clientVolume = new Decimal(this._totals[index].net).toDecimalPlaces(2).toNumber().toLocaleString();

                clientVolume = this._etsSvc.calcVwA(this._totals[index].periodEnd, this._totals[index].periodStart, this._totals[index].net, clientVolume);

                if (hour >= 8 && hour < 20) {
                    peakTotalclientVolume = this._etsSvc.calcVwA(this._totals[index].periodEnd, this._totals[index].periodStart, this._totals[index].net, peakTotalclientVolume);
                } else {
                    offPeakTotalclientVolume = this._etsSvc.calcVwA(this._totals[index].periodEnd, this._totals[index].periodStart, this._totals[index].net, offPeakTotalclientVolume);
                }
            }

            if (hour >= 8 && hour < 20) {
                peakNbOftimeStep = peakNbOftimeStep + 1;
                peakGlobalVolume = this._etsSvc.calcVwA(global.periodEnd, global.periodStart, global.volume, peakGlobalVolume);
                peakGlobalPrice = peakGlobalPrice.plus(global.price);
            } else {
                offPeakNbOftimeStep = offPeakNbOftimeStep + 1;
                offPeakGlobalVolume = this._etsSvc.calcVwA(global.periodEnd, global.periodStart, global.volume, offPeakGlobalVolume);
                offPeakGlobalPrice = offPeakGlobalPrice.plus(global.price);
            }

            summary.push(summaryItem);

        });

        summaryTotal[0].globalVolume = globalVolume.toDecimalPlaces(2).toNumber().toLocaleString();
        summaryTotal[0].clientVolume = clientVolume.toDecimalPlaces(2).toNumber().toLocaleString();
        summaryTotal[0].globalPrice = (globalPrice.dividedBy(this._globals.length)).toDecimalPlaces(2).toNumber().toLocaleString();

        summaryTotal[1].globalVolume = peakGlobalVolume.toDecimalPlaces(2).toNumber().toLocaleString();
        summaryTotal[1].clientVolume = peakTotalclientVolume.toDecimalPlaces(2).toNumber().toLocaleString();
        summaryTotal[1].globalPrice = (peakGlobalPrice.dividedBy(peakNbOftimeStep)).toDecimalPlaces(2).toNumber().toLocaleString();

        summaryTotal[2].globalVolume = offPeakGlobalVolume.toDecimalPlaces(2).toNumber().toLocaleString();
        summaryTotal[2].clientVolume = offPeakTotalclientVolume.toDecimalPlaces(2).toNumber().toLocaleString();
        summaryTotal[2].globalPrice = (offPeakGlobalPrice.dividedBy(offPeakNbOftimeStep)).toDecimalPlaces(2).toNumber().toLocaleString();
    }

    private _buildPortfoliosData(summary, summaryTotal, portfoliosNames) {
        _.forEach(this._portfolios, (portfolio, i) => {
            let peak = new Decimal(0);
            let offpeak = new Decimal(0);
            _.sortBy(portfolio.total, 'periodStart');

            portfoliosNames.push(portfolio.portfolio.name);

            _.forEach(portfolio.total, (total, index) => {
                if (this._globals[index] && this._globals[index].periodStart && _ez.isSame(this._globals[index].periodStart, total.periodStart)) {
                    summary[index].portfoliosNet.push(total.net);
                }
                let hour = total.periodStart.hours;
                if (hour >= 8 && hour < 20) {
                    peak = this._etsSvc.calcVwA(total.periodEnd, total.periodStart, total.net, peak);
                } else {
                    offpeak = this._etsSvc.calcVwA(total.periodEnd, total.periodStart, total.net, offpeak);
                }
            });

            let total = new Decimal(_.sumBy(portfolio.total, 'net'));
            summaryTotal[0].totalPortfoliosNet.push(total.toDecimalPlaces(2).toNumber().toLocaleString());
            summaryTotal[1].totalPortfoliosNet.push(peak.toDecimalPlaces(2).toNumber().toLocaleString());
            summaryTotal[2].totalPortfoliosNet.push(offpeak.toDecimalPlaces(2).toNumber().toLocaleString());
        });
    }

}
