// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import * as _ez from "@eznergy/core";

@Component({
    selector: "confirm-unsaved",
    templateUrl: "./confirm-unsaved.component.html",
    styleUrls: ["./confirm-unsaved.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ConfirmUnsavedComponent {

    @Output() readonly confirm: EventEmitter<boolean> = new EventEmitter();

    @Input()
    get open(): boolean {
        return this._open;
    }
    set open(value: boolean) {
        const newValue = _ez.coerceBoolean(value);
        if (this.open !== newValue) {
            this._open = value;
            this._cdr.markForCheck();
        }
    }
    private _open: boolean;

    constructor(private readonly _cdr: ChangeDetectorRef) {

    }

    onConfirmClickButton(confirm: boolean): void {
        this.confirm.emit(confirm);
    }
}
