import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";


@Component({
    selector: "error-load-data-cpt",
    templateUrl: './error-load-data.component.html',
    styleUrls: ["./error-load-data.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ErrorLoadDataCpt {

    @Output() readonly onClickRetry: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    get displayBtnRetry(): boolean {
        return this._displayBtnRetry;
    }
    set displayBtnRetry(value: boolean) {
        if (value !== this.displayBtnRetry) {
            this._displayBtnRetry = value;
            this._cdr.markForCheck();
        }
    }
    private _displayBtnRetry: boolean = true;

    constructor(private _cdr: ChangeDetectorRef) { }
}
