// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AtScheduleForm } from './schedule/schedule.component';
import { Subscriptions } from '@core';
import { ApiService } from '@eznergy/webapi';
import { DateTime } from '@eztypes/generic';
import { ExchangeAccessType, ExecutionMode, ICalendar, INextSchedule, IRunMetrics, ISchedule, ISession, RunState, Schedule, ScheduleMode, Session, StartConfiguration, UserRight } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import { Observable } from 'rxjs';

export enum SessionControllerSize {
    Normal = "normal",
    Small = "small",
    Big = "big",
}

export enum SessionControllerMode {
    Normal = "normal",
    Short = "short"
}

enum typeActionSession {
    RunStart = "start",
    RunManual = "manual",
    RunOneShot = "oneShot",
    Pause = "pause",
    Resume = "resume",
    Stop = "stop"
}


@Component({
    selector: 'at-session-controller',
    templateUrl: './at-session-controller.html',
    styleUrls: ['./at-session-controller.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AtSessionController implements OnDestroy, OnInit {

    //#region accessors
    @Input()
    get runMetrics(): IRunMetrics {
        return this._runMetrics;
    }
    set runMetrics(value: IRunMetrics) {
        this._runMetrics = value;
        this._cdr.detectChanges();
    }
    private _runMetrics: IRunMetrics;

    @Input()
    get session(): ISession {
        return this._session;
    }
    set session(value: ISession) {
        this._session = value;
        this._cdr.detectChanges();
    }
    private _session: Session;

    @Input()
    get size(): SessionControllerSize {
        return this._size;
    }
    set size(value: SessionControllerSize) {
        this._size = value;
    }
    private _size: SessionControllerSize = SessionControllerSize.Normal;
    sizes = SessionControllerSize;

    @Input()
    get mode(): SessionControllerMode {
        return this._mode;
    }
    set mode(value: SessionControllerMode) {
        this._mode = value;
    }
    private _mode: SessionControllerMode = SessionControllerMode.Normal;
    modes = SessionControllerMode;

    get calendar(): ICalendar {
        return this._session?.balancingGrid?.calendar;
    }

    get disableAction(): boolean {
        return !this.hasSessionStartRight || !this._session || !this._runMetrics || this._runMetrics.runState == RunState.Stopped;
    }

    get hasOnManual(): boolean {
        return this._runMetrics && this._runMetrics.runState == RunState.Manual;
    }

    get hasOnStart(): boolean {
        return this._runMetrics && (this._runMetrics.runState === RunState.Simulation || this._runMetrics.runState === RunState.Live);
    }

    get hasOnLive(): boolean {
        return this._runMetrics && this._runMetrics.runState === RunState.Live;
    }

    get hasPaused(): boolean {
        return this._runMetrics && this._runMetrics.runState == RunState.Paused;
    }

    get hasAutomaticPaused(): boolean {
        return this._runMetrics && this._runMetrics.runState === RunState.AutomaticPaused;
    }

    get hasStarted(): boolean {
        return this.hasOnStart || this.hasPaused || this.hasAutomaticPaused || this.hasOnManual;
    }

    get nextSchedule(): INextSchedule {
        if (!this._runMetrics)
            return undefined;
        return this._runMetrics.nextSchedule;
    }

    get hasNextSchedule(): boolean {
        if (!this.nextSchedule) return false;
        return this.nextSchedule.schedule.startDate.isAfter(DateTime.now());
    }

    get hasNotConfigure(): boolean {
        return !this._session || !this._runMetrics || this._runMetrics.runState == RunState.Stopped;
    }

    get hasReadOnlyAccess(): boolean {
        return !this._session || !this._session.exchangeUser || this._session.exchangeUser.accessType === ExchangeAccessType.Read;
    }

    openModalSchedule: boolean = false;
    schedule: ISchedule;
    typeAction: typeActionSession;

    @ViewChild(AtScheduleForm) readonly formSchedule: AtScheduleForm;

    //#endregion accessors

    private _subs: Subscriptions = new Subscriptions();

    hasSessionStartRight: boolean;

    constructor(
        private _api: ApiService,
        private _cdr: ChangeDetectorRef,
        private _appSvc: ApplicationService
    ) {

    }

    ngOnInit(): void {
        const subRights = this._appSvc.contractChange.subscribe(() => {
            if (this.hasSessionStartRight !== this._appSvc.hasRight(UserRight.SessionStart)) {
                this.hasSessionStartRight = this._appSvc.hasRight(UserRight.SessionStart);
                this._cdr.detectChanges();
            }
        });
        this._subs.push("checkRights", subRights);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    //#region user events
    onStartRun(event: MouseEvent): void {
        this._prepareAction(event, this.hasPaused ? typeActionSession.Resume : typeActionSession.RunStart);
    }

    onManualRun(event: MouseEvent): void {
        this._prepareAction(event, typeActionSession.RunManual);
    }

    onOneShotRun(event: MouseEvent): void {
        this._prepareAction(event, typeActionSession.RunOneShot);
    }

    onPause(event: MouseEvent): void {
        this._prepareAction(event, typeActionSession.Pause);
    }

    onStop(event: MouseEvent): void {
        this._prepareAction(event, typeActionSession.Stop);
    }

    hasScheduling: boolean = false;
    private _prepareAction(event: MouseEvent, typeAction: typeActionSession) {
        event.stopPropagation();
        event.preventDefault();
        this.typeAction = typeAction;
        this.openModalSchedule = true;
        this.schedule = undefined;
        this.hasScheduling = false;
        switch (typeAction) {
            case typeActionSession.RunStart:
                if (this._runMetrics && this._runMetrics.nextSchedule &&
                    (ScheduleMode.Live === this._runMetrics.nextSchedule.mode || ScheduleMode.Simu === this._runMetrics.nextSchedule.mode)) {
                    this.schedule = this._runMetrics.nextSchedule.schedule;
                    if (this._runMetrics.nextSchedule.schedule.startDate > DateTime.now()) {
                        this.hasScheduling = true;
                    }
                } else {
                    this.schedule = new Schedule(new DateTime(), new DateTime().addMinutes(15), true);
                }
                break;
        }
        this._cdr.detectChanges();
    }

    onSubmitForm(): void {
        if (this.typeAction === typeActionSession.RunStart) {
            this.formSchedule.submit();
        } else {
            this._sendSessionAction();
        }
    }

    closeScheduleForm(): void {
        this.typeAction = undefined;
        this.schedule = undefined;
        this.openModalSchedule = false;
    }

    private _sendSessionAction(): void {
        let obs: Observable<void>;
        switch (this.typeAction) {
            case typeActionSession.RunOneShot:
            case typeActionSession.RunManual:
                let executionMode: ExecutionMode;
                if (this.typeAction === typeActionSession.RunOneShot) {
                    executionMode = ExecutionMode.OneShot;
                } else if (this.typeAction === typeActionSession.RunManual) {
                    executionMode = ExecutionMode.Manual;
                }
                let startConfig = new StartConfiguration(executionMode, this.schedule);
                obs = this._api.autotrading.sessions.start(this._appSvc.contract.id, this.session.id, startConfig);
                break;
            case typeActionSession.Pause:
                obs = this._api.autotrading.sessions.pause(this._appSvc.contract.id, this.session.id);
                break;
            case typeActionSession.Resume:
                obs = this._api.autotrading.sessions.resume(this._appSvc.contract.id, this.session.id);
                break;
            case typeActionSession.Stop:
                obs = this._api.autotrading.sessions.stop(this._appSvc.contract.id, this.session.id);
                break;
        }
        if (obs) {
            let sub = obs.subscribe(
                () => {
                    this.closeScheduleForm();
                });
            this._subs.push("sessionCommand", sub);
        }
    }

    deleteSchedule(event: MouseEvent): void {
        if (this.hasSessionStartRight) {
            event.stopPropagation();
            event.preventDefault();
            this.typeAction = typeActionSession.Stop;
            this._sendSessionAction();
        }
    }
    //#endregion user events

    //#region private

    private _checkRights(): void {

    }
    //#endregion private
}
