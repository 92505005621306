<ez-table *ngIf="summary" [data]="summary" autofit="false">
    <ez-row-header>
        <th class="empty-cell border-right cell-download">
            <ez-button [icon]="'download'" (onClick)="generateCsv()"
                [ezTooltip]="'balancing.ets.ol.column.exportCsv' | translate"></ez-button>
        </th>
        <th class="border-right border-top" [attr.colspan]="3">{{'balancing.ets.ol.column.overall' | translate}}</th>
        <th class="col-separate"></th>
        <th [attr.colspan]="portfoliosNames.length" class="border-left border-right border-top">
            {{'balancing.ets.ol.column.perPortfolio'
            | translate}}</th>
    </ez-row-header>

    <ez-row-header>
        <th class="border-left last-fixed">{{'balancing.ets.ol.column.date' | translate}}</th>
        <th>{{'balancing.ets.ol.column.totalVolume' | translate}} ({{'balancing.ets.ol.column.unitMW' | translate}})
        </th>
        <th>{{'balancing.ets.ol.column.totalClientVolume' | translate}} ({{'balancing.ets.ol.column.unitMW' |
            translate}})</th>
        <th class="border-right">{{'balancing.ets.ol.column.etsPrice' | translate}}
            ({{'balancing.ets.ol.column.unitEMW' | translate}})</th>
        <th class="col-separate"></th>
        <th [ngClass]="{'border-left': !i, 'border-right': jLast}"
            *ngFor="let name of portfoliosNames; let i = index; last as jLast">{{name}}
            ({{'balancing.ets.ol.column.unitMW' | translate}})</th>
    </ez-row-header>

    <ez-column property="date" [fixed]='true'>
        <th *ezTbCell="let item" class="border-left last-fixed">{{item.date | ezdate}}</th>
    </ez-column>
    <ez-column property="globalVolume">
        <td *ezTbCell="let item"><span>{{item.globalVolume}}</span></td>
    </ez-column>
    <ez-column property="clientVolume">
        <td *ezTbCell="let item"><span>{{item.clientVolume}}</span></td>
    </ez-column>
    <ez-column property="globalPrice">
        <td *ezTbCell="let item" class="border-right"><span>{{item.globalPrice}}</span></td>
    </ez-column>

    <ez-column property="separator1">
        <td *ezTbCell="let item" class="col-separate"></td>
    </ez-column>

    <ez-column property="portfoliosNet" *ngFor="let col of summary[0].portfoliosNet; index as i; last as jLast"
        [indexCell]="i">
        <td *ezTbCell="let item" [ngClass]="{'border-left': !i, 'border-right': jLast}"><span>{{item}}</span></td>
    </ez-column>

    <ez-row-footer *ngFor="let total of summaryTotal;index as i;last as isLast">
        <th class="border-left last-fixed" [ngClass]="{'border-bottom': isLast}">
            <strong>{{total.label}}</strong>
        </th>
        <th [ngClass]="{'border-bottom': isLast}"><span>{{total.globalVolume}}
                {{'balancing.ets.ol.column.unitMWH' | translate}}</span></th>
        <th [ngClass]="{'border-bottom': isLast}"><span>{{total.clientVolume}}
                {{'balancing.ets.ol.column.unitMWH' | translate}}</span></th>
        <th class="border-right" [ngClass]="{'border-bottom': isLast}"><span>{{total.globalPrice}}
                {{'balancing.ets.ol.column.unitEMW' | translate}}</span></th>
        <th class="col-separate"></th>
        <th *ngFor="let total of summaryTotal[i].totalPortfoliosNet; index as j; last as jLast"
            [ngClass]="{'border-left': !j,'border-bottom': isLast, 'border-right': jLast}">
            {{total}} {{'balancing.ets.ol.column.unitMWH' | translate}}
        </th>
    </ez-row-footer>
</ez-table>