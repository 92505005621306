// @ts-nocheck
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Logger } from '@core/logger';
import { isSame, orderBy } from '@eznergy/core';
import { ApiService } from '@eznergy/webapi';
import { DateTime } from '@eztypes/generic';
import {
    AuctionBatchType,
    AuctionExchange,
    IAuctionExchangePortfolio,
    IAuctionExchangeProducts,
    IAuctionProduct,
    IAuctionScheduledBatch,
    IAuctionDeliveryArea,
    IOrganisation,
} from '@eztypes/webapi';
import { FormBase } from '@forms/core/form-base';
import { getAuctionExchangeParam } from '../../../../order/helper/exchange-param-id';
import { AuctionExchangeId, AuctionExchangeParam } from '../../../../order/models/parameters';
import { IScheduleBase, ScheduleBase } from '../../../models/schedule';
import { ApplicationService } from '@services/application.service';

@Component({
    selector: 'form-schedule-base',
    templateUrl: './form-schedule-base.component.html',
    styleUrls: ['./form-schedule-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FormSchedulesBaseComponent extends FormBase {
    @Output() valueChange: EventEmitter<IScheduleBase> = new EventEmitter<IScheduleBase>();

    @Input()
    get schedule(): IAuctionScheduledBatch {
        return this._schedule;
    }
    set schedule(value: IAuctionScheduledBatch) {
        if (this._schedule !== value) {
            this._schedule = value;
            this._initSchedule();
            this._cdr.markForCheck();
        }
    }
    private _schedule: IAuctionScheduledBatch;

    @Input()
    get deliveryArea(): IAuctionDeliveryArea {
        return this._deliveryArea;
    }
    set deliveryArea(value: IAuctionDeliveryArea) {
        if (this._deliveryArea !== value) {
            this._deliveryArea = value;
            this._initSchedule();
            this._cdr.markForCheck();
        }
    }
    private _deliveryArea: IAuctionDeliveryArea;

    @Input()
    get shipper(): IOrganisation {
        return this._shipper;
    }
    set shipper(value: IOrganisation) {
        if (this.shipper !== value) {
            this._shipper = value;
            this._initSchedule();
            this._cdr.markForCheck();
        }
    }
    private _shipper: IOrganisation;

    @Input()
    get value(): IScheduleBase {
        return this._value;
    }
    set value(value: IScheduleBase) {
        if (this.value != value) {
            if (value) {
                this._value = value;
                if (this._value.batchType && this._value.batchType.length > 0) {
                    if (this._value.batchType == AuctionBatchType.LinearAndBlock) {
                        this._selectedBatchTypes = [AuctionBatchType.Block, AuctionBatchType.Linear];
                    } else {
                        this._selectedBatchTypes = [this._value.batchType];
                    }
                }
                this._selectedExchange = this._value.exchange;
                this._selectedProduct = this._value.product;
                this._selectedPortfolio = this._value.portfolio;
            } else {
                this._value = new ScheduleBase();
                this._value.batchType = AuctionBatchType.Linear;
            }
            this._cdr.markForCheck();
        }
    }
    private _value: IScheduleBase = new ScheduleBase();

    @Input()
    set exchangeProducts(values: IAuctionExchangeProducts[]) {
        if (this._exchangesProducts !== values) {
            this._exchangesProducts = values;
            if (values) {
                this._exchanges = values
                    .filter((v) => v.products && v.products.length > 0)
                    .map((v) => getAuctionExchangeParam(v.exchange))
                    .filter((exchange, i, arr) => arr.findIndex((e) => e.id === exchange.id) === i);
                this._exchanges = orderBy(this._exchanges, (a) => a.name);
                this._initSchedule();
            }
            this._cdr.markForCheck();
        }
    }
    get exchangeProducts(): IAuctionExchangeProducts[] {
        return this._exchangesProducts;
    }
    private _exchangesProducts: IAuctionExchangeProducts[];

    @Input()
    set portfolios(values: IAuctionExchangePortfolio[]) {
        if (this._portfolios !== values) {
            this._portfolios = orderBy(values, (a) => a.name);
            this._cdr.markForCheck();
        }
    }
    get portfolios(): IAuctionExchangePortfolio[] {
        return this._portfolios;
    }
    private _portfolios: IAuctionExchangePortfolio[];

    get exchanges(): AuctionExchangeParam[] {
        return this._exchanges;
    }
    private _exchanges: AuctionExchangeParam[];

    set products(values: IAuctionProduct[]) {
        this._products = values;
        this._cdr.markForCheck();
    }
    get products(): IAuctionProduct[] {
        return this._products;
    }
    private _products: IAuctionProduct[];

    get selectedBatchTypes(): AuctionBatchType[] {
        return this._selectedBatchTypes;
    }
    private _selectedBatchTypes: AuctionBatchType[] = [AuctionBatchType.Linear];

    get selectedExchange(): AuctionExchangeParam {
        return this._selectedExchange;
    }
    private _selectedExchange: AuctionExchangeParam = null;

    get selectedProduct(): IAuctionProduct {
        return this._selectedProduct;
    }
    private _selectedProduct: IAuctionProduct = null;

    get selectedPortfolio(): IAuctionExchangePortfolio {
        return this._selectedPortfolio;
    }
    private _selectedPortfolio: IAuctionExchangePortfolio = null;

    get portfolioLoading(): boolean {
        return this._portfolioLoading;
    }
    private _portfolioLoading: boolean = false;

    readonly batchTypes = AuctionBatchType;

    constructor(
        private readonly _cdr: ChangeDetectorRef,
        private readonly _api: ApiService,
        private readonly _appSvc: ApplicationService,
        private readonly _logger: Logger
    ) {
        super();
    }

    formSubmit(): void {
        this.valueChange.emit(this._value);
    }

    selectedPortfolioChange(value: IAuctionExchangePortfolio): void {
        this._selectedPortfolio = value;
        this._value.portfolio = value;
        this.valueChange.emit(this._value);
    }

    selectedProductChange(value: IAuctionProduct): void {
        this._selectedProduct = value;
        this._value.product = value;

        if (value) {
            this._portfolioLoading = true;
            this._api.auctions.exchangePortfolios
                .all(this._appSvc.contract.id, this._shipper, this._deliveryArea, new DateTime(), value)
                .subscribe(
                    (values) => {
                        const exchange = this._value.exchange.id.toLowerCase();
                        this._portfolios = values.filter((v) => v.account.exchange.includes(exchange));
                        this._portfolios = orderBy(values, (a) => a.name);
                        this._portfolioLoading = false;
                        if (this.portfolios != null && this.portfolios.length === 1) {
                            if (this.schedule != null) {
                                this.selectedPortfolioChange(
                                    this.portfolios.find((a) => isSame(a, this.schedule.portfolio))
                                );
                            } else {
                                this.selectedPortfolioChange(this.portfolios[0]);
                            }
                        }
                        this._cdr.detectChanges();
                    },
                    (error: Error) => {
                        this._portfolioLoading = false;
                        this._logger.error('Request Auction Exchange Portfolios', error);
                        this._cdr.detectChanges();
                    }
                );
        }
        this.valueChange.emit(this._value);
    }

    selectedBatchTypesChange(value: AuctionBatchType[]): void {
        this._selectedBatchTypes = value;
        this._value.batchType =
            value.includes(AuctionBatchType.Block) && this._selectedBatchTypes.includes(AuctionBatchType.Linear)
                ? (this._value.batchType = AuctionBatchType.LinearAndBlock)
                : this._selectedBatchTypes[0];
        this.valueChange.emit(this._value);
    }

    selectedExchangesChange(value: AuctionExchangeParam): void {
        this._selectedExchange = value;
        this._value.exchange = value;
        this._selectedProduct = undefined;
        this._selectedPortfolio = undefined;
        let productList: IAuctionProduct[] = [];
        this.products = [];
        if (value) {
            this._exchangesProducts
                .filter((eP) => {
                    switch (eP.exchange) {
                        case AuctionExchange.ETS:
                            return value.id === AuctionExchangeId.ETS;
                        case AuctionExchange.NPAU:
                        case AuctionExchange.NPDA:
                            return value.id === AuctionExchangeId.NP;
                        case AuctionExchange.EXAA:
                            return value.id === AuctionExchangeId.EXAA;
                        default:
                            return false;
                    }
                })
                .forEach((e) => productList.push(...e.products));

            this.products = productList.filter(
                (product, index, self) => index === self.findIndex((t) => t.id === product.id)
            );
            this.products = orderBy(this.products, (a) => a.name);
            if (this.products != null && this.products.length === 1) {
                this.selectedProductChange(this.products[0]);
                return;
            }
        }
        this.valueChange.emit(this._value);
    }

    private _initSchedule(): void {
        if (this.schedule != null) {
            if (this.schedule.batchType == AuctionBatchType.LinearAndBlock) {
                this._selectedBatchTypes = [AuctionBatchType.Linear, AuctionBatchType.Block];
            } else {
                this._selectedBatchTypes = [this.schedule.batchType];
            }
            if (this._exchanges != null) {
                let exchangeView: AuctionExchangeParam;
                switch (this.schedule.portfolio.account.exchange) {
                    case AuctionExchange.ETS:
                        exchangeView = this._exchanges.find((a) => a.id === AuctionExchangeId.ETS);
                        break;
                    case AuctionExchange.NPAU:
                    case AuctionExchange.NPDA:
                        exchangeView = this._exchanges.find((a) => a.id === AuctionExchangeId.NP);
                        break;
                    case AuctionExchange.EXAA:
                        exchangeView = this._exchanges.find((a) => a.id === AuctionExchangeId.EXAA);
                        break;
                }
                if (exchangeView != null) {
                    this.selectedExchangesChange(exchangeView);
                }
            }

            if (this.schedule.portfolio != null) {
                this._selectedPortfolio = this.schedule.portfolio;
            }

            if (this.schedule.product != null) {
                this.selectedProductChange(this.schedule.product);
            }
        }
    }
}
