import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "menu-page",
    template: `<div class="options"><ng-content></ng-content></div>
               <ng-content select=".menu-options-center"></ng-content>
               <ng-content select=".menu-options-right"></ng-content>`,
    styleUrls: ["./menu-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,

})
export class MenuPage {
    constructor() { }
}
