// @ts-nocheck
import { AuctionBatchType, IAuctionExchangePortfolio, IAuctionProduct } from '@eztypes/webapi';
import { AuctionExchangeParam } from './parameters';

export interface IScheduleBase {
    exchange: AuctionExchangeParam;
    product: IAuctionProduct;
    portfolio: IAuctionExchangePortfolio;
    batchType: AuctionBatchType;
}

export class ScheduleBase implements IScheduleBase {
    exchange: AuctionExchangeParam;
    product: IAuctionProduct;
    portfolio: IAuctionExchangePortfolio;
    batchType: AuctionBatchType;
}
