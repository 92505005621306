import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IGrid } from '@eztypes/webapi';
import { BaseCardPopoverComponent } from '../base-card.component';

@Component({
    selector: 'timeserie-card-popover',
    template: '<ez-popover #popover><timeserie-card [value]="value" [light]="light"></timeserie-card></ez-popover>',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeserieCardPopover extends BaseCardPopoverComponent<IGrid> {

}
