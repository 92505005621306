import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";
import { PageAdminPreferenceInfoPreference } from "./admin-preferences.model";
import { PageAssetBatchPreference } from "./asset-batch-preferences.model";
import { PageAssetMessagesPreference } from "./asset-messages-preferences.model";
import { PageAuctionOrdersPreference } from "./auction-orders-preferences.model";
import { PageAuctionSchedulesPreference } from "./auction-schedules-preferences.model";
import { PageBalancingActivationPreference } from "./balancing-activation-preferences.model";
import { PageOverviewBalancingPreference } from "./balancing-overview-preferences.model";
import { PageCalculatedCurvePreference } from "./calculated-curve-preferences.model";
import { PageContinuousSessionsPreference } from "./continuous-sessions-preferences.model";
import { PageCounterpartiesPreference } from "./counterparties-preferences.model";
import { PageDealsPreference } from "./deals-preferences.model";
import { PageFilesPreferencePreference } from "./files-preferences.model";
import { PageMapPreference } from "./map-preferences.model";
import { PageMappingPreference } from "./mapping-preferences.model";
import { PageNominationBatchPreference } from "./nominations-batch-preferences.model";
import { PageNominationsMessagesPreference } from "./nominations-messages-preferences.model";
import { PageOverviewNominationsPreference } from "./nominations-overview-preferences.model";
import { PagePositionPreference } from "./positions-preferences.model";
import { PageRealtimePreference } from "./realtime-preferences.model";
import { PageTsEditPreference } from "./ts-edit-preferences.model";
import { PageAuctionOverviewPreference } from "./auction-overview-preferences.model";

class PagesPreferenceInfo {
    static load(preferences: string | PagesPreferenceInfo): PagesPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PagesPreferenceInfo>(preferences, PagesPreferenceInfo) as PagesPreferenceInfo;
        return preferences;
    }
    readonly version: number = -1;
    //#region Balancing
    readonly map: string = "";
    readonly overviewBalancing: string = "";
    readonly deals: string = "";
    readonly positions: string = "";
    //#endregion Balancing

    //#region Nominations
    readonly nomOverview: string = "";
    readonly nomRealtime: string = "";
    readonly nomMessages: string = "";
    readonly nomBatch: string = "";
    //#endregion Nominations

    //#region Asset
    readonly assetActivation: string = "";
    readonly assetMessages: string = "";
    readonly assetBatch: string = "";
    //#endregion Asset

    //#region TS
    readonly tsAdmin: string = "";
    readonly tsCalculated: string = "";
    readonly tsEdit: string = "";
    //#endregion TS

    //#region Trading
    readonly auctionOrders: string = "";
    readonly auctionOverview: string = "";
    readonly auctionSchedules: string = "";
    readonly continuousOverview: string = "";
    //#endregion Trading

    //#region Configuration
    readonly files: string = "";
    readonly counterparties: string = "";
    readonly mapping: string = "";
    //#endregion Configuration
}

@UserPreferenceClassDecorator
export class PagesPreference implements IPreferencePage {

    static load(preferences: string | PagesPreferenceInfo): PagesPreference {
        if (preferences == null) {
            return new PagesPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PagesPreferenceInfo.load(preferences);
                return new PagesPreference(
                    PagePositionPreference.load(v1.positions),
                    PageTsEditPreference.load(v1.tsEdit),
                    PageMapPreference.load(v1.map),
                    PageDealsPreference.load(v1.deals),
                    PageNominationsMessagesPreference.load(v1.nomMessages),
                    PageNominationBatchPreference.load(v1.nomBatch),
                    PageAssetMessagesPreference.load(v1.assetMessages),
                    PageAssetBatchPreference.load(v1.assetBatch),
                    PageCalculatedCurvePreference.load(v1.tsCalculated),
                    PageAdminPreferenceInfoPreference.load(v1.tsAdmin),
                    PageCounterpartiesPreference.load(v1.counterparties),
                    PageRealtimePreference.load(v1.nomRealtime),
                    PageOverviewNominationsPreference.load(v1.nomOverview),
                    PageOverviewBalancingPreference.load(v1.overviewBalancing),
                    PageAuctionOrdersPreference.load(v1.auctionOrders),
                    PageAuctionOverviewPreference.load(v1.auctionOverview),
                    PageAuctionSchedulesPreference.load(v1.auctionSchedules),
                    PageFilesPreferencePreference.load(v1.files),
                    PageMappingPreference.load(v1.mapping),
                    PageBalancingActivationPreference.load(v1.assetActivation),
                    PageContinuousSessionsPreference.load(v1.continuousOverview)
                );
            default:
                return new PagesPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;

    //#region Balancing
    @UPPropertySave
    readonly map: PageMapPreference;
    @UPPropertySave
    readonly overviewBalancing: PageOverviewBalancingPreference;
    @UPPropertySave
    readonly deals: PageDealsPreference;
    @UPPropertySave
    readonly positions: PagePositionPreference;
    //#endregion Balancing

    //#region Nominations
    @UPPropertySave
    readonly nomOverview: PageOverviewNominationsPreference;
    @UPPropertySave
    readonly nomRealtime: PageRealtimePreference;
    @UPPropertySave
    readonly nomMessages: PageNominationsMessagesPreference;
    @UPPropertySave
    readonly nomBatch: PageNominationBatchPreference;
    //#endregion Nominations

    //#region Asset
    @UPPropertySave
    readonly assetActivation: PageBalancingActivationPreference;
    @UPPropertySave
    readonly assetMessages: PageAssetMessagesPreference;
    @UPPropertySave
    readonly assetBatch: PageAssetBatchPreference;
    //#endregion Asset

    //#region TS
    @UPPropertySave
    readonly tsAdmin: PageAdminPreferenceInfoPreference;
    @UPPropertySave
    readonly tsCalculated: PageCalculatedCurvePreference;
    @UPPropertySave
    readonly tsEdit: PageTsEditPreference;
    //#endregion TS

    //#region Trading
    @UPPropertySave
    readonly auctionOrders: PageAuctionOrdersPreference;
    @UPPropertySave
    readonly auctionOverview: PageAuctionOverviewPreference;
    @UPPropertySave
    readonly auctionSchedules: PageAuctionSchedulesPreference;
    @UPPropertySave
    readonly continuousOverview: PageContinuousSessionsPreference;
    //#endregion Trading

    //#region Configuration
    @UPPropertySave
    readonly files: PageFilesPreferencePreference;
    @UPPropertySave
    readonly counterparties: PageCounterpartiesPreference;
    @UPPropertySave
    readonly mapping: PageMappingPreference;
    //#endregion Configuration

    constructor(
        positions: PagePositionPreference = new PagePositionPreference(),
        tsEdit: PageTsEditPreference = new PageTsEditPreference(),
        map: PageMapPreference = new PageMapPreference(),
        deals: PageDealsPreference = new PageDealsPreference(),
        nomMessages: PageNominationsMessagesPreference = new PageNominationsMessagesPreference(),
        nominationBatch: PageNominationBatchPreference = new PageNominationBatchPreference(),
        assetMessages: PageAssetMessagesPreference = new PageAssetMessagesPreference(),
        assetBatch: PageAssetBatchPreference = new PageAssetBatchPreference(),
        calculatedCurve: PageCalculatedCurvePreference = new PageCalculatedCurvePreference(),
        admin: PageAdminPreferenceInfoPreference = new PageAdminPreferenceInfoPreference(),
        counterparties: PageCounterpartiesPreference = new PageCounterpartiesPreference(),
        realtime: PageRealtimePreference = new PageRealtimePreference(),
        overviewNominations: PageOverviewNominationsPreference = new PageOverviewNominationsPreference(),
        overviewBalancing: PageOverviewBalancingPreference = new PageOverviewBalancingPreference(),
        orders: PageAuctionOrdersPreference = new PageAuctionOrdersPreference(),
        auctionOverview: PageAuctionOverviewPreference = new PageAuctionOverviewPreference(),
        schedules: PageAuctionSchedulesPreference = new PageAuctionSchedulesPreference(),
        files: PageFilesPreferencePreference = new PageFilesPreferencePreference(),
        mapping: PageMappingPreference = new PageMappingPreference(),
        activation: PageBalancingActivationPreference = new PageBalancingActivationPreference(),
        sessions: PageContinuousSessionsPreference = new PageContinuousSessionsPreference()
    ) {
        this.positions = positions;
        this.tsEdit = tsEdit;
        this.map = map;
        this.deals = deals;
        this.nomMessages = nomMessages;
        this.nomBatch = nominationBatch;
        this.assetMessages = assetMessages;
        this.assetBatch = assetBatch;
        this.tsCalculated = calculatedCurve;
        this.tsAdmin = admin;
        this.counterparties = counterparties;
        this.nomRealtime = realtime;
        this.nomOverview = overviewNominations;
        this.overviewBalancing = overviewBalancing;
        this.auctionOrders = orders;
        this.auctionOverview = auctionOverview;
        this.auctionSchedules = schedules;
        this.files = files;
        this.mapping = mapping;
        this.assetActivation = activation;
        this.continuousOverview = sessions;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        //#region Balancing
        this.map.reset(options);
        this.overviewBalancing.reset(options);
        this.deals.reset(options);
        this.positions.reset(options);
        //#endregion Balancing

        //#region Nominations
        this.nomOverview.reset(options);
        this.nomRealtime.reset(options);
        this.nomMessages.reset(options);
        this.nomBatch.reset(options);
        //#endregion Nominations

        //#region Asset
        this.assetActivation.reset(options);
        this.assetMessages.reset(options);
        this.assetBatch.reset(options);
        //#endregion Asset

        //#region TS
        this.tsAdmin.reset(options);
        this.tsCalculated.reset(options);
        this.tsEdit.reset(options);
        //#endregion TS

        //#region Trading
        this.auctionOrders.reset(options);
        this.auctionOverview.reset(options);
        this.auctionSchedules.reset(options);
        this.continuousOverview.reset(options);
        //#endregion Trading

        //#region Configuration
        this.counterparties.reset(options);
        this.files.reset(options);
        this.mapping.reset(options);
        //#endregion Configuration

    }
}
