export const FieldTypes = {
    Country: 'Country',
    Commodity: 'Commodity',
    Grid: 'Grid',
    GridPointCategory: 'GridPointCategory',
    GridPoint: 'GridPoint',
    Counterparty: 'Counterparty',
    Portfolio: 'Portfolio',
    Profil: 'Profil',
    Direction: 'Direction',
    Shipper: "Shipper"
} as const;
export type FieldType = typeof FieldTypes[keyof typeof FieldTypes];

export function isFieldType(value: PropertyKey): value is FieldType {
    return value in FieldTypes;
}