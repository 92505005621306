// @ts-nocheck
import { Injectable } from '@angular/core';
import { ApiService } from '@eznergy/webapi';
import { ApplicationLog, IContract, LogDevice, LogNavigator, LogNetwork, LogUri, LogUser } from '@eztypes/webapi';
import { AppConfig, ILoggingConfig } from '@services/app-config.service';
import { Subject, Subscription } from 'rxjs';
import { UAParser } from "ua-parser-js";
import { DateTime } from '@eztypes/generic';
import { environment } from '../../environments/environment';
import { CacheService, KeyCache } from '@services/cache.service';
import { UserVersionStore } from 'src/app/modules/redirection/models/user-version-store';

export enum LogLevel {
    Trace = 0,
    Debug = 1,
    Info = 2,
    Warn = 3,
    Error = 4,
    Fatal = 5
}

export interface ILogData {
    readonly message: string;
    readonly properties?: any[];
    readonly data?: any;
}

export type MessageLog = string | ILogData | (() => string | ILogData);

export interface ILog {
    readonly level: LogLevel;
    readonly message: string;
    readonly data: any;
    readonly error: Error;
    readonly date: DateTime;
}

export class Log implements ILog {
    readonly level: LogLevel;
    readonly message: string;
    readonly data: any;
    readonly error: Error;
    readonly date: DateTime;

    constructor(level: LogLevel, message: MessageLog, error?: Error) {
        this.date = DateTime.now();
        this.level = level;
        if (message) {
            let lMessage: string | ILogData;
            if (typeof (message) === "function") {
                lMessage = message() as string | ILogData;
            } else {
                lMessage = message as string | ILogData;
            }

            if (typeof (lMessage) === "string") {
                this.message = lMessage;
            } else {
                this.message = (lMessage as ILogData).message;
                const data: any = (lMessage as ILogData).data;
                if (data) {
                    this.data = data;
                }
            }
        }
        this.error = error;
        if (!this.message && this.error && this.error.message) {
            this.message = this.error.message;
        }
    }
}

class ConsoleMessage {
    message: string;
    parameters: any[];
}

@Injectable({ providedIn: 'root' })
export class Logger {

    private _logLevel: LogLevel = LogLevel.Fatal;
    private _logInConsole: boolean = true;
    private _logInAlert: boolean = false;
    private _logInApi: boolean = false;
    private _sub: Subject<ILog>;
    private _contract: IContract;
    private _user: LogUser;
    private _device: LogDevice;
    private _navigator: LogNavigator;
    private _apiSvc: ApiService;
    private _obs: Subscription;

    constructor(
        private _appConfig: AppConfig,
        private _cachingService: CacheService
    ) {
        this._initInformation();
        this._sub = new Subject<ILog>();
        this._setAsyncLog();
    }

    destroy(): void {
        if (this._obs) {
            this._obs.unsubscribe();
            this._sub.complete();
        }
    }

    configure(config: ILoggingConfig): void {
        this._parseConfigLevel(config.level);
        this._logInConsole = config.console;
        this._logInAlert = config.alert;
        this._logInApi = config.api;
    }

    configureApi(api: ApiService): void {
        this._apiSvc = api;
        this._sub.next({ date: DateTime.now(), level: LogLevel.Debug, message: "Logger Api Initialized", data: undefined, error: undefined });
    }

    setUserInformation(username: string, contract?: IContract): void {
        this._user = new LogUser();
        this._user.username = username;
        if (contract) {
            this._user.contract = contract.longName || contract.shortName;
            this._contract = contract;
        }
    }

    isTraceEnabled(): boolean {
        return this._logLevel <= LogLevel.Trace;
    }

    isDebugEnabled(): boolean {
        return this._logLevel <= LogLevel.Debug;
    }

    isInfoEnabled(): boolean {
        return this._logLevel <= LogLevel.Info;
    }

    isWarnEnabled(): boolean {
        return this._logLevel <= LogLevel.Warn;
    }

    isErrorEnabled(): boolean {
        return this._logLevel <= LogLevel.Error;
    }

    isFatalEnabled(): boolean {
        return this._logLevel <= LogLevel.Fatal;
    }

    getLogLevel(): LogLevel {
        return this._logLevel;
    }

    trace(message: MessageLog, error?: Error): void {
        if (this._logLevel <= LogLevel.Trace) {
            const log = this._createLog(LogLevel.Trace, message, error);
            this.log(log);
        }
    }

    debug(message: MessageLog, error?: Error): void {
        if (this._logLevel <= LogLevel.Debug) {
            const log = this._createLog(LogLevel.Debug, message, error);
            this.log(log);
        }
    }

    info(message: MessageLog, error?: Error): void {
        if (this._logLevel <= LogLevel.Info) {
            const log = this._createLog(LogLevel.Info, message, error);
            this.log(log);
        }
    }

    warn(message: MessageLog, error?: Error): void {
        if (this._logLevel <= LogLevel.Warn) {
            const log = this._createLog(LogLevel.Warn, message, error);
            this.log(log);
        }
    }

    error(message: MessageLog, error?: Error): void {
        if (this._logLevel <= LogLevel.Error) {
            const log = this._createLog(LogLevel.Error, message, error);
            this.log(log);
        }
    }

    fatal(message: MessageLog, error?: Error): void {
        if (this._logLevel <= LogLevel.Fatal) {
            const log = this._createLog(LogLevel.Fatal, message, error);
            this.log(log);
        }
    }

    log(log: ILog): void {
        if (log.level >= this._logLevel) {
            this._sub.next(log);
        }
    }

    private _setAsyncLog(): void {
        if (!this._obs || this._obs.closed) {
            this._obs = this._sub.subscribe((log: ILog) => {
                this._writeLog(log);
            }, (error: Error) => {
                this._writeLog({ error: error, message: "Logger internal error", level: LogLevel.Fatal, date: DateTime.now(), data: undefined });
                this._setAsyncLog();
            });
        }
    }

    private _initInformation(): void {
        if (navigator) {
            let uA = new UAParser(navigator.userAgent);
            let device = new LogDevice();
            device.cpu = navigator.hardwareConcurrency;
            device.memory = (navigator as any).deviceMemory;
            device.touch = navigator.maxTouchPoints > 0;
            let os = uA.getOS();
            if (os) {
                device.name = os.name;
                device.version = os.version;
            }
            this._device = device;

            let browser = new LogNavigator();
            let nav = uA.getBrowser();
            if (nav) {
                browser.name = nav.name;
                browser.version = nav.version;
                browser.versionMajor = nav.major;
            }
            let engine = uA.getEngine();
            if (engine) {
                browser.engine = engine.name;
                browser.engineVersion = engine.version;
            }
            browser.language = navigator.language;

            this._navigator = browser;
        }
    }

    private _parseConfigLevel(level: string = "fatal"): void {
        switch (level) {
            case "trace":
                this._logLevel = LogLevel.Trace;
                break;
            case "debug":
                this._logLevel = LogLevel.Debug;
                break;
            case "info":
                this._logLevel = LogLevel.Info;
                break;
            case "warn":
                this._logLevel = LogLevel.Warn;
                break;
            case "error":
                this._logLevel = LogLevel.Error;
                break;
            default:
                this._logLevel = LogLevel.Fatal;
                break;
        }
    }

    private _parseLevelText(level: LogLevel): string {
        switch (level) {
            case LogLevel.Trace:
                return "trace";
            case LogLevel.Debug:
                return "debug";
            case LogLevel.Info:
                return "info";
            case LogLevel.Warn:
                return "warn";
            case LogLevel.Error:
                return "error";
            case LogLevel.Fatal:
                return "fatal";
        }
    }

    private _createLog(level: LogLevel, message: MessageLog, error?: Error): ILog {
        return new Log(level, message, error);
    }

    private _writeLog(log: ILog): void {
        this._logConsole(log);
        this._logAlert(log);
        this._logApi(log);
    }

    private _formatConsoleLogMessage(log: ILog): ConsoleMessage {
        let msg: ConsoleMessage = new ConsoleMessage();
        msg.message = LogLevel[log.level].toUpperCase() + " - ";

        if (log.message) {
            msg.message += log.message + " ";
        }
        if (log.data) {
            msg.parameters = [log.data];
        }
        if (log.error) {
            if (!msg.parameters) {
                msg.parameters = [log.error];
            } else {
                msg.parameters.push(log.error);
            }
        }
        msg.message += " (" + log.date.toString() + ") ";
        return msg;
    }

    private _logAlert(log: ILog): void {
        if (this._logInAlert && log.level >= LogLevel.Warn) {
            if (alert == undefined) {
                throw new Error("Alert is not defined, cannot log msg: " + log.message);
            }
            alert("[" + LogLevel[log.level].toUpperCase() + "] " + log.message);
        }
    }

    private _logConsole(log: ILog): void {
        if (this._logInConsole) {
            /* tslint:disable:no-console */
            if (console == undefined) {
                throw new Error("Console is not defined, cannot log msg: " + log.message);
            }
            const mess = this._formatConsoleLogMessage(log);
            let logged = false;
            switch (log.level) {
                case LogLevel.Trace:
                    if (console.trace) {
                        mess.parameters ? console.trace(mess.message, ...mess.parameters) : console.trace(mess.message);
                        logged = true;
                    }
                    break;
                case LogLevel.Debug:
                    if (console.debug) {
                        mess.parameters ? console.debug(mess.message, ...mess.parameters) : console.debug(mess.message);
                        logged = true;
                    }
                    break;
                case LogLevel.Info:
                    if (console.info) {
                        mess.parameters ? console.info(mess.message, ...mess.parameters) : console.info(mess.message);
                        logged = true;
                    }
                    break;
                case LogLevel.Warn:
                    if (console.warn) {
                        mess.parameters ? console.warn(mess.message, ...mess.parameters) : console.warn(mess.message);
                        logged = true;
                    }
                    break;
                case LogLevel.Error:
                case LogLevel.Fatal:
                    if (console.error) {
                        mess.parameters ? console.error(mess.message, ...mess.parameters) : console.error(mess.message);
                        logged = true;
                    }
                    break;
                default:
                    throw new Error("Log level not supported: " + log.level);
            }
            if (!logged) {
                mess.parameters ? console.log(mess.message, ...mess.parameters) : console.log(mess.message);
            }
            /* tslint:enable:no-console */
        }
    }

    private _getLogNetwork(): LogNetwork {
        let network = new LogNetwork();
        if (navigator) {
            let connection = (navigator as any).connection;
            if (connection) {
                network.downlink = connection.downlink;
                network.effectiveType = connection.effectiveType;
                network.type = connection.type;
            }
        }
        return network;
    }

    private _getLogUri(): LogUri {
        let uri = new LogUri();
        if (document && document.location) {
            uri.full = document.location.href;
            uri.protocol = document.location.protocol;
            let page = document.location.hash;
            if (page) {
                page = page.startsWith("#") ? page.substring(1) : page;
                if (this._contract) {
                    let indexStart = page.indexOf(this._contract.contractGuid);
                    if (indexStart > -1) {
                        let indexEnd = indexStart + this._contract.contractGuid.length + 1;
                        page = page.substring(0, indexStart) + page.substring(indexEnd);
                    }
                }
                let indexQueryString = page.indexOf("?");
                if (indexQueryString > -1) {
                    page = page.substring(0, indexQueryString);
                }
                if (page.startsWith("/")) {
                    page = page.substring(1);
                }
                if (page.endsWith('/')) {
                    page = page.substring(0, page.length - 1);
                }
                uri.page = page;
            }
        }
        return uri;
    }

    private _logApi(log: ILog): void {
        if (this._logInApi) {
            const { versionInfo } = this._appConfig;
            const versionStore = this._cachingService.get<UserVersionStore | undefined>(KeyCache.UserVersion, UserVersionStore);
            const appVersionInfo = versionStore && versionStore[this._user?.username] ? versionStore[this._user?.username] : versionInfo.defaultVersion;
        
            let logApi = new ApplicationLog();
            logApi.appVersion = environment.version;
            logApi.device = this._device;
            logApi.navigator = this._navigator;
            logApi.network = this._getLogNetwork();
            logApi.user = this._user;
            logApi.url = this._getLogUri();
            logApi.versionInfo = appVersionInfo;

            logApi.date = log.date.toString();
            logApi.level = this._parseLevelText(log.level);
            logApi.message = log.message;
            if (log.error instanceof Error) {
                logApi.error = log.error;
            } else if (log.error) {
                logApi.error = new Error((log.error as any).message || JSON.stringify(log.error));
                if ((log.error as any).name) {
                    logApi.error.name = (log.error as any).name;
                }
                if ((log.error as any).stack) {
                    logApi.error.stack = (log.error as any).stack;
                }
            } else {
                logApi.error = null;
            }
            logApi.data = log.data ? JSON.stringify(log.data) : null;
            if (logApi.error != null) {
                const error = new Error(logApi.error.message);
                error.stack = logApi.error.stack;
                logApi.error = error;
            }
            if (this._apiSvc) {
                this._apiSvc.application.pushLog(logApi).subscribe();
            }
        }
    }

}
