// @ts-nocheck
export interface IVersionContainer {
    readonly version: number;
}

export class VersionContainer {
    readonly version: number;
}

export interface OptionsResetPreferencePage {
    all?: boolean;
    byDate?: boolean;
    byGrid?: boolean;
}
export interface IPreferencePage extends IVersionContainer {
    reset(options: OptionsResetPreferencePage): void;
}
