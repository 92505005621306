// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Subscriptions } from "@core/subscriptions";
import { isSame, orderBy } from "@eznergy/core";
import { ApiService } from "@eznergy/webapi";
import { ICounterpart, IDeal, IGridPoint } from "@eztypes/webapi";
import { ApplicationService } from "@services/application.service";
import { ISelectionCounterpart, SelectionCounterpart } from '../model';

export enum Tabs {
    Counterparts = "cp",
    Deal = "deal"
}

export class DealView {
    readonly id: number;
    readonly data: IDeal;
    selected: boolean = false;

    constructor(deal: IDeal, isSelected: boolean = false) {
        this.id = deal?.id;
        this.data = deal;
        this.selected = isSelected;
    }
}

export class CounterpartView {
    readonly id: number;
    readonly data: ICounterpart;
    deals: DealView[];
    selected: boolean = false;
    opened: boolean;
    hasDealSelected: boolean = false;

    constructor(counterpart: ICounterpart, isSelected: boolean = false) {
        this.id = counterpart?.entity?.id;
        this.data = counterpart;
        this.selected = isSelected;
    }
}

@Component({
    selector: 'gp-data-selector',
    templateUrl: './gp-data-selector.component.html',
    styleUrls: ['./gp-data-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GpDataSelectorComponent {

    @Output() readonly selectionChange: EventEmitter<ISelectionCounterpart[]> = new EventEmitter();

    readonly tabs = Tabs;

    currentTab: Tabs = Tabs.Counterparts;

    @Input()
    get selection(): ISelectionCounterpart[] {
        return this._selectionCounterparts;
    }
    set selection(value: ISelectionCounterpart[]) {
        if (value !== this.selection) {
            this._selectionCounterparts = value ?? [];
            this._initBySelection();
            this._cdr.markForCheck();
        }
    }
    private _selectionCounterparts: ISelectionCounterpart[] = [];

    @Input()
    get gridpoint(): IGridPoint {
        return this._gridpoint;
    }
    set gridpoint(value: IGridPoint) {
        if (value !== this.gridpoint) {
            this._gridpoint = value;
            this._cdr.markForCheck();
        }
    }
    private _gridpoint: IGridPoint;

    @Input()
    get counterparts(): ICounterpart[] {
        return this._counterparts;
    }
    set counterparts(value: ICounterpart[]) {
        if (this.counterparts !== value) {
            this._counterparts = value;
            this._data = orderBy(this.counterparts, (a) => a.entity.shortName)?.map((a) => new CounterpartView(a));
            this._cdr.markForCheck();
        }
    }
    private _counterparts: ICounterpart[];

    get data(): CounterpartView[] {
        return this._data;
    }
    private _data: CounterpartView[];

    get hasCounterpartSelected(): boolean {
        return this._hasCounterpartsSelected;
    }
    private _hasCounterpartsSelected: boolean = false;

    get dataLoaded(): boolean {
        return this._dataLoaded;
    }
    private _dataLoaded: boolean = false;


    private readonly _subs: Subscriptions = new Subscriptions();

    constructor(private readonly _cdr: ChangeDetectorRef,
        private readonly _appSvc: ApplicationService,
        private readonly _api: ApiService) {

    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    dataTackBy(_index: number, dealv: DealView | CounterpartView): number {
        return dealv.id;
    }

    loadData(): void {
        const sub = this._api.balancings.deals.getAll(this._appSvc.contract.id, {
            count: 1000,
            entities: [this._appSvc.shipper],
            technical: true,
            gridPoints: [this.gridpoint]
        }).subscribe((deals) => {
            this.data.forEach((a) => {
                const cpDeals = deals.filter((d) => isSame(a.data.entity, d.counterpart));
                a.deals = cpDeals.map((d) => new DealView(d));
            });
            this._initBySelection();
            this._dataLoaded = true;
            this._cdr.detectChanges();
        }, () => {
            this._dataLoaded = true;
            this._cdr.detectChanges();
        });
        this._subs.push("load-data", sub);
    }

    toggleAllCpSelect(selecting: boolean): void {
        this.data.forEach((cp) => {
            this._toggleCounterpartSelect(cp, selecting);
        });
        this._selectionChange();
    }

    toggleCpSelect(cp: CounterpartView): void {
        this._toggleCounterpartSelect(cp);
        this._selectionChange();
    }

    toggleAllDealSelect(cpv: CounterpartView, selecting: boolean): void {
        cpv.deals.forEach((deal) => {
            this._toggleDealSelect(cpv, deal, selecting);
        });
        this._selectionChange();
    }

    toggleDealSelect(cpv: CounterpartView, deal: DealView): void {
        this._toggleDealSelect(cpv, deal);
        this._selectionChange();
    }

    toggleDisplayDeals(event: MouseEvent, cp: CounterpartView): void {
        event.stopPropagation();
        if (cp.deals?.length > 0) {
            cp.opened = !cp.opened;
        }
    }

    detectChanges(): void {
        this._cdr.detectChanges();
    }

    private _initBySelection(): void {
        this.data?.forEach((d) => {
            const sel = this._selectionCounterparts?.find((s) => isSame(s.counterpart, d.data));
            d.selected = sel != null;
            if (d.selected) {
                d.deals?.forEach((dd) => {
                    dd.selected = sel.deals?.some((s) => isSame(s, dd.data));
                });
            }
        });
    }

    private _toggleCounterpartSelect(cp: CounterpartView, selecting?: boolean): void {
        const oldValue = cp.selected;
        cp.selected = selecting != null ? selecting : !cp.selected;
        if (cp.selected && !oldValue) {
            const sortId = this.data.findIndex((a) => isSame(a.data, cp.data));
            this._selectionCounterparts.push(new SelectionCounterpart(sortId, cp.data));
        } else if (!cp.selected && oldValue) {
            const index = this._selectionCounterparts.findIndex((a) => isSame(a.counterpart, cp.data));
            this._selectionCounterparts.splice(index, 1);
            cp.deals?.forEach((d) => {
                d.selected = false;
            });
            cp.hasDealSelected = false;
        }
        this._hasCounterpartsSelected = this._selectionCounterparts?.length === this.counterparts?.length ? true : (this._selectionCounterparts?.length === 0 ? false : undefined);
    }

    private _toggleDealSelect(cp: CounterpartView, deal: DealView, selecting?: boolean): void {
        const oldValue = deal.selected;
        deal.selected = selecting != null ? selecting : !deal.selected;

        if (deal.selected) {
            this._toggleCounterpartSelect(cp, true);
            if (!oldValue) {
                const selCp = this._selectionCounterparts.find((a) => isSame(a.counterpart, cp.data));
                selCp?.deals.push(deal.data);
                cp.hasDealSelected = cp.deals?.length === selCp.deals?.length ? true : (selCp.deals?.length === 0 ? false : undefined);
            }
        } else if (!deal.selected && oldValue) {
            const selCp = this._selectionCounterparts.find((a) => isSame(a.counterpart, cp.data));
            if (selCp != null) {
                cp.hasDealSelected = cp.deals?.length === selCp.deals?.length ? true : (selCp.deals?.length === 0 ? false : undefined);
                const index = selCp.deals.findIndex((a) => isSame(a, deal.data));
                selCp.deals.splice(index, 1);
            }
        }
    }

    private _selectionChange(): void {
        this.selectionChange.emit(this._selectionCounterparts);
    }

}
