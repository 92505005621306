import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IVersionContainer, VersionContainer } from "./core.model";
import { AppShipperPreferences } from "./shipper-preferences.model";
import { AppPreferences } from "./app-preferences.model";
import { ContractPagesPreference } from "./pages/contract-page-preferences.model";

export class AppContractPreferencesInfo {
    static load(preferences: string | AppContractPreferencesInfo): AppContractPreferencesInfo {
        if (typeof preferences === "string")
            return deserialize<AppContractPreferencesInfo>(preferences, AppContractPreferencesInfo) as AppContractPreferencesInfo;
        return preferences;

    }

    static loadV2(preferences: string | AppContractPreferencesInfo): AppContractPreferences {
        let v2: AppContractPreferencesInfo;
        if (typeof preferences === "string")
            v2 = deserialize<AppContractPreferencesInfo>(preferences, AppContractPreferencesInfo) as AppContractPreferencesInfo;
        else
            v2 = preferences;
        const shippers: AppShipperPreferences[] = v2.shippers.map((c) => AppShipperPreferences.load(c));
        return new AppContractPreferences(v2.contractGuid, shippers, v2.shipperId, ContractPagesPreference.load(v2.pages));
    }

    readonly version: number = 1;
    readonly contractGuid: string | undefined;
    shippers: string[] = [];
    shipperId: number | undefined;
    readonly pages: string | undefined;
}

@UserPreferenceClassDecorator
export class AppContractPreferences implements IVersionContainer {
    static load(preferences: string | AppContractPreferencesInfo): AppContractPreferences {


        let shippers: AppShipperPreferences[];
        let version: number;
        if (typeof preferences === 'string') {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else {
            version = preferences.version;
        }
        switch (version) {
            case 1:
                const v1 = AppContractPreferencesInfo.load(preferences);
                shippers = v1.shippers.map((c) => AppShipperPreferences.load(c));
                let pages = new ContractPagesPreference();
                if (AppPreferences.currentContract == v1.contractGuid)
                    pages = new ContractPagesPreference(AppPreferences.contractPagesPreferences);
                return new AppContractPreferences(v1.contractGuid, shippers, v1.shipperId, pages);
            case 2:
                return AppContractPreferencesInfo.loadV2(preferences);
            default:
                const v = AppContractPreferencesInfo.load(preferences);
                return new AppContractPreferences(v.contractGuid);
        }
    }

    @UPPropertySave
    readonly version: number = 2;
    @UPPropertySave
    readonly contractGuid: string | undefined;
    @UPPropertySave
    shippers: AppShipperPreferences[];
    @UPPropertySave
    shipperId: number;
    @UPPropertySave
    pages: ContractPagesPreference;

    constructor(contractGuid: string | undefined, shippers: AppShipperPreferences[] = [], shipperId: number = 0, pages = new ContractPagesPreference()) {
        this.contractGuid = contractGuid;
        this.shippers = shippers;
        this.shipperId = shipperId ?? this.shippers[0]?.shipperId;
        this.pages = pages;
    }
}
