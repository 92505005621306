// @ts-nocheck
import { Subscription } from "rxjs";

export class Subscriptions {
    private _subs: { [id: string]: Subscription; } = {};

    public push(key: string, sub: Subscription) {
        if (this._subs[key]) {
            this._clearSub(key);
        }
        this._subs[key] = sub;
    }

    public has(key: string): boolean {
        let sub: Subscription = this._subs[key];
        if (sub && (sub.closed || sub["isStopped"])) {
            this._clearSub(key);
            sub = undefined;
        }
        return sub !== undefined;
    }

    public clearSub(key: string) {
        this._clearSub(key);
    }

    public clearSubStartWith(keyStartWith: string) {
        for (let i in this._subs) {
            if (i.startsWith(keyStartWith)) {
                this._clearSub(i);
            }
        }
    }

    public clearAll() {
        for (let i in this._subs) {
            this._clearSub(i);
        }
    }

    private _clearSub(key: string) {
        let sub = this._subs[key];
        if (sub) {
            if (!sub.closed) {
                sub.unsubscribe();
            }
            sub = undefined;
            delete this._subs[key];
        }
    }
}
