import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IDateRange } from "@eztypes/generic";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageNominationsMessagesPreferenceInfo {
    static load(preferences: string | PageNominationsMessagesPreferenceInfo): PageNominationsMessagesPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageNominationsMessagesPreferenceInfo>(preferences, PageNominationsMessagesPreferenceInfo) as PageNominationsMessagesPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    maxItems: number | undefined;
    refreshTimer: number | undefined;
    deliveryDates: IDateRange | undefined;
    withDate: boolean | undefined;
}

@UserPreferenceClassDecorator
export class PageNominationsMessagesPreference implements IPreferencePage {
    static load(preferences: string | PageNominationsMessagesPreferenceInfo): PageNominationsMessagesPreference {
        if (!preferences) {
            return new PageNominationsMessagesPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageNominationsMessagesPreferenceInfo.load(preferences);
                return new PageNominationsMessagesPreference(
                    v1.maxItems,
                    v1.refreshTimer,
                    v1.deliveryDates,
                    v1.withDate
                );
            default:
                return new PageNominationsMessagesPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    maxItems: number | undefined;
    @UPPropertySave
    refreshTimer: number | undefined;

    deliveryDates: IDateRange | undefined;
    withDate: boolean | undefined;

    constructor(
        maxItems: number = 100,
        refreshTimer: number | undefined = undefined,
        deliveryDates?: IDateRange | undefined,
        withDate?: boolean | undefined
    ) {
        this.maxItems = maxItems;
        this.refreshTimer = refreshTimer;
        this.deliveryDates = deliveryDates;
        this.withDate = withDate;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byDate) {
            this.deliveryDates = undefined;
            this.withDate = undefined;
        }
        if (options.all) {
            this.maxItems = undefined;
            this.refreshTimer = undefined;
        }
    }
}

