import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IDateRange } from "@eztypes/generic";
import { TypeMessage } from '../../../modules/assets/components/schedules/params/parameters-assets-schedule.component';
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageAssetBatchPreferenceInfo {
    static load(preferences: string | PageAssetBatchPreferenceInfo): PageAssetBatchPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageAssetBatchPreferenceInfo>(preferences, PageAssetBatchPreferenceInfo) as PageAssetBatchPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    maxItems: number | undefined;
    deliveryDates: IDateRange | undefined;
    withDate: boolean | undefined;
    typeMessage: TypeMessage | undefined;
}

@UserPreferenceClassDecorator
export class PageAssetBatchPreference implements IPreferencePage {
    static load(preferences: string | PageAssetBatchPreferenceInfo): PageAssetBatchPreference {
        if (!preferences) {
            return new PageAssetBatchPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageAssetBatchPreferenceInfo.load(preferences);
                return new PageAssetBatchPreference(
                    v1.maxItems,
                    v1.deliveryDates,
                    v1.withDate,
                    v1.typeMessage
                );
            default:
                return new PageAssetBatchPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    maxItems: number | undefined;

    deliveryDates: IDateRange | undefined;
    withDate: boolean | undefined;
    typeMessage: TypeMessage | undefined;

    constructor(
        maxItems: number = 100,
        deliveryDates?: IDateRange | undefined,
        withDate?: boolean | undefined,
        typeMessage?: TypeMessage | undefined
    ) {
        this.maxItems = maxItems;
        this.deliveryDates = deliveryDates;
        this.withDate = withDate;
        this.typeMessage = typeMessage;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byDate) {
            this.deliveryDates = undefined;
            this.withDate = undefined;
        }
        if (options.all) {
            this.maxItems = undefined;
            this.typeMessage = undefined;
        }
    }
}

