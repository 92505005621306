<header>
    <ez-banner class="app-notif" [severity]="'error'" *ngIf="updateData">
        <span class="banner-label">{{updateData?.text}}</span>
        <ez-actions>
            <ez-button [label]="'Reload'" [severity]="'danger'" class="button" [small]="true"
                (onClick)="onUpdateVersion()">
            </ez-button>
        </ez-actions>
    </ez-banner>
    <router-outlet name="header" *ngIf="!popupPage"></router-outlet>
</header>
<div class="page-container">
    <router-outlet name="menu" *ngIf="!popupPage"></router-outlet>
    <div class="page-content" [ngClass]="{'full-screen': isFullScreenPage}">
        <ez-loader *ngIf="onLoading && currentUser != null"></ez-loader>
        <div id="contracts-loader-container" *ngIf="loader.onLoading">
            <mat-card>
                <mat-card-header>
                    <img src="./logo.png" width="128" height="82">
                </mat-card-header>
                <mat-card-content>
                    <mat-spinner style="width: 50px; height: 50px;"></mat-spinner>
                </mat-card-content>
            </mat-card>
        </div>
        <router-outlet (deactivate)="deactivatePage()"></router-outlet>
    </div>
    <screen-panel class="panel-aside" [hide]="!isAsideActive" [position]="'right'" [resizable]="true"
        (transitionend)="asideTransitionEnd()">
        <router-outlet name="aside"></router-outlet>
    </screen-panel>
    <screen-panel class="panel-help" [hide]="!displayHelp" [position]="'right'" [resizable]="true"
        (transitionend)="asideTransitionEnd()">
        <ez-help *ngIf="displayHelp"></ez-help>
    </screen-panel>
</div>
<ez-modal [(open)]="openPopup" (onClose)="closePopup()" [beforeCloseFn]="beforePopupClose"
    [class]="pagePopup?.cssClassPopup">
    <ez-icon *ngIf="pagePopup?.urlPage" name="external-link-alt" class="btn-external-link" (click)="clickOpenPopup()"
        ezTooltip="Open in new window">
    </ez-icon>
    <ez-header *ngIf="pagePopup?.titlePopup">
        {{ pagePopup.titlePopup }}
    </ez-header>
    <router-outlet name="popup" (activate)="activatePopup($event)" (deactivate)="deactivatePopup()"></router-outlet>
</ez-modal>
<ez-notifications [timeOut]="1500"></ez-notifications>
<div class="admin-panel" *ngIf="(currentUser!==undefined) && (currentUser.isAdmin===true) && abControlDisplay">
    <div class="admin-block">
        <div class="title-block">Rights</div>
        <admin-right class="cpt-block"></admin-right>
    </div>
    <div class="admin-block">
        <div class="title-block">Ab testing</div>
        <ab-testing class="cpt-block"></ab-testing>
    </div>
</div>
