<ez-table [data]="data" *ngIf="data && data.length" type="tableur" [disabled]="disabled">
    <ez-row-header>
        <th></th>
        <th *ngFor="let col of data[0].cells; index as i">
            <span>{{ col.from | timeFormatting: "hh:mm" }}</span>
            <span> - </span>
            <span>{{ col.to | timeFormatting: "hh:mm" }}</span>
        </th>
    </ez-row-header>
    <ez-column property="header" [fixed]="true">
        <th *ezTbCell="let item">{{ item.header }}</th>
    </ez-column>
    <ez-column *ngFor="let col of data[0].cells; index as i" property="cells" [indexCell]="i">
        <td *ezTbCell="let item" (click)="selectCol(item)" class="selectable">
             <ez-icon-font [name]="'fa-check'" [ngClass]="{active: item?.isActive}"></ez-icon-font>
        </td>
    </ez-column>
</ez-table>
