import { Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Optional, Output, Self, ViewEncapsulation, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from "@angular/forms";
import { EzFormControl, IEzFormControl } from "@eznergy/components";
import { ITimeRange, ITimespan, TimeRange, Timespan, TimeUnit } from "@eztypes/generic";
import { Product } from "@eztypes/webapi";
import * as _ from "lodash";

class TableRow {
    header: string | undefined;
    cells: TableCell[] | undefined;
}

class TableCell {
    from: ITimespan;
    to: ITimespan;
    isActive: boolean;

    constructor(from: ITimespan, to: ITimespan) {
        this.from = from;
        this.to = to;
        this.isActive = false;
    }
}


@Component({
    selector: 'timestep-selector',
    templateUrl: './timestep-selector.component.html',
    styleUrls: ['./timestep-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: EzFormControl, useExisting: TimestepSelectorComponent }
    ]
})
export class TimestepSelectorComponent extends EzFormControl implements IEzFormControl {

    @Output() valueChange = new EventEmitter<ITimeRange[]>();

    @Input()
    get productType(): Product | undefined {
        return this._type;
    }
    set productType(value: Product | undefined) {
        if (this.productType !== value) {
            this._type = value;
            if (value) {
                this._constructuctTable(this._type);
            }
            this._cdr.markForCheck();
        }
    }
    private _type: Product | undefined;

    @Input()
    get value(): ITimeRange[] | undefined {
        return this._value;
    }
    set value(value: ITimeRange[] | undefined) {
        if (this.value !== value) {
            this._value = value;
            if (value)
                this._value = _.orderBy(value, ['from.days', 'from.hours', 'from.minutes']);
            this._setActiveCells();
            this._cdr.markForCheck();
        }
    }
    private _value: ITimeRange[] | undefined;

    data: TableRow[] | undefined;

    @HostListener("focusin", ["$event"])
    override focusin(event: FocusEvent): void {
        super.focusin(event);
    }

    @HostListener("focusout", ["$event"])
    override focusout(event: FocusEvent): void {
        super.focusout(event);
    }


    constructor(
        renderer2: Renderer2,
        _cdr: ChangeDetectorRef,
        _elementRef: ElementRef,
        @Attribute('tabindex') tabIndex: string,
        @Attribute('id') id: string,
        @Self() @Optional() ngControl: NgControl) {
        super(renderer2, _elementRef, _cdr, ngControl, tabIndex, id);
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }

    override focus() {
        this._elementRef.nativeElement.focus();
    }

    private _setActiveCells() {
        _.forEach(this.data, (item: TableRow) => {
            _.forEach(item.cells, (cell: TableCell) => {
                cell.isActive = _.some(this._value, { from: { days: cell.from.days, hours: cell.from.hours, minutes: cell.from.minutes } });
            });
        });
    }

    private _constructuctTable(product: Product | undefined) {
        let tabLength;
        let minutes: number | undefined;
        let init: ITimespan = new Timespan(0, TimeUnit.Minutes);
        this.data = [{ header: 'D', cells: [] }, { header: 'D+1', cells: [] }];

        if (product == Product.Hour) {
            tabLength = 24;
            minutes = 60;
        } else if (product == Product.HalfHour) {
            tabLength = 48;
            minutes = 30;
        } else if (product == Product.QuarterHour) {
            tabLength = 96;
            minutes = 15;
        }

        if (!tabLength || !minutes) {
            return;
        }

        for (let i = 0; i < tabLength; i++) {
            let start = new Timespan(init.totalMinutes, TimeUnit.Minutes);
            let end = start.addMinutes(minutes);
            init = end;
            this.data[0]!.cells!.push(new TableCell(start, end));
            this.data[1]!.cells!.push(new TableCell(start.addDays(1), end.addDays(1)));
        }
    }

    private _addSelected(selected: TableCell) {
        if (!this._value) this._value = [];
        let newTimeSelector = new TimeRange(
            new Timespan(selected.from.totalHours, TimeUnit.Hours),
            new Timespan(selected.to.totalHours, TimeUnit.Hours)
        );
        this._value.push(newTimeSelector);
        this._propagateChangeValue();
    }

    private _deleteSelected(selected: TableCell) {
        if (!this._value) {
            return;
        }
        for (let i = 0; i < this._value.length; i++) {
            if (selected.from.days == this._value[i]?.from.days
                && selected.from.hours == this._value[i]?.from.hours
                && selected.from.minutes == this._value[i]?.from.minutes) {
                this._value.splice(i, 1);
                break;
            }
        }

        if (!this._value.length) this._value = undefined;
        this._propagateChangeValue();
    }

    selectCol(selected: TableCell) {
        if (this.disabled) return;
        selected.isActive = !selected.isActive;
        if (selected.isActive) {
            this._addSelected(selected);
        } else {
            this._deleteSelected(selected);
        }
    }

    private _propagateChangeValue(): void {
        this._onChange(this._value);
        this.valueChange.emit(this._value);
    }

    override writeValue(value: any) {
        this.value = value;
        super.writeValue(this.value);
    }
}
