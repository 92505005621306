import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseSearchComponent } from './search/base-search.component';


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        BaseSearchComponent
    ],
    exports: [
        BaseSearchComponent
    ]
})
export class BaseModule { }
