<ez-button *ngIf="!noGrid" [big]="true" (click)="toggleDisplayGridSelector()">
    <ez-icon [name]="displayGrid ? 'arrow-left' : 'arrow-right'"></ez-icon>
</ez-button>
<span>
    <ng-content></ng-content>
</span>
<span *ngIf="grid" class="grid-info">
    <span>-</span>
    <img [src]="grid | StaticGridUri:64" />
    <span>{{grid.name}}</span>
</span>
<ez-button *ngIf="hasRefresh" [big]="true" (onClick)="refresh.emit()" [ezTooltip]="'general.refresh' | translate">
    <ez-icon [name]="'sync-alt'"></ez-icon>
</ez-button>
<ng-container *ngIf="hasAutoRefresh">
    <ez-button [big]="true" class="btn-timer" [class.selected]="onAutoRefresh" ez-overlay-origin
        #arOrigin="EzOverlayOrigin" [ezTooltip]="'Last refresh : '+(lastDateRefresh | ezdate)">
        <ez-icon [name]="'hourglass-start'"></ez-icon>
        <span class="timer-time">{{timerAt}} min</span>
    </ez-button>
    <ez-popover [origin]="arOrigin" [actionType]="'click'" [alignV]="'bottom'" [alignH]="'left'" [alignInnerH]="true"
        [class]="'p-over-at'">
        <div class="p-title">Refresh every</div>
        <div>
            <ez-input-number [value]="timerAt" [min]="1" [required]="true" (valueChange)="timerValueChange($event)">
                <ez-suffix>Min</ez-suffix>
            </ez-input-number>
            <!-- <ez-button (onClick)="toggleAt()" [small]="true" [severity]="onAutoRefresh ? 'danger' :  undefined">
                <ez-icon [name]="onAutoRefresh ? 'stop' : 'play'">
                </ez-icon>
                {{onAutoRefresh ? 'Stop' : 'play'}}
            </ez-button> -->
        </div>
    </ez-popover>
</ng-container>
