import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { CustomFormGroupComponent } from './components/form-controls/custom-form-group/custom-form-group.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EzComponentsModule, EzDirectivesModule, EzFormModule } from '@eznergy/components';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SelectTimeseriesComponent } from './components/form-controls/select-timeseries/select-timeseries.component';
import { GridsController } from './components/controllers/grids/grids.controller';
import { DatesController } from './components/controllers/dates/dates.controller';
import { EmptyPage } from './components/ez-empty/empty.page';
import { FirstContent } from './components/layout/first-content/first-content.component';
import { MenuPage } from './components/layout/menu-page/menu-page.component';
import { TitlePageComponent } from './components/layout/title-page/title-page.component';
import { DownloadControllerComponent } from './components/controllers/download/download.component';
import { AgGridModule } from 'ag-grid-angular';
import { GpDataSelectorComponent } from './components/controllers/grids/gp-data-selector/gp-data-selector.component';
import { AppPipesModule } from '@pipes/pipes.module';
import { IconsModule } from './components/icons/icons.module';
import { PhysicalValueComponent } from './components/ez-form-controls/physical-value/physical-value.component';
import { CurvesSelectComponent } from './components/ez-form-controls/curves-select/curves-select.component';
import { PipesModules } from './pipes/pipes.modules';
import { DateComponent } from './components/date/date.component';
import { AppDirectivesModule } from '@directives/directives.module';
import { AddCurveComponent } from './components/ez-form-controls/add-curve/add-curve.component';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { FiltersComponentModule } from './components/filters/filters.module';
import { CreateSchedulesComponent } from './components/form-popups/create-schedules/create-schedules.component';
import {
    ForecastsSchedulesComponent
} from './components/form-popups/create-schedules/forecasts-schedules/forecasts-schedules.component';
import {
    ImmediateSchedulesComponent
} from './components/form-popups/create-schedules/immediate-schedules/immediate-schedules.component';
import { BaseModule } from './components/base/base.module';
import { ScreenPanel } from './components/layout/screen-panel/screen-panel.component';
import { TimeserieCard } from './directives/cards/timeserie/timeserie.card';
import { OrganisationCard } from './directives/cards/organisation/organisation.card';
import { GridPointCard } from './directives/cards/grid-point/grid-point.card';
import { GridCard } from './directives/cards/grid/grid.card';
import { PopoverCardDirective } from './directives/cards/cards.directive';
import { GridCardPopover } from './directives/cards/grid/grid.card.popover';
import { GridPointCardPopover } from './directives/cards/grid-point/grid-point.card.popover';
import { OrganisationCardPopover } from './directives/cards/organisation/organisation.card.popover';
import { TimeserieCardPopover } from './directives/cards/timeserie/timeserie.card.popover';
import { BaseCardComponent } from './directives/cards/base-card.component';
import { EzBanner } from './components/overlay/banner/banner.component';
import { EzInputModule } from './components/ez-form-controls/input/input.module';
import { GroupsSelectComponent } from './components/ez-form-controls/groups/groups-select.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EzLockerComponent } from './components/overlay/locker/locker.component';
import { ChartSessionSvgComponent } from '../autotrading/components/session/chart-trading/chart-session/chart-session-svg.component';
import { AtSessionController } from '../autotrading/components/session/controller/at-session-controller';
import { AtScheduleForm } from '../autotrading/components/session/controller/schedule/schedule.component';

const matShare = [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDialogModule,
    MatCardModule
];

const ezShare = [
    EzComponentsModule,
    EzDirectivesModule,
    EzFormModule,
    EzInputModule
];

const components = [
    CustomFormGroupComponent,
    SelectTimeseriesComponent,
    LoaderComponent,
    GridsController,
    GpDataSelectorComponent,
    DatesController,
    DownloadControllerComponent,
    EmptyPage,
    FirstContent,
    MenuPage,
    ScreenPanel,
    TitlePageComponent,
    PhysicalValueComponent,
    CurvesSelectComponent,
    DateComponent,
    AddCurveComponent,
    CreateSchedulesComponent,
    ForecastsSchedulesComponent,
    ImmediateSchedulesComponent,
    EzBanner,
    EzLockerComponent,
    GroupsSelectComponent,
    ChartSessionSvgComponent,
    AtSessionController,
    AtScheduleForm
];

const directives = [
    TimeserieCard,
    OrganisationCard,
    GridPointCard,
    GridCard,
    PopoverCardDirective,
    GridCardPopover,
    GridPointCardPopover,
    OrganisationCardPopover,
    TimeserieCardPopover,
    BaseCardComponent
];

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        TranslateModule,
        ...matShare,
        ...ezShare,
        AgGridModule,
        AgChartsAngularModule,
        FormsModule,
        ReactiveFormsModule,
        AppPipesModule,
        IconsModule,
        PipesModules,
        FiltersComponentModule,
        AppDirectivesModule,
        BaseModule
    ],
    declarations: [
        ...components,
        ...directives
    ],
    providers: [
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { color: 'primary' }
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' }
        }
    ],
    exports: [
        ...matShare,
        ...ezShare,
        AgGridModule,
        AgChartsAngularModule,
        PipesModules,
        AppPipesModule,
        IconsModule,
        BaseModule,
        AppDirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        FiltersComponentModule,
        ...components,
        ...directives
    ]
})
export class SharedModule {
}
