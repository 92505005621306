import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppCoreModule } from "@core/core.module";
import { AppFormsModule } from "@forms/forms.module";
import { TranslateModule } from "@ngx-translate/core";
import { PageLogOutComponent } from "./page-log-out/page-log-out.component";
import { PageNotFoundComponent } from "./pageNotFound/page-not-found.component";
import { PageOfflineComponent } from "./pageOffline/page-offline.component";

@NgModule({
    imports: [
        AppCoreModule,
        AppFormsModule,
        TranslateModule,
        RouterModule
    ],
    declarations: [
        PageLogOutComponent,
        PageNotFoundComponent,
        PageOfflineComponent
    ],
    exports: [
        PageLogOutComponent,
        PageNotFoundComponent,
        PageOfflineComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SystemsPagesModule {

}
