// @ts-nocheck
import { Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Optional, Output, Self, ViewEncapsulation, Renderer2 } from '@angular/core';
import { NgControl } from "@angular/forms";
import { GradientItemsBaseComponent, GradientItemView } from './gradient-items-base.component';
// Components
import { EzFormControl } from "@eznergy/components";
import { Timespan } from '@eztypes/generic';
// Models
import { IMarketMakingPolicy, IPhysicalValue, IUnit, MarketMakingPolicy, MarketMakingStrategy, PhysicalValue } from "@eztypes/webapi";

class GradientItemMarketView extends GradientItemView {
    strategy: MarketMakingStrategy;
    bidPriceOffset: number;
    askPriceOffset: number;
    unitPrice: IUnit;
    get value(): number {
        if (this.bidPriceOffset == undefined || this.askPriceOffset == undefined || this.strategy == undefined)
            return undefined;
        else
            return 1;
    }
}

@Component({
    selector: 'ez-gradient-market',
    templateUrl: './gradient-market.component.html',
    styleUrls: ['./gradient-market.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: EzFormControl, useExisting: GradientMarketComponent }
    ]
})
export class GradientMarketComponent extends GradientItemsBaseComponent<GradientItemMarketView, IMarketMakingPolicy[]> {

    @Output() valueChange: EventEmitter<IMarketMakingPolicy[]> = new EventEmitter();

    @Input()
    set defaultValue(value: IPhysicalValue) {
        const newValue = value ? value : new PhysicalValue();
        if (newValue !== this.defaultValue) {
            this._defaultValue = value;
            this._setDefaultValues();
            this._cdr.markForCheck();
        }

    }
    get defaultValue(): IPhysicalValue {
        return this._defaultValue;
    }
    private _defaultValue: IPhysicalValue;

    @Input()
    set value(values: IMarketMakingPolicy[]) {
        if (this.value !== values) {
            this._init(values);
            this._cdr.markForCheck();
        }
    }
    get value(): IMarketMakingPolicy[] {
        return this._oldValues;
    }
    private _oldValues: IMarketMakingPolicy[];

    strategies = MarketMakingStrategy;
    private _changedValue: boolean;

    _ctor: { new(): GradientItemMarketView } = GradientItemMarketView;

    constructor(renderer2: Renderer2, elementRef: ElementRef, cdr: ChangeDetectorRef,
        @Attribute('tabindex') tabIndex: string,
        @Attribute('id') id: string,
        @Self() @Optional() ngControl: NgControl) {
        super(renderer2, elementRef, cdr, tabIndex, id, ngControl);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    deleteRow(index: number) {
        if (this.disabled) return;
        super.deleteRow(index);
        this._emitGradientItemsChange();
        this._cdr.detectChanges();
        this._focusByIndex(index);
    }

    onBuyerValueChange(value: number, index: number) {
        if (this.disabled) return;
        let item = this.gradientItems[index];
        item.bidPriceOffset = value;
        this._checkValidityItems();
        this._emitGradientItemsChange();
    }

    onSellerValueChange(value: number, index: number) {
        if (this.disabled) return;
        let item = this.gradientItems[index];
        item.askPriceOffset = value;
        this._checkValidityItems();
        this._emitGradientItemsChange();
    }

    onStrategyValueChange(value: MarketMakingStrategy, index: number) {
        if (this.disabled) return;
        let item = this.gradientItems[index];
        item.strategy = value;
        this._checkValidityItems();
        this._emitGradientItemsChange();
    }

    onToChange(value: number, index: number) {
        if (this.disabled) return;
        super.onToChange(value, index);
        this._emitGradientItemsChange();
    }

    onAddClick() {
        if (this.disabled) return;
        let item = this.addRow();
        this._setDefaultValue(item);
        this._checkValidityItems();
        this._emitGradientItemsChange();
        this._cdr.detectChanges();
        this._focusByIndex(this.gradientItems.length - 1);
    }

    private _emitGradientItemsChange() {
        this._oldValues = !this.hasError ? this._toIGradientItemMarket() : undefined;
        this._onChange(this._oldValues);
        this.valueChange.emit(this._oldValues);
    }

    private _setDefaultValues() {
        this._changedValue = false;
        if (this.gradientItems)
            this.gradientItems.forEach((item) => {
                this._setDefaultValue(item);
            });
    }

    private _setDefaultValue(item: GradientItemMarketView) {
        if (this.defaultValue && item.bidPriceOffset == undefined) {
            item.bidPriceOffset = this.defaultValue.value;
            this._changedValue = true;
        }
        if (this.defaultValue && item.askPriceOffset == undefined) {
            item.askPriceOffset = this.defaultValue.value;
            this._changedValue = true;
        }
        if (this.defaultValue && item.strategy == undefined) {
            item.strategy = MarketMakingStrategy.PriceLimit;
            this._changedValue = true;
        }
        if (this.defaultValue && item.unitPrice == undefined) {
            let unit = (this.gradientItems.length && this.gradientItems[this.gradientItems.length - 1].unitPrice) || this._defaultValue.unit;
            item.unitPrice = unit;
            this._changedValue = true;
        }
    }

    private _init(values: IMarketMakingPolicy[]) {
        let itemsView: GradientItemMarketView[] = [];
        if (values) {
            values.forEach((item: IMarketMakingPolicy) => {
                let itemView = new GradientItemMarketView();
                itemView.from = item.from;
                if (item.to)
                    itemView.to = item.to;
                itemView.unitPrice = item.unitPrice;
                itemView.askPriceOffset = item.askPriceOffset;
                itemView.bidPriceOffset = item.bidPriceOffset;
                itemView.strategy = item.strategy;
                itemView.onError = false;
                itemsView.push(itemView);
            });
        }
        let newItemsView = this.init(itemsView);
        this._setDefaultValues();
        this._checkValidityItems();
        this._oldValues = this._toIGradientItemMarket();
        if (itemsView.length != newItemsView.length || this._changedValue) {
            this._emitGradientItemsChange();
        }
    }

    private _toIGradientItemMarket(): IMarketMakingPolicy[] {
        let items = this.gradientItems;
        let internalItems: IMarketMakingPolicy[];
        internalItems = [];
        items.forEach((item) => {
            if (item.onError) return undefined;
            let it = new MarketMakingPolicy();
            it.from = item.from as Timespan;
            if (item.to)
                it.to = item.to as Timespan;
            it.bidPriceOffset = item.bidPriceOffset;
            it.askPriceOffset = item.askPriceOffset;
            it.strategy = item.strategy;
            it.unitPrice = item.unitPrice;
            internalItems.push(it);
        });
        return internalItems;
    }

    writeValue(value: any): void {
        this.value = value;
        super.writeValue(this.value);
    }
}
