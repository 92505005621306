<ez-modal [(open)]="open" (onClose)="onConfirmClickButton($event)" class="modal-confirm">
    <ez-header>
        {{'general.confirm-unsaved-header' | translate}}
    </ez-header>
    <ez-content>
        {{'general.confirm-unsaved-content' | translate}}
    </ez-content>
    <ez-footer>
        <ez-button [label]="'general.cancel' | translate" ez-modal-close></ez-button>
        <ez-button [label]="'general.confirm' | translate" [severity]="'primary'" [ez-modal-close]="true">
        </ez-button>
    </ez-footer>
</ez-modal>
