import { Component, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { EzNotificationService } from '@eznergy/toaster';
import { IOrganisation } from '@eztypes/webapi';
import * as _ez from "@eznergy/core";
import { TranslateService } from '@ngx-translate/core';
import { BaseCardComponent } from '../base-card.component';

@Component({
    selector: 'organisation-card',
    templateUrl: './organisation.card.html',
    styleUrls: ['./organisation.card.scss', "../cards.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrganisationCard extends BaseCardComponent<IOrganisation> implements OnDestroy {

    private _successCopyId: string = '';
    private _successCopyEic: string = '';

    constructor(cdr: ChangeDetectorRef,
        private readonly _notifSvc: EzNotificationService,
        private readonly _serviceTranslate: TranslateService
    ) {
        super(cdr);
        const sub = this._serviceTranslate.get(["cards.organisation.clipboard-id", "cards.organisation.clipboard-eic"]).subscribe((translate) => {
            this._successCopyId = translate["cards.organisation.clipboard-id"];
            this._successCopyEic = translate["cards.organisation.clipboard-eic"];
        });
        this._subs.push('copy', sub);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    copyIdToClipboard(): void {
        _ez.writeToClipboard(this.value.id.toString());
        this._notifSvc.success(this._successCopyId);
    }

    copyEicToClipboard(): void {
        _ez.writeToClipboard(this.value.codeEIC);
        this._notifSvc.success(this._successCopyEic);
    }
}
