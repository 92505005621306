<div class="status-select">
    <ez-button-group class="btn-group" [value]="typeDisplaySelected">
        <ez-button [label]="'autotrading.session.ordersTradesReport.buttons.live' | translate" [severity]="'danger'"
            [value]="displayType.Live" [routerPage]="'sessionReportLive'" [routerParams]="{id: session?.id}"
            [routerOutlet]="outlet" [disabled]="!hasSessionReadRight || hasLostConnection || !isRunning">
        </ez-button>
        <ez-button [label]="'autotrading.session.ordersTradesReport.buttons.session' | translate"
            [routerPage]="'sessionReportPast'" [routerParams]="{id: session?.id}" [routerOutlet]="outlet"
            [value]="displayType.Session" [disabled]="!hasSessionReadRight">
        </ez-button>
    </ez-button-group>
</div>

<ez-tabs [(value)]="activeTab">
    <ez-tab-section [value]="tabs.Orders"
        [disabled]='!hasSessionReadRight || hasLostConnection || !isRunning || typeDisplaySelected !== displayType.Live'>
        <ez-tab-title>{{ 'autotrading.session.ordersTradesReport.orders.title' | translate }}</ez-tab-title>
        <ez-content *ngIf="activeTab === tabs.Orders">
            <ez-loader *ngIf="pageHasLoading"></ez-loader>
            <ez-order-report-table [orders]="orders" [runNumber]="currentRun?.runNumber"
                [isEditable]="isRunningManual && hasSessionUpdateRight" (orderSelected)="onSelectedOrder($event)"
                [session]="session" [grid]="session?.balancingGrid" [timezone]="tz">
            </ez-order-report-table>
        </ez-content>
    </ez-tab-section>

    <ez-tab-section [value]="tabs.Trades" [disabled]="!hasSessionReadRight">
        <ez-tab-title>{{ 'autotrading.session.ordersTradesReport.trades.title' | translate }}</ez-tab-title>
        <ez-content *ngIf="activeTab === tabs.Trades">
            <ez-loader *ngIf="pageHasLoading"></ez-loader>
            <ng-container *ngIf="typeDisplaySelected === displayType.Session; else elseNoSession">
                <ng-container *ngIf="runs && runs.length > 0; else elseNoTrades">
                    <div class="accordion-container">
                        <ez-accordion [singleSelected]="false">
                            <ez-accordion-section #tradeSec *ngFor="let run of runs; first as isFirst"
                                (selectedChange)="loadTrades(run.runNumber, tradeSec)" [selected]="isFirst">
                                <ez-title>
                                    <span>Run {{ run.runNumber }}</span>
                                    <ez-tag severity="info" class="mode-{{run.mode}}">
                                        {{ 'autotrading.runMode.' + run.mode | translate }}</ez-tag>
                                    <ng-container *ngIf="run.effectiveStartDate != null;else runCancelled">
                                        <span class="date-run">
                                            <date [value]="run.effectiveStartDate" [to]="run.effectiveEndDate"
                                                [format]="{date: 'medium', time: 'medium'}" [grid]="session?.balancingGrid"
                                                [timezone]="tz">
                                            </date>
                                        </span>
                                        <span class="pnl-run">
                                            P&L : {{ run.profitLoss.value | number:'1.2-2' }} {{ run.profitLoss.unit?.name }}
                                        </span>
                                        <span class="trades-run">
                                            Trades : {{ run.trades | number }}
                                        </span>
                                    </ng-container>
                                    <ng-template #runCancelled>
                                        <span> Cancelled </span>
                                    </ng-template>
                                </ez-title>
                                <ez-content>
                                    <ng-container *ngIf="runTrades?.get(run.runNumber) != undefined; else elseLoading">
                                        <ez-trade-report-table [runNumber]="run.runNumber" [sessionId]="session?.id"
                                            [grid]="session?.balancingGrid" [timezone]="tz">
                                        </ez-trade-report-table>
                                    </ng-container>
                                    <ng-template #elseLoading>
                                        <ez-loader *ngIf="loader.onLoading; else errorBlock"></ez-loader>
                                        <ng-template #errorBlock>
                                            <error-load-data-cpt (onClickRetry)="loadTrades(run.runNumber)">
                                            </error-load-data-cpt>
                                        </ng-template>
                                    </ng-template>
                                </ez-content>
                            </ez-accordion-section>
                        </ez-accordion>
                    </div>
                    <mat-paginator
                        (page)="getRuns($event)"
                        [pageIndex]='currentPage'
                        [length]="runTotalCount"
                        [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        [showFirstLastButtons]="true"
                        [pageSize]="runPageSize">
                    </mat-paginator>
                </ng-container>
            </ng-container>
            <ng-template #elseNoTrades>
                <span class="no-data">No trade to display, as this session hasn't run yet.</span>
            </ng-template>
            <ng-template #elseNoSession>
                <ez-trade-report-table [isCurrent]="true" [runNumber]="currentRun?.runNumber" [sessionId]="session?.id"
                    [sortable]="false" [grid]="session?.balancingGrid"
                    [timezone]="tz">
                </ez-trade-report-table>
            </ng-template>
        </ez-content>
    </ez-tab-section>

    <ez-tab-section [value]="tabs.Logs" [disabled]="!hasSessionReadRight">
        <ez-tab-title>{{ 'autotrading.session.ordersTradesReport.logs.title' | translate }}</ez-tab-title>
        <ez-content *ngIf="activeTab === tabs.Logs">
            <ez-loader *ngIf="pageHasLoading"></ez-loader>
            <ng-container *ngIf="typeDisplaySelected === displayType.Session; else elseNoSessionLogs">
                <ng-container *ngIf="runs && runs.length > 0; else elseNoLogs">
                    <div class="accordion-container">
                        <ez-accordion [singleSelected]="false">
                            <ez-accordion-section #logSec *ngFor="let run of runs; first as isFirst"
                                                  (selectedChange)="loadLogs(run.runNumber, logSec)" [selected]="isFirst">
                                <ez-title>
                                    <span>Run {{ run.runNumber }}</span>
                                    <ez-tag severity="info" class="mode-{{run.mode}}">
                                        {{ 'autotrading.runMode.' + run.mode | translate }}</ez-tag>
                                    <ng-container *ngIf="run.effectiveStartDate != null;else runCancelled">
                                    <span class="date-run">
                                        <date [value]="run.effectiveStartDate" [to]="run.effectiveEndDate"
                                              [format]="{date: 'medium', time: 'medium'}" [grid]="session?.balancingGrid"
                                              [timezone]="tz">
                                        </date>
                                    </span>
                                        <span class="pnl-run">
                                        P&L : {{ run.profitLoss.value | number:'1.2-2' }} {{ run.profitLoss.unit?.name }}
                                    </span>
                                        <span class="trades-run">
                                        Trades : {{ run.trades | number }}
                                    </span>
                                    </ng-container>
                                    <ng-template #runCancelled>
                                        <span> Cancelled </span>
                                    </ng-template>
                                </ez-title>
                                <ez-content>
                                    <ng-container *ngIf="runLogs.get(run.runNumber) != undefined; else elseLoading">
                                        <ez-log-report-table [runNumber]="run.runNumber" [sessionId]="session?.id"
                                                             [grid]="session?.balancingGrid" [timezone]="tz"
                                                             [ngStyle]="{'max-height': sizeTableAccordion === 0 ? '100%' : sizeTableAccordion+'px'}">
                                        </ez-log-report-table>
                                    </ng-container>
                                    <ng-template #elseLoading>
                                        <ez-loader *ngIf="loader.onLoading; else errorBlock"></ez-loader>
                                        <ng-template #errorBlock>
                                            <error-load-data-cpt (onClickRetry)="loadLogs(run.runNumber)"></error-load-data-cpt>
                                        </ng-template>
                                    </ng-template>
                                </ez-content>
                            </ez-accordion-section>
                        </ez-accordion>
                    </div>
                    <mat-paginator
                        (page)="getRuns($event)"
                        [length]="runTotalCount"
                        [pageIndex]='currentPage'
                        [pageSizeOptions]="[5, 10, 20, 50, 100]"
                        [showFirstLastButtons]="true"
                        [pageSize]="runPageSize">
                    </mat-paginator>
                </ng-container>
            </ng-container>
            <ng-template #elseNoLogs>
                <span class="no-data">No log to display, as this session hasn't run yet.</span>
            </ng-template>
            <ng-template #elseNoSessionLogs>
                <ez-log-report-table [isCurrent]="true" [runNumber]="currentRun?.runNumber" [sessionId]="session?.id"
                    [grid]="session?.balancingGrid" [timezone]="tz" [sortable]="false">
                </ez-log-report-table>
            </ng-template>
        </ez-content>
    </ez-tab-section>
</ez-tabs>

<ez-modal *ngIf="hasSessionUpdateRight" [open]="hasOpenModalOrder" (onClose)="onOrderFormClose()">
    <ez-header>
        {{ 'autotrading.session.order.modal.title-edit' | translate }}
    </ez-header>
    <at-order-form *ngIf="hasOpenModalOrder" [contracts]="contracts" [selectedContract]="selectedContract" [session]="session"
        [contractmetrics]="selectedContractMetrics" [privateOrder]="privateOrderSelected" (onSentOrder)="onOrderFormClose()"
        (onSubmit)="onOrderFormClose()" (onCancel)="onOrderFormClose()" (onDelete)="onOrderFormClose()"></at-order-form>
</ez-modal>
