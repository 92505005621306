// @ts-nocheck
import { Injectable } from "@angular/core";
// Model
import { Decimal } from "decimal.js-light";
import { DateTime } from '@eztypes/generic';


@Injectable({ providedIn: 'root' })
export class EtsService {

    constructor() { }

    // Calcul de volume pondéré
    public calcVwA(enDate: DateTime, startDate: DateTime, volume: number, result: Decimal): Decimal {
        let difference = Math.abs(enDate.time - startDate.time);
        let timeStep = (new Decimal(difference).dividedBy(1000)).dividedBy(60);
        let average = new Decimal(volume).mul((timeStep).dividedBy(60));
        return result.plus(average);
    }
}
