// @ts-nocheck
import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IVersionContainer, VersionContainer } from "./core.model";

class AppGridsPreferenceInfo implements IVersionContainer {
    static load(preferences: string | AppGridsPreferenceInfo): AppGridsPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<AppGridsPreferenceInfo>(preferences, AppGridsPreferenceInfo) as AppGridsPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    balancings: number[];
    nominations: number[];
    markets: number[];
    assets: number[];
    balancing: number;
    nomination: number;
    market: number;
    asset: number;
    gridpoints: number[];

    // Continuous screen
    balancingsContinuous: number[];
    balancingContinuous: number;
    // Auctions screen
    balancingsAuction: number[];
    balancingAuction: number;
    // Deals screen
    balancingsDeal: number[];
    balancingDeal: number;
    // Auction Overview screen
    auctionsOverview: number[];
    auctionOverview: number;
    // Screen Nomination Real time / Messages
    nominationsRealTimeMessages: number[];
    nominationRealTimeMessages: number;
    // Screen Nomination Overview
    nominationsOverview: number[];
    nominationOverview: number;
    // Screen Nomination Batch
    nominationsBatch: number[];
    nominationBatch: number;
    // Screen Nomination Configurations
    nominationsConf: number[];
    nominationConf: number;
}

@UserPreferenceClassDecorator
export class AppGridsPreference implements IVersionContainer {
    static load(preferences: string | AppGridsPreferenceInfo): AppGridsPreference {
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = AppGridsPreferenceInfo.load(preferences);
                return new AppGridsPreference(
                    v1.balancings, v1.markets, v1.assets, v1.balancing,
                    v1.market, v1.asset, v1.gridpoints, v1.balancingsDeal, v1.balancingDeal,
                    v1.nominationsRealTimeMessages, v1.nominationRealTimeMessages, v1.nominationsOverview,
                    v1.nominationOverview, v1.nominationsBatch, v1.nominationBatch, v1.balancingsAuction,
                    v1.balancingAuction, v1.balancingsContinuous, v1.balancingContinuous,
                    v1.auctionsOverview, v1.auctionOverview
                );
            default:
                return new AppGridsPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;

    // Screen deals
    @UPPropertySave
    balancingsDeal: number[];
    @UPPropertySave
    balancingDeal: number;
    // Continuous screen
    @UPPropertySave
    balancingsContinuous: number[];
    @UPPropertySave
    balancingContinuous: number;
    // Auction Overview screen
    @UPPropertySave
    auctionsOverview: number[];
    @UPPropertySave
    auctionOverview: number;
    // All other balancings screen
    @UPPropertySave
    balancings: number[];
    @UPPropertySave
    balancing: number;

    // Screen Nomination Real time / Messages
    @UPPropertySave
    nominationsRealTimeMessages: number[];
    @UPPropertySave
    nominationRealTimeMessages: number;
    // Screen Nomination Overview
    @UPPropertySave
    nominationsOverview: number[];
    @UPPropertySave
    nominationOverview: number;
    // Screen Nomination Batch
    @UPPropertySave
    nominationsBatch: number[];
    @UPPropertySave
    nominationBatch: number;
    // Screen Nomination Configurations
    @UPPropertySave
    nominationsConf: number[];
    @UPPropertySave
    nominationConf: number;
    // Auctions screen
    @UPPropertySave
    balancingsAuction: number[];
    @UPPropertySave
    balancingAuction: number;

    @UPPropertySave
    markets: number[];
    @UPPropertySave
    assets: number[];

    @UPPropertySave
    market: number;
    @UPPropertySave
    asset: number;
    @UPPropertySave
    gridpoints: number[];

    constructor(
        balancings?: number[], markets?: number[], assets?: number[],
        balancing?: number, market?: number, asset?: number, gridpoints?: number[], balancingsDeal?: number[],
        balancingDeal?: number, nominationsRealTimeMessages?: number[], nominationRealTimeMessages?: number,
        nominationsOverview?: number[], nominationOverview?: number, nominationsBatch?: number[],
        nominationBatch?: number, balancingsAuction?: number[], balancingAuction?: number,
        balancingsContinuous?: number[], balancingContinuous?: number, auctionsOverview?: number[], auctionOverview?: number
    ) {
        this.balancings = balancings;
        this.markets = markets;
        this.assets = assets;
        this.balancing = balancing;
        this.market = market;
        this.asset = asset;
        this.gridpoints = gridpoints;
        this.balancingsDeal = balancingsDeal;
        this.balancingDeal = balancingDeal;
        this.nominationsRealTimeMessages = nominationsRealTimeMessages;
        this.nominationRealTimeMessages = nominationRealTimeMessages;
        this.nominationsOverview = nominationsOverview;
        this.nominationOverview = nominationOverview;
        this.nominationsBatch = nominationsBatch;
        this.nominationBatch = nominationBatch;
        this.balancingsAuction = balancingsAuction;
        this.balancingAuction = balancingAuction;
        this.balancingsContinuous = balancingsContinuous;
        this.balancingContinuous = balancingContinuous;
        this.auctionsOverview = auctionsOverview;
        this.auctionOverview = auctionOverview;

    }
}
