<nav>
    <ez-ul>
        <ez-li *ngFor="let menu of menus" #categElement [class.current-menu]="isActiveMenu(menu)">
            <ng-container *ngIf="menu.submenu then withSubmenu else withoutSubmenu" ></ng-container>
            
            <ng-template #withSubmenu>
                <div class="categ" (click)="toggleMenu(categElement)" [routerPage]="menu.page"
                    [class.current]="isActiveMenu(menu) && menu.subMenu == null">
                    <ez-icon class=" cat-icon" [name]="menu.icon">
                    </ez-icon>
                    <span>{{ 'main.'+menu.categorie+'.label' | translate }}</span>
                    <ez-icon class="btn-toggle" [name]="'angle-down'" *ngIf="menu.submenu != null"></ez-icon>
                    <!-- <div class="linear-sel"></div> -->
                </div>

                <ez-ul class="sub-menu">
                    <ez-li *ngFor="let subMenu of menu.submenu" [class.current]="isActiveItem(subMenu)">
                        <a [routerPage]="subMenu.page" [disabled]="isActiveItem(subMenu)">
                            <ez-icon [name]="subMenu.icon"></ez-icon>
                            <span>{{ 'main.pageName.'+subMenu.name | translate }}</span>
                            <div class="bg-sel"></div>
                        </a>
                    </ez-li>
                </ez-ul>
            </ng-template>
            <ng-template #withoutSubmenu>
                <a class="categ" (click)="toggleMenu(categElement)" [routerPage]="menu.page"
                    [class.current]="currentCateg === menu && menu.subMenu == null">
                    <ez-icon class=" cat-icon" [name]="menu.icon">
                    </ez-icon>
                    <span>{{ 'main.'+menu.categorie+'.label' | translate }}</span>
                    <ez-icon class="btn-toggle" [name]="'angle-down'" *ngIf="menu.submenu != null"></ez-icon>
                    <!-- <div class="linear-sel"></div> -->
                </a>
            </ng-template>
        </ez-li>
    </ez-ul>
    <div class="logo" >
        <img [src]="''|StaticLogoUri:32" [routerPage]="appPage.Dashboard" *ngIf="!(environmentDisplay$ | async )" />
        <span class="name-env"  *ngIf="(environmentDisplay$ | async ) as environmentDisplay" >
            {{environmentDisplay.name | slice:0: environmentDisplay.maxLength}}
        </span>
    </div>
</nav>