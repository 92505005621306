// @ts-nocheck
import { DateTime, JsonProperty, ITimespan } from '@eztypes/generic';
import { Injectable } from '@angular/core';
import * as _ez from "@eznergy/core";
import * as _ from 'lodash';
import { AppConfig } from './app-config.service';

interface IStore {
    object: string;
    validity: DateTime;
}

class Store<T> implements IStore {
    object: string;
    @JsonProperty({ type: DateTime })
    validity: DateTime;

    constructor(object?: T, validity?: DateTime) {
        if (object)
            this.object = _ez.serialize(object);
        this.validity = validity;
    }
}

export enum TypeCache {
    Session = 0,
    Persistent = 1,
    Permanent = 2
}

enum TypeExpiration {
    Absolut,
    Sliding
}


export enum KeyCache {
    AbTesting = "abt",
    Language = "lang",
    UserPreference = "usr-pref",
    UserVersion="usr-ui",
    SchemeToken = "ast",
    SchemeLogin = "asl",
    ReturnUrl = "return-url"
}

class CacheInfo {
    readonly key: KeyCache | string;
    readonly type: TypeCache;
    readonly expirationType: TypeExpiration;
    readonly timeValidity: ITimespan;

    constructor(key: KeyCache | string, type: TypeCache, expirationType: TypeExpiration = TypeExpiration.Absolut, timeValidity?: ITimespan) {
        this.key = key;
        this.type = type;
        this.expirationType = expirationType;
        this.timeValidity = timeValidity;
    }
}

const CacheInfos: CacheInfo[] = [
    new CacheInfo(KeyCache.AbTesting, TypeCache.Session),
    new CacheInfo(KeyCache.Language, TypeCache.Permanent),
    new CacheInfo(KeyCache.UserPreference, TypeCache.Permanent),
    new CacheInfo(KeyCache.UserVersion, TypeCache.Permanent),
    new CacheInfo(KeyCache.SchemeToken, TypeCache.Session),
    new CacheInfo(KeyCache.ReturnUrl, TypeCache.Session),   
];

@Injectable({ providedIn: 'root' })
export class CacheService {

    constructor(private readonly _config: AppConfig) { }

    get<T>(key: KeyCache | string, type?: (new () => T)): T {
        const info: CacheInfo = this._getInfo(key);
        if (info == null) {
            return undefined;
        }
        const objStore: Store<T> = this._get(info);
        if (objStore == null) {
            return undefined;
        }
        return _ez.deserialize<T>(objStore.object, type) as T;
    }

    getArray<T>(key: KeyCache | string, type?: (new () => T)): T[] {
        const info: CacheInfo = this._getInfo(key);
        if (info == null) {
            return undefined;
        }
        const objStore: Store<T> = this._get(info);
        if (objStore == null) {
            return undefined;
        }
        return _ez.deserialize<T>(objStore.object, type) as T[];
    }

    set<T>(key: KeyCache | string, object: T, type?: TypeCache): void {
        const info: CacheInfo = this._getInfo(key, type);
        if (info == null) {
            throw new Error(`Cache service get key info not found (${key})`);
        }
        let validityDate: DateTime;
        if (info.timeValidity != null) {
            if (info.expirationType === TypeExpiration.Absolut) {
                const oldValue = this._get(info);
                validityDate = oldValue != null ? oldValue.validity : DateTime.now().add(info.timeValidity);
            } else {
                validityDate = DateTime.now().add(info.timeValidity);
            }
        }
        const store = new Store(object, validityDate);
        const objString: string = _ez.serialize(store);
        const fullKey: string = this._getFullKey(info.key);
        switch (info.type) {
            case TypeCache.Session:
                sessionStorage.setItem(fullKey, objString);
                break;
            case TypeCache.Persistent:
            case TypeCache.Permanent:
                localStorage.setItem(fullKey, objString);
                break;
        }
    }

    remove(key: KeyCache | string): void {
        const info: CacheInfo = this._getInfo(key);
        this._remove(info);
    }

    clear(type: TypeCache = TypeCache.Persistent): void {
        _.forEach(CacheInfos, (info: CacheInfo) => {
            if (info.type <= type) {
                this._remove(info);
            }
        });
    }

    private _get<T>(info: CacheInfo): Store<T> {
        let objString: string;
        const fullKey: string = this._getFullKey(info.key);
        switch (info.type) {
            case TypeCache.Session:
                objString = sessionStorage.getItem(fullKey);
                break;
            case TypeCache.Persistent:
            case TypeCache.Permanent:
                objString = localStorage.getItem(fullKey);
                break;
        }
        let obj = _ez.deserialize<Store<T>>(objString, Store) as Store<T>;
        if (!obj) {
            return undefined;
        }
        if (info.timeValidity != null && obj.validity && obj.validity.diff(DateTime.now()).totalMilliseconds < 0) {
            this.remove(info.key);
            return undefined;
        }
        return obj;
    }

    private _remove(info: CacheInfo): void {
        const fullKey: string = this._getFullKey(info.key);
        switch (info.type) {
            case TypeCache.Session:
                sessionStorage.removeItem(fullKey);
                break;
            case TypeCache.Persistent:
            case TypeCache.Permanent:
                localStorage.removeItem(fullKey);
                break;
        }
    }

    private _getFullKey(key: KeyCache | string): string {
        return `ezops_${this._config.environment.name}_${key}`;
    }

    private _getInfo(key: KeyCache | string, type?: TypeCache): CacheInfo {
        let info: CacheInfo = _.find(CacheInfos, (ci: CacheInfo) => ci.key === key);
        if (info == null && type != null) {
            info = new CacheInfo(key, type);
            CacheInfos.push(info);
        }
        return info;
    }

}
