import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormatAcknowledgedPipe } from "@pipes/format-acknowledged.pipe";
import { FormatNominationCurveType } from "@pipes/format-nomination-curve-type.pipe";
import { FormatNominationDetailDirectionPipe } from "@pipes/format-nomination-detail-direction.pipe";
import { FormatNominationDirectionPipe } from "@pipes/format-nomination-direction.pipe";
import { TimeFormattingPipe } from "@pipes/format-time.pipe";
import { EzTimerPipe } from '@pipes/timer.pipe';
import { UserRightPipe } from "@pipes/user-right.pipe";
import { StaticService } from "@services/static.service";
import { HumanizeTimespanPipe } from "./humanize-timespan.pipe";
import { TimezonePipe } from './timezone.pipe';

@NgModule({
    imports: [],
    declarations: [
        FormatAcknowledgedPipe,
        FormatNominationCurveType,
        FormatNominationDetailDirectionPipe,
        FormatNominationDirectionPipe,
        UserRightPipe,
        TimeFormattingPipe,
        EzTimerPipe,
        TimezonePipe,
        HumanizeTimespanPipe,
    ],
    exports: [
        FormatAcknowledgedPipe,
        FormatNominationCurveType,
        FormatNominationDetailDirectionPipe,
        FormatNominationDirectionPipe,
        UserRightPipe,
        TimeFormattingPipe,
        EzTimerPipe,
        TimezonePipe,
        HumanizeTimespanPipe,
    ],
    providers: [
        FormatNominationCurveType,
        FormatNominationDetailDirectionPipe,
        FormatNominationDirectionPipe,
        FormatAcknowledgedPipe,
        TimeFormattingPipe,
        StaticService,
        TimezonePipe,
        HumanizeTimespanPipe,
    ],
})
export class AppPipesModule {
    static forRoot(): ModuleWithProviders<AppPipesModule> {
        return {
            ngModule: AppPipesModule,
            providers: [
                FormatNominationCurveType,
                FormatNominationDetailDirectionPipe,
                FormatNominationDirectionPipe,
                FormatAcknowledgedPipe,
                TimeFormattingPipe,
                StaticService,
                HumanizeTimespanPipe,
            ],
        };
    }
}
