import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '@eznergy/webapi';
import {
    GridTypeCode,
    IAuctionGridDetails,
    IAuctionsAvailableConfiguration,
    IAvailableConfiguration,
    IAvailableMarket,
    IGrid,
    IGridRelationship,
    IMarketConfiguration,
    UserRight,
    GridByShipper,
    IOrganisation,
    IGridsIdsByShipper
} from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, withLatestFrom, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { IContractUserRights } from '@models/core/contract-user-rights.model';

@Injectable({ providedIn: 'root' })
export class GridsService implements OnDestroy {

    get areas(): IGrid[] {
        return this._areas.value;
    }
    get areasChanges(): Observable<IGrid[]> {
        return this._areas;
    }
    private readonly _areas: BehaviorSubject<IGrid[]> = new BehaviorSubject<IGrid[]>([]);

    get grids(): IGrid[] {
        return this._grids.value;
    }
    get gridsChanges(): Observable<IGrid[]> {
        return this._grids;
    }
    private readonly _grids: BehaviorSubject<IGrid[]> = new BehaviorSubject<IGrid[]>([]);

    get assetGrids(): IGrid[] {
        return this._assetGrids.value;
    }
    get assetGridsChanges(): Observable<IGrid[]> {
        return this._assetGrids;
    }
    private readonly _assetGrids: BehaviorSubject<IGrid[]> = new BehaviorSubject<IGrid[]>([]);

    get allGrids(): IGrid[] {
        return this._allGrids.value;
    }
    get allGridsChanges(): Observable<IGrid[]> {
        return this._allGrids;
    }
    private readonly _allGrids: BehaviorSubject<IGrid[]> = new BehaviorSubject<IGrid[]>([]);

    get gridRelationships(): IGridRelationship[] {
        return this._gridRelationShips.value;
    }
    get gridRelationshipChanges(): Observable<IGridRelationship[]> {
        return this._gridRelationShips;
    }
    private _gridRelationShips = new BehaviorSubject<IGridRelationship[]>([]);

    get changes(): Observable<void> {
        return this._subChanges.pipe(debounceTime(1000));
    }
    private readonly _subChanges: Subject<void> = new Subject();

    get gridsByShipper(): IGridsIdsByShipper[]  {
        const gridsByShipper = this._gridsByShipper?.map(
            ({shipperId, grids}) => ({
                shipperId,
                gridsIds: grids.map(grid => grid.id)
            })
        ) as IGridsIdsByShipper[];
        return gridsByShipper;
    }
    private _gridsByShipper: GridByShipper[] | undefined;

    private _subscriptions = new Subscription();

    constructor(
        private readonly _api: ApiService,
        private readonly _appSvc: ApplicationService
    ) {

        const subShipperChange = this._appSvc.shipperChange.subscribe(() => {
            this._allGrids.next([]);
        });
        this._subscriptions.add(subShipperChange);

        this._setupGridObservables();
    }

    public ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    private _getObservableGridsFromShipper(shipper: IOrganisation | undefined, contractInfo: IContractUserRights): Observable<IGrid[]> {
        if (this._appSvc.isMultiShipper) {
            const shipperIds = this._appSvc.shippers!.map(shipper => shipper.id);
            return this._api.topologies.grids.getAllShippersGrids(
                contractInfo!.contract.id, 
                shipperIds, 
                [GridTypeCode.codeBalancing], 
                undefined, 
                { withGridPoints: true }
            ).pipe(
                map((gridsByShipper: GridByShipper[]) => {
                    this._gridsByShipper = gridsByShipper;
                    const uniqueGridsSet = new Set<IGrid>();
                    gridsByShipper.forEach(gridShipper => 
                        gridShipper.grids.forEach(
                            grid => uniqueGridsSet.add(grid))
                    );
                    return [...uniqueGridsSet];
                })
            )
        } 
        // @ts-ignore
        return this._api.references.shippers.getAllGrids(contractInfo?.contract.id, shipper?.id, {
            gridTypes: [
                GridTypeCode.codeBalancing,
                GridTypeCode.codeNomination,
                GridTypeCode.codeProdNomination,
            ],
            withGeoData: true,
            withGridPoints: true,
        });
    }

    private _setupGridObservables(): void {
        // whenever the shipper changes

            const allGridsSub = this._appSvc.shipperChange
            // we want to load in the related grids
            .pipe(
                withLatestFrom(this._appSvc.contractChange),
                filter(([shipper, contractInfo]) => !!(shipper && contractInfo)),
                switchMap(([shipper, contractInfo]) => {
                    const grids: Observable<IGrid[]> = this._getObservableGridsFromShipper(shipper, contractInfo!)
                    const hasReadEventAuction = this._appSvc.isMultiShipper ? false : this._appSvc.hasRight(UserRight.EventAuctionRead);
                    const hasReadEventContinuous = this._appSvc.isMultiShipper ? false : this._appSvc.hasRight(UserRight.EventAutotradingRead);
                    // @ts-ignore
                    const marketConfiguration = this._api.markets.availableConfigurations(this._appSvc.contract?.id, {withAuction: hasReadEventAuction, withAutoTrading: hasReadEventContinuous})
                        .pipe(
                            map((marketConfiguration: IMarketConfiguration) => {
                                return {
                                    auctionConfigurations: marketConfiguration.auctionConfigurations?.filter((configuration) => configuration.shipper?.id === shipper?.id),
                                    autoTradingConfigurations: marketConfiguration.autoTradingConfigurations?.map((configuration) => 
                                        ({
                                            ...configuration,
                                            users: configuration.users.filter(({user}) => user.organisation.id === shipper?.id)
                                        })
                                    )
                                } as IMarketConfiguration;
                            }),
                            catchError(() => {
                                const marketconfig: IMarketConfiguration = {
                                    autoTradingConfigurations: [],
                                    auctionConfigurations: []
                                }
                                return of(marketconfig);
                            })
                        );

                    return combineLatest([marketConfiguration, grids]);
            })
            ).subscribe(([marketConfiguration, grids]) => {
                const auctionGridsData = this._filterAuctionGridsFromMarketconfig(marketConfiguration.auctionConfigurations);
                const continuousGridsData = this._filterContinuousFromMarketconfig(marketConfiguration.autoTradingConfigurations);
                this._appSvc.setMarketConfiguration(marketConfiguration);
                this._appSvc.setAuctionGrids(auctionGridsData);
                this._appSvc.setContinuousGrids(continuousGridsData);
                if (grids) {
                    this._appSvc.allGridsBalancing = grids.filter((g) => g.gridType.code === GridTypeCode.codeBalancing);
                    const allGrids = _.uniqBy([...grids, ...auctionGridsData, ...continuousGridsData], 'id').filter((grid) => grid.gridType.hasOwnProperty('code'));
                    this._allGrids.next(allGrids ?? []);
                    this._subChanges.next();
                }
            });

        // whenever the contract changes
        const gridRelationShipsSub = this._appSvc.contractChange
            // we want to load in the relationships between grids
            .pipe(
                filter((contractInfo) => !!contractInfo?.contract?.id),
                switchMap((contractInfo) => 
                    this._api.topologies.grids.getGridRelationships(contractInfo!.contract.id)
                )
            ).subscribe((gridRelationShips) => {
                this._gridRelationShips.next(gridRelationShips ?? []);
                this._subChanges.next();
            });

        // whenever the grids change
        const gridsPerType$ = this._allGrids
            // we want to provide the app with a separated list of grids
            .pipe(
                map((grids: IGrid[]) => {
                    const gridsPerType = new Map<GridTypeCode, IGrid[]>();
                    grids.forEach((grid) => {
                        const gridType = grid.gridType?.code;

                        let entry = gridsPerType.get(gridType);
                        if (!entry) {
                            gridsPerType.set(gridType, [grid]);
                            return;
                        }

                        entry.push(grid);
                    });

                    return gridsPerType;
                })
            );

        // predefined observables per type
        const areasSub = gridsPerType$
            .pipe(map((gridsPerType) => gridsPerType.get(GridTypeCode.codeBalancing)))
            .subscribe((areas) => {
                this._areas.next(areas ?? []);
                this._subChanges.next();
            });

        const gridsSub = gridsPerType$
            .pipe(map((gridsPerType) => gridsPerType.get(GridTypeCode.codeNomination)))
            .subscribe((grids) => {
                this._grids.next(grids ?? []);
                this._subChanges.next();
            });

        const assetGridsSub = gridsPerType$
            .pipe(map((gridsPerType) => gridsPerType.get(GridTypeCode.codeProdNomination)))
            .subscribe((assetGrids) => {
                this._assetGrids.next(assetGrids ?? []);
                this._subChanges.next();
            });

        this._subscriptions.add(allGridsSub);
        this._subscriptions.add(gridRelationShipsSub);
        this._subscriptions.add(areasSub);
        this._subscriptions.add(gridsSub);
        this._subscriptions.add(assetGridsSub);
    }

    private _filterAuctionGridsFromMarketconfig(auctionConfigurations: IAuctionsAvailableConfiguration[]): IGrid[] {
        const auctionGrids: IGrid[] = [];
        if (auctionConfigurations) {
            auctionConfigurations.forEach((availableConfig: IAuctionsAvailableConfiguration) => {
                const grids = availableConfig.grids?.flatMap((auctionGridDetails: IAuctionGridDetails)  => auctionGridDetails?.grid ?? []) ?? [];
                grids.forEach((gridItem: IGrid) => {
                    if (!auctionGrids.some((grid) => grid.id === gridItem?.id)) {
                        auctionGrids.push(gridItem);
                    }
                });
            });
        }
        return auctionGrids;
    }

    private _filterContinuousFromMarketconfig(autoTradingConfigurations: IAvailableMarket[]): IGrid[] {
        const continuousGrids: IGrid[] = [];
        if (autoTradingConfigurations) {
            autoTradingConfigurations.forEach((availableConfig: IAvailableMarket) => {
                const areas: IGrid[] = availableConfig.users?.flatMap((user: IAvailableConfiguration)  => user?.areas ?? []) ?? [];
                areas.forEach((area) => {
                    if (!continuousGrids.some((grid) => grid.id === area?.id)) {
                        continuousGrids.push(area);
                    }
                });
            });
        }
        return continuousGrids;
    }
}