// @ts-nocheck
import { IExchangeUser, Exchange as AtExchange } from "@eztypes/webapi";

export class Exchange {
    name: string;
    token: AtExchange;
    logoUrl: string;
    exchangeUsers: IExchangeUser[];
    constructor(token: AtExchange, logoUrl?: string, exchangeUsers?: IExchangeUser[]) {
        switch (token) {
            case AtExchange.EpexSpot:
                this.name = "EpexSpot";
                break;
            case AtExchange.NordPool:
                this.name = "NordPool";
                break;
            case AtExchange.PowerNext:
                this.name = "PowerNext";
                break;
        }
        this.token = token;
        this.logoUrl = logoUrl;
        this.exchangeUsers = exchangeUsers;
    }
}
