<div class="title">
    {{value?.shortName}}
</div>
<div class="row">
    <span class="label">{{'general.id' | translate}} : </span>
    <div class="value">
        <div class="value-clipboard" (click)="copyIdToClipboard()" [ezTooltip]="'general.clipboard-copy' | translate">
            <span>{{value?.id}}</span>
            <ez-icon name="copy" [regular]="true"></ez-icon>
        </div>
    </div>
</div>
<div class="row">
    <span class="label">{{'general.type' | translate }} : </span>
    <span class="value">{{value?.type?.name}}</span>
</div>