// @ts-nocheck
// Core
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DateRange, DateTime, ITimeRange, Timespan, TimeUnit } from "@eztypes/generic";
import { ITimeAndLocationRules, Product, SessionType, TimeAndLocationRules } from "@eztypes/webapi";
// models
import { FormBase } from "src/app/pages/form/core/form-base";
// ext
import * as _ from "lodash";
import { auditTime } from 'rxjs/operators';

class Period {
    date: DateTime;
    dates: DateRange[];
    constructor(date: DateTime) {
        this.date = date;
        this.dates = [];
    }
}

@Component({
    selector: 'at-time-and-location-form',
    templateUrl: './time-and-location.component.html',
    styleUrls: ['./time-and-location.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AtTimeAndLocationForm extends FormBase {

    @Output() valueChange: EventEmitter<ITimeAndLocationRules> = new EventEmitter<ITimeAndLocationRules>();
    @Output() paramRestartSession: EventEmitter<boolean> = new EventEmitter<boolean>();

    // INPUTS
    @Input()
    get sessionType(): SessionType {
        return this._sessionType;
    }
    set sessionType(value: SessionType) {
        if (this.sessionType !== value) {
            this._sessionType = value;
            this._resetInternalValues();
            this._cdr.markForCheck();
        }
    }
    private _sessionType: SessionType;

    @Input()
    get value(): ITimeAndLocationRules {
        return this._value;
    }
    set value(value: ITimeAndLocationRules) {
        if (value !== this.value) {
            this._value = value;
            this._resetInternalValues();
            this._cdr.markForCheck();
        }
    }
    private _value: ITimeAndLocationRules;

    @Input()
    get products(): Product[] {
        return this._products;
    }
    set products(value: Product[]) {
        if (this.products !== value) {
            this._products = value;
            this._cdr.markForCheck();
        }
    }
    private _products: Product[];
    // END INPUTS

    // INTERNAL ACCESSORS

    get selectedProduct(): Product {
        return this.internalParameters.product;
    }

    set selectedProduct(value: Product) {
        this.internalParameters.product = value;
        if ((this.internalParameters?.product !== this.value?.product) !== this._paramRestartSession) {
            this._paramRestartSession = this.internalParameters?.product !== this.value?.product;
            this.paramRestartSession.emit(this._paramRestartSession);
        }
    }

    set selectedRadio(value: number) {
        this._selectedRadio = value;
        this.internalParameters.pMinMax = value == 0 ? true : undefined;
    }
    get selectedRadio(): number {
        return this._selectedRadio;
    }
    private _selectedRadio: number = -1;

    get selectedContract(): ITimeRange[] {
        if (this._selectedContracts && this._selectedContracts.length) {
            return this._selectedContracts;
        }
    }
    set selectedContract(values: ITimeRange[]) {
        if (values && values.length)
            this._selectedContracts = values;
        else
            this._selectedContracts = undefined;
    }
    private _selectedContracts: ITimeRange[];


    get nearNextContract(): number {
        return this._nearNextContract;
    }
    set nearNextContract(value: number) {
        this._nearNextContract = value;
    }
    private _nearNextContract: number;

    datesTradesDeliveryPeriod: Period[];

    isConfirmModal: boolean = false;
    dateAddSelected: DateRange;
    timeStart: Date;
    timeEnd: Date;
    lastRangeAdd: DateRange;
    hasOpenFormTradedDay: boolean = false;
    // END INTERNAL ACCESSORS

    // VARS
    internalParameters: ITimeAndLocationRules = new TimeAndLocationRules();
    sessionTypes = SessionType;

    private _paramRestartSession: boolean = false;

    get isUpdateMode(): boolean {
        return this._isUpdateMode;
    }
    private _isUpdateMode: boolean = false;

    private _hasChangePeriodTrade: boolean = false;

    constructor(private _cdr: ChangeDetectorRef) {
        super();
        this.onCancel.subscribe(() => {
            this._resetInternalValues();
            this.hasOpenFormTradedDay = false;
            this._cdr.detectChanges();
        });

        this.onSubmit.pipe(auditTime(50)).subscribe(() => {
            this.hasOpenFormTradedDay = false;
            this._cdr.detectChanges();
        });
    }

    hasChanged(): boolean {
        if (this._hasChangePeriodTrade) {
            return true;
        }
        if (this.form && this.form.submit) {
            return this.form.hasChanged();
        }
        return undefined;
    }

    formSubmit() {
        if (this._selectedRadio != 3) {
            this.internalParameters.selectedContract = undefined;
        }
        this.internalParameters.nearNextContract = this._selectedRadio == 1 ? this._nearNextContract : undefined;
        this.internalParameters.selectedContract = this._selectedRadio == 3 ? this._selectedContracts : undefined;

        if (this.sessionType != SessionType.Generation) {
            // delete (this.internalParameters.pMinMax);
            this.internalParameters.pMinMax = undefined;
        }
        this._value = _.clone(this.internalParameters);
        this._resetInternalValues();
        this.valueChange.emit(this._value);
        this._hasChangePeriodTrade = false;
        this.onSubmit.emit();
    }

    get openingOffset(): Timespan {
        return this._openingOffset;
    }
    set openingOffset(value: Timespan) {
        if ((!this._openingOffset && value) || (this._openingOffset && value && value.totalTicks != this._openingOffset.totalTicks)) {
            this._openingOffset = value;
            this.internalParameters.openingOffset = value.totalMinutes;
        }
    }
    private _openingOffset: Timespan;

    get closingOffset(): Timespan {
        return this._closingOffset;
    }
    set closingOffset(value: Timespan) {
        if ((!this._closingOffset && value) || (this._closingOffset && value && value.totalTicks != this._closingOffset.totalTicks)) {
            this._closingOffset = value;
            this.internalParameters.closingOffset = value.totalMinutes;
        }
    }
    private _closingOffset: Timespan;

    // PRIVATE REGION
    // copy and convert external parameters to internal
    private _resetInternalValues() {
        this._hasChangePeriodTrade = false;
        this._paramRestartSession = false;
        this.viewUpdate = this._value != undefined;
        this._isUpdateMode = this._value != null;
        this.internalParameters = this._value ? _.clone(this._value) : new TimeAndLocationRules();
        this._openingOffset = !isNaN(this.internalParameters.openingOffset) ? new Timespan(this.internalParameters.openingOffset, TimeUnit.Minutes) : undefined;
        this._closingOffset = !isNaN(this.internalParameters.closingOffset) ? new Timespan(this.internalParameters.closingOffset, TimeUnit.Minutes) : undefined;
        this._selectedRadio = this._getSelectedRadioIndex();
        this._nearNextContract = this.internalParameters.nearNextContract;
        this._selectedContracts = this.internalParameters.selectedContract;
        this.prepareTradedDays();
    }

    // set selected radio button
    private _getSelectedRadioIndex(): number {
        let i: number;
        if (this.internalParameters && this.internalParameters.pMinMax) {
            i = 0;
        } else if (this.internalParameters && this.internalParameters.nearNextContract) {
            i = 1;
        } else if (this.internalParameters && this.internalParameters.selectedContract && this.internalParameters.selectedContract.length) {
            i = 3;
        } else {
            i = 2;
        }
        return i;
    }

    openFormAddTradedDay(): void {
        if (this.disabled)
            return;
        this.hasOpenFormTradedDay = true;
    }

    onAddDateRange(value: DateRange) {
        this.dateAddSelected = value;
        this.hasOpenFormTradedDay = false;
        this._cdr.detectChanges();
        this._addTradedDays(value);
        this.prepareTradedDays();
        this.dateAddSelected = undefined;
        this._hasChangePeriodTrade = true;
        this.onChange.emit(this._hasChangePeriodTrade);
        this._cdr.detectChanges();
    }

    private _addTradedDays(value: DateRange): void {
        this.lastRangeAdd = value;
        if (!this.internalParameters.tradedDays) {
            this.internalParameters.tradedDays = [value];
        } else {
            const timeStartValue: number = value.from.time;
            const timeEndValue: number = value.to.time;
            const mergeDays = _.filter(this.internalParameters.tradedDays, (d: DateRange) => {
                let dDay: DateTime = d.from.startOf(TimeUnit.Days);
                let valueDay = value.to.startOf(TimeUnit.Days);

                return dDay.isEqual(valueDay) && (
                    (d.from.time <= timeStartValue && d.to.time >= timeStartValue)
                    || (d.from.time <= timeEndValue && (d.to.time >= timeEndValue)
                        || (d.from.time >= timeStartValue && d.to.time <= timeEndValue))
                );
            });
            if (mergeDays && mergeDays.length > 0) {
                mergeDays.push(value);
                let newDays = _.over<number>(_.min, _.max)(_.flatMap(mergeDays, (d: DateRange) => {
                    return [d.from.time, d.to.time];
                }));
                _.remove(this.internalParameters.tradedDays, (d: DateRange) => {
                    return _.some(mergeDays, (ddel: DateRange) => { return d.from.time === ddel.from.time && d.to.time === ddel.to.time; });
                });
                this.lastRangeAdd = new DateRange(new DateTime(newDays[0]), new DateTime(newDays[1]));
                this.internalParameters.tradedDays.push(this.lastRangeAdd);
            } else {
                this.internalParameters.tradedDays.push(value);
                this.internalParameters.tradedDays = _.sortBy(this.internalParameters.tradedDays, (d: DateRange) => {
                    return d.from;
                });
            }
        }
    }

    private prepareTradedDays(): void {
        let periods: Period[] = [], lastDate: DateTime = undefined, period: Period = undefined;
        let dates = _.sortBy(this.internalParameters.tradedDays, ['startAt', 'endAt']);
        _.forEach(dates, (value: DateRange) => {
            let date = value.from.startOf(TimeUnit.Days);
            if (!lastDate || date.time !== lastDate.time) {
                period = new Period(date);
                periods.push(period);
                lastDate = date;
            }
            period.dates.push(value);
        });
        this.datesTradesDeliveryPeriod = periods;
    }

    deletePeriod(period: Period) {
        _.remove(this.internalParameters.tradedDays, (o: DateRange) => {
            let val = o.from.startOf(TimeUnit.Days);
            return val.time === period.date.time;
        });
        _.remove(this.datesTradesDeliveryPeriod, (p: Period) => {
            return p.date.time === period.date.time;
        });
        this.lastRangeAdd = undefined;
        this._hasChangePeriodTrade = true;
        this.onChange.emit(this._hasChangePeriodTrade);
    }

    deleteRange(range: DateRange) {
        _.remove(this.internalParameters.tradedDays, (o: DateRange) => {
            return o.from.isEqual(range.from) && o.to.isEqual(range.to);
        });

        const period = _.find(this.datesTradesDeliveryPeriod, (p: Period) => {
            let val = range.from.startOf(TimeUnit.Days);
            return p.date.isEqual(val);
        });
        if (period) {
            _.remove(period.dates, (o: DateRange) => {
                return o.from.isEqual(range.from) && o.to.isEqual(range.to);
            });
        }
        this.lastRangeAdd = undefined;
        this._hasChangePeriodTrade = true;
        this.onChange.emit(this._hasChangePeriodTrade);
    }

}
