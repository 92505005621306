export class TradingColors {
    // New Color
    static readonly borderColorInitial: string = "rgb(0, 0, 0)";
    static readonly bgColorInitial: string = "rgb(0, 0, 0)";

    static readonly borderColorCurrent: string = "rgb(243, 222, 130)";
    static readonly bgColorCurrent: string = "rgb(243, 222, 130)";

    static readonly borderColorPotential: string = "rgb(130, 130, 130)";
    static readonly bgColorPotential: string = "rgb(251, 245, 218)";
    static readonly bgColorPotentialNew: string = "rgba(0, 0, 0,0.3)";

    static readonly borderColorTradable: string = "rgb(255, 152, 0)";
    static readonly bgColorTradable: string = "rgb(255, 200, 120)";

    static readonly borderColorAsk: string = "rgb(13,71,161)";
    static readonly bgColorAsk: string = "rgb(136, 165, 210)";

    static readonly borderColorBid: string = "rgb(253, 50, 50)";
    static readonly bgColorBid: string = "rgb(253, 131, 131)";

    static readonly borderColorBest: string = "rgb(189,195,199)";
    static readonly bgColorBest: string = "rgb(220, 220, 220)";

    static readonly borderColorLimit: string = "rgb(50,50,50)";
    static readonly bgColorLimit: string = "rgb(100, 100, 100)";

    // Zones
    static readonly inactivatedZoneColor: string = "rgba(190, 190, 190 , 0.4)";
    static readonly closedZoneColor: string = "rgba(190, 190, 190 , 0.4)";
    static readonly otrErrorZoneColor: string = "rgba(249, 167, 167 , 0.4)";
    static readonly otrWarningZoneColor: string = "rgba(253, 201, 125 , 0.4)";
    static readonly selectedZoneColor: string = "rgba(101, 205, 252, 0.3)";

    // Function
    static addAlphaColor(color: string, opacity: number): string {
        return color.replace("rgb", "rgba").replace(")", "," + opacity + ")");
    }
}
