// @ts-nocheck
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";
import { IconSize } from '@enums/icon-size.enum';
import { IContract, ICountry, IGrid } from "@eztypes/webapi";
import { StaticService } from '@services/static.service';

@Pipe({ name: "StaticLogoUri" })
export class StaticLogoUri implements PipeTransform {

    constructor(private _static: StaticService) {
    }

    transform(_value: any, size?: IconSize): string {
        return this._static.logoUri(size);
    }
}

@Pipe({ name: "StaticLogoIUri" })
export class StaticLogoIUri implements PipeTransform {

    constructor(private _static: StaticService) {
    }

    transform(_value: any, size?: IconSize): string {
        return this._static.logoIUri(size);
    }
}


@Pipe({ name: "StaticContractLogoUri" })
export class StaticContractLogoUri implements PipeTransform {

    constructor(private _static: StaticService, private sanitizer: DomSanitizer) {
    }

    transform(contract: IContract, size?: IconSize): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this._static.contractLogoUri(contract, size));
    }
}

@Pipe({ name: "StaticExchangeUri" })
export class StaticExchangeUri implements PipeTransform {

    constructor(private _static: StaticService) {
    }

    transform(name: string, size?: IconSize): string {
        return this._static.exchangeUri(name, size);
    }
}

@Pipe({ name: "StaticGridUri" })
export class StaticGridUri implements PipeTransform {

    constructor(private _static: StaticService) {
    }

    transform(grid: string | IGrid, size?: IconSize): string {
        return this._static.gridUri(grid, size);
    }
}

@Pipe({ name: "StaticCountryUri" })
export class StaticCountryUri implements PipeTransform {

    constructor(private _static: StaticService) {
    }

    transform(country: string | ICountry, size?: IconSize): string {
        return this._static.countryUri(country, size);
    }
}

@Pipe({ name: "StaticTimeUri" })
export class StaticTimeUri implements PipeTransform {

    constructor(private _static: StaticService) {
    }

    transform(name: string, size?: IconSize): string {
        return this._static.timeUri(name, size);
    }
}

@Pipe({ name: "StaticMaterialUri" })
export class StaticMaterialUri implements PipeTransform {

    constructor(private _static: StaticService) {
    }

    transform(name: string, size?: IconSize): string {
        return this._static.materialUri(name, size);
    }
}
