// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseParameters, DataParamProperty, QueryParamMetaData, QueryParamProperty } from '@core';
import { Logger } from '@core/logger';
import * as _ez from "@eznergy/core";
import { isSame } from '@eznergy/core';
import { ApiService } from '@eznergy/webapi';
import { DateRange, DateTime, Dictionary, IDateRange, IDictionary, TimeUnit } from '@eztypes/generic';
import { IEtsArea, IEtsClient, IEtsPortfolio, IGrid } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import { IDataParams, IQueryParams, RouterService } from '@services/router.service';
import * as _ from "lodash";
import { concat, Observable } from 'rxjs';

export interface IEtsParameters extends IQueryParams {
    profil: IEtsClient;
    grid: IGrid;
    area: IEtsArea;
    portfolio: IEtsPortfolio;
}

class DataEtsParameters implements IDataParams {

    static keyProfil: string = "profil";
    static keyArea: string = "area";
    static keyPortfolio: string = "portfolio";

    @DataParamProperty({ key: DataEtsParameters.keyProfil, defaultType: "first" })
    profil: IEtsClient[];
    @DataParamProperty({ key: DataEtsParameters.keyArea, defaultType: 'first' })
    areaGrid: IEtsArea[];
    @DataParamProperty({ key: DataEtsParameters.keyPortfolio, defaultType: 'first', queryKey: 'profil' })
    portfolio: IDictionary<IEtsPortfolio[]>;

    constructor(
        profil: IEtsClient[],
        areaGrid: IEtsArea[],
        portfolio: IDictionary<IEtsPortfolio[]>
    ) {
        this.profil = profil;
        this.areaGrid = areaGrid;
        this.portfolio = portfolio;
    }
}

class EtsParameters implements IEtsParameters {
    @QueryParamProperty(new QueryParamMetaData(DataEtsParameters.keyProfil, 'id', false, Number))
    profil: IEtsClient;
    @QueryParamProperty(new QueryParamMetaData(DataEtsParameters.keyArea, 'id', false, Number))
    area: IEtsArea;
    @QueryParamProperty(new QueryParamMetaData(DataEtsParameters.keyPortfolio, 'id', false, Number))
    portfolio: IEtsPortfolio;
    grid: IGrid;

    constructor(
        profil?: IEtsClient,
        grid?: IGrid,
        area?: IEtsArea,
        portfolio?: IEtsPortfolio
    ) {
        this.profil = profil;
        this.grid = grid;
        this.area = area;
        this.portfolio = portfolio;
    }
}

@Component({
    selector: 'parameters-ets',
    templateUrl: './parameters-ets.component.html',
    styleUrls: ['./parameters-ets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParametersEts extends BaseParameters<DataEtsParameters, IEtsParameters> {

    @Output() parametersChange: EventEmitter<IEtsParameters> = new EventEmitter<IEtsParameters>();

    @Input()
    get isMarketResultsPanelActive(): boolean {
        return this._isMarketResultsPanelActive;
    }
    set isMarketResultsPanelActive(isDisabled: boolean) {
        this._isMarketResultsPanelActive = isDisabled;
        this._cdr.detectChanges();
    }
    private _isMarketResultsPanelActive: boolean = false;

    isLoading: boolean;

    private _profiles: IEtsClient[];
    private _allAreas: IEtsArea[] = [];
    private _areasByProfileGrid: IDictionary<IEtsArea[]> = new Dictionary<IEtsArea[]>();
    private _allPortfolios: IDictionary<IEtsPortfolio[]> = new Dictionary<IEtsPortfolio[]>();
    private _allGrids: IDictionary<IGrid[]> = new Dictionary<IGrid[]>();

    gridsData: IGrid[];
    areasData: IEtsArea[];
    portfoliosData: IEtsPortfolio[];

    constructor(
        private _cdr: ChangeDetectorRef,
        private _api: ApiService,
        private _appSvc: ApplicationService,
        router: RouterService,
        private _logger: Logger
    ) {
        super(router);

    }

    ngOnInit(): void {
        this.isLoading = true;
        this._loadData();
    }

    private _loadData(): void {
        this._subs.clearSub("etsClients");
        this._subs.clearSub("etsAreas");
        this._subs.clearSub("etsPortfolios");

        this._allAreas = [];
        this._allGrids = new Dictionary<IGrid[]>();
        this._areasByProfileGrid = new Dictionary<IEtsArea[]>();

        this._allPortfolios = new Dictionary<IEtsPortfolio[]>();

        let sub = this._api.ets.clients.getAll(this._appSvc.contract.id).subscribe(
            (profiles: IEtsClient[]) => {
                this._profiles = _.orderBy(profiles, (profile: IEtsClient) => { return profile.shipper.shortName; });
                let obsAreas: Observable<IEtsArea[]>[] = [];
                let obsPortfolios: Observable<IEtsPortfolio[]>[] = [];
                _.forEach(this._profiles, (profil: IEtsClient) => {
                    let obsArea = this._api.ets.areas.getAll(this._appSvc.contract.id, profil.id);
                    obsAreas.push(obsArea);
                    let obsPortfolio = this._api.ets.portfolios.getAll(this._appSvc.contract.id, profil.id);
                    obsPortfolios.push(obsPortfolio);
                });
                if (obsAreas == undefined || obsAreas.length == 0) {
                    this.isLoading = false;
                    this._cdr.detectChanges();
                    return;
                }

                let countEndRequest = 2;
                let countProfilArea = 0;
                let subArea = concat(...obsAreas).subscribe((areas: IEtsArea[]) => {
                    let profil = this._profiles[countProfilArea++];
                    let areasByGridId = _.groupBy(areas, (area: IEtsArea) => {
                        return area.grid.id;
                    });
                    let grids = _.sortBy(_.uniqBy(_.flatMap(areas, (area: IEtsArea) => { return area.grid; }), (grid: IGrid) => { return grid.id; }),
                        (grid: IGrid) => { return grid.country.name + " " + grid.name; });

                    this._allGrids.add(profil.id, _.cloneDeep(grids));

                    _.forEach(grids, (grid: IGrid) => {
                        let areas: IEtsArea[] = areasByGridId[grid.id];
                        if (areas) {
                            this._areasByProfileGrid.add(this._getKeyForAreasByProfil(profil, grid), areas);
                            this._allAreas.push(...areas);
                        }
                    });
                },
                    (error) => {
                        this.isLoading = false;
                        this._logger.error({ message: "error call api on getAll ets areas", data: this._appSvc.contract.id }, error);
                        this._cdr.detectChanges();
                    },
                    () => {
                        if (--countEndRequest === 0) {
                            this._prepareData();
                        }
                    });
                this._subs.push("etsAreas", subArea);
                let countProfilPortfolio = 0;
                let subPortfolio = concat(...obsPortfolios).subscribe((portfolios: IEtsPortfolio[]) => {
                    let profil = this._profiles[countProfilPortfolio++];
                    this._allPortfolios.add(profil.id, _.orderBy(portfolios, 'name'));
                },
                    (error) => {
                        this.isLoading = false;
                        this._logger.error({ message: "error call api on getAll ets portfolios", data: this._appSvc.contract.id }, error);
                        this._cdr.detectChanges();
                    }, () => {
                        if (--countEndRequest === 0) {
                            this._prepareData();
                        }
                    }
                );
                this._subs.push("etsPortfolios", subPortfolio);
            },
            (error) => {
                this.isLoading = false;
                this._logger.error({ message: "error call api on getAll ets clients", data: this._appSvc.contract.id }, error);
                this._cdr.detectChanges();
            }
        );
        this._subs.push("etsClients", sub);
    }

    private _prepareData(): void {
        this._params = new EtsParameters();
        this._data = new DataEtsParameters(this._profiles, this._allAreas, this._allPortfolios);
        this._initData();
    }

    private _currentProfil: IEtsClient;

    protected _initParams(): Promise<void> {
        super._initParams();

        return Promise.resolve();
    }

    protected _refreshParams(): void {
        this.isLoading = false;
        if (this._params.profil != null && !_ez.isSame(this._currentProfil, this._params.profil)) {
            this.gridsData = this._allGrids.get(this._params.profil.id);
            this.portfoliosData = this._allPortfolios.get(this._params.profil.id);
            this.areasData = this._areasByProfileGrid.get(this._getKeyForAreasByProfil(this._params.profil, this._params.grid));
        }
        if (this._params.grid != null) {
            this.areasData = this._areasByProfileGrid.get(this._getKeyForAreasByProfil(this._params.profil, this._params.grid));
        }
        this._cdr.detectChanges();
    }

    onChangeProfil(profil: IEtsClient): void {
        if (this._params != null) {
            this._params.profil = profil;
            this.gridsData = this._allGrids.get(this._params.profil.id);
            this.portfoliosData = this._allPortfolios.get(this._params.profil.id);
            this._emitParametersChanges.next();
        }
    }

    onChangeGrid(grid: IGrid): void {
        if (this._params != null) {
            this._params.grid = grid;
            if (this._params.grid) {
                this.areasData = this._areasByProfileGrid.get(this._getKeyForAreasByProfil(this._params.profil, this._params.grid));
            }
            this._emitParametersChanges.next();
        }
    }

    onChangeArea(area: IEtsArea): void {
        if (this._params != null) {
            this._params.area = area;
            this._emitParametersChanges.next();
        }
    }

    onChangePortfolio(portfolio: IEtsPortfolio): void {
        this._params.portfolio = portfolio;
        this._emitParametersChanges.next();
    }

    private _getKeyForAreasByProfil(profil: IEtsClient, grid: IGrid): string {
        return `${profil.id}_${grid?.id}`;
    }
}
