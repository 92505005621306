// @ts-nocheck
import { AfterContentInit, Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Optional, Output, Renderer2, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgControl } from "@angular/forms";
import { UnitFamiliesUmScore } from '@enums/unit-families-umscore.enum';
import { EzFormControl, EzFormControlPlaceholder, IEzFormControl } from '@eznergy/components';
import * as _ez from "@eznergy/core";
// Models
import { IPhysicalValue, IUnit, IUnitFamily, PhysicalValue } from "@eztypes/webapi";
import { DataService } from '@services/data.service';
import * as _ from 'lodash';

@Component({
    selector: 'ez-physical-value',
    templateUrl: './physical-value.component.html',
    styleUrls: ['./physical-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: EzFormControl, useExisting: PhysicalValueComponent }
    ]
})
export class PhysicalValueComponent extends EzFormControlPlaceholder implements IEzFormControl, AfterContentInit, OnDestroy {

    @Output() valueChange: EventEmitter<IPhysicalValue> = new EventEmitter<IPhysicalValue>();

    @ViewChild("input", { static: true }) private _input: HTMLInputElement;

    @Input()
    get value(): IPhysicalValue {
        return this._value;
    }
    set value(value: IPhysicalValue) {
        const newValue: IPhysicalValue = (value == null || (value.value == null && value.unit == null)) ? undefined : value;
        if (this.value !== newValue) {
            this._value = newValue;
            if (this._value) {
                this._valueSelected = this._value.value;
                this._unitSelected = this._value.unit || this._unitSelected;
            } else {
                this._valueSelected = undefined;
                // this._unitSelected = undefined;
            }
            this._checkValueSelected();
            this._cdr.markForCheck();
        }
    }
    private _value: IPhysicalValue;

    @Input()
    get unitFamily(): IUnitFamily {
        return this._unitFamily;
    }
    set unitFamily(value: IUnitFamily) {
        if (this.unitFamily !== value) {
            this._unitFamily = value;
            if (this.unitsVal) {
                this._filterUnits(this.unitsVal);
            }
            this._cdr.markForCheck();
        }
    }
    private _unitFamily: IUnitFamily;

    @Input()
    get familiesUmScore(): UnitFamiliesUmScore {
        return this._familiesUmScore;
    }
    set familiesUmScore(value: UnitFamiliesUmScore) {
        if (value !== this.familiesUmScore) {
            this._familiesUmScore = value;
            this._cdr.markForCheck();
        }
    }
    private _familiesUmScore: UnitFamiliesUmScore;

    @Input()
    get units(): IUnit[] {
        return this.unitsVal;
    }
    set units(value: IUnit[]) {
        if (this.units !== value) {
            this.unitsVal = value;
            if (this._unitFamily) {
                this._filterUnits(this.unitsVal);
            }
            this._cdr.markForCheck();
        }
    }
    unitsVal: IUnit[];

    @Input()
    get min(): number {
        return this.minVal;
    }
    set min(value: number) {
        if (this.min !== value) {
            this.minVal = value;
            this._cdr.markForCheck();
        }
    }
    minVal: number;

    @Input()
    get max(): number {
        return this.maxVal;
    }
    set max(value: number) {
        if (this.max !== value) {
            this.maxVal = value;
            this._cdr.markForCheck();
        }
    }
    maxVal: number;

    set internalUnit(value: IUnit) {
        if (value !== this._unitSelected) {
            this._unitSelected = value;
            this._checkValueSelected();
        }
    }
    get internalUnit(): IUnit {
        return this._unitSelected;
    }
    private _unitSelected: IUnit;

    set internalValue(value: number) {
        this._valueSelected = value;
        this._checkValueSelected();
    }
    get internalValue(): number {
        return this._valueSelected;
    }
    private _valueSelected: number;

    @HostListener("focusin", ['$event'])
    focusin(event: FocusEvent): void {
        super.focusin(event);
        if (event.target === this.element) {
            this.focus();
        }
    }

    @HostListener("focusout", ["$event"])
    focusout(event: FocusEvent): void {
        super.focusout(event);
    }


    constructor(
        renderer2: Renderer2,
        _elementRef: ElementRef,
        _cdr: ChangeDetectorRef,
        private readonly _dataSvc: DataService,
        @Attribute('tabindex') tabIndex: string,
        @Attribute('id') id: string,
        @Self() @Optional() ngControl: NgControl

    ) {
        super(renderer2, _elementRef, _cdr, ngControl, tabIndex, id || _.uniqueId("ez-physical-value-"));
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterContentInit() {
        this._setUnits();
    }

    ngOnDestroy() {
    }

    focus() {
        if (this._input) {
            this._input.focus();
        }
    }

    private _setUnits() {
        if (!this.unitsVal) {
            if (this._unitFamily || this.familiesUmScore != null) {
                this._filterUnits(this._dataSvc.units);
            } else {
                this.unitsVal = this._dataSvc.units;
            }
        }
    }

    private _filterUnits(units: IUnit[]) {
        let unitsArray = this.unitFamily != null ? _.filter(units, (u) => u.family.id == this.unitFamily.id) : _.filter(units, (u) => u.family.score === this.familiesUmScore);
        this.unitsVal = unitsArray;
        if (this._unitSelected && !_.some(this.unitsVal, (unit: IUnit) => { return unit.id === this._unitSelected.id; })) {
            this._unitSelected = undefined;
            this._cdr.detectChanges();
        } else if (!this._unitSelected) {
            this._unitSelected = _.first(this.unitsVal);
        }
    }

    private _checkValueSelected() {
        let hasChange = false;
        if (!this._unitSelected || this._valueSelected == null || isNaN(this._valueSelected)) {
            if (this._value != undefined) {
                this._value = undefined;
                hasChange = true;
            }
        } else {
            let val = new PhysicalValue(this._unitSelected, this._valueSelected);
            if (this._value == undefined || !_ez.isSame(this._value.unit, val.unit) || this._value.value != val.value) {
                this._value = val;
                hasChange = true;
            }
        }
        if (hasChange) {
            this._onChange(this._value);
            this.valueChange.emit(this._value);
        }
    }

    writeValue(value: any) {
        this.value = value;
        super.writeValue(this.value);
    }

}
