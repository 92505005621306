<ez-loader *ngIf="isLoading"></ez-loader>
<ez-form #form (onSubmit)="onValidateForm()" [autofocus]="false">
    <ez-label class="title">{{'nominations.schedules.create.forecasts.period-date-title' | translate }}</ez-label>
    <ez-form-field>
        <ez-datepicker class="period-date" [isRange]="true" [timezone]="timezone"
            [placeholder]="'nominations.schedules.create.forecasts.period' | translate" [ngModel]="periodDate"
            [displayDateTrigger]="triggerPeriodDisplay" (valueChange)="periodDateChange($event)">
        </ez-datepicker>
    </ez-form-field>

    <ez-label class="title">{{'nominations.schedules.create.forecasts.mode-title' | translate }}</ez-label>
    <ez-form-field>
        <ez-radio-group [required]="true" [(ngModel)]="selectedRadio" class="radio-group" [align]="'vertical'">
            <ez-radio [label]="'nominations.schedules.create.forecasts.sendDayAhead' | translate"
                [value]="Mode.DayBefore">
                <ez-timepicker [(value)]="date" [displayTz]="true" [disabled]="selectedRadio !== Mode.DayBefore">
                </ez-timepicker>
            </ez-radio>
            <ez-radio [label]="'nominations.schedules.create.forecasts.sendDay' | translate" [value]="Mode.Day">

                <ez-timepicker [(value)]="date" [displayTz]="true" [disabled]="selectedRadio !== Mode.Day">
                </ez-timepicker>
            </ez-radio>
            <ez-radio [label]="'nominations.schedules.create.forecasts.sendDayAfter' | translate"
                [value]="Mode.DayAfter">
                <ez-timepicker [(value)]="date" [displayTz]="true" [disabled]="selectedRadio !== Mode.DayAfter">
                </ez-timepicker>
            </ez-radio>
            <ez-radio [label]=" " [value]="Mode.On" class="datePicker">
                <ez-datepicker [timezone]="timezone" [value]="sendOnDate" [disabled]="selectedRadio !== Mode.On"
                    [withTime]="true" (valueChange)="sendDateChange($event)">
                </ez-datepicker>
            </ez-radio>
        </ez-radio-group>
    </ez-form-field>

    <ez-form-field>
        <ez-select [(ngModel)]="selectedGate" [placeholder]="'nominations.schedules.create.forecasts.gate' | translate">
            <ez-select-option *ngFor="let gate of gates" [value]="gate">{{ gate.name }}</ez-select-option>
        </ez-select>
    </ez-form-field>

    <div class="checkbox-input">
        <ez-checkbox [(checked)]="activateVersion"></ez-checkbox>
        <ez-form-field>
            <ez-input-number [(ngModel)]="version" [required]="true"
                [placeholder]="'nominations.schedules.create.forecasts.version' | translate"
                [disabled]="!activateVersion" [min]="minVersion" [max]="maxVersion">
            </ez-input-number>
        </ez-form-field>
    </div>

    <ez-form-field>
        <ez-select class="interface" [(ngModel)]="selectedInterfaces" [multiple]="true"
            [placeholder]="'nominations.schedules.create.forecasts.sendingProcess' | translate" [required]="true">
            <ez-select-option *ngFor="let interface of interfaces" [value]="interface">{{ interface.name }}
            </ez-select-option>
        </ez-select>
    </ez-form-field>
    <ez-footer>
        <ez-button [label]="'nominations.schedules.create.validate' | translate" [severity]="'primary'" ez-form-submit>
        </ez-button>
    </ez-footer>
</ez-form>
