import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EzNotificationService } from "@eznergy/toaster";
import { ERROR_KEY } from "@eznergy/webapi";
import { TranslateService } from "@ngx-translate/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {

    constructor(private _srvTranslate: TranslateService, private _toasterSvc: EzNotificationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                const errorKey: string = req.context.get(ERROR_KEY);
                if (errorKey) {
                    this._srvTranslate.get(errorKey).subscribe((translate) => {
                        this._toasterSvc.error(translate);
                        req.context.delete(ERROR_KEY);
                    });
                }
                return throwError(error);
            })
        );
    }
}
