import { registerLocaleData } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Logger } from '@core/logger';
import { ApiConfig, ApiService } from "@eznergy/webapi";
import { TranslateLoader } from '@ngx-translate/core';
import { AppConfig } from '@services/app-config.service';
import { CultureService } from '@services/culture.service';
import { EzHandleError } from 'src/app/handlers/ez-handle-error.handler';
import { EzMissingTranslationHandler } from 'src/app/handlers/ezmissingtranslationhandler.handler';
import { Observable } from 'rxjs';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';
export const locale = CultureService.getCultureByNavigator();

export function localeInitializer(logger: Logger): Promise<void> {
    const localeId = (locale && locale !== "de-CH" && locale.indexOf('-') >= 0) ? locale.substring(0, locale.indexOf('-')) : locale;
    return import(`/node_modules/@angular/common/locales/${localeId}.mjs`).then((module) => {
        logger.debug("Culture Angular : '" + localeId + "'");
        registerLocaleData(module.default);
    }).catch((err) => {
        logger.fatal("culture not defined for angular", err);
    });
    // AFTER GO JEAN
    // return import(`@angular/common/locales/${locale}.js`).then((module) => {
    //     logger.debug("Culture Angular : '" + locale + "'");
    //     registerLocaleData(module.default);
    // }).catch(() => {
    //     const localeId = locale.indexOf('-') >= 0 ? locale.substring(0, locale.indexOf('-')) : locale;
    //     return import(`@angular/common/locales/${localeId}.js`).then((module) => {
    //         logger.debug("Culture Angular : '" + localeId + "'");
    //         registerLocaleData(module.default);
    //     }).catch((err) => {
    //         logger.fatal("culture not defined for angular", err);
    //     });
    // });
}

export class EzTranslateLoader implements TranslateLoader {
    constructor(private http: HttpClient, private handler: HttpBackend, private _appConfig: AppConfig, private suffix: string = ".json") {
        this.http = new HttpClient(this.handler);
    }
    getTranslation(lang: string): Observable<any> {
        let t = Date.now();
        return this.http.get(`${this._appConfig.cdnUri}/i18n/langs/${lang}/${lang}${this.suffix}?t=` + t);
    }
}
export class AppApiConfig implements ApiConfig {
    readonly environment: string;
    readonly baseUri: string;
    readonly authConfig: OpenIdConfiguration;
    constructor(baseUri: string, environment: string, authConfig: OpenIdConfiguration) {
        this.baseUri = baseUri;
        this.environment = environment;
        this.authConfig = authConfig;
    }
}

export function initializeApp(appConfig: AppConfig, logger: Logger, injector: Injector): () => Promise<void> {
    return async () => {
        try {
            await appConfig.load();

            logger.configure(appConfig.logging);
            let api = injector.get(ApiService);
            logger.configureApi(api);

            await localeInitializer(logger);            
            injector.get(CultureService);            

        } catch (error: any) {
            logger.fatal(error.message, error);
        }
    }
}

export function HttpLoaderFactory(http: HttpClient, handler: HttpBackend, appConfig: AppConfig) {
    return new EzTranslateLoader(http, handler, appConfig);
}

export function ApiConfigFactory(appConfig: AppConfig) {
    return new AppApiConfig(appConfig.apiUri, appConfig.environment.name, appConfig.authConfig);
}

export function EzHandleErrorFactory(logger: Logger) {
    return new EzHandleError(logger);
}

export function EzMissingTranslationHandlerFactory() {
    return new EzMissingTranslationHandler();
}
