import { ApplicationRef, Injectable, Injector, createComponent } from '@angular/core';
import { ConfirmUnsavedComponent } from '@components/overlay/confirm-unsaved/confirm-unsaved.component';
import { isObservable, Observable } from 'rxjs';
import { isPromise } from 'rxjs/internal/util/isPromise';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard {
    canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
        return component && component.canDeactivate ? component.canDeactivate() : true;
    }
}

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateUnsavedConfirmGuard {
    constructor(
        private readonly _injector: Injector,
        private readonly _appRef: ApplicationRef
    ) { }

    canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
        if (component == null || typeof component.canDeactivate !== "function") {
            return true;
        }

        return new Promise((resolve, reject) => {
            const can = component.canDeactivate();
            if (typeof can === "boolean") {
                this._openConfirmModal(can, resolve, reject);
            } else if (isObservable(can)) {
                can.subscribe((value) => {
                    this._openConfirmModal(value, resolve, reject);
                });
            } else if (isPromise(can)) {
                can.then((value) => {
                    this._openConfirmModal(value, resolve, reject);
                });
            } else {
                reject(new Error("Unknow type return in canDeactivate"));
            }
        });

    }

    private _openConfirmModal(can: boolean, resolve: (value: boolean | PromiseLike<boolean>) => void, reject: (errorReason: any) => void): void {
        if (can) {
            resolve(true);
        } else {
            const confirm = createComponent(ConfirmUnsavedComponent, {
                environmentInjector: this._injector.get(ApplicationRef).injector,
            });
            this._appRef.attachView(confirm.hostView);
            confirm.instance.open = true;
            confirm.instance.confirm.asObservable().subscribe((value) => {
                resolve(value);
                this._appRef.detachView(confirm.hostView);
                confirm.destroy();
            }, (error) => {
                reject(error);
                this._appRef.detachView(confirm.hostView);
                confirm.destroy();
            });
        }
    }
}