// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation, ElementRef, Attribute, Self, Optional, HostListener, Renderer2 } from '@angular/core';
import { Subscriptions } from '@core';
import * as _ez from "@eznergy/core";
import { IGroup } from '@eztypes/webapi';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { EzFormControl, IHasPlaceholder, IEzFormControl, EzFormControlPlaceholder } from '@eznergy/components';
import { NgControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

class GroupView {
    readonly label: IGroup;

    get textOrder(): string {
        return this._textOrder;
    }
    private _textOrder: string;

    get checked(): boolean {
        return this._checked;
    }
    private _checked: boolean = false;

    get status(): GroupSelectionStatus {
        return this._status;
    }
    private _status: GroupSelectionStatus = GroupSelectionStatus.None;
    private _originalStatus: GroupSelectionStatus = GroupSelectionStatus.None;
    private _isIntdeterminated: boolean;
    get hasChange(): boolean {
        return this._hasChange;
    }
    private _hasChange: boolean;
    constructor(label: IGroup, status?: GroupSelectionStatus) {
        this.label = label;
        if (status) {
            this.setOriginalStatus(status);
        }
    }

    setOriginalStatus(status: GroupSelectionStatus): void {
        this._originalStatus = status;
        this._status = status;
        this._isIntdeterminated = this.status === GroupSelectionStatus.Partial;
        this._textOrder = "";
        switch (this._status) {
            case GroupSelectionStatus.Full:
                this._textOrder += "__";
                break;
            case GroupSelectionStatus.Partial:
                this._textOrder += "_";
                break;
        }
        this._textOrder += this.label.path;
        this._textOrder = _.deburr(this._textOrder.toLowerCase());
        this._hasChange = false;
        this._setChecked();
    }

    updateStatus(active: boolean): void {
        if (active && this._isIntdeterminated && this._status === GroupSelectionStatus.None) {
            this._status = GroupSelectionStatus.Partial;
        } else {
            this._status = active ? GroupSelectionStatus.Full : GroupSelectionStatus.None;
        }
        this._hasChange = this._status !== this._originalStatus;
        this._setChecked();
    }

    undo(): void {
        this._status = this._originalStatus;
        this._hasChange = false;
        this._setChecked();
    }

    private _setChecked(): void {
        switch (this._status) {
            case GroupSelectionStatus.Full:
                this._checked = true;
                break;
            case GroupSelectionStatus.Partial:
                this._checked = undefined;
                break;
            case GroupSelectionStatus.None:
                this._checked = false;
                break;
        }
    }
}

export enum GroupSelectionStatus {
    Full = "full",
    Partial = "partial",
    None = "none"
}

export class GroupSelection {
    group: IGroup;
    status: GroupSelectionStatus;
    constructor(group?: IGroup, status?: GroupSelectionStatus) {
        this.group = group;
        this.status = status;
    }
}

@Component({
    selector: 'ez-groups-select',
    templateUrl: './groups-select.component.html',
    styleUrls: ['./groups-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: EzFormControl, useExisting: GroupsSelectComponent }
    ],
})
export class GroupsSelectComponent extends EzFormControlPlaceholder implements IEzFormControl, IHasPlaceholder {

    @Output() readonly valueChange: EventEmitter<GroupSelection[]> = new EventEmitter();
    @Output() readonly cancel: EventEmitter<void> = new EventEmitter();

    @Input()
    get groups(): IGroup[] {
        return this._groups;
    }
    set groups(value: IGroup[]) {
        if (value !== this.groups) {
            this._groups = value;
            this._createData();
            this._cdr.markForCheck();
        }
    }
    private _groups: IGroup[];

    @Input()
    get value(): GroupSelection[] {
        return this._value;
    }
    set value(value: GroupSelection[]) {
        if (this.value !== value) {
            this._value = value;
            this._setSelection();
            this._cdr.markForCheck();
        }
    }
    private _value: GroupSelection[];

    @Input()
    get autoApply(): boolean {
        return this._autoApply;
    }
    set autoApply(value: boolean) {
        if (this.autoApply !== value) {
            this._autoApply = value;
            this._cdr.markForCheck();
        }
    }
    private _autoApply: boolean = false;

    @Input()
    get multiple(): boolean {
        return this._multiple;
    }
    set multiple(value: boolean) {
        const newValue = _ez.coerceBoolean(value, true);
        if (this.multiple !== newValue) {
            this._multiple = newValue;
            this._cdr.markForCheck();
        }
    }
    private _multiple: boolean = true;

    @HostListener("focusin", ["$event"])
    focusin(event: FocusEvent): void {
        this._focusin(event);
    }

    @HostListener("focusout", ["$event"])
    focusout(event: FocusEvent): void {
        this._focusout(event);
    }

    dataView: GroupView[] = [];
    private _allData: GroupView[] = [];

    private _filter: string;
    private readonly _subFilter: Subject<string> = new Subject();
    private _subs: Subscriptions = new Subscriptions();

    get hasChange(): boolean {
        return this._hasChange;
    }
    private _hasChange: boolean;

    constructor(
        renderer2: Renderer2,
        cdr: ChangeDetectorRef,
        elementRef: ElementRef<HTMLElement>,
        @Attribute('tabindex') tabIndex: string,
        @Attribute('id') id: string,
        @Self() @Optional() ngControl: NgControl) {
        super(renderer2, elementRef, cdr, ngControl, tabIndex, id);

    }

    ngOnInit(): void {
        super.ngOnInit();
        this._orderByView();
        this._filterView();
        const subFilter = this._subFilter.pipe(debounceTime(300)).subscribe((filter: string) => {
            if (filter !== this._filter) {
                this._filter = filter != null && filter.length > 0 ? filter.toLowerCase() : undefined;
                this._filterView();
            }
        });
        this._subs.push("filter", subFilter);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
        this._subFilter.complete();
    }

    private _createData(): void {
        this._allData = [];
        if (this.groups) {
            const labelsView: GroupView[] = [];
            const hasSelection: boolean = this.value != null && this.value.length > 0;
            _.forEach(this.groups, (label: IGroup) => {
                const selection: GroupSelection = hasSelection ? _.find(this.value, (gs: GroupSelection) => _ez.isSame(gs.group, label)) : undefined;
                labelsView.push(new GroupView(label, selection ? selection.status : undefined));
            });
            this._allData = labelsView;
            this._orderByView();
            this._filterView();
        }
    }

    private _setSelection(): void {
        _.forEach(this._allData, (gv: GroupView) => {
            const selection: GroupSelection = _.find(this.value, (gs: GroupSelection) => _ez.isSame(gs.group, gv.label));
            gv.setOriginalStatus(selection ? selection.status : GroupSelectionStatus.None);
        });
        this._hasChange = _.some(this._allData, (gv: GroupView) => gv.hasChange);
        this._orderByView();
        this._filterView();
    }

    private _orderByView(): void {
        this._allData = _.orderBy(this._allData, (gpv: GroupView) => gpv.textOrder, "asc");
    }

    private _filterView(): void {
        if (this._filter != null && this._filter !== "") {
            this.dataView = _.filter(this._allData, (data: GroupView) => data.label.path.toLowerCase().indexOf(this._filter) !== -1);
        } else {
            this.dataView = this._allData;
        }
        this._cdr.detectChanges();
    }

    private _cleanSelection(): void {
        _.forEach(this._allData, (gv: GroupView) => {
            if (gv.status !== GroupSelectionStatus.None) {
                gv.setOriginalStatus(GroupSelectionStatus.None);
            }
        });
    }

    onFilterChange(event: string): void {
        this._subFilter.next(event);
    }

    onCheckboxChange(value: boolean, groupView: GroupView): void {
        if (!this.multiple) {
            this._cleanSelection();
        }
        groupView.updateStatus(value);
        if (this.autoApply) {
            this.submit();
            groupView.setOriginalStatus(groupView.status);
        } else {
            this._hasChange = _.some(this._allData, (gv: GroupView) => gv.hasChange);
        }
    }

    undoChanges(): void {
        _.forEach(this._allData, (gv: GroupView) => gv.undo());
        this.cancel.emit();
    }

    submit(): void {
        if (this.autoApply || this.hasChange) {
            const selection: GroupSelection[] = !this.multiple ? [] : (this._value || []);
            _.forEach(this._allData, (gv: GroupView) => {
                if (gv.hasChange) {
                    const sel: GroupSelection = _.find(selection, (s: GroupSelection) => _ez.isSame(s.group, gv.label));
                    if (sel) {
                        sel.status = gv.status;
                    } else {
                        selection.push(new GroupSelection(gv.label, gv.status));
                    }
                }
                gv.setOriginalStatus(gv.status);
            });
            this._value = selection.length > 0 ? selection : undefined;
            if (!this.autoApply) {
                this._setSelection();
            }
            this.valueChange.emit(this._value);
        }
    }

    writeValue(value: GroupSelection[]): void {
        this.value = value;
        super.writeValue(this.value);
    }
}
