// @ts-nocheck
import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ChangeDetectorRef, ComponentRef, Directive, ElementRef, Inject, Input, NgZone, Renderer2, ViewContainerRef } from '@angular/core';
import { ActionType, EzIconComponent, EzOverlayOrigin, EzTooltip, HorizontalPosition, Overlay, PositionAlign, VerticalPosition } from '@eznergy/components';
import { CurveBase, DateCurve, DecimalCurve, Grid, GridPoint, Organisation, StringCurve } from '@eztypes/webapi';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseCardPopoverComponent } from './base-card.component';
import { GridPointCardPopover } from './grid-point/grid-point.card.popover';
import { GridCardPopover } from './grid/grid.card.popover';
import { OrganisationCardPopover } from './organisation/organisation.card.popover';
import { TimeserieCardPopover } from './timeserie/timeserie.card.popover';

@Directive({
    selector: '[popoverCard]'
})
export class PopoverCardDirective<T> {

    private static _textTooltip: string;

    @Input()
    get popoverCard(): T {
        return this._popoverCard;
    }
    set popoverCard(value: T) {
        if (this.popoverCard !== value) {
            this._popoverCard = value;
            this._init();
            this._cdr.markForCheck();
        }
    }
    private _popoverCard: T;

    private _iconRef: ComponentRef<EzIconComponent>;
    private _cardRef: ComponentRef<BaseCardPopoverComponent<any>>;
    private _tooltipIcon: EzTooltip;
    private _subPopoverClose: Subscription;

    private _isDisplay: boolean = false;

    constructor(
        private readonly _el: ElementRef<HTMLElement>,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _renderer: Renderer2,
        private readonly _appRef: ApplicationRef,
        private readonly _overlay: Overlay,
        private readonly _ngZone: NgZone,
        @Inject(DOCUMENT) private readonly _document: any,
        private readonly _translateSvc: TranslateService,
        private readonly _viewContainerRef: ViewContainerRef
    ) {}

    ngOnDestoy(): void {
        this._hideCard();
        if (this._iconRef != null) {
            this._iconRef.destroy();
        }
        if (this._tooltipIcon != null) {
            this._tooltipIcon.ngOnDestroy();
        }

    }

    private _init(): void {
        if (this._el != null && this.popoverCard != null) {
            this._createIcon();
            this._createTooltip();
            this._renderer.addClass(this._el.nativeElement, 'info-card');
        }
    }

    private _displayCard(): void {
        this._createCard();
        this._renderer.addClass(this._iconRef.location.nativeElement, 'info-selected');
        this._tooltipIcon.ezTooltip = '';
        this._cardRef.instance.popover.open = true;
        this._appRef.attachView(this._cardRef.hostView);
        this._isDisplay = true;
    }

    private _hideCard(): void {
        this._renderer.removeClass(this._iconRef.location.nativeElement, 'info-selected');
        if (this._cardRef != null) {
        }
        if (this._subPopoverClose != null) {
            this._subPopoverClose.unsubscribe();
            this._subPopoverClose = undefined;
        }
        if (this._cardRef != null) {
            this._appRef.detachView(this._cardRef.hostView);
            this._cardRef.destroy();
            this._cardRef = undefined;
        }
        this._isDisplay = false;
    }

    private _createIcon(): void {
        this._iconRef = this._viewContainerRef.createComponent(EzIconComponent);
        this._iconRef.instance.name = 'info-circle';
        this._el.nativeElement.appendChild(this._iconRef.location.nativeElement);
        this._renderer.addClass(this._iconRef.location.nativeElement, 'icon-p-card');
        this._renderer.listen(this._iconRef.location.nativeElement, 'click', (event: MouseEvent) => {
            event.stopPropagation();
            if (this._isDisplay === false) {
                this._displayCard();
            }
        });
        this._iconRef.changeDetectorRef.detectChanges();
    }

    private _createTooltip(): void {
        this._tooltipIcon = new EzTooltip(this._iconRef.location, this._overlay, this._ngZone, this._document);
        if (PopoverCardDirective._textTooltip == null) {
            this._translateSvc.get("general.click-info").subscribe((text) => {
                PopoverCardDirective._textTooltip = text;
                this._tooltipIcon.ezTooltip = PopoverCardDirective._textTooltip;
            });
        } else {
            this._tooltipIcon.ezTooltip = PopoverCardDirective._textTooltip;
        }
    }

    private _createCard(): void {
        if (this._cardRef == null) {
            switch (true) {
                case this.popoverCard instanceof Grid:
                    this._cardRef = this._viewContainerRef.createComponent(GridCardPopover);
                    break;
                case this.popoverCard instanceof GridPoint:
                    this._cardRef = this._viewContainerRef.createComponent(GridPointCardPopover)
                    break;
                case this.popoverCard instanceof Organisation:
                    this._cardRef = this._viewContainerRef.createComponent(OrganisationCardPopover)
                    break;
                case this.popoverCard instanceof CurveBase:
                case this.popoverCard instanceof DecimalCurve:
                case this.popoverCard instanceof DateCurve:
                case this.popoverCard instanceof StringCurve:
                    this._cardRef = this._viewContainerRef.createComponent(TimeserieCardPopover)
                    break;
            }
            this._cardRef.instance.value = this.popoverCard as any;
            this._cardRef.instance.popover.actionType = ActionType.click;
            this._cardRef.instance.popover.positionAlign = new PositionAlign(VerticalPosition.Bottom, HorizontalPosition.Left);
            this._cardRef.instance.popover.origin = new EzOverlayOrigin(this._iconRef.location);
            this._subPopoverClose = this._cardRef.instance.popover.openChange.subscribe((isOpen) => {
                if (!isOpen) {
                    this._hideCard();
                }
            });
        }

    }
}
