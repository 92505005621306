<ez-form (onSubmit)="formSubmit()" ez-overlay-origin #originLoader="EzOverlayOrigin" [autofocus]="false">
    <ez-loader *ngIf="isLoading" [origin]="originLoader"></ez-loader>

    <div class="container-input">
        <ez-form-field class="input entity">
            <ez-select [ngModel]="internalDeal.entity" [placeholder]="'balancing.deals.entity' | translate"
                [required]="true" (valueChange)="onChangeShipper($event)" [readonly]="true">
                <ez-select-option *ngFor="let shipper of shippers" [value]="shipper" [autocomplete]="true">
                    {{shipper?.shortName}}
                </ez-select-option>
            </ez-select>
        </ez-form-field>

        <ez-form-field class="input">
            <ez-input-text [placeholder]="'balancing.deals.externId' | translate" [(ngModel)]="internalDeal.externId"
                [disabled]="internalDeal.id != undefined">
            </ez-input-text>
        </ez-form-field>
        <ez-form-field>
            <ez-datepicker class="date-execution" [withTime]="true" [(ngModel)]="internalDeal.executionDate"
                [disabled]="!internalDeal?.grid" [required]="true"
                [placeholder]="'balancing.deals.executionDate' | translate" [timezone]="timezone">
            </ez-datepicker>
        </ez-form-field>
    </div>

    <ez-form-group>
        <ez-label>{{ 'balancing.deals.editForm.dealParameters' | translate }}</ez-label>
        <div class="container-input deal">
            <ez-form-field class="input">
                <ez-select [ngModel]="internalDeal.commodity" (valueChange)="onChangeMaterial($event)"
                    [autocomplete]="true" [placeholder]="'balancing.deals.commodity' | translate"
                    [disabled]="!internalDeal.entity" [required]="true">
                    <ez-select-option *ngFor="let item of materialsEnable" [value]="item" class="img-align">
                        <commodity-icon [value]="item.code"></commodity-icon>
                        {{'globalTrad.'+item.code.toLowerCase() | translate}}
                    </ez-select-option>
                </ez-select>
            </ez-form-field>

            <ez-form-field class="input">
                <ez-select [ngModel]="internalDeal.grid" (valueChange)="onChangeGrid($event)" [autocomplete]="true"
                    [placeholder]="'balancing.deals.TSO' | translate" [disabled]="!internalDeal.commodity"
                    [required]="true">
                    <ez-select-option *ngFor="let item of gridsEnable" [value]="item" class="img-align">
                        <img [src]="item.gridLogo.icon | StaticGridUri" alt="">
                        {{item.name}}
                    </ez-select-option>
                </ez-select>
            </ez-form-field>

            <ez-form-field>
                <ez-select [ngModel]="internalDeal.gridPoint" (valueChange)="onChangeGridPoint($event)"
                    [autocomplete]="true" [placeholder]="'balancing.deals.gridPoint' | translate"
                    [disabled]="!internalDeal.grid" [required]="true">
                    <ez-select-option *ngFor="let grid of gridPointsEnable" [value]="grid">
                        {{grid.shortName}}</ez-select-option>
                </ez-select>
            </ez-form-field>
        </div>

        <div class="container-input">
            <ez-form-field class="input">
                <ez-select [ngModel]="internalDeal.counterpart" (valueChange)="onChangeCounterpart($event)"
                    [autocomplete]="true" [placeholder]="'balancing.deals.counterpart' | translate"
                    [disabled]="!internalDeal.gridPoint" [required]="true">
                    <ez-select-option *ngFor="let item of counterPartsEnable" [value]="item">
                        {{item.shortName}}</ez-select-option>
                </ez-select>
            </ez-form-field>
            <ez-form-field>
                <ez-select [placeholder]="'balancing.deals.direction' | translate" [autocomplete]="true"
                    (valueChange)="onChangeDirection($event)" [disabled]="!internalDeal.counterpart" [required]="true"
                    [ngModel]="internalDeal.direction">
                    <ez-select-option *ngFor="let item of directionEnable" [value]="item">
                        {{ "balancing.deals.directionCodes." + item.code | translate }}</ez-select-option>
                </ez-select>
            </ez-form-field>
        </div>
        <div class="container-input">
            <ez-form-field class="input">
                <ez-datepicker class="input start-delivery" [withTime]="true"
                    [(ngModel)]="internalDeal.beginningOfDelivery" [disabled]="!internalDeal?.grid"
                    [placeholder]="'balancing.deals.editForm.beginningOfDelivery' | translate"
                    [ez-maxDate]="internalDeal.endOfDelivery" [required]="true"
                    [ez-minDate]="counterpartDeliveryStartDate" [ez-minDateEqual]="true" [timezone]="timezone">
                </ez-datepicker>
                <ez-message-error [validator]="'maxDate'"
                    [message]="'balancing.deals.editForm.error.maxDate' | translate"></ez-message-error>
                <ez-message-error [validator]="'minDate'"
                    [message]="'balancing.deals.editForm.error.counterpartMinDate' | translate:{counterpartDeliveryStartDate: counterpartDeliveryStartDate | ezdate}">
                </ez-message-error>
            </ez-form-field>
            <ez-form-field>
                <ez-datepicker class="input end-delivery" [withTime]="true" [(ngModel)]="internalDeal.endOfDelivery"
                    [placeholder]="'balancing.deals.editForm.endOfDelivery' | translate"
                    [disabled]="!internalDeal?.grid" [ez-minDate]="internalDeal.beginningOfDelivery" [required]="true"
                    [ez-maxDate]="counterpartDeliveryEndDate" [ez-maxDateEqual]="true" [timezone]="timezone">
                </ez-datepicker>
                <ez-message-error [validator]="'minDate'"
                    [message]="'balancing.deals.editForm.error.minDate' | translate"></ez-message-error>
                <ez-message-error [validator]="'maxDate'"
                    [message]="'balancing.deals.editForm.error.counterpartMaxDate' | translate:{counterpartDeliveryEndDate: counterpartDeliveryEndDate | ezdate}">
                </ez-message-error>
            </ez-form-field>
        </div>
        <div class="container-input">
            <ez-form-field class="input">
                <ez-select [(ngModel)]="internalDeal.shape" [placeholder]="'balancing.deals.profile' | translate">
                    <ez-select-option *ngFor="let profile of profiles" [value]="profile">{{profile.name}}
                    </ez-select-option>
                </ez-select>
            </ez-form-field>
            <ez-form-field>
                <ez-select [(ngModel)]="internalDeal.portfolio" [placeholder]="'balancing.deals.portfolio' | translate"
                    [disabled]="!(portfolios?.length > 0)">
                    <ez-select-option *ngFor="let portfolio of portfolios" [value]="portfolio">
                        {{portfolio.name}}</ez-select-option>
                </ez-select>
            </ez-form-field>
        </div>
    </ez-form-group>

    <div class="container-input vol-price">
        <ez-form-group>
            <ez-label>{{ 'balancing.deals.editForm.power' | translate }}</ez-label>
            <div [hidden]="isAddingEnergyCurve">
                <div class="container-input">
                    <ez-form-field class="input">
                        <ez-physical-value [ngModel]="volume" [min]="0" [units]="energyUnits"
                            [placeholder]="'balancing.deals.fixedVolume' | translate"
                            [required]="!internalDeal.variableVolume"
                            [disabled]="!!internalDeal.variableVolume && !volume"
                            (valueChange)="onChangeVolume($event)"></ez-physical-value>
                    </ez-form-field>
                </div>
                <div class="container-input">
                    <ez-form-field class="with-btn">
                        <ez-select-ts [type]="CurveType.Decimal"
                            [placeholder]="'balancing.deals.variableVolume' | translate"
                            [(ngModel)]="internalDeal.variableVolume" [unitFamily]="familyUnitPower"
                            [required]="!volume" [disabled]="!!volume && !internalDeal.variableVolume">
                        </ez-select-ts>
                    </ez-form-field>
                    <ez-button [icon]="'plus'" [disabled]="!hasCurveCreateRight || (!!volume && !internalDeal.variableVolume)" (onClick)="onAddEnergyCurveClicked()"
                        [small]="true" [ezTooltip]="'balancing.deals.editForm.addCurveTooltip' | translate">
                    </ez-button>
                </div>
            </div>
            <div [hidden]="!isAddingEnergyCurve">
                <ez-add-curve [unitsToUse]="energyUnits" [curveTypeToForce]="'DECIMAL'"
                    (onCurveCreateResponse)="onCurveEnergyAdded($event)">
                </ez-add-curve>
            </div>
        </ez-form-group>

        <ez-form-group>
            <ez-label>{{ 'balancing.deals.editForm.price' | translate }}</ez-label>
            <div [hidden]="isAddingPriceCurve">
                <div class="container-input">
                    <ez-form-field class="input">
                        <ez-physical-value [ngModel]="price" [min]="0" [units]="energyPriceUnits"
                            [placeholder]="'balancing.deals.fixedPrice' | translate"
                            (valueChange)="onChangePrice($event)"></ez-physical-value>
                    </ez-form-field>
                </div>
                <div class="container-input">
                    <ez-form-field class="with-btn">
                        <ez-select-ts [type]="CurveType.Decimal"
                            [placeholder]="'balancing.deals.variablePrice' | translate"
                            [(ngModel)]="internalDeal.variablePrice" [unitFamily]="familyUnitPrice">
                        </ez-select-ts>
                    </ez-form-field>
                    <ez-button [icon]="'plus'" [disabled]="!hasCurveCreateRight" (onClick)="onAddPriceCurveClicked()"
                        [small]="true" [ezTooltip]="'balancing.deals.editForm.addCurveTooltip' | translate">
                    </ez-button>
                </div>
            </div>
            <div [hidden]="!isAddingPriceCurve">
                <ez-add-curve [unitsToUse]="energyPriceUnits" [curveTypeToForce]="'DECIMAL'"
                    (onCurveCreateResponse)="onCurvePriceAdded($event)">
                </ez-add-curve>
            </div>
        </ez-form-group>
    </div>

    <ez-form-field class="container-text">
        <ez-input-text class="input-text" type="textarea" [placeholder]="'balancing.deals.comment' | translate"
            [ngModel]="internalDeal.comment" (valueChange)="internalDeal.comment = $event"
            [maxlength]="maxNumberOfCommentCharacters">
        </ez-input-text>
    </ez-form-field>
    <ez-footer>
        <ez-button ez-form-cancel [label]="'globalTrad.cancel' | translate"></ez-button>
        <ez-button ez-form-submit [disabled]="(isEditMode && !hasEditDealRight) || (!isEditMode && !hasCreateDealRight)"
            [label]="(isEditMode ? 'general.modify' : 'general.save') | translate">
        </ez-button>
    </ez-footer>
</ez-form>
<ez-modal [(open)]="openConfirm" (onClose)="onConfirmClickButton($event)">
    <ez-header>
        {{'general.confirmModalTitle' | translate}}
    </ez-header>
    <ez-content>
        {{'general.confirmModalContent' | translate}}
    </ez-content>
    <ez-footer>
        <ez-button [label]="'general.cancel' | translate" ez-modal-close></ez-button>
        <ez-button [label]="'general.confirm' | translate" [severity]="'primary'" [ez-modal-close]="true">
        </ez-button>
    </ez-footer>
</ez-modal>
