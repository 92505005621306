<div class="container">
    <div class="title">
        <div class="text">
            <span>{{withGP ? 'Grid Points' : (type === gridTypes.codeNomination ? 'Grids' : 'Areas')}}</span>
            <span class="counter">({{sizeSelection}})</span>
        </div>
        <div *ngIf="hasMultiple" class="toggle-selection">
            <ez-checkbox [indeterminate]="true" [checked]="hasSelection"
                (checkedChange)="toggleAllGridsSelection($event)">
            </ez-checkbox>
        </div>
        <div class="btn-view">
            <div class="btn-options">
                <ez-icon [name]="'list'" [class.selected]="typeDisplay === displayTypes.Mini"
                    (click)="typeDisplayTypeChange(displayTypes.Mini)"></ez-icon>
                <ez-icon [name]="'th-list'" [class.selected]="typeDisplay === displayTypes.Normal"
                    (click)="typeDisplayTypeChange(displayTypes.Normal)"></ez-icon>
                <ez-icon *ngIf="!withGP" [name]="'th'" [class.selected]="typeDisplay === displayTypes.Tiles"
                    (click)="typeDisplayTypeChange(displayTypes.Tiles)"></ez-icon>
            </div>
        </div>
    </div>
    <div class="content">
        <ng-container *ngIf="data?.length === 0; else dataContent">
            <div class="info-no-data">
                <div>No grids selected.</div>
                <div class="info-check">Please check your commodities, countries and areas filters</div>
            </div>
        </ng-container>
        <ng-template #dataContent>
            <ez-loader *ngIf="data == null"></ez-loader>
            <ez-ul *ngIf="typeDisplay !== displayTypes.Tiles; else tilesContainer">
                <ez-li *ngFor="let d of data; trackBy:countryTrackBy" [class.open]="d.opened">
                    <div class="country">
                        <div class="country-info">
                            <img [src]="d.country | StaticCountryUri:32" />
                            <span>{{d.country.name}}</span>
                            <ez-checkbox *ngIf="hasMultiple" [indeterminate]="true" [checked]="d.hasSelection"
                                (checkedChange)="toggleCountrySelection(d, $event)"
                                [ezTooltip]="(d.hasSelection ? 'general.unselect-all' : 'general.select-all') | translate">
                            </ez-checkbox>
                        </div>
                        <ez-icon [name]="'angle-down'" (click)="toggleDisplayCountry(d)"></ez-icon>
                    </div>
                    <ez-ul class="grids">
                        <ez-li *ngFor="let gv of d.grids; trackBy: gridTrackBy" [class.opened]="gv.opened">
                            <div class="content" [class.selected]="gv.selected" (click)="toggleGridSelected(gv)"
                                [autofocus]="gv.autofocus">
                                <div class="btn-toggle-display">
                                    <ez-icon
                                        *ngIf="withGP && gv.gridpoints != null && gv.gridpoints.children?.length > 0"
                                        name="angle-right" class="btn-icon"
                                        (click)="toggleDisplayGrid(gv, undefined, $event)"></ez-icon>
                                </div>
                                <!-- <ez-checkbox *ngIf="withGP" class="selectable-action" [checked]="gv.selected"
                            (click)="toggleGridSelected(gv)" [indeterminate]="true">
                        </ez-checkbox> -->
                                <div class="grid-v">
                                    <div class="img-grid">
                                        <img [src]="gv.grid | StaticGridUri:sizeIcon" />
                                    </div>
                                    <span [ezTooltip]="gv.grid.name" [tooltipDelayShow]="800"
                                        [tooltipAlign]="'right'">{{gv.grid.name}}</span>
                                    <commodity-icon [value]="gv.grid.material.code"></commodity-icon>
                                </div>
                            </div>
                            <ez-ul class="grid-points"
                                *ngIf="withGP && gv.gridpoints != null && gv.gridpoints.children?.length > 0">
                                <ng-container
                                    *ngTemplateOutlet="gpvtpl; context:{ $implicit: gv.gridpoints.children, grid: gv, parent:gv.gridpoints   }">
                                </ng-container>
                            </ez-ul>
                        </ez-li>
                    </ez-ul>
                </ez-li>
            </ez-ul>
        </ng-template>
    </div>
</div>
<div class="resize-band r-right" *ngIf="!hideResize" (mousedown)="resize($event, 'right')" (dblclick)="resizeDefault()">
    <div class="hand-grip"></div>
</div>

<ng-template #tilesContainer>
    <div class="tiles-container">
        <div class="grid-tile" *ngFor="let gv of grids; trackBy: gridTrackBy" [ezTooltip]="gv.grid.name"
            [tooltipDelayShow]="400" (click)="toggleGridSelected(gv)" [class.selected]="gv.selected">
            <div class="header">
                <img class="country" [src]="gv.grid.country | StaticCountryUri:32" />
                <div [ngSwitch]="gv.grid.material.code" class="material">
                    <power-icon *ngSwitchCase="materialCode.Power"></power-icon>
                    <gas-icon *ngSwitchCase="materialCode.Gaz"></gas-icon>
                </div>
            </div>
            <div class="body">
                <img [src]="gv.grid | StaticGridUri:sizeIcon" />
            </div>
            <div class="footer">
                {{gv.grid.name}}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #gpvtpl let-gps let-grid="grid">
    <ez-li class="gridpoint-v" *ngFor="let gp of gps; trackBy: gridPointTrackBy" [class.opened]="gp.opened"
        [class.vip]="gp.hasVirtual" [class.has-not-cp]="!(gp.hasCps || gp.hasCpsInChild)">
        <div class="content" [ngClass]="{'selected': gp.selected}" (click)=" toggleGridPointSelected(gp,grid)">
            <div class="btn-toggle-display">
                <ez-icon *ngIf="gp.children?.length > 0" name="angle-right" class="btn-icon"
                    (click)="toggleDisplayGridPoint(gp, undefined, $event)"></ez-icon>
            </div>
            <ez-checkbox class="selectable-action" [checked]="gp.selected" [indeterminate]="true">
            </ez-checkbox>
            <div class="name">
                {{ gp.gridPoint.shortName }}
            </div>
            <div class="options" *ngIf="withGPData && !gp.hasVirtual" (click)="$event.stopPropagation()">
                <gp-data-selector [gridpoint]="gp.gridPoint" [counterparts]="gp.cps" [selection]="gp.cpSelection"
                    (selectionChange)="counterpartSelectionChange(grid, gp, $event)"></gp-data-selector>
            </div>
        </div>
        <ez-ul class="grid-points" *ngIf="gp.children?.length > 0">
            <ng-container *ngTemplateOutlet="gpvtpl; context:{ $implicit: gp.children, grid: grid  }">
            </ng-container>
        </ez-ul>
    </ez-li>
</ng-template>