import { Routes } from "@angular/router";
import { AuctionSchedulesPagePopup } from './auction/schedule/components/forms/auction-schedule-popup.component';
/************** End Pages ************/
import { DealPopupPage } from './popups/deal-popup/deal-popup-page.component';
import { AppEventsPage } from '@pages/events/events.page';
// Help
import {HelpComponent} from '@pages/help/help.component';
import { CreateSessionPage } from './autotrading/components/create/create-session.page';
import { SessionParametersPage } from './autotrading/components/parameters/session-parameters.component';
import { AtReports } from './autotrading/components/reports/reports.component';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { ContractRouterService } from "src/app/resolvers/contract.resolver";
import { RightsRouterService } from "src/app/resolvers/rights.resolver";
import { AppHeaderComponent } from "src/app/templates/header/header.component";
import { AppMenuComponent } from "src/app/templates/menu/menu.component";
// Assets
import {EtsPageComponent} from '../pages/balancing/ets/ets.component';
/****************************************
 *                 Pages                *
 ****************************************/
// Dashboard
import { DashboardPage } from "../pages/dashboard/dashboard.component";
// Systems
import {PageNotFoundComponent} from '../pages/systems/pageNotFound/page-not-found.component';
import {PageOfflineComponent} from '../pages/systems/pageOffline/page-offline.component';
// User
// Interfaces
import { contractGuidMatcher } from "./routing-utils";
import { LandingComponent } from 'src/app/templates/landing/landing.component';
// Administration
import { multiShipperGuard } from "../guards/multi-shipper.guard";
import { AuthorizationGuard } from "../guards/authorization.guard";
import { PageLogOutComponent } from "@pages/systems/page-log-out/page-log-out.component";
import { LoginComponent } from "@pages/users/login/login.component";


export const appRoutes: Routes = [
    {
        path: "login",
        data: { fullscreen: true, popup: true },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: "identifier"
            },
            {
                path: ':step',
                component: LoginComponent,
            }
        ]
    },
    { path: 'logout', component: PageLogOutComponent },
    { path: 'offline', component: PageOfflineComponent },
    {
        path: 'events',
        canActivate: [RightsRouterService],
        component: AppEventsPage,
        canDeactivate: [CanDeactivateGuard],
        outlet: "popup"
    },
    {
        path: 'session/:id/parameters',
        canActivate: [RightsRouterService],
        component: SessionParametersPage,
        canDeactivate: [CanDeactivateGuard],
        outlet: "popup"
    },
    {
        path: 'session/:id/parameters/:tab',
        canActivate: [RightsRouterService],
        component: SessionParametersPage,
        canDeactivate: [CanDeactivateGuard],
        outlet: 'popup',
    },
    {
        path: 'deals/:shipperId/:id/:gridid',
        canActivate: [multiShipperGuard, AuthorizationGuard, RightsRouterService],
        component: DealPopupPage,
        canDeactivate: [CanDeactivateGuard],
        outlet: 'popup',
    },
    {
        path: 'session/:id/reports/:mode',
        canActivate: [RightsRouterService],
        canDeactivate: [CanDeactivateGuard],
        component: AtReports,
        outlet: 'popup',
    },
    {
        path: 'help/:mode',
        canActivate: [RightsRouterService],
        canDeactivate: [CanDeactivateGuard],
        component: HelpComponent,
        outlet: 'aside'
    },
    {
        path: 'create-session',
        canActivate: [RightsRouterService],
        canDeactivate: [CanDeactivateGuard],
        component: CreateSessionPage,
        outlet: 'popup'
    },
    {
        path: 'create-session/:idDuplicate',
        canActivate: [RightsRouterService],
        canDeactivate: [CanDeactivateGuard],
        component: CreateSessionPage,
        outlet: 'popup'
    },
    {
        path: 'create-auction-schedule/:sh/:da/:scl',
        canActivate: [RightsRouterService],
        canDeactivate: [CanDeactivateGuard],
        component: AuctionSchedulesPagePopup,
        outlet: 'popup'
    },
    {
        path: 'create-auction-schedule/:sh/:da',
        canActivate: [RightsRouterService],
        canDeactivate: [CanDeactivateGuard],
        component: AuctionSchedulesPagePopup,
        outlet: 'popup'
    },
    { path: '', component: LandingComponent, pathMatch: 'full' },
    {
        matcher: contractGuidMatcher,
        canActivate: [AuthorizationGuard, ContractRouterService],
        children: [
            { path: "", component: AppHeaderComponent, outlet: "header" },
            { path: "", component: AppMenuComponent, outlet: "menu" },
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: 'dashboard',
                component: DashboardPage
            },
            {
                path: 'balancing',
                loadChildren: () => import('./balancing/balancing.module').then((m) => m.BalancingModule),
                canActivate: [RightsRouterService],
                data: { rightScreen: "SCREEN_OWBALANCING", helpDirectory: "balancing/overview/" }
            },
            {
                path: "balancing-overview",
                redirectTo: "balancing/overview"
            },
            {
                path: "balancing-positions",
                redirectTo: "balancing/positions"
            },
            {
                path: "map",
                redirectTo: "balancing/map"
            },
            {
                path: "balancing-deals",
                redirectTo: "balancing/deals"
            },
            {
                path: "balancing-ets",
                component: EtsPageComponent,
                canActivate: [multiShipperGuard, RightsRouterService],
                data: { rightScreen: "SCREEN_ETS", helpDirectory: "autotrading/ets/" }
            },
            {
                path: 'nominations',
                loadChildren: () => import('./nomination/nomination.module').then((m) => m.NominationModule),
                canActivate: [multiShipperGuard, RightsRouterService]
            },
            {
                path: "nominations-overview",
                redirectTo: "nominations/overview"
            },
            {
                path: "nominations-realtime",
                redirectTo: "nominations/realtime"
            },
            {
                path: "nominations-messages",
                redirectTo: "nominations/messages"
            },
            {
                path: "nominations-batch",
                redirectTo: "nominations/batch"
            },
            {
                path: "nominations-configuration",
                redirectTo: "nominations/configuration"
            },
            {
                path: 'assets',
                loadChildren: () => import('./assets/assets.module').then((m) => m.AssetsModule),
                canActivate: [multiShipperGuard, RightsRouterService]
            },
            {
                path: "assets-tsoactivation",
                redirectTo: "assets/tsoactivation"
            },
            {
                path: "assets-messages",
                redirectTo: "assets/messages"
            },
            {
                path: "assets-batch",
                redirectTo: "assets/batch"
            },
            {
                path: "timeseries",
                loadChildren: () => import('./timeseries/timeseries.module').then((m) => m.TimeseriesModule),
                canActivate: [multiShipperGuard, RightsRouterService]
            },
            {
                path: "timeseries-admin/:groupId",
                redirectTo: 'timeseries/admin/:groupId',
                pathMatch: 'full'
            },
            {
                path: "timeseries-edit-old",
                redirectTo: 'timeseries-edit',
                pathMatch: 'full'
            },
            {
                path: "timeseries-edit",
                redirectTo: 'timeseries/edit',
            },
            {
                path: "timeseries-group",
                redirectTo: 'timeseries-admin',
                pathMatch: 'full'
            },
            {
                path: "timeseries-computed",
                redirectTo: 'timeseries/computed',
            },

            {
                path: "configuration",
                loadChildren: () => import('./configuration/configuration.module').then((m) => m.ConfigurationModule),
                canActivate: [multiShipperGuard, RightsRouterService]
            },
            {
                path: "interface-files",
                redirectTo: 'configuration/files',
            },
            {
                path: "interface-schedules",
                redirectTo: 'configuration/schedules',
            },
            {
                path: "interface-counterpart",
                redirectTo: 'configuration/counterpart',
            },
            {
                path: "mapping",
                redirectTo: 'configuration/mapping',
            },
            {
                path: "admin",
                canActivate: [multiShipperGuard],
                loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)
            },
            {
                path: "users-overview",
                redirectTo: 'admin/users-overview',
            },
            {
                path: "autotrading",
                canActivateChild: [multiShipperGuard],
                loadChildren: () => import('./autotrading/autotrading.module').then((m) => m.AutotradingModule)
            },
            {
                path: 'auction',
                canActivateChild: [multiShipperGuard],
                loadChildren: () => import('./auction/auction.module').then((m) => m.AuctionModule)
            },
            { path: '**', component: PageNotFoundComponent }
        ]
    },

    { path: '**', component: PageNotFoundComponent },
];
