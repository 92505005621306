<ez-form (onSubmit)="formSubmit()">
    <ez-form-field>
        <ez-label>{{ 'autotrading.session.create.nemos.list' | translate }}</ez-label>
        <ez-image-selector [ngModel]="exchangeSelected" [required]="true" (valueChange)="exchangeChange($event)">
            <ez-image *ngFor="let exchange of exchanges" [url]="exchange.logoUrl" [value]="exchange"
                [name]="exchange.name"></ez-image>
        </ez-image-selector>
    </ez-form-field>
    <ez-form-field>
        <ez-label>{{ 'autotrading.session.create.nemos.users' | translate }}</ez-label>
        <ez-select [(ngModel)]="value.exchangeUser" [required]="true" [disabled]="!exchangeSelected">
            <ez-select-option *ngFor="let user of exUserList" [value]="user">{{user.name}}</ez-select-option>
        </ez-select>
    </ez-form-field>
    <ez-footer *ngIf="!hideFooter">
        <ez-button [label]="'general.cancel' | translate" ez-form-cancel [disabled]="disabled"></ez-button>
        <ez-button [label]="'general.next' | translate" [severity]="'primary'" ez-form-submit [disabled]="disabled">
        </ez-button>
    </ez-footer>
</ez-form>
