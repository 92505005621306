// @ts-nocheck
export enum TypeDisplayMenu {
    Normal = "normal",
    Mini = "mini"
}

export enum AppLanguages {
    En = "en",
    Fr = "fr"
}

export enum AppQuickDates {
    DayBefore = "D-1",
    Day = "D",
    DayAfter = "D+1",
    Custom = "Custom"
}

export enum AppCommodities {
    Power = "POWER",
    Gas = "GAZ"
}

export enum AppDisplayGridType {
    Mini = "mini",
    Normal = "normal",
    Tiles = "tiles"
}

export enum AppQueryParamsKey {
    /** Use only for generic date */
    Date = "date",
    /** Use only for grid controller */
    Grids = "gd",
    /** Use only for grid controller */
    GridPoints = "gp",
    /** Use only for grid controller */
    Deals = "dl",
    /** Use only for grid controller */
    Counterparts = "cp",
    /** Use only by generic shipper */
    Shipper = "sh",
    /** Use only by date controller */
    RangeDays = "rds",
    /** Use only by date controller */
    AlignDays = "ald",
    DatesSel = "dates-sel",
    /** Use for delivery date parameters */
    DateDeliverys = "ddel",
    WithDate = "wd",
    /** Use for IGranularity object */
    Granularity = "gn",
    Search = "s",
    Calendar = "cl",
    Timezone = "tz",
    /** Used for Auction */
    DeliveryArea = "da",
    Product = "prd",
    Template = "tpl",
    Schedule = "scl",
    List = "list",
    Send = "send",
    Compare = "cpr",
    /** Generic */
    MaxItems = "items",
    /** Used for Nominations */
    TypeMessage = "tpm",
    NominationState = "nst",
    ExcludedZero = "exz"
}

export enum DayAlignType {
    Start = "start",
    Middle = "middle",
    End = "end"
}

export enum DataDisplayType {
    Sheet,
    Chart
}

export enum SplitOrientation {
    Vertical = "vertical",
    Horizontal = "horizontal"
}

export enum ChartMode {
    Bottom = "bottom",
    Right = "right",
    None = "none"
}

