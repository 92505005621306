import { AuctionExchangeId, AuctionExchangeParam, AuctionExchangeToken } from '../models/parameters';
import { AuctionExchange } from '@eztypes/webapi';

export function getAuctionExchangeId(exchange: AuctionExchange): AuctionExchangeId {
    switch (exchange) {
        case AuctionExchange.ETS:
            return AuctionExchangeId.ETS;
        case AuctionExchange.NPAU:
        case AuctionExchange.NPDA:
            return AuctionExchangeId.NP;
        case AuctionExchange.EXAA:
            return AuctionExchangeId.EXAA;
    }
}

export function getAuctionExchangeParam(exchange: AuctionExchange): AuctionExchangeParam {
    switch (getAuctionExchangeId(exchange)) {
        case AuctionExchangeId.ETS:
            return { id: AuctionExchangeId.ETS, token: AuctionExchangeToken.ExpexSpot, name: exchange };
        case AuctionExchangeId.NP:
            return { id: AuctionExchangeId.NP, token: AuctionExchangeToken.NordPool, name: exchange };
        case AuctionExchangeId.EXAA:
            return { id: AuctionExchangeId.EXAA, token: AuctionExchangeToken.Exaa, name: exchange };
    }
}
