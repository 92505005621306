// @ts-nocheck
import { Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Optional, Output, Self, ViewEncapsulation, Renderer2 } from '@angular/core';
import { NgControl } from "@angular/forms";
// Components
import { EzFormControl, EzFormControlPlaceholder, IEzFormControl } from "@eznergy/components";
// Models
import { Timespan, TimeUnit } from "@eztypes/generic";

@Component({
    selector: 'ez-timespan',
    templateUrl: './timespan.component.html',
    styleUrls: ['./timespan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: EzFormControl, useExisting: TimespanComponent }
    ]
})
export class TimespanComponent extends EzFormControlPlaceholder implements IEzFormControl {

    @Output() readonly valueChange: EventEmitter<Timespan> = new EventEmitter<Timespan>();
    @Output() readonly unitUpdate: EventEmitter<TimeUnit> = new EventEmitter<TimeUnit>();

    @Input()
    get defaultUnit(): TimeUnit {
        return this._defaultUnit;
    }
    set defaultUnit(value: TimeUnit) {
        const newValue = value ? value : TimeUnit.Minutes;
        if (newValue !== this.defaultUnit) {
            this._defaultUnit = newValue;
            this._assignValue();
            this._cdr.markForCheck();
        }
    }
    private _defaultUnit: TimeUnit = TimeUnit.Minutes;

    @Input()
    get value(): Timespan {
        return this._value;
    }
    set value(value: Timespan) {
        if (value !== this.value) {
            this._value = value;
            this._assignValue();
            this._cdr.markForCheck();
        }
    }
    private _value: Timespan;

    @Input()
    get min(): Timespan {
        return this._min;
    }
    set min(value: Timespan) {
        if (this.min !== value) {
            this._min = value;
            this._initMinMaxValue();
            this._cdr.markForCheck();
        }
    }
    private _min: Timespan;

    @Input()
    get max(): Timespan {
        return this._max;
    }
    set max(value: Timespan) {
        if (this.max !== value) {
            this._max = value;
            this._initMinMaxValue();
            this._cdr.markForCheck();
        }
    }
    private _max: Timespan;

    @HostListener("focusin", ['$event'])
    focusin(event: FocusEvent): void {
        super.focusin(event);
        if (event.target === this.element) {
            this.focus();
        }
    }

    @HostListener("focusout", ["$event"])
    focusout(event: FocusEvent): void {
        super.focusout(event);
    }

    timeUnits: string[] = [];
    private _unitSelected: TimeUnit;
    unitSelectedString: string;

    minValue: number;
    maxValue: number;

    constructor(renderer2: Renderer2, _cdr: ChangeDetectorRef, _elementRef: ElementRef,
        @Attribute('tabindex') tabIndex: string,
        @Attribute('id') id: string,
        @Self() @Optional() ngControl: NgControl) {
        super(renderer2, _elementRef, _cdr, ngControl, tabIndex, id);

        this._buildUnitsSelect();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._setUnitSelected(this._unitSelected);
    }

    focus() {
        if (this.element) {
            let input: HTMLInputElement = this.element.querySelector("ez-input-number");
            if (input) {
                input.focus();
            }
        }
    }

    private _buildUnitsSelect() {
        this.timeUnits = [];
        for (let key in TimeUnit) {
            if (TimeUnit.hasOwnProperty(key)) {
                switch (TimeUnit[key]) {
                    case TimeUnit.Milliseconds:
                        this.timeUnits.push("Milliseconds");
                        break;
                    case TimeUnit.Seconds:
                        this.timeUnits.push("Seconds");
                        break;
                    case TimeUnit.Minutes:
                        this.timeUnits.push("Minutes");
                        break;
                    case TimeUnit.Hours:
                        this.timeUnits.push("Hours");
                        break;
                    case TimeUnit.Days:
                        this.timeUnits.push("Days");
                        break;
                }
            }
        }
    }

    timespanValue: number;

    private _assignValue() {
        if (this._value) {
            let value: number;
            if (!this._unitSelected) {
                this._setUnitSelected(undefined);
            }
            switch (this._unitSelected) {
                case TimeUnit.Milliseconds:
                    value = this._value.totalMilliseconds;
                    break;
                case TimeUnit.Seconds:
                    value = this._value.totalSeconds;
                    break;
                case TimeUnit.Minutes:
                    value = this._value.totalMinutes;
                    break;
                case TimeUnit.Hours:
                    value = this._value.totalHours;
                    break;
                case TimeUnit.Days:
                    value = this._value.totalDays;
                    break;
            }
            this.timespanValue = value;
        }
    }

    private _outputData() {
        if (!isNaN(this.timespanValue)) {
            this._value = new Timespan(this.timespanValue, this._unitSelected);
        } else {
            this._value = undefined;
        }
        this._onChange(this._value);
        this.valueChange.emit(this._value);
    }

    updateTimespanValue(value: number) {
        this.timespanValue = value;
        this._outputData();
    }

    updateTimespanUnit(unit: string) {
        this._setUnitSelected(unit);
        this._outputData();
    }

    private _setUnitSelected(value: string | TimeUnit) {
        if (!value) {
            if (this._unitSelected == this._defaultUnit) {
                this.unitSelectedString = undefined;
            }
            value = this._defaultUnit;
        }
        switch (value) {
            case "Milliseconds":
            case TimeUnit.Milliseconds:
                this._unitSelected = TimeUnit.Milliseconds;
                this.unitSelectedString = "Milliseconds";
                break;
            case "Seconds":
            case TimeUnit.Seconds:
                this._unitSelected = TimeUnit.Seconds;
                this.unitSelectedString = "Seconds";
                break;
            case "Minutes":
            case TimeUnit.Minutes:
                this._unitSelected = TimeUnit.Minutes;
                this.unitSelectedString = "Minutes";
                break;
            case "Hours":
            case TimeUnit.Hours:
                this._unitSelected = TimeUnit.Hours;
                this.unitSelectedString = "Hours";
                break;
            case "Days":
            case TimeUnit.Days:
                this._unitSelected = TimeUnit.Days;
                this.unitSelectedString = "Days";
                break;
            default:
                this._setUnitSelected(undefined);
                break;
        }
        this._initMinMaxValue();
    }

    private _initMinMaxValue() {
        switch (this._unitSelected) {
            case TimeUnit.Milliseconds:
                this.minValue = this._min ? this._min.totalMilliseconds : undefined;
                this.maxValue = this._max ? this._max.totalMilliseconds : undefined;
                break;
            case TimeUnit.Seconds:
                this.minValue = this._min ? this._min.totalSeconds : undefined;
                this.maxValue = this._max ? this._max.totalSeconds : undefined;
                break;
            case TimeUnit.Minutes:
                this.minValue = this._min ? this._min.totalMinutes : undefined;
                this.maxValue = this._max ? this._max.totalMinutes : undefined;
                break;
            case TimeUnit.Hours:
                this.minValue = this._min ? this._min.totalHours : undefined;
                this.maxValue = this._max ? this._max.totalHours : undefined;
                break;
            case TimeUnit.Days:
                this.minValue = this._min ? this._min.totalDays : undefined;
                this.maxValue = this._max ? this._max.totalDays : undefined;
                break;
        }

    }

    writeValue(value: any) {
        let valueChange = !value || !this.value || value.totalMilliseconds !== this.value.totalMilliseconds;
        this.value = value;
        if (valueChange) {
            super.writeValue(this._value);
        }
    }
}
