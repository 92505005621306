<ez-chart-svg [data]="data" [timerRefresh]="300" [selection]="selection" [margin]="10"
    (selectionChange)="selectionChartChange($event)" [hasSelection]="true">
    <ez-chart-over [labelXProperty]="'name'" *ngIf="!small"></ez-chart-over>
    <ez-chart-series-line [property]="'initial'" [label]="'Initial'" [dataType]="'numeric'" [curveType]="'step'"
        [zIndex]="10" [format]="'{0[1.1-1]} MW'">
        <ez-chart-style [backgroundColor]="colorInitial" [borderSize]="1"></ez-chart-style>
    </ez-chart-series-line>
    <ez-chart-series-line *ngIf="hasTradable" [property]="'tradable'" [label]="'Tradable limit'" [dataType]="'numeric'"
        [curveType]="'step'" [zIndex]="9" [format]="'{0[1.1-1]} MW'">
        <ez-chart-style [backgroundColor]="colorTradable"></ez-chart-style>
    </ez-chart-series-line>
    <ez-chart-series-area [property]="'current'" [threshold]="0" [label]="'Current'" [dataType]="'numeric'"
        [curveType]="'step'" [zIndex]="3" [format]="'{0[1.1-1]} MW'">
        <ez-chart-style [backgroundColor]="colorCurrent" [backgroundOpacity]="1"></ez-chart-style>
    </ez-chart-series-area>
    <ez-chart-series-area [property]="'potential'" [propertyEnd]="'current'" [label]="'Potential'"
        [dataType]="'numeric'" [curveType]="'step'" [zIndex]="4" [format]="'{0[1.1-1]} MW'">
        <ez-chart-style [backgroundColor]="colorPotential" [borderColor]="borderColorPotential" [backgroundOpacity]="1">
        </ez-chart-style>
    </ez-chart-series-area>
    <ez-chart-series-line *ngIf="hasBidAsk" [property]="'totalAsk'" [label]="'Total Ask'" [dataType]="'numeric'"
        [format]="'{0[1.1-1]} MW'" [display]="false" [curveType]="'step'">
        <ez-chart-style [backgroundColor]="colorAsk" [zIndex]="8"></ez-chart-style>
    </ez-chart-series-line>
    <ez-chart-series-line *ngIf="hasBidAsk" [property]="'totalBid'" [label]="'Total Bid'" [dataType]="'numeric'"
        [format]="'{0[1.1-1]} MW'" [display]="false" [curveType]="'step'">
        <ez-chart-style [backgroundColor]="colorBid" [zIndex]="7"></ez-chart-style>
    </ez-chart-series-line>
    <ez-chart-series-line *ngIf="hasLimit" [property]="'min'" [label]="'PMin'" [dataType]="'numeric'"
        [curveType]="'step'" [zIndex]="6" [format]="'{0[1.1-1]} MW'">
        <ez-chart-style [backgroundColor]="colorTradable"></ez-chart-style>
    </ez-chart-series-line>
    <ez-chart-series-line *ngIf="hasLimit" [property]="'max'" [label]="'PMax'" [dataType]="'numeric'"
        [curveType]="'step'" [zIndex]="5" [format]="'{0[1.1-1]} MW'">
        <ez-chart-style [backgroundColor]="colorTradable"></ez-chart-style>
    </ez-chart-series-line>
    <ez-chart-axis [property]="'label'" [axisType]="'band'"></ez-chart-axis>
    <ez-chart-legends *ngIf="!small" [position]="'bottom'"></ez-chart-legends>
    <ez-chart-scales *ngIf="!small">
        <ez-chart-scale-x [position]="'bottom'" [fit]="false"></ez-chart-scale-x>
        <ez-chart-scale-y [position]="'left'" [title]="'MW'"></ez-chart-scale-y>
    </ez-chart-scales>
    <ez-chart-regions>
        <ez-chart-region-data [data]="dataInactive">
            <ez-chart-style [backgroundColor]="colorInactive" [backgroundOpacity]="1"></ez-chart-style>
        </ez-chart-region-data>
        <ez-chart-region-data [data]="dataClosed">
            <ez-chart-style [backgroundColor]="colorClosed" [backgroundOpacity]="1"></ez-chart-style>
        </ez-chart-region-data>
    </ez-chart-regions>
</ez-chart-svg>
