<div class="select-container" ez-overlay-origin #originListTag="EzOverlayOrigin">
    <ez-loader *ngIf="loadData"></ez-loader>
    <ez-icon name="filter" class="btn-filters" (click)="toggleFiltersPanel()" [ngClass]="{'selected': hasFilters }"
        [ezTooltip]="'general.filters' | translate">
    </ez-icon>
    <ez-select [placeholder]="placeholder" [autocomplete]="true" [autocompleteTrigger]="autoCompleteTrigger"
        [(value)]="internalValue" [delayEventKey]="200" [multiple]="multiple" [disabled]="disabled"
        [required]="required" tabindex="-1" id="{{id}}-select">
        <ez-select-option *ngFor="let curve of timeseries;" [value]="curve">
            {{ curve.name }}{{curve.unit ? ' (' + curve.unit.name + ')' : '' }}
        </ez-select-option>
    </ez-select>
</div>
<ng-template ez-overlay-content [overlayContentOrigin]=" originListTag" [positionAlign]="posAlignOverlayLabelsList"
    [open]="panelFiltersIsOpen" [minWidthOrigin]="true">
    <div #divOverlay class="filter-panel" tabindex="-1">
        <ez-loader *ngIf="isLoadingLabelsList"></ez-loader>
        <div class="filter-row">
            <div class="filter-item filter-computed" *ngIf="!hideFilterCalculated">
                <ez-label>
                    {{'timeseries.global.calculated' | translate}}

                </ez-label>
                <ez-button-group [value]="filterCalculated" (valueChange)="isCalculatedChange($event)"
                    [required]="false">
                    <ez-button [small]="true" [value]="true">{{ 'general.yes' | translate }}</ez-button>
                    <ez-button [small]="true" [value]="false">{{ 'general.no' | translate }}</ez-button>
                </ez-button-group>
            </div>
            <div class="filter-item filter-locked" *ngIf="!hideFilterLocked">
                <ez-label>
                    {{'timeseries.global.locked' | translate}}
                </ez-label>
                <ez-button-group [value]="filterLocked" (valueChange)="isLockedChange($event)" [required]="false">
                    <ez-button [small]="true" [value]="true">{{ 'general.yes' | translate }}</ez-button>
                    <ez-button [small]="true" [value]="false">{{ 'general.no' | translate }}</ez-button>
                </ez-button-group>
            </div>
        </div>
        <div class="filter-row row-vertical">
            <div class="filter-item filter-labels">
                <ez-label>
                    {{ 'timeseries.global.groups' | translate }}
                </ez-label>
                <ez-groups-select [groups]="labels" [value]="filterLabels" [autoApply]="true"
                    (valueChange)="onLabelsChange($event)"></ez-groups-select>
            </div>
        </div>
    </div>
</ng-template>
