<ez-loader *ngIf="isLoading"></ez-loader>
<ez-form #form (onSubmit)="onValidateForm()">
    <ez-label class="title">{{'nominations.schedules.create.immediate.title' | translate }}</ez-label>
    <ez-form-field>
        <ez-radio-group [required]="true" [(ngModel)]="selectedRadio" class="radio-group">
            <ez-radio [label]="'nominations.schedules.create.immediate.dayBefore' | translate"
                [value]="dateRangeType.DayBefore">
                <span class="date-label"
                    [class.disabled]="selectedRadio !== dateRangeType.DayBefore">
                     {{ dayBefore }}    
                </span>
            </ez-radio>
            <ez-radio [label]="'nominations.schedules.create.immediate.intraday' | translate"
                [value]="dateRangeType.Intraday"><span class="date-label"
                    [class.disabled]="selectedRadio !== dateRangeType.Intraday">
                    {{ intraday }}
                    </span>
            </ez-radio>
            <ez-radio [label]="'nominations.schedules.create.immediate.dayAhead' | translate"
                [value]="dateRangeType.DayAhead">
                <span class="date-label"
                    [class.disabled]="selectedRadio !== dateRangeType.DayAhead">
                    {{dayAhead }}
                </span>
            </ez-radio>
            <ez-radio [label]="'nominations.schedules.create.immediate.specificDay' | translate"
                [value]="dateRangeType.SpecificDay">
                <div class="date-day">
                    <ez-datepicker [value]="customDate" [timezone]="timezone"
                        [disabled]="selectedRadio !== dateRangeType.SpecificDay"
                        (valueChange)="customDateChange($event)">
                    </ez-datepicker>
                </div>
            </ez-radio>
            <ez-radio [label]="'nominations.schedules.create.immediate.period' | translate"
                [value]="dateRangeType.Period">
                <div class="date-period">
                    <ez-datepicker [value]="periodDate" [isRange]="true" [timezone]="timezone"
                        [disabled]="selectedRadio !== dateRangeType.Period" (valueChange)="periodDateChange($event)">
                    </ez-datepicker>
                </div>
            </ez-radio>
        </ez-radio-group>
    </ez-form-field>
    <ez-form-field>
        <ez-select [(ngModel)]="selectedGate" [placeholder]="'nominations.schedules.create.immediate.gate' | translate">
            <ez-select-option *ngFor="let gate of gates" [value]="gate">{{ gate.name }}</ez-select-option>
        </ez-select>
    </ez-form-field>
    <div class="checkbox-input">
        <ez-checkbox [(checked)]="activateVersion"></ez-checkbox>
        <ez-form-field>
            <ez-input-number [(ngModel)]="version"
                [placeholder]="'nominations.schedules.create.immediate.version' | translate"
                [disabled]="!activateVersion" [min]="minVersion" [max]="maxVersion" [required]="true">
            </ez-input-number>
        </ez-form-field>
    </div>
    <ez-form-field>
        <ez-select [(ngModel)]="selectedInterfaces" [multiple]="true"
            [placeholder]="'nominations.schedules.create.immediate.sendingProcess' | translate" [required]="true">
            <ez-select-option *ngFor="let interface of interfaces" [value]="interface">{{ interface.name }}
            </ez-select-option>
        </ez-select>
    </ez-form-field>
    <ez-footer>
        <ez-button [label]="'nominations.schedules.create.validate' | translate" [severity]="'primary'" ez-form-submit>
        </ez-button>
    </ez-footer>
</ez-form>
