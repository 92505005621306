import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  // TODO: Handle multiple levels
  public transform<T>(array: T[], key: keyof T, filterValue: string | any): T[] {
    if (typeof filterValue !== 'string') {
      return array;
    }
    return array.filter((item) => {
      if (item[key] && typeof item[key] === 'string') {
        return (item[key] as string).toLowerCase().includes(filterValue.toLowerCase());
      } else {
        return false;
      }
    });
  }
}
