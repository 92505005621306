// @ts-nocheck
import { Injectable } from '@angular/core';
import { Logger } from '@core/logger';
import { isSame, orderBy } from '@eznergy/core';
import { ApiService } from '@eznergy/webapi';
import { ICounterpart, IGridPoint, GridPointCategories } from '@eztypes/webapi';
import { ApplicationService } from './application.service';

export class TreeGridPoint {
    readonly hasVirtual: boolean;
    readonly gridPoint: IGridPoint;
    children: TreeGridPoint[];
    level: number;
    sortId: number;
    constructor(gridPoint: IGridPoint) {
        this.hasVirtual = GridPointService.hasVirtual(gridPoint);
        this.gridPoint = gridPoint;
        this.children = [];
    }
    init(): void { }
}

export class TreeGridPointWithCp extends TreeGridPoint {
    readonly hasCps: boolean;
    readonly cps: ICounterpart[];
    children: TreeGridPointWithCp[];
    get hasCpsInChild(): boolean {
        return this._hasCpsInChild;
    }
    private _hasCpsInChild: boolean;
    constructor(gridPoint: IGridPoint, cps: ICounterpart[]) {
        super(gridPoint);
        this.cps = cps;
        this.hasCps = this.cps.length > 0;
    }

    init(): void {
        super.init();
        this._hasCpsInChild = this.children.some((a) => a.hasCps || a.hasCpsInChild);
    }
}


@Injectable({ providedIn: 'root' })
export class GridPointService {

    static hasVirtual(gp: IGridPoint): boolean {
        return gp.type.code === "VGP" || gp.type.code === "NTW";
    }

    static hasTypeHub(gp: IGridPoint): boolean {
        return gp.type.code === "PEE" || gp.type.code === "PEG";
    }

    static getKeyOrder(gp: IGridPoint): string {
        let idType: number;
        switch (gp.category) {
            case GridPointCategories.VTP:
                idType = 1;
                break;
            case GridPointCategories.CrossBorder:
                idType = 2;
                break;
            case GridPointCategories.Production:
                idType = 3;
                break;
            case GridPointCategories.Consumption:
                idType = 4;
                break;
            case GridPointCategories.Storage:
                idType = 5;
                break;
            case GridPointCategories.Other:
                idType = 6;
                break;
            default:
                idType = 7;
                break;
        }
        return `${idType}_${gp.shortName}`;
    }

    constructor(private readonly _api: ApiService, private readonly _appSvc: ApplicationService, private readonly _logger: Logger) {
    }


    createTree<T extends TreeGridPoint>(data: T[], options: { sort?: boolean } = {}): T[] {
        return this._recursiveCreateTree(data);
    }

    getFlatChildNotVirtual<T extends TreeGridPoint>(data: T): IGridPoint[] {
        return this._getChildreNotVirtual(data);
    }


    private _recursiveCreateTree<T extends TreeGridPoint>(treeGripPoint: T[], father: T = undefined): T[] {
        let local: T[] = treeGripPoint.filter((g: T) => isSame(g.gridPoint.father, father != null ? father.gridPoint : undefined));
        local.forEach((child: TreeGridPoint) => {
            child.level = father != null ? father.level + 1 : 0;
            child.children = this._recursiveCreateTree(treeGripPoint, child);
            child.init();
        });
        local = orderBy(local, (l) => {
            return GridPointService.getKeyOrder(l.gridPoint);
        });
        this._sortIdTree(local);
        return local;
    }

    private _sortIdTree<T extends TreeGridPoint>(tree: T[], sortId: number = 1): number {
        let sid = sortId;
        tree.forEach((a) => {
            a.sortId = sid++;
            sid = this._sortIdTree(a.children, sid);
        });
        return sid;
    }

    private _getChildreNotVirtual<T extends TreeGridPoint>(data: T, children: IGridPoint[] = []): IGridPoint[] {
        data.children.forEach((c) => {
            this._getChildreNotVirtual(c, children);
        });
        if (data.gridPoint.type.code !== "VGP") {
            children.push(data.gridPoint);
        }
        return children;
    }
}
