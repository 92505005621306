<div class="title">{{'pages.auction-schedules.component.forms.label' | translate }}</div>
<ez-loader *ngIf="onLoad"></ez-loader>
<ez-tabs [(value)]="tabSelected">
    <ez-tab-section [value]="tabEnum.Immediate" [disabled]="schedule">
        <ez-tab-title> {{ 'pages.auction-schedules.component.forms.immediate.name' | translate }}</ez-tab-title>
        <ez-content>
            <auction-immediate-schedule-form [exchangeProducts]="exchangeProducts" [deliveryArea]="deliveryArea"
                [shipper]="shipper">
            </auction-immediate-schedule-form>
        </ez-content>
    </ez-tab-section>
    <ez-tab-section [value]="tabEnum.Forecast">
        <ez-tab-title> {{ 'pages.auction-schedules.component.forms.forecasts.name' | translate }}</ez-tab-title>
        <ez-content>
            <auction-schedule-forecasts-form [exchangeProducts]="exchangeProducts" [deliveryArea]="deliveryArea"
                [shipper]="shipper" [value]="schedule">
            </auction-schedule-forecasts-form>
        </ez-content>
    </ez-tab-section>
</ez-tabs>
<div class="footer">
    <ez-button [label]="'general.cancel' | translate" (onClick)="cancel()"></ez-button>
    <ez-button [label]="(schedule == null ? 'general.create' : 'general.modify') | translate" [severity]="'primary'"
        (onClick)="submitForm()">
    </ez-button>
</div>
