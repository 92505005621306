<ez-card [type]="'small'">
    <ez-editor-toolbar #toolbar (formatChange)="onFormatChange($event)"
        (externalLinkChange)="onExternalLinkChange($event)" (headingChange)="onHeadingChange()"
        (quoteChange)="onQuoteChange($event)" (listTypeChange)="onListTypeChange($event)"
        (viewTypeChange)="onViewTypeChange($event)" (submitChange)="onSubmitChange($event)" [heading]="tbHeading"
        [format]="tbFormat" [quote]="tbQuote" [listType]="tbListType">
    </ez-editor-toolbar>
</ez-card>
<div class="md-container">
    <ez-card [type]="'small'" class="md-editor">
        <ez-loader *ngIf="loader"></ez-loader>
        <ez-input-text [value]="textAreaContent" [type]="'textarea'"
            [placeholder]="'aside.help.placeholder' | translate" (valueChange)="onInputChange($event)"
            [selection]="cursorPosition" (selectionChange)="onSelectionChange($event)" (keyup)="onTaKeyUp($event)">
        </ez-input-text>
    </ez-card>
    <ez-card [type]="'small'" class="md-reader">
        <ez-markdown [gfm]="true" [breaks]="true" [data]="textAreaContent" [baseUrl]="baseUrl" [withAnalyse]="true"
            (analyseEnd)="analyseEnd($event)">
        </ez-markdown>
    </ez-card>
</div>
