import { Pipe } from '@angular/core';
import { DateTime } from '@eztypes/generic';

export interface ITimerOptions {
    withPast?: boolean;
    withAfter?: boolean;
    timer?: boolean;
}

@Pipe({
    name: "eztimer",
    pure: false
})
export class EzTimerPipe {
    private readonly _templateTimerAfter: string = "in {date}";
    private readonly _templateTimerPast: string = "{date} ago";
    private readonly _templateAfter: string = "in {date} {unit}";
    private readonly _templatePast: string = "{date} {unit} ago";
    private readonly _templateNow: string = "now";

    transform(value: DateTime | undefined, options: ITimerOptions = {}): any {
        if (!value) {
            return 'never';
        }

        let valueTime = value.diffToNow();
        this._formatOptions(options);
        let timespan = valueTime;
        let template: string;

        if (options.timer && timespan.totalDays < 1) {
            if (valueTime.totalMilliseconds < 0 && !options.withPast) {
                return "in 00:00:00";
            } else if (valueTime.totalMilliseconds > 0 && !options.withAfter) {
                return "00:00:00 ago";
            }
            if (valueTime.totalMilliseconds >= 0) {
                template = this._templateTimerAfter;
            } else {
                template = this._templateTimerPast;
            }
            let date = timespan.format("hh:mm:ss");
            return template.replace("{date}", date);
        } else {
            if (valueTime.totalMilliseconds < 0 && !options.withPast) {
                return this._templateNow;
            } else if (valueTime.totalMilliseconds > 0 && !options.withAfter) {
                return this._templateNow;
            }
            let date: number;
            let unit: string;
            if (valueTime.totalMilliseconds >= 0) {
                template = this._templateAfter;
            } else {
                template = this._templatePast;
            }
            if (timespan.totalDays >= 1) {
                date = timespan.days;
                unit = "day";
            } else if (timespan.totalHours >= 1) {
                date = timespan.hours;
                unit = "hour";
            } else if (timespan.totalMinutes >= 1) {
                date = timespan.minutes;
                unit = "minute";
            } else if (timespan.totalSeconds >= 1) {
                date = timespan.seconds;
                unit = "second";
            } else {
                return this._templateNow;
            }
            if (date > 1) {
                unit += "s";
            }
            return template.replace("{date}", date.toString()).replace("{unit}", unit);
        }
    }

    private _formatOptions(opt: ITimerOptions): void {
        if (opt.timer == undefined) {
            opt.timer = false;
        }
        if (opt.withPast == undefined) {
            opt.withPast = true;
        }
        if (opt.withAfter == undefined) {
            opt.withAfter = true;
        }
    }
}
