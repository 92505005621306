import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByPipe } from './filter-by/filter-by.pipe';
import { EnumStringToIterablePipe } from './enum-string-to-iterable/enum-string-to-iterable.pipe';
import { StaticGridUri, StaticTimeUri, StaticMaterialUri, StaticCountryUri, StaticExchangeUri, StaticLogoUri, StaticLogoIUri, StaticContractLogoUri } from './static-uri/static-uri.pipe';
import { ShrinkPipe } from './shrink/shrink.pipe';
import { OrderPipe } from './order-by/sort.pipe';
import { BooleanPipe } from './boolean/boolean.pipe';
import { FilterAuctionByStatusPipe } from './filter-auction-by-status/filter-auction-by-status.pipe';


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        FilterByPipe,
        EnumStringToIterablePipe,
        StaticGridUri,
        StaticTimeUri,
        StaticMaterialUri,
        StaticCountryUri,
        StaticExchangeUri,
        StaticLogoUri,
        StaticLogoIUri,
        StaticContractLogoUri,
        ShrinkPipe,
        OrderPipe,
        BooleanPipe,
        FilterAuctionByStatusPipe
    ],
    exports: [
        FilterByPipe,
        EnumStringToIterablePipe,
        StaticGridUri,
        StaticTimeUri,
        StaticMaterialUri,
        StaticCountryUri,
        StaticExchangeUri,
        StaticLogoUri,
        StaticLogoIUri,
        StaticContractLogoUri,
        ShrinkPipe,
        OrderPipe,
        BooleanPipe,
        FilterAuctionByStatusPipe
    ]
})
export class PipesModules {
}
