import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppComponentsModule } from "@components/components.module";
import { AppDirectivesModule } from "@directives/directives.module";
import { EzNotificationsModule } from "@eznergy/toaster";
import { AppPipesModule } from "@pipes/pipes.module";

@NgModule({
    imports: [
        CommonModule,
        AppComponentsModule,
        AppDirectivesModule,
        AppPipesModule,
        EzNotificationsModule
    ],
    exports: [
        CommonModule,
        AppComponentsModule,
        AppDirectivesModule,
        AppPipesModule,
        EzNotificationsModule
    ]
})
export class AppCoreModule {

}
