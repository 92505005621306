import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { PRIMARY_OUTLET } from '@angular/router';
import { AppQueryParamsKey, TypeDisplayMenu } from '@core/application.model';
import { Subscriptions } from '@core/subscriptions';
import { EzListOptionComponent } from '@eznergy/components';
import { UserRight } from '@eztypes/webapi';
import { AppConfig } from '@services/app-config.service';
import { ApplicationService } from '@services/application.service';
import { EventService } from '@services/event.service';
import { AppPages, RouterService } from '@services/router.service';
import { fromEvent, Observable, of } from 'rxjs';
import { auditTime, map, startWith } from 'rxjs/operators';

interface SubMenu {
    icon: string;
    name: string;
    page: AppPages;
    rights: UserRight[];
}

interface Menu {
    categorie: string;
    icon: string;
    submenu: SubMenu[] | undefined;
    page?: AppPages;
    rights?: UserRight[];
}

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AppMenuComponent {
    readonly appPage = AppPages;

    @HostListener('mouseleave')
    public mouseLeave(): void {
        const { menuType } = this._appSvc;
        if (menuType === TypeDisplayMenu.Mini) {
            this.toggleMenu();
        }
    }

    @HostBinding('class.mini')
    public get isMini(): boolean {
        return this.menuDisplayType === TypeDisplayMenu.Mini;
    }

    get menus(): Menu[] | undefined {
        return this._menus;
    }
    private _menus: Menu[] | undefined;

    get currentCateg(): Menu | undefined {
        return this._currentCateg;
    }
    private _currentCateg: Menu | undefined;

    get currentSubMenu(): SubMenu | undefined {
        return this._currentSubMenu;
    }
    private _currentSubMenu: SubMenu | undefined;

    private readonly _subs: Subscriptions = new Subscriptions();

    public readonly displayType$: Observable<TypeDisplayMenu>;
    public readonly environmentDisplay$: Observable<{ name: string; maxLength?: number } | null>;

    public menuDisplayType: TypeDisplayMenu | undefined;
    public nameEnv: string | undefined;

    public isMultiShipper: boolean = false;

    constructor(
        private readonly _el: ElementRef<HTMLElement>,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _renderer: Renderer2,
        private readonly _appSvc: ApplicationService,
        private readonly _config: AppConfig,
        private readonly _routerSvc: RouterService,
        private readonly _sizeEventChange: EventService
    ) {
        this.displayType$ = this.setupDisplayType();
        this.environmentDisplay$ = this.setupEnvironmentDisplay();
    }

    ngOnInit(): void {      
        const sub = this._appSvc.contractChange.subscribe(() => {
            this._createMenu();
            this._cdr.detectChanges();
        });

        this._subs.push('contract-change', sub);
        this._subs.push('query-change', this._routerSvc.queryParamsChange.subscribe(
            (queryParams) => {
                const shipperQuery = queryParams?.get(PRIMARY_OUTLET)[AppQueryParamsKey.Shipper];
                this.isMultiShipper = shipperQuery && shipperQuery === "0";
                this._createMenu();
            }
        ));
        const subData = this._routerSvc.dataParamsChange.subscribe((datas) => {
            if (datas != null) {
                const data = datas.get(PRIMARY_OUTLET);
                const rightScreen = data['rightScreen'];
                if (this._currentSubMenu == null || !this._currentSubMenu.rights.includes(rightScreen)) {
                    this._currentSubMenu = undefined;
                    this._currentCateg = this.menus?.find((m) => {
                        const submenu = m.submenu?.find((sm) => sm.rights.includes(rightScreen));
                        if (submenu != null) {
                            this._currentSubMenu = submenu;
                            return true;
                        }
                        return false;
                    });
                    if (this._currentSubMenu == undefined && this._currentCateg == null) {
                        this._currentCateg = this.menus?.find(
                            (m) => m.submenu == undefined && m.rights?.includes(rightScreen)
                        );
                    }
                    this._cdr.detectChanges();
                }
            }
        });
        this._subs.push('data-change', subData);

        const subTypeMenu = this._appSvc.menuTypeChange.subscribe((type) => {
            this.menuDisplayType = type;
            this._cdr.detectChanges();
        });
        this._subs.push('type-display-change', subTypeMenu);

        fromEvent(this._el.nativeElement, 'transitionend')
            .pipe(auditTime(100))
            .subscribe((_event) => {
                this._sizeEventChange.widthChanged();
            });
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    isActiveMenu(menu: Menu) {
        return this.currentCateg?.categorie === menu.categorie;
    }

    isActiveItem(subMenu: SubMenu) {
        return this.currentSubMenu?.name === subMenu.name;
    }

    toggleMenu(categElement?: EzListOptionComponent<void>): void {
        const oldElementOpen = this._el.nativeElement.querySelector<HTMLElement>('ez-li.open');
        if (oldElementOpen != null) {
            this._renderer.removeClass(oldElementOpen, 'open');
        }
        if (categElement != null && oldElementOpen !== categElement.element) {
            this._renderer.addClass(categElement.element, 'open');
        }
    }

    private _createMenu(): void {
        this._menus = [];
        if (this._appSvc.contract != null) {
            const subMenuBalancing: SubMenu[] = [];
            if (!this.isMultiShipper && this._appSvc.hasRight(UserRight.ScreenMap)) {
                subMenuBalancing.push({
                    name: 'map',
                    icon: 'map',
                    page: AppPages.Map,
                    rights: [UserRight.ScreenMap],
                });
            }
            if (!this.isMultiShipper && this._appSvc.hasRight(UserRight.ScreenOwbalancing)) {
                subMenuBalancing.push({
                    name: 'balancing-overview',
                    icon: 'sitemap',
                    page: AppPages.BalancingOverview,
                    rights: [UserRight.ScreenOwbalancing],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenPosition)) {
                subMenuBalancing.push({
                    name: 'balancing-positions',
                    icon: 'chart-area',
                    page: AppPages.Positions,
                    rights: [UserRight.ScreenPosition],
                });
            }
            if (subMenuBalancing.length > 0) {
                this._menus.push({
                    categorie: 'balancing',
                    icon: 'calculator',
                    submenu: subMenuBalancing,
                });
            }

            const subMenuTrading: SubMenu[] = [];
            if (this._appSvc.hasRight(UserRight.ScreenAuctionOverview)) {
                subMenuTrading.push({
                    icon: 'sitemap',
                    name: 'auction-overview',
                    page: AppPages.AuctionOverview,
                    rights: [UserRight.ScreenAuctionOverview],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenAuctionOrders)) {
                subMenuTrading.push({
                    icon: 'wallet',
                    name: 'auction-orders',
                    page: AppPages.AuctionOrders,
                    rights: [
                        UserRight.ScreenAuctionOrders,
                        UserRight.AuctionMarketResultsRead,
                        UserRight.AuctionLinearOrdersRead,
                        UserRight.AuctionBlockOrdersRead,
                        UserRight.AuctionTemplatesRead,
                    ],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenAuctionSchedules)) {
                subMenuTrading.push({
                    icon: 'calendar-check',
                    name: 'auction-schedules',
                    page: AppPages.AuctionSchedules,
                    rights: [UserRight.ScreenAuctionSchedules],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenAutotrading)) {
                subMenuTrading.push({
                    icon: 'th-large',
                    name: 'continuous',
                    page: AppPages.Intraday,
                    rights: [UserRight.ScreenAutotrading],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenEts) && !this._appSvc.hasRight(UserRight.ScreenAuctionOrders)) {
                subMenuTrading.push({
                    icon: 'wallet',
                    name: 'auction-dayahead',
                    page: AppPages.Ets,
                    rights: [UserRight.ScreenEts],
                });
            }
            if (!this.isMultiShipper && subMenuTrading.length > 0) {
                this._menus.push({
                    categorie: 'trading',
                    icon: 'chart-bar',
                    submenu: subMenuTrading,
                });
            }

            const subMenuNom: SubMenu[] = [];
            if (this._appSvc.hasRight(UserRight.ScreenNomoverview)) {
                subMenuNom.push({
                    icon: 'sitemap',
                    name: 'nominations-overview',
                    page: AppPages.NomOverview,
                    rights: [UserRight.ScreenNomoverview],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenNomrealtime)) {
                subMenuNom.push({
                    icon: 'clock',
                    name: 'nominations-realtime',
                    page: AppPages.NomRealTime,
                    rights: [UserRight.ScreenNomrealtime],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenNommessages)) {
                subMenuNom.push({
                    icon: 'envelope',
                    name: 'nominations-messages',
                    page: AppPages.NomMessages,
                    rights: [UserRight.ScreenNommessages],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenNomschedule)) {
                subMenuNom.push({
                    icon: 'calendar-check',
                    name: 'nominations-batch',
                    page: AppPages.NomBatch,
                    rights: [UserRight.ScreenNomschedule],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenNomConfigurations)) {
                subMenuNom.push({
                    icon: 'tools',
                    name: 'nominations-configuration',
                    page: AppPages.NomConfiguration,
                    rights: [UserRight.ScreenNomConfigurations],
                });
            }
            if (!this.isMultiShipper && subMenuNom.length > 0) {
                this._menus.push({
                    categorie: 'nominations',
                    icon: 'paper-plane',
                    submenu: subMenuNom,
                });
            }

            const subMenuAssets: SubMenu[] = [];
            if (this._appSvc.hasRight(UserRight.ScreenTaoorder)) {
                subMenuAssets.push({
                    icon: 'download',
                    name: 'assets-tsoactivation',
                    page: AppPages.TAO,
                    rights: [UserRight.ScreenTaoorder],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenProdmessages)) {
                subMenuAssets.push({
                    icon: 'envelope',
                    name: 'assets-messages',
                    page: AppPages.AssetMessages,
                    rights: [UserRight.ScreenProdmessages],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenProdschedule)) {
                subMenuAssets.push({
                    icon: 'calendar-check',
                    name: 'assets-batch',
                    page: AppPages.AssetBatch,
                    rights: [UserRight.ScreenProdschedule],
                });
            }
            if (subMenuAssets.length > 0) {
                this._menus.push({
                    categorie: 'assets',
                    icon: 'industry',
                    submenu: subMenuAssets,
                });
            }

            if (!this.isMultiShipper && this._appSvc.hasRight(UserRight.ScreenDeal)) {
                this._menus.push({
                    categorie: 'balancing-deals',
                    icon: 'server',
                    submenu: undefined,
                    page: AppPages.Deals,
                    rights: [UserRight.ScreenDeal],
                });
            }

            const subMenuTs: SubMenu[] = [];
            if (this._appSvc.hasRight(UserRight.ScreenCurve)) {
                subMenuTs.push({
                    icon: 'chart-area',
                    name: 'timeseries-edit',
                    page: AppPages.TsEdit,
                    rights: [UserRight.ScreenCurve],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenComputed)) {
                subMenuTs.push({
                    icon: 'square-root-alt',
                    name: 'timeseries-computed',
                    page: AppPages.TSFormula,
                    rights: [UserRight.ScreenComputed],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenCurvegroups)) {
                subMenuTs.push({
                    icon: 'tools',
                    name: 'timeseries-admin',
                    page: AppPages.TsAdmin,
                    rights: [UserRight.ScreenCurvegroups],
                });
            }
            if (!this.isMultiShipper && subMenuTs.length > 0) {
                this._menus.push({
                    categorie: 'timeseries',
                    icon: 'chart-line',
                    submenu: subMenuTs,
                });
            }

            const subMenuConfig: SubMenu[] = [];
            if (this._appSvc.hasRight(UserRight.ScreenFile)) {
                subMenuConfig.push({
                    icon: 'folder',
                    name: 'interface-files',
                    page: AppPages.ConfigFiles,
                    rights: [UserRight.ScreenFile],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenScheduling)) {
                subMenuConfig.push({
                    icon: 'calendar-check',
                    name: 'interface-schedules',
                    page: AppPages.ConfigBatch,
                    rights: [UserRight.ScreenScheduling],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenCounterparts)) {
                subMenuConfig.push({
                    icon: 'address-book',
                    name: 'interface-counterpart',
                    page: AppPages.ConfigCounterparts,
                    rights: [UserRight.ScreenCounterparts],
                });
            }
            if (this._appSvc.hasRight(UserRight.ScreenTsmapping)) {
                subMenuConfig.push({
                    icon: 'arrows-alt-h',
                    name: 'mapping',
                    page: AppPages.ConfigMapping,
                    rights: [UserRight.ScreenTsmapping],
                });
            }
            if (!this.isMultiShipper && subMenuConfig.length > 0) {
                this._menus.push({
                    categorie: 'interface',
                    icon: 'tools',
                    submenu: subMenuConfig,
                });
            }

            const subMenuAdmin = [];
            if (this._appSvc.hasRight(UserRight.ScreenUsersoverview)) {
                subMenuAdmin.push({
                    icon: 'users',
                    name: 'users',
                    page: AppPages.UsersOverview,
                    rights: [UserRight.ScreenUsersoverview]
                });
            }

            if (!this.isMultiShipper && subMenuAdmin.length > 0) {
                this._menus.push({
                    categorie: 'administration',
                    icon: 'cog',
                    submenu: subMenuAdmin
                })
            }
        }
        this._cdr.detectChanges();
    }

    private setupDisplayType(): Observable<TypeDisplayMenu> {
        return this._appSvc.menuTypeChange.pipe(startWith(this._appSvc.menuType));
    }

    private setupEnvironmentDisplay(): Observable<{ name: string; length?: number } | null> {
        if (!this._config.environment.displayName) {
            return of(null);
        }

        return this.displayType$.pipe(
            map((menuType) => {
                let maxLength: number | undefined = undefined;
                if (menuType === TypeDisplayMenu.Mini) {
                    maxLength = 3;
                }

                return { name: this._config.environment.name, maxLength };
            })
        );
    }
}
