import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ActivationEnd, Data, IsActiveMatchOptions, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Params, PRIMARY_OUTLET, QueryParamsHandling, Router, UrlSegment, UrlTree } from "@angular/router";
import { AppQueryParamsKey } from "@core/application.model";
import { Logger } from '@core/logger';
import { DataParamMetaData, IDataParamMetaData, IQueryParamMetaData, QueryParamMetaData } from "@core/metadata";
import * as _ez from "@eznergy/core";
import { isEqual, sortBy } from "@eznergy/core";
import { DateRange, DateTime, Dictionary, IDictionary, TimeUnit, TimeZone } from '@eztypes/generic';
import { IContract, IOrganisation } from '@eztypes/webapi';
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter } from "rxjs/operators";
import { ApplicationService } from "./application.service";

export enum AppPages {
    Offline = "offline",
    Dashboard = "dashboard",
    Positions = "balancing/positions",
    Login = "identifier",
    Logout = "logout",
    Intraday = "intraday",
    IntradayLive = "intradayLive",
    IntradayReady = "intradayReady",
    IntradayConfigure = "intradayConfigure",
    Session = "session",
    SessionOverview = "sessionOverview",
    SessionMarket = "sessionMarket",
    SessionReporting = "sessionReporting",
    SessionParamExchange = "sessionParamExchange",
    SessionParamPrice = "sessionParamPrice",
    SessionParamVolume = "sessionParamVolume",
    SessionParamStrategy = "sessionParamStrategy",
    SessionParamTermination = "sessionParamTermination",
    SessionReportPast = "sessionReportPast",
    SessionReportLive = "sessionReportLive",
    SessionQuickEdit = "sessionQuickEdit",
    Help = "help",
    HelpEdit = "helpEdit",
    SessionCreate = "sessionCreate",
    EventsReport = "events",
    TsEdit = "timeseries/edit",
    TsAdmin = "timeseries/admin",
    TSFormula = "timeseries/computed",
    AuthScheme = "auth",
    AuctionOrders = "auction-orders",
    AuctionOrdersExchange = "auction-orders-exchange",
    AuctionOrdersExchangeLinear = "auction-orders-exchange-linear",
    AuctionOrdersExchangeLinearVersion = "auction-orders-exchange-linear-version",
    AuctionOrdersExchangeLinearWorking = "auction-orders-exchange-linear-working",
    AuctionOrdersExchangeLinearOnline = "auction-orders-exchange-linear-online",
    AuctionOrdersExchangeBlock = "auction-orders-exchange-block",
    AuctionOrdersExchangeBlockVersion = "auction-orders-exchange-block-version",
    AuctionOrdersExchangeBlockWorking = "auction-orders-exchange-block-working",
    AuctionOrdersExchangeBlockOnline = "auction-orders-exchange-block-online",
    AuctionExchangeMarketResult = "auction-exchange-market-result",
    AuctionTemplateInternal = "auction-internal-template",
    AuctionSchedulesCreate = "auctionSchedulesCreate",
    AuctionSchedules = "auctionSchedules",
    AuctionOverview = "auctionOverview",
    DealForm = "dealForm",
    Map = "balancing/map",
    BalancingOverview = "balancing/overview",
    Deals = "balancing/deals",
    Ets = "balancing-ets",
    NomOverview = "nominations/overview",
    NomRealTime = "nominations/realtime",
    NomMessages = "nominations/messages",
    NomBatch = "nominations/batch",
    NomConfiguration = "nominations/configuration",
    AssetMessages = "assets/messages",
    AssetBatch = "assets/batch",
    TAO = "assets/tsoactivation",
    ConfigFiles = "configuration/files",
    ConfigBatch = "configuration/schedules",
    ConfigCounterparts = "configuration/counterpart",
    ConfigMapping = "configuration/mapping",
    UsersOverview = "admin/users-overview"
}

export interface IDataParams { }

export interface IQueryParams { }

export interface PageParams {
    readonly data: IDictionary<Data>;
    readonly uri: IDictionary<Params>;
    readonly query: IDictionary<Params>;
}

class DataParam<T> {
    readonly meta: IDataParamMetaData<T>;
    private readonly _values: T | T[] | IDictionary<T | T[]>;
    private readonly _defaultValue: T | T[] | undefined;
    private readonly _hasDictionary: boolean = false;
    constructor(meta: IDataParamMetaData<T>, values: T | T[], defaultValue?: T) {
        this.meta = meta;
        this._values = values;
        this._hasDictionary = this._values instanceof Dictionary && this.meta.queryKey != null;
        if (this.meta.defaultType === "property" && this._hasDictionary) {
            throw new Error("Is not possible associate dictionary values with default type 'property'");
        }
        this._defaultValue = defaultValue;
    }

    values(query?: IQueryParams): T | T[] {
        if (this._hasDictionary && query != null && this.meta.queryKey) {
            let qMeta = QueryParamMetaData.getMetaData<any>(query, this.meta.queryKey);
            let keyDict = qMeta.property != undefined ? (<any>query)[this.meta.queryKey][qMeta.property] : (<any>query)[this.meta.queryKey];
            return (this._values as IDictionary<T>).get(keyDict);
        }
        return this._values as T | T[];
    }

    defaultValue(qpinfo: IQueryParamMetaData<any>, query?: IQueryParams): T | T[] | undefined {
        if (this._hasDictionary && query != null && this.meta.queryKey) {
            let qMeta = QueryParamMetaData.getMetaData<any>(query, this.meta.queryKey);
            let keyDict = qMeta.property != undefined && (<any>query)[this.meta.queryKey] != undefined ? (<any>query)[this.meta.queryKey][qMeta.property] : (<any>query)[this.meta.queryKey];
            const values = (this._values as IDictionary<T | T[]>).get(keyDict);
            switch (this.meta.defaultType) {
                case "all":
                    return _.cloneDeep(values);
                case "first":
                    return _.cloneDeep(_.first(values as T[]));
                case "value":
                    return values;
                case "empty":
                    return qpinfo.isArray ? [] : undefined;
            }
        } else if (this.meta.defaultType === "empty" && qpinfo.isArray) {
            return [];
        }
        return this._defaultValue as T | T[];
    }

}

@Injectable({ providedIn: "root" })
export class RouterService {

    private _queryParams: { [key: string]: any } | null = {};
    private _appTitle: AppTitle = new AppTitle;
    private _contract: IContract | undefined;

    private _isFirstActivationEnd: boolean = true;
    private _dataParams: { [key: string]: DataParam<any> } = {};
    private _component: any;
    private _hasCustomTitle: boolean = false;

    get hostname(): string | undefined {
        if (!this._hostname) {
            this._processOriginUrl();
        }
        return this._hostname;
    }
    private _hostname: string | undefined;

    get router(): Router {
        return this._router;
    }

    get paramsChange(): Observable<PageParams | undefined> {
        return this._subjectParamsChange.asObservable();
    }
    private readonly _subjectParamsChange: BehaviorSubject<PageParams | undefined> = new BehaviorSubject<PageParams | undefined>(undefined);

    get dataParamsChange(): Observable<IDictionary<Data> | undefined> {
        return this._subjectDataParamsChange.asObservable().pipe(filter(() => this.onNavigate === false));
    }
    private readonly _subjectDataParamsChange: BehaviorSubject<IDictionary<Data> | undefined> = new BehaviorSubject<IDictionary<Data> | undefined>(new Dictionary());

    get uriParamsChange(): Observable<IDictionary<Params> | undefined> {
        return this._subjectUriParamsChange.asObservable().pipe(filter(() => this.onNavigate === false));
    }
    private readonly _subjectUriParamsChange: BehaviorSubject<IDictionary<Params> | undefined> = new BehaviorSubject<IDictionary<Params> | undefined>(new Dictionary());

    get queryParamsChange(): Observable<IDictionary<Params> | undefined> {
        return this._subjectQueryParamsChange.asObservable();
    }
    private readonly _subjectQueryParamsChange: BehaviorSubject<IDictionary<Params> | undefined> = new BehaviorSubject<IDictionary<Params> | undefined>(new Dictionary());

    get navigationCanceled(): Observable<void> {
        return this._navigationCanceled;
    }
    private _navigationCanceled: Subject<void> = new Subject();

    get onNavigate(): boolean {
        return this._onNavigate;
    }
    private _onNavigate: boolean = false;

    constructor(
        private readonly _router: Router,
        private readonly _title: Title,
        private readonly _srvTranslate: TranslateService,
        private readonly _appSvc: ApplicationService,
        private _logger: Logger,
        @Inject(DOCUMENT) private _document: Document
    ) {
        combineLatest([
            this._appSvc.contractChange, this._appSvc.shipperChange
        ]).subscribe(([appContract, shipper]) => {
            if (appContract != null) {
                if (this._appSvc.shippers && this._appSvc.shippers.length > 1) {
                    this.setContractSelected(appContract.contract, shipper);
                } else {
                    this.setContractSelected(appContract.contract);
                }
            }
        });

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this._isFirstActivationEnd = true;
                this._hasCustomTitle = false;
                this._onNavigate = true;
                this._queryParams = {};
                this._subjectParamsChange.next(undefined);
                this._subjectDataParamsChange.next(undefined);
                this._subjectUriParamsChange.next(undefined);
                this._subjectQueryParamsChange.next(undefined);
            } else if (event instanceof ActivationEnd) {
                if (this._isFirstActivationEnd && this._setPageName(event.snapshot.url)) {
                    this._isFirstActivationEnd = false;
                }
                if (event.snapshot.component != undefined && event.snapshot.outlet === "primary") {
                    if (this._component !== event.snapshot.component) {
                        this._component = event.snapshot.component;
                        this._dataParams = {};
                    }
                }
            } else if (event instanceof NavigationEnd) {
                this._onNavigate = false;
                const params = this._initQueryParams(this.router.routerState.root);
                this._subjectParamsChange.next(params);
                this._subjectDataParamsChange.next(params.data);
                this._subjectUriParamsChange.next(params.uri);
                this._subjectQueryParamsChange.next(params.query);
            } else if (event instanceof NavigationCancel) {
                this._onNavigate = false;
                this._initQueryParams(this.router.routerState.root);
                this._navigationCanceled.next();
            } else if (event instanceof NavigationError) {
                this._onNavigate = false;
            }
        });
    }

    setContractSelected(contract: IContract, shipper: IOrganisation | undefined = undefined): void {
        this._contract = contract;
        if (shipper) {
            this._appTitle.contractName = shipper.shortName;
        } else {
            this._appTitle.contractName = this._contract ? this._contract.shortName : '';
        }
    }

    isActiveUrl(url: string, options: IsActiveMatchOptions): boolean {
        return this._router.isActive(url, options);
    }

    isActivePage(page: AppPages, options: { params?: { [key: string]: string }, queryParams?: { [key: string]: any }, contractId?: string, queryParamsHandling?: QueryParamsHandling, exact?: boolean } = {}): boolean {
        let matchOptions: IsActiveMatchOptions;
        if (options.exact || options.exact == undefined) {
            matchOptions = {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'};
        } else {
            matchOptions = {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'}
        }
        const url = this.getUrl(page, options);
        return this.isActiveUrl(url, matchOptions);
    }

    private _buildParamsRecursive(route: ActivatedRoute,
        dataParams: IDictionary<Data> = new Dictionary(),
        uriParams: IDictionary<Params> = new Dictionary(),
        queryParams: IDictionary<Params> = new Dictionary()): PageParams {
        const outlet = route.snapshot.outlet;
        try {
            const data = route.snapshot.data;
            const oldData = dataParams.get(outlet) || {};
            const newData = Object.assign(oldData, data);
            dataParams.add(outlet, newData);
        } catch (error: any) {
            this._logger.warn("Build Data Params Recursive Error", error);
        }
        try {
            const params = route.snapshot.params;
            const oldParams = uriParams.get(outlet) || {};
            const newParams = Object.assign(oldParams, params);
            uriParams.add(outlet, newParams);
        } catch (error: any) {
            this._logger.warn("Build Uri Params Recursive Error", error);
        }
        route.children.forEach((child) => {
            this._buildParamsRecursive(child, dataParams, uriParams);
        });

        try {
            const query = route.snapshot.queryParams;
            const oldParams = queryParams.get(outlet) || {};
            const newParams = Object.assign(oldParams, query);
            queryParams.add(outlet, newParams);
        } catch (error: any) {
            this._logger.warn("Build Query Params Recursive Error", error);
        }
        route.children.forEach((child) => {
            this._buildParamsRecursive(child, dataParams, uriParams);
        });
        return { data: dataParams, uri: uriParams, query: queryParams };
    }

    private _initQueryParams(_route: ActivatedRoute): PageParams {
        const params = this._buildParamsRecursive(this.router.routerState.root);
        const tempKeys = !!this._queryParams ? Object.keys(this._queryParams) : [];
        const tempQuery = params.query.get(PRIMARY_OUTLET);
        const hasTempQuery = tempKeys.length > 0;
        if (hasTempQuery) {
            tempKeys.forEach((key) => {
                tempQuery[key] = !!this._queryParams ? this._queryParams[key] : {};
            });
        }
        this._queryParams = this._orderParams(tempQuery);
        if (hasTempQuery) {
            this._router.navigate([], { queryParams: this._queryParams, replaceUrl: true });
        }
        return params;
    }

    initParams(data: IDataParams): void {
        _.forEach(_.keys(data), (key: string) => {
            let qpinfo = DataParamMetaData.getMetaData<any>(data, key);
            if (qpinfo) {
                let defaultValue: any;
                const values: any | any[] = (<any>data)[key];
                switch (qpinfo.defaultType) {
                    case "all":
                        defaultValue = _.cloneDeep(values);
                        break;
                    case "first":
                        defaultValue = _.cloneDeep(_.first(values));
                        break;
                    case "value":
                        defaultValue = values;
                        break;
                    case "empty":
                        defaultValue = undefined;
                        break;
                    case "property":
                        defaultValue = !!qpinfo.defaultTypeProperty ? (<any>data)[qpinfo.defaultTypeProperty] : undefined;
                        break;
                }
                this._dataParams[qpinfo.key] = new DataParam(qpinfo, (<any>data)[key], defaultValue);
            }
        });
    }

    initQueryParams(query: IQueryParams): void {
        _.forEach(_.keys(query), (key: string) => {
            let qpinfo: IQueryParamMetaData<any> = QueryParamMetaData.getMetaData<any>(query, key);
            if (qpinfo) {
                if (!!this._queryParams && this._queryParams[qpinfo.key] !== undefined) {
                    if (qpinfo.isArray) {
                        let value = this._queryParams[qpinfo.key].split(",");
                        if (qpinfo.type === Number) {
                            _.forEach(value, (item, i) => {
                                value[i] = parseFloat(item);
                            });
                        } else if (qpinfo.type === Boolean) {
                            _.forEach(value, (item, i) => {
                                value[i] = item == null ? undefined : item === "true";
                            });
                        } else if (qpinfo.type === Date) {
                            _.forEach(value, (item, i) => {
                                value[i] = new Date(item);
                            });
                        } else if (qpinfo.type === DateTime) {
                            _.forEach(value, (item, i) => {
                                const date = new DateTime(item);
                                value[i] = qpinfo.format === "date" ? date.startOf(TimeUnit.Days) : date;
                            });
                        }
                        (<any>query)[key] = [];
                        if (this._dataParams[qpinfo.key] !== undefined && !this._dataParams[qpinfo.key]?.meta.noCheck) {
                            let values = this._dataParams[qpinfo.key]?.values(query);
                            _.forEach(values, (item: any) => {
                                let vkey = qpinfo.property !== undefined ? item[qpinfo.property] : item;
                                if (_.indexOf(value, vkey) > -1) {
                                    (<any>query)[key].push(_.cloneDeep(item));
                                }
                            });
                        } else {
                            (<any>query)[key] = value;
                        }
                    } else {
                        let value = this._queryParams[qpinfo.key];
                        if (qpinfo.type === Number) {
                            value = parseFloat(value);
                        } else if (qpinfo.type === Boolean) {
                            value = value == null ? undefined : value === "true";
                        } else if (qpinfo.type === Date) {
                            value = new Date(value);
                        } else if (qpinfo.type === DateTime) {
                            const date = new DateTime(value);
                            if (date.isValid) {
                                value = qpinfo.format === "date" ? date.startOf(TimeUnit.Days) : date;
                            }
                        } else if (qpinfo.type === DateRange) {
                            let range = value.split('_');
                            if (range.length > 0) {
                                const date1 = new DateTime(range[0], TimeZone.local().name).toUtc().convertTo(TimeZone.current());
                                const date2 = range.length > 1 ? new DateTime(range[1], TimeZone.local().name).toUtc().convertTo(TimeZone.current()) : date1.clone();
                                if (date1.isValid && date2.isValid) {
                                    value = qpinfo.format === "date" ? new DateRange(date1.startOf(TimeUnit.Days), date2.endOf(TimeUnit.Days)) : new DateRange(date1, date2);
                                }
                            }
                        }
                        if (this._dataParams[qpinfo.key] !== undefined && !this._dataParams[qpinfo.key]?.meta.noCheck) {
                            let values = this._dataParams[qpinfo.key]?.values(query);
                            (<any>query)[key] = undefined;
                            _.forEach(values, (item: any) => {
                                let vkey = qpinfo.property !== undefined ? item[qpinfo.property] : item;
                                if (value === vkey) {
                                    (<any>query)[key] = _.cloneDeep(item);
                                }
                            });
                            if ((<any>query)[key] === undefined) {
                                (<any>query)[key] = this._dataParams[qpinfo.key]?.defaultValue(qpinfo, query);
                            }
                        } else {
                            (<any>query)[key] = value;
                        }
                    }
                } else if (this._dataParams[qpinfo.key] !== undefined) {
                    (<any>query)[key] = this._dataParams[qpinfo.key]?.defaultValue(qpinfo, query);
                }
            }
        });
    }

    updateQueryParams(query: IQueryParams, replaceUrl?: boolean): void {
        let hasChangeUrl: boolean = false;
        _.forEach(_.keys(query), (key: string) => {
            let qpinfo: IQueryParamMetaData<any> = QueryParamMetaData.getMetaData<any>(query, key);
            if (qpinfo) {
                if (this._dataParams[qpinfo.key] !== undefined) {
                    let defaultValue = this._dataParams[qpinfo.key]?.defaultValue(qpinfo, query);
                    let value = (<any>query)[key];
                    if (qpinfo.isArray) {
                        if ((defaultValue.length === 0 && (value == null || value.length === 0))
                            || !_.some(defaultValue, (dv) => !_ez.containsSame(value, dv)) && defaultValue.length === value.length) {
                            if (this._pushQueryParams(qpinfo.key, undefined, { replaceUrl: replaceUrl, formatDate: 'date' })) {
                                hasChangeUrl = true;
                            }
                            return;
                        }
                    } else {
                        if (_ez.isSame(value, defaultValue)) {
                            if (this._pushQueryParams(qpinfo.key, undefined, { replaceUrl: replaceUrl, formatDate: 'date' })) {
                                hasChangeUrl = true;
                            }
                            return;
                        }
                    }
                }
                const qpValues: string[] = [];
                if ((<any>query)[key] !== undefined) {
                    const value = _.isArray((<any>query)[key]) ? (<any>query)[key] : [(<any>query)[key]];
                    _.forEach(value, (item: any) => {
                        let value = qpinfo.property !== undefined ? item[qpinfo.property] : item;
                        if (_.isDate(value)) {
                            qpValues.push(value.toISOString());
                        } else if (value instanceof DateTime) {
                            if (qpinfo.format === "date") {
                                qpValues.push(value.date.toISODate());
                            } else {
                                qpValues.push(value.toString());
                            }
                        } else if (value instanceof DateRange) {
                            if (qpinfo.format === "date") {
                                qpValues.push(value.from.date.toISODate() + "_" + value.to.date.toISODate());
                            } else {
                                qpValues.push(value.from.toString() + "_" + value.to.toString());
                            }
                        }
                        else {
                            qpValues.push(value);
                        }
                    });
                }
                if (this._pushQueryParams(qpinfo.key, qpValues.length > 0 ? qpValues.join(',') : undefined, { replaceUrl: replaceUrl, formatDate: 'date' })) {
                    hasChangeUrl = true;
                }
            }
        });

        if (hasChangeUrl) {
            this._router.navigate([], { queryParams: this._queryParams, replaceUrl: replaceUrl ?? false });
        }
    }

    private _pushQueryParams(key: string, value: any, opts: { replaceUrl?: boolean | undefined, formatDate?: 'date' | 'datetime' } = {}): boolean {
        let v: string;
        if (typeof (value) === "string" || value === undefined) {
            v = value;
        } else if (value instanceof DateTime) {
            v = opts.formatDate === 'datetime' ? value.toISO() : value.toISODate();
        } else if (value instanceof DateRange) {
            v = opts.formatDate === 'datetime' ? `${value.from.toISO()}_${value.to.toISO()}` : `${value.from.toISODate()}_${value.to.toISODate()}`;
        } else if (value.sameKey) {
            v = value.sameKey;
        } else if (typeof (value.toString) === "function") {
            v = value.toString();
        } else {
            v = value;
        }
        if (!!this._queryParams && !isEqual(this._queryParams[key], v)) {
            this._queryParams[key] = v;
            this._queryParams = this._orderParams(this._queryParams);
            return true;
        }
        return false;
    }

    public setAppTitle(title: string): void {
        this._title.setTitle(title);
        this._hasCustomTitle = true;
    }

    public getQueryParams<T>(key: string, type?: T): any {
        if (!!this._queryParams && this._queryParams[key]) {
            if (type && Array.isArray(type)) {
                let arrayParams = this._queryParams[key].split(',');
                return <T>arrayParams;
            }
            return <T>this._queryParams[key];
        }
        return undefined;
    }

    setQueryParams(key: string, value: any, opts: { replaceUrl?: boolean, formatDate?: 'date' | 'datetime' } = {}): void {
        if (this._pushQueryParams(key, value, opts)) {
            if (!this._onNavigate) {
                this._router.navigate([], { queryParams: this._queryParams, replaceUrl: opts.replaceUrl ?? false });
            }
        }
    }

    /**
     * @deprecated use setQueryParams
     */
    public pushQueryParams(key: string, value: any, replaceUrl: boolean = false) {
        if (this._pushQueryParams(key, value, { replaceUrl })) {
            if (!this._onNavigate) {
                this._router.navigate([], { queryParams: this._queryParams, replaceUrl: replaceUrl });
            }
        }
    }

    navigateByUrl(destination: string | UrlTree, options? : { inNewTab?: boolean} ): Promise<boolean> {
        const {inNewTab} = options || {};

        let urlTree : UrlTree;
        if(typeof destination ===  'string'  ) {
            urlTree = this.router.parseUrl(destination);
        }else {
            urlTree = destination;
        }

        if (inNewTab) {
            const url = `${this.hostname}#${this._router.serializeUrl(urlTree)}`;
            window.open(url, '_blank');
            return Promise.resolve(true);
        } else {
            return this._router.navigateByUrl(urlTree);
        }
    }

    navigateQueryParams(querys: { [key: string]: any }, options: { replaceUrl?: boolean, queryParamsHandling?: QueryParamsHandling } = {}): void {
        if (querys[AppQueryParamsKey.Shipper] == null && this._appSvc.shipper != null) {
            querys[AppQueryParamsKey.Shipper] = this._appSvc.shipper.id;
        }
        if (querys[AppQueryParamsKey.Date] == null && this._appSvc.date != null) {
            querys[AppQueryParamsKey.Date] = `${this._appSvc.date.toISODate()}`;
        }
        this._router.navigate([], { queryParams: querys, replaceUrl: options.replaceUrl ?? false, queryParamsHandling: options.queryParamsHandling ?? null });
    }

    public navigateTo(page: AppPages, options: { inNewTab?: boolean, params?: { [key: string]: string }, queryParams?: { [key: string]: any }, contractId?: string, queryParamsHandling?: QueryParamsHandling } = {}): void {
        let segments: string[] = this._getUrlSegmentByPage(page, options.params, options.contractId);
        if (options.queryParams == null) {
            options.queryParams = {};
        }
        if (options.queryParams[AppQueryParamsKey.Shipper] == null && this._appSvc.shipper != null) {
            options.queryParams[AppQueryParamsKey.Shipper] = this._appSvc.shipper.id;
        }
        if (options.queryParams[AppQueryParamsKey.Date] == null && this._appSvc.date != null) {
            options.queryParams[AppQueryParamsKey.Date] = `${this._appSvc.date.toISODate()}`;
        }

        let maxItems = null;
        if (options.queryParams[AppQueryParamsKey.MaxItems] != null) {
            maxItems = Number(options.queryParams[AppQueryParamsKey.MaxItems]);
        }

        let deliveryDates = null;
        if (options.queryParams[AppQueryParamsKey.DateDeliverys] != null) {
            const dates = options.queryParams[AppQueryParamsKey.DateDeliverys].split('_');
            if (dates[0] != null && dates[1] != null) {
                const startDate = new DateTime(dates[0]).startOf(TimeUnit.Days);
                const endDate = new DateTime(dates[1]).endOf(TimeUnit.Days);
                if (startDate != null && endDate != null)
                    deliveryDates = new DateRange(startDate, endDate);
            }
        }

        switch (page) {
            case AppPages.Positions:
                if (options.queryParams[AppQueryParamsKey.Granularity] != null) {
                    delete options.queryParams[AppQueryParamsKey.Granularity];
                }
                if (deliveryDates != null && this._appSvc.preferences) {
                    this._appSvc.preferences.pages.positions.dates = deliveryDates;
                }
                break;
            case AppPages.NomMessages:
                if (deliveryDates != null && this._appSvc.shipperPreferences) {
                    this._appSvc.shipperPreferences.pages.nomMessages.deliveryDates = deliveryDates;
                    this._appSvc.shipperPreferences.pages.nomMessages.withDate = true;
                }
                if (maxItems != null && !isNaN(maxItems) && this._appSvc.shipperPreferences) {
                    this._appSvc.shipperPreferences.pages.nomMessages.maxItems = maxItems;
                }
                break;
            case AppPages.NomBatch:
                if (deliveryDates != null && this._appSvc.shipperPreferences) {
                    this._appSvc.shipperPreferences.pages.nomBatch.deliveryDates = deliveryDates;
                    this._appSvc.shipperPreferences.pages.nomBatch.withDate = true;
                }
                if (maxItems != null && !isNaN(maxItems) && this._appSvc.shipperPreferences) {
                    this._appSvc.shipperPreferences.pages.nomBatch.maxItems = maxItems;
                }
                if (options.queryParams[AppQueryParamsKey.TypeMessage] != null && this._appSvc.shipperPreferences) {
                    this._appSvc.shipperPreferences.pages.nomBatch.typeMessage = options.queryParams[AppQueryParamsKey.TypeMessage];
                    delete options.queryParams[AppQueryParamsKey.TypeMessage];
                }
                break;
            case AppPages.NomRealTime:
                if (options.queryParams[AppQueryParamsKey.NominationState] != null && this._appSvc.shipperPreferences) {
                    const states = options.queryParams[AppQueryParamsKey.NominationState].split(',');
                    this._appSvc.shipperPreferences.pages.nomRealtime.states = [];
                    states.forEach(
                        (st: any) => {
                            if (this._appSvc.shipperPreferences) {
                                this._appSvc.shipperPreferences.pages.nomRealtime.states?.push(st);
                            }
                        }
                    );
                }
                if (options.queryParams[AppQueryParamsKey.ExcludedZero] != null && this._appSvc.shipperPreferences) {
                    this._appSvc.shipperPreferences.pages.nomRealtime.excludedZeroNominations = options.queryParams[AppQueryParamsKey.ExcludedZero] ? true : false;
                    delete options.queryParams[AppQueryParamsKey.ExcludedZero];
                }
                break;
            default:
                break;
        }

        const urlTree = this._router.createUrlTree(segments, { queryParams: this._orderParams(options.queryParams), queryParamsHandling: options.queryParamsHandling ?? null });
        this.navigateByUrl(urlTree, options);       
    }

    public navigateToPopup(page: AppPages, params?: { [key: string]: string }, queryParams?: { [key: string]: any }, preserveQueryParams?: boolean): void {
        let segments: string[] = this._getUrlSegmentByPopup(page, params);
        this._router.navigate(['/', { outlets: { popup: segments } }], { queryParams: queryParams ?? null, queryParamsHandling: preserveQueryParams ? "preserve" : "" });
    }

    public navigateToAside(page: AppPages, params?: { [key: string]: string }): void {
        let segments: string[] = this._getUrlSegmentByAside(page, params);
        this._router.navigate(['/', { outlets: { aside: segments } }], { queryParamsHandling: "preserve" });
    }

    public closeOutletPopup(): Promise<boolean> {
        return this._router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: "merge" });
    }

    public closeOutletAside(): Promise<boolean> {
        return this._router.navigate([{ outlets: { aside: null } }], { queryParamsHandling: "merge" });
    }

    public getUrl(page: AppPages, options: { params?: { [key: string]: string }, queryParams?: { [key: string]: any }, contractId?: string, queryParamsHandling?: QueryParamsHandling } = {}): string {
        return this.getUrlTree(page, options).toString();
    }

    public getUrlPopup(page: AppPages, params?: { [key: string]: string } , queryParams?: { [key: string]: any }, contractId?: string): string {
        let segments: string[] = this._getUrlSegmentByPopup(page, params, contractId);
        let urlTree = this._router.createUrlTree(['/', { outlets: { popup: segments } }], { queryParams: queryParams ?? null, queryParamsHandling: "preserve" });
        return urlTree.toString();
    }

    public getUrlAside(page: AppPages, params?: { [key: string]: string }): string {
        let segments: string[] = this._getUrlSegmentByAside(page, params);
        let urlTree = this._router.createUrlTree(['/', { outlets: { popup: segments } }]);
        return urlTree.toString();
    }

    public getFullUrl(page: AppPages, options: { params?: { [key: string]: string }, queryParams?: { [key: string]: any }, contractId?: string, queryParamsHandling?: QueryParamsHandling } = {}): string {
        return this.hostname + "#" + this.getUrl(page, options);
    }

    getUrlTree(page: AppPages, options: { params?: { [key: string]: string }, queryParams?: { [key: string]: any }, contractId?: string, queryParamsHandling?: QueryParamsHandling } = {}): UrlTree {
        let segments: string[] = this._getUrlSegmentByPage(page, options.params, options.contractId);
        if (options.queryParams == null) {
            options.queryParams = {};
        }
        if (options.queryParams[AppQueryParamsKey.Shipper] == null && this._appSvc.shipper != null) {
            options.queryParams[AppQueryParamsKey.Shipper] = this._appSvc.shipper.id;
        }
        if (options.queryParams[AppQueryParamsKey.Date] == null && this._appSvc.date != null) {
            options.queryParams[AppQueryParamsKey.Date] = `${this._appSvc.date.toISODate()}`;
        }
        return this._router.createUrlTree(segments, { queryParams: this._orderParams(options.queryParams), queryParamsHandling: options.queryParamsHandling ?? null });
    }

    private _orderParams(params?: { [key: string]: string }): { [key: string]: string } | null {
        if (!params) {
            return null;
        }

        let keys: string[] = [];
        for (let key in params) {
            keys.push(key);
        }
        keys = sortBy(keys, (a) => a);
        const p = {};
        keys.forEach((k) => {
            (<any>p)[k] = params[k];
        });
        return p;
    }

    private _processOriginUrl(): void {
        if (this._document && this._document.location && this._document.location.href) {
            let indexHash = this._document.location.href.indexOf('#');
            this._hostname = this._document.location.href.substring(0, indexHash);
        }
    }

    private _getUrlSegmentByAside(page: AppPages, _params: { [key: string]: string } = {}): string[] {
        let urlSegments: string[] = [];
        switch (page) {
            case AppPages.Help:
                urlSegments.push("help");
                urlSegments.push("view");
                break;
            case AppPages.HelpEdit:
                urlSegments.push("help");
                urlSegments.push("edit");
                break;
            default:
                break;
        }
        return urlSegments;
    }

    private _getUrlSegmentByPopup(page: AppPages, params: { [key: string]: string } = {}, _contractId?: string): string[] {
        let urlSegments: string[] = [];
        switch (page) {
            case AppPages.SessionParamExchange:
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("exchange");
                break;
            case AppPages.SessionParamPrice:
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("price");
                break;
            case AppPages.SessionParamStrategy:
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("strategy");
                break;
            case AppPages.SessionParamVolume:
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("volume");
                break;
            case AppPages.SessionParamTermination:
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("termination");
                break;
            case AppPages.SessionReportLive:
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("reports");
                urlSegments.push("live");
                break;
            case AppPages.SessionReportPast:
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("reports");
                urlSegments.push("past");
                break;
            case AppPages.SessionQuickEdit:
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("quick-edit");
                break;
            case AppPages.SessionCreate:
                urlSegments.push("create-session");
                if (params["id"])
                    urlSegments.push(params["id"]);
                break;
            case AppPages.DealForm:
                urlSegments.push("deals");
                if (params["shipperId"]) {
                    urlSegments.push(params["shipperId"]);
                }
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push(params["gridid"] ?? 'new');
                break;
            case AppPages.EventsReport:
                urlSegments.push("events");
                break;
            case AppPages.AuctionSchedulesCreate:
                urlSegments.push("create-auction-schedule");
                if (params['sh']) {
                    urlSegments.push(params['sh']);
                }
                if (params['da']) {
                    urlSegments.push(params['da']);
                }
                if (params['scl'])
                    urlSegments.push(params['scl']);
                break;
        }
        return urlSegments;
    }

    private _getUrlSegmentByPage(page: AppPages, params: { [key: string]: string } = {}, contractId?: string): string[] {
        let urlSegments: string[] = ["/"];
        let cid: string | undefined = contractId;
        if (!cid && this._contract) {
            cid = this._contract.contractGuid;
        }
        switch (page) {
            case AppPages.Login:
                urlSegments.push("login");
                urlSegments.push(page);
                break;
            case AppPages.Logout:
                urlSegments.push("logout");
                break;
            case AppPages.Dashboard:
            case AppPages.BalancingOverview:
            case AppPages.Map:
            case AppPages.Deals:
            case AppPages.Positions:
            case AppPages.Ets:
            case AppPages.NomOverview:
            case AppPages.NomRealTime:
            case AppPages.NomMessages:
            case AppPages.NomBatch:
            case AppPages.NomConfiguration:
            case AppPages.AssetMessages:
            case AppPages.AssetBatch:
            case AppPages.TAO:
            case AppPages.ConfigFiles:
            case AppPages.ConfigBatch:
            case AppPages.ConfigCounterparts:
            case AppPages.TSFormula:
            case AppPages.ConfigMapping:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push(...page.split('/'));
                if (params['gridType'] != null) {
                    urlSegments.push(params['gridType']);
                }
                break;
            case AppPages.Session:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                break;
            case AppPages.SessionOverview:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("overview");
                break;
            case AppPages.SessionMarket:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("market");
                break;
            case AppPages.SessionReporting:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                if (params['runNumber']) {
                    urlSegments.push(params['runNumber']);
                } else {
                    urlSegments.push("reporting");
                }
                break;
            case AppPages.SessionParamExchange:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("exchange");
                break;
            case AppPages.SessionParamPrice:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("price");
                break;
            case AppPages.SessionParamStrategy:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("strategy");
                break;
            case AppPages.SessionParamVolume:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("volume");
                break;
            case AppPages.SessionParamTermination:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("parameters");
                urlSegments.push("termination");
                break;
            case AppPages.SessionReportLive:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("reports");
                urlSegments.push("live");
                break;
            case AppPages.SessionReportPast:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("reports");
                urlSegments.push("past");
                break;
            case AppPages.SessionQuickEdit:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("quick-edit");
                break;
            case AppPages.SessionCreate:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("create-session");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                break;
            case AppPages.Intraday:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("auto");
                break;
            case AppPages.IntradayLive:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("live");
                break;
            case AppPages.IntradayReady:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("ready");
                break;
            case AppPages.IntradayConfigure:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("autotrading");
                urlSegments.push("intraday");
                urlSegments.push("configure");
                break;
            case AppPages.Offline:
                urlSegments.push("offline");
                break;
            case AppPages.TsEdit:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("timeseries-edit");
                break;
            case AppPages.TsAdmin:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("timeseries-admin");
                if (params['groupId'] == null)
                    urlSegments.push("all");
                else
                    urlSegments.push(params['groupId']);
                break;
            case AppPages.AuctionOrders:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                break;
            case AppPages.AuctionOverview:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("overview");
                break;
            case AppPages.AuctionOrdersExchange:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                break;
            case AppPages.AuctionOrdersExchangeLinear:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                if (params['internalId'] != null) {
                    urlSegments.push(params['internalId']);
                }
                urlSegments.push("linear");
                if (params['version']) {
                    urlSegments.push(params['version']);
                }
                break;
            case AppPages.AuctionOrdersExchangeLinearVersion:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                if (params['internalId'] != null) {
                    urlSegments.push(params['internalId']);
                }
                urlSegments.push("linear");
                if (params['version']) {
                    urlSegments.push(params['version']);
                }
                break;
            case AppPages.AuctionOrdersExchangeLinearWorking:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                if (params['internalId'] != null) {
                    urlSegments.push(params['internalId']);
                }
                urlSegments.push("linear");
                urlSegments.push("working");
                break;
            case AppPages.AuctionOrdersExchangeLinearOnline:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("linear");
                urlSegments.push("online");
                break;
            case AppPages.AuctionOrdersExchangeBlock:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                if (params['internalId'] != null) {
                    urlSegments.push(params['internalId']);
                }
                urlSegments.push("block");
                if (params['version']) {
                    urlSegments.push(params['version']);
                }
                break;
            case AppPages.AuctionOrdersExchangeBlockVersion:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                if (params['internalId'] != null) {
                    urlSegments.push(params['internalId']);
                }
                urlSegments.push("block");
                if (params['version']) {
                    urlSegments.push(params['version']);
                }
                break;
            case AppPages.AuctionOrdersExchangeBlockWorking:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                if (params['internalId'] != null) {
                    urlSegments.push(params['internalId']);
                }
                urlSegments.push("block");
                urlSegments.push("working");
                break;
            case AppPages.AuctionOrdersExchangeBlockOnline:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("block");
                urlSegments.push("online");
                break;
            case AppPages.AuctionExchangeMarketResult:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("exchange");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                if (params['internalId'] != null) {
                    urlSegments.push(params['internalId']);
                }
                urlSegments.push("market-result");
                break;
            case AppPages.AuctionTemplateInternal:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("orders");
                urlSegments.push("internal");
                if (params["id"]) {
                    urlSegments.push(params["id"]);
                }
                urlSegments.push("template");
                break;

            case AppPages.AuctionSchedulesCreate:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("schedules");
                if (params['sh']) {
                    urlSegments.push(params['sh']);
                }
                if (params['da']) {
                    urlSegments.push(params['da']);
                }
                if (params['scl']) {
                    urlSegments.push(params['scl']);
                }
                break;
            case AppPages.AuctionSchedules:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("auction");
                urlSegments.push("schedules");
                break;
            case AppPages.UsersOverview:
                if (cid){
                    urlSegments.push(cid);
                }
                urlSegments.push("users-overview");
                break;
            default:
                // code...
                break;
        }
        return urlSegments;
    }

    private _setPageName(url: UrlSegment[]): boolean {
        if (url.length) {
            if (this._hasCustomTitle) {
                return true;
            }
            let name = url.join('-');
            let label = name.split('-')[0];
            let namePage = 'main.pageName.' + name;
            let nameSection = 'main.' + label + '.label';

            this._srvTranslate.get([namePage, nameSection]).subscribe(
                (trads) => {
                    this._appTitle.pageName = trads[namePage] != namePage ? trads[namePage] : "";
                    this._appTitle.sectionName = trads[nameSection] != nameSection ? trads[nameSection] : "";
                    this._title.setTitle(this._appTitle.toString());
                },
                (_error) => {
                    this._appTitle.pageName = "";
                    this._appTitle.sectionName = "";
                    this._title.setTitle(this._appTitle.toString());
                });
            return true;
        }
        return false;
    }

    reload(): void {
        let lastValue = this._router.onSameUrlNavigation;
        this._router.onSameUrlNavigation = 'reload';
        let url = this._router.url;
        this._router.navigateByUrl("/login").then(() => {
            this._router.navigateByUrl(url).then(() => {
                this._router.onSameUrlNavigation = lastValue;
            });
        });
    }
}

export class AppTitle {
    contractName: string = "";
    pageName: string = "";
    sectionName: string = "";

    public toString = (): string => {
        let title = '';
        if (this.contractName) {
            title += ` - ${this.contractName}`;
        } else {
            title += ' - eZ-Ops';
        }
        if (this.sectionName) {
            title += ` - ${this.sectionName}`;
        }
        if (this.pageName) {
            title += ` - ${this.pageName}`;
        }

        if (title.length > 3)
            title = title.substring(3);

        return title;
    }
}
