import { Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, ViewEncapsulation } from "@angular/core";
import { Subscriptions } from "@core/subscriptions";
import { coerceBoolean } from "@eznergy/core";
import { DateTime, Timespan } from "@eztypes/generic";
import { IGrid } from "@eztypes/webapi";
import { ApplicationService } from "@services/application.service";
import { timer } from "rxjs";

@Component({
    selector: 'title-page',
    templateUrl: "./title-page.component.html",
    styleUrls: ['./title-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TitlePageComponent {

    @Output() readonly refresh: EventEmitter<void> = new EventEmitter();
    @Output() readonly changeTimeValue: EventEmitter<number> = new EventEmitter();

    readonly hasRefresh: boolean;
    readonly hasAutoRefresh: boolean;
    readonly noGrid: boolean;

    @Input()
    get timerAt(): number {
        return this._timerAt;
    }
    set timerAt(value: number) {
        if (this.timerAt !== value) {
            this._timerAt = value ?? 5;
            if (this.onAutoRefresh) {
                this._startAt();
            }
            this._cdr.markForCheck();
        }
    }
    private _timerAt: number = 5;

    @Input()
    get grid(): IGrid | undefined {
        return this._grid;
    }
    set grid(value: IGrid | undefined) {
        if (value != this.grid) {
            this._grid = value;
            this._cdr.markForCheck();
        }
    }
    private _grid: IGrid | undefined;

    get displayGrid(): boolean {
        return this._displayGrid;
    }
    private _displayGrid: boolean = false;

    get onAutoRefresh(): boolean {
        return this._onAutoRefresh;
    }
    private _onAutoRefresh: boolean = false;

    get lastDateRefresh(): DateTime | undefined {
        return this._lastDateRefresh;
    }
    private _lastDateRefresh: DateTime | undefined;

    private readonly _subs: Subscriptions = new Subscriptions();
    private readonly _keyAt: string = "at-timer";
    constructor(private readonly _cdr: ChangeDetectorRef,
        private readonly _appSvc: ApplicationService,
        @Attribute("hasRefresh") @Optional() _hasRefresh: string,
        @Attribute("hasAutoRefresh") @Optional() _hasAutoRefresh: string,
        @Attribute("noGrid") @Optional() _noGrid: string) {
        this.hasRefresh = coerceBoolean(_hasRefresh ?? false, true);
        this.hasAutoRefresh = coerceBoolean(_hasAutoRefresh ?? false, true);
        this.noGrid = coerceBoolean(_noGrid ?? false, true);
        if (this.hasAutoRefresh) {
            this._startAt();
        }
    }

    ngOnInit(): void {
        const sub = this._appSvc.hasDisplayGridCtlChange.subscribe((value) => {
            this._displayGrid = value;
            this._cdr.detectChanges();
        });
        this._subs.push("display-grid", sub);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    toggleDisplayGridSelector(): void {
        this._displayGrid = !this.displayGrid;
        this._appSvc.displayGridCtl(this.displayGrid);
    }

    onRefresh(): void {
        this.refresh.emit();
        this._lastDateRefresh = DateTime.now();
    }

    timerValueChange(timer: number): void {
        if (this.timerAt === timer)
            return;
        this.timerAt = timer > 0 ? timer : 1;
        this.changeTimeValue.emit(this.timerAt);
    }

    toggleAt(): void {
        if (!this.onAutoRefresh) {
            this._startAt();
        } else {
            this._stopAt();
        }
    }

    private _startAt(): void {
        this._onAutoRefresh = true;
        const time = Timespan.fromMinutes(this._timerAt).totalMilliseconds;
        const sub = timer(time, time).subscribe(() => {
            this.onRefresh();
            this._cdr.detectChanges();
        });
        this._subs.push(this._keyAt, sub);
    }

    private _stopAt(): void {
        this._subs.clearSub(this._keyAt);
        this._onAutoRefresh = false;
    }

}
