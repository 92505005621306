<ng-container *ngIf="isOnEdition; else displayMode">
    <ez-markdown-editor #_editor *ngIf="currentUser && currentUser.isAdmin" class="markdown" [value]="helpEntry"
        [baseUrl]="baseUrl" (save)='onSaveMarkdown()' (cancel)="onCancelMarkdown()">
        <ez-loader *ngIf="_loaderPage.onLoading"></ez-loader>
    </ez-markdown-editor>
</ng-container>
<ng-template #displayMode>
    <!-- <ez-card [type]="'small'" class="header" *ngIf="currentUser && currentUser.isAdmin">
        <div class="left">
            <ez-button class="btn-edit" [icon]="'pencil-alt'" [ezTooltip]="'Edit current help page'"
                (onClick)="onEditHelpClick()">
            </ez-button>
        </div>
        <div class="right" *ngIf="hasToRefresh">
            <span>{{ 'aside.help.pending-update' | translate }}</span>
            <ez-button class="btn-edit" [icon]="'redo'" [disabled]="_loaderPage.onLoading" (onClick)="onUpdateClick()">
            </ez-button>
        </div>

    </ez-card> -->
    <ez-markdown class="markdown" [gfm]="true" [breaks]="true" [data]="helpEntry.content" [baseUrl]="baseUrl">
    </ez-markdown>
    <ez-loader *ngIf="_loaderPage.onLoading"></ez-loader>
</ng-template>

<ez-modal [(open)]="openConfirm" class="modal-confirm" (onClose)="onConfirmCancel($event)">
    <ez-header>{{ "general.confirm" | translate }}</ez-header>
    {{ "aside.help.confirm-loss" | translate }}
    <ez-footer>
        <ez-button [label]="'general.cancel' | translate" ez-modal-close></ez-button>
        <ez-button [label]="'general.confirm' | translate" [severity]="'primary'" [ez-modal-close]="true"></ez-button>
    </ez-footer>
</ez-modal>