// @ts-nocheck
import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input, HostBinding, ViewChild } from '@angular/core';
import { Subscriptions } from '@core';
import { EzPopoverComponent } from '@eznergy/components';
import * as _ez from "@eznergy/core";

@Component({
    selector: 'base-card',
    template: '',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseCardComponent<T> {

    @Input()
    get value(): T {
        return this._value;
    }
    set value(value: T) {
        if (this.value !== value) {
            this._value = value;
            this._init();
            this._cdr.markForCheck();
        }
    }
    private _value: T;

    @HostBinding("class.light")
    @Input()
    get light(): boolean {
        return this._light;
    }
    set light(value: boolean) {
        const newValue = _ez.coerceBoolean(value, true);
        if (this.light !== newValue) {
            this._light = newValue;
            this._cdr.markForCheck();
        }
    }
    private _light: boolean = true;

    @HostBinding("class.ob-card")
    readonly isObCard: boolean = true;

    readonly _subs: Subscriptions = new Subscriptions();

    constructor(protected readonly _cdr: ChangeDetectorRef) {

    }

    _init(): void {

    }
}

@Component({
    selector: 'base-card-popover',
    template: '',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseCardPopoverComponent<T> extends BaseCardComponent<T> {
    @ViewChild("popover", { static: true }) readonly popover: EzPopoverComponent;

    constructor(cdr: ChangeDetectorRef) {
        super(cdr)
    }

}
