import {IContract, IUser, WrapperPreferences} from '@eztypes/webapi';
import {Observable} from 'rxjs';
import {AppPreferences} from './preferences/app-preferences.model';
import {AppPreferencesContainer} from './preferences/container-preferences.model';

export class AppUser {
  get username(): string {
    return this._user.username;
  }

  get name(): string {
    return this._user.name;
  }

  get isAdmin(): boolean {
    return this._user.isAdmin;
  }

  get preferences(): AppPreferences {
    return this._preferences.preferences;
  }

  private _preferences: AppPreferencesContainer;

  readonly contracts: IContract[];

  get preferencesChange(): Observable<void> {
    return this._preferences.changes;
  }

  private _user: IUser;
  constructor(user: IUser, contracts: IContract[]) {
    this._user = user;
    this.contracts = contracts;
    this._preferences =
      this._user.appPreference == null
        ? new AppPreferencesContainer()
        : AppPreferencesContainer.load(this._user.appPreference);
  }

  stringifyPreferencesOld(): string {
    return this._preferences.stringifyOld();
  }

  createWrapperPreferences(): WrapperPreferences {
    return this._preferences.createWrapperPreferences();
  }
}
