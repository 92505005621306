import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";
import { PageAssetBatchPreference } from "./asset-batch-preferences.model";
import { PageAssetMessagesPreference } from "./asset-messages-preferences.model";
import { PageAuctionOrdersPreference } from "./auction-orders-preferences.model";
import { PageAuctionSchedulesPreference } from "./auction-schedules-preferences.model";
import { PageBalancingActivationPreference } from "./balancing-activation-preferences.model";
import { PageOverviewBalancingPreference } from "./balancing-overview-preferences.model";
import { PageContinuousSessionsPreference } from "./continuous-sessions-preferences.model";
import { PageCounterpartiesPreference } from "./counterparties-preferences.model";
import { PageDealsPreference } from "./deals-preferences.model";
import { PageFilesPreferencePreference } from "./files-preferences.model";
import { PageMapPreference } from "./map-preferences.model";
import { PageMappingPreference } from "./mapping-preferences.model";
import { PageNominationBatchPreference } from "./nominations-batch-preferences.model";
import { PageNominationsMessagesPreference } from "./nominations-messages-preferences.model";
import { PageOverviewNominationsPreference } from "./nominations-overview-preferences.model";
import { PagePositionPreference } from "./positions-preferences.model";
import { PageRealtimePreference } from "./realtime-preferences.model";

export interface IShipperPagePreferences {
    map: PageMapPreference;
    overviewBalancing: PageOverviewBalancingPreference;
    deals: PageDealsPreference;
    positions: PagePositionPreference;
    //#endregion Balancing

    //#region Nominations
    nomOverview: PageOverviewNominationsPreference;
    nomRealtime: PageRealtimePreference;
    nomMessages: PageNominationsMessagesPreference;
    nomBatch: PageNominationBatchPreference;
    //#endregion Nominations

    //#region Asset
    assetActivation: PageBalancingActivationPreference;
    assetMessages: PageAssetMessagesPreference;
    assetBatch: PageAssetBatchPreference;
    //#endregion Asset

    //#region Trading
    auctionOrders: PageAuctionOrdersPreference;
    auctionSchedules: PageAuctionSchedulesPreference;
    continuousOverview: PageContinuousSessionsPreference;
    //#endregion Trading

    //#region Configuration
    files: PageFilesPreferencePreference;
    counterparties: PageCounterpartiesPreference;
    mapping: PageMappingPreference;
    //#endregion Configuration
}

class ShipperPagesPreferenceInfo {
    static load(preferences: string | ShipperPagesPreferenceInfo): ShipperPagesPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<ShipperPagesPreferenceInfo>(preferences, ShipperPagesPreferenceInfo) as ShipperPagesPreferenceInfo;
        return preferences;
    }
    readonly version: number = -1;
    //#region Balancing
    readonly map: string = "";
    readonly overviewBalancing: string = "";
    readonly deals: string = "";
    readonly positions: string = "";
    //#endregion Balancing

    //#region Nominations
    readonly nomOverview: string = "";
    readonly nomRealtime: string = "";
    readonly nomMessages: string = "";
    readonly nomBatch: string = "";
    //#endregion Nominations

    //#region Asset
    readonly assetActivation: string = "";
    readonly assetMessages: string = "";
    readonly assetBatch: string = "";
    //#endregion Asset

    //#region Trading
    readonly auctionOrders: string = "";
    readonly auctionSchedules: string = "";
    readonly continuousOverview: string = "";
    //#endregion Trading

    //#region Configuration
    readonly files: string = "";
    readonly counterparties: string = "";
    readonly mapping: string = "";
    //#endregion Configuration
}

@UserPreferenceClassDecorator
export class ShipperPagesPreference implements IPreferencePage {

    static load(preferences: string | ShipperPagesPreferenceInfo): ShipperPagesPreference {
        if (preferences == null) {
            return new ShipperPagesPreference();
        }

        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = ShipperPagesPreferenceInfo.load(preferences);
                const shipperPages: IShipperPagePreferences = {
                    positions: PagePositionPreference.load(v1.positions),
                    map: PageMapPreference.load(v1.map),
                    deals: PageDealsPreference.load(v1.deals),
                    nomMessages: PageNominationsMessagesPreference.load(v1.nomMessages),
                    nomBatch: PageNominationBatchPreference.load(v1.nomBatch),
                    assetMessages: PageAssetMessagesPreference.load(v1.assetMessages),
                    assetBatch: PageAssetBatchPreference.load(v1.assetBatch),
                    counterparties: PageCounterpartiesPreference.load(v1.counterparties),
                    nomRealtime: PageRealtimePreference.load(v1.nomRealtime),
                    nomOverview: PageOverviewNominationsPreference.load(v1.nomOverview),
                    overviewBalancing: PageOverviewBalancingPreference.load(v1.overviewBalancing),
                    auctionOrders: PageAuctionOrdersPreference.load(v1.auctionOrders),
                    auctionSchedules: PageAuctionSchedulesPreference.load(v1.auctionSchedules),
                    files: PageFilesPreferencePreference.load(v1.files),
                    mapping: PageMappingPreference.load(v1.mapping),
                    assetActivation: PageBalancingActivationPreference.load(v1.assetActivation),
                    continuousOverview: PageContinuousSessionsPreference.load(v1.continuousOverview)
                };
                return new ShipperPagesPreference(shipperPages);
            default:
                return new ShipperPagesPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;

    //#region Balancing
    @UPPropertySave
    readonly map: PageMapPreference;
    @UPPropertySave
    readonly overviewBalancing: PageOverviewBalancingPreference;
    @UPPropertySave
    readonly deals: PageDealsPreference;
    @UPPropertySave
    readonly positions: PagePositionPreference;
    //#endregion Balancing

    //#region Nominations
    @UPPropertySave
    readonly nomOverview: PageOverviewNominationsPreference;
    @UPPropertySave
    readonly nomRealtime: PageRealtimePreference;
    @UPPropertySave
    readonly nomMessages: PageNominationsMessagesPreference;
    @UPPropertySave
    readonly nomBatch: PageNominationBatchPreference;
    //#endregion Nominations

    //#region Asset
    @UPPropertySave
    readonly assetActivation: PageBalancingActivationPreference;
    @UPPropertySave
    readonly assetMessages: PageAssetMessagesPreference;
    @UPPropertySave
    readonly assetBatch: PageAssetBatchPreference;
    //#endregion Asset

    //#region Trading
    @UPPropertySave
    readonly auctionOrders: PageAuctionOrdersPreference;
    @UPPropertySave
    readonly auctionSchedules: PageAuctionSchedulesPreference;
    @UPPropertySave
    readonly continuousOverview: PageContinuousSessionsPreference;
    //#endregion Trading

    //#region Configuration
    @UPPropertySave
    readonly files: PageFilesPreferencePreference;
    @UPPropertySave
    readonly counterparties: PageCounterpartiesPreference;
    @UPPropertySave
    readonly mapping: PageMappingPreference;
    //#endregion Configuration

    constructor(shipperPagePreference?: IShipperPagePreferences) {
        if (shipperPagePreference) {
            this.positions = shipperPagePreference.positions;
            this.map = shipperPagePreference.map;
            this.deals = shipperPagePreference.deals;
            this.nomMessages = shipperPagePreference.nomMessages;
            this.nomBatch = shipperPagePreference.nomBatch;
            this.assetMessages = shipperPagePreference.assetMessages;
            this.assetBatch = shipperPagePreference.assetBatch;
            this.counterparties = shipperPagePreference.counterparties;
            this.nomRealtime = shipperPagePreference.nomRealtime;
            this.nomOverview = shipperPagePreference.nomOverview;
            this.overviewBalancing = shipperPagePreference.overviewBalancing;
            this.auctionOrders = shipperPagePreference.auctionOrders;
            this.auctionSchedules = shipperPagePreference.auctionSchedules;
            this.files = shipperPagePreference.files;
            this.mapping = shipperPagePreference.mapping;
            this.assetActivation = shipperPagePreference.assetActivation;
            this.continuousOverview = shipperPagePreference.continuousOverview;
        } else {
            this.positions = new PagePositionPreference();
            this.map = new PageMapPreference();
            this.deals = new PageDealsPreference();
            this.nomMessages = new PageNominationsMessagesPreference();
            this.nomBatch = new PageNominationBatchPreference();
            this.assetMessages = new PageAssetMessagesPreference();
            this.assetBatch = new PageAssetBatchPreference();
            this.counterparties = new PageCounterpartiesPreference();
            this.nomRealtime = new PageRealtimePreference();
            this.nomOverview = new PageOverviewNominationsPreference();
            this.overviewBalancing = new PageOverviewBalancingPreference();
            this.auctionOrders = new PageAuctionOrdersPreference();
            this.auctionSchedules = new PageAuctionSchedulesPreference();
            this.files = new PageFilesPreferencePreference();
            this.mapping = new PageMappingPreference();
            this.assetActivation = new PageBalancingActivationPreference();
            this.continuousOverview = new PageContinuousSessionsPreference();
        }
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        //#region Balancing
        this.map.reset(options);
        this.overviewBalancing.reset(options);
        this.deals.reset(options);
        this.positions.reset(options);
        //#endregion Balancing

        //#region Nominations
        this.nomOverview.reset(options);
        this.nomRealtime.reset(options);
        this.nomMessages.reset(options);
        this.nomBatch.reset(options);
        //#endregion Nominations

        //#region Asset
        this.assetActivation.reset(options);
        this.assetMessages.reset(options);
        this.assetBatch.reset(options);
        //#endregion Asset

        //#region Trading
        this.auctionOrders.reset(options);
        this.auctionSchedules.reset(options);
        this.continuousOverview.reset(options);
        //#endregion Trading

        //#region Configuration
        this.counterparties.reset(options);
        this.files.reset(options);
        this.mapping.reset(options);
        //#endregion Configuration
    }
}
