import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";
import { TypeStateBalancing } from '../../../modules/balancing/components/overview/balancing-overview.component';

class PageOverviewBalancingPreferenceInfo {
    static load(preferences: string | PageOverviewBalancingPreferenceInfo): PageOverviewBalancingPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageOverviewBalancingPreferenceInfo>(preferences, PageOverviewBalancingPreferenceInfo) as PageOverviewBalancingPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    refreshTimer: number | undefined;
    states: TypeStateBalancing[] | undefined;
}

@UserPreferenceClassDecorator
export class PageOverviewBalancingPreference implements IPreferencePage {
    static load(preferences: string | PageOverviewBalancingPreferenceInfo): PageOverviewBalancingPreference {
        if (!preferences) {
            return new PageOverviewBalancingPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageOverviewBalancingPreferenceInfo.load(preferences);
                return new PageOverviewBalancingPreference(v1.states, v1.refreshTimer);
            default:
                return new PageOverviewBalancingPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    states: TypeStateBalancing[] | undefined;
    @UPPropertySave
    refreshTimer: number | undefined;

    constructor(
        states?: TypeStateBalancing[] | undefined,
        refreshTimer: number | undefined = undefined
    ) {
        this.states = states;
        this.refreshTimer = refreshTimer;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byGrid) {
            this.states = undefined;
            this.refreshTimer = undefined;
        }
    }
}

