<ez-card class="card" type="big">
    <img src="./logo.png" width="128" height="82">
    <div class="info">
        <span class="text">
            No internet connecion detect.
        </span>
        <span>
            New try <ez-timer [value]="nextTry" [countdown]="false"></ez-timer>
        </span>

        <ez-button (onClick)="retryNow()">Retry now</ez-button>
    </div>
</ez-card>