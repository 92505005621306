<div class="form-header">
    <ez-button class="etape etape-1" [value]="formpages.Session"
        [label]="'autotrading.session.create.informations.label' | translate"
        [disabled]="!enabledForms.includes(formpages.Session)" (onClick)="changePage(formpages.Session)">
    </ez-button>
    <span class="separate etape-2" [ngClass]="{'disabled':!enabledForms.includes(formpages.Nemos) }"></span>
    <ez-button class="etape etape-2" [value]="formpages.Nemos"
        [label]="'autotrading.session.create.nemos.label' | translate"
        [disabled]="!enabledForms.includes(formpages.Nemos)" (onClick)="changePage(formpages.Nemos);">
    </ez-button>
    <span class="separate etape-3" [ngClass]="{'disabled':!enabledForms.includes(formpages.Country) }"></span>
    <ez-button class="etape etape-3" [value]="formpages.Country"
        [label]="'autotrading.session.create.countryArea.label' | translate"
        [disabled]="!enabledForms.includes(formpages.Country)" (onClick)="changePage(formpages.Country);">
    </ez-button>
</div>

<div class="form-container">
    <ez-loader *ngIf="isLoading"></ez-loader>
    <div class="slider-form">
        <at-session-form class="etape-1" [(value)]="sessionItem" [commodities]="commodities" [shippers]="shippers"
            (onSubmit)="onFormSubmit(formpages.Nemos)" (onError)="onError(formpages.Session)" [disabled]="disabled"
            [hideFooter]="true">
        </at-session-form>
        <at-nemos-form class="etape-2" [(value)]="sessionItem" [exchanges]="exchangeList"
            (onSubmit)="onFormSubmit(formpages.Country)" (onError)="onError(formpages.Nemos)" [disabled]="disabled"
            [hideFooter]="true">
        </at-nemos-form>
        <at-country-market-area-form class="etape-3" [(value)]="sessionItem" [countries]="countries"
            (onSubmit)="onFormSubmit()" (onError)="onError(formpages.Country)" [disabled]="disabled"
            [hideFooter]="true">
        </at-country-market-area-form>
    </div>
</div>

<div class="form-footer">
    <!-- <ez-button class="back-button" [label]="'autotrading.session.create.action.back' | translate"
        (onClick)="onPreviousPage()" [disabled]="disabled || selectedForm == 1">
    </ez-button> -->
    <ez-button [label]="'general.reset' | translate" [disabled]="disabled" (onClick)="resetForm()"></ez-button>
    <ez-button [label]="(selectedForm === 3 ? 'general.save' : 'general.next') | translate" [severity]="'primary'"
        [disabled]="disabled" (onClick)="submitForm()">
    </ez-button>
</div>
