// @ts-nocheck
// Libraries
import {
    ChangeDetectorRef,
    ElementRef,
    HostListener,
    Input,
    Renderer2,
    Attribute,
    Component,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { EzFormControl, IEzFormControl } from "@eznergy/components";
import { ITimespan, Timespan, TimeUnit } from "@eztypes/generic";

export class GradientItemView {
    from: ITimespan;
    to: ITimespan;
    onError?: boolean;
    get value(): number {
        return this._value;
    }
    set value(value: number) {
        this._value = value;
    }
    private _value: number;
}

@Component({ selector: "gradient-item-base", template: "" })
export class GradientItemsBaseComponent<T1 extends GradientItemView, T2>
    extends EzFormControl
    implements IEzFormControl
{
    private _internalGradientItems: T1[];

    hasError: boolean;

    @Input()
    set defaultIncrement(value: number) {
        if (this.defaultIncrement !== value) {
            this._defaultIncrement = value;
            this._cdr.markForCheck();
        }
    }
    get defaultIncrement(): number {
        return this._defaultIncrement;
    }
    private _defaultIncrement: number = 60;

    get gradientItems(): T1[] {
        return this._internalGradientItems;
    }
    set gradientItems(items: T1[]) {
        this._internalGradientItems = items;
    }

    @HostListener("focusin", ["$event"])
    focusin(event: FocusEvent): void {
        super.focusin(event);
        if (event.target === this.element) {
            this.focus();
        }
    }

    @HostListener("focusout", ["$event"])
    focusout(event: FocusEvent): void {
        super.focusout(event);
    }

    _ctor: { new (): T1 };

    constructor(
        renderer2: Renderer2,
        elementRef: ElementRef,
        cdr: ChangeDetectorRef,
        @Attribute("tabindex") tabIndex: string,
        @Attribute("id") id: string,
        ngControl: NgControl
    ) {
        super(renderer2, elementRef, cdr, ngControl, tabIndex, id);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    init(values: T1[]): T1[] {
        if (!values || values.length == 0) {
            let internalItem = new this._ctor();
            this._internalGradientItems = [internalItem];
            this._initTicks();
            this._checkValidityItems();
        } else {
            this._internalGradientItems = values;
            this._sortTimeSets();
            this._initTicks();
            this._checkValidityItems();
        }
        return this._internalGradientItems;
    }

    onToChange(value: number, index: number) {
        this._internalGradientItems[index].to = new Timespan(
            value,
            TimeUnit.Minutes
        );
        this._checkValidityItems();
        if (!isNaN(value) && value > 0) this._updateNextTimespan(index);
    }

    addRow(): T1 {
        let newItem = new this._ctor();
        let len: number = this._internalGradientItems.length;
        let previousItem = this._internalGradientItems[len - 1];
        let previousTo = (previousItem.to = previousItem.from.addMinutes(
            this._defaultIncrement
        ));
        newItem.from = previousTo;
        newItem.to = undefined;
        this._internalGradientItems.splice(
            this._internalGradientItems.length,
            0,
            newItem
        );
        this._checkValidityItems();
        return newItem;
    }

    deleteRow(index: number) {
        if (this._internalGradientItems.length > 1) {
            this._internalGradientItems.splice(index, 1);
            this._initTicks();
            this._updateNextTimespan(index ? index - 1 : index);
            this._checkValidityItems();
        }
    }

    private _updateNextTimespan(index: number) {
        const nextItem = this._internalGradientItems[index + 1];
        const item = this._internalGradientItems[index];
        if (nextItem && item.to && !isNaN(item.to.totalMinutes)) {
            nextItem.from = item.to;
        }
    }

    private _sortTimeSets() {
        this._internalGradientItems.sort((a, b) => {
            return (
                a.from && b.from && a.from.totalMinutes - b.from.totalMinutes
            );
        });
    }

    private _initTicks() {
        let len = this._internalGradientItems.length;
        let first = this._internalGradientItems[0];
        let last = this._internalGradientItems[len - 1];
        if (first)
            if (
                (first.from &&
                    (isNaN(first.from.totalMinutes) ||
                        first.from.totalMinutes != 0)) ||
                !first.from ||
                !first.from.totalMinutes
            ) {
                first.from = new Timespan(0, TimeUnit.Minutes);
            }
        if (last && last.to) last.to = undefined;
    }

    _checkValidityItems(): void {
        let onError = false;
        let i = this._internalGradientItems.length;
        while (i > 0) {
            i--;
            let item = this._internalGradientItems[i];
            item.onError = false;
            if (
                (i == this._internalGradientItems.length - 1 && item.to) ||
                (i < this._internalGradientItems.length - 1 && !item.to) ||
                (item.to && isNaN(item.to.totalMinutes)) ||
                (item.to &&
                    item.from &&
                    !isNaN(item.to.totalMinutes) &&
                    !isNaN(item.from.totalMinutes) &&
                    item.from.totalMinutes >= item.to.totalMinutes)
            ) {
                onError = item.onError = true;
            }
            if (item.value == undefined) {
                onError = item.onError = true;
            }
        }
        if (onError !== this.hasError) {
            this.hasError = onError;
        }
    }

    focus(): void {
        if (this.element) {
            const input: HTMLInputElement =
                this.element.querySelector(".has-error ez-input-number") ||
                this.element.querySelector("ez-input-number");
            if (input) {
                input.focus();
            }
        }
    }

    _focusByIndex(index: number): void {
        if (this.element) {
            let rows = (
                this.element as HTMLElement
            ).querySelectorAll<HTMLElement>("ez-row");
            if (rows && rows.length > index) {
                let input =
                    rows[index].querySelector<HTMLElement>("ez-input-number");
                if (input) {
                    input.focus();
                }
            }
        }
    }
}
