<ez-modal [open]="open" class="modal-form-schedule" (onClose)="onClose()">
    <ez-header>
        {{'nominations.schedules.create.actionName' | translate}}
    </ez-header>
    <ez-tabs [value]="tabSelected" ez-overlay-origin #origin="EzOverlayOrigin">
        <ez-loader *ngIf="loader.onLoading" [origin]="origin"></ez-loader>
        <ez-tab-section [value]="tabEnum.Immediate">
            <ez-tab-title> {{ 'nominations.schedules.create.immediate.actionName' | translate }}</ez-tab-title>
            <ez-content>
                <ez-immediate-schedules [type]="type" [gates]="gates" [selectedGate]="selectedGate"
                    [interfaces]="interfaces" [grid]="grid" [selectedInterfaces]="selectedInterfaces"
                    (onCreateSchedule)="onValidateChild($event)"></ez-immediate-schedules>
            </ez-content>
        </ez-tab-section>
        <ez-tab-section [value]="tabEnum.Forecast" [active]="true">
            <ez-tab-title> {{ 'nominations.schedules.create.forecasts.actionName' | translate }}</ez-tab-title>
            <ez-content>
                <ez-forecasts-schedules [type]="type" [grid]="grid" [gates]="gates" [selectedGate]="selectedGate"
                    [interfaces]="interfaces" [selectedInterfaces]="selectedInterfaces" [value]="value"
                    (onCreateForecasts)="onValidateChild($event)"></ez-forecasts-schedules>
            </ez-content>
        </ez-tab-section>
    </ez-tabs>
</ez-modal>
