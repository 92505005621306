// @ts-nocheck
import {
    Attribute,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Optional,
    Output,
    Renderer2,
    Self,
    ViewEncapsulation,
} from "@angular/core";
import { NgControl } from "@angular/forms";
// Components
import { EzFormControl, IEzFormControl } from "@eznergy/components";
import { isSame } from "@eznergy/core";
// Models
import {
    IPhysicalValue,
    IUnit,
    IVolumeGradientItem,
    PhysicalValue,
    VolumeGradientItem,
} from "@eztypes/webapi";

class GradientVolumeValueView {
    from: number;
    to: number;
    onError: boolean;
    value: number;
    unit: IUnit;
}

@Component({
    selector: "ez-gradient-volume",
    templateUrl: "./gradient-volume.component.html",
    styleUrls: ["./gradient-volume.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: EzFormControl, useExisting: GradientVolumeComponent },
    ],
})
export class GradientVolumeComponent
    extends EzFormControl
    implements IEzFormControl
{
    @Output() valueChange: EventEmitter<IVolumeGradientItem[]> =
        new EventEmitter();

    @Input()
    set defaultValue(value: IPhysicalValue) {
        if (value !== this.defaultValue) {
            this._defaultValue = value;
            if (
                this._internalGradientItems &&
                this._internalGradientItems.length
            ) {
                if (!this.offsetsUnit)
                    this.offsetsUnit = this._defaultValue.unit;
                this._changedvalue = false;
                this._setDefaultValues();
                this._checkValidityItems();
                if (this._changedvalue) {
                    this._emitGradientItemsChange();
                }
            }
            this._cdr.detectChanges();
        }
    }
    get defaultValue(): IPhysicalValue {
        return this._defaultValue;
    }
    private _defaultValue: IPhysicalValue;

    @Input()
    set value(values: IVolumeGradientItem[]) {
        if (this.value && isSame(this.value, values)) {
            return;
        }
        this._init(values);
        this.cdr.markForCheck();
    }
    get value(): IVolumeGradientItem[] {
        return this._oldValues;
    }
    private _oldValues: IVolumeGradientItem[];

    @Input()
    get defaultMaxValue(): number {
        return this._defaultMaxValue;
    }
    set defaultMaxValue(value: number) {
        if (value !== this._defaultMaxValue) {
            this._defaultMaxValue = value;
            this._cdr.markForCheck();
        }
    }
    private _defaultMaxValue: number = 100;
    infiniteMaxValue: number = Number.MAX_SAFE_INTEGER;

    @Input()
    get lastRowEditable(): boolean {
        return this._lastRowEditable;
    }
    set lastRowEditable(value: boolean) {
        this._lastRowEditable = value;
        this._cdr.markForCheck();
    }
    private _lastRowEditable: boolean;

    @Input()
    get minValue(): number {
        return this._minValue;
    }
    set minValue(value: number) {
        this._minValue = value;
    }
    private _minValue: number;

    @HostListener("focusin", ["$event"])
    focusin(event: FocusEvent): void {
        super.focusin(event);
        if (event.target === this.element) {
            this.focus();
        }
    }

    @HostListener("focusout", ["$event"])
    focusout(event: FocusEvent): void {
        super.focusout(event);
    }

    get gradientItems(): GradientVolumeValueView[] {
        return this._internalGradientItems;
    }
    set gradientItems(items: GradientVolumeValueView[]) {
        this._internalGradientItems = items;
        this._cdr.detectChanges();
    }
    private _internalGradientItems: GradientVolumeValueView[];

    addEnabled: boolean;
    volumesUnit: IUnit;
    offsetsUnit: IUnit;
    private _changedvalue: boolean;
    private _formOnError: boolean;

    constructor(
        renderer2: Renderer2,
        elementRef: ElementRef,
        private cdr: ChangeDetectorRef,
        @Attribute("tabindex") tabIndex: string,
        @Attribute("id") id: string,
        @Self() @Optional() ngControl: NgControl
    ) {
        super(renderer2, elementRef, cdr, ngControl, tabIndex, id);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    focus(): void {
        if (this.element) {
            let input =
                this.element.querySelector<HTMLElement>("ez-input-number");
            if (input) {
                input.focus();
            }
        }
    }

    private _focusByIndex(index: number): void {
        if (this.element) {
            let rows = (
                this.element as HTMLElement
            ).querySelectorAll<HTMLElement>("ez-row");
            if (rows && rows.length > index) {
                let input =
                    rows[index].querySelector<HTMLElement>("ez-input-number");
                if (input) {
                    input.focus();
                }
            }
        }
    }

    onFactorChange(value: number, index: number) {
        if (this.disabled) return;
        let item = this.gradientItems[index];
        if (item) {
            item.value = value;
            this._checkValidityItems();
            this._emitGradientItemsChange();
        }
    }

    onToChange(value: number, index: number) {
        if (this.disabled) return;
        this._toChange(value, index);
        this._checkValidityItems();
        this.addEnabled = this._enabledAddButton();
        this._emitGradientItemsChange();
    }

    onAddClick() {
        if (this.disabled) return;
        let item = this._addRow();
        this._setDefaultValue(item);
        this._checkValidityItems();
        this.addEnabled = this._enabledAddButton();
        this._emitGradientItemsChange();
        this.cdr.detectChanges();
        this._focusByIndex(this._internalGradientItems.length - 1);
    }

    onDeleteClick(index: number): void {
        if (this.disabled) return;
        this._internalGradientItems.splice(index, 1);
        if (!index || index == this._internalGradientItems.length - 1)
            this._updateTickHeads();
        this._updateGradients(index ? index - 1 : index);
        this._checkValidityItems();
        this.addEnabled = this._enabledAddButton();
        this._emitGradientItemsChange();
        this.cdr.detectChanges();
        this._focusByIndex(index);
    }

    private _setDefaultValues() {
        this._changedvalue = false;
        if (this.gradientItems) {
            this.gradientItems.forEach((value) => {
                this._setDefaultValue(value);
            });
        }
    }

    private _setDefaultValue(item: GradientVolumeValueView) {
        if (
            this._defaultValue &&
            (item.value == undefined || item.unit == undefined)
        ) {
            item.value = item.value || this._defaultValue.value;
            item.unit = item.unit || this._defaultValue.unit;
            this._changedvalue = true;
        }
    }

    private _setNewDefaultUnit(values: IVolumeGradientItem[]) {
        this.offsetsUnit =
            (values.length && values[0].offset && values[0].offset.unit) ||
            (this._defaultValue && this._defaultValue.unit);
    }

    private _init(values: IVolumeGradientItem[]) {
        // reset internal items;
        // otherwise the previous values override the new ones
        // due to change detection triggers
        this._internalGradientItems = [];
        this._cdr.detectChanges();

        let newItemView: GradientVolumeValueView;
        if (values && values.length) {
            let itemsView: GradientVolumeValueView[] = [];
            values.forEach((item: IVolumeGradientItem) => {
                let itemView = this._toGradientItemView(item);
                itemsView.push(itemView);
            });
            this._setNewDefaultUnit(values);
            this._sortTimeSets(itemsView);
            this._updateTickHeads();
            this._internalGradientItems = itemsView;
        } else {
            if (!this.offsetsUnit) this.offsetsUnit = this.defaultValue?.unit;
            newItemView = this._toGradientItemView();
            this._internalGradientItems = [newItemView];
        }
        this._setDefaultValues();
        this._checkValidityItems();
        if (newItemView || this._changedvalue) {
            this._emitGradientItemsChange();
        }
        this.addEnabled = this._enabledAddButton();
    }

    private _toGradientItemView(item: IVolumeGradientItem = undefined) {
        let itemView = new GradientVolumeValueView();
        itemView.from = item ? item.from : 0;
        itemView.to = item && item.to ? item.to : this._defaultMaxValue;
        itemView.value = item && item.offset ? item.offset.value : undefined;
        itemView.unit = item && item.offset ? item.offset.unit : undefined;
        itemView.onError = false;
        return itemView;
    }

    private _toIGradientItemValue(): IVolumeGradientItem[] {
        const items = this.gradientItems;
        const internalItems: IVolumeGradientItem[] = [];
        items.forEach((item) => {
            let it = new VolumeGradientItem();
            it.from = item.from;
            if (item.to != undefined) it.to = item.to;
            it.offset = new PhysicalValue(this.offsetsUnit, item.value);
            internalItems.push(it);
        });
        return internalItems;
    }

    private _sortTimeSets(values: GradientVolumeValueView[]) {
        values.sort((a, b) => {
            return (
                a.from != undefined &&
                a.from != null &&
                b.from != undefined &&
                b.from != null &&
                b.from &&
                a.from - b.from
            );
        });
    }

    private _updateTickHeads() {
        let len = this._internalGradientItems?.length;
        if (
            this._internalGradientItems != null &&
            this._internalGradientItems[0] &&
            this._internalGradientItems[0].from != 0
        ) {
            this._internalGradientItems[0].from = 0;
        }
        if (
            this._internalGradientItems != null &&
            this._internalGradientItems[len - 1] &&
            this._internalGradientItems[len - 1].to == undefined
        ) {
            this._internalGradientItems[len - 1].to = this._defaultMaxValue;
        }
    }

    private _checkValidityItems(): void {
        let onError = false;
        let i = this._internalGradientItems.length;
        while (i > 0) {
            i--;
            let item = this._internalGradientItems[i];
            item.onError = false;
            if (
                isNaN(item.to) ||
                item.to == null ||
                isNaN(item.from) ||
                item.from == null ||
                (!isNaN(item.to) && !isNaN(item.from) && item.from < 0) ||
                item.to < 0 ||
                item.from >= item.to
            ) {
                onError = item.onError = true;
                continue;
            }
            if (i && item.from != this._internalGradientItems[i - 1].to) {
                onError = item.onError = true;
            }
            if (item.value == undefined) {
                onError = item.onError = true;
            }
        }
        if (this._formOnError != onError) {
            this._formOnError = onError;
        }
    }

    private _addRow(): GradientVolumeValueView {
        let item = new GradientVolumeValueView();
        let len: number = this._internalGradientItems.length;
        let previousItem = this._internalGradientItems[len - 1];
        let mid: number =
            previousItem.to === this.infiniteMaxValue
                ? previousItem.from + 50
                : Math.floor(
                      (previousItem.to - previousItem.from) / 2 +
                          previousItem.from
                  );
        item.to = previousItem.to;
        previousItem.to = item.from = mid;
        this._internalGradientItems.splice(
            this._internalGradientItems.length,
            0,
            item
        );
        return item;
    }

    private _updateGradients(index: number) {
        let nextItem = this._internalGradientItems[index + 1];
        if (
            nextItem &&
            this._internalGradientItems[index].to &&
            !isNaN(this._internalGradientItems[index].to)
        ) {
            nextItem.from = this._internalGradientItems[index].to;
        }
    }

    private _toChange(value: number, index: number): void {
        if (this._internalGradientItems[index])
            this._internalGradientItems[index].to = value;
        this._updateGradients(index);
        this.addEnabled = this._enabledAddButton();
    }

    private _enabledAddButton() {
        let result: boolean = false;
        let len = this._internalGradientItems.length;
        if (len) {
            result =
                this._internalGradientItems[len - 1].to -
                    this._internalGradientItems[len - 1].from >
                1;
        }
        return result;
    }

    private _emitGradientItemsChange() {
        this._oldValues = !this._formOnError
            ? this._toIGradientItemValue()
            : undefined;

        this._onChange(this._oldValues);
        this.valueChange.emit(this._oldValues);
    }

    writeValue(value: any) {
        this.value = value;
        super.writeValue(this.value);
    }
}
