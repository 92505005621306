// @ts-nocheck
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Logger, Subscriptions } from '@core';
import { UnitFamiliesUmScore } from '@enums/unit-families-umscore.enum';
import * as _ez from '@eznergy/core';
import { EzNotificationService } from '@eznergy/toaster';
import { ApiService } from '@eznergy/webapi';
import { DateTime, TimeZone } from '@eztypes/generic';
import {
    AtDirection,
    IAtOrder,
    IContractAreaState,
    IContractMetrics,
    IPublicOrder,
    ISession,
    IUnit,
    OrderOwner,
    OrderState,
    OrderType,
    PhysicalValue,
    ThrottlingLimit,
    ThrottlingStatus,
    ValidityRestriction,
} from '@eztypes/webapi';
import { IEnabledPhysicalValue } from '@eztypes/webapi/core/enabled-physical-value';
import { FormBase } from '@forms/core/form-base';
import { ApplicationService } from '@services/application.service';
import { DataService } from '@services/data.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModal } from '../../../../shared/modals/confirm/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { OrderView, RestrictionType, ValidityType } from '../../../models/order-form';


@Component({
    selector: "at-order-form",
    templateUrl: "./create-order.component.html",
    styleUrls: ["./create-order.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AtOrderForm extends FormBase {
    @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

    @Output() onSentOrder: EventEmitter<IAtOrder> =
        new EventEmitter<IAtOrder>();

    @Input()
    get direction(): AtDirection {
        return this._direction;
    }
    set direction(value: AtDirection) {
        if (!_ez.isSame(this._direction, value)) {
            this._direction = value;
            this._processDirection();
            this._cdr.markForCheck();
        }
    }
    private _direction: AtDirection;

    @Input()
    get order(): IPublicOrder {
        return this._publicOrder;
    }
    set order(value: IPublicOrder) {
        if (!_ez.isSame(this._publicOrder, value)) {
            this._publicOrder = value;
            this._resetInternalValues();

            if (this._publicOrder) {
                this.markAsDirty();
            }

            this._cdr.markForCheck();
        }
    }
    private _publicOrder: IPublicOrder;

    @Input()
    get privateOrder(): IAtOrder {
        return this._privateOrder;
    }
    set privateOrder(value: IAtOrder) {
        if (this._privateOrder !== value) {
            this._privateOrder = value;
            this._resetInternalValues();
            this._cdr.markForCheck();
        }
    }
    private _privateOrder: IAtOrder;

    @Input()
    set contracts(value: IContractAreaState[]) {
        if (value) {
            value = _.sortBy(value, "contract.deliveryStart");
        }
        this._contracts = value;
        this._cdr.markForCheck();
    }
    get contracts(): IContractAreaState[] {
        return this._contracts;
    }
    private _contracts: IContractAreaState[];

    @Input()
    get selectedContract(): IContractAreaState {
        return this._selectedContract;
    }
    set selectedContract(value: IContractAreaState) {
        if (!_ez.isSame(this._selectedContract, value)) {
            this._selectedContract = value;
            this._resetInternalValues();
            this._processContract();
            this._initRestriction();
            this._cdr.markForCheck();
        }
    }
    private _selectedContract: IContractAreaState;


    @Input()
    get session(): ISession {
        return this._session;
    }
    set session(value: ISession) {
        if (this._session !== value) {
            this._session = value;
            this._initTimezone();
            this._cdr.markForCheck();
        }
    }
    private _session: ISession;

    @Input()
    get contractMetrics(): IContractMetrics {
        return this._contractMetrics;
    }
    set contractMetrics(value: IContractMetrics) {
        if (this._contractMetrics === value) {
            return;
        }
        this._contractMetrics = value;
        this._procressOderLimits();
        this._cdr.markForCheck();
    }

    private _contractMetrics: IContractMetrics;


    @Input()
    get throttlingLimit(): ThrottlingLimit {
        return this._throttlingLimit;
    }

    set throttlingLimit(value : ThrottlingLimit) {
        this._throttlingLimit = value;
    }

    private _throttlingLimit: ThrottlingLimit;

    get internalIdOrder(): string {
        return this._privateOrder?.id || (this.order?.ownership?.id && this.order?.ownership?.owner === OrderOwner.OwnSession); 
    }
    
    loading: boolean;

    directions = AtDirection;
    resType = RestrictionType;
    validityType = ValidityType;
    states = OrderState;

    energyPriceUnits: IUnit[] = [];
    energyUnits: IUnit[] = [];
    private _defaultEnergyUnit: IUnit;
    private _defaultPriceUnit: IUnit;

    get orderEntry(): OrderView {
        return this._orderEntry;
    }
    set orderEntry(value: OrderView) {
        if (this._orderEntry !== value) {
            this._orderEntry = value;
            this._cdr.markForCheck();
        }
    }

    private _orderEntry: OrderView;
    validityTime: DateTime;

    private _overrideOrderLimits: boolean = false;
    public get overrideOrderLimits(): boolean {
        return this._overrideOrderLimits;
    }
    public set overrideOrderLimits(value: boolean) {
        this._overrideOrderLimits = value;
    }

    get hasOmtWarning() : boolean {
        return this.throttlingLimit && this.throttlingLimit.status === ThrottlingStatus.Warning;
    }

    get hasOmtRestriction() : boolean {
        return  this.throttlingLimit && this.throttlingLimit.status === ThrottlingStatus.Restricted;
    }

    get footerMessage() : 'none'|'warning'|'error' {
        switch(true) {
            case this.overrideOrderLimits : 
            case this.hasOmtRestriction:
                return 'error';
            case this.hasOmtWarning:
                return 'warning';
            default: 
                return 'none'; 
        }
    }

    minPriceLimit?: IEnabledPhysicalValue;
    maxPriceLimit?: IEnabledPhysicalValue;

    maxVolumeLimit?: IEnabledPhysicalValue;

    minDate: DateTime;
    maxDate: DateTime;

    restriction: RestrictionType;
    validityRestriction: ValidityType;

    hasIceberg: boolean = false;
    hasTimeRestriction: boolean = false;

    get timezone(): TimeZone {
        return this._timezone;
    }
    private _timezone: TimeZone;

    private _subs: Subscriptions = new Subscriptions();

    isOrderCanceled: boolean;

    constructor(
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private _srvNotification: EzNotificationService,
        private _cdr: ChangeDetectorRef,
        private _logger: Logger,
        private readonly _dataSvc: DataService,
        private dialog: MatDialog,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        const sub = this._dataSvc.unitsChanges.subscribe((units) => {
            if (units != null) {
                this.energyUnits = units.filter((a) => a.family.score === UnitFamiliesUmScore.Power);
                this.energyPriceUnits = units.filter((a) => a.family.score === UnitFamiliesUmScore.PriceEnergy);
                this._defaultEnergyUnit = this.energyUnits.find((u: IUnit) => u.id === 9);
                this._defaultPriceUnit = this.energyPriceUnits.find((u: IUnit) => u.id === 23);
                this._processUnitOrder();
            }
        });
        this._subs.push("unitsChanges", sub);
    }

    ngAfterContentInit(): void {
        super.ngAfterContentInit();
        this._resetInternalValues();
        if (this.form) {
            this.form.onChange.subscribe(() => {
                this._checkDisabledBtnModify();
            });
        }
    }

    formCancel(): void {
        this._loading = false;
        this.orderEntry = new OrderView(
            this._privateOrder || this._publicOrder
        );
        this.form.reset();
    }

    //#region events handlers
    onSelectedContractChange(contract: IContractAreaState): void {
        if (this._privateOrder) return;
        this._selectedContract = contract;
        this._processContract();
        this._processRestriction();
        this._cdr.detectChanges();
    }

    onRestrictionChange(res: RestrictionType): void {
        this.restriction = res;
        this._processRestriction();
        this._cdr.detectChanges();
    }

    onValidityResChange(val: ValidityType): void {
        this.validityRestriction = val;
        this._processRestriction();
        this._cdr.detectChanges();
    }

    onValidityDateChanged(value: DateTime): void {
        this.validityTime = value;
        this._processRestriction();
        this._cdr.detectChanges();
    }

    onCancelOrder(): void {
        if (this.isOrderCanceled) return;
        this._deleteOrder();
    }

    onChangeDirection(val: AtDirection): void {
        if (this._privateOrder) return;
        this.orderEntry.direction = val;
    }

    onInput(): void {
        if (!this.overrideOrderLimits) {
            return;
        }

        this.overrideOrderLimits = false;
        this._procressOderLimits();
    }

    overrideFormErrors(fields: any[]): void {
        const allErrorsAreLimitRelated = fields.every((field) =>
            Object.keys(field.ngControl.control.errors).every((key) =>
                ["min", "max", "custom"].includes(key)
            )
        );

        if (!allErrorsAreLimitRelated) {
            return;
        }

        if (this.overrideOrderLimits) {
            this._procressOderLimits();
            this._cdr.detectChanges();
            this.formSubmit();
        }

        this.overrideOrderLimits = true;
    }

    formSubmit(): void {
        if (this.isOrderCanceled) return;

        if (this.shouldDisplayWarning()) {
            const ref = this.dialog.open(ConfirmModal, {
                data: this.translate.instant(
                    'autotrading.session.popups.confirmOrder',
                    { value: Math.round(this.contractMetrics.currentPosition.value * 10) / 10 }
                )
            });

            this._subs.push(ref.afterClosed().subscribe((result: boolean | undefined) => {
                if (result === true) {
                    this._sendOrder();
                }
            }));
        } else {
            this._sendOrder();
        }
    }

    private shouldDisplayWarning() {
        if (!Number.isFinite(this.contractMetrics.currentPosition?.value)) {
            return false;
        }
        const currentValue = Math.round(this.contractMetrics.currentPosition.value * 10) / 10;
        // For sell
        if (this.orderEntry.OrderEntry.direction === AtDirection.Sell) {
            // If initial position is positive
            if (this.contractMetrics.currentPosition.value > 0) {
                // Then sell without warning below the limit
                return this.orderEntry.OrderEntry.quantity.value > currentValue
            } else {
                // Then sell with warning if initial position is negative
                return true;
            }
            // For buy
        } else {
            // If initial position is negative
            if (this.contractMetrics.currentPosition.value < 0) {
                // Then buy without warning below the limit
                return this.orderEntry.OrderEntry.quantity.value < currentValue
            } else {
                // Then buy with warning if initial position is positive
                return true;
            }
        }
    }
    //#endregion events handlers

    private _initTimezone(): void {
        if (this._appSvc.timezone != null) {

            this._timezone = this._appSvc.timezone
        } else if (this.session?.balancingGrid?.calendar?.ianaName != null) {
            this._timezone = TimeZone.create(this.session?.balancingGrid?.calendar?.ianaName);
        } else {
            this._timezone = null;
        }
    }

    //#region API calls

    private _sendOrder(): void {
        let obsOrder:Observable<IAtOrder>;
        this.loading = true;
        if (this._privateOrder) {
            obsOrder = this._api.autotrading.markets.updateOrder(this._appSvc.contract.id, this.session.id, this.orderEntry.OrderModEntry)
        }else{
            obsOrder = this._api.autotrading.markets.addNewOrder(this._appSvc.contract.id, this.session.id, this.orderEntry.OrderEntry)
        }
        obsOrder.subscribe((order)=>{
            this._srvNotification.success("Order request sent successfully");
            this.privateOrder = order;
            this.onSentOrder.emit(order);
            this._resetInternalValues();
            this.loading = false;
            this._cdr.detectChanges();
        }, () =>{
            this.loading = false;
            this._cdr.detectChanges();
        });
    }

    private _deleteOrder(): void {
        this._api.autotrading.markets
            .deleteOrder(
                this._appSvc.contract.id,
                this._session.id,
                this.internalIdOrder
            )
            .subscribe(
                (res) => {
                    this._srvNotification.success(
                        "Order canceled request sent successfully"
                    );
                    this.onDelete.emit();
                },
                () => {},
                () => {
                    this._cdr.detectChanges();
                }
            );
    }
    //#endregion API calls

    private _resetInternalValues(): void {
        this.isOrderCanceled =
            this._privateOrder &&
            this._privateOrder.state === OrderState.Deleted;
        this.orderEntry = new OrderView(
            this._privateOrder || this._publicOrder
        );
        this.isOrderCanceled = this._privateOrder && this._privateOrder.state === OrderState.Deleted;

        this.orderEntry = new OrderView(this._privateOrder || this._publicOrder);
        this._processUnitOrder();
        this.overrideOrderLimits = false;
        this._procressOderLimits();
        this._processDirection();
        this._processContract();
        this._initRestriction();
        this._checkDisabledBtnModify();
        this.form.viewUpdate = this.privateOrder != undefined;
    }

    private _processDirection(): void {
        if (this._direction && !this._privateOrder) {
            this.orderEntry.direction = this._direction;
        }
    }

    private _processContract(): void {
        this.validityTime = this.minDate = this.maxDate = undefined;
        if (this.orderEntry) {
            if (this._selectedContract) {
                this.orderEntry.contract = this._selectedContract.contract;
                this.minDate = this._selectedContract.tradingPhaseStart.setTimezone(this._session?.balancingGrid?.calendar?.ianaName);
                this.maxDate = this._selectedContract.tradingPhaseEnd.setTimezone(this._session?.balancingGrid?.calendar?.ianaName);
                this.validityTime = this.maxDate;
            } else {
                this.orderEntry.contract = undefined;
            }
        }
    }

    private _initRestriction(): void {
        this.hasIceberg = false;
        this.hasTimeRestriction = false;
        this.orderEntry.validityTime = undefined;
        this.validityRestriction = ValidityType.GFS;
        this.restriction = RestrictionType.Reg;
        if (this._privateOrder) {
            switch (this._privateOrder.validityRestriction) {
                case ValidityRestriction.FillOrKill:
                    this.restriction = RestrictionType.Fok;
                    break;
                case ValidityRestriction.ImmediateOrCancel:
                    this.restriction = RestrictionType.Ioc;
                    break;
                case ValidityRestriction.GoodUntilDate:
                    this.restriction = RestrictionType.Reg;
                    this.validityRestriction = ValidityType.GTD;
                    this.orderEntry.validityTime =
                        this._privateOrder.validityTime;
                    break;
            }
        }

        if (
            this._privateOrder &&
            this._privateOrder.type === OrderType.Iceberg
        ) {
            this.restriction = RestrictionType.Icb;
            this.hasIceberg = true;
        }
        if (
            this.restriction == RestrictionType.Reg ||
            this.restriction == RestrictionType.Icb
        )
            this.hasTimeRestriction = true;
    }

    private _procressOderLimits(): void {
        if (!this.contractMetrics) {
            return;
        }

        this.minPriceLimit = undefined;
        if (
            !this.overrideOrderLimits &&
            this.contractMetrics.manualMinPriceLimit?.enabled
        ) {
            this.minPriceLimit = this.contractMetrics.manualMinPriceLimit;
        }

        this.maxPriceLimit = undefined;
        if (
            !this.overrideOrderLimits &&
            this.contractMetrics.manualMaxPriceLimit?.enabled
        ) {
            this.maxPriceLimit = this.contractMetrics.manualMaxPriceLimit;
        }

        this.maxVolumeLimit = undefined;
        if (
            !this.overrideOrderLimits &&
            this.contractMetrics.manualVolumeLimit?.enabled
        ) {
            this.maxVolumeLimit = this.contractMetrics.manualVolumeLimit;
        }

        this._cdr.markForCheck();
    }

    private _processRestriction(): void {
        this.hasIceberg = false;
        this.orderEntry.validityTime = undefined;
        this.hasTimeRestriction = false;
        this.orderEntry.type = OrderType.Regular;
        this.orderEntry.validityRestriction = ValidityRestriction.None;

        switch (this.restriction) {
            case RestrictionType.Icb:
                this.hasIceberg = true;
                this.orderEntry.type = OrderType.Iceberg;
            case RestrictionType.Reg:
                if (this.validityRestriction === ValidityType.GFS) {
                    this.orderEntry.validityRestriction =
                        ValidityRestriction.GoodForSession;
                } else {
                    this.orderEntry.validityRestriction =
                        ValidityRestriction.GoodUntilDate;
                    this.orderEntry.validityTime = this.validityTime;
                }
                this.hasTimeRestriction = true;
                break;
            case RestrictionType.Fok:
                this.orderEntry.validityRestriction =
                    ValidityRestriction.FillOrKill;
                break;
            case RestrictionType.Ioc:
                this.orderEntry.validityRestriction =
                    ValidityRestriction.ImmediateOrCancel;
                break;
            default:
                this.orderEntry.type = OrderType.Regular;
        }
    }

    private _processUnitOrder(): void {
        if (this.orderEntry) {
            if (!this.orderEntry.quantity) {
                this.orderEntry.quantity = new PhysicalValue(this._defaultEnergyUnit);
            }
            if (this.orderEntry.quantity.unit?.id == null) {
                this.orderEntry.quantity.unit = this._defaultEnergyUnit;
            }
            if (!this.orderEntry.price) {
                this.orderEntry.price = new PhysicalValue(this._defaultPriceUnit);
            }
            if (this.orderEntry.price.unit?.id == null) {
                this.orderEntry.price.unit = this._defaultPriceUnit;
            }
            if (!this.orderEntry.visibleQuantity) {
                this.orderEntry.visibleQuantity = new PhysicalValue(this._defaultEnergyUnit);
            }
            if (!this.orderEntry.priceOffset) {
                this.orderEntry.priceOffset = new PhysicalValue(this._defaultPriceUnit);
            }
        }
    }

    changeStatusOrder(isActive: boolean): void {
        this.orderEntry.isActive = isActive;
        this._checkDisabledBtnModify();
    }

    disabledBtnModify: boolean = false;
    private _checkDisabledBtnModify(): boolean {
        if (!this.privateOrder) {
            this.disabledBtnModify = false;
            return;
        }

        if (
            this.form.hasChanged() ||
            (this.privateOrder.state === this.states.Active &&
                !this.orderEntry.isActive) ||
            (this.privateOrder.state === this.states.Inactive &&
                this.orderEntry.isActive) ||
            this.privateOrder.validityRestriction !==
                this.orderEntry.validityRestriction
        ) {
            this.disabledBtnModify = false;
        } else {
            this.disabledBtnModify = true;
        }
    }
}
