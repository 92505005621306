<form (ngSubmit)="submit()" [formGroup]="formGroup">
    <ez-form-group *ngIf="sessionType == sessionTypes.Flex">
        <ez-form-field>
            <ez-label>{{ "autotrading.session.parameters.prices.spread.label" | translate }}</ez-label>
            <ez-physical-value
                formControlName="spread"
                [min]="minimumBuySellSpreadValue"
                [placeholder]="'autotrading.session.parameters.prices.dynamicPrice.minimumBuySellSpread' | translate"
                [unitFamily]="energyPriceUnitsFamily"
                [units]="units"
            >
            </ez-physical-value>
        </ez-form-field>
    </ez-form-group>

    <app-custom-form-group>
        <mat-label group-header>
            <mat-checkbox formControlName="hardLimitsActivated" name="hardLimitsActivated">
                {{ "autotrading.session.parameters.prices.hardLimits.label" | translate }}
            </mat-checkbox>
        </mat-label>
        <ng-container formGroupName="hardLimit">
            <mat-form-field>
                <mat-label>{{ "autotrading.session.parameters.prices.hardLimits.min" | translate }}</mat-label>
                <select-timeseries
                    [type]="CurveType.Decimal"
                    [unitFamily]="energyPriceUnitsFamily"
                    formControlName="hardLimitsMin">
                </select-timeseries>
            </mat-form-field>
            <span class="error-field" *ngIf="formGroup.controls.hardLimitsActivated.value && !formGroup.controls.hardLimit.controls.hardLimitsMin.value">*Required</span>
            <mat-form-field>
                <mat-label>{{ "autotrading.session.parameters.prices.hardLimits.max" | translate }}</mat-label>
                <select-timeseries
                    [type]="CurveType.Decimal"
                    [unitFamily]="energyPriceUnitsFamily"
                    formControlName="hardLimitsMax">
                </select-timeseries>
            </mat-form-field>
            <span class="error-field" *ngIf="formGroup.controls.hardLimitsActivated.value && !formGroup.controls.hardLimit.controls.hardLimitsMax.value">*Required</span>
        </ng-container>
    </app-custom-form-group>

    <app-custom-form-group formGroupName="priceLimit">
        <mat-label group-header>{{ "autotrading.session.parameters.prices.absolute.label" | translate }}</mat-label>

        <mat-form-field>
            <mat-label>{{ "autotrading.session.parameters.prices.absolute.buy" | translate }}</mat-label>
            <select-timeseries
                [type]="CurveType.Decimal"
                [unitFamily]="energyPriceUnitsFamily"
                formControlName="absoluteBuy">
            </select-timeseries>
        </mat-form-field>
        <span class="error-field" *ngIf="!formGroup.controls.priceLimit.controls.absoluteBuy.value">*Required</span>

        <mat-form-field>
            <mat-label>{{ "autotrading.session.parameters.prices.absolute.sell" | translate }}</mat-label>
            <select-timeseries
                [type]="CurveType.Decimal"
                [unitFamily]="energyPriceUnitsFamily"
                formControlName="absoluteSell">
            </select-timeseries>
        </mat-form-field>
        <span class="error-field" *ngIf="!formGroup.controls.priceLimit.controls.absoluteSell.value">*Required</span>

        <app-custom-form-group formGroupName="timeGradient">
            <mat-label group-header>
                <mat-checkbox formControlName="isTimeGradient" name="timeGradientActivated">
                    Time gradient
                </mat-checkbox>
            </mat-label>

            <ez-form-field>
                <ez-gradient-value
                    [(ngModel)]="timeGradient"
                    [disabled]="formGroup.controls.priceLimit.controls.timeGradient.controls.timeGradientValue.disabled"
                    [required]="true"
                    [defaultValue]="defaultValue"
                    [ngModelOptions]="{ standalone: true }"
                >
                    <ez-label>{{ "autotrading.session.parameters.prices.timeGradient.label" | translate }}</ez-label>
                    <span class='ez-factor'>{{ "autotrading.session.parameters.prices.timeGradient.factor" | translate }}</span>
                </ez-gradient-value>
            </ez-form-field>
        </app-custom-form-group>

        <app-custom-form-group formGroupName="volumeGradient">
            <mat-label group-header>
                <mat-checkbox formControlName="isVolumeGradient" name="volumeGradientActivated">
                    Volume gradient
                </mat-checkbox>
            </mat-label>

            <ez-form-field>
                <ez-gradient-volume
                    [(ngModel)]="volumeGradient"
                    [disabled]="formGroup.controls.priceLimit.controls.volumeGradient.controls.volumeGradientValue.disabled"
                    [required]="true"
                    [defaultValue]="defaultValue"
                    [ngModelOptions]="{ standalone: true }"
                >
                    <ez-label>{{ "autotrading.session.parameters.prices.volumeGradient.label" | translate }}</ez-label>
                    <span class='ez-factor'>{{ "autotrading.session.parameters.prices.volumeGradient.factor" | translate }}</span>
                </ez-gradient-volume>
            </ez-form-field>
        </app-custom-form-group>
    </app-custom-form-group>

    <app-custom-form-group>
        <mat-label group-header>
            <mat-checkbox formControlName="isDynamicPrice" name="dynamicPriceActivated">
                {{ "autotrading.session.parameters.prices.dynamicPrice.title" | translate }}
            </mat-checkbox>
        </mat-label>

        <ng-container formGroupName="dynamicPriceLimit">
            <mat-radio-group formControlName="dynamicPriceRadio" name="dynamicPriceType">
                <mat-radio-button [value]="dynamicPriceType.isBidAsk">
                    {{ 'autotrading.session.parameters.prices.dynamicPrice.bidAsk.title' | translate }}
                </mat-radio-button>
                <mat-slider formGroupName="valueRange"
                    [min]="limitPercent.min" [max]="limitPercent.max" discrete [displayWith]="sliderLabel"
                >
                    <input formControlName="valueRangeBuyBid" matSliderStartThumb name="askDynamicBuy">
                    <input formControlName="valueRangeSellBid" matSliderEndThumb name="askDynamicSell">
                </mat-slider>
                <mat-radio-button [value]="dynamicPriceType.isVolWeigthedSpread">
                    {{ 'autotrading.session.parameters.prices.dynamicPrice.vwsp.title' | translate }}
                </mat-radio-button>
                <mat-slider formGroupName="valueRangeVW"
                    [min]="limitPercent.min" [max]="limitPercent.max" discrete [displayWith]="sliderLabel"
                >
                    <input formControlName="valueRangeVWBuyBid" matSliderStartThumb name="vwDynamicBuy">
                    <input formControlName="valueRangeVWSellBid" matSliderEndThumb name="vwDynamicBuy">
                </mat-slider>
                <mat-radio-button [value]="dynamicPriceType.isLast">
                    {{ 'autotrading.session.parameters.prices.dynamicPrice.last.title' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="dynamicPriceType.isVolumeWeightedAvgPrice">
                    {{ 'autotrading.session.parameters.prices.dynamicPrice.vwap.title' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="dynamicPriceType.isManualStrategy">
                    {{ 'autotrading.session.parameters.prices.dynamicPrice.manual.title' | translate }}
                </mat-radio-button>

                <app-custom-form-group formGroupName="manualLimit">
                    <ez-gradient-market
                        [(ngModel)]="marketMakingPolicies"
                        [disabled]="formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.marketMakingPolicies.disabled"
                        [required]="true"
                        [defaultValue]="defaultValue"
                        [ngModelOptions]="{ standalone: true }"
                    >
                        <ez-label>
                            {{
                            "autotrading.session.parameters.prices.dynamicPrice.manual.gradient.label"
                                | translate
                            }}
                        </ez-label>
                    </ez-gradient-market>

                    <mat-label>
                        <mat-checkbox formControlName="isBestMarketFilter" name="bestMarketFilterActivated">
                            {{ "autotrading.session.parameters.prices.bestMarketFilter.label" | translate }}
                        </mat-checkbox>
                    </mat-label>

                    <ng-container formGroupName="bestMarketFilter">
                        <mat-form-field>
                            <mat-label>
                                {{ "autotrading.session.parameters.prices.bestMarketFilter.minVolumeTimeSerie" | translate }}
                            </mat-label>
                            <select-timeseries
                                formControlName="minVolumeTimeserie"
                                [type]="CurveType.Decimal"
                                [unitFamily]="powerUnitsFamily">
                            </select-timeseries>
                        </mat-form-field>
                        <span class="error-field" 
                            *ngIf="formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.isBestMarketFilter.value 
                            && !formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.bestMarketFilter.controls.minVolumeTimeserie.value">
                                *Required
                        </span>
                        <mat-form-field>
                            <mat-label>
                                {{ "autotrading.session.parameters.prices.bestMarketFilter.maxPriceDeltaTimeSerie" | translate }}
                            </mat-label>
                            <select-timeseries
                                formControlName="maxPriceDeltaTimeserie"
                                [type]="CurveType.Decimal"
                                [unitFamily]="energyPriceUnitsFamily">
                            </select-timeseries>
                        </mat-form-field>
                        <span class="error-field" 
                            *ngIf="formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.isBestMarketFilter.value 
                            && !formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.bestMarketFilter.controls.maxPriceDeltaTimeserie.value">
                                *Required
                        </span>
                    </ng-container>
                </app-custom-form-group>
            </mat-radio-group>
        </ng-container>
    </app-custom-form-group>

    <app-custom-form-group>
        <mat-label group-header>
            <mat-checkbox formControlName="isManualModeLimits" name="manualLimitsActivated">
                {{ "autotrading.session.parameters.prices.manualLimits.label" | translate }}
            </mat-checkbox>
        </mat-label>

        <ng-container formGroupName="manualModeLimits">
            <mat-form-field>
                <mat-label>
                    {{ "autotrading.session.parameters.prices.manualLimits.min" | translate }}
                </mat-label>
                <select-timeseries
                    formControlName="manualLimitsMin"
                    [type]="CurveType.Decimal"
                    [unitFamily]="energyPriceUnitsFamily">
                </select-timeseries>
            </mat-form-field>
            <span class="error-field" *ngIf="formGroup.controls.isManualModeLimits.value 
                && !formGroup.controls.manualModeLimits.controls.manualLimitsMin.value">
                    *Required
            </span>

            <mat-form-field>
                <mat-label>
                    {{ "autotrading.session.parameters.prices.manualLimits.max" | translate }}
                </mat-label>
                <select-timeseries
                    formControlName="manualLimitsMax"
                    [type]="CurveType.Decimal"
                    [unitFamily]="energyPriceUnitsFamily"
                >
                </select-timeseries>
            </mat-form-field>
            <span class="error-field" 
                *ngIf="formGroup.controls.isManualModeLimits.value 
                && !formGroup.controls.manualModeLimits.controls.manualLimitsMax.value">
                    *Required
            </span>
        </ng-container>
    </app-custom-form-group>
</form>
