import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DealPopupPage } from './deal-popup/deal-popup-page.component';
import { DealFormComponent } from './deal-popup/deal-form/deal-form.component';

@NgModule({
    declarations: [
        DealPopupPage,
        DealFormComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        TranslateModule,
    ],
    exports: [
        DealPopupPage
    ]
})
export class PopupsModule { }
