// @ts-nocheck
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Logger } from '@core/logger';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { CacheService, KeyCache } from './cache.service';
import { Dictionary, IDictionary } from '@eztypes/generic';

export enum ABTestingKeys {
    Nothing = "nothing"
}

class ABTestingObject {
    readonly key: ABTestingKeys;
    readonly defaultValue: string | boolean;
    readonly possibleValues: (string | boolean)[];
    readonly callbacks: ((value: string | boolean) => void)[] = [];
    value: string | boolean;

    constructor(key: ABTestingKeys, possibleValues: (string | boolean)[], defaultValue: string | boolean, value?: string | boolean) {
        this.key = key;
        this.possibleValues = possibleValues;
        this.defaultValue = defaultValue;
        this.callbacks = [];
        this.value = value || this.defaultValue;
    }

    addCallback(callback: (value: string | boolean) => void): void {
        this.callbacks.push(callback);
    }
}

@Injectable({ providedIn: 'root' })
export class ABTestingService {

    get changed(): Observable<ABTestingKeys> {
        return this._subjectChanged.asObservable();
    }
    private _subjectChanged: Subject<ABTestingKeys> = new Subject<ABTestingKeys>();

    private _data: ABTestingObject[] = [
        new ABTestingObject(ABTestingKeys.Nothing, [true, false], true)
    ];

    constructor(private _route: ActivatedRoute, private _logger: Logger, private _cacheSvc: CacheService) {
        this._readCache();
        this._route.queryParams.subscribe((qp: Params) => {     // listen to URL changes
            this._processParams(qp);
        });
    }

    has(key: string): boolean {
        return _.some(this._data, (data: ABTestingObject) => { return data.key === key; });
    }
    /**
     * Get the present value
     * @param key is the key of the AB testing
     */
    get(key: string): string | boolean {
        let result = _.find(this._data, (data: ABTestingObject) => { return data.key === key; });
        if (result) {
            return result.value;
        }
        return undefined;
    }

    getPossibleValues(key: string): (string | boolean)[] {
        let liste = _.find(this._data, (data: ABTestingObject) => { return data.key === key; });
        if (liste) {
            return liste.possibleValues;
        }
        return undefined;
    }

    check(key: ABTestingKeys, callback: (value: string | boolean) => void): void {
        let data = _.find(this._data, (data: ABTestingObject) => { return data.key === key; });
        if (data) {
            data.addCallback(callback);
            callback(data.value);
        }
    }

    registerParam(key: string, value: string | boolean): void {
        let liste = _.find(this._data, (data: ABTestingObject) => { return data.key === key; });
        this._dataChangeValue(liste, value);
        return undefined;
    }

    private _processParams(param: Params): void {
        _.forEach(this._data, (data: ABTestingObject) => {
            let value = param[data.key];
            if (value != undefined) {
                if (value === 'true' || value === 'false') value = value === 'true';
                this._dataChangeValue(data, value);
            }
        });
    }

    private _dataChangeValue(data: ABTestingObject, value: string | boolean): void {
        if (_.indexOf(data.possibleValues, value) !== -1) {
            if (data.value !== value) {
                data.value = value;
                this._subjectChanged.next(data.key);
                _.forEach(data.callbacks, (callback) => {
                    callback(data.value);
                });
                this._save();
            }
        } else {
            this._logger.warn("[AB Testing] value unknown for " + data.key);
        }
    }

    private _readCache(): void {
        const dataSave: IDictionary<any> = this._cacheSvc.get(KeyCache.AbTesting, Dictionary);
        if (dataSave)
            dataSave.forEach((key: string, value: string | boolean) => {
                const cache = _.find(this._data, (d: ABTestingObject) => d.key === key);
                if (cache) {
                    this._dataChangeValue(cache, value);
                }
            });
    }

    private _save(): void {
        const dataSave: IDictionary<string | boolean> = new Dictionary<string | boolean>();
        _.forEach(this._data, (data: ABTestingObject) => {
            if (data.value !== data.defaultValue) {
                dataSave.add(data.key, data.value);
            }
        });
        this._cacheSvc.set(KeyCache.AbTesting, dataSave);
    }
}
