// @ts-nocheck
// Libraries
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import * as _ez from "@eznergy/core";
import { FlexVolumesParameters, IDecimalCurve, IFlexVolumesParameters, IPhysicalValue, IPortfolio, IPositionVolumesParameters, ITimeGradientItem, IUnitFamily, PhysicalValue, PositionVolumesParameters, SessionType, Unit, CurveType } from "@eztypes/webapi";
// Models
import { FormBase } from "@forms/core/form-base";
import * as _ from "lodash";

enum InitialVolume {
    Calculated = "calculated",
    TimeSerie = "timeserie"
}
@Component({
    selector: "at-volume-position-flex-form",
    templateUrl: "./volume-position-flex.component.html",
    styleUrls: ["./volume-position-flex.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtVolumePositionFlexForm extends FormBase implements OnDestroy {

    @Output() valueChange: EventEmitter<IFlexVolumesParameters | IPositionVolumesParameters> = new EventEmitter<IFlexVolumesParameters | IPositionVolumesParameters>();
    @Output() paramRestartSession: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    get portfolios(): IPortfolio[] {
        return this._portfolios;
    }
    set portfolios(values: IPortfolio[]) {
        if (values !== this.portfolios) {
            this._portfolios = values;
            this._cdr.markForCheck();
        }
    }
    private _portfolios: IPortfolio[];

    @Input()
    set powerUnitsFamily(value: IUnitFamily) {
        if (value !== this.powerUnitsFamily) {
            this._powerUnitsFamily = value;
            this._cdr.markForCheck();
        }
    }
    get powerUnitsFamily(): IUnitFamily {
        return this._powerUnitsFamily;
    }
    private _powerUnitsFamily: IUnitFamily;

    @Input()
    get value(): IFlexVolumesParameters | IPositionVolumesParameters {
        return this._flexPovVolume;
    }
    set value(value: IFlexVolumesParameters | IPositionVolumesParameters) {
        if (value !== this.value) {
            this.form.cancel();
            this._init(value);
            this._cdr.markForCheck();
        }
    }

    flexPovVolume: IFlexVolumesParameters | IPositionVolumesParameters = new PositionVolumesParameters();
    private _flexPovVolume: IFlexVolumesParameters | IPositionVolumesParameters = new PositionVolumesParameters();

    get CurveType(): any {
        return CurveType;
    }

    get pMin(): IDecimalCurve {
        return this.flexPovVolume ? (<IFlexVolumesParameters>this.flexPovVolume).minPower : undefined;
    }
    set pMin(value: IDecimalCurve) {
        if (this.flexPovVolume) {
            (<IFlexVolumesParameters>this.flexPovVolume).minPower = value;
            this._checkParamRestartSession();
        }
    }

    get pMax(): IDecimalCurve {
        return this.flexPovVolume ? (<IFlexVolumesParameters>this.flexPovVolume).maxPower : undefined;
    }
    set pMax(value: IDecimalCurve) {
        if (this.flexPovVolume) {
            (<IFlexVolumesParameters>this.flexPovVolume).maxPower = value;
            if (this._pminEqualPmax)
                (<IFlexVolumesParameters>this.flexPovVolume).minPower = value;
            this._checkParamRestartSession();
        }
    }

    get initialSchedule(): IDecimalCurve {
        return this.flexPovVolume && this.flexPovVolume.initialVolume ? this.flexPovVolume.initialVolume.timeSerie : undefined;
    }
    set initialSchedule(value: IDecimalCurve) {
        if (this.flexPovVolume) {
            this.flexPovVolume.initialVolume.timeSerie = value;
            this._checkParamRestartSession();
        }
    }

    get portfolio(): IPortfolio {
        return this.flexPovVolume && this.flexPovVolume.initialVolume ? this.flexPovVolume.initialVolume.portfolio : undefined;
    }
    set portfolio(value: IPortfolio) {
        if (this.flexPovVolume) {
            this.flexPovVolume.initialVolume.portfolio = value;
            this._checkParamRestartSession();
        }
    }

    get gradients(): ITimeGradientItem[] {
        return this.flexPovVolume.gradients;
    }
    set gradients(val: ITimeGradientItem[]) {
        if (!this._gradients && val) this._gradients = _.cloneDeep(val);
        this.flexPovVolume.gradients = val;
    }
    private _gradients: ITimeGradientItem[];

    // TODO : unit par defaut à remplacer par unit serveur
    factor: IPhysicalValue = new PhysicalValue(new Unit(undefined, undefined, undefined, "%"), 100);

    @Input()
    get type(): SessionType {
        return this.sessionType;
    }
    set type(value: SessionType) {
        this.sessionType = value;
        if (this.sessionType != null && this.sessionType != this.sessionTypes.Flex && this.sessionType != this.sessionTypes.Position)
            throw new Error("This from has to be used with Flex or Position session type");
    }
    sessionType: SessionType = SessionType.Position;
    sessionTypes = SessionType;

    get pminEqualPmax(): boolean {
        this._pminEqualPmax = (this.flexPovVolume
            && (<IFlexVolumesParameters>this.flexPovVolume).minPower
            && (<IFlexVolumesParameters>this.flexPovVolume).maxPower
            && _ez.isSame((<IFlexVolumesParameters>this.flexPovVolume).minPower, (<IFlexVolumesParameters>this.flexPovVolume).maxPower));
        return this._pminEqualPmax;
    }
    set pminEqualPmax(value: boolean) {
        this._pminEqualPmax = value;
        if (this.flexPovVolume) {
            (<IFlexVolumesParameters>this.flexPovVolume).minPower = (<IFlexVolumesParameters>this.flexPovVolume).maxPower;
        }
    }
    private _pminEqualPmax: boolean = false;

    get selectedInitialVolume() {
        return this._selectedInitialVolume;
    }
    set selectedInitialVolume(value: InitialVolume) {
        this._selectedInitialVolume = value;
        this._checkParamRestartSession();
    }
    private _selectedInitialVolume = InitialVolume.Calculated;
    selectedInitialVolumes = InitialVolume;

    private _paramRestartSession: boolean = false;

    constructor(private _cdr: ChangeDetectorRef) {
        super();
    }

    ngOnDestroy(): void {
        this._flexPovVolume = undefined;
        this.flexPovVolume = undefined;
    }

    private _init(value: IFlexVolumesParameters | IPositionVolumesParameters): void {
        this._paramRestartSession = false;
        let data = value;
        this.viewUpdate = value != undefined;
        if (!value) {
            if (this.type == SessionType.Flex) data = new FlexVolumesParameters();
            else data = new PositionVolumesParameters();
        }

        data.gradientActivated = data.gradientActivated || false;
        if (data.initialVolume && data.initialVolume.timeSerie)
            this.selectedInitialVolume = InitialVolume.TimeSerie;
        else
            this.selectedInitialVolume = InitialVolume.Calculated;

        this._flexPovVolume = data;
        this.flexPovVolume = _.cloneDeep(data);
    }

    private _checkParamRestartSession(): void {
        let hasChange: boolean = false;
        if (!_ez.isSame(this.flexPovVolume.initialVolume.portfolio, this._flexPovVolume.initialVolume.portfolio)) {
            hasChange = true;
        }
        if (!_ez.isSame(this.selectedInitialVolume !== InitialVolume.TimeSerie ? undefined : this.flexPovVolume.initialVolume.timeSerie, this._flexPovVolume.initialVolume.timeSerie)) {
            hasChange = true;
        }
        if (this._flexPovVolume instanceof FlexVolumesParameters && this.flexPovVolume instanceof FlexVolumesParameters) {
            if (!_ez.isSame(this.flexPovVolume.minPower, this._flexPovVolume.minPower)) {
                hasChange = true;
            }
            if (!_ez.isSame(this.flexPovVolume.maxPower, this._flexPovVolume.maxPower)) {
                hasChange = true;
            }
        }
        if (hasChange !== this._paramRestartSession) {
            this._paramRestartSession = hasChange;
            this.paramRestartSession.emit(this._paramRestartSession);
        }
    }

    formSubmit() {
        if (this.selectedInitialVolume !== InitialVolume.TimeSerie) {
            this.flexPovVolume.initialVolume.timeSerie = undefined;
        }

        if (!this.flexPovVolume.gradients)
            this.flexPovVolume.gradients = [];

        this._flexPovVolume = _.cloneDeep(this.flexPovVolume);
        this._init(this._flexPovVolume);
        if (this.flexPovVolume instanceof FlexVolumesParameters) {
            this.pminEqualPmax = _ez.isSame(this.flexPovVolume.minPower, this.flexPovVolume.maxPower);
        }
        this.valueChange.emit(this._flexPovVolume);
        this.onSubmit.emit();
    }
}
