// @ts-nocheck
import { DateTime } from '@eztypes/generic';

export class EtsDataTablePosition {
    startDate: DateTime;
    endDate: DateTime;
    value: number;
    unit: string;
}

export class EtsDataTablePriceColumn {
    id: number;
    price: number;
    originalPrice: number;
    volume: number;
    originalVolume: number;
    missingVolume: boolean = false;
    hasChanged: boolean = false;
}

export class EtsDataTableRow {
    id: number;
    periodStart: DateTime;
    periodEnd: DateTime;
    position: EtsDataTablePosition;
    prices: EtsDataTablePriceColumn[] = [];
    comment: string;
    originalComment: string;
    illogicalBid: boolean = false;
    hasChanged: boolean = false;
    marketResult: EtsDtMarketResult;
}

export class EtsDataTableRowTotal {
    position: string;
    prices: string[] = [];
}

export class EtsDataTable {
    rows: EtsDataTableRow[] = [];
    total: EtsDataTableRowTotal;
    volumeTick: number;
    priceTick: number;
}

export class EtsDtMarketResult {
    price: number;
    net: number;
    purchase: number;
    sale: number;
}
