// @ts-nocheck
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class EventService {
    readonly widthEvent: Subject<boolean> = new Subject<boolean>();

    widthChanged(): void {
        this.widthEvent.next(true);
    }
}
