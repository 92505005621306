import { NgModule } from "@angular/core";
import { BalancingPagesModule } from "./balancing/balancing.module";
import { DashboardPagesModule } from "./dashboard/dashboard.module";
import { EventsPagesModule } from "./events/events.module";
import { HelpPagesModule } from "./help/help.module";
import { SystemsPagesModule } from "./systems/systems.module";
import { UsersPagesModule } from "./users/users.module";

@NgModule({
    imports: [
        BalancingPagesModule,
        DashboardPagesModule,
        EventsPagesModule,
        HelpPagesModule,
        SystemsPagesModule,
        UsersPagesModule,
    ],
    exports: [
        BalancingPagesModule,
        DashboardPagesModule,
        EventsPagesModule,
        HelpPagesModule,
        UsersPagesModule
    ]
})
export class PagesModule {

}
