// @ts-nocheck
import { Logger } from '@core/logger';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";


export class EzMissingTranslationHandler implements MissingTranslationHandler {

    constructor() { }

    handle(params: MissingTranslationHandlerParams) {
        return params.key;
    }
}
