import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { ApiService } from '@eznergy/webapi';
import { DateTime, Timespan } from '@eztypes/generic';
import { ExchangeAccessType, ExecutionMode, ISchedule, ISession, Schedule, StartConfiguration } from "@eztypes/webapi";
import { FormBase } from '@forms/core/form-base';
import { ApplicationService } from '@services/application.service';
import * as _ from "lodash";
import { Subscription } from 'rxjs';

@Component({
    selector: 'at-schedule-form',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AtScheduleForm extends FormBase implements OnDestroy {

    @Output() valueChange: EventEmitter<ISchedule> = new EventEmitter<ISchedule>();

    @Input()
    get session(): ISession | undefined {
        return this._session;
    }
    set session(value: ISession | undefined) {
        if (value !== this.session) {
            this._session = value;
            this._cdr.markForCheck();
        }
    }
    private _session: ISession | undefined;

    @Input()
    get value(): ISchedule | undefined {
        return this._schedule;
    }
    set value(value: ISchedule | undefined) {
        this._schedule = value;
        this._resetForm();
        this._cdr.markForCheck();
    }
    private _schedule: ISchedule | undefined;
    tempConfig: ISchedule | undefined;

    currentDate: DateTime = DateTime.now();

    get maxEndDate(): DateTime {
        return this._maxEndDate;
    }
    private _maxEndDate: DateTime = this.currentDate.addYears(1);

    get hasReadOnlyAccess(): boolean {
        const result = !this._session || !this._session.exchangeUser || this._session.exchangeUser.accessType === ExchangeAccessType.Read;
        if (result && this.modeRun === ExecutionMode.Live) {
            this.modeRun = ExecutionMode.Simu;
        }
        return result;
    }

    modeRun: ExecutionMode = ExecutionMode.Live;
    modeRunEnum = ExecutionMode;

    private _subSend: Subscription | undefined;

    constructor(
        private _cdr: ChangeDetectorRef,
        private _api: ApiService,
        private _appSvc: ApplicationService
    ) {
        super();
        this._resetForm();
    }

    override ngOnDestroy(): void {
        this._clearTimerDateDiff();
        this._cleanSubSend();
    }

    override submit(): boolean {
        if (this.form) {
            return this.form.submit();
        } else {
            return false;
        }
    }

    override cancel(): void {
        if (this.form) {
            this.form.cancel();
        }
    }

    private _cleanSubSend(): void {
        if (this._subSend && !this._subSend.closed) {
            this._subSend.unsubscribe();
        }
        this._subSend = undefined;
    }

    onSubmitForm() {
        if (this._subSend == undefined) {
            this._fillForm();
            let startConfig = new StartConfiguration(this.modeRun, this._schedule);
            this._subSend = this._api.autotrading.sessions.start(
                // @ts-ignore
                this._appSvc.contract?.id,
                this.session?.id,
                startConfig
            ).subscribe(() => {
                this.valueChange.emit(this._schedule);
                this.onSubmit.emit();
                this._cleanSubSend();
            }, () => {
                this._cleanSubSend();
            });
        }
    }

    onCancelForm() {
        this.onCancel.emit();
    }

    onStartDateSelected(event: DateTime) {
        if (this.tempConfig) {
            this.tempConfig.startDate = event;
            if (this.tempConfig.startDate.diff(this.tempConfig.endDate).totalMilliseconds <= 0) {
                this.tempConfig.endDate = _.clone(this.tempConfig.startDate).addMinutes(15);
            }
            this._maxEndDate = this.tempConfig.startDate.addYears(1);
        }
        this._refreshDateDiff();
        this._cdr.detectChanges();
    }

    setEndDate(granularity: string) {
        if (granularity === 'hour') {
            this.onEndDateSelected(new DateTime().addHours(1));
        } else if (granularity === 'day') {
            this.onEndDateSelected(new DateTime().addDays(1));
        } else if (granularity === 'month') {
            this.onEndDateSelected(new DateTime().addDays(30).addMinutes(15));
        }
    }

    onEndDateSelected(event: DateTime) {
        if (this.tempConfig) {
            this.tempConfig.endDate = event;
        }

        this._refreshDateDiff();
        this._cdr.detectChanges();
    }

    private _resetForm() {
        this.tempConfig = _.cloneDeep(this._schedule);
        if (!this.tempConfig || this.tempConfig.endDate < this.currentDate) {
            this.tempConfig = new Schedule();
            this.tempConfig.startDate = new DateTime();
            this.tempConfig.endDate = new DateTime().addMinutes(15);
            this.tempConfig.activateAutorefresh = true;
            this._maxEndDate = this.tempConfig.startDate.addYears(1);
        }
        this._refreshDateDiff();
    }

    private _fillForm() {
        this._schedule = _.cloneDeep(this.tempConfig);
    }

    private _timerRefreshDateDiff: number | undefined;
    private _refreshDateDiff(): void {
        this._clearTimerDateDiff();
        if (this.tempConfig) {
            let diff = DateTime.now().diff(this.tempConfig.startDate);
            let time = diff.totalMilliseconds > 0 ? diff : DateTime.now().diff(this.tempConfig.endDate);
            let timeout: number | undefined;
            if (time.days < 1) {
                timeout = Timespan.fromSeconds(1).totalMilliseconds;
            }
            if (timeout) {
                this._timerRefreshDateDiff = setTimeout(() => {
                    this._refreshDateDiff();
                    this._cdr.detectChanges();
                }, timeout) as any;
            }
        }
    }

    private _clearTimerDateDiff(): void {
        if (this._timerRefreshDateDiff) {
            clearTimeout(this._timerRefreshDateDiff);
        }
    }
}
