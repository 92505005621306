// @ts-nocheck
interface String {
    left: (value: number) => string;
    right: (value: number) => string;
    format: (...args: any[]) => string;
    splice: (start: number, delCount: number, newSubStr: string) => string;
}

String.prototype.left = function (value: number): string {
    if (this.length > value) {
        return this.substring(0, value);
    }
    return this;
};

String.prototype.right = function (value: number): string {
    if (this.length > value) {
        return this.substring(this.length - value);
    }
    return this;
};

/**
 * Equivalent of ".net" string.format
 */
String.prototype.format = function () {
    let args = arguments;
    return this.replace(/{(\d+)}/g, (match, int) => {
        return typeof args[int] != 'undefined'
            ? args[int]
            : match
            ;
    });
};

/**
 * {JSDoc}
 *
 * The splice() method changes the content of a string by removing a range of
 * characters and/or adding new characters.
 *
 * @this {String}
 * @param {number} start Index at which to start changing the string.
 * @param {number} delCount An integer indicating the number of old chars to remove.
 * @param {string} newSubStr The String that is spliced in.
 * @return {string} A new string with the spliced substring.
 */
String.prototype.splice = function (start, delCount, newSubStr) {
    return this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount));
};
