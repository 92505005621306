// @ts-nocheck

/**
 * @deprecated
 * */
export class Tools {

    /**
     * @deprecated
     * */
    static getByProperty(arr: Array<any>, property: string, value: any): any {
        let result = arr.filter((o) => (o[property] === value));
        return result ? result[0] : null;
    }
    /**
     * @deprecated
     * */
    static getPositionElement(el: HTMLElement, withZIndex: boolean = false) {
        let bodyRect = document.body.getBoundingClientRect(),
            elemRect = el.getBoundingClientRect(),
            offsetTop = elemRect.top - bodyRect.top,
            offsetLeft = elemRect.left - bodyRect.left,
            offsetWidth: number = el.offsetWidth ? el.offsetWidth : elemRect.width,
            offsetHeght: number = el.offsetHeight ? el.offsetHeight : elemRect.height,
            z = 0;

        if (withZIndex) {
            if (window.document.defaultView && window.document.defaultView.getComputedStyle) {
                let element = el;
                do {
                    try {
                        let newZi = window.document.defaultView.getComputedStyle(element).getPropertyValue('z-index');
                        if (!isNaN(Number(z)) && !isNaN(Number(newZi)) && z < Number(newZi)) {
                            z = Number(newZi);
                        }
                    } catch (e) { break; }
                    element = element.parentNode as HTMLElement;
                } while (element.parentNode);
            }
            return { top: offsetTop, left: offsetLeft, width: offsetWidth, height: offsetHeght, zIndex: z };
        }
    }
    /**
         * @deprecated
         * */
    static getWindowPos() {
        let dh = document.body.scrollHeight;
        let dw = document.body.scrollWidth;
        let st = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let sw = window.innerWidth || document.documentElement.clientWidth;
        let sh = window.innerHeight || document.documentElement.clientHeight;
        return { docHeight: dh, docWidth: dw, scrollTop: st, screenWidth: sw, screenHeight: sh };
    }

    /**
      * @deprecated
      * */
    static stringToUnicode(bin: string) {
        const length = bin.length;
        let buf = new ArrayBuffer(length);
        let arr = new Uint8Array(buf);
        for (let i = 0; i < length; i++) {
            arr[i] = bin.charCodeAt(i);
        }
        return buf;
    }

    /**
     * @deprecated
     * */
    static removeDiacritics(str) {
        const accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        const noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        for (let i = 0; i < accent.length; i++) {
            str = str.replace(accent[i], noaccent[i]);
        }

        return str;
    }

    /**
     * @deprecated use i18nService
     * */
    static whatDecimalSeparator(): string {
        let n = 1.1;
        return n.toLocaleString().substring(1, 2);
    }

    /**
     * @deprecated
     * */
    static whatCsvSeparator(): string {
        let n = Tools.whatDecimalSeparator();
        if (n == ",") return ";";
        else return ",";
    }
}
