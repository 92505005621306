<ez-form (onSubmit)="formSubmit()" [autofocus]="false">
    <ez-form-field class="date">
        <ez-label>Date</ez-label>
        <ez-datepicker (valueChange)="onDateSelectedForAddRange($event)" [ngModel]="date" [minDate]="minDate"
            [required]="true">
        </ez-datepicker>
    </ez-form-field>
    <ez-form-field class="time">
        <ez-label>Time</ez-label>
        <ez-timepicker [(ngModel)]="startTime" [max]="endTime" [midnightMax]="true" [required]="true">
        </ez-timepicker>
    </ez-form-field>
    <ez-form-field class="time">
        <ez-label>To</ez-label>
        <ez-timepicker [(ngModel)]="endTime" [min]="startTime" [midnightMax]="true" [required]="true">
        </ez-timepicker>
    </ez-form-field>
    <ez-footer *ngIf="!hideFooter">
        <ez-button [icon]="'times'" [label]="'general.cancel' | translate" [small]="true" ez-form-cancel></ez-button>
        <ez-button [icon]="'plus'" [label]="'general.add' | translate" [small]="true" ez-form-submit></ez-button>
    </ez-footer>
</ez-form>
