import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { Subscriptions } from '@core';
import * as _ez from '@eznergy/core';
import { isSame, orderBy } from '@eznergy/core';
import { EzNotificationService } from '@eznergy/toaster';
import { ApiService } from '@eznergy/webapi';
import { DateTime, TimeUnit, TimeZone } from '@eztypes/generic';
import {
    CurveType,
    Deal,
    Direction,
    ICounterpart,
    ICurveBase,
    IDeal,
    IDirection,
    IGrid,
    IGridPoint,
    IMaterial,
    IOrganisation,
    IPhysicalValue,
    IPortfolio,
    IUnit,
    IUnitFamily, PhysicalValue,
    TradeDirection,
    UserRight
} from '@eztypes/webapi';
import { FormBase } from '@forms/core/form-base';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '@services/application.service';
import { DataService } from '@services/data.service';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { StatusForm } from '@enums/status-form.enum';
// Enum
import { UnitFamiliesUmScore } from '@enums/unit-families-umscore.enum';
import { EventForm } from '@models/views/events/event-form';

@Component({
    selector: 'ez-deal-form',
    templateUrl: './deal-form.component.html',
    styleUrls: ['./deal-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealFormComponent extends FormBase implements OnInit {
    @Output() readonly dealChange: EventEmitter<IDeal> = new EventEmitter<IDeal>();

    @Input()
    get deal(): IDeal | undefined {
        return this._deal;
    }
    set deal(value: IDeal | undefined) {
        if (this.deal === undefined || value !== this.deal) {
            this._initDeal(value);
            this._cdr.markForCheck();
        }
    }
    private _deal: IDeal | undefined;
    internalDeal = new Deal();

    @Input()
    get shipper(): IOrganisation | undefined {
        return this._shipper;
    }
    set shipper(value: IOrganisation | undefined) {
        if (value !== this.shipper) {
            this._shipper = value;
            this._initDeal(this._deal);
            this._cdr.markForCheck();
        }
    }
    private _shipper: IOrganisation | undefined;

    public active: boolean = true;

    private get _isFormChanged(): boolean {
        if (this.form) {
            return this.form.hasChanged();
        } else {
            return false;
        }
    }

    public openConfirm: boolean = false;
    readonly CurveType = CurveType;
    public volume: IPhysicalValue | undefined;
    public price: IPhysicalValue | undefined;

    public hasCurveCreateRight: boolean = false;
    public hasCreateDealRight: boolean = false;
    public hasEditDealRight: boolean = false;

    public materialsEnable: IMaterial[] = [];
    public gridsEnable: IGrid[] = [];
    public gridPointsEnable: IGridPoint[] = [];
    public counterPartsEnable: IOrganisation[] = [];
    public directionEnable: IDirection[] = [];
    public counterpartDeliveryStartDate: DateTime | undefined;
    public counterpartDeliveryEndDate: DateTime | undefined;
    private powerCurves: ICurveBase[] = [];
    public energyUnits: IUnit[] = [];
    public energyPriceUnits: IUnit[] = [];
    public familyUnitPower: IUnitFamily | undefined;
    public familyUnitPrice: IUnitFamily | undefined;
    private priceCurves: ICurveBase[] = [];

    public isAddingPriceCurve: boolean = false;
    public isAddingEnergyCurve: boolean = false;

    public isLoading: boolean = false;
    public maxNumberOfCommentCharacters: number = 100;

    get timezone(): TimeZone | undefined {
        return this._timezone;
    }
    private _timezone: TimeZone | undefined;

    private _contractId: number | undefined;
    private _subs: Subscriptions = new Subscriptions();

    get shippers(): IOrganisation[] | undefined {
        return this._appSvc?.shippers;
    }

    get profiles(): ICurveBase[] {
        return this._dataSvc?.profils;
    }

    get portfolios(): IPortfolio[] {
        return this._dataSvc?.portfolios;
    }

    private _materialsEnableByEntity: { [id: number]: IMaterial[] } = {};
    private _gridsEnableByMaterial: { [id: string]: IGrid[] } = {};
    private _gridPointsByGrid: { [id: string]: IGridPoint[] } = {};
    private _counterpartsByGridPoint: { [id: string]: IOrganisation[] } = {};
    private _directionByCounterpart: { [id: string]: IDirection[] } = {};

    private _hasAutoDeliveryDate: boolean = true;

    isEditMode: boolean = false;
    private _dealEditSuccess: string | undefined;

    private _initDictionaryValue: boolean = false;

    constructor(
        private _appSvc: ApplicationService,
        private _cdr: ChangeDetectorRef,
        private _api: ApiService,
        private readonly _toasterSvc: EzNotificationService,
        private readonly _translateSvc: TranslateService,
        private readonly _dataSvc: DataService
    ) {
        super();
        this.isLoading = true;
        const translateSub = this._translateSvc.get('balancing.deals.editSuccess').subscribe((text) => {
            this._dealEditSuccess = text;
        });
        this._subs.push('translate-success', translateSub);
    }

    ngOnInit() {
        let subChange = this._appSvc.contractChange.subscribe((appContract) => {
            if (appContract != null) {
                this._contractId = appContract.contract.id;

                let hasChange: boolean = false;
                if (this.hasCreateDealRight !== this._appSvc.hasRight(UserRight.DealCreate)) {
                    this.hasCreateDealRight = this._appSvc.hasRight(UserRight.DealCreate);
                    hasChange = true;
                }

                if (this.hasEditDealRight !== this._appSvc.hasRight(UserRight.DealUpdate)) {
                    this.hasEditDealRight = this._appSvc.hasRight(UserRight.DealUpdate);
                    hasChange = true;
                }

                if (this.hasCurveCreateRight !== this._appSvc.hasRight(UserRight.CurveCreate)) {
                    this.hasCurveCreateRight = this._appSvc.hasRight(UserRight.CurveCreate);
                    hasChange = true;
                }

                if (hasChange) {
                    this._cdr.detectChanges();
                }
            }
        });
        this._subs.push('app-data-change', subChange);

        const subDatas = this._dataSvc.changes.subscribe(() => {
            this._initUnit();
            this._initDictionary();
            this._cdr.detectChanges();
        });
        this._subs.push('data-change', subDatas);
        this._initUnit();
        this._initDictionary();
    }

    override ngOnDestroy() {
        if (this._subs) {
            this._subs.clearAll();
        }
        this._deal = undefined;
    }

    /****************** Init Data ******************/

    private _initDictionary() {
        this._materialsEnableByEntity = {};
        this._gridsEnableByMaterial = {};
        this._gridPointsByGrid = {};
        this._counterpartsByGridPoint = {};
        this._directionByCounterpart = {};
        const subCounterparts =  this._dataSvc.balancingCounterpartiesChanges.subscribe((values) => {
            values.forEach((counterpart: ICounterpart) => {
                if (this._materialsEnableByEntity[counterpart.shipper.id] == null) {
                    this._materialsEnableByEntity[counterpart.shipper.id] = [];
                }
                if (
                    !this._materialsEnableByEntity[counterpart.shipper.id]?.some((a) => isSame(a, counterpart.material))
                ) {
                    this._materialsEnableByEntity[counterpart.shipper.id]?.push(counterpart.material);
                    this._materialsEnableByEntity[counterpart.shipper.id] = orderBy(
                        (this._materialsEnableByEntity[counterpart.shipper.id] as IMaterial[]),
                        (a) => a.id
                    );
                }
                const keyGridMat = `${counterpart.shipper.id}_${counterpart.material.id}`;
                if (this._gridsEnableByMaterial[keyGridMat] == null) {
                    this._gridsEnableByMaterial[keyGridMat] = [];
                }
                if (!this._gridsEnableByMaterial[keyGridMat]?.some((a) => isSame(a, counterpart.grid))) {
                    this._gridsEnableByMaterial[keyGridMat]?.push(counterpart.grid);
                    this._gridsEnableByMaterial[keyGridMat] = orderBy(
                        (this._gridsEnableByMaterial[keyGridMat] as IGrid[]),
                        (a) => a.name
                    );
                }

                const keyGpGrid = `${counterpart.shipper.id}_${counterpart.material.id}_${counterpart.grid.id}`;
                if (this._gridPointsByGrid[keyGpGrid] == null) {
                    this._gridPointsByGrid[keyGpGrid] = [];
                }
                if (!this._gridPointsByGrid[keyGpGrid]?.some((a) => isSame(a, counterpart.gridPoint))) {
                    this._gridPointsByGrid[keyGpGrid]?.push(counterpart.gridPoint);
                    this._gridPointsByGrid[keyGpGrid] = orderBy((this._gridPointsByGrid[keyGpGrid] as IGridPoint[]), (a) => a.shortName);
                }

                const keyCpGpGrid = `${counterpart.shipper.id}_${counterpart.material.id}_${counterpart.grid.id}_${counterpart.gridPoint.id}`;
                if (this._counterpartsByGridPoint[keyCpGpGrid] == null) {
                    this._counterpartsByGridPoint[keyCpGpGrid] = [];
                }
                if (!this._counterpartsByGridPoint[keyCpGpGrid]?.some((a) => isSame(a, counterpart.entity))) {
                    this._counterpartsByGridPoint[keyCpGpGrid]?.push(counterpart.entity);
                    this._counterpartsByGridPoint[keyCpGpGrid] = orderBy(
                        (this._counterpartsByGridPoint[keyCpGpGrid] as IOrganisation[]),
                        (a) => a.shortName
                    );
                }

                const keyDirCpGpGrid = `${counterpart.shipper.id}_${counterpart.material.id}_${counterpart.grid.id}_${counterpart.gridPoint.id}_${counterpart.entity.id}`;
                if (this._directionByCounterpart[keyDirCpGpGrid] == null) {
                    this._directionByCounterpart[keyDirCpGpGrid] = [];
                }
                let direction: IDirection | undefined;
                let directions: IDirection[] = [];
                if (counterpart.direction == TradeDirection.Buy) {
                    direction = this._dataSvc.directions.find((a) => a.value == 1);
                } else if (counterpart.direction == TradeDirection.Sell) {
                    direction = this._dataSvc.directions.find((a) => a.value == -1);
                } else {
                    directions = this._dataSvc.directions.filter((a) => a.value == 1 || a.value == -1);
                }
                if (
                    direction != null &&
                    !this._directionByCounterpart[keyDirCpGpGrid]?.some((a) => isSame(a, direction))
                ) {
                    this._directionByCounterpart[keyDirCpGpGrid]?.push(direction);
                    this._directionByCounterpart[keyDirCpGpGrid] = orderBy(
                        (this._directionByCounterpart[keyDirCpGpGrid] as IDirection[]),
                        (a) => a.code
                    );
                } else if (directions != null) {
                    this._directionByCounterpart[keyDirCpGpGrid] = directions;
                    this._directionByCounterpart[keyDirCpGpGrid] = orderBy(
                        (this._directionByCounterpart[keyDirCpGpGrid] as IDirection[]),
                        (a) => a.code
                    );
                }
            });
        });
        this._subs.push('counterparts-change', subCounterparts);

        this._enableMaterials();
        this._initDictionaryValue = true;
        this._initDeal();
    }

    private _initDeal(deal?: IDeal) {
        if (this._initDictionaryValue) {
            this._clearEnable();
            this._cdr.detectChanges();
            this.active = true;
            this._deal = deal;
            if (this._deal) {
                this.viewUpdate = true;
                this.internalDeal = _.clone(this._deal);
                this._hasAutoDeliveryDate = false;
                this.isEditMode = true;
                if (this.internalDeal.fixedVolume && this.internalDeal.fixedVolumeUnit) {
                    this.volume = new PhysicalValue(this.internalDeal.fixedVolumeUnit, this.internalDeal.fixedVolume);
                } else {
                    this.volume = undefined;
                }
                if (this.internalDeal.fixedPrice && this.internalDeal.fixedPriceUnit) {
                    this.price = new PhysicalValue(this.internalDeal.fixedPriceUnit, this.internalDeal.fixedPrice);
                } else {
                    this.price = undefined;
                }
                this._enableMaterials(true);
                this._enableGrids(true);
                this._enableGridPoints(true);
                this._enableCounterparts(true);
                this._enableDirection(true);
            } else {
                this.viewUpdate = false;
                this.internalDeal = new Deal();
                this.internalDeal.executionDate = new DateTime();
                this.internalDeal.beginningOfDelivery = DateTime.tomorrow().startOf(TimeUnit.Days);
                this.internalDeal.endOfDelivery = this.internalDeal.beginningOfDelivery.addDays(1);
                this._hasAutoDeliveryDate = true;
                if (this._shipper) {
                    this.internalDeal.entity = this._shipper;
                } else if (this._appSvc.shipper) {
                    this.internalDeal.entity = this._appSvc.shipper;
                }
                this.isEditMode = false;
                this._enableMaterials();
            }
            this.isLoading = false;
            this._cdr.detectChanges();
        }
    }

    _clearEnable(): void {
        this.form.reset();
        this.volume = undefined;
        this.price = undefined;
        this.materialsEnable = [];
        this.gridsEnable = [];
        this.gridPointsEnable = [];
        this.counterPartsEnable = [];
        this.directionEnable = [];
    }

    private _initUnit(): void {
        this.energyUnits = orderBy(
            this._dataSvc.units?.filter((unit) => unit.family.score == UnitFamiliesUmScore.Power),
            (a) => a.name
        );
        this.energyPriceUnits = orderBy(
            this._dataSvc.units?.filter((unit) => unit.family.score == UnitFamiliesUmScore.PriceEnergy),
            (val) => val.id
        );

        this.familyUnitPower = this.energyUnits && this.energyUnits.length ? this.energyUnits[0]?.family : undefined;
        this.familyUnitPrice =
            this.energyPriceUnits && this.energyPriceUnits.length ? this.energyPriceUnits[0]?.family : undefined;
    }

    /****************** Load dictionary ******************/
    private _enableMaterials(isInit: boolean = false) {
        this.materialsEnable = [];
        if (this._materialsEnableByEntity && this.internalDeal && this.internalDeal.entity) {
            this.materialsEnable = this._materialsEnableByEntity[this.internalDeal.entity.id] ?? [];
            if (!isInit) {
                if (this.materialsEnable?.length === 1) {
                    this.internalDeal.commodity = undefined;
                    this.onChangeMaterial(this.materialsEnable[0]);
                } else {
                    this.onChangeMaterial(undefined);
                }
            }
        } else {
            this.onChangeMaterial(undefined);
        }
    }

    private _enableGrids(isInit: boolean = false) {
        this.gridsEnable = [];
        if (this.internalDeal && this.internalDeal.entity && this.internalDeal.commodity) {
            this.gridsEnable =
                this._gridsEnableByMaterial[`${this.internalDeal.entity.id}_${this.internalDeal.commodity.id}`] || [];
            // this._cdr.detectChanges();
            if (!isInit) {
                if (this.gridsEnable.length === 1) {
                    this.internalDeal.grid = undefined;
                    this.onChangeGrid(this.gridsEnable[0]);
                } else {
                    this.onChangeGrid(undefined);
                }
            }
        } else {
            this.onChangeGrid(undefined);
        }
    }

    private _enableGridPoints(isInit: boolean = false) {
        this.gridPointsEnable = [];
        if (this.internalDeal && this.internalDeal.entity && this.internalDeal.commodity && this.internalDeal.grid) {
            this.gridPointsEnable =
                this._gridPointsByGrid[
                    `${this.internalDeal.entity.id}_${this.internalDeal.commodity.id}_${this.internalDeal.grid.id}`
                ] || [];
            // this._cdr.detectChanges();
            if (!isInit) {
                if (this.gridPointsEnable.length == 1) {
                    this.internalDeal.gridPoint = undefined;
                    this.onChangeGridPoint(this.gridPointsEnable[0]);
                } else {
                    this.onChangeGridPoint(undefined);
                }
            }
        } else {
            this.onChangeGridPoint(undefined);
        }
    }

    private _enableCounterparts(isInit: boolean = false) {
        this.counterPartsEnable = [];
        if (
            this.internalDeal &&
            this.internalDeal.entity &&
            this.internalDeal.commodity &&
            this.internalDeal.grid &&
            this.internalDeal.gridPoint
        ) {
            this.counterPartsEnable =
                this._counterpartsByGridPoint[
                    `${this.internalDeal.entity.id}_${this.internalDeal.commodity.id}_${this.internalDeal.grid.id}_${this.internalDeal.gridPoint.id}`
                ] || [];
            // this._cdr.detectChanges();
            if (!isInit) {
                if (this.counterPartsEnable.length == 1) {
                    this.internalDeal.counterpart = undefined;
                    this.onChangeCounterpart(this.counterPartsEnable[0]);
                } else {
                    this.onChangeCounterpart(undefined);
                }
            }
        } else {
            this.onChangeCounterpart(undefined);
        }
    }

    private _enableDirection(isInit: boolean = false) {
        this.directionEnable = [];
        if (
            this.internalDeal &&
            this.internalDeal.entity &&
            this.internalDeal.commodity &&
            this.internalDeal.grid &&
            this.internalDeal.gridPoint &&
            this.internalDeal.counterpart
        ) {
            this.directionEnable =
                this._directionByCounterpart[
                    `${this.internalDeal.entity.id}_${this.internalDeal.commodity.id}_${this.internalDeal.grid.id}_${this.internalDeal.gridPoint.id}_${this.internalDeal.counterpart.id}`
                ] || [];
            // this._cdr.detectChanges();
            if (!isInit) {
                if (this.directionEnable.length == 1) {
                    this.internalDeal.direction = this.directionEnable[0];
                } else {
                    this.internalDeal.direction = undefined;
                }
            }
        } else {
            this.internalDeal.direction = undefined;
        }
    }

    /****************** View Events ******************/
    onChangeShipper(shipper: IOrganisation) {
        if (_ez.isSame(this.internalDeal.entity, shipper)) return;
        this.internalDeal.entity = shipper ? shipper : undefined;
        this._enableMaterials();
        this._cdr.detectChanges();
    }

    onChangeMaterial(material: IMaterial | undefined) {
        if (_ez.isSame(this.internalDeal.commodity, material)) return;
        this.internalDeal.commodity = material ? material : undefined;
        this._enableGrids();
        this._cdr.detectChanges();
    }

    onChangeGrid(grid: IGrid | undefined) {
        if (_ez.isSame(this.internalDeal.grid, grid)) return;
        this.internalDeal.grid = grid ? grid : undefined;
        if (this.internalDeal.grid && this.internalDeal.grid.unit && !this.internalDeal.fixedVolumeUnit) {
            this.volume = new PhysicalValue(
                _.find(this.energyUnits, { id: this.internalDeal.grid.unit.id }) as IUnit,
                this.internalDeal.fixedVolume
            );
            this.volume.value = this.internalDeal.fixedVolume;
        }
        if (this.internalDeal?.grid != null) {
            this._timezone = this._appSvc.timezone ?? TimeZone.create(this.internalDeal.grid.calendar.ianaName);
            if (this._hasAutoDeliveryDate) {
                this.internalDeal.beginningOfDelivery = DateTime.tomorrow()
                    .setTimezone(this.internalDeal.grid.calendar.ianaName)
                    .startOf(TimeUnit.Days)
                    .addHours(this.internalDeal.grid.calendar.offset);
                this.internalDeal.endOfDelivery = this.internalDeal.beginningOfDelivery.addDays(1);
            }
        } else {
            this._timezone = undefined;
        }

        this._enableGridPoints();
        this._cdr.detectChanges();
    }

    onChangeGridPoint(gridPoint: IGridPoint | undefined) {
        if (_ez.isSame(this.internalDeal.gridPoint, gridPoint)) return;
        this.internalDeal.gridPoint = gridPoint ? gridPoint : undefined;
        this._enableCounterparts();
        this._cdr.detectChanges();
    }

    onChangeCounterpart(counterpart: IOrganisation | undefined) {
        if (_ez.isSame(this.internalDeal.counterpart, counterpart)) return;
        this.internalDeal.counterpart = counterpart ? counterpart : undefined;
        this._enableDirection();
        this._cdr.detectChanges();
    }

    onChangeVolume(volume: IPhysicalValue) {
        if (_ez.isSame(this.volume, volume)) return;
        this.volume = volume;
        if (this.volume) {
            this.internalDeal.fixedVolume = this.volume.value;
            this.internalDeal.fixedVolumeUnit = this.volume.unit;
        } else {
            this.internalDeal.fixedVolume = undefined;
            this.internalDeal.fixedVolumeUnit = undefined;
        }
    }

    onChangePrice(price: IPhysicalValue) {
        if (_ez.isSame(this.price, price)) return;
        this.price = price;
        if (this.price) {
            this.internalDeal.fixedPrice = this.price.value;
            this.internalDeal.fixedPriceUnit = this.price.unit;
        } else {
            this.internalDeal.fixedPrice = undefined;
            this.internalDeal.fixedPriceUnit = undefined;
        }
    }

    onChangeDirection(direction: Direction): void {
        if (_ez.isSame(this.internalDeal.direction, direction)) return;
        this.internalDeal.direction = direction;
        this.getCounterpartDeliveryStartDate();
        this._cdr.detectChanges();
    }

    formSubmit() {
        this.isLoading = true;
        let obs: Observable<IDeal>;
        if (!this._contractId) {
            return;
        }
        if (this.isEditMode) {
            obs = this._api.balancings.deals.update(this._contractId, this.internalDeal);
        } else {
            obs = this._api.balancings.deals.create(this._contractId, this.internalDeal, true);
        }
        obs.subscribe(
            (updateDeal: IDeal) => {
                this._initDeal(updateDeal);
                this.dealChange.emit(this._deal);
                this.onSubmit.emit();
                if (this._dealEditSuccess) {
                    this._toasterSvc.success(this._dealEditSuccess);
                }
                this.isLoading = false;
            },
            () => {
                this.onError.emit();
                this.isLoading = false;
                this._cdr.detectChanges();
            }
        );
    }

    onAddPriceCurveClicked() {
        this.isAddingPriceCurve = true;
    }

    onAddEnergyCurveClicked() {
        this.isAddingEnergyCurve = true;
    }

    onCurveEnergyAdded(eventForm: EventForm<ICurveBase>) {
        if (!this.hasCurveCreateRight) return;
        switch (eventForm.status) {
            case StatusForm.Valid:
                if (!this.powerCurves) {
                    this.powerCurves = [];
                }
                this.powerCurves.push(eventForm.data);
                this.powerCurves = _.clone(this.powerCurves);
                this.internalDeal.variableVolume = eventForm.data;
                this.isAddingEnergyCurve = false;
                break;
            case StatusForm.Cancel:
                this.isAddingEnergyCurve = false;
                break;
            case StatusForm.Error:
                break;
        }
    }

    onCurvePriceAdded(eventForm: EventForm<ICurveBase>) {
        if (!this.hasCurveCreateRight) return;
        switch (eventForm.status) {
            case StatusForm.Valid:
                if (!this.priceCurves) {
                    this.priceCurves = [];
                }
                this.priceCurves.push(eventForm.data);
                this.priceCurves = _.clone(this.priceCurves);
                this.internalDeal.variableVolume = eventForm.data;
                this.isAddingPriceCurve = false;
                break;
            case StatusForm.Cancel:
                this.isAddingPriceCurve = false;
                break;
            case StatusForm.Error:
                break;
        }
    }

    private _subjectAlertChange: Subject<boolean> | undefined;
    canClosedForm: () => Promise<boolean> = () => this._canClosedForm();
    private _canClosedForm(): Promise<boolean> {
        if (this._isFormChanged) {
            this.openConfirm = true;
            this._cdr.detectChanges();
            if (!this._subjectAlertChange) {
                this._subjectAlertChange = new Subject<boolean>();
            }
            //@ts-ignore
            return this._subjectAlertChange.toPromise();
        }
        return Promise.resolve(true);
    }

    onConfirmClickButton(confirmChange: boolean) {
        if (confirmChange) {
            if (this.form) {
                this.form.cancel();
            }
        }
        if (this._subjectAlertChange) {
            this._subjectAlertChange.next(confirmChange === true);
            this._subjectAlertChange.complete();
            this._subjectAlertChange = undefined;
        }
        this._cdr.detectChanges();
    }

    getCounterpartDeliveryStartDate(): void {
        const linksByGridAndGridPoints = this._dataSvc.counterparties.filter(
            (l: ICounterpart) =>
                _ez.isSame(l.grid, this.internalDeal.grid) && _ez.isSame(l.gridPoint, this.internalDeal.gridPoint)
        );

        // const stakeHolders = _.flatMap(linksByGridAndGridPoints, (l: ILink) => l.stakeHolders);
        // const filteredStakeHolders = _.filter(stakeHolders, (sh: ILinkStakeHolder) => {
        //     return _ez.isSame(sh.counterpart, this.internalDeal.counterpart) && _ez.isSame(sh.material, this.internalDeal.commodity) && _ez.isSame(sh.direction, this.internalDeal.direction);
        // });

        this.counterpartDeliveryStartDate = undefined;
        this.counterpartDeliveryEndDate = undefined;
        if (linksByGridAndGridPoints.length > 0) {
            this.counterpartDeliveryStartDate = _.minBy(linksByGridAndGridPoints, (sh: ICounterpart) =>
                sh.validity.from.toJsDate()
            )?.validity.from;
            this.counterpartDeliveryEndDate = _.maxBy(linksByGridAndGridPoints, (sh: ICounterpart) =>
                sh.validity.to.toJsDate()
            )?.validity.to;
        }
        this.internalDeal.beginningOfDelivery = _.clone(this.internalDeal.beginningOfDelivery);
        this.internalDeal.endOfDelivery = _.clone(this.internalDeal.endOfDelivery);
    }
}
