import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import * as _ez from "@eznergy/core";
import { EzNotificationService } from '@eznergy/toaster';
import { IGridPoint } from '@eztypes/webapi';
import { TranslateService } from '@ngx-translate/core';
import { BaseCardComponent } from '../base-card.component';

@Component({
    selector: 'grid-point-card',
    templateUrl: './grid-point.card.html',
    styleUrls: ['./grid-point.card.scss', "../cards.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridPointCard extends BaseCardComponent<IGridPoint> {

    private _translate: string = '';

    constructor(
        cdr: ChangeDetectorRef,
        private readonly _notifSvc: EzNotificationService,
        private readonly _serviceTranslate: TranslateService
    ) {
        super(cdr);
        const sub = this._serviceTranslate.get("cards.gridpoint.clipboard").subscribe((translate) => {
            this._translate = translate;
        });
        this._subs.push('copy', sub);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    copyIdToClipboard(): void {
        _ez.writeToClipboard(this.value.id.toString());
        this._notifSvc.success(this._translate);
    }
}
