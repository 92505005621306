// @ts-nocheck
import { Directive, ElementRef, Input } from "@angular/core";
import { Subscriptions } from "@core/subscriptions";
import { timer } from "rxjs";
@Directive({
    selector: '[scrollfocus]'
})
export class ScrollFocusDirective {

    @Input()
    get scrollfocus(): boolean {
        return this._scrollfocus;
    }
    set scrollfocus(value: boolean) {
        this._scrollfocus = value;
        this._checkScroll();
    }
    private _scrollfocus: boolean;

    @Input()
    get delay(): number {
        return this._delay;
    }
    set delay(value: number) {
        this._delay = value;
    }
    private _delay: number;

    private _init: boolean = false;
    private readonly _subs: Subscriptions = new Subscriptions();

    constructor(private _el: ElementRef) {

    }

    ngAfterViewInit(): void {
        this._init = true;
        this._checkScroll();
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }
    private _checkScroll(): void {
        if (this._init && this._scrollfocus) {
            if (this._delay) {
                const sub = timer(this._delay).subscribe(() => {
                    this._positionScroll();
                });
                this._subs.push("sf-timer", sub);
            } else {
                this._positionScroll();
            }
        }
    }

    private _positionScroll(): void {
        let element: HTMLElement = this._el.nativeElement;
        element.scrollIntoView({ inline: "center", block: "center", behavior: "auto" });
    }
}
