// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ApiService } from '@eznergy/webapi';
import { IMaterial, IOrganisation, ISession, Session, SessionNameStatus, SessionType } from "@eztypes/webapi";
import { ApplicationService } from '@services/application.service';
import { FormBase } from "src/app/pages/form/core/form-base";
import { Subscription } from 'rxjs';

@Component({
    selector: 'at-session-form',
    templateUrl: './session.component.html',
    styleUrls: ['./session.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AtSessionForm extends FormBase {

    @Output() valueChange: EventEmitter<ISession> = new EventEmitter<ISession>();

    @Input()
    get value(): Session {
        return this._session;
    }
    set value(value: Session) {
        if (this.value !== value) {
            this._session = value || new Session();
            this._prepareCommodity();
            this._cdr.markForCheck();
        }
    }
    private _session: Session;

    @Input()
    get commodities(): IMaterial[] {
        return this._commodities;
    }
    set commodities(value: IMaterial[]) {
        if (this.commodities !== value) {
            this._commodities = value;
            this._prepareCommodity();
            this._cdr.markForCheck();
        }
    }
    private _commodities: IMaterial[];

    @Input()
    get shippers(): IOrganisation[] {
        return this._shippers;
    }
    set shippers(value: IOrganisation[]) {
        if (this.shippers !== value) {
            this._shippers = value;
            this._cdr.markForCheck();
        }
    }
    private _shippers: IOrganisation[];

    enabledSessionTypes = [SessionType.Flex, SessionType.Position];

    constructor(private _cdr: ChangeDetectorRef, private _api: ApiService, private _appSvc: ApplicationService) {
        super();
    }

    formSubmit() {
        this.valueChange.emit(this._session);
        this.onSubmit.emit();
    }

    errorName: boolean = false;
    private _subCheckName: Subscription;
    checkNameValidity(newName: string): void {
        if (this._subCheckName) {
            this._subCheckName.unsubscribe();
        }
        this._subCheckName = this._api.autotrading.sessions.checkValidatyName(this._appSvc.contract.id, newName).subscribe((status: SessionNameStatus) => {
            if (status === SessionNameStatus.Ok) {
                this.value.name = newName;
                this.errorName = false;
                this._cdr.detectChanges();
            } else {
                this.value.name = newName;
                this.errorName = true;
                this._cdr.detectChanges();
            }
        }, () => { }, () => {
            this._subCheckName = undefined;
        });
    }

    private _prepareCommodity(): void {
        if (this._session != null && this.commodities != null) {
            this._session.material = this.commodities[0];
        }
    }
}

