// @ts-nocheck
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PRIMARY_OUTLET } from '@angular/router';
import { Logger } from '@core/logger';
import { BasePage } from '@core/pages';
import { RouterService } from '@services/router.service';

@Component({
    selector: "empty-page",
    templateUrl: "./empty.page.html",
    styleUrls: ["./empty.page.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class EmptyPage extends BasePage {

    get hasLoader(): boolean {
        return this._hasLoader;
    }
    private _hasLoader: boolean = false;

    @Input()
    get info(): string {
        return this._info;
    }
    set info(value: string) {
        this._info = value;
    }
    private _info: string;

    constructor(private readonly _routerSvc: RouterService, logger: Logger) {
        super(logger);
    }

    ngOnInit(): void {
        this._routerSvc.paramsChange.subscribe((params) => {
            if (params != null) {
                const data = params.data.get(PRIMARY_OUTLET);
                if (data.hasLoader != null) {
                    this._hasLoader = data.hasLoader === true;
                }
                if (data.info != null && this.info == null) {
                    this._info = data.info;
                }
            }
        });
    }
}
