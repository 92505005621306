<ez-table *ngIf="data" [data]="rows" class="order-linear-table" autofit="false">
    <ez-row-header>
        <th class="no-border-top-left"></th>
        <th class="border-right no-border-top"></th>
        <th class="border-right border-top" [attr.colspan]="columns.length + 1">
            {{'balancing.ets.ol.column.order'| translate}}
        </th>
        <td class="col-separate"></td>
        <th class="border-left border-top border-right" *ngIf="marketData && isLinear" colspan="4">
            {{'balancing.ets.ol.column.market' | translate}}
        </th>
    </ez-row-header>
    <ez-row-header>
        <th class="border-left">{{'balancing.ets.ol.column.date' | translate}}</th>
        <th class="last-fixed">{{'balancing.ets.ol.column.position' | translate}} (MW)</th>
        <th *ngFor="let item of columns; let i = index; first as isFirst; last as isLast;" class="col-edit">
            <div class="col-edit-price">
                <span *ngIf="btnDisable || isFirst || isLast">{{ item.price }}</span>
                <ng-container *ngIf="!isFirst && !isLast && !btnDisable">
                    <ez-button class="btn-del-col" [severity]="'danger'" [icon]="'minus'" [small]="true"
                        (onClick)="onDeleteColumnPrice(i)" [disabled]="btnDisable">
                    </ez-button>
                    <ez-input-number #inputPrice [value]="item.price"
                        (focusout)="onBlurPriceColumn(i, inputPrice, item)"
                        (valueChange)="onBlurPriceColumn(i, $event, item)" [step]="priceTick">
                    </ez-input-number>
                </ng-container>
                <ez-button *ngIf="!btnDisable && !isLast" class="btn-insert-col" [icon]="'plus'" [small]="true"
                    (onClick)="onAddColumnPrice(i+1)"
                    [disabled]="btnDisable || !area || item.price == area.maximumPrice - priceTick">
                </ez-button>
            </div>
        </th>
        <th class="border-right">{{'balancing.ets.ol.column.comment' | translate}}</th>

        <td class="col-separate"></td>

        <th class="border-left" *ngIf="marketData && isLinear"> {{'balancing.ets.ol.column.price' | translate}}
            ({{'balancing.ets.ol.column.unitEMW' | translate}})</th>
        <th *ngIf="marketData && isLinear"> {{'balancing.ets.ol.column.net' | translate}}
            ({{'balancing.ets.ol.column.unitMW' | translate}})</th>
        <th *ngIf="marketData && isLinear"> {{'balancing.ets.ol.column.purchase' | translate}}
            ({{'balancing.ets.ol.column.unitMW' | translate}})</th>
        <th class="border-right" *ngIf="marketData && isLinear"> {{'balancing.ets.ol.column.sale' | translate}}
            ({{'balancing.ets.ol.column.unitMW' | translate}})</th>
    </ez-row-header>
    <!-- TBODY -->
    <ez-column property="periodStart" [fixed]='true'>
        <th *ezTbCell="let item" class="border-left">{{item.periodStart | ezdate}}</th>
    </ez-column>
    <ez-column property="position" [fixed]='true'>
        <th class="last-fixed" *ezTbCell="let item">{{item.position.value | number:'1.2-2'}}</th>
    </ez-column>
    <ez-column property="prices" *ngFor="let col of columns; index as i;" [indexCell]="i">
        <td *ezTbCell="let price; index as j;" class="ets-data-volume" [ngClass]="{
						'has-success':!isNew && isActiveVersion && !hasDataChanged && !price.hasDataChanged,
						'has-delete': !isNew && isActiveVersion && !hasDataChanged && !hasData,
						'has-warning': price && (price.originalVolume != price.volume || price.originalPrice != price.price) && !isNew && !rows[j].illogicalBid,
						'has-error': price && (price.missingVolume || rows[j].illogicalBid)}">
            <span *ngIf="btnDisable;else volumeInput">{{price?.volume}}</span>
            <ez-icon-font *ngIf="rows[j].illogicalBid" [name]="'fa-exclamation-circle'" class="error-icon"
                [ezTooltip]="'balancing.ets.ol.errors.illogicalBid' | translate">
            </ez-icon-font>
            <ez-icon-font *ngIf="price?.missingVolume" [name]="'fa-exclamation-circle'" class="error-icon"
                [ezTooltip]="'balancing.ets.ol.errors.missingVolume' | translate">
            </ez-icon-font>
            <ng-template #volumeInput>
                <ez-input-number *ngIf="!btnDisable && price" [(value)]="price.volume" [step]="volumeTick"
                    (valueChange)="onBlurColumnVolume(j,i)">
                </ez-input-number>
            </ng-template>
        </td>
    </ez-column>

    <ez-column property="comment">
        <td *ezTbCell="let item; index as i;" class="comment border-right"
            [ngClass]="{'cell-changed': item.originalComment != item.comment}">
            <span *ngIf="btnDisable;else commentInput">{{item.comment}}</span>
            <ng-template #commentInput>
                <ez-input-text *ngIf="!btnDisable" [value]="item.comment"
                    (valueChange)="onBlurColumnComment(i, $event, item)">
                </ez-input-text>
            </ng-template>
        </td>
    </ez-column>
    <ez-column>
        <td *ezTbCell="let item; index as i" class="col-separate">
            <ez-button [small]="true" *ngIf="!btnDisable" class="btn-clear-row" [severity]="'danger'" [icon]="'times'"
                (onClick)="onClearRow(i)">
            </ez-button>
        </td>
    </ez-column>
    <!-- MARKET RESULT -->
    <ez-column property="marketResult.price" *ngIf="marketResult">
        <td *ezTbCell="let item" class="border-left">
            {{ item.marketResult?.price | number:'1.0-2'}} </td>
    </ez-column>
    <ez-column property="marketResult.net" *ngIf="marketResult">
        <td *ezTbCell="let item"> {{ item.marketResult?.net | number:'1.0-2'}} </td>
    </ez-column>
    <ez-column property="marketResult.purchase" *ngIf="marketResult">
        <td *ezTbCell="let item"> {{ item.marketResult?.purchase | number:'1.0-2'}} </td>
    </ez-column>
    <ez-column property="marketResult.sale" *ngIf="marketResult">
        <td *ezTbCell="let item" class="border-right"> {{ item.marketResult?.sale | number:'1.0-2'}} </td>
    </ez-column>

    <ez-row-footer>
        <th class="border-left">{{'balancing.ets.ol.column.total' | translate}}</th>
        <th class="last-fixed">{{total.position}} {{'balancing.ets.ol.column.unitMWH' | translate}}</th>
        <th *ngFor="let price of total.prices; let j = index; trackBy: trackByFn">{{price}}
            {{'balancing.ets.ol.column.unitMWH' | translate}}</th>
        <th class="border-right"></th>
        <th class="col-separate"></th>
        <th class="marketResult" *ngIf="marketResult"></th>
        <th class="marketResult" *ngIf="marketResult"></th>
        <th class="marketResult" *ngIf="marketResult"></th>
        <th class="marketResult" *ngIf="marketResult"></th>
    </ez-row-footer>
</ez-table>