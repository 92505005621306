import { inject  } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn } from "@angular/router";
import { AppQueryParamsKey } from "@core/application.model";
import { AppPages, RouterService } from "@services/router.service";

export const multiShipperGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const router = inject(RouterService);
    const contractGuid = route.paramMap.get('contractGuid')!; 
    const queryParamShipper = route.queryParamMap.get(AppQueryParamsKey.Shipper);
    if (queryParamShipper && queryParamShipper === "0")
        return router.getUrlTree(AppPages.Positions, { contractId: contractGuid });
    return true;
}

