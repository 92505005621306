import { FormatWidth } from "@angular/common";
import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, ViewChild, ViewEncapsulation } from "@angular/core";
import { PRIMARY_OUTLET } from "@angular/router";
import { AppUser } from "@core/app-user.model";
import { AppCommodities, AppQueryParamsKey, AppQuickDates, TypeDisplayMenu } from "@core/application.model";
import { Logger } from "@core/logger";
import { Subscriptions } from "@core/subscriptions";
import { IconSize } from "@enums/icon-size.enum";
import { IconType } from "@enums/icon-type.enum";
import { HorizontalPosition, I18nService, PositionAlign, VerticalPosition } from "@eznergy/components";
import * as _ez from "@eznergy/core";
import { contains, containsSame, isSame, orderBy } from "@eznergy/core";
import { ApiService, UserConnectedInfosService } from "@eznergy/webapi";
import { DateTime, Dictionary, IDictionary, TimeUnit, TimeZone } from "@eztypes/generic";
// @ts-ignore
import { AuctionAccount, AuctionExchange, Exchange as AtExchange, ExchangeAccessType, ExchangeUser, GridTypeCode, IAuctionAccount, IAuctionsAvailableConfiguration, IAvailableMarket, IContract, ICountry, IExchangeUser, IGrid, IMarketConfiguration, IOrganisation, MaterialCode, UserRight, Organisation } from "@eztypes/webapi";
import { HubConnectionState } from "@microsoft/signalr";
import { Exchange } from '../../modules/autotrading/models/exchange.model';
import { getAuctionExchangeParam } from '../../modules/auction/order/helper/exchange-param-id';
import { AuctionExchangeId, AuctionExchangeParam } from '../../modules/auction/order/models/parameters';
import { AppConfig } from "@services/app-config.service";
import { ApplicationService } from "@services/application.service";
import { DataService } from "@services/data.service";
import { AppPages, PageParams, RouterService } from "@services/router.service";
import { StaticService } from "@services/static.service";
import * as _ from "lodash";
import { combineLatest, fromEvent } from "rxjs";
// @ts-ignore
import { first, map } from "rxjs/operators";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AlertModalComponent } from "src/app/modules/shared/modals/alert/alert-modal.component";
import { ContactSupportModalComponent } from "./contact-support-modal/contact-support-modal.component";

export interface IDataCountry {
    country: ICountry;
    hasPower: boolean;
    hasGas: boolean;
}

export enum FilterTab {
    Countries,
    Areas,
    Exchanges
}

class AuctionAccountView extends AuctionAccount {
    selected: boolean | undefined;

    constructor(id: number, name: string, exchange: AuctionExchange, selected?: boolean) {
        super(id, name, exchange);
        this.selected = selected;
    }
}

export class ExchangeUserView extends ExchangeUser {
    selected: boolean | undefined;
    exchange: AtExchange;

    constructor(id: number,
        name: string,
        organisation: IOrganisation,
        accessType: ExchangeAccessType,
        exchange: AtExchange,
        selected?: boolean) {
        super();

        this.id = id;
        this.name = name;
        this.organisation = organisation;
        this.accessType = accessType;
        this.exchange = exchange;
        this.selected = selected;
    }
}

export class ExchangeView {
    auction: AuctionExchangeParam | undefined;
    autotrading: Exchange | undefined;
    label: string;

    constructor(label: string, auction?: AuctionExchangeParam, autotrading?: Exchange) {
        this.auction = auction;
        this.autotrading = autotrading;
        this.label = label;
    }
}

enum DirectionChangeDate {
    next = "next",
    previous = "previous"
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AppHeaderComponent {

    readonly appPages = AppPages;
    readonly quickDates = AppQuickDates;
    readonly commoTypes = AppCommodities;
    readonly filterTabs = FilterTab;
    readonly PosPopover: PositionAlign = new PositionAlign(VerticalPosition.Center, HorizontalPosition.Left);

    filterTab: FilterTab = FilterTab.Countries;
    private _gridsByAccounts: Dictionary<IGrid[]> | undefined;
    private _gridsByExchangeUser: Dictionary<IGrid[]> | undefined;
    private _autoTradingExchanges: Exchange[] | undefined;
    private _rightScreen: string | undefined;

    private _gasSelected: boolean | undefined;
    get gasSelected(): boolean | undefined {
        return this._gasSelected;
    }

    private _powerSelected: boolean | undefined;
    get powerSelected(): boolean | undefined {
        return this._powerSelected;
    }

    public get canSeeAutoTrading(): boolean | undefined {
        return this._canSeeAutoTrading;
    }
    private _canSeeAutoTrading: boolean | undefined;

    public get canSeeAuction(): boolean | undefined {
        return this._canSeeAuction;
    }
    private _canSeeAuction: boolean | undefined;

    get contracts(): IContract[] | undefined {
        if (this._user == null) {
            return undefined;
        }
        return this._user.contracts;
    }

    get contract(): IContract | undefined {
        return this._contract;
    }
    private _contract: IContract | undefined;

    get shippers(): IOrganisation[] | undefined {
        return this._shippers;
    }
    private _shippers: IOrganisation[] | undefined;

    get shipper(): IOrganisation | undefined {
        return this._shipper;
    }
    private _shipper: IOrganisation | undefined;
    private _shipperId: number | undefined;

    get isMultiShipper(): boolean {
        return this._shipper?.id === 0;
    }

    get date(): DateTime | undefined {
        return this._date;
    }
    private _date: DateTime | undefined;

    get quickDate(): AppQuickDates | undefined {
        return this._quickDate;
    }
    private _quickDate: AppQuickDates | undefined;

    displayPopoverDate: boolean = false;

    get userIcon(): string | undefined {
        if (this._user == null || this._user.username == null) {
            return undefined;
        }
        return this._user.username[0];
    }

    get userName(): string | undefined {
        if (this._user == null) {
            return undefined;
        }
        return this._user.name;
    }

    get countries(): IDataCountry[] | undefined {
        return this._countries;
    }
    private _countries: IDataCountry[] | undefined;

    get countriesSelected(): IDataCountry[] | undefined {
        return this._countriesSelected;
    }
    private _countriesSelected: IDataCountry[] | undefined;

    get hasCountriesSelection(): boolean | undefined {
        return this._hasCountriesSelection;
    }
    private _hasCountriesSelection: boolean | undefined;

    get areasPower(): IGrid[] | undefined {
        return this._areasPower;
    }
    private _areasPower: IGrid[] | undefined;

    get areasPowerSelected(): IGrid[] | undefined {
        return this._areasPowerSelected;
    }
    private _areasPowerSelected: IGrid[] | undefined;

    get hasAreasPowerSelection(): boolean | undefined {
        return this._hasAreasPowerSelection;
    }
    private _hasAreasPowerSelection: boolean | undefined;

    get areasGas(): IGrid[] | undefined {
        return this._areasGas;
    }
    private _areasGas: IGrid[] | undefined;

    get areasGasSelected(): IGrid[] | undefined {
        return this._areasGasSelected;
    }
    private _areasGasSelected: IGrid[] | undefined;

    get hasAreasGasSelection(): boolean | undefined {
        return this._hasAreasGasSelection;
    }
    private _hasAreasGasSelection: boolean | undefined;

    get hasAreasSelection(): boolean | undefined {
        return this._hasAreasSelection;
    }
    private _hasAreasSelection: boolean | undefined;

    get hasExchangeSelection(): boolean | undefined {
        return this._hasExchangeSelection;
    }
    private _hasExchangeSelection: boolean | undefined;

    get hasMarketSelection(): boolean | undefined {
        return this._hasMarketSelection;
    }
    private _hasMarketSelection: boolean | undefined;

    get exchanges(): ExchangeView[] | undefined {
        return this._exchangesView;
    }
    private _exchangesView: ExchangeView[] | undefined;

    get exchangesSelected(): ExchangeView[] | undefined {
        return this._exchangesSelected;
    }
    private _exchangesSelected: ExchangeView[] | undefined;

    private _exchanges: AuctionExchangeParam[] = [];
    private _exchangeByShipper: IDictionary<AuctionExchangeParam[]> | undefined;

    get accounts(): AuctionAccountView[] | undefined {
        return this._accounts;
    }
    private _accounts: AuctionAccountView[] | undefined;
    private _accountsCache: IAuctionAccount[] | undefined;

    get users(): ExchangeUserView[] | undefined {
        return this._users;
    }
    private _users: ExchangeUserView[] | undefined;

    get uniqueUsers(): ExchangeUserView[] | undefined {
        return _.uniqBy(this._users, 'id');
    }

    private _usersCache: IExchangeUser[] | undefined;

    private _usersSelected: ExchangeUserView[] | undefined;

    private _accountsSelected: IAuctionAccount[] | undefined;
    private _accountsByShipper: IDictionary<IAuctionAccount[]> | undefined;

    get hasAccountsSelection(): boolean | undefined {
        return this._hasAccountsSelection;
    }
    private _hasAccountsSelection: boolean | undefined;

    get commodities(): AppCommodities[] | undefined {
        return this._commodities;
    }
    private _commodities: AppCommodities[] | undefined;

    get commoditiesSelected(): AppCommodities[] | undefined {
        return this._commoditiesSelected;
    }
    private _commoditiesSelected: AppCommodities[] | undefined;

    get countEvents(): number | undefined {
        return this._countEvents;
    }
    private _countEvents: number | undefined;

    get eventSvcEnable(): boolean | undefined {
        return this._eventSvcEnable;
    }
    private _eventSvcEnable: boolean | undefined;

    get countEventsChange(): boolean | undefined {
        return this._countEventsChange;
    }
    private _countEventsChange: boolean | undefined;

    get hasRightEvents(): boolean {
        return this._hasRightEvents;
    }
    private _hasRightEvents: boolean = false;

    readonly cultureUsed: string | undefined;
    readonly cultureDecimalSeparator: string;
    readonly cultureThousandSeparator: string;
    readonly cultureTimeSeparator: string;
    readonly cultureDateFormat: string;
    readonly cultureTimeFormat: string;

    get hasHelpPanel(): boolean {
        return this._hasHelpPanel;
    }
    private _hasHelpPanel: boolean = false;

    displayUserMenu: boolean = false;

    private _user: AppUser | undefined;
    private _grids: IGrid[] | undefined;
    private _gridByCountry: Map<number, IGrid[]> | undefined;
    private _areasByGrid: Map<number, Map<number, boolean>> | undefined;

    private readonly _subs: Subscriptions = new Subscriptions();
    get shipperOnLoad(): boolean {
        return this._shipperOnload;
    }
    private _shipperOnload: boolean = false;

    get contractOnChange(): boolean {
        return this._contractOnChange;
    }
    private _contractOnChange: boolean = false;

    private _dateSelected: string;
    public dateQueryUrl: string | undefined;
    public isDateChanges: boolean = false;

    public directionChangeDate = DirectionChangeDate;

    @HostBinding("style.background")
    get bgColor(): string {
        return this._config?.environment?.color ?? undefined;
    }

    @ViewChild("userMenuInfo") readonly userMenuInfo: ElementRef<HTMLElement> | undefined;

    constructor(
        private readonly _routerSvc: RouterService,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _api: ApiService,
        private readonly _i18nSvc: I18nService,
        private readonly _appSvc: ApplicationService,
        private readonly _appRef: ApplicationRef,
        private readonly _logger: Logger,
        private readonly _staticService: StaticService,
        private readonly _config: AppConfig,
        private readonly _dataSvc: DataService,
        private readonly _userConnectedInfos: UserConnectedInfosService,
        private dialog: MatDialog
    ) {
        const cultures = this._i18nSvc.currentCulture.split('-');
        if (cultures.length === 2) {
            this.cultureUsed = cultures[1]?.toLowerCase();
        } else {
            this.cultureUsed = cultures[0]?.toLowerCase();
        }
        this.cultureDecimalSeparator = this._i18nSvc.getDecimalSeparator();
        this.cultureThousandSeparator = this._i18nSvc.getThousandSeparator();
        this.cultureTimeSeparator = this._i18nSvc.getTimeSeparator();
        this.cultureDateFormat = this._i18nSvc.getDateFormat(FormatWidth.Short);
        this.cultureTimeFormat = this._i18nSvc.getTimeFormat(FormatWidth.Medium);
        this._dateSelected = this._getFormatISODate(new Date());
    }

    ngOnInit(): void {
        const subUser = this._appSvc.userChange.subscribe((user) => {
            this._user = user;
            const cGuid = this._user?.preferences.contract;
            if (cGuid != null) {
                this._contract = this._user?.contracts.find((a) => a.contractGuid === cGuid);
            }
            if (this._appSvc.preferences) {
                this.quickDateChange(this._appSvc.preferences.quickDate);
            }
            this._cdr.detectChanges();
        });
        this._subs.push("user-change", subUser);
        const subContract = this._appSvc.contractChange.subscribe((appContract) => {
            this._canSeeAuction = this._appSvc.hasRight(UserRight.ScreenAuctionOrders);
            this._canSeeAutoTrading = this._appSvc.hasRight(UserRight.ScreenAutotrading);
            this._contractOnChange = true;
            this._cdr.detectChanges();
            this._contract = appContract?.contract;
            this._loadDataByContract();
            this._contractOnChange = false;
            this._cdr.detectChanges();
        });
        this._subs.push("contract-change", subContract);
        const subParams = this._routerSvc.paramsChange
        .subscribe((params: PageParams | undefined) => {
            if (params != null) {
                const query = params.query.get(PRIMARY_OUTLET);
                const shipperId = query[AppQueryParamsKey.Shipper] ?? this._appSvc.appContractPreferences?.shipperId;
                this._appSvc.setShipperIdPreference(shipperId);
                const shipperIdInt = shipperId != null && !isNaN(shipperId) ? parseInt(shipperId) : undefined;
                this._rightScreen = params.data.get('primary')['rightScreen'];
                if (shipperIdInt !== this._shipperId) {
                    this._shipperId = shipperIdInt;
                    this._setShipperSelected();
                }
                const dateString = query[AppQueryParamsKey.Date];
                let forcedDates: boolean = true;
                if (dateString != null) {
                    if (this.dateQueryUrl && this.dateQueryUrl !== dateString) {
                        if (!this.isSameDate(dateString)) {
                            this.setTimerHighlightDateInput();
                        }
                    }
                    this.dateQueryUrl = dateString;
                    const date = new DateTime(dateString, TimeZone.local().name).toUtc().convertTo(TimeZone.current());
                    if (date.isValid) {
                        this._setDates(date);
                        forcedDates = false;
                    }
                }
                if (forcedDates) {
                    const qd = this._appSvc.preferences?.quickDate ?? AppQuickDates.Day;
                    if (qd != AppQuickDates.Custom) {
                        this.quickDateChange(qd);
                    } else {
                        if (this._date == null) {
                            this.dateChanges(DateTime.now().startOf(TimeUnit.Days));
                        } else {
                            this.dateChanges(this._date);
                        }
                    }
                }

                this.displayUserMenu = false;
                this._cdr.detectChanges();
            }
        });
        this._subs.push("params-change", subParams);

        const subTz = this._appSvc.timezoneChange.subscribe(() => {
            this._setDates(this._date?.convertTo(TimeZone.current()));
        });
        this._subs.push("tz-change", subTz);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    isSameDate(dateString: string): boolean {
        const day = this._dateSelected;
        const isSameDay = day == dateString;
        this._dateSelected = dateString;
        return isSameDay;
    }

    private _getFormatISODate(date: Date): string {
        const [datepart, _] = date.toISOString().split('T');
        return datepart!;
    }

    setTimerHighlightDateInput(): void {
        this.isDateChanges = true;
       setTimeout(() => {
            this.isDateChanges = false;
            this._cdr.detectChanges();
       }, 3000);
    }

    toggleMenuDisplay(): void {
        this._appSvc.setMenuType(this._appSvc.menuType === TypeDisplayMenu.Normal ? TypeDisplayMenu.Mini : TypeDisplayMenu.Normal);
    }

    contractChange(contract: IContract): void {
        if (!isSame(contract, this._appSvc.contract)) {
            this.shipperChange(undefined);
            this._loadAuctionConfiguration();
            // @ts-ignore
            let url = this._routerSvc.router.url.replace(this.contract.contractGuid, contract.contractGuid);
            url = url.substring(0, url.indexOf("?"));
            this._contractOnChange = true;
            this._routerSvc.navigateByUrl(url);
        }
    }

    openContractInNewWindow(contract: IContract, event: MouseEvent): void {
        event.stopPropagation();
        // @ts-ignore
        let url = this._routerSvc.router.url.replace(this.contract.contractGuid, contract.contractGuid);
        url = url.substring(0, url.indexOf("?"));
        this._routerSvc.navigateByUrl(url, {inNewTab : true});
    }

    shipperChange(shipper: IOrganisation | undefined): void {
        if (!isSame(shipper, this._shipper)) {
            this._shipper = shipper;
            this._loadExchangesByShipper();
            this._setAuctionDataByShipper();
            this._loadDataByShipper();
            this._loadAccountsByShipper();
            if (this.isMultiShipper) {
                this._routerSvc.navigateTo(AppPages.Positions);
            }
        }
    }

    quickDateChange(qDate: AppQuickDates): void {
        if (this._appSvc.preferences) {
            this._appSvc.preferences.quickDate = qDate;
        }
        this._quickDate = qDate;
        const date = this._convertQuickDate(qDate);
        if (date != null) {
            this._routerSvc.setQueryParams(AppQueryParamsKey.Date, `${date.toISODate()}`);
            this.displayPopoverDate = false;
        }
    }

    dateChanges(date: DateTime): void {
        this._dateSelected = this._getFormatISODate(date.convertTo(TimeZone.utc()).toJsDate());
        this.quickDateChange(AppQuickDates.Custom);
        this._routerSvc.setQueryParams(AppQueryParamsKey.Date, `${date.toISODate()}`);
        this.displayPopoverDate = false;
    }

    commodityChange(commo: AppCommodities): void {
        const index = this._commoditiesSelected?.indexOf(commo);
        if (index === -1) {
            this._commoditiesSelected?.push(commo);
        } else {
            // @ts-ignore
            this._commoditiesSelected?.splice(index, 1);
        }
        // @ts-ignore
        this._commoditiesSelected = orderBy(this._commoditiesSelected, (a) => a, "desc");
        this._defineSelectedCommodities();
        // @ts-ignore
        this._appSvc.shipperPreferences.commodities = this.commoditiesSelected;
        this._filtersGrids();
    }

    countriesChange(countries: IDataCountry[] | undefined, options: {updateAreas: boolean} = { updateAreas: true }): void {
        this._refreshCountriesSelected(countries);
        if ( options?.updateAreas ) {
            const ctsSelected = this.countriesSelected?.map((a) => a.country);
            // @ts-ignore
            this._refreshPowerAreaSelected(this.areasPower.filter((a) => containsSame(ctsSelected, a.country)));
            // @ts-ignore
            this._refreshGasAreaSelected(this.areasGas.filter((a) => containsSame(ctsSelected, a.country)));
        }
        this._refreshAreasSelected();
        this._filtersGrids();
    }

    areasPowerChange(areas: IGrid[] | undefined): void {
        this._refreshPowerAreaSelected(areas);
        this._refreshAreasSelected();
        this._refreshCountriesByAreas();
        this._filtersGrids();
    }

    areasGasChange(areas: IGrid[] | undefined): void {
        this._refreshGasAreaSelected(areas);
        this._refreshAreasSelected();
        this._refreshCountriesByAreas();
        this._filtersGrids();
    }

    exchangesChange(exchanges: ExchangeView[] | undefined): void {
        this._refreshExchangesSelected(exchanges);
        this._filtersAuctionContinuousGrids();
    }

    accountsChange(account: AuctionAccountView): void {
        account.selected = !account.selected;
        const accounts = this._accountsSelected?.slice();
        if (account.selected)
            accounts?.push(account as AuctionAccount);
        else {
            const index = accounts?.findIndex((a) => a.id === account.id);
            // @ts-ignore
            accounts?.splice(index, 1);
        }
        this._refreshAccountsSelected(accounts);
    }

    usersChange(user: ExchangeUserView): void {
        user.selected = !user.selected;
        const users = this._usersSelected?.slice();
        if (user.selected)
            users?.push(user);
        else {
            const index = users?.findIndex((a) => a.id === user.id);
            // @ts-ignore
            users?.splice(index, 1);
        }
        this._refreshUsersSelected(users);
    }

    eventBadgeTransitionEnd(): void {
        this._countEventsChange = false;
    }

    toggleDisplayUserMenu(event: MouseEvent): void {
        event.stopPropagation();
        const key = "user-menu-close";
        this.displayUserMenu = !this.displayUserMenu;
        this._subs.clearSub(key);
        if (this.displayUserMenu) {
            const sub = fromEvent(document, 'click').subscribe((event) => {
                const target: HTMLElement = event.target as HTMLElement;
                if (target == null || !this.userMenuInfo?.nativeElement.contains(target)) {
                    this.displayUserMenu = false;
                    this._cdr.detectChanges();
                }
            });
            this._subs.push(key, sub);
        }
    }

    toggleDisplayHelp(): void {
        this._hasHelpPanel = !this.hasHelpPanel;
        this._appSvc.displayHelp(this.hasHelpPanel);
    }

    toggleSelectionCountries(all: boolean): void {

        if (this.hasCountriesSelection !== all) {
            if (all) {
                this.countriesChange(this.countries?.slice());
            } else {
                this.countriesChange([]);
            }
        }
    }

    toggleSelectionPowerAreas(all: boolean): void {
        if (this.hasAreasPowerSelection !== all) {
            if (all) {
                this.areasPowerChange(this.areasPower?.slice());
            } else {
                this.areasPowerChange([]);
            }
        }
    }

    toggleSelectionGasAreas(all: boolean): void {
        if (this.hasAreasGasSelection !== all) {
            if (all) {
                this.areasGasChange(this.areasGas?.slice());
            } else {
                this.areasGasChange([]);
            }
        }
    }

    toggleSelectionExchanges(all: boolean): void {
        if (all) {
            this.exchangesChange(this.exchanges?.slice());
            this._refreshAccountsSelected(this.accounts?.slice());
        } else {
            this.exchangesChange([]);
            this._refreshAccountsSelected([]);
        }
        this._cdr.detectChanges();
        this._filtersAuctionContinuousGrids();
    }

    toggleSelectionAccounts(all: boolean): void {
        if (all) {
            this._accounts?.forEach((a) => a.selected = true);
            this._users?.forEach((u) => u.selected = true);
            this._refreshAccountsSelected(this.accounts?.slice());
            this._refreshUsersSelected(this.users?.slice());
        } else {
            this._accounts?.forEach((a) => a.selected = false);
            this._users?.forEach((u) => u.selected = false);
            this._refreshAccountsSelected([]);
            this._refreshUsersSelected([]);
        }
        this._hasAccountOrUsersSelected();
        this._filtersAuctionContinuousGrids();
    }

    toggleSelectionAreas(all: boolean): void {
        if (all) {
            this._refreshGasAreaSelected(this.areasGas?.slice());
            this._refreshPowerAreaSelected(this.areasPower?.slice());
        } else {
            this._refreshGasAreaSelected([]);
            this._refreshPowerAreaSelected([]);
        }
        this._refreshAreasSelected();
        this._refreshCountriesByAreas();
        this._filtersGrids();
    }

    private _defineSelectedCommodities(): void {
        // @ts-ignore
        this._gasSelected = containsSame(this._commoditiesSelected, AppCommodities.Gas);
        // @ts-ignore
        this._powerSelected = containsSame(this._commoditiesSelected, AppCommodities.Power);
    }

    private _refreshCountriesSelected(countries: IDataCountry[] | undefined): void {
        this._countriesSelected = countries;
        let countriesIdPreferences: number[] | undefined = [];
        if (countries?.length !== this._countries?.length) {
            countriesIdPreferences = this._countriesSelected?.map((value) => value.country.id);
        }
        // @ts-ignore
        this._appSvc.shipperPreferences.countries = countriesIdPreferences;
        this._hasCountriesSelection = this.countriesSelected?.length === this.countries?.length ? true : (this.countriesSelected?.length === 0 ? false : undefined);
        if (this._appSvc.shipperPreferences)
            this._appSvc.shipperPreferences.hasCountriesSelection = this._hasCountriesSelection;
    }

    private _refreshPowerAreaSelected(areas: IGrid[] | undefined): void {
        this._areasPowerSelected = areas;
        let powerAreasIdPreferences: number[] | undefined = [];
        if (areas?.length !== this._areasPower?.length) {
            powerAreasIdPreferences = this._areasPowerSelected?.map((grid) => grid.id);
        }
        // @ts-ignore
        this._appSvc.shipperPreferences.powerAreas = powerAreasIdPreferences;
        this._hasAreasPowerSelection = this._areasPowerSelected?.length === this.areasPower?.length ? true : (this._areasPowerSelected?.length === 0 ? false : undefined);
        if (this._appSvc.shipperPreferences)
            this._appSvc.shipperPreferences.hasPowerAreasSelection = this._hasAreasPowerSelection;
    }

    private _refreshGasAreaSelected(areas: IGrid[] | undefined): void {
        this._areasGasSelected = areas;
        let gasAreasIdPreferences: number[] | undefined = [];
        if (areas?.length !== this._areasGas?.length) {
            gasAreasIdPreferences = this.areasGasSelected?.map((grid) => grid.id);
        }
        // @ts-ignore
        this._appSvc.shipperPreferences.gasAreas = gasAreasIdPreferences;
        this._hasAreasGasSelection = this._areasGasSelected?.length === this._areasGas?.length ? true : (this._areasGasSelected?.length === 0 ? false : undefined);
        if (this._appSvc.shipperPreferences)
            this._appSvc.shipperPreferences.hasGasAreasSelection = this._hasAreasGasSelection;
    }

    private _refreshAreasSelected(): void {
        this._hasAreasSelection = this.hasAreasPowerSelection && this.hasAreasGasSelection ? true : (this.hasAreasPowerSelection === false && this.hasAreasGasSelection === false ? false : undefined);
    }

    private _refreshCountriesByAreas(): void {
        const countries = [
            ...this.areasPowerSelected ? this.areasPowerSelected.map((a) => a.country) : [],
            ...this.areasGasSelected ? this.areasGasSelected.map((a) => a.country) : []
        ];
        this._refreshCountriesSelected(this.countries?.filter((a) => containsSame(countries, a.country)));
    }

    private _refreshExchangesSelected(exchanges: ExchangeView[] | undefined): void {
        this._exchangesSelected = exchanges;
        if (this._accountsCache != null) {
            const checkAuctionAccount = (exchange: AuctionExchangeParam | undefined, account: IAuctionAccount): boolean => {
                if (exchange && exchange.id === AuctionExchangeId.ETS && account.exchange === AuctionExchange.ETS) {
                    return true;
                } else if (
                    exchange &&
                    exchange.id === AuctionExchangeId.NP &&
                    (account.exchange === AuctionExchange.NPAU || account.exchange === AuctionExchange.NPDA)
                ) {
                    return true;
                } else if (
                    exchange &&
                    exchange.id === AuctionExchangeId.EXAA &&
                    account.exchange === AuctionExchange.EXAA
                ) {
                    return true;
                } else return false;
            };

            let accounts = this._accountsCache?.slice();
            let users = this._usersCache?.slice();

            accounts = accounts?.filter((account) => this._exchangesSelected?.find((exchange) => checkAuctionAccount(exchange.auction, account)));
            users = users?.filter((user) => this._exchangesSelected?.find((exchange) => exchange.autotrading?.exchangeUsers.find((userE) => userE.id === user.id && userE.name === user.name)));

            this._accounts = accounts.map((a) => new AuctionAccountView(a.id, a.name, a.exchange, true));

            const usersView: ExchangeUserView[] = [];
            users?.forEach((user) => {
                let autoTradingExchange: Exchange;
                this._autoTradingExchanges?.forEach(
                    (at) => {
                        if (at.exchangeUsers.find((u) => u.id == user.id && u.name === user.name)) {
                            autoTradingExchange = at;
                        }
                    }
                );
                // @ts-ignore
                usersView.push(new ExchangeUserView(user.id, user.name, user.organisation, user.accessType, autoTradingExchange.token, true));
            });
            this._users = usersView;
            this._refreshAccountsSelected(accounts);
            this._refreshUsersSelected(usersView);
        }

        // @ts-ignore
        this._hasExchangeSelection = exchanges?.length > 0;
        // @ts-ignore
        this._appSvc.setExchanges(exchanges);
    }

    private _refreshAccountsSelected(accounts: IAuctionAccount[] | undefined): void {
        this._accountsSelected = accounts;
        // @ts-ignore
        this._appSvc.setAccountsAuction(accounts);
        this._hasAccountOrUsersSelected();
        this._filtersAuctionContinuousGrids();
    }

    private _refreshUsersSelected(users: ExchangeUserView[] | undefined): void {
        this._usersSelected = users;
        this._hasAccountOrUsersSelected();
        this._filtersAuctionContinuousGrids();
        // @ts-ignore
        this._appSvc.setContinuousUsers(users);
    }

    private _hasAccountOrUsersSelected(): void {
        // @ts-ignore
        this._hasAccountsSelection = this._usersSelected?.length > 0 || this._accountsSelected?.length > 0;
        this._hasMarketSelection = this._usersSelected?.length != this._users?.length || this._accountsSelected?.length != this._accounts?.length;
        this._cdr.detectChanges();
    }

    private _loadDataByContract(): void {
        const key = "load-data-contract";
        const keyHubEvent = "hub-event";
        this._shippers = undefined;
        this._subs.clearSub(key);
        this._subs.clearSubStartWith(keyHubEvent);
        if (this.contract != null) {
            this._loadAuctionConfiguration();
            const sub = this._appSvc.shippersChanges
            .pipe(
                map((shippers: IOrganisation[] | undefined) => {
                    if (shippers && shippers.length > 1)
                        shippers.push(new Organisation(0, "", "All Shippers"));
                    return shippers;
                })
            )
            .subscribe((shippers) => {
                this._shippers = shippers;
                this._setShipperSelected();
                this._cdr.detectChanges();
            }, (error) => {
                this._logger.fatal("header get data by contract", error);
                this._cdr.detectChanges();
            });
            this._subs.push(key, sub);
            const hasReadEventAuction = this._appSvc.hasRight(UserRight.EventAuctionRead);
            const hasReadEventContinuous = this._appSvc.hasRight(UserRight.EventAutotradingRead);
            const hasReadEventComInt = this._appSvc.hasRight(UserRight.EventComintRead);
            this._hasRightEvents = hasReadEventAuction || hasReadEventContinuous || hasReadEventComInt;
            if (this.hasRightEvents) {
                const subIStable = this._appRef.isStable.pipe(first((stable) => stable)).subscribe(() => {
                    // @ts-ignore
                    const hub = this._api.events.hub(this.contract?.id);
                    const subHubEventState = hub.stateChanges.subscribe((state) => {
                        switch (state) {
                            case HubConnectionState.Connected:
                                this._eventSvcEnable = true;
                                break;
                            default:
                                this._countEvents = undefined;
                                this._eventSvcEnable = false;
                                break;
                        }
                        this._cdr.detectChanges();
                    });
                    this._subs.push(`${keyHubEvent}-state`, subHubEventState);
                    const subHubEvent = hub.countUnread().subscribe((result) => {
                        this._countEvents = undefined;
                        if (result.dataReceived && result.data) {
                            let countEvent = 0;
                            if (hasReadEventContinuous) {
                                countEvent += result.data.autoTradingCount;
                            }
                            if (hasReadEventComInt) {
                                countEvent += result.data.comIntCount;
                            }
                            if (hasReadEventAuction) {
                                countEvent += result.data.auctionCount;
                            }
                            if (countEvent != this._countEvents) {
                                this._countEvents = countEvent;
                                this._countEventsChange = true;
                            }
                        }
                        this._cdr.detectChanges();
                    });
                    this._subs.push(keyHubEvent, subHubEvent);
                });
                this._subs.push(`${keyHubEvent}-sistable`, subIStable);
            }
        }
    }

    private _loadAuctionConfiguration(): void {
        this._exchanges = [];
        this._accountsCache = [];
        this._accounts = [];
        this._users = [];
        this._usersCache = [];
        this._autoTradingExchanges = [];

        if (this._canSeeAuction || this._canSeeAutoTrading) {
            const key = 'load-data-auction-conf';
            this._subs.clearSubStartWith(key);
            this._subs.push(key,

                // @ts-ignore
                this._appSvc.marketConfiguration.subscribe(
                    (marketsConfig: IMarketConfiguration) => {
                        this._users = [];
                        this._autoTradingExchanges = [];
                        if (marketsConfig?.autoTradingConfigurations?.length > 0) {
                            this._gridsByExchangeUser = new Dictionary<IGrid[]>();
                            marketsConfig.autoTradingConfigurations.forEach(
                                (market: IAvailableMarket) => {
                                    let users: ExchangeUserView[] = [];

                                    let exchange: Exchange = new Exchange(market.exchange, this._staticService.getUriIcon(IconType.Exchange, IconSize.Size_32, market.exchange), users);
                                    market.users.forEach((config) => {
                                        let user = new ExchangeUserView(config.user.id, config.user.name, config.user.organisation, config.user.accessType, exchange.token, true);
                                        users.push(user);
                                        const key = `${user.exchange}_${user.id}`;
                                        if (this._gridsByExchangeUser?.containsKey(key)) {
                                            this._gridsByExchangeUser?.get(key).push(...config.areas);
                                        } else {
                                            this._gridsByExchangeUser?.add(key, config.areas);
                                        }
                                    });
                                    this._users?.push(...users);
                                    this._autoTradingExchanges?.push(exchange);
                                }
                            );
                        }
                        this._usersCache = this._users.slice();

                        if (marketsConfig?.auctionConfigurations?.length > 0) {
                            const shippers: IOrganisation[] = [];
                            this._exchangeByShipper = new Dictionary<AuctionExchangeParam[]>();
                            this._accountsByShipper = new Dictionary<IAuctionAccount[]>();
                            this._gridsByAccounts = new Dictionary<IGrid[]>();
                            marketsConfig.auctionConfigurations.forEach((availConfig: IAuctionsAvailableConfiguration) => {
                                if (!shippers.find((s) => _ez.isSame(s, availConfig.shipper))) {
                                    shippers.push(availConfig.shipper);
                                }
                                const keyShipper: number = availConfig.shipper.id;
                                let exchange: AuctionExchangeParam = getAuctionExchangeParam(availConfig.account.exchange);

                                if (exchange != null) {
                                    if (this._exchangeByShipper?.containsKey(keyShipper)) {
                                        if (!this._exchangeByShipper?.get(keyShipper).some((e) => e.token === exchange.token)) {
                                            this._exchangeByShipper?.get(keyShipper).push(exchange);
                                        }
                                    } else {
                                        this._exchangeByShipper?.add(keyShipper, [exchange]);
                                    }
                                }

                                if (this._accountsByShipper?.containsKey(keyShipper)) {
                                    this._accountsByShipper?.get(keyShipper).push(availConfig.account);
                                } else {
                                    this._accountsByShipper?.add(keyShipper, [availConfig.account]);
                                }

                                let grids = availConfig.grids.flatMap((d) => d.grid);
                                const key = `${availConfig.account.exchange}_${availConfig.account.id}`;
                                if (this._gridsByAccounts?.containsKey(key)) {
                                    this._gridsByAccounts?.get(key).push(...grids);
                                } else {
                                    this._gridsByAccounts?.add(key, grids);
                                }
                            });
                        }
                        this._setAuctionDataByShipper();
                    }
                )
            );
        }
    }

    private _setAuctionDataByShipper(): void {
        this._exchangesView = [];
        if (this._shipper != null && !this.isMultiShipper) {
            if (this._exchangeByShipper != null) {
                this._exchanges = this._exchangeByShipper.get(this._shipper.id);
                if (this._exchanges != null && this._exchanges.length > 0) {
                    this._exchanges.forEach((e) => {
                        this._exchangesView?.push(new ExchangeView(e.name, e));
                    });
                }
            }

            if (this._autoTradingExchanges != null && this._autoTradingExchanges.length > 0) {
                this._autoTradingExchanges.forEach((e) => {
                    let eView = this._exchangesView?.find((eV) => eV.auction?.token == e.token.toString());
                    if (eView == null) {
                        this._exchangesView?.push(new ExchangeView(e.name.toLocaleLowerCase(), undefined, e));
                    } else
                        eView.autotrading = e;
                });
            }
            this._refreshExchangesSelected(this._exchangesView);
            if (this._accountsByShipper != null && this._accountsByShipper.get(this._shipper.id) != null) {
                this._accounts = this._accountsByShipper.get(this._shipper.id).map((a) => new AuctionAccountView(a.id, a.name, a.exchange, true));
                this._accountsCache = this._accounts?.slice();
                this._appSvc.setAccountsAuction(this._accounts);
                this._refreshAccountsSelected(this._accounts);
            }
        }
    }

    private _loadDataByShipper(): void {
        const key = 'load-data-shipper';
        this._shipperOnload = true;
        this._subs.clearSub(key);
        this._countries = undefined;
        this._gridByCountry = undefined;
        this._countriesSelected = undefined;
        this._filtersGrids();
        // @ts-ignore
        this._appSvc.setShipper(this._shipper);
        if (this._shipper != null) {
            this._routerSvc.setQueryParams(AppQueryParamsKey.Shipper, this._shipper.id);
            const sub = combineLatest([this._dataSvc.allGridsChanges, this._dataSvc.gridRelationshipChanges])
            .subscribe(([grids, relationships]) => {
                relationships ??= [];
                if (grids == null || grids.length == 0) {
                    this._shipperOnload = false;
                    return;
                }

                const commodities: AppCommodities[] = [];
                this._grids = orderBy(grids, (c) => (c.material.code === MaterialCode.Power ? "a" : "w" + "_") + c.name);

                this._countries = [];
                this._areasPower = [];
                this._areasGas = [];
                this._gridByCountry = new Map();
                this._grids.forEach((g) => {
                    let countryData = this._countries?.find((c) => isSame(c.country, g.country));
                    if (countryData == null) {
                        countryData = { country: g.country, hasPower: false, hasGas: false };
                        this._countries?.push(countryData);
                        this._gridByCountry?.set(g.country.id, [g]);
                    } else {
                        this._gridByCountry?.get(countryData.country.id)?.push(g);
                    }

                    switch (g.material.code) {
                        case MaterialCode.Power:
                            countryData.hasPower = true;
                            if (g.gridType.code === GridTypeCode.codeBalancing) {
                                this._areasPower?.push(g);
                            }
                            if (!containsSame(commodities, AppCommodities.Power)) {
                                commodities.push(AppCommodities.Power);
                            }
                            break;
                        case MaterialCode.Gaz:
                            countryData.hasGas = true;
                            if (g.gridType.code === GridTypeCode.codeBalancing) {
                                this._areasGas?.push(g);
                            }
                            if (!containsSame(commodities, AppCommodities.Gas)) {
                                commodities.push(AppCommodities.Gas);
                            }
                            break;
                    }
                });

                this._areasByGrid = new Map();
                relationships.forEach((relationship) => {
                    let entry = this._areasByGrid?.get(relationship.nominationGrid.id);
                    if (!entry) {
                        entry = new Map();
                        this._areasByGrid?.set(relationship.nominationGrid.id, entry);
                    }

                    entry.set(relationship.balancingGrid.id, true);
                });

                this._commodities = commodities;
                this._commoditiesSelected = this._appSvc.shipperPreferences?.commodities != null && this._appSvc.shipperPreferences.commodities.length > 0 ? this._appSvc.shipperPreferences.commodities : this._commodities.slice();
                this._defineSelectedCommodities();
                this._countries = orderBy(this._countries, (c) => c.country.name);

                let areasPowerSelected = this._areasPower.slice();
                if (this._appSvc.shipperPreferences?.hasPowerAreasSelection === false)
                    areasPowerSelected = [];
                if (this._appSvc.shipperPreferences?.powerAreas != null
                    && this._appSvc.shipperPreferences.powerAreas.length > 0
                    && this._appSvc.shipperPreferences.powerAreas.length !== this._areasPower.length) {
                    areasPowerSelected = areasPowerSelected.filter((grid) => contains(this._appSvc.shipperPreferences!.powerAreas, grid.id));
                }
                this._refreshPowerAreaSelected(areasPowerSelected);

                let areasGasSelected = this._areasGas.slice();
                if (this._appSvc.shipperPreferences?.hasGasAreasSelection === false)
                    areasGasSelected = [];
                if (this._appSvc.shipperPreferences?.gasAreas != null
                    && this._appSvc.shipperPreferences.gasAreas.length > 0
                    && this._appSvc.shipperPreferences.gasAreas.length !== this._areasGas.length) {
                    areasGasSelected = areasGasSelected.filter((grid) => contains(this._appSvc.shipperPreferences!.gasAreas, grid.id));
                }
                this._refreshGasAreaSelected(areasGasSelected);
                this._refreshAreasSelected();

                let countriesSelected = this._countries.slice();
                if (this._appSvc.shipperPreferences?.hasCountriesSelection === false)
                    countriesSelected = [];
                if (this._appSvc.shipperPreferences?.countries != null
                    && this._appSvc.shipperPreferences.countries.length > 0
                    &&  this._appSvc.shipperPreferences.countries.length !== this._countries.length) {
                    countriesSelected = countriesSelected.filter((c) => contains(this._appSvc.shipperPreferences!.countries, c.country.id));
                }
                this.countriesChange(countriesSelected, { updateAreas: false});

                this._shipperOnload = false;
                this._cdr.detectChanges();
            });
            this._subs.push(key, sub);
        }
    }

    private _loadExchangesByShipper(): void {
        if (this._shipper != null && this._exchangeByShipper) {
            this._exchanges = !this.isMultiShipper ? this._exchangeByShipper.get(this._shipper.id) : [];
        }
    }

    private _loadAccountsByShipper(): void {
        if (this._shipper != null && this._accountsByShipper != null) {
            let accounts = this._accountsByShipper.get(this._shipper.id);
            if (accounts != null) {
                this._accounts = this._accountsByShipper.get(this._shipper.id).map((a) => new AuctionAccountView(a.id, a.name, a.exchange));
                this._accountsCache = this._accounts?.slice();
            }
            this._refreshAccountsSelected(this._accounts);
        }
    }

    private _setShipperSelected(): void {
        if (this._shippers != null) {
            let shipper = this._shippers.length === 1 || this._shipperId == null ? this._shippers[0] : this._shippers.find((s) => s.id === this._shipperId);
            if (shipper == null) {
                shipper = this._shippers[0];
            }
            this.shipperChange(shipper);
        } else {
            this.shipperChange(undefined);
        }
    }

    private _setDates(date: DateTime | undefined): void {
        this._date = date;
        if (this._quickDate !== AppQuickDates.Custom) {
            this.quickDateChange(AppQuickDates.Custom);
        }
        // @ts-ignore
        this._appSvc.setDate(this._date);
    }

    private _convertQuickDate(date: AppQuickDates): DateTime | undefined {
        switch (date) {
            case AppQuickDates.DayBefore:
                return DateTime.yesterday().date;
            case AppQuickDates.DayAfter:
                return DateTime.tomorrow().date;
            case AppQuickDates.Day:
                return DateTime.now().date;
            default:
                return undefined;
        }
    }

    private _filtersGrids(): void {
        let grids;
        if (this._countriesSelected == null) {
            grids = undefined;
        } else {
            grids = [];
            const hasPower = this.commoditiesSelected?.includes(AppCommodities.Power);
            const hasGas = this.commoditiesSelected?.includes(AppCommodities.Gas);
            this._countriesSelected.forEach((c) => {
                const countryGrids = this._gridByCountry?.get(c.country.id);
                countryGrids?.forEach((grid) => {
                    if (grid.material != null) {
                        switch (grid.material.code) {
                            case MaterialCode.Power:
                            case MaterialCode.PowerReserve: // power reserve should be included when including power
                                if (hasPower && this._includeGrid( grid, this.areasPowerSelected)) {
                                    grids.push(grid);
                                }
                                break;
                            case MaterialCode.Gaz:
                                if (hasGas && this._includeGrid( grid, this.areasGasSelected)) {
                                    grids.push(grid);
                                }
                                break;
                        }
                    } else {
                        this._logger.error(`Grid ${grid.name} (${grid.id}) don't have material`);
                    }
                });
            });
        }
        // @ts-ignore
        this._appSvc.setGrids(grids);
        this._filtersAuctionContinuousGrids();
    }

    private _filtersAuctionContinuousGrids(): void {
        let gridsAuction: IGrid[] = [];
        let gridsContinuous: IGrid[] = [];
        if (this._areasPower != null && this._areasGas != null && this._countries != null && (this._usersSelected != null || this._accountsSelected)) {
            this._usersSelected?.forEach((user) => {
                gridsContinuous = _.union(gridsContinuous, this._gridsByExchangeUser?.get(`${user.exchange}_${user.id}`));
            });
            this._accountsSelected?.forEach((user) => {
                gridsAuction = _.uniqBy(_.union(gridsAuction, this._gridsByAccounts?.get(`${user.exchange}_${user.id}`)), 'id');
            });
        }
        const grids =  this._rightScreen === "SCREEN_AUTOTRADING" ? gridsContinuous : gridsAuction;

        const withCountryFilters = grids.filter((grid) => this._countriesSelected?.some((country) => country.country.id === grid.country.id));

        this._appSvc.setAuctionContinuousGrids(withCountryFilters);
    }

    private _includeGrid(grid: IGrid, selectedAreas: IGrid[] | undefined): boolean | undefined {
        if (!this._areasByGrid) {
            return true;
        }

        if (grid.gridType.code === GridTypeCode.codeBalancing) {
            return selectedAreas?.some((selectedArea) => selectedArea.id === grid.id);
        }

        const gridRelationshipEntry = this._areasByGrid.get(grid.id);
        if (!gridRelationshipEntry) {
            return true;
        }

        return selectedAreas?.some((selectedArea) => gridRelationshipEntry.get(selectedArea.id ) );
    }

    translateSeparator(separator: string): string {
        switch (separator) {
            case " ":
                return "space";
            case ",":
                return "comma";
            case ":":
                return "colon";
            case ".":
                return "dot";
            case "'":
                return "quote";
            default:
                return "space";
        }
    }

    onChangeDate(event: PointerEvent, directionChangeDate: DirectionChangeDate): void {
        event.stopPropagation();
        const days = directionChangeDate == DirectionChangeDate.next ? 1 : -1;
        if (this._date)
            this.dateChanges(this._date.addDays(days));
    }

    onChangePwd(): void {
        const { token } = this._userConnectedInfos;
        const hostname = this._routerSvc.hostname!.slice(0, -1);
        const returnUrl = `${hostname}#/${this._routerSvc.router.url}`;
        window.location.href = `${this._config.authConfig.authority}/Account/ResetPassword/Reset?token=${token}&returnUrl=${returnUrl}`;
    }

    toggleContactSupport(): void {
        const config: MatDialogConfig = { 
            data: 
                { component: ContactSupportModalComponent } 
        };
        this.dialog.open(AlertModalComponent<ContactSupportModalComponent>, config);
    }
}
