import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";
import {
    CurveBase,
    SessionType
} from '@eztypes/webapi';
import {
    SessionTabType
} from '../../../modules/autotrading/components/overview/content/content-sessions-overview.component';

class PageContinuousSessionsPreferenceInfo {
    static load(preferences: string | PageContinuousSessionsPreferenceInfo): PageContinuousSessionsPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageContinuousSessionsPreferenceInfo>(preferences, PageContinuousSessionsPreferenceInfo) as PageContinuousSessionsPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    sessionTab: SessionTabType | undefined;
    sessionTypes: SessionType[] | undefined;
    selectedTSPerSession: Array<{sessionId: number, timeserie: CurveBase | undefined}> = [];
}

@UserPreferenceClassDecorator
export class PageContinuousSessionsPreference implements IPreferencePage {
    static load(preferences: string | PageContinuousSessionsPreferenceInfo): PageContinuousSessionsPreference {
        if (!preferences) {
            return new PageContinuousSessionsPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageContinuousSessionsPreferenceInfo.load(preferences);
                return new PageContinuousSessionsPreference(
                    v1.sessionTab,
                    v1.sessionTypes,
                    v1.selectedTSPerSession
                );
            default:
                return new PageContinuousSessionsPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    sessionTab: SessionTabType | undefined;
    sessionTypes: SessionType[] | undefined;
    @UPPropertySave
    selectedTSPerSession: Array<{sessionId: number, timeserie: CurveBase | undefined}>;

    constructor(
        sessionTab?: SessionTabType | undefined,
        sessionTypes?: SessionType[] | undefined,
        selectedTSPerSession: Array<{sessionId: number, timeserie: CurveBase | undefined}> = []
    ) {
        this.sessionTab = sessionTab;
        this.sessionTypes = sessionTypes;
        this.selectedTSPerSession = selectedTSPerSession;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.sessionTab = undefined;
            this.sessionTypes = undefined;
            this.selectedTSPerSession = [];
        }
    }
}

