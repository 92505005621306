// @ts-nocheck
import { HttpClient } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppCoreModule } from "@core/core.module";
import { TranslateModule } from "@ngx-translate/core";
import { DashboardPage } from "./dashboard.component";

@NgModule({
    imports: [
        AppCoreModule,
        TranslateModule
    ],
    declarations: [
        DashboardPage
    ],
    exports: [
        DashboardPage
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardPagesModule {

}
