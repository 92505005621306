// @ts-nocheck
import {
    Attribute,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Optional,
    Output,
    Renderer2,
    Self,
    ViewEncapsulation
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { EzFormControl, IEzFormControl } from "@eznergy/components";
import { isSame } from "@eznergy/core";
import { IPhysicalValue, IUnit, PhysicalValue, Unit } from "@eztypes/webapi";

class GradientOmtValueView {
    from: number;
    to: number;
    onError: boolean;
    offset1: number;
    offset2: number;
    unitOffset1: IUnit;
    unitOffset2: IUnit;
}

export interface IOmtGradientItem {
    from: number;
    to: number;
    offset1: IPhysicalValue;
    offset2: IPhysicalValue;
}

export class OmtGradientItem implements IOmtGradientItem {
    from: number;
    to: number;
    offset1: IPhysicalValue;
    offset2: IPhysicalValue;
    constructor(
        from?: number,
        to?: number,
        offset1?: IPhysicalValue,
        offset2?: IPhysicalValue
    ) {
        this.from = from;
        this.to = to;
        this.offset1 = offset1;
        this.offset2 = offset2;
    }
}

@Component({
    selector: "ez-gradient-omt",
    templateUrl: "./gradient-omt.component.html",
    styleUrls: ["./gradient-omt.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: EzFormControl, useExisting: GradientOmtComponent }],
})
export class GradientOmtComponent
    extends EzFormControl
    implements IEzFormControl
{
    @Output() valueChange: EventEmitter<IOmtGradientItem[]> =
        new EventEmitter();

    @HostListener("focusin", ["$event"])
    focusin(event: FocusEvent): void {
        super.focusin(event);
        if (event.target === this.element) {
            this.focus();
        }
    }

    @HostListener("focusout", ["$event"])
    focusout(event: FocusEvent): void {
        super.focusout(event);
    }

    @Input() public rowHeader: string | undefined;

    @Input()
    set defaultOffset1(value: IPhysicalValue) {
        if (value !== this._defaultOffset1) {
            this._defaultOffset1 = value;
            if (
                this._internalGradientItems &&
                this._internalGradientItems.length
            ) {
                this._changedvalue = false;
                this._setDefaultValues();
                this._checkValidityItems();
                if (this._changedvalue) {
                    this._emitGradientItemsChange();
                }
            }
            this._cdr.detectChanges();
        }
    }
    get defaultOffset1(): IPhysicalValue {
        return this._defaultOffset1;
    }
    private _defaultOffset1: IPhysicalValue;

    @Input()
    set defaultOffset2(value: IPhysicalValue) {
        if (value !== this._defaultOffset2) {
            this._defaultOffset2 = value;
            if (
                this._internalGradientItems &&
                this._internalGradientItems.length
            ) {
                this._changedvalue = false;
                this._setDefaultValues();
                this._checkValidityItems();
                if (this._changedvalue) {
                    this._emitGradientItemsChange();
                }
            }
            this._cdr.detectChanges();
        }
    }
    get defaultOffset2(): IPhysicalValue {
        return this._defaultOffset2;
    }
    private _defaultOffset2: IPhysicalValue;

    @Input()
    set value(values: IOmtGradientItem[]) {
        if (this.value && isSame(this.value, values)) {
            return;
        }

        this._init(values);
        this.cdr.markForCheck();
    }
    get value(): IOmtGradientItem[] {
        return this._oldValues;
    }
    private _oldValues: IOmtGradientItem[];

    get gradientItems(): GradientOmtValueView[] {
        return this._internalGradientItems;
    }
    set gradientItems(items: GradientOmtValueView[]) {
        this._internalGradientItems = items;
        this._cdr.detectChanges();
    }
    private _internalGradientItems: GradientOmtValueView[];

    private _formOnError: boolean;
    private _changedvalue: boolean;
    private _defaultMaxValue: number = 100;

    addEnabled: boolean;
    offset1Unit: IUnit = new Unit(undefined, undefined, undefined, "");
    offset2Unit: IUnit = new Unit(undefined, undefined, undefined, "s");

    infiniteMaxValue: number = Number.MAX_SAFE_INTEGER;

    constructor(
        renderer2: Renderer2,
        elementRef: ElementRef,
        private cdr: ChangeDetectorRef,
        @Attribute("tabindex") tabIndex: string,
        @Attribute("id") id: string,
        @Self() @Optional() ngControl: NgControl
    ) {
        super(renderer2, elementRef, cdr, ngControl, tabIndex, id);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    focus(): void {
        if (this.element) {
            let input =
                this.element.querySelector<HTMLElement>("ez-input-number");
            if (input) {
                input.focus();
            }
        }
    }

    onToChange(value: number, index: number) {
        if (this.disabled) return;
        this._toChange(value, index);
        this._checkValidityItems();
        this.addEnabled = this._enabledAddButton();
        this._emitGradientItemsChange();
    }

    onDeleteClick(index: number): void {
        if (this.disabled) return;
        this._internalGradientItems.splice(index, 1);
        if (!index || index == this._internalGradientItems.length - 1)
            this._updateTickHeads();
        this._updateGradients(index ? index - 1 : index);
        this._checkValidityItems();
        this.addEnabled = this._enabledAddButton();
        this._emitGradientItemsChange();
        this.cdr.detectChanges();
        this._focusByIndex(index);
    }

    onAddClick() {
        if (this.disabled) return;
        let item = this._addRow();
        this._setDefaultValue(item);
        this._checkValidityItems();
        this.addEnabled = this._enabledAddButton();
        this._emitGradientItemsChange();
        this.cdr.detectChanges();
        this._focusByIndex(this._internalGradientItems.length - 1);
    }

    onOffset1Change(value: number, index: number) {
        if (this.disabled) return;
        let item = this.gradientItems[index];
        if (item) {
            item.offset1 = value;
            this._checkValidityItems();
            this._emitGradientItemsChange();
        }
    }

    onOffset2Change(value: number, index: number) {
        if (this.disabled) return;
        let item = this.gradientItems[index];
        if (item) {
            item.offset2 = value;
            this._checkValidityItems();
            this._emitGradientItemsChange();
        }
    }

    private _addRow(): GradientOmtValueView {
        let item = new GradientOmtValueView();
        let len: number = this._internalGradientItems.length;
        let previousItem = this._internalGradientItems[len - 1];
        let mid: number =
            previousItem.to === this.infiniteMaxValue
                ? previousItem.from + 50
                : Math.floor(
                      (previousItem.to - previousItem.from) / 2 +
                          previousItem.from
                  );
        item.to = previousItem.to;
        previousItem.to = item.from = mid;
        this._internalGradientItems.splice(
            this._internalGradientItems.length,
            0,
            item
        );
        return item;
    }

    private _focusByIndex(index: number): void {
        if (this.element) {
            let rows = (
                this.element as HTMLElement
            ).querySelectorAll<HTMLElement>("ez-row");
            if (rows && rows.length > index) {
                let input =
                    rows[index].querySelector<HTMLElement>("ez-input-number");
                if (input) {
                    input.focus();
                }
            }
        }
    }

    private _updateTickHeads() {
        let len = this._internalGradientItems?.length;
        if (
            this._internalGradientItems != null &&
            this._internalGradientItems[0] &&
            this._internalGradientItems[0].from != 0
        ) {
            this._internalGradientItems[0].from = 0;
        }
        if (
            this._internalGradientItems != null &&
            this._internalGradientItems[len - 1] &&
            this._internalGradientItems[len - 1].to == undefined
        ) {
            this._internalGradientItems[len - 1].to = this._defaultMaxValue;
        }
    }

    private _updateGradients(index: number) {
        let nextItem = this._internalGradientItems[index + 1];
        if (
            nextItem &&
            this._internalGradientItems[index].to &&
            !isNaN(this._internalGradientItems[index].to)
        ) {
            nextItem.from = this._internalGradientItems[index].to;
        }
    }

    private _enabledAddButton() {
        let result: boolean = false;
        let len = this._internalGradientItems.length;
        if (len) {
            result =
                this._internalGradientItems[len - 1].to -
                    this._internalGradientItems[len - 1].from >
                1;
        }
        return result;
    }

    private _checkValidityItems(): void {
        let onError = false;
        let i = this._internalGradientItems.length;
        while (i > 0) {
            i--;
            let item = this._internalGradientItems[i];
            item.onError = false;
            if (
                isNaN(item.to) ||
                item.to == null ||
                isNaN(item.from) ||
                item.from == null ||
                (!isNaN(item.to) && !isNaN(item.from) && item.from < 0) ||
                item.to < 0 ||
                item.from >= item.to
            ) {
                onError = item.onError = true;
                continue;
            }
            if (i && item.from != this._internalGradientItems[i - 1].to) {
                onError = item.onError = true;
            }
            if (item.offset1 == undefined || item.offset2 == undefined) {
                onError = item.onError = true;
            }
        }
        if (this._formOnError != onError) {
            this._formOnError = onError;
        }
    }

    private _setDefaultValue(item: GradientOmtValueView) {
        if (
            this._defaultOffset1 &&
            this._defaultOffset2 
        ) {
            item.offset1 = item.offset1 != null ?  item.offset1: this._defaultOffset1.value;
            item.unitOffset1 = item.unitOffset1 || this._defaultOffset1.unit;
            item.offset2 = item.offset2 != null ?  item.offset2 : this._defaultOffset2.value;
            item.unitOffset2 = item.unitOffset2 || this._defaultOffset2.unit;
            this._changedvalue = true;
        }
    }

    private _toChange(value: number, index: number): void {
        if (this._internalGradientItems[index])
            this._internalGradientItems[index].to = value;
        this._updateGradients(index);
        this.addEnabled = this._enabledAddButton();
    }

    private _emitGradientItemsChange() {
        this._oldValues = !this._formOnError
            ? this._toIGradientItemValue()
            : undefined;

        this._onChange(this._oldValues);
        this.valueChange.emit(this._oldValues);
    }

    private _toIGradientItemValue(): IOmtGradientItem[] {
        let items = this.gradientItems;
        let internalItems: IOmtGradientItem[];
        internalItems = [];
        items.forEach((item) => {
            let it = new OmtGradientItem();
            it.from = item.from;
            if (item.to != undefined) it.to = item.to;
            it.offset1 = new PhysicalValue(this.offset1Unit, item.offset1);
            it.offset2 = new PhysicalValue(this.offset2Unit, item.offset2);
            internalItems.push(it);
        });
        return internalItems;
    }

    private _toGradientItemView(item: IOmtGradientItem = undefined) {
        let itemView = new GradientOmtValueView();
        itemView.from = item ? item.from : 0;
        itemView.to = item && item.to ? item.to : this._defaultMaxValue;
        itemView.offset1 =
            item && item.offset1 ? item.offset1.value : undefined;
        itemView.offset2 =
            item && item.offset2 ? item.offset2.value : undefined;
        itemView.unitOffset1 =
            item && item.offset1.unit ? item.offset1.unit : undefined;
        itemView.unitOffset2 =
            item && item.offset2.unit ? item.offset2.unit : undefined;
        itemView.onError = false;
        return itemView;
    }

    private _sortTimeSets(values: GradientOmtValueView[]) {
        values.sort((a, b) => {
            return (
                a.from != undefined &&
                a.from != null &&
                b.from != undefined &&
                b.from != null &&
                b.from &&
                a.from - b.from
            );
        });
    }

    private _setDefaultValues() {
        this._changedvalue = false;
        if (this.gradientItems) {
            this.gradientItems.forEach((value) => {
                this._setDefaultValue(value);
            });
        }
    }

    private _init(values: IOmtGradientItem[]) {
        // reset internal items;
        // otherwise the previous values override the new ones
        // due to change detection triggers
        this._internalGradientItems = [];
        this._cdr.detectChanges();

        let newItemView: GradientOmtValueView;
        if (values && values.length) {
            let itemsView: GradientOmtValueView[] = [];
            values.forEach((item: IOmtGradientItem) => {
                let itemView = this._toGradientItemView(item);
                itemsView.push(itemView);
            });
            this._sortTimeSets(itemsView);
            this._updateTickHeads();
            this._internalGradientItems = itemsView;
        } else {
            newItemView = this._toGradientItemView();
            this._internalGradientItems = [newItemView];
        }
        this._setDefaultValues();
        this._checkValidityItems();
        if (newItemView || this._changedvalue) {
            this._emitGradientItemsChange();
        }
        this.addEnabled = this._enabledAddButton();
    }

    writeValue(value: any) {
        this.value = value;
        super.writeValue(this.value);
    }
}
