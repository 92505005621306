// @ts-nocheck
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Subscriptions } from '@core/subscriptions';
import { EzListComponent, EzOverlayOrigin, EzPopoverComponent } from '@eznergy/components';
import { containsSame, orderBy } from '@eznergy/core';
import { ITimezoneInfo, TimeZone, TimezoneInfosList } from '@eztypes/generic';
import { IGrid } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
interface ITzDisplay {
    tz: ITimezoneInfo;
    local: boolean;
    grids: IGrid[];
}

@Component({
    selector: 'tz-selector',
    templateUrl: './timezone.component.html',
    styleUrls: ['./timezone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TimezoneSelectorComponent {
    @Output() readonly valueChange: EventEmitter<ITimezoneInfo> = new EventEmitter<ITimezoneInfo>();

    @HostBinding('attr.tabindex')
    readonly tabindex: number = -1;

    @Input()
    get value(): ITimezoneInfo {
        return this._value;
    }
    set value(value: ITimezoneInfo) {
        if (this.value !== value) {
            this._value = value;
            this._initDataSelected();
            this._cdr.markForCheck();
        }
    }
    private _value: ITimezoneInfo;

    @Input()
    get filterGrids(): IGrid[] {
        return this._filterGrids;
    }
    set filterGrids(value: IGrid[]) {
        this._filterGrids = value;
        this._prepareData();
        this._cdr.markForCheck();
    }
    private _filterGrids: IGrid[];

    @HostBinding('attr.disabled')
    get _attrDisabled(): boolean {
        return this.disabled || undefined;
    }
    @Input() disabled: boolean = false;

    @ViewChild('popover') readonly listTz: EzListComponent<ITimezoneInfo>;
    @ViewChild('popdata') readonly popover: EzPopoverComponent;
    @HostListener('focusout', ['$event'])
    focusout(event: FocusEvent): void {
        if (this.listTz.element.contains(event.relatedTarget as Node)) {
            event.stopPropagation();
            this.focus();
        }
    }

    @HostListener('mouseup', ['$event'])
    mouseup(event: MouseEvent): void {
        if (this.listTz.element.contains(event.target as Node)) {
            event.stopPropagation();
        }
    }

    readonly timezones: ITimezoneInfo[];
    readonly tzLocalInfo: ITimezoneInfo;
    readonly origin: EzOverlayOrigin;

    displayPopover: boolean = false;

    get data(): ITzDisplay[] {
        return this._data;
    }
    private _data: ITzDisplay[];
    get dataSelected(): ITzDisplay {
        return this._dataSelected;
    }
    private _dataSelected: ITzDisplay;
    private _grids: IGrid[];

    private readonly _subs: Subscriptions = new Subscriptions();

    constructor(
        private readonly _cdr: ChangeDetectorRef,
        private readonly _appSvc: ApplicationService,
        private readonly _el: ElementRef<HTMLElement>
    ) {
        this.origin = new EzOverlayOrigin(this._el);
        this.tzLocalInfo = TimeZone.local().info;
        this.timezones = TimezoneInfosList;
        if (!this.timezones.some((a) => a.iana === this.tzLocalInfo.iana)) {
            this.timezones.push(this.tzLocalInfo);
            this.timezones = orderBy(this.timezones, (a) => a.name);
        }
    }

    ngOnInit(): void {
        const sub = this._appSvc.gridsBalancing.subscribe((grids) => {
            this._grids = grids;
            this._prepareData();
            this._initDataSelected();
            this._cdr.detectChanges();
        });
        this._subs.push('grids-change', sub);
        const subTz = this._appSvc.timezoneChange.subscribe((tz) => {
            this._prepareData();
            const newTz = this._data?.find((d) => d?.tz === tz?.info);
            if (newTz) {
                this.changeTimeZone(newTz);
            }
            this._cdr.detectChanges();
        });
        this._subs.push('tz-change', subTz);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    changeTimeZone(data: ITzDisplay): void {
        if (!this.disabled) {
            this._dataSelected = data;
            this._value = data.tz;
            this.valueChange.emit(this.value);
            this.popover?.close();
        }
    }

    focus(): void {
        this._el?.nativeElement.focus();
    }

    private _initDataSelected(): void {
        this._dataSelected =
            this.value != null
                ? this._data?.find((a) => a.tz.iana === this.value.iana)
                : this._data?.find((a) => a.local);
        if (this._dataSelected == null && this._data?.length > 0) {
            this._dataSelected = this._data.find((a) => a.local);
            if (this._dataSelected == null) {
                this._dataSelected = this._data[0];
            }
        }
    }

    private _prepareData(): void {
        if (this._grids != null) {
            this._data = this.timezones.map((tz) => {
                return {
                    tz: tz,
                    local: this.tzLocalInfo.iana === tz.iana,
                    grids: this._grids.filter((grid) => grid.calendar?.ianaName === tz.iana),
                };
            });
            if (this._filterGrids != null) {
                this._data = this._data.filter((tz) => this._appSvc.timezone?.iana === tz.tz.iana || tz.grids.some((grid) => containsSame(this._filterGrids, grid)));
            }
            this._initDataSelected();

            if (this._dataSelected.tz.iana != this.value?.iana) {
                this.changeTimeZone(this._dataSelected);
            }
        }
    }
}
