import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppCoreModule } from "@core/core.module";
import { AppFormsModule } from "@forms/forms.module";
import { TranslateModule } from "@ngx-translate/core";
import { LoginComponent } from "./login/login.component";
import { PreferencesPage } from "./preferences/preferences.page";
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
    imports: [
        AppCoreModule,
        AppFormsModule,
        TranslateModule,
        SharedModule
    ],
    declarations: [
        LoginComponent,
        PreferencesPage
    ],
    exports: [
        LoginComponent,
        PreferencesPage
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsersPagesModule {

}
