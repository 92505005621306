// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
// Model
import { Subscriptions } from "@core";
import { Logger } from '@core/logger';
import { ApiService } from "@eznergy/webapi";
import { DateTime } from '@eztypes/generic';
import { IEtsArea, IEtsClient, IEtsMarketResult, IEtsPortfolio, IGrid, UserRight } from '@eztypes/webapi';
import { ApplicationService } from "@services/application.service";
import { IEtsParameters } from './params/parameters-ets.component';

// Component
export { EtsMarketResultPortfolioComponent } from "./ets-market-result/ets-market-result-portfolio/ets-market-result-portfolio.component";
export { EtsMarketResultSummaryComponent } from "./ets-market-result/ets-market-result-summary/ets-market-result-summary.component";
export { EtsMarketResultComponent } from "./ets-market-result/ets-market-result.component";
export { EtsOrderLinearTableComponent } from "./orderLinear/ets-order-linear-table.component";
export { EtsOrderLinearComponent } from "./orderLinear/ets-order-linear.component";

@Component({
    selector: "ets-dashboard",
    templateUrl: "./ets.component.html",
    styleUrls: ["./ets.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.ez-page]": "true"
    }
})
export class EtsPageComponent implements OnInit, OnDestroy {

    profileSelected: IEtsClient;
    gridSelected: IGrid;
    areaSelected: IEtsArea;
    deliveryDate: DateTime;
    portfolioSelected: IEtsPortfolio;
    hasRightReadOrderLinear: boolean = false;
    marketResultsPanelActive: boolean = false;
    selectedTab: 'linear' | 'result' = 'linear';
    isLoadingTreeGrid: boolean = false;
    marketResultData: IEtsMarketResult;

    private _subs = new Subscriptions();

    constructor(
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private _cdr: ChangeDetectorRef,
        private _logger: Logger
    ) { }

    ngOnInit() {
        this._logger.warn('User on deprecated screen');
        this.hasRightReadOrderLinear = this._appSvc.hasRight(UserRight.EtsLinearorderRead);
        const subDate = this._appSvc.dateChange.subscribe((date) => {
            this.deliveryDate = date;
            this._requestMarketResult();
            this._cdr.detectChanges();
        });
        this._subs.push("dates-change", subDate);
    }

    ngOnDestroy() {
        if (this._subs)
            this._subs.clearAll();
    }

    private _requestMarketResult() {
        this._subs.clearSub('market');
        this.marketResultData = undefined;
        if (this.profileSelected != null && this.deliveryDate != null) {
            let sub = this._api.ets.marketResults.getAll(this._appSvc.contract.id, this.profileSelected.id, this.deliveryDate, [(this.areaSelected ? this.areaSelected.id : undefined)]).subscribe((result) => {
                if (result.length > 0) {
                    this.marketResultData = result[0];
                    this._cdr.detectChanges();

                } else {
                    this.marketResultsPanelActive = false;
                    this.selectedTab = 'linear';
                }
                this._cdr.detectChanges();
            }, (error) => {
                this._logger.error({ message: "error call api on getAll ets marketResults", data: this._appSvc.contract.id }, error);
            }, () => {
                this._cdr.detectChanges();
            }
            );
            this._subs.push('market', sub);
        }
    }

    onSelectTabChange(event: string) {
        this.marketResultsPanelActive = event === 'result';
        this._cdr.detectChanges();
    }

    onParametersChange(data: IEtsParameters): void {
        if (data) {
            this.profileSelected = data.profil;
            this.gridSelected = data.grid;
            this.areaSelected = data.area;
            this.portfolioSelected = data.portfolio;
            this._cdr.detectChanges();
            this._requestMarketResult();
        }
    }

    onRefreshClick(): void {
        this._requestMarketResult();
    }
}
