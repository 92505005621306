// @ts-nocheck
import { AppCommodities } from "@core/application.model";
import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IVersionContainer, OptionsResetPreferencePage, VersionContainer } from './core.model';
import { AppGridsPreference } from "./grid-preferences.model";
import { AppPreferences } from "./app-preferences.model";
import { ShipperPagesPreference } from "./pages/shipper-page-preferences.model";

class AppShipperPreferencesInfo {
    static load(preferences: string | AppShipperPreferencesInfo): AppShipperPreferencesInfo {
        if (typeof preferences === "string")
            return deserialize<AppShipperPreferencesInfo>(preferences, AppShipperPreferencesInfo) as AppShipperPreferencesInfo;
        return preferences;
    }

    readonly version: number = 3;
    readonly shipperId: number;
    readonly grids: string;
    countries: number[] = [];
    hasCountriesSelection: boolean = true;
    powerAreas: number[] = [];
    hasPowerAreasSelection: boolean = true;
    gasAreas: number[] = [];
    hasGasAreasSelection: boolean = true;
    commodities: AppCommodities[] = [];
    readonly pages: string;
}

@UserPreferenceClassDecorator
export class AppShipperPreferences implements IVersionContainer {
    static load(preferences: string | AppShipperPreferencesInfo): AppShipperPreferences {
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        const { shipperId, grids, countries, hasCountriesSelection, commodities, powerAreas, hasPowerAreasSelection, gasAreas, hasGasAreasSelection, pages } = AppShipperPreferencesInfo.load(preferences);
        switch (version) {
            case 1:
                const pagesPreferences = new ShipperPagesPreference(AppPreferences.shipperPagePreferences);
                return new AppShipperPreferences(shipperId, AppGridsPreference.load(grids), countries, hasCountriesSelection, commodities, powerAreas, hasPowerAreasSelection, gasAreas, hasGasAreasSelection, pagesPreferences);
            case 2:
                return new AppShipperPreferences(shipperId, AppGridsPreference.load(grids), countries, hasCountriesSelection, commodities, powerAreas, hasPowerAreasSelection, gasAreas, hasGasAreasSelection, ShipperPagesPreference.load(pages));
            default:
                return new AppShipperPreferences(shipperId);
        }
    }

    @UPPropertySave
    readonly version: number = 2;
    @UPPropertySave
    readonly shipperId: number;
    @UPPropertySave
    grids: AppGridsPreference;

    @UPPropertySave
    countries: number[];

    @UPPropertySave
    hasCountriesSelection: boolean | undefined;

    @UPPropertySave
    hasPowerAreasSelection: boolean | undefined;

    @UPPropertySave
    hasGasAreasSelection: boolean | undefined;

    @UPPropertySave
    powerAreas: number[];

    @UPPropertySave
    gasAreas: number[];

    @UPPropertySave
    commodities: AppCommodities[];

    @UPPropertySave
    readonly pages: ShipperPagesPreference;

    constructor(
        shipperId: number,
        grids: AppGridsPreference = new AppGridsPreference(),
        countries: number[] = [],
        hasCountriesSelection: boolean = true,
        commodities: AppCommodities[] = [],
        powerAreas: number[] = [],
        hasPowerAreasSelection: boolean = true,
        gasAreas: number[] = [],
        hasGasAreasSelection: boolean = true,
        pages: ShipperPagesPreference = new ShipperPagesPreference()
    ) {
        this.shipperId = shipperId;
        this.grids = grids;
        this.countries = countries;
        this.hasCountriesSelection = hasCountriesSelection;
        this.commodities = commodities;
        this.powerAreas = powerAreas;
        this.hasPowerAreasSelection = hasPowerAreasSelection;
        this.gasAreas = gasAreas;
        this.hasGasAreasSelection = hasGasAreasSelection;
        this.pages = pages;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.grids =  new AppGridsPreference();
            this.countries = [];
            this.hasCountriesSelection = true;
            this.commodities = [];
            this.powerAreas = [];
            this.hasPowerAreasSelection = true;
            this.gasAreas = [];
            this.hasGasAreasSelection = true;
            this.pages.reset(options);
        }
    }
}

