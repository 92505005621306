// @ts-nocheck
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "formatNominationDetailDirection" })
export class FormatNominationDetailDirectionPipe implements PipeTransform {

    constructor(private _translateService: TranslateService) {
    }

    /**
     * Format value of the nomination detail direction
     * Private method
     * @param value Origin value from api
     * @returns {string} nomination detail direction formated
     */
    private _formatDetailDirection(value: string): string {
        switch (value) {
            case "SELL":
                return this._translateService.instant("nominations.messages.sell");
            case "BUY":
                return this._translateService.instant("nominations.messages.buy");
            default:
                return "";
        }
    }

    /**
     * Format value of the nomination detail direction
     * Pipe method
     * @param value Origin value from api
     * @returns {string} nomination detail direction formated
     */
    transform(value: string): string {
        return this._formatDetailDirection(value);
    }

    /**
    * Format value of the nomination detail direction
    * Public method
    * @param value Origin value from api
    * @returns {string} nomination detail direction formated
    */
    formatDetailDirection(value: string): string {
        return this._formatDetailDirection(value);
    }
}
