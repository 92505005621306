// @ts-nocheck
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { EzForm } from '@eznergy/components';
import * as _ from "lodash";

export enum FormatType {
    Bold = "bold",
    Italic = "italic"
}
export enum LinkType {
    Link = "link",
    ImageLink = "image",
    ImageFile = "file"
}
export enum SubmitType {
    Save = "save",
    Cancel = "cancel",
}
export enum ViewType {
    Splitted = "splitted",
    Preview = "preview"
}
export enum ListType {
    Ul = "ul",
    Ol = "ol"
}
export class ExternalLink {
    label: string;
    value: string | File[];
    type: LinkType;
}

@Component({
    selector: 'ez-editor-toolbar',
    templateUrl: './editor-toolbar.component.html',
    styleUrls: ['./editor-toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorToolbarComponent {

    @ViewChild("linkForm", { static: true }) _linkForm: EzForm;
    @ViewChild("imageForm", { static: true }) _imageForm: EzForm;

    @Output() formatChange: EventEmitter<FormatType[]> = new EventEmitter();
    @Output() externalLinkChange: EventEmitter<ExternalLink> = new EventEmitter();
    @Output() headingChange: EventEmitter<void> = new EventEmitter();
    @Output() quoteChange: EventEmitter<boolean> = new EventEmitter();
    @Output() listTypeChange: EventEmitter<ListType> = new EventEmitter();
    @Output() viewTypeChange: EventEmitter<ViewType> = new EventEmitter();
    @Output() submitChange: EventEmitter<SubmitType> = new EventEmitter();

    @Input()
    get disabled() {
        return this._disabled;
    }
    set disabled(value: boolean) {
        if (!value) {
            value = undefined;
        }
        if (value !== this.disabled) {
            this._disabled = value;
            this._cdr.markForCheck();
        }
    }
    private _disabled: boolean;

    @Input()
    get format(): FormatType[] { return this._format; }
    set format(value: FormatType[]) {
        if (this.format !== value) {
            this._format = value;
            this._cdr.markForCheck();
        }
    }
    private _format: FormatType[];
    formats = FormatType;

    @Input()
    get heading(): boolean {
        return this._heading;
    }
    set heading(value: boolean) {
        if (!value) {
            value = undefined;
        }
        if (value !== this._heading) {
            this._heading = value;
            this._cdr.markForCheck();
        }
    }
    private _heading: boolean;

    @Input()
    get quote(): boolean {
        return this._quote;
    }
    set quote(value: boolean) {
        if (value !== this.quote) {
            this._quote = value;
            this._cdr.markForCheck();
        }
    }
    private _quote: boolean;

    @Input()
    get listType(): ListType {
        return this._listType;
    }
    set listType(value: ListType) {
        if (this.listType !== value) {
            this._listType = value;
            this._cdr.markForCheck();
        }
    }
    private _listType: ListType;
    listTypes = ListType;

    @Input()
    get viewType(): ViewType {
        return this._viewType;
    }
    set viewType(value: ViewType) {
        if (this.viewType !== value) {
            this._viewType = value;
            this._cdr.markForCheck();
        }
    }
    private _viewType: ViewType;
    viewTypes = ViewType;

    isLinkModalOpen: boolean;
    isImageFileModalOpen: boolean;

    get externalLink(): ExternalLink { return this._externalLink; }
    set externalLink(value: ExternalLink) { this._externalLink = value; }
    private _externalLink: ExternalLink = new ExternalLink();

    submitType: SubmitType;
    submitTypes = SubmitType;

    links = LinkType;
    linkType: LinkType;

    constructor(private _cdr: ChangeDetectorRef) { }

    onExecCommandFormat(value: FormatType[]): void {
        this._format = value;
        this.formatChange.emit(this._format);
    }

    onExecCommandLink(value: LinkType): void {
        this.externalLink.type = this.linkType = value;
        switch (this.externalLink.type) {
            case LinkType.Link:
            case LinkType.ImageLink:
                this.isLinkModalOpen = true;
                break;
            case LinkType.ImageFile:
                this.isImageFileModalOpen = true;
                break;
        }
    }

    onExecCommandHeading(): void {
        this.headingChange.emit();
    }

    onExecCommandList(value: ListType): void {
        // TODO : bug fix button-group emit same value several times in required mode true
        this._listType = value;
        this.listTypeChange.emit(this._listType);
    }

    onExecCommandQuote(): void {
        this._quote = !this._quote;
        this.quoteChange.emit(this._quote);
    }

    onExecCommandView(value: ViewType): void {
        this._viewType = value;
        this.viewTypeChange.emit(this._viewType);
    }

    onExecCommandSubmit(value: SubmitType): void {
        this.submitType = undefined;
        this.submitChange.emit(value);
    }

    onCancelLink(close: boolean = false): void {
        let type = this._externalLink.type;
        this.externalLink = new ExternalLink();
        if (!close)
            this.externalLink.type = type;
    }

    onValidateLink(): void {
        this.externalLinkChange.emit(_.clone(this.externalLink));
        this.externalLink.type == LinkType.ImageFile ? this._imageForm.cancel() : this._linkForm.cancel();
        this.externalLink = new ExternalLink();
        this.isLinkModalOpen = this.isImageFileModalOpen = false;
    }
}
