<ez-table [data]="gradientItems" [disabled]="disabled">
    <ez-row-header>
        <th colspan="2" class="gd-times">
            <ng-content select="ez-label"></ng-content>
        </th>
        <th class="gd-value">
            <ng-content select=".ez-factor"></ng-content>
        </th>
        <th class="gd-actions"></th>
    </ez-row-header>
    <ez-column property="from">
        <td
            *ezTbCell="let item"
            class="gd-time"
            [ngClass]="{ 'has-error': item.onError }"
        >
            {{ item.from?.totalMinutes }}
        </td>
    </ez-column>
    <ez-column property="to">
        <td
            *ezTbCell="let item; index as i"
            class="gd-time"
            [ngClass]="{ 'has-error': item.onError }"
        >
            <ez-input-number
                *ngIf="item?.to; else infinity"
                [disabled]="disabled"
                [value]="item.to.totalMinutes"
                (valueChange)="onToChange($event, i)"
            >
            </ez-input-number>
            <ng-template #infinity>
                <span class="ez-icon-infiny"> </span>
            </ng-template>
        </td>
    </ez-column>
    <ez-column property="value">
        <td
            *ezTbCell="let item; index as i"
            class="gd-value"
            [ngClass]="{ 'has-error': item.onError }"
        >
            <ez-input-number
                [disabled]="disabled"
                [(value)]="item.value"
                (valueChange)="onFactorChange($event, i)"
                [min]="min"
                [max]="max"
            >
                <ez-suffix>{{ item.unit ? item.unit.name : "%" }}</ez-suffix>
            </ez-input-number>
        </td>
    </ez-column>
    <ez-column property="delete">
        <td
            *ezTbCell="let item; index as i"
            class="gd-actions"
            [ngClass]="{ 'has-error': item.onError }"
        >
            <ez-button
                *ngIf="item?.to; else addbtn"
                [icon]="'minus'"
                (onClick)="deleteRow(i)"
                [disabled]="
                    (gradientItems &&
                        gradientItems.length == 1 &&
                        !gradientItems.from &&
                        !gradientItems.to) ||
                    disabled
                "
                [small]="true"
            >
            </ez-button>
            <ng-template #addbtn>
                <ez-button
                    [disabled]="disabled"
                    [icon]="'plus'"
                    (onClick)="onAddClick()"
                    [small]="true"
                ></ez-button>
            </ng-template>
        </td>
    </ez-column>
</ez-table>
