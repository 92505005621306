// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Logger } from '@core/logger';
import { DateRangeType } from '@enums/date-range-type.enum';
import { EzNotificationService } from '@eznergy/toaster';
import { ApiService } from '@eznergy/webapi';
import { DateRange, DateTime, DayUnit, IDateRange, TimeUnit, TimeZone } from '@eztypes/generic';
import { AuctionScheduleImmediate, IAuctionDeliveryArea, IAuctionExchangePortfolio, IAuctionExchangeProducts, IAuctionScheduledBatch, ICalendar, IOrganisation } from '@eztypes/webapi';
import { FormBase } from '@forms/core/form-base';
import { TranslateService } from '@ngx-translate/core';
import { IScheduleBase } from '../../../models/schedule';
import { ApplicationService } from '@services/application.service';
import { FormSchedulesBaseComponent } from '../form-schedule-base/form-schedule-base.component';

@Component({
    selector: 'auction-immediate-schedule-form',
    templateUrl: './auction-immediate-schedule.component.html',
    styleUrls: ['./auction-immediate-schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AuctionImmediateSchedulesForm extends FormBase {

    @Output() validated: EventEmitter<IAuctionScheduledBatch[]> = new EventEmitter<IAuctionScheduledBatch[]>();
    @ViewChild(FormSchedulesBaseComponent, { static: true }) readonly formBase: FormSchedulesBaseComponent;

    @Input()
    get deliveryArea(): IAuctionDeliveryArea {
        return this._deliveryArea;
    }
    set deliveryArea(value: IAuctionDeliveryArea) {
        if (this._deliveryArea !== value) {
            this._deliveryArea = value;
            this._cdr.markForCheck();
        }
    }
    private _deliveryArea: IAuctionDeliveryArea;

    @Input()
    get shipper(): IOrganisation {
        return this._shipper;
    }
    set shipper(value: IOrganisation) {
        if (this.shipper !== value) {
            this._shipper = value;
            this._cdr.markForCheck();
        }
    }
    private _shipper: IOrganisation;

    @Input()
    set exchangeProducts(values: IAuctionExchangeProducts[]) {
        if (this._exchangesProducts !== values) {
            this._exchangesProducts = values;
            this._cdr.markForCheck();
        }
    }
    get exchangeProducts(): IAuctionExchangeProducts[] {
        return this._exchangesProducts;
    }
    private _exchangesProducts: IAuctionExchangeProducts[];

    @Input()
    set portfolios(values: IAuctionExchangePortfolio[]) {
        if (this._portfolios !== values) {
            this._portfolios = values;
            this._cdr.markForCheck();
        }
    }
    get portfolios(): IAuctionExchangePortfolio[] {
        return this._portfolios;
    }
    private _portfolios: IAuctionExchangePortfolio[];

    set selectedRadio(value: DateRangeType) {
        this._selectedRadio = value;
    }
    get selectedRadio(): DateRangeType {
        return this._selectedRadio;
    }
    private _selectedRadio: DateRangeType = DateRangeType.Intraday;

    get timezone(): TimeZone {
        return this._timezone;
    }
    private _timezone: TimeZone;

    get calendar(): ICalendar {
        return this.scheduleBaseValue && this.scheduleBaseValue.portfolio ? this.scheduleBaseValue.portfolio.area.calendar : undefined;
    }

    get porfolioSelected(): boolean {
        return this._porfolioSelected;
    }
    private _porfolioSelected: boolean;

    get onValidate(): boolean {
        return this._onValidate;
    }
    private _onValidate: boolean;

    periodDate: IDateRange;
    intraday: DateTime;
    dayAhead: DateTime;
    scheduleBaseValue: IScheduleBase;
    readonly dateRangeType = DateRangeType;
    private _portfolioCache: IAuctionExchangePortfolio;

    constructor(private readonly _cdr: ChangeDetectorRef,
        private readonly _api: ApiService,
        private readonly _logger: Logger,
        private readonly _appSvc: ApplicationService,
        private readonly _translate: TranslateService,
        private readonly _notifSvc: EzNotificationService
    ) {
        super();
    }

    periodDateChange(period: IDateRange): void {
        this.periodDate = new DateRange(period.from.startOf(TimeUnit.Days), period.to.startOf(TimeUnit.Days));
    }

    formBaseValueChanged(value: IScheduleBase): void {
        this._porfolioSelected = value && value.portfolio ? true : false;
        if (this._porfolioSelected) {
            if (value.portfolio != this._portfolioCache) {
                this._portfolioCache = value.portfolio;
                this._initForm();
            }
        }
    }

    formSubmit(): void {
        if (this.formBase.submit()) {
            this._onValidate = true;
            let startDate: DateTime;
            let endDate: DateTime;
            if (this.selectedRadio == DateRangeType.Intraday) {
                startDate = this.intraday.startOf(TimeUnit.Days);
                endDate = this.intraday.startOf(TimeUnit.Days);
            } else if (this.selectedRadio == DateRangeType.DayAhead) {
                startDate = this.dayAhead.startOf(TimeUnit.Days);
                endDate = this.dayAhead.startOf(TimeUnit.Days);
            } else {
                startDate = this.periodDate.from;
                endDate = this.periodDate.to;
            }

            const batch: AuctionScheduleImmediate = new AuctionScheduleImmediate();
            batch.batchType = this.scheduleBaseValue.batchType;
            batch.executedAt = DateTime.now().setTimezone(this._timezone.name).addMinutes(2);
            batch.calendarId = this.calendar.id;

            this._api.auctions.schedules.createImmediate(this._appSvc.contract.id, this._shipper, this.scheduleBaseValue.portfolio, this._deliveryArea, startDate, endDate, this.scheduleBaseValue.product, batch).subscribe(
                (results) => {
                    this._onValidate = false;
                    this._translate.get('pages.auction-schedules.component.forms.success-save').subscribe((text) => {
                        this._notifSvc.success(text);
                    });
                    this.validated.emit(results);
                },
                (error: Error) => {
                    this._onValidate = false;
                    this._logger.error("Request Create Immediate Schedule Failed", error);
                    this._cdr.detectChanges();
                }
            );
        }
    }

    private _initForm(): void {
        this._timezone = TimeZone.create(this.calendar.ianaName);
        this.intraday = new DateTime(undefined, this.calendar.ianaName).startOf(TimeUnit.Days).addHours(this.calendar.offset, true);
        this.dayAhead = this.intraday.addDays(1);
        const nextMonday = this.intraday.nextDay(DayUnit.Monday, this.intraday.dayOfWeek === DayUnit.Monday ? 1 : undefined);
        const followingSunday = nextMonday.nextDay(DayUnit.Sunday);
        this.periodDate = new DateRange(nextMonday.startOf(TimeUnit.Days).addHours(this.calendar.offset, true), followingSunday.startOf(TimeUnit.Days).addHours(this.calendar.offset, true));
        this.selectedRadio = DateRangeType.Intraday;
    }
}
