// @ts-nocheck
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ABTestingKeys, ABTestingService } from '@services/ab-testing.service';

class OneItemValues {
    keyLabel: string;
    keyValue: string;
    value: any;
    possibleValues: any[];
}

@Component({
    selector: 'ab-testing',
    templateUrl: './ab-testing.component.html',
    styleUrls: ['./ab-testing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AbTesting {

    private _enumKeys: any = ABTestingKeys;
    keysAndValues: OneItemValues[] = [];

    constructor(private _abService: ABTestingService) {
        for (let testingKey in this._enumKeys) {
            let item = new OneItemValues;
            item.keyLabel = testingKey.replace(/_/gi, " ");                                     // Key label (on screen)
            item.keyValue = this._enumKeys[testingKey];                     // Key value
            item.value = this._abService.get(this._enumKeys[testingKey]);   // present value
            item.possibleValues = this._abService.getPossibleValues(this._enumKeys[testingKey]); // list of possible values
            this.keysAndValues.push(item);                                 // Object: label + key + value + possible values...
        }
    }

    checkboxClick(item: OneItemValues, checked: boolean): void {
        this._abService.registerParam(item.keyValue, checked);
    }

    selectedValue(item: OneItemValues, value: string): void {
        this._abService.registerParam(item.keyValue, value);
    }
}
