// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from "@angular/core";
import { DateTime, Timespan } from '@eztypes/generic';
import { AppPages, RouterService } from "@services/router.service";
import { interval, Subscription } from 'rxjs';

@Component({
    selector: "page-offline",
    templateUrl: "./page-offline.component.html",
    styleUrls: ["./page-offline.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PageOfflineComponent {

    private _sub: Subscription;

    get nextTry(): DateTime {
        return this._nextTry;
    }
    private _nextTry: DateTime;

    private readonly _nextTryInterval: number = 10;

    constructor(private readonly _router: RouterService, private readonly _cdr: ChangeDetectorRef) {
        this._nextTry = DateTime.now().addSeconds(this._nextTryInterval + 1);
        this._sub = interval(Timespan.fromSeconds(this._nextTryInterval).totalMilliseconds).subscribe(() => {
            if (!this.retryNow()) {
                this._nextTry = DateTime.now().addSeconds(this._nextTryInterval + 1);
                this._cdr.detectChanges();
            }
        });
    }

    retryNow(): boolean {
        if (navigator.onLine) {
            this._router.navigateTo(AppPages.Login);
            return true;
        }
        return false;
    }

    ngOnDestroy(): void {
        this._sub.unsubscribe();
        this._sub = undefined;
    }

}
