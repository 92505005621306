import {
    ColumnMetaData, DashboardChartDisplay, DashboardChartType, DashboardTableType, IColumnMetaData, ViewLevel
} from '../../../modules/dashboard/models/contracts';
import { ViewToolPanelColumnData } from '../../../modules/dashboard/tool-panel/actions/actions.toolpanel';
import { UPPropertySave, UserPreferenceClassDecorator } from '@core/decorators';
import { deserialize } from '@eznergy/core';
import { DateRange, IDateRange } from '@eztypes/generic';
import { IMaterialType, TimeGranularity } from '@eztypes/webapi';
import { IVersionContainer, OptionsResetPreferencePage, VersionContainer } from '../core.model';
import { SavedPositionPresets, defaultPreset } from './position-filters-preferences.model';

class PagePositionPreferenceInfo {
    static load(preferences: string | PagePositionPreferenceInfo): PagePositionPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PagePositionPreferenceInfo>(
                preferences,
                PagePositionPreferenceInfo
            ) as PagePositionPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    chartMode: DashboardChartDisplay = DashboardChartDisplay.None;
    tableMode: DashboardTableType = DashboardTableType.DateCol;
    granularity: TimeGranularity | undefined;
    unitId: number | undefined;
    timezoneIana: string | undefined;
    horizontalViewLevel: ViewLevel | undefined;
    horizontalColumns: ColumnMetaData[] | undefined;
    chartType: DashboardChartType = DashboardChartType.Line;
    dates: DateRange | undefined;
    horizontalColumnsData: ViewToolPanelColumnData[] | undefined;
    verticalViewLevel: ViewLevel | undefined;
    verticalColumns: ColumnMetaData[] | undefined;
    verticalColumnsData: ViewToolPanelColumnData[] | undefined;
    hiddenDataKeys: string[] | undefined;
    refreshTimer: number | undefined;
    presets: SavedPositionPresets[] = defaultPreset;
    activePreset: string | undefined;
    horizontalTotals: { enabled: boolean; visible: boolean } | undefined;
    verticalTotals: { enabled: boolean; visible: boolean } | undefined;
    horizontalSubtotals: { enabled: boolean; visible: boolean } | undefined;
    verticalSubtotals: { enabled: boolean; visible: boolean } | undefined;
}

@UserPreferenceClassDecorator
export class PagePositionPreference implements IVersionContainer {
    static load(preferences: string | PagePositionPreferenceInfo): PagePositionPreference {
        if (!preferences) {
            return new PagePositionPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;

        switch (version) {
            case 1:
                const v1 = PagePositionPreferenceInfo.load(preferences);
                return new PagePositionPreference(v1);
            default:
                return new PagePositionPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    chartMode: DashboardChartDisplay = DashboardChartDisplay.None;
    @UPPropertySave
    tableMode: DashboardTableType = DashboardTableType.DateCol;
    @UPPropertySave
    chartType: DashboardChartType = DashboardChartType.Line;
    @UPPropertySave
    dates: IDateRange | undefined;
    @UPPropertySave
    granularity: TimeGranularity | undefined;
    @UPPropertySave
    unitId: number | undefined;
    @UPPropertySave
    materialType: IMaterialType | undefined;

    @UPPropertySave
    timezoneIana: string | undefined;

    @UPPropertySave
    horizontalViewLevel: ViewLevel | undefined;
    @UPPropertySave
    horizontalColumns: IColumnMetaData[] | undefined;

    @UPPropertySave
    verticalViewLevel: ViewLevel | undefined;
    @UPPropertySave
    verticalColumns: IColumnMetaData[] | undefined;
    @UPPropertySave
    refreshTimer: number | undefined;
    @UPPropertySave
    presets: SavedPositionPresets[];
    @UPPropertySave
    activePreset: string | undefined;

    getViewLevel(tableType: DashboardTableType): ViewLevel | undefined {
        switch (tableType) {
            case DashboardTableType.DateCol: {
                return this.horizontalViewLevel;
            }
            case DashboardTableType.DateRow: {
                return this.verticalViewLevel;
            }
        }
        return undefined;
    }

    setViewLevel(tableType: DashboardTableType, value: ViewLevel | undefined): void {
        switch (tableType) {
            case DashboardTableType.DateCol: {
                this.horizontalViewLevel = value;
                break;
            }
            case DashboardTableType.DateRow: {
                this.verticalViewLevel = value;
                break;
            }
        }
    }

    getColumns(tableType: DashboardTableType): IColumnMetaData[] | undefined {
        switch (tableType) {
            case DashboardTableType.DateCol: {
                return this.horizontalColumns && [...this.horizontalColumns];
            }
            case DashboardTableType.DateRow: {
                return this.verticalColumns && [...this.verticalColumns];
            }
        }
        return undefined;
    }

    setColumns(tableType: DashboardTableType, value: IColumnMetaData[] | undefined): void {
        switch (tableType) {
            case DashboardTableType.DateCol: {
                this.horizontalColumns = value && [...value];
                break;
            }
            case DashboardTableType.DateRow: {
                this.verticalColumns = value && [...value];
                break;
            }
        }
    }

    @UPPropertySave
    hiddenDataKeys: string[] | undefined;

    @UPPropertySave
    horizontalTotals: { enabled: boolean; visible: boolean; } | undefined;
    @UPPropertySave
    verticalTotals: { enabled: boolean; visible: boolean; } | undefined;

    @UPPropertySave
    horizontalSubtotals: { enabled: boolean; visible: boolean; } | undefined;
    @UPPropertySave
    verticalSubtotals: { enabled: boolean; visible: boolean; } | undefined;

    getTotals(tableType: DashboardTableType): { enabled: boolean; visible: boolean; } | undefined {
        switch (tableType) {
            case DashboardTableType.DateCol: {
                return this.horizontalTotals;
            }
            case DashboardTableType.DateRow: {
                return this.verticalTotals;
            }
        }
        return undefined;
    }

    setTotals(tableType: DashboardTableType, value: { enabled: boolean; visible: boolean; } | undefined): void {
        switch (tableType) {
            case DashboardTableType.DateCol: {
                this.horizontalTotals = value;
                break;
            }
            case DashboardTableType.DateRow: {
                this.verticalTotals = value;
                break;
            }
        }
    }

    getSubtotals(tableType: DashboardTableType): { enabled: boolean; visible: boolean; } | undefined {
        switch (tableType) {
            case DashboardTableType.DateCol: {
                return this.horizontalSubtotals;
            }
            case DashboardTableType.DateRow: {
                return this.verticalSubtotals;
            }
        }
        return undefined;
    }

    setSubtotals(tableType: DashboardTableType, value: { enabled: boolean; visible: boolean; } | undefined): void {
        switch (tableType) {
            case DashboardTableType.DateCol: {
                this.horizontalSubtotals = value;
                break;
            }
            case DashboardTableType.DateRow: {
                this.verticalSubtotals = value;
                break;
            }
        }
    }

    constructor(
        data: Partial<{
            chartMode: DashboardChartDisplay;
            chartType: DashboardChartType;
            tableMode: DashboardTableType;
            horizontalViewLevel: ViewLevel | undefined;
            horizontalColumns: ColumnMetaData[] | undefined;
            granularity: TimeGranularity | undefined;
            unitId: number | undefined;
            timezoneIana: string | undefined;
            dates: IDateRange | undefined;
            verticalViewLevel: ViewLevel | undefined;
            verticalColumns: ColumnMetaData[] | undefined;
            hiddenDataKeys: string[] | undefined;
            refreshTimer: number | undefined;
            presets: SavedPositionPresets[];
            activePreset: string | undefined;
            horizontalTotals: { enabled: boolean; visible: boolean; } | undefined;
            verticalTotals: { enabled: boolean; visible: boolean; } | undefined;
            horizontalSubtotals: { enabled: boolean; visible: boolean; } | undefined;
            verticalSubtotals: { enabled: boolean; visible: boolean; } | undefined;
        }> = {}
    ) {
        const {
            chartMode,
            chartType,
            tableMode,
            horizontalViewLevel,
            horizontalColumns,
            granularity,
            timezoneIana,
            unitId,
            dates,
            verticalViewLevel,
            verticalColumns,
            hiddenDataKeys,
            refreshTimer,
            presets,
            activePreset,
            horizontalTotals,
            verticalTotals,
            horizontalSubtotals,
            verticalSubtotals
        } = data;

        this.dates = dates;

        this.chartMode = chartMode || DashboardChartDisplay.None;
        this.chartType = chartType || DashboardChartType.Line;
        this.tableMode = tableMode || DashboardTableType.DateCol;

        this.granularity = granularity;
        this.unitId = unitId;
        this.timezoneIana = timezoneIana;

        this.horizontalViewLevel = horizontalViewLevel;
        this.horizontalColumns = horizontalColumns;

        this.verticalViewLevel = verticalViewLevel;
        this.verticalColumns = verticalColumns;

        this.hiddenDataKeys = hiddenDataKeys;

        this.refreshTimer = refreshTimer;

        this.presets = presets ?? defaultPreset;
        this.activePreset = activePreset;

        this.horizontalTotals = horizontalTotals;
        this.verticalTotals = verticalTotals;

        this.horizontalSubtotals = horizontalSubtotals;
        this.verticalSubtotals = verticalSubtotals;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.granularity = undefined;
            this.unitId = undefined;
            this.timezoneIana = undefined;
            this.tableMode = DashboardTableType.DateCol;
            this.chartType = DashboardChartType.Line;
            this.chartMode = DashboardChartDisplay.None;
            this.hiddenDataKeys = undefined;
            this.verticalColumns = undefined;
            this.horizontalColumns = undefined;
            this.horizontalTotals = { enabled: true, visible: false };
            this.horizontalSubtotals = { enabled: true, visible: false };
            this.verticalTotals = { enabled: false, visible: false };
            this.verticalSubtotals = { enabled: false, visible: false };
            this.refreshTimer = undefined;
            this.presets = defaultPreset;
            this.activePreset = undefined;
            this.refreshTimer = undefined;
        }
    }

    initForOneGrid(): void {
        this.tableMode = DashboardTableType.DateCol;
        this.chartMode = DashboardChartDisplay.None;
        this.chartType = DashboardChartType.Line;
    }
}
