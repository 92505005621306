// @ts-nocheck
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Direction } from '../transport-direction/transport-direction.icon';

@Component({
    selector: 'transport-power-exit-icon',
    templateUrl: './transport-power-exit.icon.html',
    styleUrls: ['./transport-power-exit.icon.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportPowerExitIcon {

    Direction = Direction;

    constructor() { }

}
