// Libs
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { isSame } from '@eznergy/core';
import { NumberRange } from '@eztypes/generic';
import {
    IDecimalCurve,
    IFlexPricesParameters,
    IMarketMakingPolicy,
    IPhysicalValue,
    IPositionPricesParameters,
    ITimeGradientItem,
    IUnit,
    IUnitFamily,
    IVolumeGradientItem,
    PhysicalValue,
    PositionPricesParameters,
    SessionType,
    CurveType
} from '@eztypes/webapi';
// Models
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicPriceType, IFormGroupPriceFlexPos } from '../../../../models/price-flexpos.models';
import { merge, Observable, Subscription } from 'rxjs';
import { PricesFlexposMapper } from '../../../../models/prices-flexpos.mapper';
import { map } from 'rxjs/operators';
import * as _ from "lodash";

@Component({
    selector: "at-prices-flexpos-form",
    templateUrl: "./prices-flexpos.component.html",
    styleUrls: ["./prices-flexpos.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AtPricesFlexPosForm implements OnInit, OnDestroy {

    public formGroup: FormGroup<IFormGroupPriceFlexPos> = this.fb.group<IFormGroupPriceFlexPos>({
        spread: this.fb.control({ value: null, disabled: true }),
        hardLimitsActivated: this.fb.nonNullable.control(false, Validators.required),
        hardLimit: this.fb.group({
            hardLimitsMin: this.fb.control<IDecimalCurve | null>({value: null, disabled: true }, Validators.required),
            hardLimitsMax: this.fb.control<IDecimalCurve | null>({value: null, disabled: true }, Validators.required),
        }),
        priceLimit: this.fb.group({
            absoluteBuy: this.fb.control<IDecimalCurve | null>(null, Validators.required),
            absoluteSell: this.fb.control<IDecimalCurve | null>(null, Validators.required),
            timeGradient : this.fb.group({
                isTimeGradient: this.fb.nonNullable.control(false),
                timeGradientValue: this.fb.nonNullable.control<ITimeGradientItem[]>([], Validators.required),
            }),
            volumeGradient: this.fb.group({
                isVolumeGradient: this.fb.nonNullable.control(false),
                volumeGradientValue: this.fb.nonNullable.control<IVolumeGradientItem[]>([], Validators.required)
            })
        }),
        isDynamicPrice: this.fb.nonNullable.control<boolean>(false),
        dynamicPriceLimit: this.fb.group({
            dynamicPriceRadio: this.fb.nonNullable.control<DynamicPriceType>(DynamicPriceType.isBidAsk),
            valueRange: this.fb.group({
                valueRangeBuyBid: this.fb.nonNullable.control<number>(0),
                valueRangeSellBid: this.fb.nonNullable.control<number>(100),
            }),
            valueRangeVW: this.fb.group({
                valueRangeVWBuyBid: this.fb.nonNullable.control<number>(0),
                valueRangeVWSellBid: this.fb.nonNullable.control<number>(100),
            }),
            manualLimit: this.fb.group({
                marketMakingPolicies: this.fb.nonNullable.control<IMarketMakingPolicy[]>([], Validators.required),
                isBestMarketFilter: this.fb.nonNullable.control<boolean>(false),
                bestMarketFilter: this.fb.group({
                    minVolumeTimeserie: this.fb.control<IDecimalCurve | null>({ value: null, disabled: true }, Validators.required),
                    maxPriceDeltaTimeserie: this.fb.control<IDecimalCurve | null>({ value: null, disabled: true }, Validators.required)
                })
            }),
        }),
        isManualModeLimits: this.fb.nonNullable.control<boolean>(false, Validators.required),
        manualModeLimits: this.fb.group({
            manualLimitsMin: this.fb.control<IDecimalCurve | null>(null, Validators.required),
            manualLimitsMax: this.fb.control<IDecimalCurve | null>(null, Validators.required),
        })
    });

    private _value: IFlexPricesParameters | IPositionPricesParameters = new PositionPricesParameters();

    // Ez-Form compatibility
    @Output() valueChange: EventEmitter<IFlexPricesParameters | IPositionPricesParameters> =
        new EventEmitter<IFlexPricesParameters | IPositionPricesParameters>();
    // Ez-Form compatibility
    @Output() paramRestartSession: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    // Ez-Form compatibility
    @Output() onSubmit: EventEmitter<void> =
        new EventEmitter<void>();
    // Ez-Form compatibility
    @Output() onChange: Observable<boolean> =
        this.formGroup.valueChanges.pipe(
            map(() => this.formGroup.dirty && this.formGroup.touched)
        );

    // Ez-Form compatibility
    hasChanged(): boolean {
        return this.formGroup.dirty && this.formGroup.touched;
    }

    cancel() {
        this.formGroup.reset(new PricesFlexposMapper().toFormGroup(this._value));
    }

    @Input()
    get value(): IFlexPricesParameters | IPositionPricesParameters {
        return this._value;
    }
    set value(value: IFlexPricesParameters | IPositionPricesParameters) {
        if (!value) {
            this._value = new PositionPricesParameters();
            this._resetInternalValues();
            this._cdr.markForCheck();
        }
        else if (value !== this.value) {
            this._value = value;
            this._resetInternalValues();
            this._cdr.markForCheck();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._globalDisable;
    }
    set disabled(value: boolean) {
        this._globalDisable = value;
        value ? this.formGroup.disable() : this.formGroup.enable();
    }
    private _globalDisable = false;

    @Input()
    set reset(value: boolean) {
        if (value) this.formGroup.reset(new PricesFlexposMapper().toFormGroup(this._value));
    }

    @Input()
    get sessionType(): SessionType {
        return this._sessionType;
    }
    set sessionType(value: SessionType) {
        if (!value) {
            return;
        }

        if (this.sessionType !== value) {
            this._sessionType = value;

            if (this._sessionType != SessionType.Flex && this._sessionType != SessionType.Position) {
                throw new Error("SessionType must be Flex or Position in current form");
            }

            this._resetInternalValues();
            this._cdr.markForCheck();
        }
    }
    private _sessionType: SessionType = SessionType.Position;

    @Input()
    get defaultUnit(): IUnit | undefined {
        return this._defaultUnit;
    }
    set defaultUnit(value: IUnit | undefined) {
        if (value !== this._defaultUnit) {
            this._defaultUnit = value;
            this._cdr.markForCheck();
        }
    }
    private _defaultUnit: IUnit | undefined;

    @Input()
    get defaultValue(): IPhysicalValue {
        return this._defaultValue;
    }
    set defaultValue(value: IPhysicalValue) {
        if (value !== this.defaultValue) {
            this._defaultValue = value;
            this._cdr.markForCheck();
        }
    }
    private _defaultValue: IPhysicalValue = new PhysicalValue();

    @Input()
    set energyPriceUnitsFamily(value: IUnitFamily | undefined) {
        if (this.energyPriceUnitsFamily !== value) {
            this._energyPriceUnitsFamily = value;
            this._cdr.markForCheck();
        }
    }
    get energyPriceUnitsFamily(): IUnitFamily | undefined {
        return this._energyPriceUnitsFamily;
    }
    private _energyPriceUnitsFamily: IUnitFamily | undefined;

    @Input()
    set powerUnitsFamily(value: IUnitFamily | undefined) {
        if (this.powerUnitsFamily !== value) {
            this._powerUnitsFamily = value;
            this._cdr.markForCheck();
        }
    }
    get powerUnitsFamily(): IUnitFamily | undefined {
        return this._powerUnitsFamily;
    }
    private _powerUnitsFamily: IUnitFamily | undefined;

    @Input()
    get units(): IUnit[] | undefined {
        return this._units;
    }
    set units(value: IUnit[] | undefined) {
        if (this.units !== value) {
            this._units = value;
            this._cdr.markForCheck();
        }
    }
    private _units: IUnit[] | undefined;

    // Ez-Form compatibility
    private _paramRestartSession: boolean = false;
    private subs = new Subscription();
    private _defaultMarketPolicies: IMarketMakingPolicy[] = [];
    private _defaultTimeGradient: ITimeGradientItem[] = [];
    private _defaultVolumeGradient: IVolumeGradientItem[] = [];
    sessionTypes = SessionType;
    limitPercent = new NumberRange(0, 100);
    dynamicPriceType = DynamicPriceType;
    minimumBuySellSpreadValue: number = 0.01;

    get CurveType(): any {
        return CurveType;
    }

    get timeGradient(): ITimeGradientItem[] {
        return this.formGroup.controls.priceLimit.controls.timeGradient.controls.timeGradientValue.value;
    }
    set timeGradient(value: ITimeGradientItem[]) {
        if (!this._defaultTimeGradient.length) {
            this._defaultTimeGradient = value;
            return;
        }
        if (!this._value?.timeGradient || !this.formGroup.controls.priceLimit.controls.timeGradient.controls.isTimeGradient.value)
            return;
        if (this.formGroup.untouched && this._hasTimeOrVolumeGradientValueChanged(value, "timeGradient"))
            this.formGroup.markAsTouched()
        this.formGroup.controls.priceLimit.controls.timeGradient.controls.timeGradientValue.setValue(value);
    }

    get volumeGradient(): IVolumeGradientItem[] {
        return this.formGroup.controls.priceLimit.controls.volumeGradient.controls.volumeGradientValue.value;
    }
    set volumeGradient(value: IVolumeGradientItem[]) {
        if (!this._defaultVolumeGradient.length){
            this._defaultVolumeGradient = value;
            return;
        }
        if (!this._value?.volumeGradient || !this.formGroup.controls.priceLimit.controls.volumeGradient.controls.isVolumeGradient.value)
            return;
        if (this.formGroup.untouched && value.length && this._hasTimeOrVolumeGradientValueChanged(value, "volumeGradient"))
            this.formGroup.markAsTouched();
        this.formGroup.controls.priceLimit.controls.volumeGradient.controls.volumeGradientValue.setValue(value);
    }

    get marketMakingPolicies(): IMarketMakingPolicy[] {
        return this.formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.marketMakingPolicies.value;
    }
    set marketMakingPolicies(value: IMarketMakingPolicy[]) {
        if (!this._defaultMarketPolicies.length) {
            this._defaultMarketPolicies = value;
            return;
        }
        if (this.formGroup.controls.dynamicPriceLimit.controls.dynamicPriceRadio.value !== DynamicPriceType.isManualStrategy)
            return;
        if (this.formGroup.untouched && value && this._hasMarketPoliciesValueChanged(value))
            this.formGroup.markAsTouched()
        this.formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.marketMakingPolicies.setValue(value);
    }

    constructor(
        private _cdr: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    public ngOnInit() {
        this._setupFormsListeners();
    }

    private _setupFormsListeners(): void {
        const hardLimitSub = this.formGroup.controls.hardLimitsActivated.valueChanges
            .subscribe((checked: boolean) => {
                const hardLimitGroup = this.formGroup.controls.hardLimit;
                checked && !this._globalDisable ? hardLimitGroup?.enable() : hardLimitGroup?.disable();
                this.formGroup.markAsTouched();
            });
        this.subs.add(hardLimitSub);

        const timeGradientSub = this.formGroup.controls.priceLimit.controls.timeGradient.controls.isTimeGradient.valueChanges
            .subscribe((checked: boolean) => {
                const timeGradientGroup = this.formGroup.controls.priceLimit.controls.timeGradient.controls.timeGradientValue;
                if (checked && !this._globalDisable) {
                    timeGradientGroup?.enable();
                    this.timeGradient = this._defaultTimeGradient;
                } else 
                    timeGradientGroup?.disable();
                this.formGroup.markAsTouched();
            });
        this.subs.add(timeGradientSub);

        const volumeGradientSub = this.formGroup.controls.priceLimit.controls.volumeGradient.controls.isVolumeGradient.valueChanges
            .subscribe((checked: boolean) => {
                const volumeGradientGroup = this.formGroup.controls.priceLimit.controls.volumeGradient.controls.volumeGradientValue;
                if (checked && !this._globalDisable) {
                    volumeGradientGroup?.enable();
                    this.volumeGradient = this._defaultVolumeGradient;
                } else
                    volumeGradientGroup?.disable();
                this.formGroup.markAsTouched();
            });
        this.subs.add(volumeGradientSub);

        const dynamicLimitSub = this.formGroup.controls.isDynamicPrice.valueChanges
            .subscribe((checked: boolean) => {
                checked && !this._globalDisable ? this.enableDynamicLimits() : this.formGroup.controls.dynamicPriceLimit.disable();
                this.formGroup.markAsTouched();
            });
        this.subs.add(dynamicLimitSub);

        const dynamicPriceRadioSub = this.formGroup.controls.dynamicPriceLimit.controls.dynamicPriceRadio.valueChanges
            .subscribe(() => {
                if (this.formGroup.controls.isDynamicPrice.value) {
                    this.enableDynamicLimits();
                    this.formGroup.markAsTouched();
                }
            });
        this.subs.add(dynamicPriceRadioSub);

        const dynamicPriceLimitValueRangeSub = this.formGroup.controls.dynamicPriceLimit.controls.valueRange.valueChanges
            .subscribe(() => this.formGroup.markAsTouched());
        this.subs.add(dynamicPriceLimitValueRangeSub);

        const dynamicPriceLimitValueRangeVWSub = this.formGroup.controls.dynamicPriceLimit.controls.valueRangeVW.valueChanges
            .subscribe(() => this.formGroup.markAsTouched());
        this.subs.add(dynamicPriceLimitValueRangeVWSub);

        const isBestMarketFilterSub = this.formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.isBestMarketFilter.valueChanges
            .subscribe((checked: boolean) => {
                const bestMarketFilterControl = this.formGroup.controls.dynamicPriceLimit.controls.manualLimit.controls.bestMarketFilter;
                checked && !this._globalDisable ? bestMarketFilterControl?.enable() : bestMarketFilterControl?.disable();
                this.formGroup.markAsTouched();
            });
        this.subs.add(isBestMarketFilterSub);

        const isManualModeLimitsSub = this.formGroup.controls.isManualModeLimits.valueChanges
            .subscribe((checked: boolean) => {
                const manualModeLimitsControl = this.formGroup.controls.manualModeLimits;
                checked && !this._globalDisable ? manualModeLimitsControl.enable() : manualModeLimitsControl.disable();
                this.formGroup.markAsTouched();
            });
        this.subs.add(isManualModeLimitsSub);

        // Ez-Form compatibility
        const absoluteBuySell = merge(
            this.formGroup.controls.priceLimit.controls.absoluteBuy.valueChanges,
            this.formGroup.controls.priceLimit.controls.absoluteSell.valueChanges
        ).subscribe(() => {
            this._checkParamRestartSession();
        });
        this.subs.add(absoluteBuySell);
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public sliderLabel(value: number) {
        return value + '%';
    }

    public submit() {
        if (!this.formGroup.valid) {
            return;
        }
        this._value = new PricesFlexposMapper().toDTO(this.formGroup.getRawValue(), this._value);
        this._resetInternalValues();
        this.valueChange.emit(this._value);
        this.onSubmit.emit();
    }

    private _resetInternalValues() {
        this.formGroup.reset(new PricesFlexposMapper().toFormGroup(this._value));
        this._paramRestartSession = false;
    }

    // Ez-Form compatibility
    private _checkParamRestartSession(): void {
        let hasChange: boolean = false;
        if (!isSame(this.formGroup.controls.priceLimit.controls.absoluteBuy.value, this.value.absoluteBuyTimeSerie)) {
            hasChange = true;
        }
        if (!isSame(this.formGroup.controls.priceLimit.controls.absoluteSell.value, this.value.absoluteSellTimeSerie)) {
            hasChange = true;
        }
        if (hasChange !== this._paramRestartSession) {
            this._paramRestartSession = hasChange;
            this.paramRestartSession.emit(this._paramRestartSession);
        }
    }

    private enableDynamicLimits(): void {
        if (this._globalDisable || !this.formGroup.controls.isDynamicPrice.value) {
            this.formGroup.controls.dynamicPriceLimit.disable();
            return;
        }
        this.formGroup.controls.dynamicPriceLimit.controls.dynamicPriceRadio.enable({ emitEvent: false });
        const dynamicType = this.formGroup.controls.dynamicPriceLimit.controls.dynamicPriceRadio.value;
        const dynamicFormGroup = this.formGroup.controls.dynamicPriceLimit.controls;
        switch (dynamicType) {
            case DynamicPriceType.isBidAsk:
                dynamicFormGroup.valueRange.enable();
                dynamicFormGroup.valueRangeVW.disable();
                dynamicFormGroup.manualLimit.disable();
                break;
            case DynamicPriceType.isVolWeigthedSpread:
                dynamicFormGroup.valueRange.disable();
                dynamicFormGroup.valueRangeVW.enable();
                dynamicFormGroup.manualLimit.disable();
                break;
            case DynamicPriceType.isManualStrategy:
                dynamicFormGroup.valueRange.disable();
                dynamicFormGroup.valueRangeVW.disable();
                dynamicFormGroup.manualLimit.enable();
                this.marketMakingPolicies = this._defaultMarketPolicies;
                if (dynamicFormGroup.manualLimit.controls.isBestMarketFilter.value) {
                    dynamicFormGroup.manualLimit.enable();
                } else {
                    dynamicFormGroup.manualLimit.controls.isBestMarketFilter.enable();
                }
                break;
            case DynamicPriceType.isLast:
            case DynamicPriceType.isVolumeWeightedAvgPrice:
            default:
                dynamicFormGroup.valueRange.disable();
                dynamicFormGroup.valueRangeVW.disable();
                dynamicFormGroup.manualLimit.disable();
                break;
        }
    }

    private _hasTimeOrVolumeGradientValueChanged(currentValue: (ITimeGradientItem | IVolumeGradientItem)[], property: "timeGradient" | "volumeGradient"): boolean {
        const gradientValue = this._value[property] as (ITimeGradientItem | IVolumeGradientItem)[] ?? [];
        if (currentValue?.length !== gradientValue.length)
            return true;
        return gradientValue?.some((value: ITimeGradientItem | IVolumeGradientItem, index: number) => {
            return !_.isEqual(value, currentValue[index]);
        });
    }

    private _hasMarketPoliciesValueChanged(currentValue: IMarketMakingPolicy[]): boolean {
        const gradientValue = this._value.dynamicPrice.marketMakingPolicies ?? [];
        if (currentValue?.length !== gradientValue.length)
            return true;
        return gradientValue?.some((value: IMarketMakingPolicy, index: number) => {
            return !_.isEqual(value, currentValue[index]);
        });
    }
}
