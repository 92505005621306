import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageMappingPreferenceInfo {
    static load(preferences: string | PageMappingPreferenceInfo): PageMappingPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageMappingPreferenceInfo>(preferences, PageMappingPreferenceInfo) as PageMappingPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    gridPointId: number | undefined;
}

@UserPreferenceClassDecorator
export class PageMappingPreference implements IPreferencePage {
    static load(preferences: string | PageMappingPreferenceInfo): PageMappingPreference {
        if (!preferences) {
            return new PageMappingPreference();
        }

        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageMappingPreferenceInfo.load(preferences);
                return new PageMappingPreference(v1.gridPointId);
            default:
                return new PageMappingPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    gridPointId: number | undefined;

    constructor(gridPointId: number | undefined = undefined) {
        this.gridPointId = gridPointId;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.gridPointId = undefined;
        }
    }
}

