// @ts-nocheck
import { IAuctionAccount, IAuctionDeliveryArea, IAuctionProduct } from '@eztypes/webapi';

export interface IAuctionParameters {
    readonly accounts: IAuctionAccount[];
    readonly product: IAuctionProduct;
    readonly deliveryArea: IAuctionDeliveryArea;
    readonly exchanges: AuctionExchangeParam[];
}

export class AuctionParameters implements IAuctionParameters {
    accounts: IAuctionAccount[];
    product: IAuctionProduct;
    deliveryArea: IAuctionDeliveryArea;
    exchanges: AuctionExchangeParam[];

    constructor(
        accounts?: IAuctionAccount[],
        auction?: IAuctionProduct,
        deliveryArea?: IAuctionDeliveryArea,
        exchanges?: AuctionExchangeParam[]
    ) {
        this.accounts = accounts;
        this.product = auction;
        this.deliveryArea = deliveryArea;
        this.exchanges = exchanges;
    }
}

export enum AuctionExchangeId {
    ETS = 'ETS',
    NP = 'NP',
    EXAA = 'EXAA',
}

export enum AuctionExchangeToken {
    NordPool = 'nordpool',
    ExpexSpot = 'epexspot',
    Exaa = 'exaa',
}

export interface AuctionExchangeParam {
    readonly id: AuctionExchangeId;
    readonly token: string;
    readonly name: string;
}
