import { IFiltersPosition } from '../../../modules/balancing/components/positions/filters/filters-position.component';
import { ITimezoneInfo } from "@eztypes/generic";
import { IMaterialType, IUnit, TimeGranularity } from "@eztypes/webapi";
import { IDashboardFormatOptions } from '../../../modules/dashboard/models/contracts';

export interface IPositionFilter {
    unit: IUnit;
    granularity: TimeGranularity;
    timezone: ITimezoneInfo;
    materialType: IMaterialType;
}

export interface IPositionPreset extends Omit<IDashboardFormatOptions, "timezone">, IPositionFilter {}

export interface SavedPositionPresets {
    name: string;
    preset: IPositionPreset;
}

export const defaultPreset: SavedPositionPresets[] = [];

export function initFiltersPositionFromPreset({ unit, granularity, timezone, materialType }: IPositionPreset): IFiltersPosition {
    return {
        unit,
        granularity,
        timezone,
        materialType
    } as IFiltersPosition;
}