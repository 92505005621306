import {
    IDecimalCurve,
    IMarketMakingPolicy,
    IPhysicalValue, ITimeGradientItem,
    IVolumeGradientItem,
    TimeGradientItem
} from '@eztypes/webapi';
import { FormControl, FormGroup } from '@angular/forms';

export enum DynamicPriceType {
    isBidAsk = 1,
    isVolWeigthedSpread = 2,
    isLast = 3,
    isVolumeWeightedAvgPrice = 4,
    isManualStrategy = 5,
}

export interface IFormPriceFlexPos {
    spread: IPhysicalValue | null;
    hardLimitsActivated: boolean;
    hardLimit: {
        hardLimitsMax: IDecimalCurve | null;
        hardLimitsMin: IDecimalCurve | null;
    };
    priceLimit: {
        absoluteBuy: IDecimalCurve | null;
        absoluteSell: IDecimalCurve | null;
        timeGradient: {
            isTimeGradient: boolean;
            timeGradientValue: TimeGradientItem[];
        };
        volumeGradient: {
            isVolumeGradient: boolean;
            volumeGradientValue: IVolumeGradientItem[]
        }
    };
    isDynamicPrice: boolean;
    isManualModeLimits: boolean;
    dynamicPriceLimit: {
        dynamicPriceRadio: DynamicPriceType;
        valueRange: {
            valueRangeBuyBid: number;
            valueRangeSellBid: number;
        };
        valueRangeVW: {
            valueRangeVWBuyBid: number;
            valueRangeVWSellBid: number;
        };
        manualLimit: {
            isBestMarketFilter: boolean;
            bestMarketFilter: {
                minVolumeTimeserie: IDecimalCurve | null;
                maxPriceDeltaTimeserie: IDecimalCurve | null;
            };
            marketMakingPolicies: IMarketMakingPolicy[];
        };
    };
    manualModeLimits: {
        manualLimitsMax: IDecimalCurve | null;
        manualLimitsMin: IDecimalCurve | null;
    };
}

export interface IFormGroupPriceFlexPos {
    spread: FormControl<IPhysicalValue | null>;
    hardLimitsActivated: FormControl<boolean>;
    hardLimit: FormGroup<{
        hardLimitsMin: FormControl<IDecimalCurve | null>;
        hardLimitsMax: FormControl<IDecimalCurve | null>;
    }>;
    priceLimit: FormGroup<{
        absoluteBuy: FormControl<IDecimalCurve | null>;
        absoluteSell: FormControl<IDecimalCurve | null>;
        timeGradient: FormGroup<{
            isTimeGradient: FormControl<boolean>;
            timeGradientValue: FormControl<ITimeGradientItem[]>;
        }>;
        volumeGradient: FormGroup<{
            isVolumeGradient: FormControl<boolean>;
            volumeGradientValue: FormControl<IVolumeGradientItem[]>
        }>
    }>;
    isDynamicPrice: FormControl<boolean>;
    dynamicPriceLimit: FormGroup<{
        dynamicPriceRadio: FormControl<DynamicPriceType>;
        valueRange: FormGroup<{
            valueRangeBuyBid: FormControl<number>;
            valueRangeSellBid: FormControl<number>;
        }>;
        valueRangeVW: FormGroup<{
            valueRangeVWBuyBid: FormControl<number>;
            valueRangeVWSellBid: FormControl<number>;
        }>;
        manualLimit: FormGroup<{
            isBestMarketFilter: FormControl<boolean>;
            bestMarketFilter: FormGroup<{
                minVolumeTimeserie: FormControl<IDecimalCurve | null>;
                maxPriceDeltaTimeserie: FormControl<IDecimalCurve | null>;
            }>;
            marketMakingPolicies: FormControl<IMarketMakingPolicy[]>;
        }>;
    }>;
    isManualModeLimits: FormControl<boolean>;
    manualModeLimits: FormGroup<{
        manualLimitsMax: FormControl<IDecimalCurve | null>;
        manualLimitsMin: FormControl<IDecimalCurve | null>;
    }>;
}