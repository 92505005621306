import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { REDIRECT_KEY } from "@eznergy/webapi";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {

    constructor(private _router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                const redirectKey: string = req.context.get(REDIRECT_KEY);
                if (redirectKey) {
                    this._router.navigateByUrl(redirectKey);
                    req.context.delete(REDIRECT_KEY);
                }
                return throwError(error);
            })
        );
    }
}
