// @ts-nocheck
import { AppDisplayGridType, AppLanguages, AppQuickDates, DayAlignType, TypeDisplayMenu } from "@core/application.model";
import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { HourCycle } from "@eznergy/components";
import { deserialize } from "@eznergy/core";
import { AppContractPreferences, AppContractPreferencesInfo } from "./contract-preferences.model";
import { IVersionContainer, VersionContainer } from "./core.model";
import { PagesPreference } from "./pages/pages-preferences.model";
import { IContractPagesPreferences } from "./pages/contract-page-preferences.model";
import { IShipperPagePreferences } from "./pages/shipper-page-preferences.model";

export class AppPreferencesInfo implements IVersionContainer {
    static load(preferences: string | AppPreferencesInfo): AppPreferencesInfo {
        if (typeof preferences === "string")
            return deserialize<AppPreferencesInfo>(preferences, AppPreferencesInfo) as AppPreferencesInfo;
        return preferences;
    }

    static loadV3(preferences: string | AppPreferencesInfo): AppPreferences {
        let v3: AppPreferencesInfo;
        if (typeof preferences === "string")
            v3 = deserialize<AppPreferencesInfo>(preferences, AppPreferencesInfo) as AppPreferencesInfo;
        else
            v3 = preferences;
        const contracts = v3.contracts.map((c) => AppContractPreferences.load(c));
        return new AppPreferences(v3.language, v3.tzActivate, v3.timezone, v3.hourCycle, v3.menuDisplay, v3.quickDate, v3.gridDisplayType,
            v3.alignDate, v3.rangeDays, v3.contract, contracts);
    }

    readonly version: number = 1;
    language: AppLanguages = AppLanguages.En;
    tzActivate: boolean = false;
    timezone: string;
    hourCycle: HourCycle;
    menuDisplay: TypeDisplayMenu = TypeDisplayMenu.Mini;
    quickDate: AppQuickDates = AppQuickDates.Day;
    gridDisplayType: AppDisplayGridType = AppDisplayGridType.Normal;
    alignDate: DayAlignType = DayAlignType.Middle;
    rangeDays: number = 3;
    contract: string;
    contracts: string[] | AppContractPreferencesInfo[] = [];

    readonly pages: string;
}


@UserPreferenceClassDecorator
export class AppPreferences implements IVersionContainer {

    public static shipperPagePreferences: IShipperPagePreferences;
    public static contractPagesPreferences: IContractPagesPreferences;
    public static currentContract: string;

    static load(preferences: string | AppPreferencesInfo): AppPreferences {
        if (preferences == null) {
            return new AppPreferences();
        }
        let version: number;
        if (typeof preferences == "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else {
            version = preferences.version;
        }
        switch (version) {
            case 2:
                const v2 = AppPreferencesInfo.load(preferences);
                const pagesPreferences = v2.pages ? PagesPreference.load(v2.pages) : new PagesPreference();
                this._setupPagePreferences(pagesPreferences);
                this.currentContract = v2.contract;
                const contractsV2 = v2.contracts.map((c) => AppContractPreferences.load(c));
                return new AppPreferences(v2.language, v2.tzActivate, v2.timezone, v2.hourCycle, v2.menuDisplay, v2.quickDate, v2.gridDisplayType,
                    v2.alignDate, v2.rangeDays, v2.contract, contractsV2);
            case 3:
                return AppPreferencesInfo.loadV3(preferences);
            default:
                return new AppPreferences();
        }
    }

    private static _setupPagePreferences(pagesPreferences: PagesPreference): void {
        const {
            tsEdit, tsCalculated, tsAdmin,
            map, overviewBalancing, deals, positions,
            nomOverview, nomRealtime, nomMessages, nomBatch,
            assetActivation, assetMessages, assetBatch,
            auctionOrders, auctionSchedules, continuousOverview,
            files, counterparties, mapping
        } = pagesPreferences;
        this.shipperPagePreferences = {
            map, overviewBalancing, deals, positions,
            nomOverview, nomRealtime, nomMessages, nomBatch,
            assetActivation, assetMessages, assetBatch,
            auctionOrders, auctionSchedules, continuousOverview,
            files, counterparties, mapping
        } as IShipperPagePreferences;
        this.contractPagesPreferences = { tsEdit, tsCalculated, tsAdmin } as IContractPagesPreferences;
    }

    reset(): void {
        this.alignDate = DayAlignType.Middle;
        this.rangeDays = 3;
        this.contract = undefined;
        this.contracts = [];
    }

    @UPPropertySave
    readonly version: number = 3;
    @UPPropertySave
    language: AppLanguages;
    @UPPropertySave
    tzActivate: boolean;
    @UPPropertySave
    timezone: string;
    @UPPropertySave
    hourCycle: HourCycle;
    @UPPropertySave
    menuDisplay: TypeDisplayMenu;
    @UPPropertySave
    quickDate: AppQuickDates;
    @UPPropertySave
    gridDisplayType: AppDisplayGridType;
    @UPPropertySave
    alignDate: DayAlignType;
    @UPPropertySave
    rangeDays: number;
    @UPPropertySave
    contract: string;
    @UPPropertySave
    contracts: AppContractPreferences[];
    displayGridSelector: boolean = true;
    get pages(): PagesPreference {
        const contract = this.contracts.find((c) => c.contractGuid === this.contract);
        const pagePreferences = contract?.shippers.find(({shipperId}) => shipperId == contract?.shipperId)?.pages;
        return pagePreferences ?? new PagesPreference();
    }
    gridDisplaySize: string;

    constructor(language: AppLanguages = AppLanguages.En, tzActivate: boolean = false, timezone: string = null, hourCycle: HourCycle = null, menuDisplay: TypeDisplayMenu = TypeDisplayMenu.Mini, quickDate: AppQuickDates = AppQuickDates.Day,
        gridDisplayType: AppDisplayGridType = AppDisplayGridType.Mini, alignDate: DayAlignType = DayAlignType.Middle, rangeDays: number = 3, contract?: string,
        contracts: AppContractPreferences[] = []) {
        this.language = language;
        this.tzActivate = tzActivate;
        this.timezone = timezone;
        this.hourCycle = hourCycle;
        this.menuDisplay = menuDisplay;
        this.quickDate = quickDate;
        this.gridDisplayType = gridDisplayType;
        this.alignDate = alignDate;
        this.rangeDays = rangeDays;
        this.contract = contract;
        this.contracts = contracts;
    }
}
