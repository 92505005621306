<span class="text">{{text}}</span>
<span *ngIf="hasTzOverride && !onlyDate && !empty" class="d-override">*</span>
<ez-icon [name]="'info-circle'" [class.hidden]="empty || onlyDate" [class.opened]="infoPopover.open" ez-overlay-origin
    #infoOrigin="EzOverlayOrigin"></ez-icon>

<ez-popover #infoPopover [origin]="infoOrigin" [actionType]="'over'" [alignH]="'right'" [alignInnerH]="true">
    <div class="tz">
        Use
        <ng-container *ngIf="tz; else localTz">
            {{tz?.info?.name}}
        </ng-container>
        <ng-template #localTz>
            local
        </ng-template>
        time zone
    </div>
    <div *ngIf="grid != null && !hasTzOverride">
        From
        <span>{{grid?.name}}</span>
    </div>
</ez-popover>
