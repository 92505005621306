// @ts-nocheck
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "gas-icon",
    template: '<ez-icon name="burn"></ez-icon>',
    styleUrls: ['./gas.icon.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class GasIcon {

}
