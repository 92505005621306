// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Logger } from '@core/logger';
import { DateRangeType } from '@enums/date-range-type.enum';
import { EzDatePipe } from '@eznergy/components';
import { EzNotificationService } from '@eznergy/toaster';
import { ApiService } from '@eznergy/webapi';
import { DateRange, DateTime, DayUnit, IDateRange, TimeUnit, TimeZone } from '@eztypes/generic';
import { AuctionScheduleForecast, AuctionScheduleMode, IAuctionDeliveryArea, IAuctionExchangePortfolio, IAuctionExchangeProducts, IAuctionScheduledBatch, ICalendar, IOrganisation } from '@eztypes/webapi';
import { FormBase } from '@forms/core/form-base';
import { TranslateService } from '@ngx-translate/core';
import { IScheduleBase } from '../../../models/schedule';
import { ApplicationService } from '@services/application.service';
import { FormSchedulesBaseComponent } from '../form-schedule-base/form-schedule-base.component';

@Component({
    selector: 'auction-schedule-forecasts-form',
    templateUrl: './auction-schedule-forecasts.component.html',
    styleUrls: ['./auction-schedule-forecasts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AuctionSchedulesForecastsForm extends FormBase {

    @ViewChild(FormSchedulesBaseComponent, { static: true }) readonly formBase: FormSchedulesBaseComponent;
    @Output() validated: EventEmitter<IAuctionScheduledBatch[]> = new EventEmitter<IAuctionScheduledBatch[]>();

    @Input()
    get value(): IAuctionScheduledBatch {
        return this._value;
    }
    set value(value: IAuctionScheduledBatch) {
        if (this._value !== value) {
            this._value = value;
            this._initSchedule();
            this._cdr.markForCheck();
        }
    }
    private _value: IAuctionScheduledBatch;

    @Input()
    get deliveryDate(): DateTime {
        return this._deliveryDate;
    }
    set deliveryDate(value: DateTime) {
        if (this._deliveryDate !== value) {
            this._deliveryDate = value;
            this._cdr.markForCheck();
        }
    }
    private _deliveryDate: DateTime;

    @Input()
    get deliveryArea(): IAuctionDeliveryArea {
        return this._deliveryArea;
    }
    set deliveryArea(value: IAuctionDeliveryArea) {
        if (this._deliveryArea !== value) {
            this._deliveryArea = value;
            this._cdr.markForCheck();
        }
    }
    private _deliveryArea: IAuctionDeliveryArea;

    @Input()
    get shipper(): IOrganisation {
        return this._shipper;
    }
    set shipper(value: IOrganisation) {
        if (this.shipper !== value) {
            this._shipper = value;
            this._cdr.markForCheck();
        }
    }
    private _shipper: IOrganisation;

    @Input()
    set exchangeProducts(values: IAuctionExchangeProducts[]) {
        if (this._exchangesProducts !== values) {
            this._exchangesProducts = values;
            this._cdr.markForCheck();
        }
    }
    get exchangeProducts(): IAuctionExchangeProducts[] {
        return this._exchangesProducts;
    }
    private _exchangesProducts: IAuctionExchangeProducts[];

    @Input()
    set portfolios(values: IAuctionExchangePortfolio[]) {
        if (this._portfolios !== values) {
            this._portfolios = values;
            this._cdr.markForCheck();
        }
    }
    get portfolios(): IAuctionExchangePortfolio[] {
        return this._portfolios;
    }
    private _portfolios: IAuctionExchangePortfolio[];

    set selectedRadio(value: AuctionScheduleMode) {
        this._selectedRadio = value;
    }
    get selectedRadio(): AuctionScheduleMode {
        return this._selectedRadio;
    }
    private _selectedRadio: AuctionScheduleMode;

    get timezone(): TimeZone {
        return this._timezone;
    }
    private _timezone: TimeZone;

    get calendar(): ICalendar {
        return this.scheduleBaseValue && this.scheduleBaseValue.portfolio ? this.scheduleBaseValue.portfolio.area.calendar : undefined;
    }

    get porfolioSelected(): boolean {
        return this._porfolioSelected;
    }
    private _porfolioSelected: boolean;

    get onValidate(): boolean {
        return this._onValidate;
    }
    private _onValidate: boolean;

    set sendOnDate(value: DateTime) {
        this._sendOnDate = value;
    }
    get sendOnDate(): DateTime {
        return this._sendOnDate;
    }
    private _sendOnDate: DateTime;

    set periodDate(value: IDateRange) {
        this._periodDate = value;
    }
    get periodDate(): IDateRange {
        return this._periodDate;
    }
    private _periodDate: IDateRange;

    scheduleBaseValue: IScheduleBase;
    dayBefore: DateTime;
    intraday: DateTime;
    sendPeriodDate: IDateRange;
    sendPeriodFrom: number;
    sendPeriodTo: number;
    sendPeriodTime: DateTime;
    day: DateTime;

    readonly Mode = AuctionScheduleMode;
    readonly dateRangeType = DateRangeType;

    private _portfolioCache: IAuctionExchangePortfolio;

    constructor(private readonly _cdr: ChangeDetectorRef,
        private readonly _api: ApiService,
        private readonly _logger: Logger,
        private readonly _appSvc: ApplicationService,
        private readonly _datePipe: EzDatePipe,
        private readonly _translate: TranslateService,
        private readonly _notifSvc: EzNotificationService
    ) {
        super();
    }

    formBaseValueChanged(value: IScheduleBase): void {
        this._porfolioSelected = value && value.portfolio ? true : false;
        if (this._porfolioSelected) {
            if (value.portfolio != this._portfolioCache) {
                this._portfolioCache = value.portfolio;
                this._initForm();
            }
        }
    }

    onSenPeriodFromChange(value: number): void {
        let from = this.day.addDays(-value).startOf(TimeUnit.Days).addHours(9);
        let to = this.day.addDays(-this.sendPeriodTo).startOf(TimeUnit.Days);
        this.sendPeriodDate = new DateRange(from, to);
    }

    periodDateChange(period: IDateRange): void {
        this.periodDate = new DateRange(period.from.startOf(TimeUnit.Days), period.to.startOf(TimeUnit.Days));
    }

    formSubmit(): void {
        if (this.formBase.submit()) {
            this._onValidate = true;

            if (this._value) {
                this._value.batchType = this.scheduleBaseValue.batchType;
                this._value.scheduledAt = new DateTime(this.sendOnDate, this._value.portfolio.area.calendar.ianaName);

                this._api.auctions.schedules.update(this._appSvc.contract.id, this._shipper, this._value.portfolio, this._value).subscribe(
                    (result) => {
                        this._onValidate = false;
                        this._translate.get('pages.auction-schedules.component.forms.success-save').subscribe((text) => {
                            this._notifSvc.success(text);
                        });
                        this.validated.emit([result]);
                        this._cdr.detectChanges();
                    },
                    (error: Error) => {
                        this._onValidate = false;
                        this._logger.error("Request Update Forecast Schedule Failed", error);
                        this._cdr.detectChanges();
                    }
                );
            } else {
                const batch: AuctionScheduleForecast = new AuctionScheduleForecast();
                batch.batchType = this.scheduleBaseValue.batchType;
                batch.sendMode = this.selectedRadio;
                batch.calendarId = this.calendar.id;

                switch (this.selectedRadio) {
                    case AuctionScheduleMode.On:
                        batch.scheduleHour = this.sendOnDate.hours;
                        batch.scheduleMinute = this.sendOnDate.minutes;
                        batch.executedAt = new DateTime(this.sendOnDate, this.calendar.ianaName);
                        break;
                    case AuctionScheduleMode.DayBefore:
                        batch.scheduleHour = this.dayBefore.hours;
                        batch.scheduleMinute = this.dayBefore.minutes;
                        batch.executedAt = this.dayBefore;
                        break;
                    case AuctionScheduleMode.Day:
                        batch.scheduleHour = this.intraday.hours;
                        batch.scheduleMinute = this.intraday.minutes;
                        batch.executedAt = this.intraday;
                        break;
                    case AuctionScheduleMode.Period:
                        batch.scheduleHour = this.sendPeriodTime.hours;
                        batch.scheduleMinute = this.sendPeriodTime.minutes;
                        batch.periodFromDay = -this.sendPeriodFrom;
                        batch.periodToDay = -this.sendPeriodTo;
                        batch.executedAt = this.sendPeriodTime;
                        break;
                    default:
                        throw new Error("Unknown Auction Schedule send mode.");
                }

                this._api.auctions.schedules.createForecast(this._appSvc.contract.id, this._shipper, this.scheduleBaseValue.portfolio, this._deliveryArea, this.periodDate.from, this.periodDate.to, this.scheduleBaseValue.product, batch).subscribe(
                    (results) => {
                        this._onValidate = false;
                        this._translate.get('pages.auction-schedules.component.forms.success-save').subscribe((text) => {
                            this._notifSvc.success(text);
                        });
                        this.validated.emit(results);
                        this._cdr.detectChanges();
                    },
                    (error: Error) => {
                        this._onValidate = false;
                        this._logger.error("Request Create Forecast Schedule Failed", error);
                        this._cdr.detectChanges();
                    }
                );
            }
        }
    }

    private _initForm(): void {
        this._timezone = TimeZone.create(this.calendar.ianaName);
        const now = new DateTime(undefined, this._timezone.name).startOf(TimeUnit.Days);
        this.day = now;
        this.sendPeriodTime = this.day.startOf(TimeUnit.Days).addHours(9);
        const nextMonday = now.nextDay(DayUnit.Monday, now.dayOfWeek === DayUnit.Monday ? 1 : undefined).addHours(this.calendar.offset, true);
        const followingSunday = nextMonday.nextDay(DayUnit.Sunday).startOf(TimeUnit.Days).addHours(this.calendar.offset, true);
        this.periodDate = new DateRange(nextMonday, followingSunday);
        this.intraday = new DateTime(undefined, this.calendar.ianaName).startOf(TimeUnit.Days).addHours(this.calendar.offset, true);
        this.dayBefore = this.intraday.addDays(-1).startOf(TimeUnit.Days).addHours(9);
        this.intraday = this.intraday.addHours(9);
        this.sendOnDate = this.intraday;
        this.selectedRadio = AuctionScheduleMode.On;
    }

    private _initSchedule(): void {
        if (this.value) {
            this._sendOnDate = this.value.scheduledAt;
            if (this.value.deliveryDate)
                this.periodDate = new DateRange(this.value.deliveryDate.startOf(TimeUnit.Days), this.value.deliveryDate.endOf(TimeUnit.Days));
            this.selectedRadio = AuctionScheduleMode.On;
        }
    }
}
