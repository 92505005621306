// @ts-nocheck
import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ez-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[@enterLeave]': '_state'
    },
    animations: [
        trigger('enterLeave', [
            transition('void => enter', [
                style({ height: 0, opacity: 0 }),
                animate('300ms ease-in', style({ height: '*', opacity: 1 }))
            ]),
            transition('enter => void', [
                style({ height: '*', opacity: 1 }),
                animate('300ms ease-out', style({ height: 0, opacity: 0 }))
            ])
        ])
    ]
})
export class EzBanner implements OnInit, OnDestroy {

    @Output() onClose = new EventEmitter();

    @Input() closable: boolean;

    @Input()
    get severity(): string {
        return this._severity;
    }
    set severity(value: string) {
        if (this.severity != value) {
            this._severity = value;
            this._initSeverity();
        }
    }
    private _severity: string = "";

    @HostBinding("class.has-icon")
    get hasIcon(): boolean {
        return this.iconName != null
    }

    get iconName(): string {
        return this._iconName;
    }
    private _iconName: string;

    constructor(private readonly _el: ElementRef<HTMLElement>, private readonly _renderer: Renderer2) { }

    ngOnInit(): void {
        this._state = "enter";
    }

    ngOnDestroy(): void {
        this._state = "void";
    }

    _state: string = "void";

    private _initSeverity(): void {
        this._renderer.removeClass(this._el.nativeElement, "s-error");
        this._renderer.removeClass(this._el.nativeElement, "s-warning");
        this._renderer.removeClass(this._el.nativeElement, "s-info");
        this._renderer.removeClass(this._el.nativeElement, "s-success");
        this._renderer.removeClass(this._el.nativeElement, "s-primary");
        switch (this.severity) {
            case "error":
                this._renderer.addClass(this._el.nativeElement, "s-error");
                this._iconName = 'exclamation-circle';
                break;
            case "warning":
                this._renderer.addClass(this._el.nativeElement, "s-warning");
                this._iconName = 'exclamation-triangle';
                break;
            case "info":
                this._renderer.addClass(this._el.nativeElement, "s-info");
                this._iconName = 'info-circle';
                break;
            case "success":
                this._renderer.addClass(this._el.nativeElement, "s-success");
                this._iconName = 'check-circle';
                break;
            case "primary":
                this._renderer.addClass(this._el.nativeElement, "s-primary");
                break;
        }
    }

    onCloseClick() {
        this.onClose.emit();
    }
}
