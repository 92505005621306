// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from "@angular/core";
import { MaterialCode } from "@eztypes/webapi";

@Component({
    selector: 'commodity-icon',
    templateUrl: './commodity.icon.html',
    styleUrls: ["./commodity.icon.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CommodityIcon {
    readonly codes = MaterialCode;

    @Input()
    get value(): MaterialCode {
        return this._value;
    }
    set value(value: MaterialCode) {
        if (this.value !== value) {
            this._value = value;
            this._cdr.markForCheck();
        }
    }
    private _value: MaterialCode;

    constructor(private readonly _cdr: ChangeDetectorRef) { }
}
