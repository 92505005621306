// @ts-nocheck
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { Subscriptions } from '@core';
import { AppPages, RouterService } from '@services/router.service';
import { debounceTime } from 'rxjs';

export type Outlet = "popup" | "aside";


@Directive({
    selector: '[routerPage]'
})
export class EzRouterDirective {

    @Input()
    get routerPage(): AppPages {
        return this._routerPage;
    }
    set routerPage(value: AppPages) {
        if (value !== this.routerPage) {
            this._routerPage = value;
            this._refreshUrl();
        }
    }
    private _routerPage: AppPages;

    @Input()
    get routerParams(): { [key: string]: string } {
        return this._routerParams;
    }
    set routerParams(value: { [key: string]: string }) {
        if (value !== this.routerParams) {
            this._routerParams = value;
            this._refreshUrl();
        }
    }
    private _routerParams: { [key: string]: string };

    @Input()
    get routerQuery(): { [key: string]: string } {
        return this._routerQuery;
    }
    set routerQuery(value: { [key: string]: string }) {
        if (value !== this.routerQuery) {
            this._routerQuery = value;
            this._refreshUrl();
        }
    }
    private _routerQuery: { [key: string]: string };

    @Input()
    get queryParamsHandling(): QueryParamsHandling {
        return this._queryParamsHandling;
    }
    set queryParamsHandling(value: QueryParamsHandling) {
        if (value !== this.queryParamsHandling) {
            this._queryParamsHandling = value;
            this._refreshUrl();
        }
    }
    private _queryParamsHandling: QueryParamsHandling;

    @Input()
    get routerOutlet(): Outlet {
        return this._outlet;
    }
    set routerOutlet(value: Outlet) {
        if (value !== this.routerOutlet) {
            this._outlet = value;
            this._refreshUrl();
        }
    }
    private _outlet: Outlet;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        if (value !== this.disabled) {
            this._disabled = value;
            this._refreshUrl();
        }
    }
    private _disabled: boolean;

    @Input()
    get routerLinkActiveExact(): boolean {
        return this._routerLinkActiveExact;
    }
    set routerLinkActiveExact(value: boolean) {
        if (value !== this._routerLinkActiveExact) {
            this._routerLinkActiveExact = value;
            this._routerLinkActived();
        }
    }
    private _routerLinkActiveExact: boolean = true;

    @Input()
    get routerLinkActive(): string {
        return this._routerLinkActive;
    }
    set routerLinkActive(value: string) {
        if (value !== this.routerLinkActive) {
            this._routerLinkActive = value;
            this._routerLinkActived();
        }
    }
    private _routerLinkActive: string;

    private readonly _subs: Subscriptions = new Subscriptions();

    constructor(private readonly _el: ElementRef<HTMLElement>,
        private readonly _routerSvc: RouterService,
        private readonly _renderer: Renderer2) {
    }

    private _eventClick: (event: MouseEvent) => void;

    ngOnInit(): void {
        this._refreshUrl();
    }

    ngOnDestroy(): void {
        this._removeEventListener();
        this._subs.clearAll();
    }

    private _createEventListener(): void {
        if (this._el && this._el.nativeElement) {
            this._eventClick = (event: MouseEvent) => this._routeClick(event);
            this._el.nativeElement.addEventListener('click', this._eventClick);
        }
    }

    private _removeEventListener(): void {
        if (this._el && this._el.nativeElement) {
            this._el.nativeElement.removeEventListener('click', this._eventClick);
        }
    }

    private _refreshUrl(): void {
        if (this._el && this._el.nativeElement) {
            this._removeEventListener();
            if (!this._disabled) {
                if (this._el.nativeElement.nodeName === "A") {
                    let url: string;
                    switch (this._outlet) {
                        case 'popup':
                            url = '#' + this._routerSvc.getUrlPopup(this._routerPage, this._routerParams, this._routerQuery);
                            break;
                        case 'aside':
                            url = '#' + this._routerSvc.getUrlAside(this._routerPage, this._routerParams);
                            break;
                        default:
                            url = this._routerSvc.getFullUrl(this._routerPage, { params: this.routerParams, queryParams: this.routerQuery, queryParamsHandling: this.queryParamsHandling });
                            break;
                    }
                    (this._el.nativeElement as HTMLAnchorElement).href = url;
                }
                this._createEventListener();
            } else {
                (this._el.nativeElement as HTMLAnchorElement).href = "javascript:void(0)";
            }
            this._routerLinkActived();
        }
    }

    private _routeClick(event: MouseEvent): void {

        if (this._routerPage) {
            event.stopPropagation();
            event.preventDefault();
            event.returnValue = false;

            const inNewTab = event.ctrlKey;
            switch (this._outlet) {
                case "popup":
                    this._routerSvc.navigateToPopup(this._routerPage, this._routerParams, this._routerQuery, true);
                    break;
                case "aside":
                    this._routerSvc.navigateToAside(this._routerPage, this._routerParams);
                    break;
                default:
                    this._routerSvc.navigateTo(this._routerPage, { inNewTab, params: this.routerParams, queryParams: this.routerQuery, queryParamsHandling: this.queryParamsHandling });
                    break;
            }
        }
    }

    private _routerLinkActived(): void {
        const key = "router-actived";
        this._subs.clearSub(key);
        if (this.routerLinkActive != null) {
            const sub = this._routerSvc.paramsChange
            .pipe(debounceTime(0))
            .subscribe(() => {
                const classList: string[] = this.routerLinkActive.split(' ');
                const isActive = this._routerSvc.isActivePage(this._routerPage, { exact: this._routerLinkActiveExact, params: this.routerParams, queryParams: this.routerQuery, queryParamsHandling: this.queryParamsHandling });
                if (isActive) {
                    classList.forEach((className) => {
                        this._renderer.addClass(this._el.nativeElement, className);
                    });
                } else {
                    classList.forEach((className) => {
                        this._renderer.removeClass(this._el.nativeElement, className);
                    });
                }
            });
            this._subs.push(key, sub);
        }
    }

}
