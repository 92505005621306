// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from "@angular/core";
import { ApiService } from '@eznergy/webapi';
import { UserRight } from '@eztypes/webapi';
import { ApplicationService } from "@services/application.service";
import { RouterService } from '@services/router.service';

@Component({
    selector: 'admin-right',
    templateUrl: './admin-right.component.html',
    styleUrls: ['./admin-right.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AdminRight {

    userRight: UserRight[] = [];

    rights = UserRight;

    constructor(private _appSvc: ApplicationService, private _apiSvc: ApiService, private _routerSvc: RouterService, private _cdr: ChangeDetectorRef) {
        this._appSvc.contractChange.subscribe(() => {
            this.userRight = this._appSvc.rights;
            this._cdr.detectChanges();
        });
    }

    changeUserRight(value: UserRight[]): void {
        if (this._appSvc.user.isAdmin) {
            this._appSvc.setContract(this._appSvc.contract, value);
        }
    }

    refreshPage(): void {
        this._routerSvc.reload();
    }

    reInitRight(): void {
        this._apiSvc.contract.rights(this._appSvc.contract.id).subscribe((rights: UserRight[]) => {
            this._appSvc.setContract(this._appSvc.contract, rights);
        });
    }
}
