// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, OnDestroy, Renderer2, ViewEncapsulation } from "@angular/core";
import { ActivationEnd, ActivationStart, Event as RouterEvent, NavigationCancel, NavigationEnd, PRIMARY_OUTLET, Router } from "@angular/router";
import { AppUser } from "@core/app-user.model";
import { Logger } from '@core/logger';
import { BasePagePopup } from '@core/pages';
import { Subscriptions } from "@core/subscriptions";
import { ApplicationService } from "@services/application.service";
import { EventService } from "@services/event.service";
import { IPopup, PopupOptions, PopupService } from '@services/popup.services';
import { IUpdateData, SWService } from "@services/SWUpdate.service";
import * as _ from "lodash";
import { combineLatest , forkJoin, interval, Observable, of } from "rxjs";
import { Timespan } from '@eztypes/generic';
import { MatIconRegistry } from "@angular/material/icon";
import { UserConnectedInfosService, OauthService, ApiService } from "@eznergy/webapi";
import { ActivatedRoute } from '@angular/router';
import { IContract, IUser, User } from "@eztypes/webapi";
import { catchError, retry, switchMap} from "rxjs/operators";
import { orderBy } from "@eznergy/core";
import { AppPages, RouterService } from "@services/router.service";
import { MultiLoader } from "@core/multiloader";
import { CacheService, KeyCache } from "@services/cache.service";

@Component({
    selector: "main-cmp",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent implements OnDestroy {
    popupPage: boolean = false;

    isFullScreenPage: boolean = false;

    @HostBinding("class.app-notif-show")
    get hasAppNotif(): boolean {
        return this.updateData != null;
    }
    updateData: IUpdateData;

    get onLoading(): boolean {
        return this._onLoading;
    }
    private _onLoading: boolean = false;

    private _isFirstActivationEnd: boolean = false;

    openPopup: boolean = false;
    private _openPopupExternal: boolean;
    private _popupExternal: IPopup[] = [];
    get pagePopup(): BasePagePopup {
        return this._popupComponent;
    }
    private _popupComponent: BasePagePopup;
    currentUser: AppUser;

    isAsideActive: boolean;

    get displayHelp(): boolean {
        return this._displayHelp;
    }
    private _displayHelp: boolean;

    abControlDisplay: boolean = false;

    private readonly _subs: Subscriptions = new Subscriptions();

    public readonly loader: MultiLoader = new MultiLoader();

    constructor(
        private readonly _routerSvc: RouterService,
        private readonly _router: Router,
        private readonly swservice: SWService,
        private readonly _logger: Logger,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _popupSvc: PopupService,
        private readonly _appSvc: ApplicationService,
        private readonly _renderer: Renderer2,
        private readonly _sizeChangeSvc: EventService,
        private registry: MatIconRegistry,
        private readonly _userConnectedInfos: UserConnectedInfosService,
        private readonly _oauthService: OauthService,
        private _route: ActivatedRoute,
        private readonly _api: ApiService,
        private readonly _cacheSvc: CacheService
    ) {
        this.registry.registerFontClassAlias('outlined', 'material-symbols-outlined mat-ligature-font');
    }

    ngOnInit(): void {
        const key = "load-user-contract-pref";
        this.loader.push(key)
        const preferencesObs = this._api.auth.getAppPreference().pipe(
            retry(2),
            catchError((err: Error) => {
                this._logger.error("Error get user preferences", err)
                return of(undefined);
            })
        )
        const contractsObs = this._api.contract.contracts().pipe(
            catchError((err: Error) => {
                this._logger.error("Error getting contracts", err)
                throw err;
            }
        ));
        const userAuthentificatedObs = combineLatest([this._userConnectedInfos.userData$, this._userConnectedInfos.isAuthenticated$]);
        this._subs.push("user-data", userAuthentificatedObs
        .subscribe(([ _, isAuthenticated]) => {
            if (!isAuthenticated && this._appSvc.user) {
                this._appSvc.setUser(undefined);
                return;
            }
        }));
        this._subs.push("check-auth", this._oauthService.checkAuth()
        .pipe(
            switchMap((loginResponse: LoginResponse) => {
                if (loginResponse.isAuthenticated)
                    return forkJoin({contractsData: contractsObs, preferences: preferencesObs, userData: of(loginResponse.userData as IUser)});
                throw new Error("Not authenticated");
            })
        )
        .subscribe(
        ({contractsData, preferences, userData}) => {
            if (!contractsData.length)
                throw new Error("No contracts");
            const contracts = orderBy(contractsData, (c: IContract) => c.shortName);
            const user = new User(userData.name, false, userData.name, preferences);
            this._appSvc.setUser(user, contracts);
            const returnUrl: string | undefined  = this._cacheSvc.get(KeyCache.ReturnUrl);
            this.loader.remove(key);
            if (returnUrl) {
                this._cacheSvc.remove(KeyCache.ReturnUrl);
                this._routerSvc.navigateByUrl(returnUrl);
            } else {
                this._routerSvc.navigateTo(AppPages.Dashboard, { contractId: contracts[0]!.contractGuid });
            }
        },
        (err) => {
            this.loader.remove(key);
            this._logger.error(err);
            this._oauthService.login();
        }));
        const subUser = this._appSvc.userChange.subscribe((user) => {
            if (user) {
                this.currentUser = user;
                this._checkBgApp();
            }
        });
        this._subs.push("user-change", subUser);
        const subHelp = this._appSvc.hasDisplayHelpChange.subscribe((display) => {
            this._displayHelp = display;
            this._cdr.detectChanges();
        });
        this._subs.push("display-help", subHelp);
        const subEventRoute = this._router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });
        this._subs.push("event-route", subEventRoute);

        const subAppVersion = this.swservice.alertNewVersion.subscribe((data: IUpdateData) => {
            if (this.currentUser == null) {
                this.swservice.activateNewVersion();
            } else {
                this.updateData = data;
                const keyAppUpdate = "app-update";
                if (this.updateData.forced && !this._subs.has(keyAppUpdate)) {
                    const subUpdateVersion = interval(Timespan.fromSeconds(1).totalMilliseconds).subscribe(() => {
                        this._cdr.detectChanges();
                    });
                    this._subs.push(keyAppUpdate, subUpdateVersion);
                }
                this._cdr.detectChanges();
            }
            this._logger.debug("service worker: new version application");
        });
        this._subs.push("app-version", subAppVersion);
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    deactivatePage(): void {
        _.forEach(this._popupExternal, (popup: IPopup) => {
            popup.close();
        });
        this._popupExternal = [];
    }

    activatePopup(cpt: BasePagePopup): void {
        this._openPopupExternal = false;
        this._popupComponent = cpt;
        this._cdr.detectChanges();
        this.openPopup = true;
    }

    beforePopupClose: () => Observable<boolean> | Promise<boolean> | boolean = () => { return this._beforePopupClose(); };
    private _beforePopupClose(): Observable<boolean> | Promise<boolean> | boolean {
        return this._popupComponent && this._popupComponent.canDeactivate ? this._popupComponent.canDeactivate() : true;
    }

    closePopup(): void {
        this._router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: "merge" });
    }

    clickOpenPopup(): void {
        this._openPopupExternal = true;
        this.closePopup();
    }

    deactivatePopup(): void {
        this.openPopup = false;
        if (this._openPopupExternal) {
            this._openExternalPopup();
        }
        this._popupComponent = undefined;
    }

    asideTransitionEnd(): void {
        this._sizeChangeSvc.widthChanged();
    }

    private _openExternalPopup(): void {
        let url = this._popupComponent.urlPage;
        let title = this._popupComponent.titlePopup;

        let option = new PopupOptions(800, 600);
        let popup = this._popupSvc.createPopup(url, title, option);
        this._popupExternal.push(popup);
        popup.open();
        this._openPopupExternal = false;
    }

    private navigationInterceptor(event: RouterEvent): void {
        if (event instanceof ActivationStart) {
            this._isFirstActivationEnd = true;
            if (event.snapshot.outlet === PRIMARY_OUTLET) {
                this._onLoading = true;
                this._cdr.detectChanges();
            }
        }
        if (event instanceof NavigationEnd) {
            const newAsideValue = event.url.indexOf("aside") > -1;
            if (newAsideValue !== this.isAsideActive) {
                this.isAsideActive = newAsideValue;
            }
        }
        if (event instanceof ActivationEnd) {
            if (this._isFirstActivationEnd) {
                this.popupPage = _.some(event.snapshot.pathFromRoot, (d) => d.data.popup === true);
                this.isFullScreenPage = _.some(event.snapshot.pathFromRoot, (d) => d.data.fullscreen === true);
                this._isFirstActivationEnd = false;
            }
        }
        if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
            this._onLoading = false;
            this._cdr.detectChanges();
        }
    }

    private _checkBgApp(): void {
        if (this.currentUser == null) {
            this._renderer.addClass(document.body, "bg-img");
        } else {
            this._renderer.removeClass(document.body, "bg-img");
        }
    }

    onUpdateVersion() {
        this.swservice.activateNewVersion();
    }

    @HostListener('document:keydown.control.i', ['$event'])
    toggleWindow(event: KeyboardEvent) {
        event.stopImmediatePropagation();
        event.preventDefault();
        event.returnValue = false;
        this.abControlDisplay = !this.abControlDisplay;
    }
}
