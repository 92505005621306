// @ts-nocheck
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
    OnDestroy,
    ViewChild
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Logger, MultiLoader, Subscriptions } from "@core";
import { UnitFamiliesUmScore } from '@enums/unit-families-umscore.enum';
import { EzNotificationService } from '@eznergy/toaster';
import { ApiService, AutoTradingHub, IHubResult } from '@eznergy/webapi';
import { Exchange, ICalendar, IEarlyTerminationRules, IFlexPricesParameters, IFlexStrategy, IFlexVolumesParameters, IGenerationPricesParameters, IGenerationStrategy, IGenerationVolumesParameters, IPhysicalValue, IPortfolio, IPositionPricesParameters, IPositionStrategy, IPositionVolumesParameters, ISession, ITimeAndLocationRules, IUnit, IUnitFamily, PhysicalValue, Product, RunState, SessionType, ThrottlingLimit, UserRight } from "@eztypes/webapi";
import { AtPricesFlexPosForm } from './prices/prices-flexpos.component';
import { AtTimeAndLocationForm } from './time-and-location/time-and-location.component';
import { AtVolumePositionFlexForm } from './volumes/volume-position-flex.component';
import { FormBase } from '@forms/core/form-base';
import { HubConnectionState } from '@microsoft/signalr';
import { ApplicationService } from '@services/application.service';
import { DataService } from '@services/data.service';
import { Observable, Subject, Subscription } from "rxjs";
import { AtEarlyTerminationForm } from './early-termination/early-termination.component';
import { AtStrategyForm } from './strategy/strategy.component';

export type TabForm =
    | "Products & Contracts"
    | "Strategy"
    | "Volume"
    | "Prices"
    | "Early termination";

type ParametersType =
    | ITimeAndLocationRules
    | IPositionVolumesParameters
    | IFlexVolumesParameters
    | IGenerationVolumesParameters
    | IPositionPricesParameters
    | IFlexPricesParameters
    | IGenerationPricesParameters
    | IEarlyTerminationRules
    | IFlexStrategy
    | IPositionStrategy
    | IGenerationStrategy;

@Component({
    selector: "at-parameters-form",
    templateUrl: "./parameters.component.html",
    styleUrls: ["./parameters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AtParametersForm implements OnDestroy {
    @ViewChild("timeAndLocationForm")
    timeAndLocationForm: AtTimeAndLocationForm;
    @ViewChild("strategyForm") strategyForm: AtStrategyForm;
    @ViewChild("volumePosFlexForm") volumePosFlexForm: AtVolumePositionFlexForm;
    @ViewChild("pricePosFlexForm") pricePosFlexForm: AtPricesFlexPosForm;
    @ViewChild("terminationForm") terminationForm: AtEarlyTerminationForm;

    get form(): FormBase {
        if (this.timeAndLocationForm) return this.timeAndLocationForm;
        else if (this.strategyForm) return this.strategyForm;
        else if (this.volumePosFlexForm) return this.volumePosFlexForm;
        else if (this.pricePosFlexForm) return this.pricePosFlexForm;
        else if (this.terminationForm) return this.terminationForm;
    }

    @HostListener("window:beforeunload", ["$event"])
    beforeunload(event: Event) {
        if (this._isFormChanged) event.returnValue = true;
    }

    @Input()
    get session(): ISession {
        return this._session;
    }
    set session(value: ISession) {
        if (this.session !== value) {
            this._session = value;
            this._initSession();
            this._cdr.markForCheck();
        }
    }
    _session: ISession;

    @Input()
    get tab(): TabForm {
        return this._tab;
    }
    set tab(value: TabForm) {
        if (this.tab !== value) {
            this._tab = value;
            this.onInitTab();
            this._cdr.markForCheck();
        }
    }
    private _tab: TabForm;

    objectTab: ParametersType;

    private _objectTabUpdate: ParametersType;

    portfolios: IPortfolio[];

    get units(): IUnit[] {
        return this._units;
    }
    set units(value: IUnit[]) {
        if (this.units !== value) {
            this._units = value;
            this._cdr.markForCheck();
        }
    }
    private _units: IUnit[];

    get calendars(): ICalendar[] {
        return this._calendars;
    }
    set calendars(value: ICalendar[]) {
        this._calendars = value;
    }
    private _calendars: ICalendar[];

    private get _isFormChanged(): boolean {
        if (this.form) {
            return this.form.hasChanged();
        } else {
            return false;
        }
    }

    throttlingLimit: ThrottlingLimit;
    showBanner: boolean;

    powerUnitsFamily: IUnitFamily;
    energyPriceUnitsFamily: IUnitFamily;
    energyUnitsFamily: IUnitFamily;

    powerUnits: IUnit[] = [];
    defaultPowerValue: IPhysicalValue;
    defaultEnergyPriceValue: IPhysicalValue;
    defaultCurrencyUnit: IUnit;
    defaultEnergyPriceUnit: IUnit;

    private _contractId: number;
    private _subscriptions: Subscriptions = new Subscriptions();

    products: Product[] = [Product.QuarterHour, Product.HalfHour, Product.Hour];

    sessionTypes = SessionType;
    exchangeEnum = Exchange;
    runState: boolean;
    private _runRunning: boolean;
    openConfirmSubmit: boolean = false;
    openConfirmSubmitAllParam: boolean = false;

    tabForms: TabForm[] = [
        "Products & Contracts",
        "Strategy",
        "Volume",
        "Prices",
        "Early termination",
    ];

    selectedTabIndex: number;
    _newSelectedTabIndex: number;
    _prevSelectedTabIndex: number;

    openConfirm: boolean;
    private _paramRestartSession: boolean = false;

    hasLostConnection: boolean;
    hasSessionUpdateRight: boolean;
    private _hasPortfolioReadRight: boolean;

    private _subRights: Subscription;

    readonly outlet: string;

    private _hub: AutoTradingHub;

    get hasOnLoading(): boolean {
        return this._loader.onLoading;
    }
    private _loader: MultiLoader = new MultiLoader();

    constructor(
        private _appSvc: ApplicationService,
        private _api: ApiService,
        private _srvNotification: EzNotificationService,
        private _cdr: ChangeDetectorRef,
        private _logger: Logger,
        private _route: ActivatedRoute,
        private readonly _dataSvc: DataService
    ) {
        this.outlet = this._route.outlet;
        this._loader.push("initPage");
        this._contractId = this._appSvc.contract.id;
        this._getCalendars();
    }

    ngOnInit(): void {
        this._subRights = this._appSvc.contractChange.subscribe(() => {
            let hasChanged: boolean;
            if (this.hasSessionUpdateRight !== this._appSvc.hasRight(UserRight.SessionUpdate)) {
                this.hasSessionUpdateRight = this._appSvc.hasRight(UserRight.SessionUpdate);
                hasChanged = true;
            }
            if (this._hasPortfolioReadRight !== this._appSvc.hasRight(UserRight.DealPortfolioRead)) {
                this._hasPortfolioReadRight = this._appSvc.hasRight(UserRight.DealPortfolioRead);
                this._getPortfolios();
                hasChanged = true;
            }
            if (hasChanged)
                this._cdr.detectChanges();
        });
        this._hub = this._api.autotrading.hub(this._appSvc.contract.id);
        const subState = this._hub.stateChanges.subscribe((state) => {
            const hasLostConnection = state === HubConnectionState.Reconnecting;
            if (this.hasLostConnection !== hasLostConnection) {
                this.hasLostConnection = hasLostConnection;
                this._cdr.detectChanges();
            }
        });
        this._subscriptions.push("hubState", subState);
        const subUnits = this._dataSvc.unitsChanges.subscribe((units) => {
            units ??= [];

            this._units = units;
            this.powerUnits = units.filter((u) => u.family.score === UnitFamiliesUmScore.Power);
            let energyPriceUnits = units.filter((u) => u.family.score === UnitFamiliesUmScore.PriceEnergy);
            let currencyUnits = units.filter((u) => u.family.score === UnitFamiliesUmScore.Currency);
            let energyUnits = units.filter((u) => u.family.score === UnitFamiliesUmScore.Energy);

            this.powerUnitsFamily = this.powerUnits && this.powerUnits.length ? this.powerUnits[0].family : undefined;
            this.energyPriceUnitsFamily = energyPriceUnits && energyPriceUnits.length ? energyPriceUnits[0].family : undefined;
            this.energyUnitsFamily = energyUnits && energyUnits.length ? energyUnits[0].family : undefined;

            this.defaultCurrencyUnit = currencyUnits.find((u) => u.id === 15);
            this.defaultPowerValue = new PhysicalValue(this.units.find((u) => u.id === 9), 1);
            this.defaultEnergyPriceValue = new PhysicalValue(this.units.find((u) => u.id == 23), 1);
            this._cdr.detectChanges();
        });
        this._subscriptions.push("unitsChanges", subUnits);
        this._initSession();
        this._loader.remove("initPage");
    }

    ngOnDestroy(): void {
        this._subscriptions.clearAll();
        this._subRights.unsubscribe();
    }

    changeParamRestartSession(value: boolean): void {
        this._paramRestartSession = value;
    }

    private _initSession(): void {
        this._subscriptions.clearSub("runMetrics");
        if (this._hub != null && this.session != null) {
            const sub = this._hub.runMetrics(this.session).subscribe(
                (result) => {
                    if (result != null && result.data != null) {
                        this._runRunning =
                            result.data.runState !== RunState.Stopped &&
                            result.data.runState !== RunState.Listening;
                    }
                },
                (error) => {
                    this._logger.error("RunMetrics Error", error);
                },
                () => {
                    this._logger.debug("RunMetrics Completed");
                }
            );
            this._subscriptions.push("runMetrics", sub);
            this._watchObject();
            this._cdr.detectChanges();
        }
    }

    private errorSubscription(error: any) {
        this._logger.error("error subscription", error);
    }

    private completeSubscription(): void {
        this._logger.debug("complete subscription");
    }

    formHasChange: boolean = false;
    onInitTab() {
        if (this.showBanner) this.showBanner = false;
        this._watchObject();
        this._subscriptions.clearSub("formChange");
        this.formHasChange = false;
        this._cdr.detectChanges();
        if (this.form) {
            let sub = this.form.onChange.subscribe((hasChange: boolean) => {
                this.formHasChange = hasChange;
                this._cdr.markForCheck();
            });
            this._subscriptions.push("formChange", sub);
        }
    }

    private _getPortfolios() {
        if (this._hasPortfolioReadRight) {
            const key = "getPortfolios";
            this._subscriptions.clearSub(key);
            this._loader.push(key);
            let sub = this._api.balancings.portfolios
                .getAll(this._contractId)
                .subscribe(
                    (res) => {
                        this.portfolios = res;
                        this._loader.remove(key);
                        this._cdr.detectChanges();
                    },
                    (error) => {
                        this.errorSubscription(error);
                        this._loader.remove(key);
                        this._cdr.detectChanges();
                    },
                    () => this.completeSubscription()
                );
            this._subscriptions.push(key, sub);
        } else if (this.portfolios) {
            this.portfolios = undefined;
            this._cdr.detectChanges();
        }
    }


    private _getCalendars() {
        const key = "getCalendars";
        this._subscriptions.clearSub(key);
        this._loader.push(key);
        let sub = this._api.references.calendars
            .getAll(this._contractId)
            .subscribe(
                (res) => {
                    this._calendars = res;
                    this._loader.remove(key);
                    this._cdr.detectChanges();
                },
                (error) => {
                    this.errorSubscription(error);
                    this._loader.remove(key);
                    this._cdr.detectChanges();
                },
                () => this.completeSubscription()
            );
        this._subscriptions.push(key, sub);
    }

    onUpdateBannerClick() {
        if (this.showBanner) {
            this.objectTab = this._objectTabUpdate;
            this.showBanner = false;
        }
    }

    submit(): void {
        if (this.hasSessionUpdateRight) {
            if (this._runRunning) {
                if (this.form && this.form.hasChanged() && this.hasSessionUpdateRight) {
                    if (this._paramRestartSession) {
                        this.openConfirmSubmit = true;
                    } else {
                        this.openConfirmSubmitAllParam = true;
                    }
                }
            } else {
                this.confirmSubmit(true);
            }
        }
    }

    confirmSubmit(isConfirm: boolean) {
        if (isConfirm && this.hasSessionUpdateRight && this.form) {
            this.form.submit();
        }
    }

    cancel(): void {
        if (this.form) {
            this.form.cancel();
        }
    }

    private _subjectAlertChange: Subject<boolean>;
    canClosedForm: () => Promise<boolean> = () => this._canClosedForm();
    private _canClosedForm(): Promise<boolean> {
        if (this._isFormChanged && this.hasSessionUpdateRight) {
            this.openConfirm = true;
            this._cdr.detectChanges();
            if (!this._subjectAlertChange) {
                this._subjectAlertChange = new Subject<boolean>();
            }
            return this._subjectAlertChange.toPromise();
        }
        return Promise.resolve(true);
    }

    onConfirmClickButton(confirmChange: boolean) {
        if (confirmChange) {
            if (this.form) {
                this.form.cancel();
            }
        }
        if (this._subjectAlertChange) {
            this._subjectAlertChange.next(confirmChange === true);
            this._subjectAlertChange.complete();
            this._subjectAlertChange = undefined;
        }
        this._cdr.detectChanges();
    }

    onFormSubmit() {
        if (this.showBanner || !this.hasSessionUpdateRight) return;
        this._subscriptions.clearSub("savedForm");
        let obs: Observable<any>;
        switch (this.tab) {
            case "Products & Contracts":
                obs = this._api.autotrading.sessions.setTimeAndLocationRules(
                    this._contractId,
                    this._session.id,
                    <ITimeAndLocationRules>this.objectTab
                );
                break;
            case "Strategy":
                switch (this.session.type) {
                    case SessionType.Position:
                        obs =
                            this._api.autotrading.position.setStrategyParameters(
                                this._contractId,
                                this._session.id,
                                <IPositionStrategy>this.objectTab
                            );
                        break;
                    case SessionType.Flex:
                        obs = this._api.autotrading.flex.setStrategyParameters(
                            this._contractId,
                            this._session.id,
                            <IFlexStrategy>this.objectTab
                        );
                        break;
                    case SessionType.Generation:
                        obs =
                            this._api.autotrading.generation.setStrategyParameters(
                                this._contractId,
                                this._session.id,
                                <IGenerationStrategy>this.objectTab
                            );
                }
                break;
            case "Volume":
                switch (this.session.type) {
                    case SessionType.Position:
                        obs =
                            this._api.autotrading.position.setVolumesParameters(
                                this._contractId,
                                this._session.id,
                                <IPositionVolumesParameters>this.objectTab
                            );
                        break;
                    case SessionType.Flex:
                        obs = this._api.autotrading.flex.setVolumesParameters(
                            this._contractId,
                            this._session.id,
                            <IFlexVolumesParameters>this.objectTab
                        );
                        break;
                    case SessionType.Generation:
                        obs =
                            this._api.autotrading.generation.setVolumesParameters(
                                this._contractId,
                                this._session.id,
                                <IGenerationVolumesParameters>this.objectTab
                            );
                        break;
                }
                break;
            case "Prices":
                switch (this.session.type) {
                    case SessionType.Position:
                        obs =
                            this._api.autotrading.position.setPricesParameters(
                                this._contractId,
                                this._session.id,
                                <IPositionPricesParameters>this.objectTab
                            );
                        break;
                    case SessionType.Flex:
                        obs = this._api.autotrading.flex.setPricesParameters(
                            this._contractId,
                            this._session.id,
                            <IFlexPricesParameters>this.objectTab
                        );
                        break;
                    case SessionType.Generation:
                        obs =
                            this._api.autotrading.generation.setPricesParameters(
                                this._contractId,
                                this._session.id,
                                <IGenerationPricesParameters>this.objectTab
                            );
                        break;
                }
                break;
            case "Early termination":
                obs =
                    this._api.autotrading.sessions.setEarlyTerminationParameters(
                        this._contractId,
                        this._session.id,
                        <IEarlyTerminationRules>this.objectTab
                    );
                break;
        }

        let sub = obs.subscribe(
            () => {
                this._srvNotification.success("data saved");
                this._paramRestartSession = false;
            },
            (error) => this.errorSubscription(error),
            () => this.completeSubscription()
        );
        this._subscriptions.push("savedForm", sub);
    }

    hasErrorWatchObject: boolean = false;
    private _watchObject() {
        const key = "selectedTab";
        this.hasErrorWatchObject = false;
        this._objectTabUpdate = undefined;
        this.objectTab = undefined;
        this._paramRestartSession = false;
        if (!this.tab || !this._session || !this._hub) return;
        this._subscriptions.clearSub(key);
        this._subscriptions.clearSub(`session_throttlinglimit`);
        this._loader.push(key);
        let obs: Observable<IHubResult<any, ISession>>;
        switch (this.tab) {
            case "Products & Contracts":
                obs = this._hub.timeAndLocationParameters(this.session);
                break;
            case "Strategy":
                obs = this._hub.strategyParameters(this.session);
                this._subsToThrottlingLimit();
                break;
            case "Volume":
                obs = this._hub.volumeParameters(this.session);
                break;
            case "Prices":
                obs = this._hub.priceParameters(this.session);
                break;
            case "Early termination":
                obs = this._hub.terminationRulesParameters(this.session);
                break;
        }
        // TODO remove this test
        let isFirstLoad: boolean = true;

        // END TODO
        let sub = obs.subscribe(
            (result) => {
                if (!result.dataReceived) {
                    return;
                }

                const obj = result.data;
                // TODO: to remove. temporary set to match backend object
                if (this._isDifferentObject(this.objectTab, obj) && !isFirstLoad) {
                    this.showBanner = true;
                    this._objectTabUpdate = obj;
                } else {
                    this.objectTab = obj;
                    isFirstLoad = false;
                }
                this._loader.remove(key);
                this._cdr.detectChanges();
            },
            (error) => {
                this.hasErrorWatchObject = true;
                this.errorSubscription(error);
                this._loader.remove(key);
                this._cdr.detectChanges();
            },
            () => this.completeSubscription()
        );
        this._subscriptions.push(key, sub);
    }

    private _subsToThrottlingLimit(): void {
        this._subscriptions.clearSub("session_throttlinglimit");
        const subThrottlingLimit = this._hub
            .throttlingLimit(this._session)
            .subscribe(
                (result) => {
                    if (result.dataReceived) {
                        this.throttlingLimit = result.data;
                    }
                },
                (error) => {
                    this._logger.error(
                        `ThrottlingLimit stream error for session ${this._session}`,
                        error
                    );
                },
                () => {
                    this._logger.debug(
                        `ThrottlingLimit stream complete for session ${this._session}`
                    );
                }
            );
        this._subscriptions.push("session_throttlinglimit", subThrottlingLimit);
    }

    private _isDifferentObject(obj, result): boolean {
        if(!obj || !result) 
            return false
        return Object.keys(obj).some(key => {
            if (!result.hasOwnProperty(key)) 
                return true;
            const currentObject = obj[key];
            const objectCompare = result[key];
            if (currentObject == undefined && objectCompare == undefined)
                return false;
            const id: number | undefined = currentObject?.id;
            if(id) 
                return id !== objectCompare?.id;
            if(currentObject instanceof Array) 
                return this._isDifferentArray(currentObject, objectCompare);
            if(typeof currentObject === "object") 
                return this._isDifferentObject(currentObject, objectCompare)
            return currentObject !== objectCompare;
        });

    }

    private _isDifferentArray(array, arrayToCompare): boolean {
        if (array.length !== arrayToCompare.length) 
            return true;
        return array.some((value, index) => 
            this._isDifferentObject(value, arrayToCompare[index])
        );
    }
}
