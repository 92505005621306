<div class="btn-action">
    <ez-button *ngIf="mode !== modes.Short || !(disableAction || (hasStarted && !hasPaused))" [big]="size === sizes.Big"
        [ngClass]="{'schedule': nextSchedule != undefined && !hasStarted }" [small]="size === sizes.Small"
        [icon]="'play'" [severity]="hasOnStart ? 'danger' : undefined" (onClick)="onStartRun($event)"
        [disabled]="disableAction || (hasStarted && !hasPaused)"
        [ezTooltip]="('autotrading.session.controller.tooltip.start'+(nextSchedule?.schedule?.startDate != null ? '-scheduled': '') | translate:{startDate:(nextSchedule?.schedule?.startDate | appdate: {format:{date: 'medium', time: 'short'}, calendar: calendar })})">
    </ez-button>

    <ez-button *ngIf="mode !== modes.Short" [big]="size === sizes.Big" [small]="size === sizes.Small"
        [icon]="'hand-pointer'" (onClick)="onManualRun($event)"
        [disabled]="hasReadOnlyAccess || disableAction || hasStarted" [severity]="hasOnManual ? 'danger' : undefined"
        ezTooltip="{{'autotrading.session.controller.tooltip.manual' | translate}}">
    </ez-button>

    <ez-button *ngIf="mode !== modes.Short" [big]="size === sizes.Big" [small]="size === sizes.Small"
        [icon]="'mouse-pointer'" (onClick)="onOneShotRun($event)"
        [disabled]="hasReadOnlyAccess || disableAction || hasStarted"
        ezTooltip="{{'autotrading.session.controller.tooltip.oneShot' | translate}}">
    </ez-button>

    <div class="separate" *ngIf="mode !== modes.Short"></div>

    <ez-button *ngIf="mode !== modes.Short || !(disableAction || !hasOnLive)" [big]="size === sizes.Big"
        [small]="size === sizes.Small" [icon]="'pause'"
        [severity]="hasPaused || hasAutomaticPaused ? 'danger' : undefined" (onClick)="onPause($event)"
        [disabled]="hasReadOnlyAccess || disableAction || !hasOnLive"
        ezTooltip="{{'autotrading.session.controller.tooltip.pause' | translate}}">
    </ez-button>

    <ez-button *ngIf="mode !== modes.Short || !(disableAction || !hasStarted)" [big]="size === sizes.Big"
        [small]="size === sizes.Small" [icon]="'stop'" (onClick)="onStop($event)" class="stop-btn"
        [disabled]="disableAction || !hasStarted" [ngClass]="{'schedule': hasStarted}"
        [ezTooltip]="('autotrading.session.controller.tooltip.stop'+(nextSchedule?.schedule?.endDate != null ? '-scheduled': '') | translate:{endDate:(nextSchedule?.schedule?.endDate | appdate: {format:{date: 'medium', time: 'short'}, calendar: calendar })})">
    </ez-button>
</div>

<ez-modal [open]="openModalSchedule" (onClose)="closeScheduleForm()" class="modal-schedule-session">
    <ez-header *ngIf="typeAction">
        {{ ('autotrading.session.'+typeAction+'.confirm.title') | translate}}
    </ez-header>
    <ez-content>
        <at-schedule-form *ngIf="schedule; else elseNotRun" [(value)]="schedule" [session]="session" [hideFooter]="true"
            (onSubmit)="closeScheduleForm()" (onCancel)="closeScheduleForm()"></at-schedule-form>
        <ng-template #elseNotRun>
            <span *ngIf="typeAction">{{ ('autotrading.session.'+typeAction+'.confirm.content') |
                translate:{name:session?.name} }}</span>
        </ng-template>
        <div *ngIf="hasNextSchedule && !hasStarted" class="alert-schedule">
            <ez-icon name="exclamation"></ez-icon>
            <p>{{ 'autotrading.session.schedule.nextschedule' | translate:{typeRun:('autotrading.runMode.'
                +nextSchedule?.mode | translate ), date:(nextSchedule?.schedule?.startDate | appdate:{format : {date: 'medium',
                time: 'short'} , calendar: calendar}:{date:nextSchedule?.schedule?.endDate, separator:'and'}) } }}
            </p>
        </div>
    </ez-content>
    <ez-footer>
        <ez-button class="btn-delete-schedule" [label]="'Delete'" [severity]="'danger'"
            (onClick)="deleteSchedule($event)" *ngIf="hasScheduling">
        </ez-button>
        <ez-button [label]="'general.cancel' | translate" (onClick)="closeScheduleForm()"></ez-button>
        <ez-button [label]="'general.confirm' | translate" [severity]="'primary'" (onClick)="onSubmitForm()">
        </ez-button>
    </ez-footer>
</ez-modal>
