<ez-icon [name]="'globe'"></ez-icon>
<div class="f-value">
    {{dataSelected?.tz.name}}
</div>
<ez-popover #popdata [(open)]="displayPopover" [origin]="disabled ? undefined : origin" [actionType]="'click'"
    [alignH]="'left'" [alignInnerH]="true" [class]="'filter-tz-popover'" [dispatchEventsToOrigin]="true">
    <ez-ul class="f-p-tz" #popover>
        <ez-li *ngFor="let d of data" (click)="changeTimeZone(d)" [class.selected]="d.tz.iana === dataSelected?.tz.iana"
            [scrollfocus]="popdata.open && d.tz.iana === dataSelected?.tz.iana" [delay]="200">
            <ez-icon [name]="'map-marker-alt'" *ngIf="d.local" [ezTooltip]="'Local Time Zone'"></ez-icon>
            <span class="tz-name">{{d.tz.name}}</span>
            <ez-icon [hidden]="!(d.grids?.length > 0)" [name]="'info-circle'" #tzInfo="EzOverlayOrigin"
                ez-overlay-origin></ez-icon>
            <ez-popover [origin]="tzInfo" [alignH]="'right'" [alignInnerH]="false" [class]="'filter-over-grids'">
                <div class="f-p-grids">
                    <div *ngFor="let g of d.grids" class="f-p-grid">
                        <span>{{g.name}}</span>
                        <span class="separator">-</span>
                    </div>
                </div>
            </ez-popover>
        </ez-li>
    </ez-ul>
</ez-popover>
