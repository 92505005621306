import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { orientationType } from "@eznergy/components";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageMapPreferenceInfo {
    static load(preferences: string | PageMapPreferenceInfo): PageMapPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageMapPreferenceInfo>(preferences, PageMapPreferenceInfo) as PageMapPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    orientation: orientationType | undefined;
    materialId: number | undefined;
    displayGridPoints: boolean | undefined;
    zoomLevel: number | undefined;
    position: { lat: number, lng: number } | undefined;
}

@UserPreferenceClassDecorator
export class PageMapPreference implements IPreferencePage {
    static load(preferences: string | PageMapPreferenceInfo): PageMapPreference {
        if (!preferences) {
            return new PageMapPreference();
        }

        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageMapPreferenceInfo.load(preferences);
                return new PageMapPreference(v1.orientation, v1.materialId, v1.displayGridPoints, v1.zoomLevel, v1.position);
            default:
                return new PageMapPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    orientation: orientationType | undefined;
    @UPPropertySave
    materialId: number | undefined;
    @UPPropertySave
    displayGridPoints: boolean | undefined;
    @UPPropertySave
    zoomLevel: number | undefined;
    @UPPropertySave
    position: { lat: number, lng: number } | undefined;

    gridPointId: number | undefined;
    gridId: number | undefined;

    constructor(
        orientation: orientationType = "vertical",
        materialId: number = 1,
        displayGridPoints: boolean = false,
        zoomLevel: number = 3,
        position: { lat: number, lng: number } | undefined = undefined
    ) {
        this.orientation = orientation;
        this.materialId = materialId;
        this.displayGridPoints = displayGridPoints;
        this.zoomLevel = zoomLevel;
        this.position = position;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.materialId = undefined;
            this.gridPointId = undefined;
            this.gridId = undefined;
            this.orientation = undefined;
            this.displayGridPoints = undefined;
            this.zoomLevel = undefined;
            this.position = undefined;
        }
    }
}

