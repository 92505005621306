// @ts-nocheck
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

export class MultiLoader {

    private static tokenFactory = (function* () {
        let count = 0;
        while (true) {
            yield `MultiLoader-${count++}`;
        }
    })();

    private tokensSubject: BehaviorSubject<string[]>;
    private get tokens(): string[] {
        return [...this.tokensSubject.value];
    }

    private set tokens(value: Iterable<string>) {
        this.tokensSubject.next([...value]);
    }

    public isloadingChange: Observable<boolean>;
    public get isLoading() {
        const { tokens } = this;
        return this._isLoading(tokens);
    }

    /** @deprecated for isLoading */
    public get onLoading(): boolean {
        return this.isLoading;
    }

    private _activeObservers: Map<string, Subscriber<any>>;
    constructor() {
        this._activeObservers = new Map();

        this.tokensSubject = new BehaviorSubject([]);
        this.isloadingChange = this._setupIsLoadingChanges();
    }

    public onDestroy(): void {
        this.tokensSubject.complete();
    }

    public push(token: string): void {
        const { tokens } = this;

        if (tokens.includes(token)) {
            return;
        }

        this.tokens = [...tokens, token];
    }

    public remove(token: string): void {
        const { tokens } = this;

        const index = tokens.indexOf(token);
        if (index < 0) {
            return;
        }

        tokens.splice(index, 1);
        this.tokens = tokens;
    }

    /** @deprecated for isLoading */

    public clearAll(): void {
        this.reset();
    }

    public reset(): void {
        const { _activeObservers } = this;
        for (let observer of _activeObservers.values()) {
            observer.complete();
        }

        this.tokens = [];
    }

    public asObservable(): Observable<boolean> {
        return this._setupIsLoadingChanges();

    }

    public add<T>(source: Observable<T>): Observable<T> {
        return new Observable(observer => {
            const { value } = MultiLoader.tokenFactory.next();

            this._activeObservers.set(value, observer);
            this.push(value)

            const subscription = source.subscribe({
                next: (value?: T) => {
                    observer.next(value);
                },
                error: (err?: any) => {
                    observer.error(err);
                    this.remove(value);
                },
                complete: () => {
                    observer.complete();

                    this.remove(value);
                }
            });

            return () => {
                subscription.unsubscribe();
                this.remove(value);
            }
        });
    }

    private _setupIsLoadingChanges(): Observable<boolean> {
        return this.tokensSubject.pipe(map(tokens => this._isLoading(tokens)));
    }

    private _isLoading(tokens: string[]) {
        return tokens.length > 0;
    }

}
