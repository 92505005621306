import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppCoreModule } from "@core/core.module";
import { Logger } from "@core/logger";
import { ApiConfig, ApiModule, AuthInterceptor, ErrorInterceptor, HeaderInterceptor, TimezoneInterceptor } from "@eznergy/webapi";
import { AppFormsModule } from "@forms/forms.module";
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { PagesModule } from "@pages/pages.module";
import { AppConfig } from "@services/app-config.service";
import { DefaultValues } from 'src/app/constants/constant.default-values';
import { AppHeaderComponent } from "src/app/templates/header/header.component";
import { AppMenuComponent } from "src/app/templates/menu/menu.component";
import { AppRedirectionModule, RedirectComponent } from 'src/app/modules/redirection';
import { LandingComponent } from 'src/app/templates/landing/landing.component';
import { MainComponent } from "../templates/main/main.component";
import { ApiConfigFactory, EzHandleErrorFactory, EzMissingTranslationHandlerFactory, HttpLoaderFactory, initializeApp, locale } from "./module-utils";
import { appRoutes } from "./routing";
import { LicenseManager } from 'ag-grid-enterprise';
import { SharedModule } from './shared/shared.module';
import { PopupsModule } from './popups/popups.module';
import { NotificationInterceptor } from "../interceptors/notification.interceptor";
import { RedirectInterceptor } from "../interceptors/redirect.interceptor";

LicenseManager.setLicenseKey(DefaultValues.agGridKey);

@NgModule({
    imports: [
        AppCoreModule,
        ApiModule.forRoot({ provide: ApiConfig, useFactory: ApiConfigFactory, deps: [AppConfig] }),
        BrowserModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient, HttpBackend, AppConfig] } }),
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { registrationStrategy: 'registerImmediately' }),
        PagesModule,
        AppFormsModule,
        AppRedirectionModule,
        PopupsModule,
        SharedModule
    ],
    declarations: [
        AppHeaderComponent,
        MainComponent,
        AppMenuComponent,
        LandingComponent,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: locale },
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig, Logger, Injector, TranslateService], multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: APP_BASE_HREF, useValue: "/" },
        { provide: ErrorHandler, useFactory: EzHandleErrorFactory, deps: [Logger] },
        { provide: MissingTranslationHandler, useFactory: EzMissingTranslationHandlerFactory },
        // WARNING : Order is important for the interceptors
        { provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [RedirectComponent, MainComponent]
})
export class AppModule {
}

