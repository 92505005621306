import {
  ColumnApi,
  GridApi,
  GridOptions,
  ICellRendererParams,
  IHeaderGroupParams,
  IHeaderParams,
  IToolPanelParams,
  ToolPanelDef,
} from 'ag-grid-enterprise';

export declare type WithoutGridCommon<T extends AgGridCommon> = Omit<T, keyof AgGridCommon>;
export interface AgGridCommon {
  api: GridApi;
  columnApi: ColumnApi;
  context: any;
}

export type IExtendedGridContext<T = unknown> = Record<PropertyKey, any> & T;

export type IExtendedGridCommon<T = unknown> = Omit<AgGridCommon, 'context'> & {
  context: IExtendedGridContext<T>;
};

export type WithGridCommon<
  TType extends AgGridCommon,
  TGridCommon extends IExtendedGridCommon
> = WithoutGridCommon<TType> & TGridCommon;

export type IExtendedColumnHeaderParams<
  TCustomParams = unknown,
  TGridCommon extends IExtendedGridCommon = IExtendedGridCommon
> = WithGridCommon<IHeaderParams, TGridCommon> & {
  customParams?: TCustomParams;
};

export type IExtendedGroupHeaderParams<
  TCustomParams = unknown,
  TGridCommon extends IExtendedGridCommon = IExtendedGridCommon
> = WithGridCommon<IHeaderGroupParams, TGridCommon> & {
  customParams?: TCustomParams;
};

export type IExtendedHeaderParams<
  TCustomParams = unknown,
  TGridCommon extends IExtendedGridCommon = IExtendedGridCommon
> = IExtendedColumnHeaderParams<TCustomParams, TGridCommon> | IExtendedGroupHeaderParams<TCustomParams, TGridCommon>;

export class HeaderParams {
  static areColumnParams<TCustom, TGridCommon extends IExtendedGridCommon>(
    params: IExtendedHeaderParams<TCustom, TGridCommon> | any
  ): params is IExtendedColumnHeaderParams<TCustom, TGridCommon> {
    return !!(<any>params)?.column;
  }

  static areGroupParams<TCustom, TGridCommon extends IExtendedGridCommon>(
    params: IExtendedHeaderParams<TCustom, TGridCommon> | any
  ): params is IExtendedGroupHeaderParams<TCustom, TGridCommon> {
    return !!(<any>params)?.columnGroup;
  }

  static createHeaderParams<T extends IExtendedHeaderParams>(): (params: T['customParams']) => {
    customParams: T['customParams'];
  } {
    return (customParams: T['customParams']) => ({customParams});
  }
}

export interface ICustomCellRendererParams {
  customParams?: unknown;
  data?: unknown;
}

export type IExtendedCellRendererParams<
  TParams extends ICustomCellRendererParams = ICustomCellRendererParams,
  TGridCommon extends IExtendedGridCommon = IExtendedGridCommon
> = Omit<WithGridCommon<ICellRendererParams, TGridCommon>, 'data'> & {
  customParams: TParams['customParams'];
  data: TParams['data'];
};

export class CellRendererParams {
  static createCellParams<TParams extends IExtendedCellRendererParams>(): (params: TParams['customParams']) => {
    customParams: TParams['customParams'];
  } {
    return (customParams: TParams['customParams']) => ({customParams});
  }
}

export type IExtendedGridOptions<TContext = unknown> = Omit<GridOptions, 'context'> & {
  context: TContext;
};

export type IExtendedToolPanelDef<TParams = unknown> = Omit<ToolPanelDef, 'toolPanelParams'> & {
  toolPanelParams: TParams;
};

export type IExtendedToolPanelParams<
  TParams = unknown,
  TGridCommon extends IExtendedGridCommon = IExtendedGridCommon
> = WithGridCommon<IToolPanelParams, TGridCommon> & {
  [key in keyof TParams]: TParams[key];
};
