// @ts-nocheck
import { Injectable } from '@angular/core';
import { Subscriptions } from '@core/subscriptions';
import { ApiService } from '@eznergy/webapi';
import {
    GridByShipper,
    GridTypeCode,
    ICounterpart,
    ICountry,
    ICurveBase,
    IDirection,
    IGrid,
    IGridRelationship,
    IMaterial,
    IOrganisation,
    IPortfolio,
    IUnit,
    UserRight,
} from '@eztypes/webapi';
import { GridsService } from '@services/grids.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
    auditTime,
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    startWith,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { ApplicationService } from './application.service';
import { IGridsIdsByShipper } from '@models/api/topology/gridIds-by-shipper.model';

@Injectable({ providedIn: 'root' })
export class DataService {
    get counterparties(): ICounterpart[] {
        return this._subCounterparties.value;
    }

    get counterpartiesChanges(): Observable<ICounterpart[]> {
        return this._subCounterparties;
    }
    private readonly _subCounterparties: BehaviorSubject<ICounterpart[]> = new BehaviorSubject<ICounterpart[]>(
        undefined
    );

    private _nominationCounterParties$: Observable<ICounterpart[]>;
    get nominationCounterpartiesChanges(): Observable<ICounterpart[]> {
        return this._nominationCounterParties$;
    }

    private _balancingCounterParties$: Observable<ICounterpart[]>;
    get balancingCounterpartiesChanges(): Observable<ICounterpart[]> {
        return this._balancingCounterParties$;
    }

    get portfolios(): IPortfolio[] {
        return this._subPortfolios.value;
    }
    get portfoliosChanges(): Observable<IPortfolio[]> {
        return this._subPortfolios;
    }
    private readonly _subPortfolios: BehaviorSubject<IPortfolio[]> = new BehaviorSubject<IPortfolio[]>(undefined);

    get profils(): ICurveBase[] {
        return this._subProfils.value;
    }
    get profilsChanges(): Observable<ICurveBase[]> {
        return this._subProfils;
    }
    private readonly _subProfils: BehaviorSubject<ICurveBase[]> = new BehaviorSubject<ICurveBase[]>(undefined);

    get materials(): IMaterial[] {
        return this._subMaterials.value;
    }
    get materialsChanges(): Observable<IMaterial[]> {
        return this._subMaterials;
    }
    private readonly _subMaterials: BehaviorSubject<IMaterial[]> = new BehaviorSubject<IMaterial[]>(undefined);

    get directions(): IDirection[] {
        return this._subDirections.value;
    }
    get directionsChanges(): Observable<IDirection[]> {
        return this._subDirections;
    }
    private readonly _subDirections: BehaviorSubject<IDirection[]> = new BehaviorSubject<IDirection[]>(undefined);


    get units(): IUnit[] {
        return this._subUnits.value;
    }
    get unitsChanges(): Observable<IUnit[]> {
        return this._subUnits;
    }
    private readonly _subUnits: BehaviorSubject<IUnit[]> = new BehaviorSubject<IUnit[]>(undefined);

    get areas(): IGrid[] {
        return this._gridsService.areas;
    }
    get areasChanges(): Observable<IGrid[]> {
        return this._gridsService.areasChanges;
    }

    get grids(): IGrid[] {
        return this._gridsService.grids;
    }
    get gridsChanges(): Observable<IGrid[]> {
        return this._gridsService.gridsChanges;
    }

    get gridsAsset(): IGrid[] {
        return this._gridsService.assetGrids;
    }
    get gridsAssetChanges(): Observable<IGrid[]> {
        return this._gridsService.assetGridsChanges;
    }

    get allGrids(): IGrid[] {
        return this._gridsService.allGrids;
    }
    get allGridsChanges(): Observable<IGrid[]> {
        return this._gridsService.allGridsChanges;
    }

    get gridRelationships(): IGridRelationship[] {
        return this._gridsService.gridRelationships;
    }
    get gridRelationshipChanges(): Observable<IGridRelationship[]> {
        return this._gridsService.gridRelationshipChanges;
    }

    get countries(): Observable<ICountry[]> {
        if (this._countries == null) {
            this._countries = new BehaviorSubject<ICountry[]>(undefined);
            this._initCountries();
        }
        return this._countries.asObservable();
    }
    private _countries: BehaviorSubject<ICountry[]>;

    get changes(): Observable<void> {
        return this._subChanges.asObservable();
    }
    private readonly _subChanges: Subject<void> = new Subject();

    private readonly _subs: Subscriptions = new Subscriptions();

    private _gridIdsByShipper: IGridsIdsByShipper[] = []; 

    constructor(
        private readonly _api: ApiService,
        private readonly _appSvc: ApplicationService,
        private readonly _gridsService: GridsService
    ) {
        const subRightChange = this._appSvc.contractChange.subscribe(() => {
            this._subCounterparties.next(undefined);
            this._subPortfolios.next(undefined);
            this._subProfils.next(undefined);
            this._subMaterials.next(undefined);
            this._subDirections.next(undefined);
            this._appSvc.setAllShippers(undefined);
            this._subUnits.next(undefined);
            this._subChanges.next();
            this._prepareData();
        });
        this._subs.push('sub-rights', subRightChange);

        const gridChangesSub = this._gridsService.changes.subscribe(() => {
            this._subChanges.next();
        });

        this._subs.push('sub-grid-changes', gridChangesSub);
        this._setupCounterpartyObservables();
    }

    private _prepareData(): void {
        if (this._appSvc.contract != null) {
            if (this._appSvc.hasRight(UserRight.DealPortfolioRead)) {
                const subPortfolios = this._api.balancings.portfolios
                    .getAll(this._appSvc.contract.id)
                    .subscribe((portfolios) => {
                        this._subPortfolios.next(portfolios);
                        this._subChanges.next();
                    });
                this._subs.push('sub-portfolios', subPortfolios);
            }

            const subProfil = this._api.timeseries.curves
                .getAll(this._appSvc.contract.id, { isShape: true })
                .subscribe((profils) => {
                    this._subProfils.next(profils);
                    this._subChanges.next();
                });
            this._subs.push('sub-profils', subProfil);

            const subMaterials = this._api.references.materials
                .getAll(this._appSvc.contract.id)
                .subscribe((materials) => {
                    this._subMaterials.next(materials);
                    this._subChanges.next();
                });
            this._subs.push('sub-materials', subMaterials);

            const subDirection = this._api.references.directions
                .getAll(this._appSvc.contract.id)
                .subscribe((directions) => {
                    this._subDirections.next(directions);
                    this._subChanges.next();
                });
            this._subs.push('sub-directions', subDirection);

            const subShippers = this._api.references.shippers.getAll(this._appSvc.contract.id).subscribe((shippers) => {
                this._appSvc.setAllShippers(shippers);
                this._subChanges.next();
            });
            this._subs.push('sub-shippers', subShippers);

            const subUnits = this._api.timeseries.units.getAll(this._appSvc.contract.id).subscribe((units) => {
                this._subUnits.next(units);
                this._subChanges.next();
            });
            this._subs.push('sub-units', subUnits);
        }
    }

    private _initCountries(): void {
        this._api.topologies.countries.getAll(this._appSvc.contract.id, true).subscribe((countries: ICountry[]) => {
            this._countries.next(countries);
        });
    }

    private _setupCounterpartyObservables(): void {
        const appSvcData$ = combineLatest([this._appSvc.contractChange, this._appSvc.shipperChange]);

        // getCounterparts currently returns a list of distinct counterparties
        // in doing so we lose grid relationship information when 2 grids share a counterpart
        // therefore loading them separately seemed like a solution but in fact still causes the same problem

        // load counterparties for balancing areas
        this._balancingCounterParties$ = this.areasChanges.pipe(
            withLatestFrom(appSvcData$),
            switchMap(([areas, [contractData, shipper]]) => {
                if (!areas?.length || !contractData || !shipper) {
                    return of<ICounterpart[]>([]);
                }
                this._gridIdsByShipper = this._gridsService.gridsByShipper;
                if (this._appSvc.isMultiShipper) {
                    return this._api.references.shippers.getAllCounterpartsByShipper(contractData.contract.id, this._gridIdsByShipper, {
                        displayOld: false,
                    });
                }
                return this._api.references.shippers.getCounterparts(contractData.contract.id, shipper.id, areas, {
                    displayOld: false,
                });
            }),
            startWith([]),
            shareReplay({ bufferSize: 1, refCount: true })
        );

        // load counterparties for nomination grids
        this._nominationCounterParties$ = this.gridsChanges.pipe(
            withLatestFrom(appSvcData$),
            switchMap(([grids, [contractData, shipper]]) => {
                if (!grids?.length || !contractData || !shipper) {
                    return of<ICounterpart[]>([]);
                }

                return this._api.references.shippers.getCounterparts(contractData.contract.id, shipper.id, grids, {
                    displayOld: false,
                });
            }),
            startWith([]),
            shareReplay({ bufferSize: 1, refCount: true })
        );

        // loaad counterparties for gridAssets
        const gridAssetCounterparts$ = this.gridsAssetChanges.pipe(
            withLatestFrom(appSvcData$),
            switchMap(([gridAssets, [contractData, shipper]]) => {
                if (!gridAssets?.length || !contractData || !shipper) {
                    return of<ICounterpart[]>([]);
                }

                return this._api.references.shippers.getCounterparts(contractData.contract.id, shipper.id, gridAssets, {
                    displayOld: false,
                });
            }),
            startWith([]),
            shareReplay({ bufferSize: 1, refCount: true })
        );

        // combine separately loaded counterparties
        const subscription = combineLatest([
            this.balancingCounterpartiesChanges,
            this.nominationCounterpartiesChanges,
            gridAssetCounterparts$,
        ])
            .pipe(
                map(([areasCounterParts, gridCounterParts, gridAssetCounterparts]) => [
                    ...areasCounterParts,
                    ...gridCounterParts,
                    ...gridAssetCounterparts,
                ]) // combine results into list
            )
            .subscribe((counterParties) => {
                this._subCounterparties.next(counterParties);
                this._subChanges.next();
            });

        this._subs.push('sub-counterparties', subscription);
    }
}
