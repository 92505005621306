// @ts-nocheck
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "power-icon",
    template: '<ez-icon name="bolt"></ez-icon>',
    styleUrls: ['./power.icon.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PowerIcon {

}
