<ez-form [autofocus]="false">
    <ez-loader *ngIf="isLoading"></ez-loader>
    <ez-form-field>
        <ez-label>{{'balancing.ets.selectShipper' | translate}}</ez-label>
        <ez-select [ngModel]="_params?.profil" (valueChange)="onChangeProfil($event)" [nullable]="false">
            <ez-select-option *ngFor="let profile of _data?.profil" [value]="profile">
                {{profile.shipper.shortName}} ({{profile.etsName}})
            </ez-select-option>
        </ez-select>
    </ez-form-field>
    <grids-controller-balancing hasFilterGrids hideResize [filterGrids]="gridsData" (gridChange)="onChangeGrid($event)">
    </grids-controller-balancing>
    <ez-form-field>
        <ez-label>{{'balancing.ets.etsArea' | translate}}</ez-label>
        <ez-select [ngModel]="_params?.area" (valueChange)="onChangeArea($event)" [nullable]="false">
            <ez-select-option *ngFor="let item of areasData" [value]="item">
                {{item.name}}
            </ez-select-option>
        </ez-select>
    </ez-form-field>
    <ez-form-field>
        <ez-label>{{'balancing.ets.etsPortfolio' | translate}}</ez-label>
        <ez-select [ngModel]="_params?.portfolio" (valueChange)="onChangePortfolio($event)"
            [disabled]="isMarketResultsPanelActive" [nullable]="false">
            <ez-select-option *ngFor="let portfolio of portfoliosData" [value]="portfolio">
                {{portfolio.name}}
            </ez-select-option>
        </ez-select>
    </ez-form-field>
</ez-form>
