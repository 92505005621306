export enum SessionDisplayType {
    Live = 'current',
    Session = 'session'
}

export enum DisplayModeTrading {
    Session = 'session',
    Market = 'market',
    Reporting = 'reporting'
}

export enum ReportTypeTrading {
    Session = 'session',
    Run = 'run'
}
