// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, IterableChangeRecord, IterableDiffer, IterableDiffers, Output, ViewEncapsulation } from "@angular/core";
import * as _ez from "@eznergy/core";
import { DateTime, TimeZone } from '@eztypes/generic';
import { SessionType } from '@eztypes/webapi';
// Models
import { TradingDetails } from '../../../../models/details';
import { DateService } from "@services/date.service";
import { TradingColors } from '../../../../models/trading.constant';
import * as _ from "lodash";

class SessionDetailChart {
    label: string;
    name: string;
    current: number;
    potential: number;
    tradable: number;
    initial: number;
    min: number;
    max: number;
    totalAsk: number;
    totalBid: number;
    inactive: boolean;
    closed: boolean;
    startDate: DateTime;
    constructor(current?: number,
        potential?: number,
        tradable?: number,
        initial?: number,
        min?: number,
        max?: number,
        totalAsk?: number,
        totalBid?: number) {
        this.current = current;
        this.potential = potential;
        this.tradable = tradable;
        this.initial = initial;
        this.min = min;
        this.max = max;
        this.totalAsk = totalAsk;
        this.totalBid = totalBid;
    }
}

@Component({
    selector: 'ez-chart-session-svg',
    templateUrl: './chart-session-svg.component.html',
    styleUrls: ['./chart-session-svg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ChartSessionSvgComponent {

    @Output() selectedChange: EventEmitter<TradingDetails> = new EventEmitter<TradingDetails>();

    @Input()
    get small(): boolean {
        return this._small;
    }
    set small(value: boolean) {
        if (this._small !== value) {
            this._small = value;
            this._cdr.markForCheck();
        }
    }
    private _small: boolean = false;

    @Input()
    get details(): TradingDetails[] {
        return this._details;
    }
    set details(value: TradingDetails[]) {
        if (value !== this._details) {
            this._switchData(value);
            this._cdr.markForCheck();
        }
    }
    private _details: TradingDetails[] = [];

    @Input()
    get sessionType(): SessionType {
        return this._sessionType;
    }
    set sessionType(value: SessionType) {
        this._sessionType = value;
        this._cdr.markForCheck();
    }
    private _sessionType: SessionType;

    @Input()
    get selected(): TradingDetails {
        return this._selected;
    }
    set selected(value: TradingDetails) {
        if (this.selected !== value) {
            this._selected = value;
            this._setSelection();
            this._cdr.markForCheck();
        }
    }
    private _selected: TradingDetails;
    selection: SessionDetailChart;

    get data(): SessionDetailChart[] {
        return this._data;
    }
    private _data: SessionDetailChart[] = [];

    get hasTradable(): boolean {
        return this._sessionType && this._sessionType === SessionType.Position;
    }

    get hasLimit(): boolean {
        return this._sessionType && (this._sessionType === SessionType.Flex || this._sessionType === SessionType.Generation);
    }

    get hasBidAsk(): boolean {
        return this._sessionType && (this._sessionType === SessionType.Flex || this._sessionType === SessionType.Position);
    }

    get colorInitial(): string {
        return TradingColors.borderColorInitial;
    }

    get colorCurrent(): string {
        return TradingColors.bgColorCurrent;
    }

    get colorPotential(): string {
        return TradingColors.bgColorPotentialNew;
    }

    get borderColorPotential(): string {
        return TradingColors.borderColorPotential;
    }

    get colorTradable(): string {
        return TradingColors.borderColorTradable;
    }

    get colorAsk(): string {
        return TradingColors.borderColorAsk;
    }

    get colorBid(): string {
        return TradingColors.borderColorBid;
    }

    get colorInactive(): string {
        return TradingColors.inactivatedZoneColor;
    }

    get colorClosed(): string {
        return TradingColors.closedZoneColor;
    }

    dataInactive: SessionDetailChart[];
    dataClosed: SessionDetailChart[];

    private _differ: IterableDiffer<TradingDetails>;

    private readonly _tz: TimeZone = TimeZone.create("Europe/Paris");
    constructor(private _cdr: ChangeDetectorRef, private _differs: IterableDiffers,
        private readonly _dateSvc: DateService) {
    }

    ngDoCheck() {
        if (this._differ != null) {
            const diffData = this._differ.diff(this._details);
            if (diffData) {
                diffData.forEachOperation((record: IterableChangeRecord<TradingDetails>,
                    previousIndex: number, currentIndex: number) => {
                    if (previousIndex == null) {
                        this._addData(record.item, currentIndex);
                    } else if (currentIndex == null) {
                        this._removeData(previousIndex);
                    } else {
                        this._moveData(previousIndex, currentIndex);
                    }
                });
                this.dataInactive = _.filter(this._data, (data: SessionDetailChart) => {
                    return data.inactive;
                });
                this.dataClosed = _.filter(this._data, (data: SessionDetailChart) => {
                    return data.closed;
                });
                this._cdr.detectChanges();
            }
        }
    }

    private _switchData(value: TradingDetails[] = []): void {
        this._differ = this._differs.find(value).create();
        this._details = value;
        this._data = [];
    }

    private _addData(detail: TradingDetails, index: number): void {
        const data = new SessionDetailChart();
        this._populateData(detail, data);
        if (this.selected && detail.name === this._selected.name) {
            this.selection = data;
        }
        this._data.splice(index, 0, data);
    }

    private _moveData(previousIndex: number, currentIndex: number): void {
        _ez.move(this._data, previousIndex, currentIndex);
    }

    private _removeData(index: number): void {
        this._data.splice(index, 1);
    }

    private _populateData(detail: TradingDetails, data: SessionDetailChart): void {
        let current: number, potential: number, initial: number, min: number, max: number, ask: number, bid: number, reference: number;
        if (!detail.contractClosed) {
            if (detail.pob) {
                if (detail.pob.askOrders) {
                    ask = Math.round(_.sum(_.map(detail.pob.askOrders, (ask) => -ask.quantity)) * 10) / 10;
                }
                if (detail.pob.bidOrders) {
                    bid = Math.round(_.sum(_.map(detail.pob.bidOrders, (bid) => bid.quantity)) * 10) / 10;
                }
            }
        }

        if (detail.currentValues) {
            if (detail.currentValues.initial != null)
                initial = detail.currentValues.initial.value;
            if (detail.currentValues.current != null)
                current = detail.currentValues.current.value;
            if (detail.currentValues.potential != null)
                potential = detail.currentValues.potential.value;
            if (detail.currentValues.min != null)
                min = detail.currentValues.min.value;
            if (detail.currentValues.max != null)
                max = detail.currentValues.max.value;
            if (detail.currentValues.tradableLimit != null)
                reference = detail.currentValues.tradableLimit.value;
        }
        data.startDate = detail.startDate;
        data.label = this._dateSvc.format(data.startDate, { format: { hours: "long", minutes: "long", timezone: null }, timezone: this._tz });
        data.name = detail.name;
        data.current = current;
        data.potential = potential;
        data.initial = initial;
        data.tradable = reference;
        data.max = max;
        data.min = min;
        data.totalAsk = ask;
        data.totalBid = bid;
        data.inactive = !detail.contractClosed && detail.contractInactivated;
        data.closed = detail.contractClosed;
    }

    private _setSelection(): void {
        if (this.selected) {
            this.selection = _.find(this._data, (d: SessionDetailChart) => { return d.name === this.selected.name; });
        } else {
            this.selection = undefined;
        }
    }

    selectionChartChange(selection: SessionDetailChart): void {
        this.selection = selection;
        if (this.selection) {
            this._selected = _.find(this.details, (d: TradingDetails) => { return d.name === this.selection.name; });
        } else {
            this._selected = undefined;
        }
        this.selectedChange.emit(this._selected);
    }

}
