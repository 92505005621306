import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { EzComponentsModule } from '@eznergy/components';
import { EzNotificationsModule } from '@eznergy/toaster';
import { TranslateModule } from '@ngx-translate/core';
import { AppPipesModule } from '@pipes/pipes.module';

@NgModule({
    imports: [
        EzComponentsModule,
        CommonModule,
        TranslateModule,
        EzNotificationsModule,
        AppPipesModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class CoreComponentsModule { }
