import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EzRouterDirective } from "./router.directive";
import { ScrollFocusDirective } from "./scrollfocus.directive";
import { EzDateValidatorMax, EzDateValidatorMin, EzMaxGradientValueValidator, EzMaxPhValValidator, EzMaxTimespanValidator, EzMinGradientValueValidator, EzMinPhValValidator, EzMinTimespanValidator } from "./validators";
import { VarDirective } from "./variable.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        EzRouterDirective,
        ScrollFocusDirective,
        VarDirective,
        EzMinPhValValidator, EzMaxPhValValidator,
        EzMinTimespanValidator, EzMaxTimespanValidator,
        EzMinGradientValueValidator, EzMaxGradientValueValidator,
        EzDateValidatorMin,
        EzDateValidatorMax
    ],
    exports: [
        EzRouterDirective,
        ScrollFocusDirective,
        VarDirective,
        EzMinPhValValidator, EzMaxPhValValidator,
        EzMinTimespanValidator, EzMaxTimespanValidator,
        EzMinGradientValueValidator, EzMaxGradientValueValidator,
        EzDateValidatorMin,
        EzDateValidatorMax
    ]
})
export class AppDirectivesModule {

}
