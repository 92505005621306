<ez-icon class="icon-banner" *ngIf="iconName" [name]="iconName"></ez-icon>
<div class="container">
    <ng-content select="ez-title"></ng-content>
    <div class="content-container">
        <div class="content">
            <ng-content></ng-content>
        </div>
        <ng-content select="ez-actions"></ng-content>
    </div>
</div>
<ez-icon *ngIf="closable" [name]="'fa-times'" class="icon-close" (click)="onCloseClick()"></ez-icon>
