<ez-button-group class="options" [value]="alignDate" (valueChange)="changeAlignDate($event)">
    <ez-button [big]="true" [value]="dayPosTypes.Start">
        <ez-icon [name]="'align-left'"></ez-icon>
    </ez-button>
    <ez-button [big]="true" [value]="dayPosTypes.Middle">
        <ez-icon [name]="'align-center'"></ez-icon>
    </ez-button>
    <ez-button [big]="true" [value]="dayPosTypes.End">
        <ez-icon [name]="'align-right'"></ez-icon>
    </ez-button>
</ez-button-group>
<ez-select [value]="selectedDays" (valueChange)="selectedDaysChange($event)" [nullable]="false">
    <ez-option *ngFor="let d of days" [value]="d" [disabled]="d > maxDays">{{d}} Days</ez-option>
</ez-select>
