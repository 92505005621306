import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageCalculatedCurvePreferenceInfo {
    static load(preferences: string | PageCalculatedCurvePreferenceInfo): PageCalculatedCurvePreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageCalculatedCurvePreferenceInfo>(preferences, PageCalculatedCurvePreferenceInfo) as PageCalculatedCurvePreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    curveId: number | undefined;
}

@UserPreferenceClassDecorator
export class PageCalculatedCurvePreference implements IPreferencePage {
    static load(preferences: string | PageCalculatedCurvePreferenceInfo): PageCalculatedCurvePreference {
        if (!preferences) {
            return new PageCalculatedCurvePreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageCalculatedCurvePreferenceInfo.load(preferences);
                return new PageCalculatedCurvePreference(v1.curveId);
            default:
                return new PageCalculatedCurvePreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    curveId: number | undefined;

    constructor(curveId: number | undefined = undefined) {
        this.curveId = curveId;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.curveId = undefined;
        }
    }
}

