<ez-table [data]="gradientItems" [disabled]="disabled">
    <ez-row-header>
        <th colspan="2" class="gd-times">
            <ng-content select="ez-label"></ng-content>
        </th>
        <th class="gd-strat">Strategy</th>
        <th class="gd-sell">Seller - Add. margin {{ defaultValue?.unit?.name ? '('+defaultValue?.unit?.name+')' : '' }}
        </th>
        <th class="gd-buy">Buyer - Add. margin {{ defaultValue?.unit?.name ? '('+defaultValue?.unit?.name+')' : '' }}
        </th>
        <th class="gd-actions"></th>
    </ez-row-header>
    <ez-column property="from">
        <td *ezTbCell="let item" class="gd-time" [ngClass]="{'has-error': item.onError}">
            {{item.from.totalMinutes}}
        </td>
    </ez-column>
    <ez-column property="to">
        <td *ezTbCell="let item; index as i" class="gd-time" [ngClass]="{'has-error': item.onError}">
            <span class="ez-icon-infiny" *ngIf="item.to == undefined; else to"></span>
            <ng-template #to>
                <ez-input-number #input [disabled]="disabled" [value]="item.to?.totalMinutes"
                    (valueChange)="onToChange($event, i)"></ez-input-number>
            </ng-template>
        </td>
    </ez-column>
    <ez-column property="strategy">
        <td *ezTbCell="let item; index as i" class="gd-strat" [ngClass]="{'has-error': item.onError}">
            <ez-select [disabled]="disabled" [placeholder]="'Choose a strategy'" [value]="item.strategy"
                (valueChange)="onStrategyValueChange($event, i)" [required]="true">
                <ez-select-option [disabled]="disabled" *ngFor="let s of strategies | enumStringToIterable"
                    [value]="s['value']">
                    {{'autotrading.market.strategies.' + s['value'] | translate }}
                </ez-select-option>
            </ez-select>
        </td>
    </ez-column>
    <ez-column property="askPriceOffset">
        <td *ezTbCell="let item; index as i" class="gd-sell" [ngClass]="{'has-error': item.onError}">
            <ez-input-number [disabled]="disabled" [value]="item.askPriceOffset"
                (valueChange)="onSellerValueChange($event, i)"></ez-input-number>
        </td>
    </ez-column>
    <ez-column property="bidPriceOffset">
        <td *ezTbCell="let item; index as i" class="gd-buy" [ngClass]="{'has-error': item.onError}">
            <ez-input-number [disabled]="disabled" [value]="item.bidPriceOffset"
                (valueChange)="onBuyerValueChange($event, i)"></ez-input-number>
        </td>
    </ez-column>
    <ez-column property="delete">
        <td *ezTbCell="let item; index as i" class="gd-actions" [ngClass]="{'has-error': item.onError}">
            <ez-button *ngIf="item.to === undefined" [disabled]="disabled" [icon]="'plus'" (onClick)="onAddClick()"
                [small]="true">
            </ez-button>
            <ez-button *ngIf="item.to !== undefined" [icon]="'minus'" (onClick)="deleteRow(i)"
                [disabled]="(gradientItems && gradientItems.length == 1 && !gradientItems.from && !gradientItems.to) || disabled"
                [small]="true"></ez-button>
        </td>

    </ez-column>
</ez-table>
