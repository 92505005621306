// @ts-nocheck
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "formatNominationDirection" })
export class FormatNominationDirectionPipe implements PipeTransform {

    constructor(private _translateService: TranslateService) {
    }

    /**
     * Format value of the nomination direction
     * @param value  Origin value from api
     * @returns {string} nomination direction formated
     */
    transform(value: string): string {
        switch (value) {
            case "ENVOI":
                return this._translateService.instant("nominations.messages.outgoing");
            case "RECEPTION":
                return this._translateService.instant("nominations.messages.incoming");
            default:
                return "";
        }
    }
}
