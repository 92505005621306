// @ts-nocheck
import {
    Attribute,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Optional,
    Output,
    Self,
    ViewEncapsulation,
    Renderer2,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { GradientItemsBaseComponent, GradientItemView } from './gradient-items-base.component';
// Components
import { EzFormControl } from "@eznergy/components";
import { Timespan } from "@eztypes/generic";
// Models
import {
    IPhysicalValue,
    ITimeGradientItem,
    IUnit,
    PhysicalValue,
    TimeGradientItem,
} from "@eztypes/webapi";

class GradientItemValueView extends GradientItemView {
    unit: IUnit;
}

@Component({
    selector: "ez-gradient-value",
    templateUrl: "./gradient-value.component.html",
    styleUrls: ["./gradient-value.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: EzFormControl, useExisting: GradientValueComponent },
    ],
})
export class GradientValueComponent extends GradientItemsBaseComponent<
    GradientItemValueView,
    ITimeGradientItem[]
> {
    @Output() valueChange: EventEmitter<ITimeGradientItem[]> =
        new EventEmitter();

    @Input()
    set defaultValue(value: IPhysicalValue) {
        if (this.defaultValue !== value) {
            this._defaultValue = value;
            this._setDefaultValues();
            this._cdr.markForCheck();
        }
    }
    get defaultValue(): IPhysicalValue {
        return this._defaultValue;
    }
    private _defaultValue: IPhysicalValue = new PhysicalValue(undefined, 0);

    @Input()
    set value(values: ITimeGradientItem[]) {
        if (this.value !== values) {
            this._init(values);
            this._cdr.markForCheck();
        }
    }
    get value(): ITimeGradientItem[] {
        return this._oldValues;
    }
    private _oldValues: ITimeGradientItem[];

    @Input()
    get min(): number {
        return this._min;
    }
    set min(value: number) {
        if (value !== this.min) {
            this._min = value;
            this._cdr.markForCheck();
        }
    }
    private _min: number;

    @Input()
    get max(): number {
        return this._max;
    }
    set max(value: number) {
        if (value !== this.min) {
            this._max = value;
            this._cdr.markForCheck();
        }
    }
    private _max: number;

    private _changedValue: boolean;
    private _hasMinMaxError: boolean;
    displayedUnit: IUnit;

    _ctor: { new (): GradientItemValueView } = GradientItemValueView;

    constructor(
        renderer2: Renderer2,
        elementRef: ElementRef,
        cdr: ChangeDetectorRef,
        @Attribute("tabindex") tabIndex: string,
        @Attribute("id") id: string,
        @Self() @Optional() ngControl: NgControl
    ) {
        super(renderer2, elementRef, cdr, tabIndex, id, ngControl);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    deleteRow(index: number) {
        if (this.disabled) return;

        if(index == 0 && this.gradientItems.length == 2) {
            this._init(this._toIGradientItemValue().splice(-1));
        } else {
            super.deleteRow(index);
            this._emitGradientItemsChange();
            this._focusByIndex(index);
        }
        this._cdr.detectChanges();
    }

    onFactorChange(value: number, index: number) {
        if (this.disabled) return;
        let item = this.gradientItems[index];
        item.value = value;
        this._checkValidityItems();
        this._emitGradientItemsChange();
    }

    onToChange(value: number, index: number) {
        if (this.disabled) return;
        super.onToChange(value, index);
        this._emitGradientItemsChange();
    }

    onAddClick() {
        if (this.disabled) return;
        let item = this.addRow();
        this._setDefaultValue(item);
        this._checkValidityItems();
        this._emitGradientItemsChange();
        this._cdr.detectChanges();
        this._focusByIndex(this.gradientItems.length - 1);
    }

    _checkValidityItems(): void {
        super._checkValidityItems();
        const hasMin = this._min != null;
        const hasMax = this._max != null;
        this._hasMinMaxError = false;
        if (hasMin || hasMax) {
            let hasError: boolean;
            let i = this.gradientItems.length - 1;
            while (i >= 0) {
                let item = this.gradientItems[i];
                if (
                    (hasMin && item.value < this._min) ||
                    (hasMax && item.value > this._max)
                ) {
                    item.onError = true;
                    hasError = true;
                }
                i--;
            }
            this._hasMinMaxError = hasError;
        }
    }

    private _emitGradientItemsChange() {
        this._oldValues =
            !this.hasError || this._hasMinMaxError
                ? this._toIGradientItemValue()
                : undefined;
        this._onChange(this._oldValues);
        this.valueChange.emit(this._oldValues);
    }

    private _setDefaultValues() {
        this._changedValue = false;
        if (this.gradientItems)
            this.gradientItems.forEach((value) => {
                this._setDefaultValue(value);
            });
    }

    private _setDefaultValue(item: GradientItemValueView) {
        if (
            this._defaultValue &&
            (item.value == undefined || item.value == null)
        ) {
            item.value = this._defaultValue.value;
            this._changedValue = true;
        }
        if (item.unit == undefined || item.unit == null) {
            let unit =
                (this.gradientItems.length &&
                    this.gradientItems[this.gradientItems.length - 1].unit) ||
                (this._defaultValue && this._defaultValue.unit);
            item.unit = unit;
            this._changedValue = true;
        }
    }

    private _init(values: ITimeGradientItem[]) {
        let itemsView: GradientItemValueView[] = [];
        if (values) {
            values.forEach((item: ITimeGradientItem) => {
                let itemView = new GradientItemValueView();
                itemView.from = item.from;
                if (item.to) itemView.to = item.to;
                itemView.unit = item.unit;
                itemView.value = item.offset;
                itemView.onError = false;
                itemsView.push(itemView);
            });
        }
        let newItemsView = this.init(itemsView);
        this._setDefaultValues();
        this._checkValidityItems();
        this._oldValues = this._toIGradientItemValue();
        if (itemsView.length != newItemsView.length || this._changedValue) {
            this._emitGradientItemsChange();
        }
    }

    private _toIGradientItemValue(): ITimeGradientItem[] {
        let items = this.gradientItems;
        let internalItems: ITimeGradientItem[];
        internalItems = [];
        items.forEach((item) => {
            let it = new TimeGradientItem();
            it.from = item.from as Timespan;
            if (item.to) it.to = item.to as Timespan;
            it.offset = item.value;
            it.unit = item.unit;
            internalItems.push(it);
        });
        return internalItems;
    }

    writeValue(value: any): void {
        this.value = value;
        super.writeValue(this.value);
    }
}
