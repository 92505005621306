// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@core';
import { BasePagePopup } from '@core/pages';
import { isSame } from '@eznergy/core';
import { ApiService } from '@eznergy/webapi';
import { IDeal, IGrid, IOrganisation } from '@eztypes/webapi';
import { DealFormComponent } from './deal-form/deal-form.component';
import { ApplicationService } from '@services/application.service';
import { DataService } from '@services/data.service';
import { DataUpdateService } from '@services/deals.service';
import { AppPages, RouterService } from '@services/router.service';

@Component({
    selector: 'deal-form-page',
    templateUrl: './deal-popup-page.component.html',
    styleUrls: ['./deal-popup-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealPopupPage extends BasePagePopup implements OnInit {

    @ViewChild(DealFormComponent, { static: true }) readonly form: DealFormComponent;

    private _selectedShipperId: number;
    private _dealId: number;
    private _gridId: number;

    get urlPopup(): string {
        if (!this._srvRouter || !this._selectedShipperId || !this._dealId)
            return undefined;
        return this._srvRouter.getUrlPopup(AppPages.DealForm, { shipperId: this._selectedShipperId.toString(), id: this._dealId.toString(), gridid: this._gridId?.toString() });
    }

    deal: IDeal;
    shipper: IOrganisation;

    constructor(private _srvRouter: RouterService,
        private _route: ActivatedRoute,
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private _cdr: ChangeDetectorRef,
        private _dataUpdateService: DataUpdateService,
        logger: Logger,
        private readonly _dataSvc: DataService
    ) {
        super(logger, 'deal-form');
        this.titlePopup = "Create deal";
    }

    ngOnInit() {
        let sub = this._route.paramMap.subscribe((param) => {
            if (param.has("shipperId")) {
                let shipperId = parseInt(param.get('shipperId'));
                if (shipperId !== this._selectedShipperId) {
                    this._selectedShipperId = shipperId;
                    this._loadShipper();
                }
                if (param.has("id")) {
                    if (param.has("gridid")) {
                        let gridId = parseInt(param.get('gridid'));
                        if (isNaN(gridId)) {
                            gridId = undefined;
                        }
                        this._gridId = gridId;
                    }
                    let id = parseInt(param.get('id'));
                    if (isNaN(id)) {
                        this._dealId = undefined;
                        this.titlePopup = "Create deal";
                    }
                    else if (this._dealId !== id) {
                        this._dealId = id;
                        this.titlePopup = "Edit deal";
                        this._loadSelectedDeal();
                    }

                } else {
                    this._loadSelectedDeal();
                }
            }
            this._cdr.detectChanges();
        });
        this._subscriptions.push("paramMap", sub);

        const subData = this._dataSvc.changes.subscribe((data) => {
            this._loadShipper();
            this._cdr.detectChanges();
        });
        this._subscriptions.push("data-changes", subData);
    }

    canDeactivate() {
        if (this.form) {
            return this.form.canClosedForm();
        }
        return true;
    }

    onDealChange(deal: IDeal): void {
        this._dataUpdateService.feedDeal(deal);
        this._srvRouter.closeOutletPopup();
    }

    private _loadSelectedDeal(): void {
        this.deal = undefined;
        if (this._dealId) {
            const grid = this._dataSvc.allGrids?.find((g: IGrid) => g.id === this._gridId);

            let deal = this._api.balancings.deals.get(this._appSvc.contract.id, this._dealId.toString());
            let sub = deal.subscribe((deal: IDeal) => {
                if (deal) {
                    this.deal = deal;
                    this.deal.grid = grid;
                    this._cdr.detectChanges();
                }
            });
            this._subscriptions.push('selectedDeal', sub);
        }
    }

    private _loadShipper(): void {
        const shipper = this._dataSvc.shippers?.find((sh: IOrganisation) => sh.id === this._selectedShipperId);
        if (!isSame(shipper, this.shipper)) {
            this.shipper = shipper;
        }
    }
}
