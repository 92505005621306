<div class="select-container" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
    <app-loader *ngIf="isTimeseriesLoading"></app-loader>
    <ez-icon name="filter" class="btn-filters" (click)="toggleFiltersPanel()" [ngClass]="{'selected': hasFilters, 'show': shouldLabelFloat }"
        [ezTooltip]="'general.filters' | translate">
    </ez-icon>
    <input matInput type="text"
           #autoInput
           [formControl]="displayFormControl"
           [placeholder]="placeholder"
           [matAutocomplete]="auto"
           tabindex="-1" id="{{id}}-select">
    <ez-icon name="chevron-down" class="chevron"></ez-icon>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayProperty">
        <mat-option *ngIf="mainFormControl.value" [value]="null">Remove selection</mat-option>
        <mat-option *ngFor="let curve of timeseries | filterBy : 'name' : displayFormControl.value" [value]="curve">
            {{ curve.name }}{{curve.unit ? ' (' + curve.unit.name + ')' : '' }}
        </mat-option>
    </mat-autocomplete>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="panelFiltersIsOpen"
             [cdkConnectedOverlayHasBackdrop]="panelFiltersIsOpen"
             [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop-cs'"
             (backdropClick)="panelFiltersIsOpen = !panelFiltersIsOpen">
    <div class="filter-panel" tabindex="-1">
        <app-loader *ngIf="isTimeseriesGroupsLoading"></app-loader>
        <div class="filter-row">
            <div class="filter-item filter-computed" *ngIf="!hideFilterCalculated">
                <mat-label>
                    {{'timeseries.global.calculated' | translate}}
                </mat-label>
                <mat-button-toggle-group [(ngModel)]="calculated" name="filterCalculated" aria-label="filterCalculated" [hideSingleSelectionIndicator]="true">
                    <mat-button-toggle [value]="undefined">{{ 'general.all' | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="true">{{ 'general.yes' | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="false">{{ 'general.no' | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="filter-item filter-locked" *ngIf="!hideFilterLocked">
                <mat-label>
                    {{'timeseries.global.locked' | translate}}
                </mat-label>
                <mat-button-toggle-group [(ngModel)]="locked" name="filterLocked" aria-label="filterLocked">
                    <mat-button-toggle [value]="undefined">{{ 'general.all' | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="true">{{ 'general.yes' | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="false">{{ 'general.no' | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="filter-item filter-labels" [formGroup]="groupsForm">
            <mat-form-field>
                <mat-label>
                    {{ 'timeseries.global.groups' | translate }}
                </mat-label>
                <input matInput formControlName="search">
            </mat-form-field>
            <ul class="ts-groups" formArrayName="selectedGroups">
                <li *ngFor="let group of timeseriesGroups | filterBy : 'path' : groupsForm.controls.search.value ; let i = index">
                    <mat-checkbox [formControlName]="i" (change)="onGroupSelected($event, group)">
                        {{ group.path }}
                    </mat-checkbox>
                </li>
            </ul>
        </div>
    </div>
</ng-template>
