import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Logger } from "@core/logger";
import { isSame } from "@eznergy/core";
import { ApiService } from "@eznergy/webapi";
import { ApplicationService } from "@services/application.service";
import { AppPages, RouterService } from "@services/router.service";
import { Observable } from "rxjs";

export const ContractRouterService: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  => {
    const appService = inject(ApplicationService); 
    const api = inject(ApiService); 
    const logger = inject(Logger); 
    const router = inject(RouterService); 

    if (appService.user == null) {
        return router.getUrlTree(AppPages.Login, { queryParams: { "returnUrl": state.url } });
    }
    const contract = appService.user.contracts.find((c) => c.contractGuid === route.params['contractGuid']);
    if (contract == null) {
        return router.getUrlTree(AppPages.Dashboard, { contractId: appService.user.contracts[0]!.contractGuid });
    } else if (isSame(contract, appService.contract)) {
        return true;
    }
    return new Observable((obs) => {
        api.contract.rights(contract.id).subscribe((rights) => {
            appService.setContract(contract, rights);
            obs.next(true);
            obs.complete();
        }, (error) => {
            logger.fatal('get rights by contract', error);
            obs.next(router.getUrlTree(AppPages.Dashboard, { contractId: appService.user!.contracts[0]!.contractGuid }));
            obs.complete();
        });
    });
}