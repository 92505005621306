<ez-tabs [value]="selectedTabIndex" (valueChange)="onSelectedTabChange($event)">
    <ez-tab-section [value]="0">
        <ez-tab-title>{{ 'balancing.ets.tabs.summary' | translate}}</ez-tab-title>
        <ez-content>
            <ets-market-result-summary *ngIf="!selectedTabIndex" [marketData]="marketData" [profile]="profile"
                [grid]="grid" [area]="area">
            </ets-market-result-summary>
        </ez-content>
    </ez-tab-section>
    <ez-tab-section *ngFor="let portfolio of portfolios, let i = index " [value]="i + 1">
        <ez-tab-title>{{portfolio.portfolio.name}}</ez-tab-title>
        <ez-content>
            <ets-market-result-portfolio *ngIf="selectedTabIndex == i + 1" [marketData]="marketData"
                [portfolio]="portfolio">
            </ets-market-result-portfolio>
        </ez-content>
    </ez-tab-section>
</ez-tabs>
