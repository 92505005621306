import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IDateRange } from "@eztypes/generic";
import { TypeMessage } from '../../../modules/nomination/components/schedules/params/parameters-nomination-schedule.component';
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageNominationBatchPreferenceInfo {
    static load(preferences: string | PageNominationBatchPreferenceInfo): PageNominationBatchPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageNominationBatchPreferenceInfo>(preferences, PageNominationBatchPreferenceInfo) as PageNominationBatchPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    maxItems: number | undefined;
    refreshTimer: number | undefined;
    withDate: boolean | undefined;
    deliveryDates: IDateRange | undefined;
    typeMessage: TypeMessage | undefined;
}

@UserPreferenceClassDecorator
export class PageNominationBatchPreference implements IPreferencePage {
    static load(preferences: string | PageNominationBatchPreferenceInfo): PageNominationBatchPreference {
        if (!preferences) {
            return new PageNominationBatchPreference();
        }

        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageNominationBatchPreferenceInfo.load(preferences);
                return new PageNominationBatchPreference(
                    v1.maxItems,
                    v1.refreshTimer,
                    v1.withDate,
                    v1.deliveryDates,
                    v1.typeMessage
                );
            default:
                return new PageNominationBatchPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    maxItems: number | undefined;
    @UPPropertySave
    refreshTimer: number | undefined;

    withDate: boolean | undefined;
    deliveryDates: IDateRange | undefined;
    typeMessage: TypeMessage | undefined;

    constructor(
        maxItems: number = 100,
        refreshTimer: number | undefined = undefined,
        withDate?: boolean | undefined,
        deliveryDates?: IDateRange | undefined,
        typeMessage?: TypeMessage | undefined
    ) {
        this.maxItems = maxItems;
        this.refreshTimer = refreshTimer;
        this.withDate = withDate;
        this.deliveryDates = deliveryDates;
        this.typeMessage = typeMessage;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byDate) {
            this.deliveryDates = undefined;
            this.withDate = undefined;
        }
        if (options.all) {
            this.maxItems = undefined;
            this.typeMessage = undefined;
            this.refreshTimer = undefined;
        }
    }
}

