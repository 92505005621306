// @ts-nocheck
import { HttpClient } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppCoreModule } from "@core/core.module";
import { AppFormsModule } from "@forms/forms.module";
import { TranslateModule } from "@ngx-translate/core";
import { AppEventsPage } from "./events.page";

@NgModule({
    imports: [
        AppCoreModule,
        AppFormsModule,
        TranslateModule
    ],
    declarations: [
        AppEventsPage
    ],
    exports: [
        AppEventsPage
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventsPagesModule {

}
