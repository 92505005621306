// @ts-nocheck
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
// interface for date TODO change place
import "./app/models/core/string.model";
import { AppModule } from "./app/modules/module";
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch((err) => console.log(err));
