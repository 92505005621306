// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { IEtsArea, IEtsClient, IEtsMarketResult, IGrid } from "@eztypes/webapi";
import { PortfolioMarket } from "@models/api/ets/etsMarketResult.model";
import * as _ from "lodash";

@Component({
    selector: 'ets-market-result',
    templateUrl: 'ets-market-result.component.html',
    styleUrls: ['ets-market-result.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EtsMarketResultComponent {

    @Input()
    get marketData(): IEtsMarketResult {
        return this._marketData;
    }
    set marketData(value: IEtsMarketResult) {
        if (!_.isEqual(this._marketData, value)) {
            this._marketData = value;

            this.splitData();
            this._cdr.detectChanges();
        }
    }
    private _marketData: IEtsMarketResult;

    @Input()
    get profile(): IEtsClient {
        return this._profile;
    }
    set profile(value: IEtsClient) {
        if (!_.isEqual(this._profile, value)) {
            this._profile = value;
            this._cdr.detectChanges();
        }
    }
    private _profile: IEtsClient;

    @Input()
    get grid(): IGrid {
        return this._grid;
    }
    set grid(value: IGrid) {
        if (!_.isEqual(this._grid, value)) {
            this._grid = value;
            this._cdr.detectChanges();
        }
    }
    private _grid: IGrid;

    @Input()
    get area(): IEtsArea {
        return this._area;
    }
    set area(value: IEtsArea) {
        if (!_.isEqual(this._area, value)) {
            this._area = value;
            this._cdr.detectChanges();
        }
    }
    private _area: IEtsArea;

    portfolios: PortfolioMarket[];
    summaryLabel: string;

    selectedTabIndex: number;

    constructor(private _cdr: ChangeDetectorRef) { }

    ngOnDestroy() {
        this._clearOlDisplay();
    }

    private _clearOlDisplay() {
        this.portfolios = undefined;
        this.summaryLabel = undefined;
    }

    onSelectedTabChange(event: any) {
        this.selectedTabIndex = event;
        this._cdr.detectChanges();
    }

    splitData() {
        if (this.marketData == undefined) {
            this.portfolios = undefined;
            this._cdr.detectChanges();
        } else {
            this.portfolios = _.orderBy(this._marketData.portfolios, ['portfolio.name'], ['asc']);
        }
    }
}
