// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from "@angular/core";
import { I18nService, IFormatDate, typeFormatDate } from "@eznergy/components";
import { DateRange, DateTime, TimeZone } from "@eztypes/generic";
import { ApplicationService } from "@services/application.service";
import { IFormatDate as FormatDate } from "@eztypes/generic";
import { ICalendar, IGrid } from "@eztypes/webapi";
import { Subscriptions } from "@core/subscriptions";
import { merge } from "rxjs";

@Component({
    selector: 'date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateComponent {

    @Input()
    get value(): DateRange | DateTime {
        return this._value;
    }
    set value(value: DateRange | DateTime) {
        if (value !== this.value) {
            this._value = value;
            this._refresh();
            this._cdr.markForCheck();
        }
    }
    private _value: DateRange | DateTime;

    @Input()
    get to(): DateTime {
        return this._to;
    }
    set to(value: DateTime) {
        if (value !== this.to) {
            this._to = value;
            this._refresh();
            this._cdr.markForCheck();
        }
    }
    private _to: DateTime;

    @Input()
    get format(): typeFormatDate | IFormatDate | FormatDate {
        return this._format;
    }
    set format(value: typeFormatDate | IFormatDate | FormatDate) {
        if (this.format !== value) {
            this._format = value;
            this._refresh();
            this._cdr.markForCheck();
        }
    }
    private _format: typeFormatDate | IFormatDate | FormatDate;

    @Input()
    get grid(): IGrid {
        return this._grid;
    }
    set grid(value: IGrid) {
        if (this.grid !== value) {
            this._grid = value;
            this._refresh();
            this._cdr.markForCheck();
        }
    }
    private _grid: IGrid;

    @Input()
    get calendar(): ICalendar {
        return this._calendar;
    }
    set calendar(value: ICalendar) {
        if (value !== this.calendar) {
            this._calendar = value;
            this._refresh();
            this._cdr.markForCheck();
        }
    }
    private _calendar: ICalendar;

    @Input()
    get timezone(): TimeZone {
        return this._timezone;
    }
    set timezone(value: TimeZone) {
        if (this.timezone !== value) {
            this._timezone = value;
            this._refresh();
            this._cdr.markForCheck();
        }
    }
    private _timezone: TimeZone;

    @Input()
    get onlyDate(): boolean {
        return this._onlyDate;
    }
    set onlyDate(value: boolean) {
        if (this.onlyDate !== value) {
            this._onlyDate = value;
            this._cdr.markForCheck();
        }
    }
    private _onlyDate: boolean = false;

    get hasTzOverride(): boolean {
        return this._hasTzOverride;
    }
    private _hasTzOverride: boolean;

    get hasTZA(): boolean {
        return this._appSvc.preferences.tzActivate;
    }

    get empty(): boolean {
        return !this.text;
    }

    get text(): string {
        return this._text;
    }
    private _text: string;

    get tz(): TimeZone {
        return this._tz;
    }
    private _tz: TimeZone;
    private readonly _subs: Subscriptions = new Subscriptions();

    private _isInit: boolean = false;

    constructor(private readonly _cdr: ChangeDetectorRef,
        private readonly _appSvc: ApplicationService,
        private readonly _i18nSvc: I18nService) { }

    ngOnInit(): void {
        this._isInit = true;
        const sub = merge(this._i18nSvc.hourCycleChange, this._appSvc.timezoneChange).subscribe(() => {
            this._refresh();
            this._cdr.detectChanges();
        });
        this._subs.push("tzchange", sub);
        this._refresh();
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
    }

    private _refresh(): void {
        if (this._isInit) {
            let newTz: TimeZone = TimeZone.current();

            if (this.hasTZA) {
                newTz = this._appSvc.timezone;
            } else if (this.timezone != null) {
                newTz = this.timezone;
            } else if (this.calendar?.ianaName != null) {
                newTz = TimeZone.create(this.calendar.ianaName);
            } else if (this.grid?.calendar?.ianaName != null) {
                newTz = TimeZone.create(this.grid.calendar.ianaName);
            }
            this._tz = newTz;
            if (this.grid?.calendar?.ianaName != null) {
                this._hasTzOverride = this.tz?.info?.iana !== this.grid.calendar.ianaName;
            } else if (this.timezone != null) {
                this._hasTzOverride = this.tz?.info?.iana !== this.timezone.name;
            } else {
                this._hasTzOverride = this.hasTZA;
            }
            const date = this.to != null && this.value instanceof DateTime ? new DateRange(this.value, this.to) : this.value;
            this._text = this._i18nSvc.formatDate(date, { format: this.format, timezone: this._tz });
        }
    }

}
