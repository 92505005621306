// @ts-nocheck
import { EventEmitter, Input, Output, ViewChild, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EzForm } from '@eznergy/components';
import * as _ez from "@eznergy/core";
import { CanComponentDeactivate } from '@services/can-deactivate.guard';
import { IDataParams, IQueryParams, RouterService } from '@services/router.service';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { Logger } from "./logger";
import { MultiLoader } from './multiloader';
import { Subscriptions } from "./subscriptions";
import { auditTime } from 'rxjs/operators';

@Directive()
export class BasePage {
    get pageHasLoading(): boolean {
        return this._loaderPage.onLoading;
    }
    readonly _loaderPage: MultiLoader = new MultiLoader();

    get _subs(): Subscriptions {
        return this._subscriptions;
    }
    readonly _subscriptions: Subscriptions = new Subscriptions();

    constructor(protected _logger: Logger) {
        this._loaderPage.push("init-page");
    }

    ngAfterContentInit(): void {
        this._loaderPage.remove("init-page");
    }

    ngOnDestroy(): void {
        if (this._subscriptions) {
            this._subscriptions.clearAll();
        }
    }
}
@Directive()
export class BasePagePopup extends BasePage implements CanComponentDeactivate {

    titlePopup: string;
    readonly cssClassPopup: string;
    get urlPopup(): string {
        return undefined;
    }
    get urlPage(): string {
        return undefined;
    }

    constructor(logger: Logger, cssClass: string = "") {
        super(logger);
        this.cssClassPopup = "outlet-popup" + (cssClass ? " " + cssClass : "");
    }

    canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
        return true;
    }
}

@Directive()
export class BaseParameters<D extends IDataParams, P extends IQueryParams> {

    get pageHasLoading(): boolean {
        return this._loaderPage.onLoading;
    }
    _loaderPage: MultiLoader = new MultiLoader();

    @ViewChild(EzForm, { static: true }) readonly formParams: EzForm;

    @Output() readonly parametersChange: EventEmitter<P> = new EventEmitter<P>();

    @Input()
    get beforeChangeConfirm(): () => Promise<boolean> {
        return this._beforeChangeConfirm;
    }
    set beforeChangeConfirm(value: () => Promise<boolean>) {
        if (this.beforeChangeConfirm !== value) {
            this._beforeChangeConfirm = value;
        }
    }
    private _beforeChangeConfirm: () => Promise<boolean>;

    _emitParametersChanges: Subject<boolean> = new Subject<boolean>();

    _data: D;
    _params: P;

    // _isLoading: boolean = false;

    _subs: Subscriptions = new Subscriptions();

    constructor(protected _routerSvc: RouterService) {
        let rpUrl: boolean = false;

        let sub = this._emitParametersChanges.pipe(auditTime(50)).subscribe((replaceUrl: boolean) => {
            if (this.formParams) {
                rpUrl = replaceUrl;
                if (!this._subs.has("formParamsSubmit")) {
                    let sub = this.formParams.onSubmit.subscribe(() => {
                        this._emitAfterSubmit(rpUrl);
                    });
                    this._subs.push("formParamsSubmit", sub);
                }
                this.formParams.submit();
            } else {
                this._emitParameters(replaceUrl);
            }
        });
        this._subs.push("parametersChange", sub);
        this._loaderPage.push("init-page");
    }

    ngAfterContentInit(): void {
        this._loaderPage.remove("init-page");
    }

    ngOnDestroy(): void {
        if (this._subs) {
            this._subs.clearAll();
        }
    }

    protected _initData(): Promise<void> {
        return new Promise((resolve, _reject) => {
            const key = "init-data";
            this._loaderPage.push(key);
            this._routerSvc.initParams(this._data);

            if (this._subs.has("queryParamsChange")) {
                this._subs.clearSub("queryParamsChange");
            }
            let isInit: boolean = true;

            const subCancel = this._routerSvc.navigationCanceled.subscribe(() => {
                this._initParams().then(() => {
                    this._refreshParams();
                });
            });
            this._subs.push("nav-cancel", subCancel);
            const subParams = this._routerSvc.queryParamsChange.subscribe((params) => {
                if (this._data && params != null) {
                    const lastParams = _.clone(this._params);
                    this._initParams().then(() => {
                        this._loaderPage.remove(key);
                        if (isInit) {
                            resolve();
                        }
                        if (isInit || !_ez.isSame(this._params, lastParams)) {
                            this.parametersChange.emit(this._params);
                            this._refreshParams();
                            isInit = false;
                        }
                        this._routerSvc.updateQueryParams(this._params, true);
                    });
                }
            });
            this._subs.push("queryParamsChange", subParams);
        });
    }

    protected _initParams(): Promise<void> {
        this._routerSvc.initQueryParams(this._params);
        return Promise.resolve();
    }

    protected _refreshParams(): void {

    }

    private _emitParameters(replaceUrl?: boolean): void {
        this._routerSvc.updateQueryParams(this._params, replaceUrl);
        this.parametersChange.emit(this._params);
    }

    private _emitAfterSubmit(replaceUrl?: boolean): void {
        this._emitParameters(replaceUrl);
    }
}
