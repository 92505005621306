// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Subscriptions } from '@core';
import { Logger } from '@core/logger';
import { EzDatePipe } from "@eznergy/components";
import { ApiService } from '@eznergy/webapi';
import { DateRange, DateTime, DayUnit, IDateRange, TimeUnit, TimeZone } from '@eztypes/generic';
import { ICommunicationInterface, IGate, IGrid, INominationSchedule, IProductionSchedule, IScheduleForecast, Mode, ScheduleForecast } from "@eztypes/webapi";
import { ApplicationService } from "@services/application.service";
import * as _ from "lodash";
import { Observable } from 'rxjs';
// Constant
import { DefaultValues } from '../../../../../../constants/constant.default-values';
import { scheduleType } from '../create-schedules.component';

@Component({
    selector: "ez-forecasts-schedules",
    templateUrl: "./forecasts-schedules.component.html",
    styleUrls: ["./forecasts-schedules.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ForecastsSchedulesComponent {

    @Output() onCreateForecasts: EventEmitter<INominationSchedule[] | IProductionSchedule[]> = new EventEmitter<INominationSchedule[] | IProductionSchedule[]>();

    activateVersion: boolean;
    minVersion: number = DefaultValues.minVersion;
    maxVersion: number = DefaultValues.maxVersion;
    version: number = 1;
    selectedRadio: Mode = Mode.On;
    private _subs: Subscriptions = new Subscriptions();

    public isLoading: boolean = false;

    @Input()
    get grid(): IGrid {
        return this._grid;
    }
    set grid(value: IGrid) {
        if (this.grid !== value) {
            this._grid = value;
            this._initForm();
            this._cdr.markForCheck();
        }
    }
    private _grid: IGrid;

    @Input()
    get gates(): IGate[] {
        return this._gates;
    }
    set gates(value: IGate[]) {
        if (this.gates !== value) {
            this._gates = value;
            this._cdr.markForCheck();
        }
    }
    private _gates: IGate[];

    @Input()
    get selectedGate(): IGate {
        return this._selectedGate;
    }
    set selectedGate(value: IGate) {
        if (this.selectedGate !== value) {
            this._selectedGate = value;
            this._cdr.markForCheck();
        }
    }
    private _selectedGate: IGate;

    @Input()
    get interfaces(): ICommunicationInterface[] {
        return this._interfaces;
    }
    set interfaces(value: ICommunicationInterface[]) {
        if (this.interfaces !== value) {
            this._interfaces = value;
            this._cdr.markForCheck();
        }
    }
    private _interfaces: ICommunicationInterface[];

    @Input()
    get selectedInterfaces(): ICommunicationInterface[] {
        return this._selectedInterfaces;
    }
    set selectedInterfaces(value: ICommunicationInterface[]) {
        if (this.selectedInterfaces !== value) {
            this._selectedInterfaces = value;
            this._cdr.markForCheck();
        }
    }
    private _selectedInterfaces: ICommunicationInterface[];

    @Input()
    get type(): scheduleType {
        return this._type;
    }
    set type(type: scheduleType) {
        if (this.type !== type) {
            this._type = type;
            this._cdr.markForCheck();
        }
    }
    private _type: scheduleType;

    @Input()
    get value(): INominationSchedule | IProductionSchedule {
        return this._value;
    }
    set value(value: INominationSchedule | IProductionSchedule) {
        if (this.value !== value) {
            this._value = value;
            this._setForecastSchedule();
            this._cdr.markForCheck();
        }
    }
    private _value: INominationSchedule | IProductionSchedule;

    date: DateTime;
    readonly Mode = Mode;


    periodDate: IDateRange;
    sendOnDate: DateTime;

    triggerPeriodDisplay: (date: DateRange) => [string, string] = (date: DateRange) => {
        return this._triggerDatePeriod(date);
    }

    get timezone(): TimeZone {
        return this._timezone;
    }
    private _timezone: TimeZone;

    constructor(private _cdr: ChangeDetectorRef, private _api: ApiService, private _appSvc: ApplicationService, private _logger: Logger, private readonly _datePipe: EzDatePipe) {
        this.version = DefaultValues.minVersion;
    }

    periodDateChange(period: IDateRange): void {
        this.periodDate = period;
        this.periodDate = new DateRange(this.periodDate.from.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true), this.periodDate.to.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true));
        const lastHours = this.date != null ? this.date.hours : 9;
        const lastMinutes = this.date != null ? this.date.minutes : 0;
        this.date = this.periodDate.from.startOf(TimeUnit.Days).addHours(lastHours).addMinutes(lastMinutes);
    }

    sendDateChange(date: DateTime): void {
        this.sendOnDate = date;
    }

    private _triggerDatePeriod(date: DateRange): [string, string] {
        let dateFrom: string = "";
        let dateTo: string = "";
        if (this.grid) {
            if (date.from != null && date.from.isValid === true) {
                const d = date.from.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true);
                dateFrom = this._datePipe.transform(d, { format: { date: "medium", time: "short" } });
            }
            if (date.to != null && date.to.isValid === true) {
                const d = date.to.addDays(1).startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true);
                dateTo = this._datePipe.transform(d, { format: { date: "medium", time: "short" } });
            }
        }
        return [dateFrom, dateTo];
    }

    private _initForm(): void {
        if (this.grid != null) {
            this._timezone = TimeZone.create(this.grid.calendar.ianaName);
            this._cdr.detectChanges();
            const now = new DateTime(undefined, this._timezone.name).startOf(TimeUnit.Days);
            const nextMonday = now.nextDay(DayUnit.Monday, now.dayOfWeek === DayUnit.Monday ? 1 : undefined).addHours(this.grid.calendar.offset, true);
            const followingSunday = nextMonday.nextDay(DayUnit.Sunday).startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true);
            const previousFriday = nextMonday.addDays(-3).startOf(TimeUnit.Days).addHours(15);
            this.periodDate = new DateRange(nextMonday, followingSunday);
            this.sendOnDate = previousFriday;
            this.selectedRadio = Mode.On;
            this.date = this.periodDate.from.startOf(TimeUnit.Days).addHours(9);
            this.minVersion = DefaultValues.minVersion;
            this.maxVersion = DefaultValues.maxVersion;
            this.version = 1;
            this.activateVersion = false;
            this._setForecastSchedule();
            this._cdr.detectChanges();
        }
    }

    private _setForecastSchedule(): void {
        if (this.value != null) {
            this.periodDate = new DateRange(this.value.startDate, this.value.endDate);
            this._selectedGate = this.value.gate;
            this.activateVersion = true;
            this.version = this.value.version;
            this.selectedRadio = Mode.On;
            this.sendOnDate = this.value.scheduleTime;
            this._selectedInterfaces = [this.value.interface];
        }
    }
    private _isPeriodValid(): boolean {
        return this.periodDate != null && this.periodDate.from != null && this.periodDate.to != null;
    }

    private _internalValidForm(): boolean {
        if (this.selectedRadio === Mode.On) {
            return this._isPeriodValid() && this.sendOnDate != null ? true : false;
        }
        return this._isPeriodValid();
    }

    onValidateForm() {
        if (this._internalValidForm()) {
            if (this.selectedGate && (!this.gates || this.gates.length === 0)) {
                this.selectedGate = undefined;
            }
            let item = new ScheduleForecast();
            item.startDate = this.periodDate.from.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true);
            item.endDate = this.periodDate.to.startOf(TimeUnit.Days).addHours(this.grid.calendar.offset, true);
            item.sendMode = this.selectedRadio;
            switch (this.selectedRadio) {
                case Mode.On:
                    item.scheduleTimeOn = this.sendOnDate;
                    break;
                case Mode.DayBefore:
                case Mode.Day:
                case Mode.DayAfter:
                    const hours: number = this.date.hours;
                    item.scheduleHour = hours;
                    item.scheduleMinute = this.date.minutes;
                    break;
                default:
                    throw new Error("Unknown send mode ...");
            }
            item.version = this.activateVersion === true ? this.version : null;
            item.gateId = this._selectedGate ? this._selectedGate.id : null;
            item.interfaceIds = _.flatMap(this._selectedInterfaces, (obj: ICommunicationInterface) => {
                return obj.id;
            });
            item.calendarId = this._grid.calendar.id;
            this._onCreateForecasts(item);
        }
    }

    private _onCreateForecasts(properties: IScheduleForecast): void {
        this.isLoading = true;
        if (this._type) {
            let obs: Observable<INominationSchedule[] | IProductionSchedule[]> = null;
            let path: string = this._type + "createForecasts";
            switch (this._type) {
                case 'nominations':
                    obs = this._api.nominations.schedules.createForecasts(this._appSvc.contract.id, properties);
                    break;
                case 'productions':
                    obs = this._api.productions.schedules.createForecasts(this._appSvc.contract.id, properties);
                    break;
                default:
                    return;
            }
            this._subs.clearSub("createForecasts");
            let sub = obs.subscribe(
                (resp: INominationSchedule[] | IProductionSchedule[]) => {
                    this.isLoading = false;
                    this.onCreateForecasts.emit(resp);
                },
                (error) => {
                    this.isLoading = false;
                    this._logger.error({ message: 'error call ' + path, data: this._appSvc.contract.id }, error);
                },
                () => {
                    this.isLoading = false;
                    this._cdr.detectChanges();
                }
            );
            this._subs.push("createForecasts", sub);
        }
    }
}
