// @ts-nocheck
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import { Subscriptions } from "@core";
import * as _ez from "@eznergy/core";
import { ApiService } from "@eznergy/webapi";
import { DateRange, DateTime, TimeUnit } from '@eztypes/generic';
import { EtsCancelOrderData, IEtsArea, IEtsClient, IEtsLinearOrder, IEtsMarketResult, IEtsPortfolio, IFlatPosition, IGrid, TimeGranularity, UserRight } from "@eztypes/webapi";
import { ApplicationService } from "@services/application.service";
// config global
import * as _ from "lodash";
// Pages
import {
    EtsOrderLinearTableComponent,
    EventStatusOlTable,
} from "./ets-order-linear-table.component";

@Component({
    selector: "ets-order-linear-panel",
    templateUrl: "./ets-order-linear.component.html",
    styleUrls: ["./ets-order-linear.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EtsOrderLinearComponent {
    @Output() readonly refresh: EventEmitter<void> = new EventEmitter();

    @ViewChild("olEdit", { static: true })
    dataTable: EtsOrderLinearTableComponent;

    @Input()
    get marketDataResult(): IEtsMarketResult {
        return this._marketDataResult;
    }
    set marketDataResult(value: IEtsMarketResult) {
        this._marketDataResult = value;
        this._checkMarketData();
        this._cdr.detectChanges();
    }
    private _marketDataResult: IEtsMarketResult;

    @Input()
    set profile(profile: IEtsClient) {
        if (!this._profile || !profile || this._profile.id != profile.id) {
            this._profile = profile;
            this._isCmpInit = true;
            this._clearOlDisplay();
            this._requestOrderLinear();
        }
    }
    private _profile: IEtsClient;

    @Input()
    set area(area: IEtsArea) {
        if (!this._area || !area || this._area.id != area.id) {
            if (!area) this.btnDisable = true;
            this._area = area;
            this._isCmpInit = true;
            this._clearOlDisplay();
            this._requestPosition();
            this._requestOrderLinear();
        }
    }
    get area(): IEtsArea {
        return this._area;
    }
    _area: IEtsArea;

    @Input()
    set grid(value: IGrid) {
        if (!this._grid || !value || this._grid.id != value.id) {
            this._grid = value;
            this._isCmpInit = true;
            this._clearOlDisplay();
            this._requestPosition();
            this._requestOrderLinear();
        }
    }
    private _grid: IGrid;

    @Input()
    set portfolio(portfolio: IEtsPortfolio) {
        if (
            !this._portfolio ||
            !portfolio ||
            this._portfolio.id != portfolio.id
        ) {
            this._isCmpInit = true;
            this._portfolio = portfolio;
            this._clearOlDisplay();
            this._requestOrderLinear();
        }
    }
    get portfolio(): IEtsPortfolio {
        return this._portfolio;
    }
    private _portfolio: IEtsPortfolio;

    @Input()
    set deliveryDate(deliveryDate: DateTime) {
        if (
            !this._deliveryDate ||
            !deliveryDate ||
            !_ez.isSame(this._deliveryDate, deliveryDate)
        ) {
            this._isCmpInit = true;
            this._deliveryDate = deliveryDate;
            this._clearOlDisplay();
            this._requestPosition();
            this._requestOrderLinear();
        }
    }
    private _deliveryDate: DateTime;

    get orderLinear(): IEtsLinearOrder {
        return this._orderLinear;
    }
    private _orderLinear: IEtsLinearOrder;

    get flatPositions(): IFlatPosition[] {
        return this._flatPositions;
    }
    private _flatPositions: IFlatPosition[];

    get amountCap(): number {
        return this._amountCap;
    }
    set amountCap(value: number) {
        this._amountCap = value;
    }
    private _amountCap: number;

    get amountCapChecked(): boolean {
        return this._amountCapChecked;
    }
    set amountCapChecked(value: boolean) {
        this._amountCapChecked = value;
    }
    private _amountCapChecked: boolean = false;

    versions: number[] = [];
    versionSelected: number;
    loader: MultiLoader = new MultiLoader();
    isNew: boolean;
    isActiveVersion: boolean;
    hasData: boolean;
    hasDataChanged: boolean;
    hasError: boolean;
    hasRightCreate: boolean = false;
    hasRightDelete: boolean = false;
    openConfirmSend: boolean;
    openConfirmDelete: boolean;

    private _subs = new Subscriptions();

    private _historyOrderLinear: IEtsLinearOrder[] = [];

    btnDisable = false;
    valPercent: number = 100;

    private _isCmpInit: boolean = true;

    constructor(
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private _cdr: ChangeDetectorRef
    ) {
        this._checkMarketData();
    }

    ngOnInit() {
        this.hasRightCreate = this._appSvc.hasRight(UserRight.EtsLinearorderCreate);
        this.hasRightDelete = this._appSvc.hasRight(UserRight.EtsLinearorderDelete);
    }

    ngOnDestroy() {
        this._clearOlDisplay();
        this.btnDisable = undefined;
        this._subs.clearAll();
    }

    //#region api
    private _requestPosition() {
        if (
            !this._deliveryDate ||
            !this._grid ||
            !this._area ||
            !this._profile
        ) {
            return;
        }
        this.loader.pushLoader('requestPos');
        this._subs.clearSub('requestPos');
        const date = new DateRange(this._deliveryDate.startOf(TimeUnit.Days), this._deliveryDate.date.addDays(1).startOf(TimeUnit.Days));
        const subPos = this._api.balancings.positions.flatPositions(this._appSvc.contract.id, date, this._grid.id, this._grid.unit.id, TimeGranularity[this._area.granularity], { entitiesId: [this._profile.shipper.id] }).subscribe((flatPos) => {
            this._flatPositions = flatPos;
            this.loader.removeLoader('requestPos');
            this._cdr.detectChanges();
        }, () => {
            this.loader.removeLoader('requestPos');
            this._cdr.detectChanges();
        });
        this._subs.push('requestPos', subPos);
    }

    private _requestOrderLinear() {
        if (
            !this._profile ||
            !this._grid ||
            !this._area ||
            !this._portfolio ||
            !this._deliveryDate
        ) {
            this._clearOlDisplay();
            return;
        }
        this.loader.pushLoader("requestOl");
        if (this._historyOrderLinear[this.versionSelected]) {
            this._orderLinear = this._historyOrderLinear[this.versionSelected];
            this.isActiveVersion = this._orderLinear.isActiveVersion;
            this.isNew = false;
            this.loader.removeLoader("requestOl");
            this._cdr.detectChanges();
        } else {
            this._subs.clearSub('requestOl');
            let sub: any = this._api.ets.orders.getAllLinears(this._appSvc.contract.id, this._profile.id, this._area.id, this._portfolio.id, this._deliveryDate, this.versionSelected).subscribe((orderLinears) => {
                this._orderLinear = { id: undefined, area: undefined, curves: undefined, deliveryDate: undefined, isActiveVersion: undefined, portfolio: undefined, version: undefined };
                if (orderLinears && orderLinears.length == 1) {
                    this._orderLinear = orderLinears[0];
                    if (!this._orderLinear.isActiveVersion && !this._historyOrderLinear[this._orderLinear.version]) {
                        this._historyOrderLinear[this._orderLinear.version] = this._orderLinear;
                    }
                    this.isActiveVersion = this._orderLinear.isActiveVersion;
                    this.isNew = false;
                } else {
                    this._orderLinear.deliveryDate = this._deliveryDate;
                    this.isNew = true;
                    this.isActiveVersion = true;
                }
                this._checkVersions();
            }, () => {
                this.loader.removeLoader('requestOl');
            }, () => {
                this.loader.removeLoader('requestOl');
                this._cdr.detectChanges();
            });
            this._subs.push('requestOl', sub);
        }
    }

    private _cancelOrder() {
        this.loader.pushLoader('deleteOrder');
        this._subs.clearSub('deleteOrder');
        let sub: any = this._api.ets.orders.cancelLinear(this._appSvc.contract.id, this._profile.id, this._cancelOrderLinear()).subscribe((cancel) => {
            let newOl = _.cloneDeep(this._orderLinear);
            this._orderLinear.isActiveVersion = false;
            this._historyOrderLinear[this._orderLinear.version] = this._orderLinear;
            newOl.version++;
            newOl.curves = [];
            this._orderLinear = newOl;
            this._checkVersions();
        }, () => {
            this.loader.removeLoader('deleteOrder');
            this._cdr.detectChanges();
        }, () => {
            this.loader.removeLoader('deleteOrder');
            this._cdr.detectChanges();
        });
        this._subs.push('deleteOrder', sub);
    }

    private _submitOrder() {
        this.dataTable.getOrderLinear().subscribe((ordrLinear) => {
            this._subs.clearSub("sendOl");
            this.loader.pushLoader("sendOl");
            ordrLinear.area = this._area;
            ordrLinear.deliveryDate = this._deliveryDate;
            ordrLinear.portfolio = this._portfolio;
            let sub: any = this._api.ets.orders.createLinear(this._appSvc.contract.id, this._profile.id, ordrLinear).subscribe((ol) => {
                if (this._orderLinear) {
                    this._orderLinear.isActiveVersion = false;
                    this._historyOrderLinear[this._orderLinear.version] = this._orderLinear;
                }
                this._orderLinear = ol;
                this._checkVersions();
                this.isNew = false;
            }, () => {
                this.loader.removeLoader('sendOl');
                this._cdr.detectChanges();
            }, () => {
                this.loader.removeLoader('sendOl');
                this._cdr.detectChanges();
            });
            this._subs.push('sendOl', sub);
        });
    }
    //#endregion api

    //#region event listeners
    onSubmitOrder(send: boolean) {
        if (send) {
            this._submitOrder();
        }
    }

    onBalancePosition() {
        this.loader.pushLoader("balanced");
        this.dataTable
            .fillByPositions(
                this.valPercent,
                this._amountCap,
                !this._amountCapChecked
            )
            .subscribe(
                (item) => { },
                (error) => { },
                () => {
                    this.loader.removeLoader("balanced");
                }
            );
    }

    onRevertVersion() {
        this.loader.pushLoader("revertVersion");
        this.dataTable.reinitOrderLinear().subscribe(
            (item) => { },
            (error) => { },
            () => {
                this.loader.removeLoader("revertVersion");
                this._cdr.detectChanges();
            }
        );
    }

    onDeleteOrder(isDelete: boolean) {
        if (isDelete) {
            this._cancelOrder();
        }
    }

    onRefresh() {
        this.refresh.emit();
        this.loader.pushLoader("refresh");
        this._clearOlDisplay();
        this._requestPosition();
        this._requestOrderLinear();
        this.loader.removeLoader("refresh");
    }

    onClearData() {
        this.loader.pushLoader("clearData");
        this.dataTable.clearData().subscribe(
            (item) => { },
            (error) => { },
            () => {
                this.loader.removeLoader("clearData");
                this._cdr.detectChanges();
            }
        );
    }

    onChangeVersion(version: number) {
        if (version) {
            if (!this._isCmpInit) {
                this.versionSelected = version;
                this._requestOrderLinear();
            } else {
                this._isCmpInit = false;
            }
        }
    }

    onTableChange(event: EventStatusOlTable) {
        setTimeout(() => {
            this.hasData = _.clone(event.hasData);
            this.hasDataChanged = event.hasDataChange;
            this.hasError = event.hasError;
            this._cdr.detectChanges();
        }, 10);
    }

    onBeforeTableLoad() {
        this.loader.pushLoader("tableLoad");
    }

    onAfterTableLoad() {
        this.loader.removeLoader("tableLoad");
    }
    //#endregion event listeners

    //#region private
    private _cancelOrderLinear() {
        let cancel: EtsCancelOrderData = new EtsCancelOrderData();
        cancel.area = this._area;
        cancel.portfolio = this._portfolio;
        cancel.deliveryDate = this._orderLinear.deliveryDate;
        return cancel;
    }

    private _checkVersions() {
        this._checkMarketData();
        if (
            !this.versions ||
            this.versions.length == 0 ||
            this._orderLinear.isActiveVersion
        ) {
            this.versions = [];
            if (this._orderLinear && this._orderLinear.version) {
                for (let i = 1; i <= this._orderLinear.version; ++i) {
                    this.versions.push(i);
                }
            }
        } else {
            if (!_.includes(this.versions, this._orderLinear.version)) {
                this.versions.push(this._orderLinear.version);
                this.versions = this.versions.splice(0);
            }
        }
        this._cdr.detectChanges();
        if (this._orderLinear && this._orderLinear.version) {
            this.versionSelected = this._orderLinear.version;
            this.isActiveVersion = this._orderLinear.isActiveVersion;
        } else {
            this.versionSelected = undefined;
            this.isActiveVersion = false;
        }
        this._cdr.detectChanges();
    }

    private _clearOlDisplay() {
        this.versions = [];
        this.versionSelected = undefined;
        this._orderLinear = undefined;
        this._historyOrderLinear = [];
    }

    private _checkMarketData() {
        if (this._marketDataResult) {
            this.btnDisable = true;
        } else {
            this.btnDisable = false;
        }
    }
    //#endregion private
}

class MultiLoader {
    public get onLoading(): boolean {
        return this._onLoading;
    }

    private _onLoading: boolean = false;

    private _loaders: string[] = [];

    public pushLoader(loader: string) {
        let index = this._loaders.indexOf(loader);
        if (index >= 0) return;
        this._loaders.push(loader);
        this.checkLoading();
    }

    public removeLoader(loader: string) {
        let index = this._loaders.indexOf(loader);
        if (index < 0) return;
        this._loaders.splice(index, 1);
        this.checkLoading();
    }

    private checkLoading() {
        if (this._loaders.length > 0) this._onLoading = true;
        else this._onLoading = false;
    }
}
