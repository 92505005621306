// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BasePagePopup, Logger } from '@core';
import { ApiService } from '@eznergy/webapi';
import { IAvailableMarket, IOrganisation, ISession, UserRight } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import { AppPages, RouterService } from '@services/router.service';

@Component({
    selector: 'create-session-page',
    templateUrl: './create-session.page.html',
    styleUrls: ['./create-session.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.ez-page]": "true"
    }
})
export class CreateSessionPage extends BasePagePopup {

    markets: IAvailableMarket[];
    shippers: IOrganisation[];

    get urlPage(): string {
        if (!this._routerSvc)
            return undefined;
        let params;
        if (this._sessionId)
            params = { id: this._sessionId.toString() };
        return this._routerSvc.getUrl(AppPages.SessionCreate, { params: params });
    }
    get urlPopup(): string {
        if (!this._routerSvc)
            return undefined;
        let params;
        if (this._sessionId)
            params = { id: this._sessionId.toString() };
        return this._routerSvc.getUrlPopup(AppPages.SessionCreate, params);
    }

    get session(): ISession {
        return this._session;
    }
    set session(value: ISession) {
        if (this._session?.id === value?.id) {
            return;
        }

        this._session = value;
        this._cdr.detectChanges();
    }

    private get sessionId(): number {
        return this._sessionId;
    }
    private set sessionId(value: number) {
        if (this._sessionId === value) {
            return;
        }

        this._sessionId = value;
        this._getSession();
    }


    hasSessionCreateRight: boolean = false;
    private _hasSessionReadRight: boolean = false;
    private _sessionId: number;
    private _session: ISession;

    constructor(private _api: ApiService,
        private _appSvc: ApplicationService,
        private _activatedRoute: ActivatedRoute,
        logger: Logger,
        private _routerSvc: RouterService,
        private _cdr: ChangeDetectorRef) {
        super(logger);
        this.titlePopup = "Create Session";
    }

    ngOnInit(): void {
        this._loadMarkets();
        this._loadShippers();
        const subRights = this._appSvc.contractChange.subscribe(() => {
            this._checkRights();
        });
        this._routerSvc.setAppTitle(this.titlePopup);
        const sub = this._activatedRoute.paramMap.subscribe((param) => {
            const id = param.get('idDuplicate');

            if (id) {
                this.sessionId = parseInt(id);
                return;
            }

            this.sessionId = undefined;
        });
        this._subscriptions.push("checkRights", subRights);
        this._subscriptions.push("paramMap", sub);
    }

    private _checkRights(): void {
        this._hasSessionReadRight = this._appSvc.hasRight(UserRight.SessionRead);
        this.hasSessionCreateRight = this._appSvc.hasRight(UserRight.SessionCreate);

        this._getSession();
    }

    private _loadMarkets() {
        this._loaderPage.push('getMarkets');
        this._subscriptions.clearSub('markets');
        this._cdr.detectChanges();
        let sub = this._api.autotrading.markets.getAll(this._appSvc.contract.id).subscribe((markets) => {
            this.markets = markets;
            this._loaderPage.remove('getMarkets');
            this._cdr.detectChanges();
        }, (error) => {
            this._logger.error("Error in get all markets autotrading api", error);
            this._loaderPage.remove('getMarkets');
            this._cdr.detectChanges();
        });
        this._subscriptions.push("markets", sub);
    }

    private _loadShippers() {
        this._loaderPage.push('getShippers');
        this._subscriptions.clearSub('shippers');
        this._cdr.detectChanges();
        let sub = this._api.references.shippers.getAll(this._appSvc.contract.id).subscribe(
            (shippers) => {
                this.shippers = shippers;
                this._loaderPage.remove('getShippers');
                this._cdr.detectChanges();
            }, (error) => {
                this._logger.error("Error in get all shipper api", error);
                this._loaderPage.remove('getShippers');
                this._cdr.detectChanges();
            });
        this._subscriptions.push("shippers", sub);
    }

    private _getSession(): void {
        if (!this._sessionId || !this._hasSessionReadRight) {
            this.session = undefined;
            return;
        }

        if (this._session?.id === this._sessionId) {
            return
        }

        this._subscriptions.clearSub("session");
        this._loaderPage.push("getSession");
        this._cdr.detectChanges();

        let sub = this._api.autotrading.sessions.get(this._appSvc.contract.id, this._sessionId).subscribe((obj) => {
            if (obj) {
                this.session = obj;
                this._loaderPage.remove("getSession");
                this._cdr.detectChanges();
            }
        }, () => {
            this._loaderPage.remove("getSession");
            this._cdr.detectChanges();
        });

        this._subscriptions.push("session", sub);
    }

    onCreateSession(session: ISession) {
        if (session) {
            this._routerSvc.closeOutletPopup().then(() => {
                this._routerSvc.navigateTo(AppPages.Session, { params: { id: session.id.toString() } });
            });
        }
    }
}
