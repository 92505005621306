import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild
} from '@angular/core';
import {
    ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NgControl, Validators
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject } from 'rxjs';
import { CurveBase, CurveType, ICurveBase, IGroup, IUnitFamily } from '@eztypes/webapi';
import { UnitFamiliesUmScore } from '@enums/unit-families-umscore.enum';
import { Subscriptions } from '@core';
import { ApiService } from '@eznergy/webapi';
import { ApplicationService } from '@services/application.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'select-timeseries',
    templateUrl: 'select-timeseries.component.html',
    styleUrls: ['select-timeseries.component.scss'],
    providers: [{provide: MatFormFieldControl, useExisting: SelectTimeseriesComponent}],
    host: {
        '[id]': 'id',
    }
})
export class SelectTimeseriesComponent implements ControlValueAccessor, MatFormFieldControl<CurveBase>, OnInit, OnDestroy {
    public static nextId = 0;
    @ViewChild('autoInput') public autoInput!: HTMLInputElement;
    public displayFormControl: FormControl<ICurveBase | string | null> = this.formBuilder.control<ICurveBase | string | null>(null);
    public mainFormControl: FormControl<ICurveBase | null> = this.formBuilder.control<ICurveBase | null>(null);
    public stateChanges = new Subject<void>();
    public focused = false;
    public touched = false;
    public controlType = 'select-timeseries';
    public id = `select-timeseries-${SelectTimeseriesComponent.nextId++}`;
    public onChange = (_: any) => {};
    public onTouched = () => {};
    // Not related to NgControl
    public timeseries: ICurveBase[] = [];
    public isTimeseriesLoading: boolean = true;
    public panelFiltersIsOpen: boolean = false;
    public groupsForm: FormGroup<{search: FormControl<string>, selectedGroups: FormArray}> = this.formBuilder.group({
        'search': this.formBuilder.control('', { nonNullable: true }),
        'selectedGroups': this.formBuilder.array<boolean>([])
    });
    public timeseriesGroups: IGroup[] = [];
    public selectedTimeseriesGroups: IGroup[] = [];
    public filterTypes: CurveType[] = [];
    public isTimeseriesGroupsLoading: boolean = false;
    public hasFilters: boolean = false;
    private readonly _contractId: number = this._appSvc.contract!.id;
    private _subs: Subscriptions = new Subscriptions();
    private _isInit: boolean = false;

    get empty() {
        return !this.displayFormControl.value;
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input('aria-describedby') userAriaDescribedBy: string = "";

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string = "Select time series";

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this._required ? this.displayFormControl.addValidators(Validators.required) : this.displayFormControl.removeValidators(Validators.required);
        this._required ? this.mainFormControl.addValidators(Validators.required) : this.mainFormControl.removeValidators(Validators.required);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.displayFormControl.disable() : this.displayFormControl.enable();
        this._disabled ? this.mainFormControl.disable() : this.mainFormControl.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): CurveBase | null {
        if (this.mainFormControl.valid) {
            return this.mainFormControl.value;
        }
        return null;
    }
    set value(timeserie: ICurveBase | null) {
        this.mainFormControl.setValue(timeserie);
        this.displayFormControl.setValue(timeserie);
        this.stateChanges.next();
    }

    get errorState(): boolean {
        return this.mainFormControl.invalid && this.touched;
    }

    // Not related to NgControl
    @Input()
    get sizeElement(): number {
        return this._sizeElement;
    }
    set sizeElement(value: number) {
        if (value !== this.sizeElement) {
            this._sizeElement = value;
            this.stateChanges.next();
        }
    }
    private _sizeElement: number = 100;

    @Input()
    get type(): CurveType | undefined {
        return this._type;
    }
    set type(value: CurveType | undefined) {
        if (value !== this.type) {
            this._type = value;
            this.stateChanges.next();
        }
    }
    private _type: CurveType | undefined;

    @Input()
    get familiesUmScore(): UnitFamiliesUmScore | undefined {
        return this._familiesUmScore;
    }
    set familiesUmScore(value: UnitFamiliesUmScore | undefined) {
        if (value !== this._familiesUmScore) {
            this._familiesUmScore = value;
            this.stateChanges.next();
        }
    }
    private _familiesUmScore: UnitFamiliesUmScore | undefined;

    @Input()
    get unitFamily(): IUnitFamily | undefined {
        return this._unitFamily;
    }
    set unitFamily(value: IUnitFamily | undefined) {
        if (this._unitFamily !== value) {
            this._unitFamily = value;
            this._searchTimeseries();
            this.stateChanges.next();
        }
    }
    private _unitFamily: IUnitFamily | undefined;

    @Input()
    get calculated(): boolean | undefined {
        return this.filterCalculated;
    }
    set calculated(value: boolean | undefined) {
        if (value !== this.filterCalculated) {
            this.filterCalculated = value;
            this._searchTimeseries();
            this.stateChanges.next();
        }
    }
    filterCalculated: boolean | undefined = undefined;

    @Input()
    get locked(): boolean | undefined {
        return this.filterLocked;
    }
    set locked(value: boolean | undefined) {
        if (value !== this.filterLocked) {
            this.filterLocked = value;
            this._searchTimeseries();
            this.stateChanges.next();
        }
    }
    filterLocked: boolean | undefined = undefined;

    @Input()
    get hideFilterCalculated(): boolean {
        return this._hideFilterCalculated;
    }
    set hideFilterCalculated(value: boolean) {
        if (this.hideFilterCalculated !== value) {
            this._hideFilterCalculated = value;
            this.stateChanges.next();
        }
    }
    private _hideFilterCalculated: boolean = false;

    @Input()
    get hideFilterLocked(): boolean {
        return this._hideFilterLocked;
    }
    set hideFilterLocked(value: boolean) {
        if (this.hideFilterLocked !== value) {
            this._hideFilterLocked = value;
            this.stateChanges.next();
        }
    }
    private _hideFilterLocked: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private _focusMonitor: FocusMonitor,
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this._isInit = true;
        this._searchTimeseries();

        this._subs.push('sync-controls', this.displayFormControl.valueChanges.subscribe((value) => {
            if (!value) {
                this.mainFormControl.setValue(null);
                return;
            }
            if (typeof value === 'string') {
                return;
            }
            this.mainFormControl.setValue(value);
        }));
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    onFocusIn(_event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.select-container'
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick() {
        this._focusMonitor.focusVia(this.autoInput, 'program');
    }

    writeValue(timeserie: ICurveBase | null): void {
        this.value = timeserie;
    }

    registerOnChange(fn: any): void {
        this._subs.push('on-change', this.mainFormControl.valueChanges.subscribe(fn));
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    // Not related to NgControl
    private _searchTimeseries(): void {
        if (this._isInit) {
            this._subs.clearSub('search-ts');
            let obs: Observable<ICurveBase[]>;
            if (this.type === CurveType.Decimal) {
                if (this.familiesUmScore != null && (this.unitFamily == null || this.unitFamily.score !== this.familiesUmScore)) {
                    const sub = this._api.timeseries.unitFamilies.getAll(this._contractId, [this.familiesUmScore]).subscribe((units) => {
                        if (units == null || units.length === 0) {
                            this._familiesUmScore = undefined;
                        } else {
                            this._unitFamily = units[0];
                        }
                        this._searchTimeseries();
                        this.isTimeseriesLoading = false;
                    });
                    this._subs.push('search-ts-units', sub);
                    return;
                } else {
                    obs = this._api.timeseries.decimalCurves.getAll(this._contractId,
                        // @ts-ignore
                        {
                            count: this._sizeElement,
                            name: this.groupsForm.controls.search.value,
                            labelIds: this.selectedTimeseriesGroups.map((g) => g.id),
                            isComputed: this.filterCalculated,
                            isLock: this.filterLocked,
                            unitFamilyId: this._unitFamily?.id
                        }
                    );
                }
            } else {
                obs = this._api.timeseries.curves.getAll(this._contractId,
                    // @ts-ignore
                    {
                        count: this._sizeElement,
                        name: this.groupsForm.controls.search.value,
                        labels: this.selectedTimeseriesGroups,
                        isComputed: this.filterCalculated,
                        isLock: this.filterLocked,
                        types: this.filterTypes
                    }
                );
            }
            this.hasFilters = this.selectedTimeseriesGroups.length > 0 || typeof this.filterCalculated === 'boolean' || typeof this.filterLocked === 'boolean';
            const sub = obs.subscribe((timeseries: ICurveBase[]) => {
                this.timeseries = timeseries;
                this.isTimeseriesLoading = false;
                this.mainFormControl.updateValueAndValidity();
                this.displayFormControl.updateValueAndValidity();
                this.stateChanges.next();
            }, () => {
                this.timeseries = [];
                this.mainFormControl.updateValueAndValidity();
                this.displayFormControl.updateValueAndValidity();
                this.isTimeseriesLoading = false;
                this.isTimeseriesGroupsLoading = false;
                this.stateChanges.next();
            });
            this._subs.push('search-ts', sub);
        }
    }

    public displayProperty(curve: ICurveBase) {
        if (curve) {
            return curve.name + (curve.unit ? ' (' + curve.unit.name + ')' : '');
        } else {
            return '';
        }
    }

    public toggleFiltersPanel(): void {
        // This dirty line allows the filterPanel to be opened second and therefore, be on top
        setTimeout(() => {
            this.panelFiltersIsOpen = !this.panelFiltersIsOpen;
            this.stateChanges.next();
        }, 1);
        if (!this.timeseriesGroups.length && !this.isTimeseriesGroupsLoading) {
            this.isTimeseriesGroupsLoading = true;
            this._subs.push('labels', this._api.timeseries.groups.getAll(this._contractId).subscribe((groups: IGroup[]) => {
                this.timeseriesGroups = groups;
                for (let i = 0; i < groups.length; i++) {
                    this.groupsForm.controls.selectedGroups.push(this.formBuilder.control(false));
                }
                this.groupsForm.updateValueAndValidity();
                this.isTimeseriesGroupsLoading = false;
                this.stateChanges.next();
            }, () => {
                this.isTimeseriesGroupsLoading = false;
                this.stateChanges.next();
            }));
        }
    }

    public onGroupSelected(value: MatCheckboxChange, groupSelected: IGroup) {
        if (value.checked) {
            this.selectedTimeseriesGroups = [...this.selectedTimeseriesGroups, groupSelected];
        } else {
            this.selectedTimeseriesGroups = [...this.selectedTimeseriesGroups.filter((group) => group.id !== groupSelected.id)];
        }
        this._searchTimeseries();
    }
}