import { DataDisplayType } from '@core/application.model';
import { UPPropertySave, UserPreferenceClassDecorator } from '@core/decorators';
import { deserialize } from '@eznergy/core';
import { IDateRange } from '@eztypes/generic';
import { TimeGranularity } from '@eztypes/webapi';
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from '../core.model';

export interface ITimeSeriesIdColor {
    id: number;
    color: string | undefined;
}
class PageTsEditPreferenceInfo {
    static load(preferences: string | PageTsEditPreferenceInfo): PageTsEditPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageTsEditPreferenceInfo>(preferences, PageTsEditPreferenceInfo) as PageTsEditPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    displayType: DataDisplayType[] = [];
    granularity: TimeGranularity | undefined;
    timeserieIds: number[] = [];
    timeserieColors: ITimeSeriesIdColor[] = [];
    calendarId: number | undefined;
}

@UserPreferenceClassDecorator
export class PageTsEditPreference implements IPreferencePage {
    static load(preferences: string | PageTsEditPreferenceInfo): PageTsEditPreference {
        if (!preferences) {
            return new PageTsEditPreference();
        }

        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageTsEditPreferenceInfo.load(preferences);
                return new PageTsEditPreference(
                    v1.displayType,
                    v1.granularity,
                    v1.timeserieIds,
                    v1.calendarId,
                    v1.timeserieColors
                );
            default:
                return new PageTsEditPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    displayType: DataDisplayType[];
    @UPPropertySave
    granularity: TimeGranularity;
    @UPPropertySave
    timeserieIds: number[] | undefined;
    @UPPropertySave
    timeserieColors: ITimeSeriesIdColor[];
    @UPPropertySave
    calendarId: number | undefined;

    dates: IDateRange | undefined;
    groupId: number | undefined;

    constructor(
        displayType: DataDisplayType[] = [DataDisplayType.Sheet],
        granularity: TimeGranularity = TimeGranularity.Hour,
        timeserieIds: number[] = [],
        calendarId: number | undefined = undefined,
        timeserieColors: ITimeSeriesIdColor[] = []
    ) {
        this.displayType = displayType;
        this.granularity = granularity;
        this.timeserieIds = timeserieIds;
        this.calendarId = calendarId;
        this.timeserieColors = timeserieColors;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byDate) {
            this.dates = undefined;
        }
        if (options.all) {
            this.granularity = TimeGranularity.Hour;
            this.timeserieIds = undefined;
            this.calendarId = undefined;
            this.groupId = undefined;
        }
    }
}
