import { NgModule } from "@angular/core";
import { EtsPageModule } from "./ets/ets.module";
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
    imports: [
        EtsPageModule,
        SharedModule
    ],
    exports: [
        EtsPageModule,
    ]
})
export class BalancingPagesModule {

}
