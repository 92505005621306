// @ts-nocheck
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppCoreModule } from "@core/core.module";
import { EzComponentsModule } from "@eznergy/components";
import { FormBase } from "./form-base";

@NgModule({
    imports: [
        CommonModule,
        EzComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        AppCoreModule
    ],
    declarations: [
        FormBase
    ],
    exports: [
        FormBase,
        FormsModule,
        ReactiveFormsModule,
        AppCoreModule,
    ]
})
export class CoreFormModule { }
