import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RedirectComponent } from 'src/app/modules/redirection/components/redirect.component';

@NgModule({
    imports: [CommonModule],
    declarations: [RedirectComponent],
    exports: [RedirectComponent]
})
export class AppRedirectionModule { }
