import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageBalancingActivationPreferenceInfo {
    static load(preferences: string | PageBalancingActivationPreferenceInfo): PageBalancingActivationPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageBalancingActivationPreferenceInfo>(preferences, PageBalancingActivationPreferenceInfo) as PageBalancingActivationPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    gridMode: boolean | undefined;
}

@UserPreferenceClassDecorator
export class PageBalancingActivationPreference implements IPreferencePage {
    static load(preferences: string | PageBalancingActivationPreferenceInfo): PageBalancingActivationPreference {
        if (!preferences) {
            return new PageBalancingActivationPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageBalancingActivationPreferenceInfo.load(preferences);
                return new PageBalancingActivationPreference(v1.gridMode);
            default:
                return new PageBalancingActivationPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    gridMode: boolean | undefined;

    constructor(gridMode: boolean | undefined = undefined) {
        this.gridMode = gridMode;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.gridMode = undefined;
        }
    }
}

