// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { MultiLoader, Subscriptions } from '@core';
import * as _ez from "@eznergy/core";
import { ApiService } from '@eznergy/webapi';
import { ICommunicationInterface, IGate, IGrid, INominationSchedule, IProductionSchedule } from '@eztypes/webapi';
import { ApplicationService } from "@services/application.service";
import * as _ from 'lodash';

export enum Tab {
    Immediate = "immediate",
    Forecast = "forecast"
}
export type scheduleType = "nominations" | "productions";
@Component({
    selector: "ez-create-schedules",
    templateUrl: "./create-schedules.component.html",
    styleUrls: ["./create-schedules.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CreateSchedulesComponent {

    @Output() dataResultFromForm: EventEmitter<INominationSchedule[] | IProductionSchedule[]> = new EventEmitter<INominationSchedule[] | IProductionSchedule[]>();
    @Output() openChange: EventEmitter<boolean> = new EventEmitter();

    // Used for limited the type of the interfaces visible in the form
    @Input() limitedInterfaceTypeIdentifier: number = null;

    @Input()
    get type(): scheduleType {
        return this._type;
    }
    set type(type: scheduleType) {
        if (type !== this.type) {
            this._type = type;
            this._cdr.markForCheck();
        }
    }
    private _type: scheduleType;

    @Input()
    get grid(): IGrid {
        return this._grid;
    }
    set grid(value: IGrid) {
        if (this.grid !== value) {
            this._grid = value;
            this._loadGates();
            this._loadInterfaces();
            this._cdr.markForCheck();
        }
    }
    private _grid: IGrid;

    @Input()
    get value(): INominationSchedule | IProductionSchedule {
        return this._value;
    }
    set value(value: INominationSchedule | IProductionSchedule) {
        if (this.value !== value) {
            this._value = value;
            if (value != null) {
                this.tabSelected = Tab.Immediate;
            }
            this._cdr.markForCheck();
        }
    }
    private _value: INominationSchedule | IProductionSchedule;

    @Input()
    get open(): boolean {
        return this._open;
    }
    set open(value: boolean) {
        const newValue = _ez.coerceBoolean(value, false);
        if (this.open !== newValue) {
            this._open = newValue;
            this._cdr.markForCheck();
        }
    }
    private _open: boolean = false;

    get gates(): IGate[] {
        return this._gates;
    }
    private _gates: IGate[];

    get selectedGate(): IGate {
        return this._selectedGate;
    }
    private _selectedGate: IGate;

    get interfaces(): ICommunicationInterface[] {
        return this._interfaces;
    }
    private _interfaces: ICommunicationInterface[];

    get selectedInterfaces(): ICommunicationInterface[] {
        return this._selectedInterfaces;
    }
    private _selectedInterfaces: ICommunicationInterface[];

    readonly tabEnum = Tab;
    tabSelected: Tab = Tab.Immediate;

    readonly loader: MultiLoader = new MultiLoader();
    private readonly _subs: Subscriptions = new Subscriptions();

    constructor(
        private readonly _cdr: ChangeDetectorRef,
        private readonly _api: ApiService,
        private readonly _appSvc: ApplicationService) {
    }

    onValidateChild(item: INominationSchedule[]): void {
        this.dataResultFromForm.emit(item);
    }

    onClose(): void {
        this._open = false;
        this.openChange.emit(this._open);
    }

    private _loadGates(): void {
        const key = "load-gate";
        this.loader.push(key);
        this._subs.clearSub(key);
        this._selectedGate = undefined;
        this._gates = undefined;
        if (this.grid != null) {
            const sub = this._api.topologies.grids.getGates(this._appSvc.contract.id, this._grid.id).subscribe(
                (gates) => {
                    if (gates.length > 0) {
                        if (this.value != null && this.value.gate != null) {
                            this._selectedGate = _.find(gates, (g) => _ez.isSame(g, this.value.gate));
                        }
                        if (this._selectedGate == null && (this.value == null || this.value.gate != null)) {
                            this._selectedGate = gates[0];
                        }
                    }
                    this._gates = gates;
                    this.loader.remove(key);
                    this._cdr.detectChanges();
                },
                () => {
                    this.loader.remove(key);
                    this._cdr.detectChanges();
                });
            this._subs.push(key, sub);
        }
    }

    private _loadInterfaces(): void {
        const key = "load-interfaces";
        this.loader.push(key);
        this._subs.clearSub(key);
        this._selectedInterfaces = undefined;
        this._interfaces = undefined;
        if (this.grid != null) {
            const sub = this._api.communications.interfaces.getAll(this._appSvc.contract.id, this.grid.id, this._appSvc.shipper.id, "ENVOI", true, this.limitedInterfaceTypeIdentifier).subscribe(
                (interfaces) => {
                    if (interfaces.length > 0) {
                        this._selectedInterfaces = [interfaces[0]];
                    }
                    this._interfaces = interfaces;
                    this.loader.remove(key);
                    this._cdr.detectChanges();
                },
                () => {
                    this.loader.remove(key);
                    this._cdr.detectChanges();
                });
            this._subs.push(key, sub);
        }
    }
}
