// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { EzInputNumberComponent } from '@eznergy/components';
import * as _ez from "@eznergy/core";
import { EzNotificationService } from "@eznergy/toaster";
import { ITimespan, Timespan, TimeUnit } from '@eztypes/generic';
import { IEtsArea, IEtsCurve, IEtsCurvePoint, IEtsLinearOrder, IEtsMarketResult, IEtsMarketResultPortfolio, IEtsPortfolio, IFlatPosition } from "@eztypes/webapi";
// Model
import { EtsTotal } from "@models/api/ets/etsTotal.model";
import { EtsDataTable, EtsDataTablePosition, EtsDataTablePriceColumn, EtsDataTableRow, EtsDataTableRowTotal, EtsDtMarketResult } from "@models/views/ets/etsDataTable.model";
import { TranslateService } from "@ngx-translate/core";
import { EtsService } from "@services/ets.service";
import { Tools } from 'src/app/tools/Tools';
import { Decimal } from "decimal.js-light";
import * as _ from "lodash";
import { Observable } from "rxjs";

export class EventStatusOlTable {
    public hasData: boolean = false;
    public hasDataChange: boolean = false;
    public hasError: boolean = false;
}

@Component({
    selector: "ets-order-linear-table",
    templateUrl: "./ets-order-linear-table.component.html",
    styleUrls: ["./ets-order-linear-table.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EtsOrderLinearTableComponent implements OnDestroy {

    @Output() onChange = new EventEmitter<EventStatusOlTable>();
    @Output() onBeforeLoading = new EventEmitter();
    @Output() onAfterLoading = new EventEmitter();

    @Input()
    set area(area: IEtsArea) {
        if (!this._area || !area || this._area.id != area.id) {
            this.onBeforeLoading.emit();
            this._area = area;
            if (this._area) {
                this._tickFixed = this._area.priceTick.toString().substring(this._area.priceTick.toString().indexOf('.') + 1).length;
                this._tickFixedVolume = this._area.volumeTick.toString().substring(this._area.volumeTick.toString().indexOf('.') + 1).length;
            }
            else
                this._tickFixed = undefined;
            this._initData();
            this.onAfterLoading.emit();
            this._cdr.detectChanges();
        }
    }
    get area(): IEtsArea {
        return this._area;
    }
    private _area: IEtsArea;

    @Input()
    set flatPositions(value: IFlatPosition[]) {
        this.onBeforeLoading.emit();
        this._hasDataChanged = false;
        this._hasData = false;
        this._hasError = false;
        this._initFlatPos(value);
        this.onAfterLoading.emit();
        this._cdr.detectChanges();
    }

    @Input()
    get orderLinear(): IEtsLinearOrder {
        return this._orderlinear;
    }
    set orderLinear(ol: IEtsLinearOrder) {
        this.onBeforeLoading.emit();
        this._orderlinear = _.cloneDeep(ol);
        this._initOrderLinear();
        this.onAfterLoading.emit();
        this._cdr.detectChanges();
    }
    private _orderlinear: IEtsLinearOrder;

    @Input()
    get marketData(): IEtsMarketResult {
        return this._marketData;
    }
    set marketData(value: IEtsMarketResult) {
        this._marketData = value;
        this._initData();
        this._cdr.detectChanges();
    }
    private _marketData: IEtsMarketResult;

    @Input()
    get btnDisable(): boolean {
        return this._btnDisable;
    }
    set btnDisable(value: boolean) {
        this._btnDisable = value;
        this._cdr.detectChanges();
    }
    private _btnDisable: boolean;

    @Input()
    get portfolio(): IEtsPortfolio {
        return this._portfolio;
    }
    set portfolio(value: IEtsPortfolio) {
        this._portfolio = value;
        this._cdr.detectChanges();
    }
    private _portfolio: IEtsPortfolio;

    @Input()
    set isNew(isNew: boolean) {
        this._isNew = isNew;
        this._cdr.detectChanges();
    }
    get isNew(): boolean {
        return this._isNew;
    }
    private _isNew: boolean;

    get data(): EtsDataTable {
        return this._data;
    }

    get hasError(): boolean {
        return this._hasError;
    }

    get hasDataChanged(): boolean {
        return this._hasDataChanged;
    }

    get hasData(): boolean {
        return this._hasData;
    }

    get isActiveVersion(): boolean {
        if (!this._orderlinear) return false;
        return this._orderlinear.isActiveVersion;
    }

    get columns(): EtsDataTablePriceColumn[] {
        return this._priceCols || undefined;
    }

    get rows(): EtsDataTableRow[] {
        if (!this._data) return undefined;
        return this._data.rows;
    }

    get prices(): EtsDataTablePriceColumn[] {
        if (!this.rows) return undefined;
        return this.rows[0].prices;
    }

    get marketResult(): EtsDtMarketResult {
        if (!this.rows) return undefined;
        return this.rows[0].marketResult;
    }

    get total(): EtsDataTableRowTotal {
        if (!this._data) return new EtsDataTableRowTotal;
        return this._data.total;
    }

    get priceTick(): number {
        if (!this._area) return;
        return this._area.priceTick;
    }

    get volumeTick(): number {
        if (!this._area) return;
        return this._area.volumeTick;
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        let textData = event.clipboardData.getData('text');
        if (isNaN(this._parseNumber(textData))) {
            event.stopPropagation();
            event.preventDefault();
            this.onBeforeLoading.emit();
            setTimeout(() => {
                this._parseTextDataToOrderlinear(textData).subscribe((ol) => {
                    this._data = _.cloneDeep(ol);
                    this._checkAllDataChanged();
                }, (error) => {
                    this._ezNotifSrv.error(this._textParseData);
                    this.onAfterLoading.emit();
                }, () => {
                    this.onAfterLoading.emit();
                    this._cdr.detectChanges();
                });
            }, 10);
        }
    }

    private _data: EtsDataTable;
    private _hasError: boolean;
    private _hasDataChanged: boolean;
    private _hasData: boolean;
    private _priceCols: EtsDataTablePriceColumn[] = [];
    private _positions: EtsDataTablePosition[];
    private _lastState: EventStatusOlTable = new EventStatusOlTable;
    private _tickFixed: number;
    private _tickFixedVolume: number;
    private _originalPrices: number[];
    private _hasRemoveColPrice: boolean;
    private _textParseData: string = '';
    private _valResult: number;

    isLinear: boolean = false;

    constructor(
        private _ezNotifSrv: EzNotificationService,
        private _translateSrv: TranslateService,
        private _cdr: ChangeDetectorRef,
        private _etsSrv: EtsService
    ) {
        this._translateSrv.get(["balancing.ets.ol.errorParseData"]).subscribe((res) => {
            if (res && res["balancing.ets.ol.errorParseData"])
                this._textParseData = res["balancing.ets.ol.errorParseData"];
        });
    }
    /************** PUBLIC METHOD *****************/

    ngOnDestroy() { }

    public reinitOrderLinear(): Observable<void> {
        this.onBeforeLoading.emit();
        return new Observable((obs) => {
            this._initOrderLinear();
            this.onAfterLoading.emit();
            obs.complete();
        });
    }

    public getOrderLinear(): Observable<IEtsLinearOrder> {
        this.onBeforeLoading.emit();
        return new Observable((obs) => {
            let orderLinear: IEtsLinearOrder = {
                area: this.area,
                curves: [],
                deliveryDate: undefined,
                id: undefined,
                isActiveVersion: undefined,
                portfolio: undefined,
                version: undefined
            };
            for (let i = 0; i < this._data.rows.length; i++) {
                let c: IEtsCurve = {
                    periodStart: this._data.rows[i].periodStart,
                    periodEnd: this._data.rows[i].periodEnd,
                    comment: this._data.rows[i].comment,
                    curvePoints: []
                };

                for (let j = 0; j < this._data.rows[i].prices.length; j++) {
                    let cp: IEtsCurvePoint;
                    let ipc = this._data.rows[i].prices[j].id - 1;
                    let vm = this._data.rows[i].prices[j].volume;
                    if (vm != undefined && vm != null && !isNaN(Number(vm))) {
                        cp = {
                            price: this._priceCols[ipc].price,
                            volume: vm
                        };
                        c.curvePoints.push(cp);
                    }
                }
                if (c.curvePoints.length > 0 || c.comment)
                    orderLinear.curves.push(c);
            }

            obs.next(orderLinear);
            this.onAfterLoading.emit();
            obs.complete();
        });
    }

    public fillByPositions(percent: number, cap: number, amountCapDisabled: boolean): Observable<void> {
        this.onBeforeLoading.emit();
        return new Observable((obs) => {
            this._clearData();
            for (let i = this._data.rows.length - 1; i >= 0; i--) {
                let currentVal = this._data.rows[i].position.value;
                let val;
                let capVal = cap;
                if (percent && capVal == undefined || capVal == 0 || capVal && amountCapDisabled === true) {
                    val = (percent * (-1 * currentVal)) / 100;
                } else if (amountCapDisabled === false && capVal && percent == undefined || percent == 0) {
                    this._absoluteValCalc(currentVal, cap, capVal);
                    val = this._valResult;
                } else if (amountCapDisabled === false && percent && capVal) {
                    currentVal = (percent * currentVal) / 100;
                    this._absoluteValCalc(currentVal, cap, capVal);
                    val = this._valResult;
                } else {
                    val = -1 * currentVal;
                }
                let ic = this._data.rows[i].prices.length;
                if (val == 0 || ic < 2) continue;

                for (let j = this._data.rows[i].prices.length - 1; j >= 0; j--) {
                    this._data.rows[i].prices[j].volume = val;
                    this._checkDataChanged(this._data.rows[i].prices[j]);
                }

            }

            this._checkAllDataChanged();
            this._cdr.detectChanges();
            this.onAfterLoading.emit();
            obs.complete();
        });
    }

    public clearData(): Observable<void> {
        this.onBeforeLoading.emit();
        return new Observable((obs) => {
            this._clearData();
            this._checkAllDataChanged();
            this.onAfterLoading.emit();
            obs.complete();
        });
    }

    /************** END PUBLIC METHOD *****************/

    /***************** Events **********************/

    onBlurPriceColumn(index: number, value?: EzInputNumberComponent | number, item?: any) {
        if (item) {
            if (typeof value === 'number' && (value < item.price - this.priceTick || value > item.price + this.priceTick)) return;
            else if (value instanceof EzInputNumberComponent) value = value.value;
            item.price = value;
            this._cdr.detectChanges();
        }
        this.onBeforeLoading.emit();
        this._checkValuePrice(index);
        this._checkAllDataChanged();
        this.onAfterLoading.emit();
        this._cdr.detectChanges();
    }

    onBlurColumnVolume(indexRow: number, indexPrice: number) {
        this.onBeforeLoading.emit();
        if (this._data && this._data.rows[indexRow]) {
            this._checkValueVolume(this._data.rows[indexRow]);
            this._checkAllDataChanged();
        }
        this.onAfterLoading.emit();
    }

    onBlurColumnComment(indexRow, value?: string, item?: any) {
        if (item) {
            item.comment = value;
            this._cdr.detectChanges();
        }
        this.onBeforeLoading.emit();
        this._checkValueComment(this._data.rows[indexRow]);
        this._checkAllDataChanged();
        this.onAfterLoading.emit();
        this._cdr.detectChanges();
    }

    onAddColumnPrice(index: number) {
        this.onBeforeLoading.emit();
        this._addPriceColumn(index);
        this._checkAllValuePrice();
        this._checkAllDataChanged();
        this.onAfterLoading.emit();
    }

    onDeleteColumnPrice(index: number) {

        this.onBeforeLoading.emit();
        this._removePriceColumn(index);
        this.onAfterLoading.emit();
    }

    onClearRow(index: number) {
        this.onBeforeLoading.emit();
        this._clearRow(index);
        this._checkAllDataChanged();
        this._cdr.detectChanges();
        this.onAfterLoading.emit();
    }


    /*************** End Events ***************/

    /************** PRIVATE METHOD *****************/

    private _absoluteValCalc(currentVal: number, cap: number, capVal: number) {
        if (currentVal < 0) {
            capVal = -1 * capVal;
            if (currentVal < capVal) {
                this._valResult = cap;
            } else {
                this._valResult = -1 * currentVal;
            }
        } else {
            if (currentVal < capVal) {
                this._valResult = -1 * currentVal;
            } else {
                this._valResult = -1 * capVal;
            }
        }
    }

    private _initFlatPos(positions: IFlatPosition[]) {
        this._positions = [];
        if (positions != null) {
            positions.forEach((pos: IFlatPosition) => {
                let p = new EtsDataTablePosition();
                p.startDate = pos.date.from;
                p.endDate = pos.date.to;
                p.value = pos.value;
                this._positions.push(p);
            });
        }
        this._initData();
    }

    private _initOrderLinear() {
        this._originalPrices = undefined;

        this._hasData = false;
        this._hasDataChanged = false;
        this._hasRemoveColPrice = false;

        if (this._orderlinear) {
            let dashOl = _(this._orderlinear.curves);
            if (this._area.isStepwise) {
                this._originalPrices = [];
                dashOl.each((o) => {
                    let prices = _.flatMap(o.curvePoints, 'price');
                    let prev = undefined;
                    let pricesCount = _.countBy(this._originalPrices);
                    for (let i = prices.length - 1; i >= 0; i--) {
                        if (pricesCount[prices[i]] == undefined || (prev == prices[i] && pricesCount[prices[i]] == 1 && prices[i] != this._area.minimumPrice && prices[i] != this._area.maximumPrice)) {
                            this._originalPrices.push(prices[i]);
                        }
                        prev = prices[i];
                    }
                });
                this._originalPrices = _.sortBy(this._originalPrices);
            } else {
                this._originalPrices = dashOl.flatMap('curvePoints').flatMap('price').uniq().sortBy().value() as number[];
            }
        }

        this._initData();
    }

    private _prepareData(): EtsDataTableRow[] {
        let addValue: ITimespan;
        switch (this._area.granularity) {
            case "Hour":
                addValue = new Timespan(1, TimeUnit.Hours);
                break;
            case "HalfHour":
                addValue = new Timespan(30, TimeUnit.Minutes);
                break;
            case "QuarterHour":
                addValue = new Timespan(15, TimeUnit.Minutes);
                break;
            default:
                break;
        }
        let d = this._orderlinear.deliveryDate.setTimezone("CET").startOf(TimeUnit.Days);
        let startDate = d;
        let endDate = startDate.date.addDays(1).setTimezone("CET").startOf(TimeUnit.Days);
        let local = [];
        do {
            let r = new EtsDataTableRow;
            r.periodStart = startDate.toLocal();
            startDate = startDate.add(addValue);
            r.periodEnd = startDate.toLocal();
            local.push(r);
        } while (startDate < endDate);

        return local;
    }


    private _initData() {
        if (!this._area || !this._orderlinear || !this._positions) {
            this._data = undefined;
            this._checkSendEventChange();
        } else {
            this._data = undefined;
            this._priceCols = this._createPriceColum();
            if (this._priceCols == undefined) {
                return;
            }
            let data = new EtsDataTable();
            let dataHas = false;
            let rows = this._prepareData();
            let lengthCubes = rows.length;

            let linears: EtsTotal[];
            this.isLinear = false;
            if (this._marketData && this._marketData.area && this._marketData.area.id == this._area.id && this.portfolio) {
                let portfolioMarket = _.find(this._marketData.portfolios, (p) => { return p.portfolio.id === this.portfolio.id; }) as IEtsMarketResultPortfolio;
                if (portfolioMarket != undefined) {
                    linears = portfolioMarket.linear;
                    this.isLinear = true;
                }
            }
            let totalPosition = new Decimal(0);
            for (let i = 0; i < lengthCubes; i++) {
                let row = rows[i];
                row.id = i + 1;

                if (this._marketData && linears) {
                    let linear = _.find(linears, function (item) {
                        if (_ez.isSame(item.periodStart, row.periodStart)) {
                            return true;
                        }
                        return false;
                    });

                    let global = _.find(this._marketData.globals, function (item) {
                        if (_ez.isSame(item.periodStart, row.periodStart)) {
                            return true;
                        }
                        return false;
                    });
                    if (linear && global) {
                        let marketResult = new EtsDtMarketResult;
                        marketResult.net = linear.net;
                        marketResult.purchase = linear.purchase;
                        marketResult.sale = -1 * linear.sale;
                        marketResult.price = global.price;
                        row.marketResult = marketResult;
                    }
                }

                if (this._positions.length > 0) {
                    row.position = _.find(this._positions, function (o) {
                        if (_ez.isSame(o.startDate, row.periodStart) && _ez.isSame(o.endDate, row.periodEnd)) {
                            return true;
                        }
                        return false;
                    });
                }
                if (!row.position) {
                    row.position = new EtsDataTablePosition;
                    row.position.startDate = row.periodStart;
                    row.position.endDate = row.periodEnd;
                    row.position.value = 0;
                }
                row.prices = this._createPriceColum();

                totalPosition = this._etsSrv.calcVwA(row.periodEnd, row.periodStart, row.position.value, totalPosition);

                if (this._orderlinear) {
                    // var curve = _.find(orderLinear.curves, { periodStart: row.periodStart, periodEnd: row.periodEnd });
                    let curve = _.find(this._orderlinear.curves, function (o) {
                        if (_ez.isSame(o.periodStart, row.periodStart) && _ez.isSame(o.periodEnd, row.periodEnd)) {
                            return true;
                        }
                        return false;
                    });
                    if (curve) {
                        dataHas = true;
                        if (curve.comment) row.originalComment = row.comment = curve.comment;
                        for (let j = row.prices.length - 1; j >= 0; j--) {
                            let cp = curve.curvePoints[j];
                            if (cp && !isNaN(Number(cp.volume))) {
                                row.prices[j].originalVolume = row.prices[j].volume = new Decimal(cp.volume).toDecimalPlaces(this._tickFixedVolume).toNumber();
                            }
                        }
                        this._checkValueVolume(row);
                    }
                }
                data.rows.push(row);
            }
            data.total = new EtsDataTableRowTotal;
            data.total.prices = new Array(this._createPriceColum().length);

            data.total.position = totalPosition.toDecimalPlaces(2).toNumber().toLocaleString();

            this._data = data;
            this._calculTotalRow();
            this._hasData = dataHas;
            this._checkSendEventChange();
        }
    }

    private _createPriceColum(): EtsDataTablePriceColumn[] {
        let priceCols = [];

        if (this._originalPrices && this._originalPrices.length >= 2) {
            for (let i = 0; i < this._originalPrices.length; i++) {
                let cp = new EtsDataTablePriceColumn();
                cp.id = i + 1;
                cp.originalPrice = cp.price = Number(this._originalPrices[i]);
                priceCols.push(cp);
            }
            if (this.marketData == null) {
                if (priceCols[0].price != this._area.minimumPrice) {
                    if (this._area.minimumPrice < priceCols[0].price) {
                        let cp = new EtsDataTablePriceColumn();
                        cp.id = 1;
                        cp.originalPrice = cp.price = Number(this._area.minimumPrice);
                        priceCols.forEach((a) => {
                            a.id++;
                        });
                        priceCols.splice(0, 0, cp);
                    }
                }
                if (priceCols[priceCols.length - 1].price != this._area.maximumPrice) {
                    if (this._area.maximumPrice > priceCols[priceCols.length - 1].price) {
                        let cp = new EtsDataTablePriceColumn();
                        cp.id = priceCols.length + 1;
                        cp.originalPrice = cp.price = Number(this._area.maximumPrice);
                        priceCols.push(cp);
                    }
                }
            }
            // if (priceCols[0].price != this._area.minimumPrice || priceCols[priceCols.length - 1].price != this._area.maximumPrice)
            //     return undefined;
        } else {
            let colMin = new EtsDataTablePriceColumn();
            colMin.id = 1;
            colMin.originalPrice = colMin.price = this._area.minimumPrice;
            priceCols.push(colMin);
            let colMax = new EtsDataTablePriceColumn();
            colMax.id = 2;
            colMax.originalPrice = colMax.price = this._area.maximumPrice;
            priceCols.push(colMax);
        }

        return priceCols;
    }

    private _addNewPrice(price: number) {
        let prices = _.cloneDeep(this._priceCols);
        if (!_.some(prices, (p: EtsDataTablePriceColumn) => p.price === price)) {
            let cp = new EtsDataTablePriceColumn();
            cp.price = price;
            prices.push(cp);
        }
        prices = _.sortBy(prices, 'price');
        let index = 0;
        for (let i = prices.length - 1; i >= 0; i--) {
            prices[i].id = i + 1;
            if (prices[i].price == price) {
                index = i;
            }
        }
        this._priceCols = prices;

        for (let i = this._data.rows.length - 1; i >= 0; i--) {
            this._data.rows[i].prices.splice(index, 0, new EtsDataTablePriceColumn());
            for (let j = this._data.rows[i].prices.length - 1; j >= 0; j--) {
                this._data.rows[i].prices[j].id = j + 1;
                this._data.rows[i].prices[j].price = this._priceCols[j].price;
            }
        }
        this._data.total.prices.splice(index, 0, "0");
        this._calculTotalRow();
    }

    private _clearData() {
        if (this._priceCols.length > 2) {
            for (let i = this._priceCols.length - 2; i >= 1; i--) {
                this._removePriceColumn(i);
            }
        }

        for (let i = 0; i < this._data.rows.length; i++) {
            this._data.rows[i].comment = undefined;
            this._data.rows[i].illogicalBid = undefined;
            this._checkValueComment(this._data.rows[i]);
            for (let j = this._data.rows[i].prices.length - 1; j >= 0; j--) {
                this._data.rows[i].prices[j].volume = 0;
                this._data.rows[i].prices[j].missingVolume = undefined;
                this._checkDataChanged(this._data.rows[i].prices[j]);
            }
        }
        this._cdr.detectChanges();
    }


    private _checkValuePrice(index: number) {
        if (index == 0 || index >= this._priceCols.length) return;
        let tick = 0;
        let priceMin, priceMax;
        if (this._area.isStepwise) {
            if (this._priceCols[index].price <= this._area.minimumPrice) {
                this._priceCols[index].price = new Decimal(this._priceCols[index].price).plus(this._area.priceTick).toDecimalPlaces(this._tickFixed).toNumber();
            } else if (this._priceCols[index].price >= this._area.maximumPrice) {
                this._priceCols[index].price = new Decimal(this._priceCols[index].price).minus(this._area.priceTick).toDecimalPlaces(this._tickFixed).toNumber();
            }

            let priceBefore = this._priceCols[index - 1].price;
            priceMin = new Decimal(this._priceCols[index - 1].price).plus(tick).toDecimalPlaces(this._tickFixed).toNumber();
            if ((index - 2) >= 0) {
                if (this._priceCols[index - 2].price == priceBefore) {
                    priceMin = new Decimal(this._priceCols[index - 1].price).minus(this._area.priceTick).toDecimalPlaces(this._tickFixed).toNumber();
                }
            }
            let priceAfter = this._priceCols[index + 1].price;
            priceMax = new Decimal(this._priceCols[index + 1].price).plus(tick).toDecimalPlaces(this._tickFixed).toNumber();
            if ((index + 2) < this._priceCols.length) {
                if (this._priceCols[index + 2].price == priceAfter) {
                    priceMax = new Decimal(this._priceCols[index + 1].price).minus(this._area.priceTick).toDecimalPlaces(this._tickFixed).toNumber();
                }
            }
        } else {
            tick = this._area.priceTick;
            priceMin = new Decimal(this._priceCols[index - 1].price).plus(tick).toDecimalPlaces(this._tickFixed).toNumber();
            priceMax = new Decimal(this._priceCols[index + 1].price).minus(tick).toDecimalPlaces(this._tickFixed).toNumber();
        }

        let price = this._priceCols[index].price;
        if (priceMin >= price) {
            this._priceCols[index].price = priceMin;
        } else if (price > priceMax) {
            this._priceCols[index].price = priceMax;
        }
        let hasChanged = false;
        if (price != this._priceCols[index].price) hasChanged = true;
        for (let i = this._data.rows.length - 1; i >= 0; i--) {
            this._data.rows[i].prices[index].price = this._priceCols[index].price;
            if (hasChanged) this._data.rows[i].prices[index].hasChanged = true;
        }
    }

    private _checkAllValuePrice() {
        if (!this._priceCols || this._priceCols.length <= 2) return;
        for (let i = 1; i < this._priceCols.length - 1; i++) {
            this._checkValuePrice(i);
        }
    }

    private _checkValueVolume(row: EtsDataTableRow) {
        let prices = row.prices;
        let hasComment = false;
        let noCurves = true;
        if (row.comment && row.comment.length > 0) {
            hasComment = true;
        }

        for (let i = row.prices.length - 1; i >= 0; i--) {
            if (hasComment && (row.prices[i].volume || row.prices[i].volume != null)) {
                noCurves = false;
            }
            this._checkDataChanged(row.prices[i]);
        }
        if (noCurves && hasComment) {
            prices[0].volume = 0;
            prices[prices.length - 1].volume = 0;
        }

        // Check Illogical bid
        row.illogicalBid = this._isIllogicalBid(prices);

        // Check Missing volume
        row.prices[0].missingVolume = false;
        row.prices[prices.length - 1].missingVolume = false;
        let hasVolume = this._hasVolumeForPeriod(prices);
        if (hasVolume) {
            if (prices[0].volume === null || prices[0].volume === undefined || isNaN(Number(prices[0].volume))) {
                row.prices[0].missingVolume = true;
            }
            if (prices[prices.length - 1].volume === null || prices[prices.length - 1].volume === undefined || isNaN(Number(prices[prices.length - 1].volume))) {
                row.prices[prices.length - 1].missingVolume = true;
            }
        }

    }

    private _isIllogicalBid(prices: EtsDataTablePriceColumn[]) {
        let oldVolume = undefined;
        for (let i = 0; i < prices.length; i++) {
            if (oldVolume !== null && oldVolume !== undefined) {
                if (prices[i].volume > oldVolume) return true;
            }
            if (prices[i].volume !== null && prices[i].volume !== undefined && !isNaN(Number(prices[i].volume)))
                oldVolume = prices[i].volume;
        }
        return false;
    }

    private _hasVolumeForPeriod(prices: EtsDataTablePriceColumn[]) {
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].volume !== null && prices[i].volume !== undefined && !isNaN(Number(prices[i].volume))) return true;
        }
        return false;
    }

    private _checkDataChanged(data: EtsDataTablePriceColumn) {
        if (data.volume) {
            data.volume = new Decimal(data.volume).toDecimalPlaces(this._tickFixedVolume).toNumber();
        }
        if (data.price) {
            data.price = new Decimal(data.price).toDecimalPlaces(this._tickFixed).toNumber();
        }
        if (data.volume != data.originalVolume || data.price != data.originalPrice) {
            data.hasChanged = true;
        } else {
            data.hasChanged = false;
        }
    }

    private _parseNumber(value: string) {
        let decimalSeparate = Tools.whatDecimalSeparator();
        if (decimalSeparate == ",") {
            value = value.replace(decimalSeparate, ".");
        }
        value = value.replace(',', '');
        return Number(value);
    }

    private _checkValueComment(row: EtsDataTableRow) {
        if (row.comment) {
            for (let i = row.prices.length - 1; i >= 0; i--) {
                if (row.prices[i].volume == undefined || row.prices[i].volume == null) {
                    row.prices[i].volume = undefined;
                    row.prices[0].volume = 0;
                    row.prices[row.prices.length - 1].volume = 0;
                }
                break;
            }
        } else {
            row.comment = undefined;
        }
        if (row.comment != row.originalComment) {
            row.hasChanged = true;
        } else {
            row.hasChanged = false;
        }
    }

    private _checkAllDataChanged() {
        let dashOl = _(this._data.rows);
        this._hasDataChanged = false;
        if (this._hasRemoveColPrice) {
            this._hasDataChanged = true;
        }
        if (!this._hasDataChanged)
            this._hasDataChanged = dashOl.flatMap('hasChanged').uniq().includes(true);

        if (!this._hasDataChanged)
            this._hasDataChanged = dashOl.flatMap('prices').flatMap('hasChanged').uniq().includes(true);

        let hasError = false;

        let hasIllogicalBid = dashOl.flatMap('illogicalBid').uniq().includes(true);
        if (hasIllogicalBid) {
            hasError = true;
        }

        let hasMissingVolume = dashOl.flatMap('prices').flatMap('missingVolume').uniq().includes(true);
        if (hasMissingVolume) {
            hasError = true;
        }

        this._hasError = hasError;

        let datas = dashOl.flatMap('prices').flatMap('volume').uniq().value();
        this._removeData(datas, undefined);
        if (datas.length > 0)
            this._hasData = true;
        else
            this._hasData = false;

        this._calculTotalRow();
        this._checkSendEventChange();
    }

    private _removeData(array: any[], data: any) {
        let index = array.indexOf(data);
        if (index < 0) return;
        array.splice(index, 1);
    }

    private _addPriceColumn(index: number, price?: number) {
        if (index == 0 || index >= this._priceCols.length) return;

        this._priceCols.splice(index, 0, new EtsDataTablePriceColumn());
        let prevPrice: number = undefined;
        for (let i = 0; i < this._priceCols.length; i++) {
            this._priceCols[i].id = i + 1;
            if (prevPrice != null && prevPrice != undefined && this._priceCols[i].price == null || this._priceCols[i].price == undefined) {
                this._priceCols[i].price = price || new Decimal(prevPrice).plus(this._area.priceTick).toDecimalPlaces(this._tickFixed).toNumber();
                this._priceCols[i].hasChanged = true;
            }
            prevPrice = this._priceCols[i].price;
        }

        for (let i = this._data.rows.length - 1; i >= 0; i--) {
            this._data.rows[i].prices.splice(index, 0, new EtsDataTablePriceColumn());
            for (let j = this._data.rows[i].prices.length - 1; j >= 0; j--) {
                this._data.rows[i].prices[j].id = j + 1;
                this._data.rows[i].prices[j].price = this._priceCols[j].price;
                if (index == j) {
                    this._data.rows[i].prices[j].volume = this._data.rows[i].prices[j + 1].volume;
                }
                this._checkDataChanged(this._data.rows[i].prices[j]);
            }
        }
        this._data.total.prices.splice(index, 0, "0");

        this._checkAllDataChanged();
    }

    private _removePriceColumn(index: number) {
        if (index == 0 || index >= this._priceCols.length) return;

        let removePrice = this._priceCols.splice(index, 1);
        for (let i = 0; i < this._priceCols.length; i++) {
            this._priceCols[i].id = i + 1;
        }

        for (let i = this._data.rows.length - 1; i >= 0; i--) {
            this._data.rows[i].prices.splice(index, 1);
            for (let j = this._data.rows[i].prices.length - 1; j >= 0; j--) {
                this._data.rows[i].prices[j].id = j + 1;
                this._data.rows[i].prices[j].price = this._priceCols[j].price;
            }
        }
        if (removePrice && removePrice.length == 1 && removePrice[0].originalPrice) {
            this._hasRemoveColPrice = true;
        }

        this._data.total.prices.splice(index, 1);

        this._checkAllDataChanged();
    }

    private _checkSendEventChange() {
        let hasChange = false;

        if (this._lastState.hasData != this._hasData) {
            this._lastState.hasData = this._hasData;
            hasChange = true;
        }

        if (this._lastState.hasDataChange != this._hasDataChanged) {
            this._lastState.hasDataChange = this._hasDataChanged;
            hasChange = true;
        }

        if (this._lastState.hasError != this._hasError) {
            this._lastState.hasError = this._hasError;
            hasChange = true;
        }
        if (hasChange) {
            this.onChange.emit(this._lastState);
        }
    }

    private _calculTotalRow() {
        for (let i = this._data.total.prices.length - 1; i >= 0; i--) {
            let total = new Decimal(0);
            _.forEach(this._data.rows, (row) => {
                if (row.prices[i].volume) {
                    total = this._etsSrv.calcVwA(row.periodEnd, row.periodStart, row.prices[i].volume, total);
                }
            });
            this._data.total.prices[i] = total.toDecimalPlaces(2).toNumber().toLocaleString();
        }
    }

    private _clearRow(index: number) {
        let row = this._data.rows[index];
        if (row) {
            row.comment = undefined;
            row.illogicalBid = false;
            this._checkValueComment(row);
            for (let i = row.prices.length - 1; i >= 0; i--) {
                row.prices[i].volume = undefined;
                row.prices[i].missingVolume = false;
            }
            this._checkAllDataChanged();
        }
    }


    private _parseTextDataToOrderlinear(textData: string): Observable<EtsDataTable> {
        return new Observable((obs) => {
            try {
                let rows = textData.split('\n');
                if (rows.length > 1) {
                    let prices = rows[0].split('\t');
                    let indexStartPrice = undefined;
                    let indexComment = undefined;
                    let indexPeriod = undefined;
                    for (let i = 0; i < prices.length; i++) {
                        let p = this._parseNumber(prices[i]);
                        if (isNaN(p)) {
                            let text = prices[i].toLowerCase();
                            if (text == "comment") {
                                indexComment = i;
                            }
                            else if (text == "period") {
                                indexPeriod = i;
                            }
                        } else {
                            if (indexStartPrice == undefined) {
                                indexStartPrice = i;
                            }

                            if (!_(this._priceCols).map((o) => o.price).includes(p)) {
                                this._addNewPrice(p);
                            }
                        }
                    }

                    if (indexStartPrice == undefined) {
                        obs.error();
                    }

                    let pasteOl = _.cloneDeep(this._data);
                    for (let i = 1; i < rows.length; ++i) {
                        if (rows[i] == "") continue;

                        let cols = rows[i].split('\t');
                        let id = i - 1;
                        if (indexPeriod != undefined)
                            id = Number(cols[indexPeriod]) - 1;


                        let curve = pasteOl.rows[id];
                        if (indexComment != undefined) {
                            curve.comment = cols[indexComment];
                            this._checkValueComment(curve);
                        }

                        for (let j = indexStartPrice; j < prices.length; j++) {
                            let p = this._parseNumber(prices[j]);
                            let cp = _.find(curve.prices, { price: p });
                            if (!cp) {
                                let indexCp = _.find(this._priceCols, { price: p });
                                cp = _.cloneDeep(indexCp);
                                curve.prices.splice(cp.id - 1, 0, cp);
                            }
                            let vol = this._parseNumber(cols[j]);
                            cp.volume = vol;
                        }
                        this._checkValueVolume(curve);
                    }

                    if (pasteOl && pasteOl.rows.length > 0) {
                        obs.next(pasteOl);
                        obs.complete();
                    } else {
                        obs.error();
                    }
                } else {
                    obs.error();
                }
            }
            catch (ex) {
                obs.error(ex);
            }
        });
    }

    /************** END PRIVATE METHOD *****************/

    public trackByFn(index, item) {
        return index;
    }
}
