import { inject } from '@angular/core';
import {
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { OauthService, UserConnectedInfosService } from '@eznergy/webapi';
import { CacheService, KeyCache, TypeCache } from '@services/cache.service';

export const AuthorizationGuard: CanActivateFn = (_, state: RouterStateSnapshot) => {
    const oauthService = inject(OauthService);
    const cacheSvc = inject(CacheService);
    const userConnectedInfos = inject(UserConnectedInfosService)
    cacheSvc.set(KeyCache.ReturnUrl, state.url, TypeCache.Session);
    if (!userConnectedInfos.isAuthenticated) {
      oauthService.login();
      return false;
    }
    return true;
}