// @ts-nocheck
import { ActivatedRouteSnapshot, Route, UrlMatchResult, UrlSegment, UrlSegmentGroup } from "@angular/router";
import { AppQueryParamsKey } from "@core/application.model";

function internalContractGuidMatcher(name: string = "contractGuid", regex: RegExp = /ez[0-9a-z]{10}/i) {
    return (
        segments: UrlSegment[],
        segmentGroup: UrlSegmentGroup,
        route: Route) => {
        const parts = [regex];
        const posParams: { [key: string]: UrlSegment } = {};
        const consumed: UrlSegment[] = [];

        let currentIndex = 0;

        for (let i = 0; i < parts.length; ++i) {
            if (currentIndex >= segments.length) {
                return null;
            }
            const current = segments[currentIndex];

            const part = parts[i];
            if (!part.test(current.path)) {
                return null;
            }

            posParams[name] = current;
            consumed.push(current);
            currentIndex++;
        }

        if (route.pathMatch === 'full' &&
            (segmentGroup.hasChildren() || currentIndex < segments.length)) {
            return null;
        }

        return { consumed, posParams };
    };
}

export function contractGuidMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    const factory = internalContractGuidMatcher();
    return factory(segments, group, route);
}

export function runGuardsAndResolvers(curr: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot, opts: {
    queries?: { activate: boolean, queriesToIgnore?: string[], queriesToRemoveOnly?: string[] }
} = {}): boolean {
    const allKeys = Object.keys(curr.params);
    Object.keys(future.params).forEach((key) => {
        if (!allKeys.includes(key)) {
            allKeys.push(key);
        }
    });
    for (const key of allKeys) {
        if (future.params[key] !== curr.params[key]) {
            return true;
        }
    }
    if (opts.queries != null && opts.queries.activate) {
        const allKeysQueries = Object.keys(curr.queryParams);
        Object.keys(future.queryParams).forEach((key) => {
            if (!allKeysQueries.includes(key)) {
                allKeysQueries.push(key);
            }
        });
        for (const key of allKeysQueries) {
            if (opts.queries.queriesToIgnore == null || !opts.queries.queriesToIgnore.includes(key)) {
                if (opts.queries.queriesToRemoveOnly != null && opts.queries.queriesToRemoveOnly.includes(key)) {
                    const allCurrentParams: string[] = curr.queryParams[key] != null ? curr.queryParams[key].split(',') : [];
                    const allFuturParams: string[] = future.queryParams[key] != null ? future.queryParams[key].split(',') : [];
                    let hasRemove: boolean = false;
                    allCurrentParams.forEach((q) => {
                        if (!allFuturParams.includes(q)) {
                            hasRemove = true;
                            return true;
                        }
                    });
                    if (hasRemove) {
                        return true;
                    }
                } else if (future.queryParams[key] !== curr.queryParams[key]) {
                    return true;
                }
            }
        }
    }

    return false;
}

export function tsEditRunGuardsAndResolvers(curr: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot): boolean {
    return runGuardsAndResolvers(curr, future, { queries: { activate: true, queriesToRemoveOnly: ["timeseries"] } });
}

export function auctionRunGuardsAndResolvers(curr: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot): boolean {
    return runGuardsAndResolvers(curr, future, { queries: { activate: true, queriesToIgnore: [AppQueryParamsKey.List, AppQueryParamsKey.Send] } });
}
