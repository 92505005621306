<ez-banner class="banner" *ngIf="showBanner" [severity]="'warning'">
    <ez-title>The current form has been remotely updated</ez-title>
    <span class="banner-label">please refresh the page to continue... </span>
    <ez-actions>
        <ez-button
            [label]="'Refresh'"
            [small]="true"
            (onClick)="onUpdateBannerClick()"
            [severity]="'warning'"
        >
        </ez-button>
    </ez-actions>
</ez-banner>
<error-load-data-cpt
    *ngIf="hasErrorWatchObject"
    (onClickRetry)="onInitTab()"
></error-load-data-cpt>
<ez-tabs [value]="tab" [beforeChangeFn]="canClosedForm">
    <ez-tab-section [value]="'Products & Contracts'">
        <ez-tab-title
            [routerPage]="'sessionParamExchange'"
            [routerParams]="{ id: session?.id }"
            [routerOutlet]="outlet"
        >
            {{
                "autotrading.session.parameters.productsContracts.tabtitle"
                    | translate
            }}
        </ez-tab-title>
        <ez-content>
            <ez-loader *ngIf="hasOnLoading"></ez-loader>
            <at-time-and-location-form
                #timeAndLocationForm
                class="ez-form-container"
                *ngIf="tab === 'Products & Contracts'"
                [(value)]="objectTab"
                [disabled]="showBanner || !hasSessionUpdateRight"
                [products]="products"
                [sessionType]="session?.type"
                (onSubmit)="onFormSubmit()"
                [hideFooter]="true"
                (paramRestartSession)="changeParamRestartSession($event)"
            >
            </at-time-and-location-form>
        </ez-content>
    </ez-tab-section>
    <ez-tab-section *ngIf="objectTab !== null || tab !== 'Products & Contracts'" [value]="'Volume'">
        <ez-tab-title
            [routerPage]="'sessionParamVolume'"
            [routerParams]="{ id: session?.id }"
            [routerOutlet]="outlet"
        >
            {{ "autotrading.session.parameters.volumes.tabtitle" | translate }}
        </ez-tab-title>
        <ez-content>
            <ez-loader *ngIf="hasOnLoading"></ez-loader>
            <at-volume-position-flex-form
                #volumePosFlexForm
                class="ez-form-container"
                [disabled]="showBanner || !hasSessionUpdateRight"
                [(value)]="objectTab"
                *ngIf="tab === 'Volume'"
                [portfolios]="portfolios"
                [powerUnitsFamily]="powerUnitsFamily"
                [energyUnitsFamily]="energyUnitsFamily"
                [type]="session?.type"
                (onSubmit)="onFormSubmit()"
                [hideFooter]="true"
                (paramRestartSession)="changeParamRestartSession($event)"
            >
            </at-volume-position-flex-form>
        </ez-content>
    </ez-tab-section>
    <ez-tab-section *ngIf="objectTab !== null || tab !== 'Products & Contracts'" [value]="'Prices'">
        <ez-tab-title
            [routerPage]="'sessionParamPrice'"
            [routerParams]="{ id: session?.id }"
            [routerOutlet]="outlet"
        >
            {{ "autotrading.session.parameters.prices.tabtitle" | translate }}
        </ez-tab-title>
        <ez-content>
            <ez-loader *ngIf="hasOnLoading"></ez-loader>
            <at-prices-flexpos-form
                #pricePosFlexForm
                *ngIf="tab === 'Prices'"
                class="ez-form-container"
                [powerUnitsFamily]="powerUnitsFamily"
                [disabled]="showBanner || !hasSessionUpdateRight"
                [(value)]="objectTab"
                [energyPriceUnitsFamily]="energyPriceUnitsFamily"
                [units]="units"
                [defaultValue]="defaultEnergyPriceValue"
                [defaultUnit]="defaultCurrencyUnit"
                [sessionType]="session?.type"
                (onSubmit)="onFormSubmit()"
                (paramRestartSession)="changeParamRestartSession($event)"
            >
            </at-prices-flexpos-form>
        </ez-content>
    </ez-tab-section>
    <ez-tab-section *ngIf="objectTab !== null || tab !== 'Products & Contracts'" [value]="'Strategy'">
        <ez-tab-title
            [routerPage]="'sessionParamStrategy'"
            [routerParams]="{ id: session?.id }"
            [routerOutlet]="outlet"
        >
            {{
                "autotrading.session.parameters.strategies.tabtitle" | translate
            }}
        </ez-tab-title>
        <ez-content>
            <ez-loader *ngIf="hasOnLoading"></ez-loader>
            <at-strategy-form
                #strategyForm
                class="ez-form-container"
                *ngIf="tab === 'Strategy'"
                [(value)]="objectTab"
                [powerUnitsFamily]="powerUnitsFamily"
                [sessionType]="session?.type"
                [exchangeName]="session?.exchange"
                [defaultPriceValue]="defaultEnergyPriceValue"
                [defaultVolumeValue]="defaultPowerValue"
                [isRunStarted]="runState"
                [energyPriceUnitsFamily]="energyPriceUnitsFamily"
                [units]="units"
                (onSubmit)="onFormSubmit()"
                [hideFooter]="true"
                [throttlingLimit]="throttlingLimit" 
                [disabled]="!hasSessionUpdateRight"
            >
            </at-strategy-form>
        </ez-content>
    </ez-tab-section>
    <ez-tab-section *ngIf="objectTab !== null || tab !== 'Products & Contracts'" [value]="'Early termination'">
        <ez-tab-title
            [routerPage]="'sessionParamTermination'"
            [routerParams]="{ id: session?.id }"
            [routerOutlet]="outlet"
        >
            {{
                "autotrading.session.parameters.earlyTermination.tabtitle"
                    | translate
            }}
        </ez-tab-title>
        <ez-content>
            <ez-loader *ngIf="hasOnLoading"></ez-loader>
            <at-early-termination-form
                #terminationForm
                class="ez-form-container"
                [disabled]="showBanner || !hasSessionUpdateRight"
                [(value)]="objectTab"
                [units]="units"
                [sessionType]="session?.type"
                *ngIf="tab === 'Early termination'"
                (onSubmit)="onFormSubmit()"
                [hideFooter]="true"
            >
            </at-early-termination-form>
        </ez-content>
    </ez-tab-section>
</ez-tabs>
<div class="footer">
    <div *ngIf="objectTab === null && !showBanner" class="unfilled-warn">
        <ez-icon [name]="'exclamation'"></ez-icon>
        {{
            "autotrading.session.parameters.footer.unfilled-form"
                | translate: { form: tab }
        }}
    </div>
    <ez-button
        [label]="'general.reset' | translate"
        (onClick)="cancel()"
        [disabled]="hasOnLoading || !hasSessionUpdateRight"
    ></ez-button>
    <ez-button
        [label]="'general.save' | translate"
        [severity]="formHasChange && hasSessionUpdateRight ? 'warning' : 'primary'"
        (onClick)="submit()"
        [disabled]="hasOnLoading || !hasSessionUpdateRight"
    >
    </ez-button>
</div>
<ez-locker [activated]="hasLostConnection">Connection lost ...</ez-locker>

<ez-modal
    [(open)]="openConfirm"
    (onClose)="onConfirmClickButton($event)"
    class="modal-confirm"
>
    <ez-header>
        {{ "autotrading.session.parameters.confirm.save.title" | translate }}
    </ez-header>
    <ez-content>
        {{ "autotrading.session.parameters.confirm.save.content" | translate }}
    </ez-content>
    <ez-footer>
        <ez-button
            [label]="'general.cancel' | translate"
            ez-modal-close
        ></ez-button>
        <ez-button
            [label]="'general.confirm' | translate"
            [severity]="'primary'"
            [ez-modal-close]="true"
        >
        </ez-button>
    </ez-footer>
</ez-modal>

<ez-modal
    [(open)]="openConfirmSubmit"
    (onClose)="confirmSubmit($event)"
    class="modal-confirm"
>
    <ez-header>
        <span>
            {{
                "autotrading.session.parameters.confirm.runState.title"
                    | translate
            }}</span
        >
    </ez-header>
    {{ "autotrading.session.parameters.confirm.runState.content" | translate }}
    <ez-footer>
        <ez-button
            [label]="'general.cancel' | translate"
            ez-modal-close
        ></ez-button>
        <ez-button
            [label]="'general.confirm' | translate"
            [ez-modal-close]="true"
            [severity]="'primary'"
        >
        </ez-button>
    </ez-footer>
</ez-modal>

<ez-modal
    [(open)]="openConfirmSubmitAllParam"
    (onClose)="confirmSubmit($event)"
    class="modal-confirm"
>
    <ez-header>
        <span>
            {{
                "autotrading.session.parameters.confirm.runStateAllParams.title"
                    | translate
            }}</span
        >
    </ez-header>
    {{
        "autotrading.session.parameters.confirm.runStateAllParams.content"
            | translate
    }}
    <ez-footer>
        <ez-button
            [label]="'general.cancel' | translate"
            ez-modal-close
        ></ez-button>
        <ez-button
            [label]="'general.confirm' | translate"
            [ez-modal-close]="true"
            [severity]="'primary'"
        >
        </ez-button>
    </ez-footer>
</ez-modal>
