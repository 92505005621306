import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { EzComponentsModule } from "@eznergy/components";
import { TranslateModule } from "@ngx-translate/core";
import { AppPipesModule } from "@pipes/pipes.module";
import { AbTesting } from "./ab-testing/ab-testing.component";
import { MarkdownEditorComponent } from "./help/markdown-editor.component";
import { EditorToolbarComponent } from "./help/toolbar/editor-toolbar.component";
import { AdminRight } from "./right/admin-right.component";
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        EzComponentsModule,
        TranslateModule,
        AppPipesModule,
        SharedModule
    ],
    declarations: [
        AbTesting,
        EditorToolbarComponent,
        MarkdownEditorComponent,
        AdminRight
    ],
    exports: [
        AbTesting,
        EditorToolbarComponent,
        MarkdownEditorComponent,
        AdminRight
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminComponentsModule {

}
