<ez-form (onSubmit)="formSubmit()" [disabled]="disabled">
    <ez-form-group>
        <ez-label>{{ 'autotrading.session.parameters.earlyTermination.title' | translate }}</ez-label>
    </ez-form-group>
    <ez-form-group>
        <ez-label>{{ 'autotrading.session.parameters.earlyTermination.general' | translate }}</ez-label>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.earlyTermination.maxTrades' | translate }}</ez-label>
            <ez-input-number [(ngModel)]="tempValue.maxTradesNumber"></ez-input-number>
        </ez-form-field>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.earlyTermination.maxSessionProfitAndLoss' | translate }}
            </ez-label>
            <ez-physical-value [(ngModel)]="tempValue.maxSessionProfitAndLoss" [units]="currencyUnits">
            </ez-physical-value>
        </ez-form-field>
    </ez-form-group>
    <ez-form-group>
        <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.ask.title' | translate }}</ez-label>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.ask.above' | translate }}</ez-label>
            <ez-physical-value [(ngModel)]="tempValue.askAbove" [units]="energyPriceUnits"></ez-physical-value>
        </ez-form-field>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.ask.below' | translate }}</ez-label>
            <ez-physical-value [(ngModel)]="tempValue.askBelow" [units]="energyPriceUnits"></ez-physical-value>
        </ez-form-field>
    </ez-form-group>
    <ez-form-group>
        <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.bid.title' | translate }}</ez-label>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.bid.above' | translate }}</ez-label>
            <ez-physical-value [(ngModel)]="tempValue.bidAbove" [units]="energyPriceUnits"></ez-physical-value>
        </ez-form-field>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.bid.below' | translate }}</ez-label>
            <ez-physical-value [(ngModel)]="tempValue.bidBelow" [units]="energyPriceUnits"></ez-physical-value>
        </ez-form-field>
    </ez-form-group>
    <ez-form-group>
        <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.trade.title' | translate }}</ez-label>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.trade.above' | translate }}</ez-label>
            <ez-physical-value [(ngModel)]="tempValue.tradeAbove" [units]="energyPriceUnits"></ez-physical-value>
        </ez-form-field>
        <ez-form-field>
            <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.trade.below' | translate }}</ez-label>
            <ez-physical-value [(ngModel)]="tempValue.tradeBelow" [units]="energyPriceUnits"></ez-physical-value>
        </ez-form-field>
    </ez-form-group>
    <ez-form-group>
        <ez-label>{{ 'autotrading.session.parameters.earlyTermination.groups.tsDelay.title' | translate }}</ez-label>
        <ez-table [data]="tempValue.timeSeriesUpdateDelays" class="table-ts-delay">
            <ez-column property="actived">
                <th *ezTbHeader>
                    {{ 'autotrading.session.parameters.earlyTermination.groups.tsDelay.activated' | translate }}</th>
                <td *ezTbCell="let item">
                    <ez-checkbox [(ngModel)]="item.activated" [disabled]="!item.editable || disabled">
                    </ez-checkbox>
                </td>
            </ez-column>
            <ez-column property="type">
                <th *ezTbHeader>
                    {{ 'autotrading.session.parameters.earlyTermination.groups.tsDelay.type.title' | translate }}</th>
                <td *ezTbCell="let item" class="max-delay-type">
                    <span>{{ ('autotrading.session.parameters.earlyTermination.groups.tsDelay.type.'+item.type) |
                        translate }}</span>
                </td>
            </ez-column>
            <ez-column property="maxDelay">
                <th *ezTbHeader>
                    {{ 'autotrading.session.parameters.earlyTermination.groups.tsDelay.maxDelay' | translate }}</th>
                <td *ezTbCell="let item">
                    <ez-form-field>
                        <ez-timespan [(ngModel)]="item.maxDelay" [min]="minDelay" [defaultUnit]="defaultTimeUnit"
                            [disabled]="!item.activated || !item.editable || disabled" [required]="true">
                        </ez-timespan>
                    </ez-form-field>
                </td>
            </ez-column>
            <ez-row *ezTbRow="let item" [class.disabled]="item.editable === false || disabled"></ez-row>
        </ez-table>
    </ez-form-group>
    <ez-footer *ngIf="!hideFooter">
        <ez-button [label]="'general.reset' | translate" ez-form-cancel></ez-button>
        <ez-button [label]="'general.save' | translate" [severity]="'primary'" ez-form-submit></ez-button>
    </ez-footer>
</ez-form>