// @ts-nocheck
import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Exchange } from '../../../../models/exchange.model';
import { FormBase } from "src/app/pages/form/core/form-base";
import { ISession, IExchangeUser, Session } from "@eztypes/webapi";

@Component({
    selector: 'at-nemos-form',
    templateUrl: './nemos.component.html',
    styleUrls: ['./nemos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AtNemosForm extends FormBase {
    @Output() valueChange: EventEmitter<ISession> = new EventEmitter<ISession>();

    @Input()
    get value(): Session {
        return this._session;
    }
    set value(session: Session) {
        if (this.value !== session) {
            this._session = session;
            this._cdr.markForCheck();
        }
    }
    private _session: Session;

    @Input()
    get exchanges(): Exchange[] {
        return this._exchanges;
    }
    set exchanges(value: Exchange[]) {
        if (this.exchanges !== value) {
            this._exchanges = value;
            this.setExchangeSelected();
            this._cdr.markForCheck();
        }
    }
    private _exchanges: Exchange[];

    get exchangeSelected(): Exchange {
        return this._exchangeSelected;
    }
    private _exchangeSelected: Exchange;

    exUserList: IExchangeUser[] = [];

    constructor(private _cdr: ChangeDetectorRef) {
        super();
    }

    private _updateUserList() {
        if (!this._exchangeSelected) {
            this.exUserList = [];
        } else {
            this.exUserList = this._exchangeSelected.exchangeUsers;
        }
    }

    exchangeChange(exchange: Exchange) {
        if (exchange) {
            this._exchangeSelected = _.find(this._exchanges, { token: exchange.token });
            this._session.exchange = this._exchangeSelected.token;
        } else {
            this._exchangeSelected = undefined;
            this._session.exchange = undefined;
            this._session.exchangeUser = undefined;
        }
        this._updateUserList();
        this._cdr.detectChanges();
    }

    formSubmit() {
        this.valueChange.emit(this._session);
        this.onSubmit.emit();
    }

    private setExchangeSelected() {
        if (this.value.exchange) {
            this._exchangeSelected = _.find(this._exchanges, { token: this.value.exchange });
        }
        this._updateUserList();
    }
}
