// @ts-nocheck
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

export enum Direction {
    Exit = 'exit',
    Entry = 'entry'
}

@Component({
    selector: 'transport-direction-icon',
    templateUrl: './transport-direction.icon.html',
    styleUrls: ['./transport-direction.icon.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportDirectionComponent {

    @Input() direction: Direction;
    readonly Direction = Direction;

    constructor() { }
}



