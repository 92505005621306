// @ts-nocheck
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, ChangeDetectorRef, HostListener, ViewChild, ElementRef, Output, EventEmitter, HostBinding, Renderer2 } from '@angular/core';
import { IDownloadProgess } from '@eznergy/webapi';
import * as _ez from "@eznergy/core";
import * as d3 from 'd3';

@Component({
    selector: 'download-ctl',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DownloadControllerComponent {
    @Output() readonly onClick: EventEmitter<MouseEvent> = new EventEmitter();
    @ViewChild('loader', { static: true }) readonly loaderHtml: ElementRef<SVGElement>;

    @Input()
    get progress(): IDownloadProgess {
        return this._progress;
    }
    set progress(value: IDownloadProgess) {
        if (this.progress !== value) {
            this._progress = value;
            this._refreshLoader();
            this._cdr.markForCheck();
        }
    }
    private _progress: IDownloadProgess;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        const newValue = _ez.coerceBoolean(value, false);
        if (newValue !== this.disabled) {
            this._disabled = newValue;
            this._cdr.markForCheck();
        }
    }
    private _disabled: boolean = false;

    @Input()
    get icon(): string {
        return this._icon;
    }
    set icon(value: string) {
        const newValue = value || 'download';
        if (newValue != this.icon) {
            this._icon = newValue;
            this._cdr.markForCheck();
        }
    }
    private _icon: string = 'download';

    @Input()
    get big(): boolean {
        return this._big;
    }
    set big(value: boolean) {
        const newValue = _ez.coerceBoolean(value, false);
        if (newValue !== this.big) {
            this._big = newValue;
            this._cdr.markForCheck();
        }
    }
    private _big: boolean = false;

    @HostListener('click', ["$event"])
    click(event: MouseEvent): void {
        if (this.disabled || this.progress != null) {
            event.stopImmediatePropagation();
        } else {
            this.onClick.emit(event);
        }
    }

    @HostBinding("class.on-download")
    get onDownload(): boolean {
        return this.progress != null;
    }


    constructor(private readonly _cdr: ChangeDetectorRef, private readonly _elRef: ElementRef<HTMLElement>) { }

    private _timer: d3.Timer;
    // private _svg: d3.Selection<SVGElement, any, null, undefined>;
    private _progressSvg: d3.Selection<SVGPathElement, any, null, undefined>;
    private _arcProgress: d3.Arc<any, d3.DefaultArcObject>;
    private _createSvg(): void {
        const loaderRadius = (this._elRef.nativeElement.offsetHeight / 2);
        this._arcProgress = d3.arc();
        const arcLoader = d3.arc()
            .innerRadius(loaderRadius - 3)
            .outerRadius(loaderRadius)
            .startAngle(0)
            .endAngle(this._degToRad(70));
        const g = d3.select(this.loaderHtml.nativeElement).append("g");


        this._progressSvg = g.append("path")
            .datum({ endAngle: 0, startAngle: 0, innerRadius: loaderRadius - 3, outerRadius: loaderRadius })
            .attr("class", "progress");
        g.append("path").attr("class", "loader")
            .attr("d", arcLoader);

    }

    private _currentDeg: number;
    private _refreshLoader(): void {
        if (this._progressSvg == null) {
            this._createSvg();
        }
        if (this._progress == null) {
            this._timer.stop();
            this._timer = undefined;
            this._refreshProgress(100);
        } else if (this._timer == null) {
            const loaderRadius = (this._elRef.nativeElement.offsetHeight / 2);
            this._progressSvg.datum({ endAngle: 0, startAngle: 0, innerRadius: loaderRadius - 3, outerRadius: loaderRadius });
            const animationTime = 300;
            this._timer = d3.interval(() => {
                this._refreshProgress(this.progress.progress, animationTime);
            }, animationTime, 0);
        }
    }

    private _degToRad(deg: number) {
        return deg * Math.PI / 180;
    }

    private _refreshProgress(progress: number, animationTime: number = 300): void {
        const newDeg = progress * 360 / 100;
        if (this._currentDeg !== newDeg) {
            this._progressSvg
                .interrupt()
                .transition()
                .ease(d3.easeLinear)
                .duration(animationTime)
                .attrTween("d", (d: d3.DefaultArcObject) => {
                    const newAngle = this._degToRad(newDeg);
                    const interpolate = d3.interpolate(d.endAngle, newAngle);
                    return (t: number) => {
                        d.endAngle = interpolate(t);
                        return this._arcProgress(d);
                    };
                });
            this._currentDeg = newDeg;
        }

        if (progress === 100) {
            this._progressSvg
                .transition()
                .ease(d3.easeBounce)
                .delay(animationTime)
                .duration(animationTime * 1.5)
                .attrTween("d", (d: d3.DefaultArcObject) => {
                    const interpolateInner = d3.interpolate(d.innerRadius, 0);
                    // const interpolateOuter = d3.interpolate(d.outerRadius, 0);
                    return (t: number) => {
                        d.innerRadius = interpolateInner(t);
                        // d.outerRadius = interpolateOuter(t);
                        return this._arcProgress(d);
                    };
                });
            this._progressSvg
                .transition()
                .ease(d3.easeBounce)
                .delay(animationTime * 2.5)
                .duration(animationTime * 1.5)
                .attrTween("d", (d: d3.DefaultArcObject) => {
                    // const interpolateInner = d3.interpolate(d.innerRadius, 0);
                    const interpolateOuter = d3.interpolate(d.outerRadius, 0);
                    return (t: number) => {
                        // d.innerRadius = interpolateInner(t);
                        d.outerRadius = interpolateOuter(t);
                        return this._arcProgress(d);
                    };
                });
        }
    }
}
