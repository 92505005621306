// @ts-nocheck
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBase } from '@forms/core/form-base';
import { DateRange, DateTime, TimeUnit } from '@eztypes/generic';

@Component({
    selector: 'one-day-period-form',
    templateUrl: './one-day-period.component.html',
    styleUrls: ['./one-day-period.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class OneDayPeriodForm extends FormBase {

    @Input()
    get value(): DateRange {
        return this._value;
    }
    set value(value: DateRange) {
        if (value !== this.value) {
            this._value = value;
            this._initDate();
            this._cdr.markForCheck();
        }
    }
    private _value: DateRange;

    @Input()
    get minDate(): DateTime {
        return this._minDate;
    }
    set minDate(value: DateTime) {
        if (this.minDate !== value) {
            this._minDate = value;
            this._cdr.markForCheck();
        }
    }
    private _minDate: DateTime;

    @Output()
    readonly valueChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

    date: DateTime;
    startTime: DateTime;
    endTime: DateTime;

    constructor(private _cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this._initDate();
    }

    onDateSelectedForAddRange(date: DateTime) {
        if (date) {
            this.date = date;
        } else {
            this.date = undefined;
        }
    }

    private _initDate() {
        if (this.value) {
            this.date = this.value.from.clone();
            this.startTime = this.value.from.clone();
            this.endTime = this.value.to.clone();
        } else {
            this.date = undefined;
            this.startTime = DateTime.now().startOf(TimeUnit.Days);
            this.endTime = this.startTime.addDays(1);
        }
        this.form.cancel();
    }

    formSubmit(): void {
        let startDate = this.date.startOf(TimeUnit.Days).addHours(this.startTime.hours).addMinutes(this.startTime.minutes);
        let endDate = this.date.startOf(TimeUnit.Days);
        const endHours = this.endTime.hours;
        const endMinutes = this.endTime.minutes;
        if (endHours === 0 && endMinutes === 0) {
            endDate = endDate.addDays(1);
        } else {
            endDate = endDate.addHours(endHours).addMinutes(endMinutes);
        }
        this._value = new DateRange(startDate, endDate);
        this.valueChange.emit(this.value);
    }

}
