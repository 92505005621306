import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppDirectivesModule } from "@directives/directives.module";
import { EzComponentsModule } from "@eznergy/components";
import { TranslateModule } from "@ngx-translate/core";
import { AppPipesModule } from "@pipes/pipes.module";
import { CalendarSelectorComponent } from './calendar/calendar.component';
import { DateRangeSelectorComponent } from './date-range/date-range.component';
import { FiltersNominationsStatesComponent } from './filters-nominations-states/filters-nominations-states.component';
import { GranularitySelectorComponent } from './granularity/granularity.component';
import { MaterialTypeSelectorComponent } from './material-type/material-type.component';
import { SearchComponent } from './search/search.component';
import { SelectComponent } from './select/select.component';
import { TimezoneSelectorComponent } from './timezone/timezone.component';
import { UnitSelectorComponent } from './unit/unit.component';
import { IconsModule } from '../icons/icons.module';
import { PipesModules } from '../../pipes/pipes.modules';
import { AuctionStatesComponent } from './auction-states/auction-states.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        EzComponentsModule,
        AppPipesModule,
        AppDirectivesModule,
        IconsModule,
        PipesModules
    ],
    declarations: [
        AuctionStatesComponent,
        CalendarSelectorComponent,
        DateRangeSelectorComponent,
        FiltersNominationsStatesComponent,
        GranularitySelectorComponent,
        MaterialTypeSelectorComponent,
        SearchComponent,
        SelectComponent,
        TimezoneSelectorComponent,
        UnitSelectorComponent
    ],
    exports: [
        AuctionStatesComponent,
        CalendarSelectorComponent,
        DateRangeSelectorComponent,
        FiltersNominationsStatesComponent,
        GranularitySelectorComponent,
        MaterialTypeSelectorComponent,
        SearchComponent,
        SelectComponent,
        TimezoneSelectorComponent,
        UnitSelectorComponent
    ]
})
export class FiltersComponentModule {}
