import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageCounterpartiesPreferenceInfo {
    static load(preferences: string | PageCounterpartiesPreferenceInfo): PageCounterpartiesPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageCounterpartiesPreferenceInfo>(preferences, PageCounterpartiesPreferenceInfo) as PageCounterpartiesPreferenceInfo;
        return preferences;
    }
    readonly version: number = 1;
    displayOld: boolean | undefined;
    displayHub: boolean | undefined;
}

@UserPreferenceClassDecorator
export class PageCounterpartiesPreference implements IPreferencePage {
    static load(preferences: string | PageCounterpartiesPreferenceInfo): PageCounterpartiesPreference {
        if (!preferences) {
            return new PageCounterpartiesPreference();
        }
        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageCounterpartiesPreferenceInfo.load(preferences);
                return new PageCounterpartiesPreference(v1.displayHub, v1.displayOld);
            default:
                return new PageCounterpartiesPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    @UPPropertySave
    displayOld: boolean | undefined;
    @UPPropertySave
    displayHub: boolean | undefined;

    search: string | undefined;

    constructor(displayHub: boolean = true,
        displayOld: boolean = true) {
        this.displayHub = displayHub;
        this.displayOld = displayOld;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all) {
            this.displayHub = undefined;
            this.displayOld = undefined;
            this.search = undefined;
        }
    }
}

