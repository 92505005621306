// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppQueryParamsKey } from '@core/application.model';
import { Logger } from '@core/logger';
import { MultiLoader } from '@core/multiloader';
import { BasePagePopup } from '@core/pages';
import { ApiService } from '@eznergy/webapi';
import { AuctionDeliveryArea, IAuctionDeliveryArea, IAuctionExchangeProducts, IAuctionScheduledBatch, IOrganisation, Organisation } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import { AppPages, RouterService } from '@services/router.service';
import { AuctionImmediateSchedulesForm } from './auction-immediate-schedule/auction-immediate-schedule.component';
import { AuctionSchedulesForecastsForm } from './auction-schedule-forecasts/auction-schedule-forecasts.component';

export enum Tab {
    Immediate = "immediate",
    Forecast = "schedule"
}

@Component({
    selector: 'auction-schedule-popup',
    templateUrl: './auction-schedule-popup.component.html',
    styleUrls: ['./auction-schedule-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        "[class.ez-page]": "true"
    }
})
export class AuctionSchedulesPagePopup extends BasePagePopup {

    @ViewChild(AuctionSchedulesForecastsForm, { static: true }) readonly forecastsForm: AuctionSchedulesForecastsForm;
    @ViewChild(AuctionImmediateSchedulesForm, { static: true }) readonly immediateForm: AuctionImmediateSchedulesForm;

    get urlPage(): string {
        if (!this._routerSvc || !this._deliveryAreaId || !this._shipperId)
            return undefined;
        let params = {};
        if (this._scheduleId) {
            params = { deliveryAreaId: this._deliveryAreaId.toString(), shipper: this._shipperId.toString(), schedule: this._scheduleId.toString() };
        } else {
            params = { deliveryAreaId: this._deliveryAreaId.toString(), shipper: this._shipperId.toString() };
        }
        return this._routerSvc.getUrl(AppPages.AuctionSchedulesCreate, { params: params });
    }

    get urlPopup(): string {
        if (!this._routerSvc || !this._deliveryAreaId || !this._shipperId)
            return undefined;
        let params = {};
        if (this._scheduleId) {
            params = { deliveryArea: this._deliveryAreaId.toString(), shipper: this._shipperId.toString(), schedule: this._scheduleId.toString() };
        } else {
            params = { deliveryArea: this._deliveryAreaId.toString(), shipper: this._shipperId.toString() };
        }
        return this._routerSvc.getUrlPopup(AppPages.AuctionSchedulesCreate, params);
    }

    get onLoad(): boolean {
        return this._loaders.onLoading;
    }
    private readonly _loaders: MultiLoader = new MultiLoader();

    public get exchangeProducts(): IAuctionExchangeProducts[] {
        return this._exchangeProducts;
    }
    private _exchangeProducts: IAuctionExchangeProducts[];

    public get shipper(): IOrganisation {
        return this._shipper;
    }
    private _shipper: IOrganisation;

    public get deliveryArea(): IAuctionDeliveryArea {
        return this._deliveryArea;
    }
    private _deliveryArea: IAuctionDeliveryArea;

    public get schedule(): IAuctionScheduledBatch {
        return this._schedule;
    }
    private _schedule: IAuctionScheduledBatch;

    readonly tabEnum = Tab;
    tabSelected: Tab = Tab.Immediate;

    private _deliveryAreaId: number;
    private _shipperId: number;
    private _scheduleId: number;

    constructor(
        private readonly _router: Router,
        private readonly _api: ApiService,
        private readonly _appSvc: ApplicationService,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _routerSvc: RouterService,
        readonly logger: Logger) {
        super(logger);
    }

    ngOnInit(): void {

        const subs = this._routerSvc.paramsChange.subscribe((params) => {
            if (params && params.uri) {
                const uri = params.uri.get('popup');
                if (uri) {
                    const deliveryAreaId = uri[AppQueryParamsKey.DeliveryArea] != null && !isNaN(uri[AppQueryParamsKey.DeliveryArea]) ? parseInt(uri[AppQueryParamsKey.DeliveryArea], 10) : undefined;
                    const shipperId = uri[AppQueryParamsKey.Shipper] != null && !isNaN(uri[AppQueryParamsKey.Shipper]) ? parseInt(uri[AppQueryParamsKey.Shipper], 10) : undefined;
                    const scheduleId = uri[AppQueryParamsKey.Schedule] != null && !isNaN(uri[AppQueryParamsKey.Schedule]) ? parseInt(uri[AppQueryParamsKey.Schedule], 10) : undefined;

                    if (deliveryAreaId) {
                        if (this._deliveryAreaId != deliveryAreaId) {
                            this._deliveryAreaId = deliveryAreaId;
                            this._deliveryArea = new AuctionDeliveryArea(deliveryAreaId);
                        }
                    }
                    if (shipperId) {
                        if (this._shipperId != shipperId) {
                            this._shipperId = shipperId;
                            this._shipper = new Organisation(shipperId);
                        }
                    }
                    if (scheduleId) {
                        this.tabSelected = Tab.Forecast;
                        if (this._scheduleId != scheduleId) {
                            this._scheduleId = scheduleId;
                        }
                    }
                }
                this._loadData();
            }
        });
        this._subs.push("sub-queryparams", subs);
    }

    submitForm(): void {
        this._subscriptions.clearSubStartWith('validated');
        if (this.tabSelected == Tab.Forecast) {
            this._subscriptions.push('validated-forecast', this.forecastsForm.validated.subscribe(
                () => {
                    this.cancel();
                }
            ));
            this.forecastsForm.formSubmit();
        } else {
            this._subscriptions.push('validated-immediate', this.immediateForm.validated.subscribe(
                () => {
                    this.cancel();
                }
            ));
            this.immediateForm.formSubmit();
        }
    }

    cancel(): void {
        this._router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: "merge" });
    }

    private _loadData(): void {
        if (this._shipper && this._deliveryArea) {
            const key = "loadData";
            this._loaders.push(key);
            const sub =
                this._api.auctions.exchangeProducts(this._appSvc.contract.id, this._shipper, this._deliveryArea).subscribe(
                    (exchangesProducts: IAuctionExchangeProducts[]) => {
                        if (exchangesProducts && exchangesProducts.length > 0) {
                            this._exchangeProducts = exchangesProducts;
                            this._loaders.remove(key);
                            this._cdr.detectChanges();
                        }
                    },
                    (error: Error) => {
                        this._logger.error("Request Auction Exchange Products", error);
                        this._loaders.remove(key);
                        this._cdr.detectChanges();
                    }
                );
            this._subs.push('load-conf', sub);
        }

        if (this._scheduleId) {
            const key = "loadSchedule";
            this._loaders.push(key);
            const sub =
                this._api.auctions.schedules.get(this._appSvc.contract.id, this._shipper, this._scheduleId).subscribe(
                    (schedule: IAuctionScheduledBatch) => {
                        if (schedule) {
                            this._schedule = schedule;
                            this._loaders.remove(key);
                            this._cdr.detectChanges();
                        }
                    },
                    (error: Error) => {
                        this._logger.error("Request Auction Schedule", error);
                        this._loaders.remove(key);
                        this._cdr.detectChanges();
                    }
                );
            this._subs.push('load-schedule', sub);
        }
    }
}
