<ez-icon class="icon-btn-options" [name]="'ellipsis-v'" (click)="loadData()" [class.opened]="popover.open"
    ez-overlay-origin #origin="EzOverlayOrigin">
</ez-icon>
<ez-popover #popover [origin]="origin" [actionType]="'click'" [alignH]="'left'" [alignV]="'bottom'" [alignInnerH]="true"
    [class]="'popover-pos-deals'" (openChange)="detectChanges()">
    <ez-loader *ngIf="dataLoaded !== true && popover.open"></ez-loader>
    <div class="title">
        <span>{{ 'general.counterparts' | translate }} ({{counterparts?.length}})</span>
        <ez-checkbox [indeterminate]="true" [checked]="hasCounterpartSelected"
            (checkedChange)="toggleAllCpSelect($event)"></ez-checkbox>
    </div>
    <ez-ul class="counterparts" *ngIf="data?.length > 0; else noCp">
        <ez-li *ngFor="let cv of data; trackBy:dataTrackBy" [class.opened]="cv.opened">
            <div class="content" [class.selected]="cv.selected" (click)="toggleCpSelect(cv)">
                <ez-checkbox class="selectable-action" [checked]="cv.selected"
                    [ezTooltip]="'general.select-all' | translate">
                </ez-checkbox>
                <span [popoverCard]="cv.data.entity">
                    {{cv.data.entity?.shortName}}
                </span>
                <div class="btn-toggle-display" (click)="toggleDisplayDeals($event,cv)"
                    [ezTooltip]="'Toggle display time series'">
                    <ez-icon *ngIf="cv.deals?.length > 0" [name]="cv.opened ? 'angle-up': 'angle-down'"></ez-icon>
                </div>
            </div>
            <ez-ul *ngIf="cv.deals?.length > 0">
                <ez-li class="header">
                    <span>{{ 'general.timeseries' | translate }} ({{cv.deals.length}})</span>
                    <ez-checkbox [indeterminate]="true" [checked]="cv.hasDealSelected"
                        (checkedChange)="toggleAllDealSelect(cv, $event)"></ez-checkbox>
                </ez-li>
                <ez-li *ngFor="let dv of cv.deals; trackBy:dataTrackBy" (click)="toggleDealSelect(cv,dv)">
                    <div class="content" [class.selected]="dv.selected">
                        <ez-checkbox class="selectable-action" [checked]="dv.selected"
                            [ezTooltip]="'general.select-all' | translate">
                        </ez-checkbox>
                        <span [popoverCard]="dv.data.variableVolume">
                            {{dv.data.variableVolume?.name}}
                        </span>
                    </div>
                </ez-li>
            </ez-ul>
        </ez-li>
    </ez-ul>
    <ng-template #noCp>
        <span class="no-data" *ngIf="deals != null">{{
            'components.grid.gp-data.no-counterparts' |
            translate}}</span>
    </ng-template>
</ez-popover>
