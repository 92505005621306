import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogModule
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';


@Component({
    selector: 'app-confirm',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        TranslateModule
    ],
})
export class ConfirmModal {

    constructor(
        @Inject(MAT_DIALOG_DATA) public confirmMessage: string
    ) {}
}
