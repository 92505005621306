import { Injectable } from "@angular/core";
import { Logger } from '@core/logger';
import { TranslateService } from "@ngx-translate/core";
import { CacheService, KeyCache } from './cache.service';

@Injectable({ providedIn: 'root' })
export class CultureService {

    private static readonly _languageSupported: any = { "fr": "fr", "en": "en" };
    private static readonly _defaultLanguage: string = "en";

    public _culture: string | null = null;

    get currentLanguage(): string | null {
        return this._language;
    }
    set currentLanguage(lang: string | null) {
        this._language = CultureService._checkLanguageSupported(lang);
        this._cacheSvc.set(KeyCache.Language, this._language);
        this._setLanguage(true);
    }
    private _language: string | null = null;

    constructor(private readonly _translateSrv: TranslateService,
        private readonly _cacheSvc: CacheService,
        private readonly _logger: Logger) {
        this._prepareCultureByNavigator();
        let langCache = this._cacheSvc.get<string>(KeyCache.Language);
        if (langCache) this._language = langCache;
        this._translateSrv.addLangs(["en", "fr"]);
        this._setLanguage();
    }

    private _setLanguage(reload: boolean = false) {
        if (!this._language){
            return;
        }
        this._translateSrv.setDefaultLang(this._language);
        this._translateSrv.use(this._language);
        let data: any = [];
        data["language"] = this._language;
        if (reload) {
            location.reload();
        }
        this._logger.debug("Set Language " + this._language);
    }

    private _prepareCultureByNavigator() {
        this._culture = this._language = null;
        let culture = CultureService.getCultureByNavigator();
        let limiter = culture?.indexOf("-");
        if (!!culture && culture.length > 2 && limiter !== -1) {
            this._culture = culture;
            this._language = culture.substring(0, limiter);
        } else {
            this._language = this._culture = culture;
        }
        this._language = CultureService._checkLanguageSupported(this._language);
    }

    public static getCultureByNavigator(): string | null {
        let allLanguages = navigator['languages'] || ["en-GB"];
        let culture = navigator.language || null;
        if (culture == null && allLanguages[0]) {
            culture = allLanguages[0];
        }
        let limiter = culture?.indexOf("-");
        if (!!culture && culture.length > 2 && limiter !== -1) {
            culture = culture;
        } else {
            let language = culture;
            for (let i = allLanguages.length - 1; i > 0; i--) {
                if (!!culture && !!allLanguages[i] && allLanguages[i] !== culture && allLanguages[i]?.toLowerCase().startsWith(culture.toLowerCase())) {
                    culture = allLanguages[i] as string;
                    break;
                }
            }
            if (culture == null) {
                culture = language;
            }
        }
        return culture;
    }

    private static _checkLanguageSupported(language: string | null): string | null {
        if (!!language && !this._languageSupported[language]) {
            return this._defaultLanguage;
        }
        return language;
    }
}
