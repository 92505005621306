import { GridPointCategories } from '@eztypes/webapi';

export const GridPointCategoryOrder: Record<GridPointCategories, number> = {
    [GridPointCategories.VTP]: 0,
    [GridPointCategories.Production]: 1,
    [GridPointCategories.Consumption]: 2,
    [GridPointCategories.CrossBorder]: 3,
    [GridPointCategories.Storage]: 4,
    [GridPointCategories.Other]: 5,
} as const;

export function sortGridPointCategories(a: GridPointCategories, b: GridPointCategories ): number {
    return GridPointCategoryOrder[a] > GridPointCategoryOrder[b] ? 1 : -1;
}