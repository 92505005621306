import { UPPropertySave, UserPreferenceClassDecorator } from "@core/decorators";
import { deserialize } from "@eznergy/core";
import { NominationState } from '../../../modules/nomination/models/overview/dated-gravity.model';
import { IPreferencePage, OptionsResetPreferencePage, VersionContainer } from "../core.model";

class PageOverviewNominationsPreferenceInfo {
    static load(preferences: string | PageOverviewNominationsPreferenceInfo): PageOverviewNominationsPreferenceInfo {
        if (typeof preferences === "string")
            return deserialize<PageOverviewNominationsPreferenceInfo>(preferences, PageOverviewNominationsPreferenceInfo) as PageOverviewNominationsPreferenceInfo;
        return preferences;
    }

    readonly version: number = 1;
    refreshTimer: number | undefined;
}

@UserPreferenceClassDecorator
export class PageOverviewNominationsPreference implements IPreferencePage {
    static load(preferences: string | PageOverviewNominationsPreferenceInfo): PageOverviewNominationsPreference {
        if (!preferences) {
            return new PageOverviewNominationsPreference();
        }

        let version: number;
        if (typeof preferences === "string") {
            const prefInfo = deserialize<VersionContainer>(preferences, VersionContainer) as VersionContainer;
            version = prefInfo.version;
        } else
            version = preferences.version;
        switch (version) {
            case 1:
                const v1 = PageOverviewNominationsPreferenceInfo.load(preferences);
                return new PageOverviewNominationsPreference(v1.refreshTimer);
            default:
                return new PageOverviewNominationsPreference();
        }
    }

    @UPPropertySave
    readonly version: number = 1;
    states: NominationState[] | undefined;
    @UPPropertySave
    refreshTimer: number | undefined;

    constructor(refreshTimer: number | undefined = undefined) {
        this.refreshTimer = refreshTimer;
    }

    reset(options: OptionsResetPreferencePage = {}): void {
        if (options.all || options.byGrid) {
            this.states = undefined;
        }
        if (options.all) {
            this.refreshTimer = undefined;
        }
    }
}

