<ez-form
    #form
    (onSubmit)="formSubmit()"
    (onError)="overrideFormErrors($event)"
    (onCancel)="formCancel()"
    [disabled]="isOrderCanceled"
    [autofocus]="false"
>
    <ez-loader *ngIf="loading"></ez-loader>

    <div class="btn-groups">
        <ez-button-group
            class="btn-states"
            [value]="orderEntry.isActive"
            [required]="true"
            (valueChange)="changeStatusOrder($event)"
        >
            <ez-button
                [label]="'autotrading.market.orderState.' + states.Active | translate"
                [severity]="'success'"
                [value]="true"
                [disabled]="orderEntry.isActive"
            >
            </ez-button>
            <!-- <ez-button
                [label]="
                    'autotrading.market.orderState.' + states.Inactive
                        | translate
                "
                [severity]="'danger'"
                [value]="false"
            >
            </ez-button> -->
        </ez-button-group>

        <ez-button-group
            [value]="orderEntry.direction"
            (valueChange)="onChangeDirection($event)"
            [required]="true"
            class="btn-directions"
        >
            <ez-button
                *ngFor="let dir of directions | enumStringToIterable"
                [value]="dir['value']"
                [disabled]="internalIdOrder != null"
                [label]="
                    'autotrading.market.orderDir.' + dir['value'] | translate
                "
                [ngClass]="{
                    'order-buy': dir['value'] == directions.Buy,
                    'order-sell': dir['value'] == directions.Sell
                }"
            >
            </ez-button>
        </ez-button-group>
    </div>

    <ez-form-field>
        <ez-label>{{
            "autotrading.market.form.order.contracts" | translate
        }}</ez-label>
        <ez-select
            [required]="true"
            [ngModel]="selectedContract"
            [disabled]="internalIdOrder != null"
            (valueChange)="onSelectedContractChange($event)"
            [placeholder]="'Select a contract'"
        >
            <ez-select-option *ngFor="let c of contracts" [value]="c">{{
                c.contract.name
            }}</ez-select-option>
        </ez-select>
    </ez-form-field>

    <div class="group-flex">
        <ez-form-field #volumeField>
            <ez-label>{{
                "autotrading.market.form.order.quantity.label" | translate
            }}</ez-label>
            <ez-physical-value
                [name]="'quantity-field'"
                [(ngModel)]="orderEntry.quantity"
                [required]="true"
                [units]="energyUnits"
                [min]="0"
                [max]="maxVolumeLimit?.value?.value"
                (valueChange)="onInput()"
                [hasCustomError]="
                    maxVolumeLimit?.enabled && !maxVolumeLimit?.value
                "
            >
            </ez-physical-value>
            <ez-description
                *ngIf="maxVolumeLimit?.enabled && maxVolumeLimit?.value"
            >
                <span
                    [class.error]="
                        orderEntry?.quantity?.value >
                        maxVolumeLimit?.value?.value
                    "
                >
                    {{
                        "autotrading.market.form.order.quantity.maxDescription"
                            | translate
                                : {
                                      maxVolumeLimit:
                                          maxVolumeLimit?.value?.value || "-"
                                  }
                    }}
                </span>
            </ez-description>
            <ez-description
                *ngIf="maxVolumeLimit?.enabled && !maxVolumeLimit?.value"
            >
                <span class="error">
                    {{
                        "autotrading.market.form.order.quantity.noSerieValueError"
                            | translate
                    }}
                </span>
            </ez-description>
            <ez-message-error [validator]="'max'" [message]="' '">
                <!-- suppress the error message -->
            </ez-message-error>
        </ez-form-field>
        <span class="at">&#64;</span>
        <ez-form-field #priceField>
            <ez-label>{{
                "autotrading.market.form.order.price.label" | translate
            }}</ez-label>
            <ez-physical-value
                [name]="'price-field'"
                [(ngModel)]="orderEntry.price"
                [required]="true"
                [units]="energyPriceUnits"
                [min]="minPriceLimit?.value?.value"
                [max]="maxPriceLimit?.value?.value"
                [hasCustomError]="
                    (minPriceLimit?.enabled && !minPriceLimit?.value) ||
                    (maxPriceLimit?.enabled && !maxPriceLimit?.value)
                "
                (valueChange)="onInput()"
            >
            </ez-physical-value>
            <ez-description
                *ngIf="
                    minPriceLimit?.enabled &&
                    minPriceLimit?.value &&
                    maxPriceLimit?.enabled &&
                    maxPriceLimit?.value
                "
            >
                <span
                    [class.error]="
                        priceField.isTouched &&
                        orderEntry?.price?.value < minPriceLimit.value.value
                    "
                >
                    {{
                        "autotrading.market.form.order.price.minDescription"
                            | translate
                                : {
                                      minPriceLimit:
                                          minPriceLimit?.value?.value || "-"
                                  }
                    }}
                </span>
                <span> / </span>
                <span
                    [class.error]="
                        orderEntry?.price?.value > maxPriceLimit.value.value
                    "
                >
                    {{
                        "autotrading.market.form.order.price.maxDescription"
                            | translate
                                : {
                                      maxPriceLimit:
                                          maxPriceLimit?.value?.value || "-"
                                  }
                    }}
                </span>
            </ez-description>
            <ez-description
                *ngIf="
                    (minPriceLimit?.enabled && !minPriceLimit?.value) ||
                    (maxPriceLimit?.enabled && !maxPriceLimit?.value)
                "
            >
                <span class="error">
                    {{
                        "autotrading.market.form.order.price.noSerieValueError"
                            | translate
                    }}
                </span>
            </ez-description>
            <ez-message-error [validator]="'min'" [message]="' '">
                <!-- suppress the error message -->
            </ez-message-error>
            <ez-message-error [validator]="'max'" [message]="' '">
                <!-- suppress the error message -->
            </ez-message-error>
        </ez-form-field>
    </div>
    <ez-form-group>
        <ez-label>
            <ez-button-group
                [value]="restriction"
                (valueChange)="onRestrictionChange($event)"
                class="btn-restrictions"
            >
                <ez-button
                    *ngFor="let res of resType | enumStringToIterable"
                    [value]="res['value']"
                    [label]="res['key']"
                    [disabled]="privateOrder"
                >
                </ez-button>
            </ez-button-group>
        </ez-label>

        <div class="group-flex iceberg-grp">
            <ez-form-field>
                <ez-label>{{
                    "autotrading.market.form.order.peak" | translate
                }}</ez-label>
                <ez-physical-value
                    [(ngModel)]="orderEntry.visibleQuantity"
                    [required]="true"
                    [disabled]="!hasIceberg"
                    [units]="energyUnits"
                    [max]="orderEntry.quantity"
                ></ez-physical-value>
            </ez-form-field>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.market.form.order.delta" | translate
                }}</ez-label>
                <ez-physical-value
                    [(ngModel)]="orderEntry.priceOffset"
                    [min]="
                        orderEntry.direction == directions.Sell ? 0 : undefined
                    "
                    [max]="
                        orderEntry.direction == directions.Buy ? 0 : undefined
                    "
                    [required]="true"
                    [disabled]="!hasIceberg"
                    [units]="energyPriceUnits"
                ></ez-physical-value>
            </ez-form-field>
        </div>
        <div class="group-flex val-res-group flex-baseline">
            <ez-button-group
                [value]="validityRestriction"
                (valueChange)="onValidityResChange($event)"
            >
                <ez-button
                    *ngFor="let res of validityType | enumStringToIterable"
                    [value]="res['value']"
                    [label]="res['key']"
                    [disabled]="!hasTimeRestriction"
                ></ez-button>
            </ez-button-group>
            <ez-form-field class="field-inline">
                <ez-datepicker
                    [disabled]="
                        validityRestriction !== validityType.GTD ||
                        !hasTimeRestriction
                    "
                    [withTime]="true"
                    [ngModel]="validityTime"
                    (valueChange)="onValidityDateChanged($event)"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [required]="true"
                >
                </ez-datepicker>
            </ez-form-field>
        </div>
    </ez-form-group>
    <ez-form-field>
        <ez-label>{{
            "autotrading.session.parameters.strategies.comment" | translate
        }}</ez-label>
        <ez-input-text
            name="comment"
            [(ngModel)]="orderEntry.comment"
            [type]="'textarea'"
            [placeholder]="'Comment'"
        >
        </ez-input-text>
    </ez-form-field>
    <ez-footer>
        <div class="flex-footer">
            <ez-button
                *ngIf="internalIdOrder"
                [severity]="'danger'"
                (onClick)="onCancelOrder()"
                [label]="
                    'autotrading.market.form.order.cancelOrder' | translate
                "
            >
            </ez-button>

            <div *ngIf="footerMessage !== 'none'" 
                [class.unfilled-err]="footerMessage === 'error'" 
                [class.unfilled-warn]="footerMessage === 'warning'">
                <ez-icon [name]="'exclamation'"></ez-icon>
                <span>
                    {{
                        'autotrading.market.form.order.warningPrefix' | translate 
                    }}
                </span>
                <span *ngIf="overrideOrderLimits">
                    {{'autotrading.market.form.order.manualLimit' | translate }}
                </span>
                <span *ngIf="overrideOrderLimits && (hasOmtRestriction || hasOmtWarning)" >&</span>
                <span *ngIf="hasOmtRestriction">
                    {{'autotrading.market.form.order.omtRestricted' | translate }}
                </span>
                <span *ngIf="hasOmtWarning">
                    {{
                        'autotrading.market.form.order.omtWarning'
                        | translate
                    }}
                </span>
            </div>

            <ez-button
                class="pull-right"
                [label]="'general.cancel' | translate"
                ez-form-cancel
            ></ez-button>
            <ez-button
                *ngIf="!overrideOrderLimits"
                [label]="
                    (privateOrder ? 'general.modify' : 'general.send')
                        | translate
                "
                ez-form-submit
                [disabled]="privateOrder && disabledBtnModify"
            >
            </ez-button>
            <ez-button
                *ngIf="overrideOrderLimits"
                [label]="'general.force' | translate"
                [severity]="'danger'"
                ez-form-submit
            >
            </ez-button>
        </div>
    </ez-footer>
</ez-form>
<ez-locker [activated]="isOrderCanceled">Canceled order...</ez-locker>
