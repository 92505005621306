<div *ngFor="let item of keysAndValues">
    <div *ngIf="(item.value === true) || (item.value === false); else elseBlock">
        <ez-form-field>
            <ez-checkbox [ngModel]="item.value" (checkedChange)="checkboxClick(item, $event)">
                {{item.keyLabel}}
            </ez-checkbox>
        </ez-form-field>
    </div>

    <ng-template #elseBlock>
        <ez-form-field>
            <ez-select [autocomplete]="true" [placeholder]="item.keyLabel" [ngModel]="item.value" [required]="true"
                (valueChange)="selectedValue(item, $event)">
                <ez-select-option *ngFor="let possibleValue of item.possibleValues" [value]="possibleValue">
                    {{possibleValue}}
                </ez-select-option>
            </ez-select>
        </ez-form-field>
    </ng-template>
</div>
