// @ts-nocheck
import { Injectable } from "@angular/core";
import { Feature, FeatureState } from '@services/app-feature.service';
import { OpenIdConfiguration } from "angular-auth-oidc-client";

export type UIVersion = 'new' | 'old';

export interface IVersionInfo {
    readonly version: UIVersion;
    readonly defaultVersion: UIVersion

    readonly versionUrls: Record<UIVersion, string>;
}

export interface IFeatureSwitches extends Record<Feature, FeatureState> {

}

export interface IEnvironment {
    readonly name: string;
    readonly color: string;
    readonly displayName: boolean;
}

export interface ILoggingConfig {
    readonly level: string;
    readonly console: boolean;
    readonly api: boolean;
    readonly alert: boolean;
}

export interface IApiConfig {
    readonly uri: string;
    readonly uriSecure: string;
    readonly displayUri: boolean;
}

export interface ICdnConfig {
    readonly uri: string;
    readonly uriSecure: string;
}

export interface IAppConfig {
    readonly features?: IFeatureSwitches;
    readonly versionInfo: IVersionInfo;
    readonly environment: IEnvironment;
    readonly logging: ILoggingConfig;
    readonly api: IApiConfig;
    readonly cdn: ICdnConfig;
    readonly auth: OpenIdConfiguration;
}

@Injectable({ providedIn: "root" })
export class AppConfig {

    get versionInfo(): IVersionInfo | undefined {
        return this._settings?.versionInfo;
    }

    get features() : IFeatureSwitches | undefined {
        return this._settings.features;
    }

    get environment(): IEnvironment {
        if (!this._settings) return undefined;
        return this._settings.environment;
    }

    get logging(): ILoggingConfig {
        if (!this._settings) return undefined;
        return this._settings.logging;
    }

    get displayApiUri(): boolean {
        if (!this._settings || !this._settings.api) return undefined;
        return this._settings.api.displayUri;
    }

    get apiUri(): string {
        return this._apiUri;
    }
    private _apiUri: string;

    get cdnUri(): string {
        return this._cdnUri;
    }
    private _cdnUri: string;

    get authConfig(): OpenIdConfiguration {
        return this._authConfig;
    }
    private _authConfig: OpenIdConfiguration;

    private _settings: IAppConfig;

    get isLoaded(): boolean {
        return this._isLoaded;
    }
    private _isLoaded: boolean;

    constructor() {
    }

    load(): Promise<void> {
        if (this._isLoaded) {
            return Promise.resolve();
        } else {
            const jsonFile = `config.json?t=${Date.now()}`;
            return new Promise<void>((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', jsonFile);
                xhr.addEventListener('readystatechange', () => {
                    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                        this._settings = <IAppConfig>JSON.parse(xhr.responseText);
                        if (this._settings) {
                            this._apiUri = window.isSecureContext && this._settings.api.uriSecure ? this._settings.api.uriSecure : this._settings.api.uri;
                            this._cdnUri = window.isSecureContext && this._settings.cdn.uriSecure ? this._settings.cdn.uriSecure : this._settings.cdn.uri;
                            this._authConfig = this._settings.auth;
                            this._isLoaded = true;
                        }
                        resolve();
                    } else if (xhr.readyState === XMLHttpRequest.DONE) {
                        reject(new Error(`Could not load file '${jsonFile}': ${JSON.stringify(xhr.response)}`));
                    }
                });
                xhr.send(null);
            });
        }
    }
}
