import { EzAccordionSectionComponent } from '@eznergy/components';
import { timer, Subscription } from 'rxjs';

export class SectionAutoScroll {

    private _section: EzAccordionSectionComponent | undefined;
    private _subDelay: Subscription | undefined;

    setSection(section: EzAccordionSectionComponent): void {
        this._clearSubDelay();
        this._section = section;
        this._refreshScroll();
    }

    clearSection(delay: number = 300): void {
        this._clearSubDelay();
        this._refreshScroll();
        this._subDelay = timer(delay).subscribe(() => {
            this._refreshScroll();
            this.destroy();
        });
    }

    destroy(): void {
        this._clearSubDelay();
        this._section = undefined;
    }

    private _refreshScroll(): void {
        if (this._section != null && this._section.element != null) {
            this._section.element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }

    private _clearSubDelay(): void {
        if (this._subDelay != null) {
            this._subDelay.unsubscribe();
            this._subDelay = undefined;
        }
    }
}
