import { Logger } from "@core/logger";
import { Injectable } from '@angular/core';
import { AppUser } from '@core/app-user.model';
import { DayAlignType, TypeDisplayMenu } from '@core/application.model';
import { AppPreferences } from '@core/preferences/app-preferences.model';
import { AppContractPreferences } from '@core/preferences/contract-preferences.model';
import { OptionsResetPreferencePage } from '@core/preferences/core.model';
import { AppShipperPreferences } from '@core/preferences/shipper-preferences.model';
import { I18nService } from '@eznergy/components';
import { isSame } from '@eznergy/core';
import { ApiService } from '@eznergy/webapi';
import { DateRange, DateTime, IDateRange, TimeUnit, TimeZone } from '@eztypes/generic';
import {
    AuctionAccount,
    GridTypeCode,
    IAuctionAccount,
    IAuctionDeliveryArea,
    IContract,
    IGrid,
    IMarketConfiguration,
    IOrganisation,
    IUser,
    UserRight,
} from '@eztypes/webapi';
import { ExchangeUserView, ExchangeView } from 'src/app/templates/header/header.component';
import Decimal from 'decimal.js-light';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';
import { ShipperPagesPreference } from "@core/preferences/pages/shipper-page-preferences.model";
import { IContractUserRights } from "@models/core/contract-user-rights.model";
import { UserConnectedInfosService } from '@eznergy/webapi';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
    get user(): AppUser | undefined {
        return this._subjectUser.value;
    }
    get userChange(): Observable<AppUser | undefined> {
        return this._subjectUser.asObservable();
    }
    private readonly _subjectUser: BehaviorSubject<AppUser | undefined> = new BehaviorSubject<AppUser | undefined>(undefined);

    get contract(): IContract | undefined {
        return this._subjectContract.value != null ? this._subjectContract.value.contract : undefined;
    }
    get rights(): UserRight[] | undefined {
        return this._subjectContract.value != null ? this._subjectContract.value.rights : undefined;
    }
    get contractChange(): Observable<IContractUserRights | undefined> {
        return this._subjectContract.asObservable();
    }
    private readonly _subjectContract: BehaviorSubject<IContractUserRights | undefined> = new BehaviorSubject<IContractUserRights | undefined>(undefined);

    get shippers(): IOrganisation[] | undefined {
        return this._subShippers.value?.filter((grid) => grid.id !== 0);
    }
    get shippersChanges(): Observable<IOrganisation[] | undefined> {
        return this._subShippers.pipe(map((grids) => grids?.filter((grid) => grid.id !== 0)));
    }
    private readonly _subShippers: BehaviorSubject<IOrganisation[] | undefined> = new BehaviorSubject<IOrganisation[] | undefined>(undefined);

    get shipper(): IOrganisation | undefined {
        return this._subjectShipper.value;
    }
    get shipperChange(): Observable<IOrganisation | undefined> {
        return this._subjectShipper.asObservable();
    }
    private readonly _subjectShipper: BehaviorSubject<IOrganisation | undefined> = new BehaviorSubject<IOrganisation | undefined>(undefined);

    get isMultiShipper(): boolean {
        return this._subjectShipper.value?.id === 0;
    }

    get menuType(): TypeDisplayMenu {
        return this._subjectMenuType.value;
    }
    get menuTypeChange(): Observable<TypeDisplayMenu> {
        return this._subjectMenuType.asObservable();
    }
    private readonly _subjectMenuType: BehaviorSubject<TypeDisplayMenu> = new BehaviorSubject<TypeDisplayMenu>(TypeDisplayMenu.Normal);

    get grids(): Observable<IGrid[] | undefined> {
        return this._subjectGrids.asObservable();
    }
    private readonly _subjectGrids: BehaviorSubject<IGrid[] | undefined> = new BehaviorSubject<IGrid[] | undefined>(undefined);

    get exchangesAuction(): Observable<ExchangeView[] | undefined> {
        return this._subjectExchangesAuction.asObservable();
    }
    private readonly _subjectExchangesAuction: BehaviorSubject<ExchangeView[] | undefined> = new BehaviorSubject<ExchangeView[] | undefined>(undefined);

    get accountsAuction(): BehaviorSubject<AuctionAccount[] | undefined> {
        return this._subjectAccountsAuction;
    }
    private readonly _subjectAccountsAuction: BehaviorSubject<AuctionAccount[] | undefined> = new BehaviorSubject<AuctionAccount[] | undefined>(undefined);

    get gridAreas(): BehaviorSubject<Map<IAuctionDeliveryArea, IGrid> | undefined> {
        return this._subjectGridAreas;
    }
    private readonly _subjectGridAreas: BehaviorSubject<Map<IAuctionDeliveryArea, IGrid> | undefined> =
        new BehaviorSubject<Map<IAuctionDeliveryArea, IGrid> | undefined>(undefined);

    get gridsNomination(): Observable<IGrid[] | undefined> {
        return this._subjectGridsNom.asObservable();
    }
    private readonly _subjectGridsNom: ReplaySubject<IGrid[] | undefined> = new ReplaySubject(1);

    get gridsAssetNomination(): Observable<IGrid[] | undefined> {
        return this._subjectGridsAssetNom.asObservable();
    }
    private readonly _subjectGridsAssetNom: ReplaySubject<IGrid[] | undefined> = new ReplaySubject(1);

    get gridsBalancing(): Observable<IGrid[] | undefined> {
        return this._subjectGridsBal;
    }
    private readonly _subjectGridsBal: ReplaySubject<IGrid[] | undefined> = new ReplaySubject(1);

    set allGridsBalancing(value: IGrid[]) {
        this._allGridsBalancing = value;
    }
    private _allGridsBalancing: IGrid[] = [];

    get gridsMarket(): Observable<IGrid[] | undefined> {
        return this._subjectGridsMar;
    }
    private readonly _subjectGridsMar: ReplaySubject<IGrid[] | undefined> = new ReplaySubject(1);

    get gridsAuction(): Observable<IGrid[]> {
        return this._subjectGridsAuction;
    }
    private readonly _subjectGridsAuction: ReplaySubject<IGrid[]> = new ReplaySubject(1);

    get gridsContinuous(): Observable<IGrid[]> {
        return this._subjectGridsContinuous;
    }
    private readonly _subjectGridsContinuous: ReplaySubject<IGrid[]> = new ReplaySubject(1);

    get continuousUsers(): Observable<ExchangeUserView[] | undefined> {
        return this._subjectContinuousUsers.asObservable();
    }
    private readonly _subjectContinuousUsers: BehaviorSubject<ExchangeUserView[] | undefined> = new BehaviorSubject<ExchangeUserView[] | undefined>(undefined);

    get marketConfiguration(): Observable<IMarketConfiguration> {
        return this._subMarketConfiguration;
    }
    private readonly _subMarketConfiguration: ReplaySubject<IMarketConfiguration> = new ReplaySubject(1);

    get date(): DateTime | undefined {
        return this._subjectDate.value;
    }
    get dateChange(): Observable<DateTime | undefined> {
        return this._subjectDate.asObservable();
    }
    private readonly _subjectDate: BehaviorSubject<DateTime | undefined> = new BehaviorSubject<DateTime | undefined>(undefined);

    get hasDisplayHelpChange(): Observable<boolean> {
        return this._subjectDisplayHelp.asObservable();
    }
    private readonly _subjectDisplayHelp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get hasDisplayGridCtlChange(): Observable<boolean> {
        return this._subjectHasDisplayGridCtl.asObservable();
    }
    private readonly _subjectHasDisplayGridCtl: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    get preferences(): AppPreferences | undefined {
        return this.user?.preferences;
    }

    get appContractPreferences(): AppContractPreferences | undefined {
        return this._appContractPreferences;
    }
    private _appContractPreferences: AppContractPreferences | undefined;

    get shipperPreferences(): AppShipperPreferences | undefined {
        if (this._appShipperPreferences) {
            return this._appShipperPreferences;
        }

        const shipperId = this.shipper?.id ?? this._appContractPreferences?.shipperId;

        if (!shipperId) {
            return undefined;
        }

        let shipperPreferences = this._appContractPreferences?.shippers.find(
            (shipperPreferences) => +shipperPreferences.shipperId === +shipperId
        );

        if (!shipperPreferences && this._appContractPreferences) {
            shipperPreferences = new AppShipperPreferences(shipperId);
            this._appContractPreferences.shippers = [...this._appContractPreferences.shippers, shipperPreferences];
        }

        return this._appShipperPreferences = shipperPreferences;
    }
    private _appShipperPreferences: AppShipperPreferences | undefined;

    get pages$(): Observable<ShipperPagesPreference> {
        return this._subjectShipperPrefChange;
    }
    private _subjectShipperPrefChange: Observable<ShipperPagesPreference>;

    get timezone(): TimeZone {
        return this._subjectTzChange.value;
    }
    get timezoneChange(): Observable<TimeZone> {
        return this._subjectTzChange.asObservable();
    }
    private _subjectTzChange: BehaviorSubject<TimeZone> = new BehaviorSubject(TimeZone.local());

    constructor(
        private readonly _api: ApiService,
        private readonly _i18nSvc: I18nService,
        private readonly _logger: Logger,
        private readonly _userConnectedSvc: UserConnectedInfosService
    ) {
        TimeZone.currentChange.subscribe((tz) => {
            this._subjectTzChange.next(tz);
        });
        this._subjectShipperPrefChange = this._setupShipperPrefChange();
    }

    public setAllShippers(value: IOrganisation[] | undefined): void {
        this._subShippers.next(value);
    }

    private _setupShipperPrefChange(): Observable<ShipperPagesPreference> {
        return this._subjectShipper.pipe(
            filter((shipper: IOrganisation | undefined) => !!shipper),
            map((shipper: IOrganisation | undefined) => {
                const shipperId: number = shipper?.id ?? this._appContractPreferences!.shipperId;
                let preferences = this._appContractPreferences!.shippers.find(
                    (s) => +s?.shipperId === +shipperId
                );
                if (!preferences) {
                    preferences = new AppShipperPreferences(shipperId);
                    this._appContractPreferences!.shippers.push(preferences);
                    this._appContractPreferences!.shippers = this._appContractPreferences!.shippers.slice();
                }
                this._appShipperPreferences = preferences;
                return this._appShipperPreferences.pages;
            })
        );
    }

    hasRight(value: UserRight): boolean {
        if (this.rights == null) {
            return false;
        }
        return this.rights.includes(value);
    }

    getDateRange(days: number = 1, opts: { position?: DayAlignType; withTime?: boolean } = {}): IDateRange | undefined {
        if (this.date == null) {
            return undefined;
        }
        let subDays: number = 0;
        let addDays: number = 0;
        switch (opts.position) {
            case DayAlignType.Start:
                addDays = days - 1;
                break;
            default:
                addDays = new Decimal(days).div(2).toDecimalPlaces(0, Decimal.ROUND_DOWN).toNumber();
                subDays = new Decimal(days).minus(1).minus(addDays).toNumber();
                break;
            case DayAlignType.End:
                subDays = days - 1;
                break;
        }
        let startDate = this.date.addDays(-subDays);
        let endDate = this.date.addDays(addDays);
        if (opts.withTime) {
            startDate = startDate.startOf(TimeUnit.Days);
            endDate = endDate.endOf(TimeUnit.Days);
        }
        return new DateRange(startDate, endDate);
    }

    setGrids(grids: IGrid[] | undefined): void {
        this._resetPreferences({ byGrid: true });
        this._subjectGrids.next(grids);
        this._subjectGridsBal.next(
            grids == null ? grids : grids.filter((g) => (g.gridType.code === GridTypeCode.codeBalancing && this._allGridsBalancing.some((grid) => grid.id === g.id)))
        );
        this._subjectGridsNom.next(
            grids == null ? grids : grids.filter((g) => g.gridType.code === GridTypeCode.codeNomination)
        );
        this._subjectGridsAssetNom.next(
            grids == null ? grids : grids.filter((g) => g.gridType.code === GridTypeCode.codeProdNomination)
        );
        this._subjectGridsMar.next(grids);
    }

    setMarketConfiguration(marketConfiguration: IMarketConfiguration): void {
        this._subMarketConfiguration.next(marketConfiguration);
    }

    setAuctionGrids(gridsAuction: IGrid[]): void {
        this._subjectGridsAuction.next(gridsAuction);
    }

    setContinuousGrids(gridsContinuous: IGrid[]): void {
        this._subjectGridsContinuous.next(gridsContinuous);
    }

    setContinuousUsers(continuousUsers: ExchangeUserView[] | undefined): void {
        this._subjectContinuousUsers.next(continuousUsers);
    }

    setAuctionContinuousGrids(grids: IGrid[]): void {
        this._subjectGridsMar.next(grids);
    }

    setExchanges(exchanges: ExchangeView[]): void {
        this._subjectExchangesAuction.next(exchanges);
    }

    setAccountsAuction(accounts: IAuctionAccount[]): void {
        this._subjectAccountsAuction.next(accounts);
    }

    setMenuType(type: TypeDisplayMenu): void {
        if (type !== this.menuType) {
            this._subjectMenuType.next(type);
            if (this.preferences) {
                this.preferences.menuDisplay = this.menuType;
            }
        }
    }

    setDate(date: DateTime): void {
        // @ts-ignore
        if (date != null && !date.equals(this.date)) {
            this._resetPreferences({ byDate: true });
            this._subjectDate.next(date);
        }
    }

    setUser(user?: IUser, contracts?: IContract[]): void {
        if (user == null) {
            this._subjectUser.next(undefined);
            return;
        }
        if (contracts == null) {
            return;
        }
        const appUser = new AppUser(user, contracts);
        appUser.preferencesChange.pipe(skip(1)).subscribe(() => {
            this._savePreferences();
        });
        this._subjectUser.next(appUser);
        this._subjectHasDisplayGridCtl.next(this.preferences?.displayGridSelector ?? true);
        this.setMenuType(appUser.preferences.menuDisplay);
        this._i18nSvc.setHourCycle(appUser.preferences.hourCycle ?? 'local');
        this.refreshTimeZone();
    }

    setContract(contract: IContract, rights: UserRight[]): void {
        if (!isSame(contract, this.contract)) {
            // @ts-ignore
            this._logger.setUserInformation(this._subjectUser.value?.username, contract);
            this._appContractPreferences = undefined;
            if (contract != null) {
                this._appContractPreferences = this.preferences?.contracts.find(
                    (c) => c.contractGuid === contract.contractGuid
                );
                if (this._appContractPreferences == null) {
                    this._appContractPreferences = new AppContractPreferences(contract.contractGuid);
                    if (this.preferences) {
                        this.preferences.contracts.push(this._appContractPreferences);
                        this.preferences.contracts = this.preferences.contracts.slice();
                    }
                }
                if (this.preferences) {
                    this.preferences.contract = contract.contractGuid;
                }
            } else {
                this.setShipper(undefined);
            }
            this._subjectContract.next({ contract, rights });
        }
    }

    setShipper(shipper: IOrganisation | undefined): void {
        if (!isSame(shipper, this.shipper)) {
            this._appShipperPreferences = undefined;
            if (shipper != null) {
                this.setShipperIdPreference(shipper.id);
            }
            this.setGrids(undefined);
            this._subjectShipper.next(shipper);
        }
    }

    setShipperIdPreference(shipperId: number | undefined): void {
        if (this._appContractPreferences && shipperId) {
            this._appContractPreferences.shipperId = shipperId;
        }
    }

    displayHelp(value: boolean): void {
        this._subjectDisplayHelp.next(value);
    }

    displayGridCtl(value: boolean): void {
        this._subjectHasDisplayGridCtl.next(value);
        if (this.preferences) {
            this.preferences.displayGridSelector = this._subjectHasDisplayGridCtl.value;
        }
    }

    refreshTimeZone(): void {
        let timezone: TimeZone = TimeZone.local();
        if (this.preferences?.tzActivate && this.preferences.timezone != null) {
            timezone = TimeZone.create(this.preferences.timezone);
            if (timezone?.isValid !== true) {
                timezone = TimeZone.local();
                this.preferences.timezone = timezone.name;
            }
        }
        if (timezone?.equals(TimeZone.current()) === false) {
            TimeZone.setCurrent(timezone);
        }
    }

    hardResetPreferences(): void {
        if (this.preferences == null) {
            return;
        }

        this.preferences.reset();
    }

    private _savePreferences(): void {
        if (this.user?.preferences != null) {
            const wrapperPreferences = this.user.createWrapperPreferences();
            this._userConnectedSvc.preferences = wrapperPreferences;
            this._api.auth.setAppPreference(wrapperPreferences).subscribe();
        }
    }

    private _resetPreferences(opts: OptionsResetPreferencePage = {}): void {
        if (this.preferences == null) {
            return;
        }

        this.preferences.pages.reset(opts);
    }
}
