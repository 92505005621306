<screen-panel position="left">
    <parameters-ets [isMarketResultsPanelActive]="marketResultsPanelActive"
        (parametersChange)="onParametersChange($event)">
    </parameters-ets>
</screen-panel>

<first-content>
    <ez-tabs [value]='selectedTab' (valueChange)="onSelectTabChange($event)">
        <ez-tab-section [value]="'linear'" [active]="!marketResultsPanelActive" [disabled]="!hasRightReadOrderLinear">
            <ez-tab-title> {{ 'balancing.ets.tabs.linear' | translate }}</ez-tab-title>
            <ez-content>
                <ets-order-linear-panel *ngIf="!marketResultsPanelActive" [marketDataResult]="marketResultData"
                    [profile]="profileSelected" [grid]="gridSelected" [area]="areaSelected"
                    [portfolio]="portfolioSelected" [deliveryDate]="deliveryDate" (refresh)="onRefreshClick()">
                </ets-order-linear-panel>
            </ez-content>
        </ez-tab-section>
        <ez-tab-section [value]="'result'" [active]="marketResultsPanelActive" [disabled]="!marketResultData">
            <ez-tab-title> {{ 'balancing.ets.tabs.globals' | translate }}</ez-tab-title>
            <ez-content>
                <ets-market-result *ngIf="marketResultsPanelActive" [marketData]="marketResultData"
                    [profile]="profileSelected" [grid]="gridSelected" [area]="areaSelected"
                    [portfolio]="portfolioSelected" [deliveryDate]="deliveryDate">
                </ets-market-result>
            </ez-content>
        </ez-tab-section>
    </ez-tabs>
</first-content>
