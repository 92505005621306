<div class="title">
    {{value?.name}}
</div>
<div class="row">
    <span class="label">{{'general.id' | translate}} : </span>
    <div class="value">
        <div class="value-clipboard" (click)="copyIdToClipboard()" [ezTooltip]="'general.clipboard-copy' | translate">
            <span>{{value?.id}}</span>
            <ez-icon name="copy" [regular]="true"></ez-icon>
        </div>
    </div>
</div>
<div class="row country">
    <span class="label">{{'ob.grid.country' | translate}} : </span>
    <span class="value">
        {{value?.country?.name | translate}}
        <img [src]="value?.country?.logo?.icon | StaticCountryUri:32" />
    </span>
</div>
<div class="row">
    <span class="label">{{'ob.grid.grid-type' | translate}} : </span>
    <span class="value">
        {{"enum.gridType." + (value?.gridType?.code) | translate}}
    </span>
</div>
<div class="row material">
    <span class="label">{{'ob.grid.commodity' | translate}} : </span>
    <span class="value">
        {{('enum.material.' + value?.material.code) | translate}}
        <commodity-icon [value]="value?.material?.code"></commodity-icon>
    </span>
</div>
<div class="row">
    <span class="label">{{'ob.grid.timeGranularity' | translate}} : </span>
    <span class="value">
        {{('enum.timeGranularity.' + value?.timeGranularity ) | translate}}
    </span>
</div>
