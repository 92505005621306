// @ts-nocheck
import {
    AtDirection,
    ExeRestriction, IAtContract, IAtOrder,
    IOrderEntry, OrderEntry,
    OrderModEntry,
    IOrderModEntry,
    IPhysicalValue, IPublicOrder, OrderState, OrderType, PhysicalValue, PublicOrder,
    ValidityRestriction,
} from '@eztypes/webapi';
import { DateTime } from '@eztypes/generic';

export enum RestrictionType {
    Reg = "regular",
    Fok = "fillOrKill",
    Ioc = "immediateOrCancel",
    Icb = "iceberg",
}

export enum ValidityType {
    GFS = "goodForSession",
    GTD = "goodUntilDate",
}

export class OrderView implements IOrderEntry, IOrderModEntry {
    id: string;
    comment: string;
    deliveryStart: DateTime;
    direction: AtDirection;
    exeRestriction: ExeRestriction;
    quantity: IPhysicalValue;
    price: IPhysicalValue;
    isActive: boolean;
    validityRestriction: ValidityRestriction;
    validityTime: DateTime;
    type: OrderType;
    visibleQuantity: IPhysicalValue;
    priceOffset: IPhysicalValue;
    contract: IAtContract;

    constructor(order: IAtOrder | IPublicOrder) {
        this.id = (order && order.id) || undefined;
        this.quantity =
            order instanceof PublicOrder
                ? new PhysicalValue(null, order?.quantity)
                : (order as IAtOrder)?.quantity;
        this.price =
            order instanceof PublicOrder
                ? new PhysicalValue(null, order?.price)
                : (order as IAtOrder)?.price;
        this.comment = (order && (<IAtOrder>order).comment) || undefined;
        this.direction =
            (order && (<IAtOrder>order).direction) || AtDirection.Buy;
        this.exeRestriction =
            (order && (<IAtOrder>order).exeRestriction) || ExeRestriction.None;
        this.isActive =
            order && (<IAtOrder>order).state
                ? (<IAtOrder>order).state === OrderState.Active
                : true;
        this.validityRestriction =
            (order && (<IAtOrder>order).validityRestriction) ||
            ValidityRestriction.GoodForSession;
        this.validityTime =
            (order && (<IAtOrder>order).validityTime) || undefined;
        this.type = (order && (<IAtOrder>order).type) || OrderType.Regular;
        this.visibleQuantity =
            (order && (<IAtOrder>order).visibleQuantity) || undefined;
        this.priceOffset =
            (order && (<IAtOrder>order).priceOffset) || undefined;
        this.contract =
            order && (<IAtOrder>order).contract
                ? (<IAtOrder>order).contract
                : undefined;
    }
    get OrderEntry(): IOrderEntry {
        return new OrderEntry(
            this.contract ? this.contract.deliveryStart : undefined,
            this.quantity,
            this.price,
            this.direction,
            this.comment,
            this.exeRestriction,
            this.validityRestriction,
            this.validityTime,
            this.type,
            this.visibleQuantity,
            this.priceOffset,
            this.isActive
        );
    }

    get OrderModEntry(): IOrderModEntry {
        return new OrderModEntry(
            this.id,
            this.comment,
            this.quantity,
            this.price,
            this.exeRestriction,
            this.isActive,
            this.validityRestriction,
            this.validityRestriction === ValidityRestriction.GoodUntilDate
                ? this.validityTime
                : undefined,
            this.visibleQuantity || undefined,
            this.priceOffset || undefined
        );
    }
}
