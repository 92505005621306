// @ts-nocheck
import { Pipe, PipeTransform } from "@angular/core";
import { ITimespan } from "@eztypes/generic";

@Pipe({
    name: "humanizeTimespan",
})
export class HumanizeTimespanPipe implements PipeTransform {

    public static defaultResult = '--';

    transform(value: ITimespan): string {
        if (!value) {
            return HumanizeTimespanPipe.defaultResult;
        }

        if (value.totalDays >= 2) {
            return `${value.totalDays} d`;
        }

        if (value.totalHours >= 2) {
            return `${value.totalHours} h`;
        }

        if (value.totalMinutes >= 2) {
            return `${value.totalMinutes} m`;
        }

        return `${value.totalSeconds} s`
    }
}
