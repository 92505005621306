// @ts-nocheck
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Direction } from '../transport-direction/transport-direction.icon';

@Component({
    selector: 'transport-gaz-exit-icon',
    templateUrl: './transport-gaz-exit.icon.html',
    styleUrls: ['./transport-gaz-exit.icon.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportGazExitIcon {
    Direction = Direction;

    constructor() { }
}
