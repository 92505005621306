import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, UrlTree } from "@angular/router";
import { ApplicationService } from "@services/application.service";
import { AppPages, RouterService } from "@services/router.service";
import { Observable } from "rxjs";

export const RightsRouterService: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  => {
    const appSvc = inject(ApplicationService); 
    const router = inject(RouterService); 
    if (route.data['rightScreen '] == null) {
        return true;
    }
    return appSvc.hasRight(route.data['rightScreen ']) ? true : router.getUrlTree(AppPages.Dashboard);
}