// @ts-nocheck
import { Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Optional, Output, Renderer2, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscriptions } from '@core';
import { UnitFamiliesUmScore } from '@enums/unit-families-umscore.enum';
import { EzFormControl, EzFormControlPlaceholder, EzSelectComponent, HorizontalPosition, IEzFormControl, PositionAlignInternal, VerticalPosition } from "@eznergy/components";
import * as _ez from '@eznergy/core';
import { ApiService } from '@eznergy/webapi';
import { CurveType, ICurveBase, IGroup, IUnitFamily } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import * as _ from "lodash";
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { GroupSelection, GroupSelectionStatus } from '../groups/groups-select.component';

@Component({
    selector: "ez-select-ts",
    templateUrl: "curves-select.component.html",
    styleUrls: ['curves-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: EzFormControl, useExisting: CurvesSelectComponent }
    ],
    host: {
        '[class.display-filters]': 'hasFocus || value != null'
    }
})
export class CurvesSelectComponent extends EzFormControlPlaceholder implements IEzFormControl, OnDestroy {
    @ViewChild(EzSelectComponent, { static: true }) private _select: EzSelectComponent<ICurveBase>;

    @Output() readonly valueChange = new EventEmitter<ICurveBase | ICurveBase[]>();

    @Input()
    get value(): ICurveBase | ICurveBase[] {
        return this._value;
    }
    set value(value: ICurveBase | ICurveBase[]) {
        if (!_ez.isSame(this.value, value)) {
            this._value = value;
            this._addValueInTsList();
            this._cdr.markForCheck();
        }
    }
    private _value: ICurveBase | ICurveBase[];

    @Input()
    get sizeElement(): number {
        return this._sizeElement;
    }
    set sizeElement(value: number) {
        const newValue = value ? value : 100;
        if (newValue !== this.sizeElement) {
            this._sizeElement = value;
            this._cdr.markForCheck();
        }
    }
    private _sizeElement: number = 100;

    @Input()
    get type(): CurveType {
        return this._type;
    }
    set type(value: CurveType) {
        if (value !== this.type) {
            this._type = value;
            this._cdr.markForCheck();
        }
    }
    private _type: CurveType;

    @Input()
    get multiple(): boolean {
        return this._multiple;
    }
    set multiple(val: boolean) {
        const newValue = _ez.coerceBoolean(val, false);
        if (newValue !== this.multiple) {
            this._multiple = newValue;
            this._cdr.markForCheck();
        }
    }
    private _multiple: boolean = false;

    @Input()
    get familiesUmScore(): UnitFamiliesUmScore {
        return this._familiesUmScore;
    }
    set familiesUmScore(value: UnitFamiliesUmScore) {
        if (value !== this.familiesUmScore) {
            this._familiesUmScore = value;
            this._cdr.markForCheck();
        }
    }
    private _familiesUmScore: UnitFamiliesUmScore;


    @Input()
    get unitFamily(): IUnitFamily {
        return this._unitFamily;
    }
    set unitFamily(value: IUnitFamily) {
        if (this.unitFamily !== value) {
            this._unitFamily = value;
            this._searchTimeseries();
            this._cdr.markForCheck();
        }
    }
    private _unitFamily: IUnitFamily;

    @Input()
    get calculated(): boolean {
        return this._calculated;
    }
    set calculated(value: boolean) {
        if (value !== this.calculated) {
            this._calculated = value;
            this.filterCalculated = this._calculated;
            this._cdr.markForCheck();
        }
    }
    private _calculated: boolean;

    @Input()
    get locked(): boolean {
        return this._locked;
    }
    set locked(value: boolean) {
        if (value !== this.locked) {
            this._locked = value;
            this.filterLocked = this._locked;
            this._cdr.markForCheck();
        }
    }
    private _locked: boolean;

    @Input()
    get hideFilterCalculated(): boolean {
        return this._hideFilterCalculated;
    }
    set hideFilterCalculated(value: boolean) {
        const newValue = _ez.coerceBoolean(value, false);
        if (this.hideFilterCalculated !== newValue) {
            this._hideFilterCalculated = newValue;
            this._cdr.markForCheck();
        }
    }
    private _hideFilterCalculated: boolean = false;

    @Input()
    get hideFilterLocked(): boolean {
        return this._hideFilterLocked;
    }
    set hideFilterLocked(value: boolean) {
        const newValue = _ez.coerceBoolean(value, false);
        if (this.hideFilterLocked !== newValue) {
            this._hideFilterLocked = newValue;
            this._cdr.markForCheck();
        }
    }
    private _hideFilterLocked: boolean = false;

    set internalValue(value: ICurveBase | ICurveBase[]) {
        if (!_ez.isSame(this._internalValue, value)) {
            this._internalValue = value;
            this._value = this._internalValue;
            this._onChange(this._value);
            this.valueChange.emit(this._value);
        }
    }
    get internalValue() {
        return this._internalValue;
    }
    private _internalValue: ICurveBase | ICurveBase[];

    get timeseries(): ICurveBase[] {
        return this._timeseries;
    }
    private _timeseries: ICurveBase[];

    get loadData(): boolean {
        return this._loadData;
    }
    private _loadData: boolean = true;

    readonly posAlignOverlayLabelsList: PositionAlignInternal = new PositionAlignInternal(VerticalPosition.Bottom, HorizontalPosition.Right, false, true);
    panelFiltersIsOpen: boolean = false;
    isLoadingLabelsList: boolean = false;
    labels: IGroup[];

    filterLabels: GroupSelection[] = [];
    filterCalculated: boolean;
    filterLocked: boolean;
    filterTypes: CurveType[] = [];

    hasFilters: boolean = false;

    private _contractId: number;
    private _subs: Subscriptions = new Subscriptions();

    @HostListener("focusin", ['$event'])
    focusin(event: FocusEvent): void {
        super.focusin(event);
        if (event.target === this.element) {
            this.focus();
        } else if (this._select.element.contains(event.target as HTMLElement)) {
            this.panelFiltersIsOpen = false;
        }
    }

    @ViewChild('divOverlay') readonly divOverlayElement: ElementRef<HTMLElement>;
    private _subClickOut: Subscription;
    @HostListener("focusout", ['$event'])
    focusout(event: FocusEvent): void {
        if (event.relatedTarget && this.divOverlayElement != null && this.divOverlayElement.nativeElement.contains(event.relatedTarget as HTMLElement)) {
            if (this._subClickOut == null) {
                this._subClickOut = fromEvent(document, 'focusout').subscribe((eventFocus: FocusEvent) => {
                    this.focusout(eventFocus);
                    this._cdr.markForCheck();
                });
            }
            event.stopPropagation();
            return;
        }
        super.focusout(event);
        if (!this.hasFocus) {
            this.panelFiltersIsOpen = false;
            this._cleanSubClickOut();
        }
    }

    private _subjectSearchEnd: Subject<void>;

    private _isInit: boolean = false;

    constructor(renderer2: Renderer2,
        private _api: ApiService,
        private _appSvc: ApplicationService,
        _elementRef: ElementRef,
        _cdr: ChangeDetectorRef,
        @Attribute('tabindex') tabIndex: string,
        @Attribute('id') id: string,
        @Self() @Optional() ngControl: NgControl

    ) {
        super(renderer2, _elementRef, _cdr, ngControl, tabIndex, id);
        this.placeholder = 'Select time series';
        this._contractId = this._appSvc.contract.id;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._isInit = true;
        this._searchTimeseries();
    }

    ngOnDestroy(): void {
        this._subs.clearAll();
        this.panelFiltersIsOpen = false;
        this._cleanSubClickOut();
    }

    focus(): void {
        if (this._select) {
            this._select.focus();
        }
    }

    private _cleanSubClickOut(): void {
        if (this._subClickOut) {
            this._subClickOut.unsubscribe();
            this._subClickOut = undefined;
        }
    }

    private _searchTimeseries(nameFilter?: string): void {
        if (this._isInit) {
            this._subs.clearSub('search-ts');
            const labels = _.filter(this.filterLabels, (label: GroupSelection) => label.status === GroupSelectionStatus.Full).map((label: GroupSelection) => label.group);
            let obs: Observable<ICurveBase[]>;
            if (this.type === CurveType.Decimal) {
                if (this.familiesUmScore != null && (this.unitFamily == null || this.unitFamily.score !== this.familiesUmScore)) {
                    const sub = this._api.timeseries.unitFamilies.getAll(this._contractId, [this.familiesUmScore]).subscribe((units) => {
                        if (units == null || units.length === 0) {
                            this._familiesUmScore = undefined;
                        } else {
                            this._unitFamily = units[0];
                        }
                        this._searchTimeseries(nameFilter);
                        this._loadData = false;
                    });
                    this._subs.push('search-ts-units', sub);
                    return;
                } else {
                    obs = this._api.timeseries.decimalCurves.getAll(this._contractId, {
                        count: this._sizeElement,
                        name: nameFilter,
                        labelIds: labels.length > 0 ? labels.map((l) => l.id) : undefined,
                        isComputed: this.filterCalculated,
                        isLock: this.filterLocked,
                        unitFamilyId: this._unitFamily ? this._unitFamily.id : undefined
                    });
                }
            } else {
                obs = this._api.timeseries.curves.getAll(this._contractId, {
                    count: this._sizeElement,
                    name: nameFilter,
                    labels: labels.length > 0 ? labels : undefined,
                    isComputed: this.filterCalculated,
                    isLock: this.filterLocked,
                    types: this.filterTypes.length > 0 ? this.filterTypes : undefined
                });
            }
            this.hasFilters = labels.length > 0 || this.filterCalculated || this.filterLocked;
            const sub = obs.subscribe((timeseries: ICurveBase[]) => {
                this._timeseries = timeseries;
                this._addValueInTsList();
                if (this._subjectSearchEnd != null) {
                    this._subjectSearchEnd.next();
                    this._subjectSearchEnd.complete();
                }
                this._loadData = false;
                this._cdr.detectChanges();
            }, () => {
                this._timeseries = [];
                this.isLoadingLabelsList = false;
                if (this._subjectSearchEnd != null) {
                    this._subjectSearchEnd.next();
                    this._subjectSearchEnd.complete();
                }
                this._loadData = false;
                this._cdr.detectChanges();
            });
            this._subs.push('search-ts', sub);
        }
    }

    toggleFiltersPanel(): void {
        this.panelFiltersIsOpen = !this.panelFiltersIsOpen;
        if (this.labels === undefined && !this.isLoadingLabelsList) {
            this.isLoadingLabelsList = true;
            this._api.timeseries.groups.getAll(this._contractId).subscribe((labels: IGroup[]) => {
                this.labels = labels;
                this.isLoadingLabelsList = false;
                this._cdr.detectChanges();
            }, () => {
                this.isLoadingLabelsList = false;
                this._cdr.detectChanges();
            });
        }
    }

    isCalculatedChange(value: boolean): void {
        if (this.filterCalculated !== value) {
            this.filterCalculated = value;
            this._searchTimeseries();
        }
    }

    isLockedChange(value: boolean): void {
        if (this.filterLocked !== value) {
            this.filterLocked = value;
            this._searchTimeseries();
        }
    }

    onLabelsChange(value: GroupSelection[]): void {
        this.filterLabels = value;
        this._searchTimeseries();
    }

    private _addValueInTsList(): void {
        if (!this._timeseries) {
            this._timeseries = [];
        }
        if (this.multiple) {
            const value: ICurveBase[] = this.value as ICurveBase[];
            if (value != null && value.length > 0) {
                _.forEach(value, (ts: ICurveBase) => {
                    if (!_ez.containsSame(this._timeseries, ts)) {
                        this._timeseries.push(ts);
                    }
                });
            }
        } else {
            const value: ICurveBase = this.value as ICurveBase;
            if (value != null) {
                if (!_.some(this._timeseries, (ts: ICurveBase) => _ez.isSame(ts, value))) {
                    this._timeseries.push(value);
                }
            }
        }

        this._timeseries = _.orderBy(this._timeseries, (ts: ICurveBase) => _.deburr(ts.name?.toLowerCase()));
        this._cdr.detectChanges();
        this._internalValue = this._value;
    }

    autoCompleteTrigger = (text: string) => {
        this._subjectSearchEnd = new Subject();
        this._searchTimeseries(text);
        return this._subjectSearchEnd.asObservable().pipe(map(() => text ? this.timeseries : undefined));
    }

    writeValue(value: any) {
        this.value = value;
        super.writeValue(this.value);
    }
}
