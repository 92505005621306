// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import * as _ez from "@eznergy/core";
import { IEtsMarketResult, IEtsMarketResultOrderBlock } from "@eztypes/webapi";
import { EtsBlockOrderHeader, EtsBlockTotal, EtsGlobal, EtsPortfolioResult, EtsPortfolioTotal, PortfolioMarket } from "@models/api/ets/etsMarketResult.model";
import { TranslateService } from '@ngx-translate/core';
import { EtsService } from "@services/ets.service";
import { Decimal } from 'decimal.js-light';
import * as _ from "lodash";

@Component({
    selector: 'ets-market-result-portfolio',
    templateUrl: 'ets-market-result-portfolio.component.html',
    styleUrls: ['ets-market-result-portfolio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class EtsMarketResultPortfolioComponent {

    private _globals: EtsGlobal[];

    @Input()
    get portfolio(): PortfolioMarket {
        return this._portfolio;
    }
    set portfolio(value: PortfolioMarket) {
        if (!_.isEqual(this._portfolio, value)) {
            this._portfolio = value;
            this._prepareData();
            this._cdr.detectChanges();
        }
    }
    private _portfolio: PortfolioMarket;

    @Input()
    get marketData(): IEtsMarketResult {
        return this._marketData;
    }
    set marketData(value: IEtsMarketResult) {
        if (!_.isEqual(this._marketData, value)) {
            this._marketData = value;
            if (this._marketData && this._marketData.portfolios && this._marketData.globals) {
                this._globals = _.orderBy(this._marketData.globals, ['periodStart'], ['asc']);
                this._blockOrders = this._marketData.blockOrders;
                this._prepareData();
                this._cdr.detectChanges();
            }
        }
    }
    private _marketData: IEtsMarketResult;

    portfolios: EtsPortfolioResult[];
    portfolioBlockOrders: EtsBlockOrderHeader[];
    portfolioTotal: EtsPortfolioTotal[];
    blockOrderTotal: EtsBlockTotal[];
    display: boolean;
    private _blockLabel: string;
    private _blockOrders: IEtsMarketResultOrderBlock[];

    ngOnDestroy() {
        this._clearOlDisplay();
    }

    constructor(private _srvTrad: TranslateService, private _cdr: ChangeDetectorRef, private _etsSvc: EtsService) {
        this._srvTrad.get('balancing.ets.ol.column.block').subscribe((label) => {
            this._blockLabel = label;
        });
    }

    private _clearOlDisplay() {
        this._blockOrders = undefined;
        this.portfolios = undefined;
        this.portfolioBlockOrders = undefined;
        this.portfolioTotal = undefined;
        this.blockOrderTotal = undefined;
        this.display = undefined;
    }

    private _prepareData() {
        if (this._portfolio && this._blockOrders && this._globals) {
            let portfolios = [];
            let portfolioBlockOrders = [];
            let portfolioTotal = [];

            let globalPrice = new Decimal(_.sumBy(this._globals, 'price'));

            let index = 1;

            for (let i = 0; i < 3; i++) {
                let item = new EtsPortfolioTotal();
                item.data = [];
                portfolioTotal.push(item);
            }

            this._labelTranslatation(portfolioTotal);
            this._buildGlobalData(portfolios, portfolioTotal);
            this._buildBlocksData(index, portfolioBlockOrders, portfolioTotal, portfolios);

            this.display = portfolioBlockOrders != undefined && portfolioBlockOrders.length > 0;

            portfolioTotal[0].price = (globalPrice.dividedBy(this._globals.length)).toDecimalPlaces(2).toNumber().toLocaleString();

            this.portfolios = portfolios;
            this.portfolioTotal = portfolioTotal;
            this.portfolioBlockOrders = portfolioBlockOrders;
        }
    }

    private _labelTranslatation(portfolioTotal) {
        let totalLabel, peakLabel, offPeaklLabel, offerLabel, WapLabel, statusLabel;
        this._srvTrad.get('balancing.ets.ol.column').subscribe((labels) => {
            totalLabel = labels.etsTotal;
            peakLabel = labels.peak;
            offPeaklLabel = labels.offPeak;
            offerLabel = labels.offer;
            WapLabel = labels.wap;
            statusLabel = labels.status;
        });
        portfolioTotal[0].label = totalLabel;
        portfolioTotal[0].label2 = offerLabel;

        portfolioTotal[1].label = peakLabel;
        portfolioTotal[1].label2 = WapLabel;

        portfolioTotal[2].label = offPeaklLabel;
        portfolioTotal[2].label2 = statusLabel;
    }

    private _buildGlobalData(portfolios, portfolioTotal) {
        let totalBlock = new Decimal(0);
        let peakBlock = new Decimal(0);
        let offPeakBlock = new Decimal(0);

        let peakPrice = new Decimal(0);
        let offPeakPrice = new Decimal(0);

        let totalLinear = new Decimal(0);
        let peakLinear = new Decimal(0);
        let offPeakLinear = new Decimal(0);

        let peakNbOftimeStep = 0;
        let offPeakNbOftimeStep = 0;

        _.forEach(this._globals, (global, index) => {
            let portfolioItem = new EtsPortfolioResult();
            portfolioItem.date = global.periodStart;

            let hour = portfolioItem.date.hours;
            portfolioItem.globalPrice = new Decimal(global.price).toDecimalPlaces(2).toNumber().toLocaleString();

            if (hour >= 8 && hour < 20) {
                peakNbOftimeStep = peakNbOftimeStep + 1;
                peakPrice = peakPrice.plus(global.price);
            } else {
                offPeakNbOftimeStep = offPeakNbOftimeStep + 1;
                offPeakPrice = (offPeakPrice.plus(global.price));
            }

            if (portfolioItem.portfolioBlocks == undefined) {
                portfolioItem.portfolioBlocks = [];
            }

            if (this._portfolio.linear[index] && _ez.isSame(portfolioItem.date, this._portfolio.linear[index].periodStart)) {

                totalLinear = this._etsSvc.calcVwA(this._portfolio.linear[index].periodEnd, this._portfolio.linear[index].periodStart, this._portfolio.linear[index].net, totalLinear);
                portfolioItem.linearVolume = new Decimal(this._portfolio.linear[index].net).toDecimalPlaces(2).toNumber().toLocaleString();

                if (hour >= 8 && hour < 20) {
                    peakLinear = this._etsSvc.calcVwA(this._portfolio.linear[index].periodEnd, this._portfolio.linear[index].periodStart, this._portfolio.linear[index].net, peakLinear);
                } else {
                    offPeakLinear = this._etsSvc.calcVwA(this._portfolio.linear[index].periodEnd, this._portfolio.linear[index].periodStart, this._portfolio.linear[index].net, offPeakLinear);
                }
            }

            if (this._portfolio.block[index] && _ez.isSame(portfolioItem.date, this._portfolio.block[index].periodStart)) {
                totalBlock = this._etsSvc.calcVwA(this._portfolio.block[index].periodEnd, this._portfolio.block[index].periodStart, this._portfolio.block[index].net, totalBlock);
                portfolioItem.blockVolume = new Decimal(this._portfolio.block[index].net).toDecimalPlaces(2).toNumber().toLocaleString();
                if (hour >= 8 && hour < 20) {
                    peakBlock = this._etsSvc.calcVwA(this._portfolio.block[index].periodEnd, this._portfolio.block[index].periodStart, this._portfolio.block[index].net, peakBlock);
                } else {
                    offPeakBlock = this._etsSvc.calcVwA(this._portfolio.block[index].periodEnd, this._portfolio.block[index].periodStart, this._portfolio.block[index].net, offPeakBlock);
                }
            }
            portfolios.push(portfolioItem);
        });

        portfolioTotal[0].linear = totalLinear.toDecimalPlaces(2).toNumber().toLocaleString();
        portfolioTotal[0].block = totalBlock.toDecimalPlaces(2).toNumber().toLocaleString();

        portfolioTotal[1].price = (peakPrice.dividedBy(peakNbOftimeStep)).toDecimalPlaces(2).toNumber().toLocaleString();
        portfolioTotal[1].linear = peakLinear.toDecimalPlaces(2).toNumber().toLocaleString();
        portfolioTotal[1].block = peakBlock.toDecimalPlaces(2).toNumber().toLocaleString();

        portfolioTotal[2].price = (offPeakPrice.dividedBy(offPeakNbOftimeStep)).toDecimalPlaces(2).toNumber().toLocaleString();
        portfolioTotal[2].linear = offPeakLinear.toDecimalPlaces(2).toNumber().toLocaleString();
        portfolioTotal[2].block = offPeakBlock.toDecimalPlaces(2).toNumber().toLocaleString();

    }

    private _buildBlocksData(index, portfolioBlockOrders, portfolioTotal, portfolios) {

        let blockorders = _.sortBy(this._blockOrders, 'price');

        _.forEach(blockorders, (blockorder) => {

            if (blockorder.portfolio.id == this._portfolio.portfolio.id) {
                let block = new EtsBlockOrderHeader;

                block.name = this._blockLabel + " " + index;
                block.etsID = blockorder.etsId;
                block.comment = blockorder.comment;

                portfolioBlockOrders.push(block);

                portfolioTotal[0].data.push(new Decimal(blockorder.price).toDecimalPlaces(2).toNumber().toLocaleString());
                portfolioTotal[2].data.push(blockorder.status);

                let totalPeriodVolume = _.sumBy(blockorder.periods, 'volume');
                let wap = new Decimal(0);

                let periods = _.sortBy(blockorder.periods, 'periodStart');

                if (periods.length == this._globals.length) {

                    _.forEach(blockorder.periods, (period, i) => {
                        if (_ez.isSame(period.periodStart, portfolios[i].date)) {
                            let price = new Decimal(this._globals[i].price);
                            let volume = new Decimal(period.volume);
                            portfolios[i].portfolioBlocks.push(volume.toDecimalPlaces(2).toNumber().toLocaleString());
                            wap = wap.plus(price.mul(volume));
                        }
                    });
                } else {
                    let g = 0;
                    _.forEach(this._globals, function (global, e) {
                        if (periods[g] && _ez.isSame(global.periodStart, periods[g].periodStart)) {

                            let price = new Decimal(global.price);
                            let volume = new Decimal(periods[g].volume);
                            portfolios[e].portfolioBlocks.push(volume.toDecimalPlaces(2).toNumber().toLocaleString());
                            wap = wap.plus(price.mul(volume));
                            g = g + 1;
                        } else {
                            portfolios[e].portfolioBlocks.push('-');
                        }
                    });
                }
                wap = wap.dividedBy(totalPeriodVolume);
                portfolioTotal[1].data.push(wap.toDecimalPlaces(2).toNumber().toLocaleString());
                index++;
            }
        });
    }
}
