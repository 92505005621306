<div class="title">
    {{value?.shortName}}
</div>
<div class="row">
    <span class="label">{{'general.id' | translate}} : </span>
    <div class="value">
        <div class="value-clipboard" (click)="copyIdToClipboard()" [ezTooltip]="'general.clipboard-copy' | translate">
            <span>{{value?.id}}</span>
            <ez-icon name="copy" [regular]="true"></ez-icon>
        </div>
    </div>
</div>
<div class="row">
    <span class="label">{{'ob.entity.fullname' | translate }} : </span>
    <span class="value">{{value?.longName ? value?.longName  : value?.shortName }}</span>
</div>
<div class="row" *ngIf="value?.codeEIC">
    <span class="label">{{'ob.entity.codeEIC' | translate }} : </span>
    <span class="value">
        <div class="value-clipboard" (click)="copyEicToClipboard()" [ezTooltip]="'general.clipboard-copy' | translate">
            <span>{{ value?.codeEIC }}</span>
            <ez-icon name="copy" [regular]="true"></ez-icon>
        </div>
    </span>
</div>
