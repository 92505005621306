// @ts-nocheck
/* tslint:disable */
import { Reflect } from "core-js";
import { dataParamMetadataKey, IDataParamMetaData, IQueryParamMetaData, QueryParamMetaData, queryParamMetadataKey } from "@core/metadata";
import { serialize } from "@eznergy/core";
import { Observable, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Subscriptions } from "./subscriptions";

export function QueryParamProperty<T>(metadata?: IQueryParamMetaData<T> | string): any {
    if (metadata instanceof String || typeof metadata === "string") {
        return Reflect.metadata(queryParamMetadataKey, new QueryParamMetaData(metadata as string));
    } else {
        return Reflect.metadata(queryParamMetadataKey, metadata);
    }
}

export function DataParamProperty<T>(metadata?: IDataParamMetaData<T>): any {
    return Reflect.metadata(dataParamMetadataKey, metadata);
}




export interface IUserPreferenceClass {
    stringify(): string;
    formatPreferencesObject(): any;
}

export interface IUserPreferenceClassDecorator extends IUserPreferenceClass {
    readonly isUPClass: boolean;
    readonly changes: Observable<void>;
}

function getUPValueOld(value: any): any {
    if (value != null && (value as IUserPreferenceClassDecorator).isUPClass === true) {
        return (value as IUserPreferenceClassDecorator).stringify();
    }
    return value;
}

function getUPValue(value: any): any {
    if (value != null && (value as IUserPreferenceClassDecorator).isUPClass === true) {
        return (value as IUserPreferenceClassDecorator).formatPreferencesObject();
    }
    return value;
}

export function UserPreferenceClassDecorator<T extends { new(...args: any[]): {} }>(constructor: T): T {
    return class extends constructor implements IUserPreferenceClassDecorator {

        readonly isUPClass: boolean = true;

        get changes(): Observable<void> {
            return this._subjectChanges.asObservable().pipe(debounceTime(10));
        }
        private readonly _subjectChanges: Subject<void> = new Subject();

        private readonly propertiesSaving: string[] = []

        private readonly _subs: Subscriptions = new Subscriptions();

        constructor(...args: any[]) {
            super(...args);
            const properties = Object.getOwnPropertyNames(this);
            for (const property of properties) {
                if (this["save_" + property] === true) {
                    this.propertiesSaving.push(property);
                }
                if (property === "isUPClass" || property === "_subjectChanges") {
                    return;
                }
                let value = this[property];
                this._checkIfUpClass(property, value);
                if (delete this[property]) {
                    Object.defineProperty(this, property, {
                        get: () => {
                            return value;
                        },
                        set: (v) => {
                            if (v !== value) {
                                value = v;
                                this._checkIfUpClass(property, value);
                                this._subjectChanges.next();
                            }
                        }
                    });
                }
            }
        }

        stringify(): string {
            const obj = {};
            for (const property of this.propertiesSaving) {
                if (this[property] != null && Array.isArray(this[property])) {
                    obj[property] = [];
                    for (let prop of this[property]) {
                        obj[property].push(getUPValueOld(prop));
                    }
                } else {
                    obj[property] = getUPValueOld(this[property]);
                }
            }
            return serialize(obj);
        }

        formatPreferencesObject() {
            const obj = {};
            for (const property of this.propertiesSaving) {
                if (this[property] != null && Array.isArray(this[property])) {
                    obj[property] = [];
                    for (let prop of this[property]) {
                        obj[property].push(getUPValue(prop));
                    }
                } else {
                    obj[property] = getUPValue(this[property]);
                }
            }
            return obj;
        }

        private _checkIfUpClass(property: string, value: any): void {
            if (value != null) {
                if ((value as IUserPreferenceClassDecorator).isUPClass === true) {
                    this._subs.clearSub(property);
                    const sub = (value as IUserPreferenceClassDecorator).changes.subscribe(() => {
                        this._subjectChanges.next();
                    });
                    this._subs.push(property, sub);
                } else if (Array.isArray(value) && value.length > 0 && (value[0] as IUserPreferenceClassDecorator).isUPClass === true) {
                    this._subs.clearSubStartWith(property);
                    for (let i = 0; i < value.length; i++) {
                        const sub = (value[i] as IUserPreferenceClassDecorator).changes.subscribe(() => {
                            this._subjectChanges.next();
                        });
                        this._subs.push(property + '_' + i, sub);
                    }
                }
            }
        }
    }
}

export function UPPropertySave(target: any, property: string): void {
    Object.defineProperty(target, "save_" + property, {
        value: true
    });
}

// export function enumerable(value: boolean): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => void {
//     return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
//         descriptor.enumerable = value;
//     };
// }

