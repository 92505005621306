// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Logger } from '@core/logger';
import { BasePagePopup } from '@core/pages';
import { EzPaginatorComponent, PaginatorEvent } from '@eznergy/components';
import { ApiService } from '@eznergy/webapi';
import { DateTime } from '@eztypes/generic';
import { AppEvent, AppEventType, ComInterfaceDetailEvent, EventAcknowledge, IComInterfaceCharacteristic, UserRight } from '@eztypes/webapi';
import { ApplicationService } from '@services/application.service';
import { AppPages, RouterService } from '@services/router.service';
import * as _ from 'lodash';
import { auditTime } from 'rxjs/operators';

enum FilterAcknow {
    Read = 'read',
    Unread = 'unread'
}

interface IPageEvent { idAuction: number; idAutoTrading: number; idComInt: number; }

class EventView {

    get event(): AppEvent {
        return this._event;
    }
    private _event: AppEvent;

    readonly type: AppEventType;
    readonly date: DateTime;

    get hasDetails(): boolean {
        return this._hasDetails;
    }
    private _hasDetails: boolean;

    get hasAckno(): boolean {
        return this._hasAckno;
    }
    private _hasAckno: boolean;

    get onUpdated(): boolean {
        return this._onUpdated;
    }
    private _onUpdated: boolean = false;

    get onLoadDetails(): boolean {
        return this._onLoadDetails;
    }
    private _onLoadDetails: boolean = false;

    get detailsOpened(): boolean {
        return this._detailsOpened;
    }
    private _detailsOpened: boolean = false;

    get detailsLoaded(): boolean {
        return this._detailsLoaded;
    }
    private _detailsLoaded: boolean = false;

    get details(): ComInterfaceDetailEvent[] {
        return this._details;
    }
    private _details: ComInterfaceDetailEvent[];

    constructor(event: AppEvent) {
        this._event = event;
        this.type = this.event.type;
        this.date = this.event.date;
        this.setEvent(event);
    }

    updating(): void {
        this._onUpdated = true;
    }

    loadingDetails(): void {
        this._onLoadDetails = true;
    }

    toggleDetails(): void {
        this._detailsOpened = !this._detailsOpened;
    }

    setEvent(event: AppEvent): void {
        this._event = event;
        this._hasAckno = this.event.acknowledge != null;
        this._hasDetails = this.event.hasDetails || this._hasAckno;
        this._onUpdated = false;
    }

    setDetails(details: ComInterfaceDetailEvent[]): void {
        this._details = details;
        this._onLoadDetails = false;
        this._detailsLoaded = true;
    }
}

@Component({
    selector: 'events-page',
    templateUrl: './events.page.html',
    styleUrls: ['./events.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AppEventsPage extends BasePagePopup {

    @ViewChild(EzPaginatorComponent, { static: true }) paginator: EzPaginatorComponent;

    get events(): EventView[] {
        return this._eventsView;
    }
    private _eventsView: EventView[] = [];

    private _cacheStreamEvents: AppEvent[];

    // get urlPage(): string {
    //     if (!this._routerSvc)
    //         return undefined;
    //     return this._routerSvc.getUrl(this._page);
    // }
    get urlPopup(): string {
        if (!this._routerSvc)
            return undefined;
        return this._routerSvc.getUrlPopup(AppPages.EventsReport);
    }

    get filterAcknow(): FilterAcknow[] {
        return this._filterAcknow;
    }
    private _filterAcknow: FilterAcknow[] = [FilterAcknow.Unread];

    get filterType(): AppEventType[] {
        return this._filterType;
    }
    private _filterType: AppEventType[] = [AppEventType.AutoTrading, AppEventType.ComInterface, AppEventType.Auction];

    get hasReadAuction(): boolean {
        return this._hasReadAuction;
    }
    private _hasReadAuction: boolean = false;
    get hasUpdateAuction(): boolean {
        return this._hasUpdateAuction;
    }
    private _hasUpdateAuction: boolean = false;

    get hasReadAt(): boolean {
        return this._hasReadAt;
    }
    private _hasReadAt: boolean = false;
    get hasUpdateAt(): boolean {
        return this._hasUpdateAt;
    }
    private _hasUpdateAt: boolean = false;
    get hasReadCi(): boolean {
        return this._hasReadCi;
    }
    private _hasReadCi: boolean = false;
    get hasUpdateCi(): boolean {
        return this._hasUpdateCi;
    }
    private _hasUpdateCi: boolean = false;

    get displayFilterBtn(): boolean {
        return this._displayFilterBtn;
    }
    private _displayFilterBtn: boolean = false;


    readonly eventTypes = AppEventType;
    readonly acknowFilters = FilterAcknow;

    get lengthElements(): number {
        return this._lengthElements;
    }
    private _lengthElements: number = 100;
    private _realLengthElement: number = 100;

    private _lastIndexPaginator: number = 0;

    private _pageSize: number = 50;

    private _displayStream: boolean = true;

    private _currentPage: IPageEvent;


    private _mapPageParams: Map<number, IPageEvent> = new Map();

    constructor(logger: Logger,
        private _api: ApiService,
        private _appSvc: ApplicationService,
        private _cdr: ChangeDetectorRef,
        private _routerSvc: RouterService) {
        super(logger, 'events-report');
        this.titlePopup = "Events";
    }

    ngOnInit(): void {
        this._loaderPage.push("get_events");
        const sub = this._api.events.hub(this._appSvc.contract.id).events().pipe(auditTime(300)).subscribe((events) => {
            if (events != null && events.dataReceived) {
                this._cacheStreamEvents = events.data;

                if (this._displayStream) {
                    this._displayStreamEvents(events.updated);
                } else {
                    _.forEach(events.updated, (e) => {
                        this._updatedViewByEvent(e, false);
                    });
                }
                this._loaderPage.remove("get_events");
                this._cdr.detectChanges();
            }
        });
        this._subscriptions.push('events', sub);
        const subRight = this._appSvc.contractChange.subscribe(() => {
            this._hasReadAt = this._appSvc.hasRight(UserRight.EventAutotradingRead);
            this._hasReadCi = this._appSvc.hasRight(UserRight.EventComintRead);
            this._hasReadAuction = this._appSvc.hasRight(UserRight.EventAuctionRead);

            this._hasUpdateAt = this._appSvc.hasRight(UserRight.EventAutotradingUpdate);
            this._hasUpdateCi = this._appSvc.hasRight(UserRight.EventComintUpdate);
            this._hasUpdateAuction = this._appSvc.hasRight(UserRight.EventAuctionUpdate);

            if (!this._hasReadAt) {
                const index = this._filterType.findIndex((a) => a === AppEventType.AutoTrading);
                if (index !== -1) {
                    this._filterType.splice(index, 1);
                }
            }

            if (!this._hasReadCi) {
                const index = this._filterType.findIndex((a) => a === AppEventType.ComInterface);
                if (index !== -1) {
                    this._filterType.splice(index, 1);
                }
            }

            if (!this._hasUpdateAuction) {
                const index = this._filterType.findIndex((a) => a === AppEventType.Auction);
                if (index !== -1) {
                    this._filterType.splice(index, 1);
                }
            }

            this._displayFilterBtn = this._hasReadAt && this._hasReadCi || this._hasReadAt && this._hasReadAuction
                || this._hasReadCi && this._hasReadAuction;
            this._cdr.detectChanges();
        });
        this._subscriptions.push('right', subRight);
    }

    ngAfterContentInit(): void {
        super.ngAfterContentInit();
    }

    ngAfterViewInit(): void {
        if (this.paginator != null) {
            this.paginator.onChange.subscribe((event: PaginatorEvent) => {
                if (this._lastIndexPaginator !== event.startIndex || this.paginator.size !== this._pageSize) {
                    const sizeChanged = this.paginator.size !== this._pageSize;
                    this._pageSize = this.paginator.size;
                    this._lengthElements = event.endElement + (this._pageSize * 2);
                    this._realLengthElement = event.endElement;

                    if (sizeChanged || event.startIndex === 0) {
                        this._resetParams();
                        this._cdr.detectChanges();
                    } else {
                        this._displayStream = false;
                        this._callEvents();
                        this._lastIndexPaginator = event.startIndex;
                    }
                }
            });
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    filterAcknowChange(filter: FilterAcknow[]): void {
        this._filterAcknow = filter;
        this._resetParams();
        (document.activeElement as HTMLElement).blur();
    }

    filterTypeChange(filter: AppEventType[]): void {
        this._filterType = filter;
        this._resetParams();
        (document.activeElement as HTMLElement).blur();
    }

    acknowlegdeAll(): void {
        const events: AppEvent[] = [];
        _.forEach(this.events, (view: EventView) => {
            if (view.event.acknowledge == null && this._hasRightUpdate(view)) {
                view.updating();
                events.push(view.event);
            }
        });
        if (events.length > 0) {
            const indexPage = this._lastIndexPaginator;
            this._api.events.acknowledgeList(this._appSvc.contract.id, events).subscribe(() => {
                if (indexPage === this._lastIndexPaginator) {
                    _.forEach(this.events, (view: EventView) => {
                        if (view.onUpdated) {
                            const e = view.event;
                            e.acknowledge = new EventAcknowledge(DateTime.now());
                            this._updatedViewByEvent(e, false);
                        }
                    });
                    this._cdr.detectChanges();
                }
            }, (error) => {
                this._logger.error("Error event ackno all", error);
            });
        }
    }

    acknowlegde(event: MouseEvent, view: EventView): void {
        event.stopPropagation();
        if (this._hasRightUpdate(view)) {
            view.updating();
            this._api.events.acknowledge(this._appSvc.contract.id, view.event).subscribe((event: AppEvent) => {
                this._updatedViewByEvent(event, false);
                this._cdr.detectChanges();
            }, (error) => {
                this._logger.error("Error event ackno", error);
            });
        }
    }

    unacknowledge(event: MouseEvent, view: EventView): void {
        event.stopPropagation();
        if (this._hasRightUpdate(view)) {
            view.updating();
            this._api.events.unacknowledge(this._appSvc.contract.id, view.event).subscribe((event: AppEvent) => {
                this._updatedViewByEvent(event, false);
                this._cdr.detectChanges();
            }, (error) => {
                this._logger.error("Error event unackno", error);
            });
        }
    }

    toggleDetail(view: EventView): void {
        if (!view.onLoadDetails) {
            if (!view.detailsLoaded && view.event.type === AppEventType.ComInterface && view.event.hasDetails) {
                const comInterface = (view.event.characteristic as IComInterfaceCharacteristic).comInterface;
                if (comInterface != null) {
                    view.loadingDetails();
                    this._api.communicationInterfaceEvents.getAllDetails(this._appSvc.contract.id, view.event.id).subscribe((details: ComInterfaceDetailEvent[]) => {
                        view.setDetails(details);
                        view.toggleDetails();
                        this._cdr.detectChanges();
                    });
                    return;
                }
            }
            view.toggleDetails();
        }
    }

    onPageChange(): void {
        this._cdr.detectChanges();
    }

    private _hasRightUpdate(event: EventView): boolean {
        switch (event.type) {
            case AppEventType.AutoTrading:
                return this._hasUpdateAt;
            case AppEventType.ComInterface:
                return this._hasUpdateCi;
            case AppEventType.Auction:
                return this._hasUpdateAuction;
        }
    }

    private _refreshData(): void {
        if (this._displayStream) {
            this._eventsView = [];
            this._displayStreamEvents();
        } else {
            this._callEvents();
        }
    }

    private _displayStreamEvents(updateds?: AppEvent[]): void {
        if (this._cacheStreamEvents != null && this._cacheStreamEvents.length > 0) {
            let events = this._filtersEvents(this._cacheStreamEvents);
            events = _.orderBy(events, (event) => event.date.time && event.id, "desc");
            this._lengthElements = events.length || this._pageSize;
            events = events.slice(0, this._pageSize);
            this._realLengthElement = events.length;
            if (updateds && updateds.length > 0) {
                _.remove(this._eventsView, (view: EventView) => {
                    if (!_.some(updateds, (event: AppEvent) => view.event.id === event.id)) {
                        return false;
                    }
                    if (!_.some(events, (event: AppEvent) => view.event.id === event.id)) {
                        return true;
                    }
                });
            }
            _.forEach(events, (event: AppEvent) => {
                this._updatedViewByEvent(event);
            });
            this._eventsView = _.orderBy(this._eventsView, (view: EventView) => view.date.time && view.event.id, "desc");
            if (events.length < this._pageSize) {
                this._callEvents(true);
            }
        }
    }

    private _updatedViewByEvent(event: AppEvent, addIfNotExist: boolean = true): boolean {
        let changed = false;
        const view = _.find(this._eventsView, (view: EventView) => view.event === event || (view.event.id === event.id && view.event.type === event.type));
        if (view) {
            view.setEvent(event);
        } else if (addIfNotExist) {
            changed = true;
            this._eventsView.push(new EventView(event));
        }
        if (this._eventsView.length > this._pageSize) {
            this._eventsView = _.orderBy(this._eventsView, (view: EventView) => view.date.time && view.event.id, "desc");
            this._eventsView = this._eventsView.slice(0, this._pageSize);
            changed = true;
        }
        return changed;
    }

    private _filtersEvents(events: AppEvent[]): AppEvent[] {
        return _.filter(events, (event: AppEvent) => {
            return (_.includes(this._filterType, event.type) && _.includes(this._filterAcknow, event.acknowledge == null ? FilterAcknow.Unread : FilterAcknow.Read)) || _.some(this._eventsView, (view) => view.event.id === event.id && view.event.type == event.type);
        });
    }

    private _callEvents(isAddData: boolean = false): void {
        if (this._filterType.length !== 0 && this._filterAcknow.length !== 0) {
            this._loaderPage.push("get_events_call");
            this._cdr.detectChanges();

            let idMap = this.paginator.page;
            let nextPage = this._mapPageParams.get(idMap);
            if (!nextPage) {
                nextPage = this._getLastIndex();
                this._mapPageParams.set(idMap, nextPage);
            }
            this._currentPage = nextPage;

            const filterAcknow = this._filterAcknow.length == 2 ? undefined : _.includes(this._filterAcknow, FilterAcknow.Read);
            const filterTypes = Object.keys(this.eventTypes).length !== this._filterType.length ? this._filterType : undefined;
            this._subscriptions.clearSub('get_events_call');
            const sub = this._api.events.getAll(this._appSvc.contract.id, {
                acknowledged: filterAcknow,
                paging: { count: this._pageSize + 1, direction: 'desc', startIdComInt: this._currentPage.idComInt, startIdAutotrading: this._currentPage.idAutoTrading, startIdAuction: this._currentPage.idAuction, isAbsoluteIndex: true },
                types: filterTypes
            }).subscribe(
                (events: AppEvent[]) => {
                    if (!isAddData) {
                        this._eventsView = [];
                    }

                    let params = this._mapPageParams.get(this.paginator.page);
                    if (params) {
                        if (params.idComInt) {
                            this._removeEventFromViewEvents(events, AppEventType.ComInterface, params.idComInt);
                        }
                        if (params.idAutoTrading) {
                            this._removeEventFromViewEvents(events, AppEventType.AutoTrading, params.idAutoTrading);
                        }
                        if (params.idAuction) {
                            this._removeEventFromViewEvents(events, AppEventType.Auction, params.idAuction);
                        }
                    }

                    if (events && events.length > 0) {
                        const newEvents = _.orderBy(events, (e: AppEvent) => e.date.time && e.id, "desc");
                        if (newEvents.length > 0) {
                            let changed = false;
                            _.forEach(newEvents, (event: AppEvent) => {
                                changed = this._updatedViewByEvent(event) || changed;
                            });
                            if (newEvents.length < this._pageSize && changed) {
                                this._lengthElements = this._displayStream ? this.events.length : this._realLengthElement;
                            }
                        }
                    } else {
                        this._eventsView = _.clone(this._eventsView);
                        this._lengthElements = this._realLengthElement;
                    }

                    this._loaderPage.remove("get_events_call");
                    this._cdr.detectChanges();
                },
                (error) => {
                    this._logger.error("Error get events", error);
                }
            );
            this._subscriptions.push('get_events_call', sub);
        }
    }

    private _removeEventFromViewEvents(events: AppEvent[], type: AppEventType, idEvent: number): void {
        let eventIndex = events.findIndex((e) => e.id == idEvent && e.type == type);
        if (eventIndex != -1) {
            events.splice(eventIndex, 1);
        }
    }

    private _resetParams(): void {
        this._eventsView = [];
        this._currentPage = undefined;
        this._mapPageParams = new Map();
        this._lastIndexPaginator = 0;
        this.paginator.page = 1;
        this._displayStream = true;
        this._displayStreamEvents();
    }

    private _getLastIndex(): IPageEvent {
        let gp = _.groupBy(this.events.map((a) => a.event), (e) => e.type);
        let idAutotrading, idComInt, idAuction;
        if (gp[AppEventType.AutoTrading]) {
            idAutotrading = _.minBy(gp[AppEventType.AutoTrading], (e) => e.id).id;
        }
        if (gp[AppEventType.ComInterface]) {
            idComInt = _.minBy(gp[AppEventType.ComInterface], (e) => e.id).id;
        }
        if (gp[AppEventType.Auction]) {
            idAuction = _.minBy(gp[AppEventType.Auction], (e) => e.id).id;
        }

        return {
            idAutoTrading: idAutotrading ? idAutotrading : (this._currentPage ? this._currentPage.idAutoTrading : undefined),
            idAuction: idAuction ? idAuction : (this._currentPage ? this._currentPage.idAuction : undefined),
            idComInt: idComInt ? idComInt : (this._currentPage ? this._currentPage.idComInt : undefined)
        };
    }
}
