// @ts-nocheck
// Angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { PRIMARY_OUTLET } from "@angular/router";
import { BasePage, Logger } from '@core';
import { FormBase } from '@forms/core/form-base';
import { ApplicationService } from "@services/application.service";
import { AppPages, RouterService } from '@services/router.service';
import { timer } from "rxjs";
import { UserConnectedInfosService, OauthService, ApiService } from "@eznergy/webapi";
import { LoginResponse } from "angular-auth-oidc-client";
import { ActivatedRoute } from '@angular/router';
import { switchMap } from "rxjs/operators";
import { IContract } from "@eztypes/webapi";
import { orderBy } from '@eznergy/core';

enum FormPage {
    Login = 1,
    SingIn = 2,
    Recovery = 3,
    ChangePwd = 4
}

@Component({
    selector: "login-cmp",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent extends BasePage {
    get returnUrl(): string {
        return this._returnUrl;
    }
    private _returnUrl: string;

    constructor(
        private _cdr: ChangeDetectorRef,
        private readonly _appSvc: ApplicationService,
        private readonly _routerSvc: RouterService,
        private readonly _renderer: Renderer2,
        private readonly _el: ElementRef<HTMLElement>,
        private readonly _api: ApiService,
        logger: Logger,
        private readonly _oauthService: OauthService,
        private readonly _userConnectedInfos: UserConnectedInfosService,
        private _route: ActivatedRoute,
    ) {
        super(logger);
    }

    ngOnInit(): void {
        const keyTimer = 'timeTransition';
        const sub = this._routerSvc.paramsChange.subscribe((data) => {
            if (data) {
                const queryParams = data.query.get(PRIMARY_OUTLET);
                if (queryParams != null && queryParams['returnUrl']) {
                    this._returnUrl = queryParams['returnUrl'];
                }
                const { step } = data.uri.get(PRIMARY_OUTLET);
                if (step != null) {
                    if (step === 'identifier' && !this._userConnectedInfos.isAuthenticated) {
                        this._oauthService.login();
                        return;
                    };
                }
                if (this._returnUrl) 
                    this._routerSvc.navigateByUrl(this._returnUrl);
                else 
                    this._routerSvc.navigateTo(AppPages.Dashboard, { contractId: this._appSvc.preferences.contract });
            }
        });
        this._subs.push('change-params', sub);
    }
}
