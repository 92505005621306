<ez-form
    (onSubmit)="formSubmit()"
    (onCancel)="formCancel()"
    [disabled]="disabled"
>
    <ng-container *ngIf="internalValue">
        <ez-form-group>
            <ez-label>{{
                "autotrading.session.parameters.strategies.strategy.title"
                    | translate
            }}</ez-label>

            <ez-form-group>
                <ez-label>
                    <ez-form-field
                        *ngIf="
                            internalSessiontType == type.Position ||
                            internalSessiontType == type.Flex
                        "
                    >
                        <ez-checkbox
                            [(ngModel)]="
                                internalValue.pricePonderationParameters
                                    .activated
                            "
                            [disabled]="internalValue.atAnyPrice || disabled"
                        >
                            {{
                                "autotrading.session.parameters.strategies.strategy.ponderation.label"
                                    | translate
                            }}
                        </ez-checkbox>
                    </ez-form-field>
                </ez-label>
                <ez-form-field
                    *ngIf="
                        internalSessiontType == type.Position ||
                        internalSessiontType == type.Flex
                    "
                >
                    <ez-label
                        >{{
                            "autotrading.session.parameters.strategies.strategy.ponderation.starts"
                                | translate
                        }}
                    </ez-label>
                    <ez-timespan
                        [(ngModel)]="
                            internalValue.pricePonderationParameters
                                .dynamicBefore
                        "
                        [min]="
                            internalValue.pricePonderationParameters
                                .absoluteAfter
                        "
                        [disabled]="
                            internalValue.atAnyPrice ||
                            !internalValue.pricePonderationParameters.activated
                            || disabled
                        "
                        [required]="true"
                    ></ez-timespan>
                </ez-form-field>
                <ez-form-field
                    *ngIf="
                        internalSessiontType == type.Position ||
                        internalSessiontType == type.Flex
                    "
                >
                    <ez-label
                        >{{
                            "autotrading.session.parameters.strategies.strategy.ponderation.equals"
                                | translate
                        }}
                    </ez-label>
                    <ez-timespan
                        [(ngModel)]="
                            internalValue.pricePonderationParameters
                                .absoluteAfter
                        "
                        [max]="
                            internalValue.pricePonderationParameters
                                .dynamicBefore
                        "
                        [disabled]="
                            internalValue.atAnyPrice ||
                            !internalValue.pricePonderationParameters.activated
                            || disabled
                        "
                        [required]="true"
                    >
                    </ez-timespan>
                </ez-form-field>
            </ez-form-group>

            <ez-form-field>
                <ez-checkbox [(ngModel)]="internalValue.activateSelfMatchPrev" [disabled]="disabled">
                    {{
                        "autotrading.session.parameters.strategies.strategy.selfMatch"
                            | translate
                    }}
                </ez-checkbox>
            </ez-form-field>
        </ez-form-group>

        <ez-form-group
            *ngIf="
                internalSessiontType == type.Flex &&
                internalValueFlex.flexToCloseParameters
            "
        >
            <ez-label>
                <ez-checkbox
                    label="{{
                        'autotrading.session.parameters.strategies.flexToClose.label'
                            | translate
                    }}"
                    [(ngModel)]="
                        internalValueFlex.flexToCloseParameters.activated
                    "
                    [(checked)]="
                        internalValueFlex.flexToCloseParameters.activated
                    "
                    [disabled]="disabled"
                >
                </ez-checkbox>
            </ez-label>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.flexToClose.flexBefore"
                        | translate
                }}</ez-label>
                <ez-timespan
                    [(ngModel)]="
                        internalValueFlex.flexToCloseParameters.flexBefore
                    "
                    [required]="true"
                    [min]="internalValueFlex.flexToCloseParameters.closeAfter"
                    [disabled]="
                        !internalValueFlex.flexToCloseParameters.activated
                        || disabled
                    "
                >
                </ez-timespan>
            </ez-form-field>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.flexToClose.closeAfter"
                        | translate
                }}</ez-label>
                <ez-timespan
                    [(ngModel)]="
                        internalValueFlex.flexToCloseParameters.closeAfter
                    "
                    [required]="true"
                    [max]="internalValueFlex.flexToCloseParameters.flexBefore"
                    [disabled]="
                        !internalValueFlex.flexToCloseParameters.activated
                        || disabled
                    "
                >
                </ez-timespan>
            </ez-form-field>
        </ez-form-group>

        <!-- Market value -->
        <ez-form-group>
            <ez-label>
                <ez-checkbox
                    [(ngModel)]="internalValue.marketMakingParameters.activated"
                    [disabled]="disabled"
                >
                    {{
                        "autotrading.session.parameters.strategies.marketMaking.title"
                            | translate
                    }}
                </ez-checkbox>
            </ez-label>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.marketMaking.price"
                        | translate
                }}</ez-label>
                <ez-timespan
                    [(ngModel)]="
                        internalValue.marketMakingParameters.priceTakerBefore
                    "
                    [min]="
                        internalValue.marketMakingParameters.marketMakerAfter
                    "
                    [disabled]="!internalValue.marketMakingParameters.activated || disabled"
                    [required]="true"
                ></ez-timespan>
            </ez-form-field>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.marketMaking.market"
                        | translate
                }}</ez-label>
                <ez-timespan
                    [(ngModel)]="
                        internalValue.marketMakingParameters.marketMakerAfter
                    "
                    [max]="
                        internalValue.marketMakingParameters.priceTakerBefore
                    "
                    [disabled]="!internalValue.marketMakingParameters.activated || disabled"
                    [required]="true"
                ></ez-timespan>
            </ez-form-field>
        </ez-form-group>
        <ez-form-group>
            <ez-label>{{
                "autotrading.session.parameters.strategies.orders.title"
                    | translate
            }}</ez-label>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.orders.direction"
                        | translate
                }}</ez-label>
                <ez-select
                    [required]="true"
                    id="s1"
                    [(ngModel)]="internalValue.direction"
                    [disabled]="disabled"
                >
                    <ez-select-option
                        *ngFor="
                            let item of orderDirection | enumStringToIterable
                        "
                        [value]="item['value']"
                        >{{
                            "autotrading.market.orderDirStrat." + item["value"]
                                | translate
                        }}</ez-select-option
                    >
                </ez-select>
            </ez-form-field>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.orders.type"
                        | translate
                }}</ez-label>
                <ez-select
                    [required]="true"
                    id="s2"
                    [(ngModel)]="internalValue.type"
                    [disabled]="disabled"
                >
                    <ez-select-option
                        *ngFor="let item of orderType | enumStringToIterable"
                        [value]="item['value']"
                        >{{
                            "autotrading.market.orderType." + item["value"]
                                | translate
                        }}</ez-select-option
                    >
                </ez-select>
            </ez-form-field>

            <ez-form-field>
                <ez-label
                    >{{
                        "autotrading.session.parameters.strategies.orders.displayedQuantity"
                            | translate
                    }}
                </ez-label>
                <ez-input-number
                    [disabled]="internalValue.type != orderType.Iceberg || disabled"
                    [(ngModel)]="displayedQuantityValue"
                    [min]="5"
                    [required]="true"
                >
                    <ez-suffix>{{ unitMW?.name }}</ez-suffix>
                </ez-input-number>
            </ez-form-field>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.orders.exeRestriction"
                        | translate
                }}</ez-label>
                <ez-select
                    [required]="true"
                    [(ngModel)]="internalValue.restriction"
                    [disabled]="disabled"
                >
                    <ez-select-option
                        *ngFor="
                            let item of orderRestriction | enumStringToIterable
                        "
                        [value]="item['value']"
                    >
                        {{
                            "autotrading.market.exeRes." + item["value"]
                                | translate
                        }}</ez-select-option
                    >
                </ez-select>
            </ez-form-field>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.orders.minOrderSize"
                        | translate
                }}</ez-label>
                <ez-physical-value
                    [(ngModel)]="internalValue.minOrderSize"
                    [units]="units"
                    [unitFamily]="powerUnitsFamily"
                    [required]="true"
                >
                </ez-physical-value>
            </ez-form-field>
            <ez-form-field>
                <ez-label>{{
                    "autotrading.session.parameters.strategies.comment"
                        | translate
                }}</ez-label>
                <ez-input-text
                    [(ngModel)]="internalValue.comment"
                    [type]="'textarea'"
                    [placeholder]="'Comment'"
                >
                </ez-input-text>
            </ez-form-field>
        </ez-form-group>

       

        <!-- OMT -->
        <ez-form-group *ngIf="enableOmt">
            <ez-label>
                <ez-form-field>
                    <ez-checkbox
                        [(ngModel)]="internalValue.omtParametersActivated"
                        [disabled]="disabled"
                    >
                        {{
                            "autotrading.session.parameters.strategies.strategy.omt.omtParameters"
                                | translate
                        }}
                    </ez-checkbox>
                </ez-form-field>
                <span *ngIf="!enableOmt" class="not-enable-feature">
                    {{
                        "autotrading.session.parameters.strategies.strategy.otr.notEnable"
                            | translate
                    }}
                </span>
            </ez-label>
            <!-- Short Rules -->
            <ez-form-group>
                <ez-label
                    [class.disabled]="
                        !enableOmt || !internalValue.omtParametersActivated
                    "
                >
                    <div class="limit-header">
                        {{
                            "autotrading.session.parameters.strategies.strategy.omt.shortLimitRules"
                                | translate
                        }}
                    </div>
                </ez-label>
                <!-- Short Initiator Limit -->
                <ez-form-field>
                    <ez-input-number
                        [(ngModel)]="
                            internalValue.omtShortRulesParameters.initiatorLimit
                        "
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [min]="0"
                        [placeholder]="'autotrading.session.parameters.strategies.strategy.omt.initiatorLimit' | translate"
                    >
                        <ez-suffix>%</ez-suffix>
                    </ez-input-number>
                    <ez-description *ngIf="enableOmt && internalValue.omtParametersActivated && throttlingLimit">
                        <span>{{getLimitDescription(internalValue.omtShortRulesParameters.initiatorLimit, throttlingLimit.shortRule)}}</span>                          
                    </ez-description>
                </ez-form-field>
                <!-- Short Aggressor Limit -->
                <ez-form-field>
                    <ez-input-number
                        [(ngModel)]="
                            internalValue.omtShortRulesParameters.aggressorLimit
                        "
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [min]="0"
                        [placeholder]="'autotrading.session.parameters.strategies.strategy.omt.aggressionLimit' | translate"
                    >
                        <ez-suffix>%</ez-suffix>
                    </ez-input-number>
                    <ez-description *ngIf="enableOmt && internalValue.omtParametersActivated && throttlingLimit">
                        <span>{{getLimitDescription(internalValue.omtShortRulesParameters.aggressorLimit, throttlingLimit.shortRule)}}</span>                      
                    </ez-description>
                </ez-form-field>
                <!-- Short Maximum Order Modification -->
                <ez-form-field>
                    <ez-gradient-omt
                        [rowHeader]="
                            'autotrading.session.parameters.strategies.strategy.omt.omtValue' | translate"
                        [defaultOffset1]="
                            defaultShortOmtOrderModificationGradientsOffset1
                        "
                        [defaultOffset2]="
                            defaultShortOmtOrderModificationGradientsOffset2
                        "
                        [(ngModel)]="omtShortOrderModificationGradients"
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated
                        "
                        [required]="true"
                    >
                    </ez-gradient-omt>
                </ez-form-field>
                <!-- Short Rules Price Gradients  -->
                <ez-form-field>
                    <ez-gradient-volume
                        [defaultValue]="defaultPriceValueOmt"
                        [(ngModel)]="omtShortPriceGradients"
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [minValue]="0"
                        [maxValue]="100"
                    >
                        <ez-label>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.omtValue"
                                    | translate
                            }}
                        </ez-label>
                        <span class='ez-factor'>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.priceOffset"
                                    | translate
                            }}
                        </span>
                    </ez-gradient-volume>
                </ez-form-field>
                <!-- Short Rules Volume Gradients  -->
                <ez-form-field>
                    <ez-gradient-volume
                        [defaultValue]="defaultVolumeValueOmt"
                        [(ngModel)]="omtShortVolumeGradients"
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [minValue]="0"
                        [maxValue]="100"
                    >
                        <ez-label>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.omtValue"
                                    | translate
                            }}
                        </ez-label>
                        <span class='ez-factor'>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.volumeOffset"
                                    | translate
                            }}
                        </span>
                    </ez-gradient-volume>
                </ez-form-field>
            </ez-form-group>
            <!-- Long Rules -->
            <ez-form-group>
                <ez-label
                    [class.disabled]="
                        !enableOmt || !internalValue.omtParametersActivated
                    "
                >
                    <div class="limit-header">
                        {{
                            "autotrading.session.parameters.strategies.strategy.omt.longLimitRules"
                                | translate
                        }}
                        <ez-button
                            [icon]="'clone'"
                            [small]="true"
                            [disabled]="
                                !enableOmt ||
                                !internalValue.omtParametersActivated
                                || disabled
                            "
                            [ezTooltip]="'Clone short rules params to long'"
                            (onClick)="cloneShortParamsToLong($event)"
                        ></ez-button>
                    </div>
                </ez-label>
                <!-- Long Rules Initiator limit  -->
                <ez-form-field>
                    <ez-input-number
                        [(ngModel)]="
                            internalValue.omtLongRulesParameters.initiatorLimit
                        "
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated 
                            || disabled
                        "
                        [required]="true"
                        [min]="0"
                        [placeholder]="'autotrading.session.parameters.strategies.strategy.omt.initiatorLimit' | translate"
                    >
                        <ez-suffix>%</ez-suffix>
                    </ez-input-number>
                    <ez-description *ngIf="enableOmt && internalValue.omtParametersActivated && throttlingLimit">
                        <span>{{getLimitDescription(internalValue.omtLongRulesParameters.initiatorLimit, throttlingLimit.longRule)}}</span>                      
                    </ez-description>
                </ez-form-field>
                <!-- Long Rules Aggressor limit  -->
                <ez-form-field>
                    <ez-input-number
                        [(ngModel)]="
                            internalValue.omtLongRulesParameters.aggressorLimit
                        "
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [min]="0"
                        [placeholder]="'autotrading.session.parameters.strategies.strategy.omt.aggressionLimit' | translate"
                    >
                        <ez-suffix>%</ez-suffix>
                    </ez-input-number>
                    <ez-description *ngIf="enableOmt && internalValue.omtParametersActivated && throttlingLimit">
                        <span>{{getLimitDescription(internalValue.omtLongRulesParameters.aggressorLimit, throttlingLimit.longRule)}}</span>                      
                    </ez-description>
                </ez-form-field>
                <!-- Long Rules Maximum Order Modification -->
                <ez-form-field>
                    <!-- TEMP -->
                    <ez-form-field>
                        <ez-gradient-omt
                            [rowHeader]="
                                'autotrading.session.parameters.strategies.strategy.omt.omtValue' | translate"
                            [defaultOffset1]="
                                defaultLongOmtOrderModificationGradientsOffset1
                            "
                            [defaultOffset2]="
                                defaultLongOmtOrderModificationGradientsOffset2
                            "
                            [(ngModel)]="omtLongOrderModificationGradients"
                            [disabled]="
                                !enableOmt ||
                                !internalValue.omtParametersActivated
                                || disabled
                            "
                            [required]="true"
                        >
                        </ez-gradient-omt>
                    </ez-form-field>
                </ez-form-field>
                <!-- Long Rules Price Gradients -->
                <ez-form-field>
                    <ez-gradient-volume
                        [rowHeader]="
                            'autotrading.session.parameters.strategies.strategy.omt.omtValue'"
                        [defaultValue]="defaultPriceValueOmt"
                        [(ngModel)]="omtLongPriceGradients"
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [minValue]="0"
                        [maxValue]="100"
                    >
                        <ez-label>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.omtValue"
                                    | translate
                            }}</ez-label
                        >
                        <span class='ez-factor'>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.priceOffset"
                                    | translate
                            }}
                        </span>
                    </ez-gradient-volume>
                </ez-form-field>
                <!-- Long Rules Volume Gradients -->
                <ez-form-field>
                    <ez-gradient-volume
                        [rowHeader]="
                            'autotrading.session.parameters.strategies.strategy.omt.omtValue'"
                        [defaultValue]="defaultVolumeValueOmt"
                        [(ngModel)]="omtLongVolumeGradients"
                        [disabled]="
                            !enableOmt || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [minValue]="0"
                        [maxValue]="100"
                    >
                        <ez-label>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.omtValue"
                                    | translate
                            }}</ez-label
                        >
                        <span class='ez-factor'>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.volumeOffset"
                                    | translate
                            }}
                        </span>
                    </ez-gradient-volume>
                </ez-form-field>
            </ez-form-group>
        </ez-form-group>

        
        <!-- THROTTLING -->
        <ez-form-group *ngIf="enableThrottling">
            <ez-label>
                <ez-form-field>
                    <ez-checkbox
                        [(ngModel)]="internalValue.omtParametersActivated"
                    >
                        {{
                            "autotrading.session.parameters.strategies.strategy.throttling.throttlingLimitManagement"
                                | translate
                        }}
                    </ez-checkbox>
                </ez-form-field>
                <span *ngIf="!enableThrottling" class="not-enable-feature">
                    {{
                        "autotrading.session.parameters.strategies.strategy.throttling.notEnable"
                            | translate
                    }}
                </span>
            </ez-label>
            <!-- Short Rules -->
            <ez-form-group>
                <ez-label
                    [class.disabled]="
                        !enableThrottling || !internalValue.omtParametersActivated
                    "
                >
                    <div class="limit-header">
                        {{
                            "autotrading.session.parameters.strategies.strategy.throttling.shortLimitRules"
                                | translate
                        }}
                    </div>
                </ez-label>
                <!-- Short Initiator Limit -->
                <ez-form-field>
                    <ez-input-number
                        [(ngModel)]="
                            internalValue.omtShortRulesParameters.initiatorLimit
                        "
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [min]="0"
                        [max]="100"
                        [placeholder]="'autotrading.session.parameters.strategies.strategy.throttling.initiatorShortLimit' | translate"
                    >
                        <ez-suffix class="suffix">%</ez-suffix>
                    </ez-input-number>
                </ez-form-field>
                <!-- Short Aggressor Limit -->
                <ez-form-field>
                    <ez-input-number
                        [(ngModel)]="
                            internalValue.omtShortRulesParameters.aggressorLimit
                        "
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [min]="0"
                        [max]="100"
                        [placeholder]="'autotrading.session.parameters.strategies.strategy.throttling.aggressionShortLimit' | translate"
                    >
                        <ez-suffix class="suffix">%</ez-suffix>
                    </ez-input-number>
                </ez-form-field>
                <!-- Short Maximum Order Modification -->
                <ez-form-field>
                    <ez-gradient-omt
                        [rowHeader]="
                            'autotrading.session.parameters.strategies.strategy.throttling.throttlingShortLimit' | translate"
                        [defaultOffset1]="
                            defaultShortOmtOrderModificationGradientsOffset1
                        "
                        [defaultOffset2]="
                            defaultShortOmtOrderModificationGradientsOffset2
                        "
                        [(ngModel)]="omtShortOrderModificationGradients"
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                    >
                    </ez-gradient-omt>
                </ez-form-field>
                <!-- Short Rules Price Gradients  -->
                <ez-form-field>
                    <ez-gradient-volume
                        [defaultValue]="defaultPriceValueOmt"
                        [(ngModel)]="omtShortPriceGradients"
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [minValue]="0"
                        [maxValue]="100"
                    >
                        <ez-label>
                            {{
                                "autotrading.session.parameters.strategies.strategy.throttling.throttlingShortLimit"
                                    | translate
                            }} 
                        </ez-label>
                        <span class='ez-factor'>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.priceOffset"
                                    | translate
                            }} 
                        </span>
                    </ez-gradient-volume>
                </ez-form-field>
                <!-- Short Rules Volume Gradients  -->
                <ez-form-field>
                    <ez-gradient-volume
                        [defaultValue]="defaultVolumeValueOmt"
                        [(ngModel)]="omtShortVolumeGradients"
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [minValue]="0"
                        [maxValue]="100"
                    >
                        <ez-label>
                            {{
                                
                                "autotrading.session.parameters.strategies.strategy.throttling.throttlingShortLimit"
                                    | translate
                            }}
                        </ez-label>
                        <span class='ez-factor'>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.volumeOffset"
                                    | translate
                            }}
                        </span>
                    </ez-gradient-volume>
                </ez-form-field>
            </ez-form-group>
            <!-- Long Rules -->
            <ez-form-group>
                <ez-label
                    [class.disabled]="
                        !enableThrottling || !internalValue.omtParametersActivated
                    "
                >
                    <div class="limit-header">
                        {{
                            "autotrading.session.parameters.strategies.strategy.omt.longLimitRules"
                                | translate
                        }}
                        <ez-button
                            [icon]="'clone'"
                            [small]="true"
                            [disabled]="
                                !enableThrottling ||
                                !internalValue.omtParametersActivated
                                || disabled
                            "
                            [ezTooltip]="'Clone short rules params to long'"
                            (onClick)="cloneShortParamsToLong($event)"
                        ></ez-button>
                    </div>
                </ez-label>
                <!-- Long Rules Initiator limit  -->
                <ez-form-field>
                    <ez-input-number
                        [(ngModel)]="
                            internalValue.omtLongRulesParameters.initiatorLimit
                        "
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [min]="0"
                        [max]="100"
                        [placeholder]="'autotrading.session.parameters.strategies.strategy.throttling.initiatorLongLimit' | translate"
                    >
                        <ez-suffix class="suffix">%</ez-suffix>
                    </ez-input-number>
                </ez-form-field>
                <!-- Long Rules Aggressor limit  -->
                <ez-form-field>
                    <ez-input-number
                        [(ngModel)]="
                            internalValue.omtLongRulesParameters.aggressorLimit
                        "
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [min]="0"
                        [max]="100"
                        [placeholder]="'autotrading.session.parameters.strategies.strategy.throttling.aggressionLongLimit' | translate"
                    >
                        <ez-suffix class="suffix">%</ez-suffix>
                    </ez-input-number>
                </ez-form-field>
                <!-- Long Rules Maximum Order Modification -->
                <ez-form-field>
                    <!-- TEMP -->
                    <ez-form-field>
                        <ez-gradient-omt
                            [rowHeader]="
                                'autotrading.session.parameters.strategies.strategy.throttling.throttlingLongLimit' | translate"
                            [defaultOffset1]="
                                defaultLongOmtOrderModificationGradientsOffset1
                            "
                            [defaultOffset2]="
                                defaultLongOmtOrderModificationGradientsOffset2
                            "
                            [(ngModel)]="omtLongOrderModificationGradients"
                            [disabled]="
                                !enableThrottling ||
                                !internalValue.omtParametersActivated
                                || disabled
                            "
                            [required]="true"
                        >
                        </ez-gradient-omt>
                    </ez-form-field>
                </ez-form-field>
                <!-- Long Rules Price Gradients -->
                <ez-form-field>
                    <ez-gradient-volume
                        [defaultValue]="defaultPriceValueOmt"
                        [(ngModel)]="omtLongPriceGradients"
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [minValue]="0"
                        [maxValue]="100"
                    >
                        <ez-label>
                            {{
                                "autotrading.session.parameters.strategies.strategy.throttling.throttlingLongLimit"
                                    | translate
                            }}</ez-label
                        >
                        <span class='ez-factor'>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.priceOffset"
                                    | translate
                            }}
                        </span>
                    </ez-gradient-volume>
                </ez-form-field>
                <!-- Long Rules Volume Gradients -->
                <ez-form-field>
                    <ez-gradient-volume
                        [defaultValue]="defaultVolumeValueOmt"
                        [(ngModel)]="omtLongVolumeGradients"
                        [disabled]="
                            !enableThrottling || !internalValue.omtParametersActivated
                            || disabled
                        "
                        [required]="true"
                        [minValue]="0"
                        [maxValue]="100"
                    >
                        <ez-label>
                            {{
                                "autotrading.session.parameters.strategies.strategy.throttling.throttlingLongLimit"
                                    | translate
                            }}</ez-label
                        >
                        <span class='ez-factor'>
                            {{
                                "autotrading.session.parameters.strategies.strategy.omt.volumeOffset"
                                    | translate
                            }}
                        </span>
                    </ez-gradient-volume>
                </ez-form-field>
            </ez-form-group>
        </ez-form-group>

        <ez-footer *ngIf="!hideFooter">
            <ez-button
                [label]="'general.reset' | translate"
                ez-form-cancel
            ></ez-button>
            <ez-button
                [label]="'general.save' | translate"
                [severity]="'primary'"
                ez-form-submit
            ></ez-button>
        </ez-footer>
    </ng-container>
</ez-form>
